export const ComponentIds = {
    AssociationsTable: 'AssociationsTable',
    QueryPipelinesTable: 'QueryPipelinesTable',
    EditQueryPipeline: 'EditQueryPipelineComponent',
    ViewAccessPipeline: 'ViewAccessPipeline',
    EditSynonym: 'EditSynonym',
    FiltersTable: 'FiltersTable',
    EditFilter: 'EditFilter',
    CopyStatement: 'CopyStatement',
    EditWithCode: 'EditWithCode',
    SynonymsTable: 'SynonymsTable',
    RankingWeights: 'RankingWeightComponent',
    RankingWeightsTable: 'RankingWeightsTable',
    ImportQueryPipeline: 'ImportQueryPipeline',
    ImportQueryPipelineWarning: 'ConfirmImportQueryPipeline',
    ExportQueryPipeline: 'ExportQueryPipeline',
    ConfirmExportQueryPipeline: 'ConfirmExportQueryPipeline',
    TriggersTable: 'TriggersTable',
    EditTrigger: 'EditTrigger',
    EditRankingWeight: 'EditRankingWeight',
    EditSearchSetting: 'EditSearchSetting',
    ConditionsTable: 'ConditionsTable',
    StatementGroupsTable: 'StatementGroupsTable',
    StatementGroupsConfiguration: 'StatementGroupsConfiguration',
    ABTestTable: 'ABTestTable',
    EditRankingExpression: 'EditRankingExpression',
    SearchSettingsComponent: 'SearchSettingsComponent',
    StopWordsComponent: 'StopWordsComponent',
    AssociationsComponent: 'AssociationsComponent',
    ResultRankingComponent: 'ResultRankingComponent',
    EditAssociationComponent: 'EditAssociationComponent',
    EditAssociation: 'EditAssociation',
    ResultRankingConfigurationComponent: 'ResultRankingConfigurationComponent',
    EditAssociationJSONComponent: 'EditAssociationJSONComponent',
    FeaturedResultsComponent: 'FeaturedResultsComponent',
    RelevanceInspector: 'RelevanceInspector',
};
