import {IReduxAction} from '@coveord/plasma-react';
import _ from 'underscore';
import {
    EditDimensionTimeseriesActionPayload,
    EditDimensionTimeseriesActionsType,
    EditDimensionTimeseriesFullPayload,
    EditDimensionTimeseriesSetFiltersPayload,
} from '../actions/EditDimensionTimeseriesActions';
import {EditTimeseriesActionsType} from '../actions/EditTimeseriesActions';
import {EditDimensionTimeseriesDefaultState, EditDimensionTimeseriesState} from '../EditDimensionTimeseriesState';

const initialize = (
    state: EditDimensionTimeseriesState,
    action: IReduxAction<EditDimensionTimeseriesFullPayload>,
): EditDimensionTimeseriesState =>
    _.extend({}, state, EditDimensionTimeseriesDefaultState, {
        ...action.payload,
    });

const clear = (): EditDimensionTimeseriesState => _.extend({}, EditDimensionTimeseriesDefaultState);

const setFilters = (
    state: EditDimensionTimeseriesState,
    action: IReduxAction<EditDimensionTimeseriesSetFiltersPayload>,
): EditDimensionTimeseriesState => _.extend({}, state, {filters: action.payload.filters});

const EditDimensionTimeseriesReducers: {[key: string]: (...args: any[]) => EditDimensionTimeseriesState} = {
    [EditDimensionTimeseriesActionsType.Initialize]: initialize,
    [EditDimensionTimeseriesActionsType.SetFilters]: setFilters,
    [EditTimeseriesActionsType.Clear]: clear,
};

export type EditDimensionTimeseriesPayload =
    | EditDimensionTimeseriesActionPayload
    | EditDimensionTimeseriesFullPayload
    | EditDimensionTimeseriesSetFiltersPayload;

export const EditDimensionTimeseriesReducer = (
    state: EditDimensionTimeseriesState = EditDimensionTimeseriesDefaultState,
    action?: IReduxAction<EditDimensionTimeseriesPayload>,
): EditDimensionTimeseriesState =>
    EditDimensionTimeseriesReducers[action.type] ? EditDimensionTimeseriesReducers[action.type](state, action) : state;
