import {generateUUID} from '@coveord/jsadmin-common';
import {IDropdownOption} from '@coveord/plasma-react';
import _ from 'underscore';
import * as s from 'underscore.string';

import {Locales} from '../../../../../Locales';
import {SfObjectFieldModel, SfObjectFieldTypes} from '../../fields/SfObjectFieldModel';
import {SfObjectConditionInConditionTypes} from './inconditions/SfObjectConditionsInConditionTypes';
import {SfObjectConditionOperators} from './operators/SfObjectConditionsOperators';
import {FlattenedSfObjectCondition} from './SfObjectConditionsReducers';

export class SfObjectConditionsUtils {
    static SOQL_QUOTE_TYPE = "'";
    static NullValue = 'null';

    static getConditionAdderActionTooltip(condition: FlattenedSfObjectCondition): string {
        const isDefaultFieldName = condition.fieldName === Locales.format('selectFieldName');
        const isDefautOperator = condition.operator === Locales.format('selectOperator');

        if (isDefaultFieldName && isDefautOperator) {
            return Locales.format('selectFieldNameAndOperator');
        } else if (isDefaultFieldName) {
            return Locales.format('selectFieldNameWithDot');
        } else if (isDefautOperator) {
            return Locales.format('selectOperatorWithDot');
        }

        return Locales.format('addCondition');
    }

    static getConditionValuePlaceholderFromFieldTypeAndOperator(fieldType: string, operator: string): string {
        const formattedFieldType = fieldType || Locales.format('unknown');
        const pluralOrSingular = SfObjectConditionsUtils.isInCondition(operator) ? 2 : 1;
        const defaultValue = Locales.format('SfConditionPlaceholder_default', {
            smart_count: pluralOrSingular,
        });

        return Locales.formatOrDefault(`SfConditionPlaceholder_${formattedFieldType}`, {
            defaultTranslation: defaultValue,
            smart_count: pluralOrSingular,
        });
    }

    static getContextualTooltipFromFieldType(fieldType: string): string {
        return Locales.format('sfConditionContextualTooltip_intro', {
            type: Locales.formatOrDefault(`sfObjectFieldType_${fieldType}`, {
                defaultTranslation: Locales.format('unknown'),
            }),
        });
    }

    static getContextualTooltipFooterFromFieldType(fieldType: string): string {
        return Locales.formatOrDefault(`sfConditionContextualTooltip_${fieldType}`, {defaultTranslation: ''}) || null;
    }

    static toggleDropdownSelectedOption(options: IDropdownOption[], selectedValue: string): IDropdownOption[] {
        const selectedOption = _.findWhere(options, {value: selectedValue});

        if (selectedOption) {
            selectedOption.selected = true;
            return options;
        }

        const isDefault = _.contains(
            [Locales.format('selectFieldName'), Locales.format('selectOperator')],
            selectedValue,
        );
        const customSelectedValue = !!SfObjectConditionOperators[selectedValue]
            ? {
                  hidden: true,
                  value: Locales.format('selectOperator'),
                  selected: true,
                  custom: true,
                  default: true,
              }
            : {hidden: isDefault, value: selectedValue, selected: true, custom: true, default: isDefault};

        return [customSelectedValue, ...options];
    }

    static generateNewId(): string {
        return `cond-${generateUUID(5)}`;
    }

    static assignNewIds(conditions: FlattenedSfObjectCondition[]): FlattenedSfObjectCondition[] {
        return conditions.map((condition: FlattenedSfObjectCondition) =>
            _.extend({}, condition, {id: SfObjectConditionsUtils.generateNewId()}),
        );
    }

    static isConditionAdderValid(condition: FlattenedSfObjectCondition): boolean {
        return (
            condition.fieldName !== Locales.format('selectFieldName') &&
            condition.operator !== Locales.format('selectOperator')
        );
    }

    static isInCondition(operator: string) {
        return _.contains([SfObjectConditionOperators.IN, SfObjectConditionOperators.NOT_IN], operator);
    }

    static isFieldOfTypeDateOrDatetime(fieldType: string) {
        return _.contains([SfObjectFieldTypes.Date, SfObjectFieldTypes.Datetime], fieldType);
    }

    static isFieldOfTypeBoolean(fieldType: string) {
        return _.contains([SfObjectFieldTypes.Boolean2, SfObjectFieldTypes.Boolean], fieldType);
    }

    static isFieldOfTypeNonPolymorphicParent(fieldType: string) {
        return _.contains([SfObjectFieldTypes.NonPolymorphicParent], fieldType);
    }

    static isFieldOfTypeString(fieldType: string) {
        return _.contains(
            [
                SfObjectFieldTypes.Address,
                SfObjectFieldTypes.Category,
                SfObjectFieldTypes.Combobox,
                SfObjectFieldTypes.Phone,
                SfObjectFieldTypes.Picklist,
                SfObjectFieldTypes.Reference,
                SfObjectFieldTypes.String,
                SfObjectFieldTypes.Url,
                SfObjectFieldTypes.Email,
                SfObjectFieldTypes.Id,
                SfObjectFieldTypes.MultiPicklist,
            ],
            fieldType,
        );
    }

    static formatSOQLValueToDisplayedValue(operator: string, value: string, type?: string) {
        if (SfObjectConditionsUtils.isInCondition(operator) && value === undefined) {
            return SfObjectConditionsUtils.NullValue;
        }

        if (SfObjectConditionsUtils.isInCondition(operator) && type === SfObjectConditionInConditionTypes.SOQL_STRING) {
            return s.quote(value, SfObjectConditionsUtils.SOQL_QUOTE_TYPE);
        }

        return value;
    }

    static formatDisplayedValueToSOQLValue(operator: string, value: string, fieldType: string) {
        if (SfObjectConditionsUtils.isInCondition(operator) && value === SfObjectConditionsUtils.NullValue) {
            return undefined;
        }

        if (SfObjectConditionsUtils.isInCondition(operator) && SfObjectConditionsUtils.isFieldOfTypeString(fieldType)) {
            return s.unquote(value, SfObjectConditionsUtils.SOQL_QUOTE_TYPE);
        }

        return value === '' ? SfObjectConditionsUtils.NullValue : value;
    }

    static getSOQLTypeFromValueAndFieldType(value: string, fieldType: string): string {
        if (value === '' || value === SfObjectConditionsUtils.NullValue) {
            return SfObjectConditionInConditionTypes.SOQL_STRING;
        }

        if (SfObjectConditionsUtils.isFieldOfTypeBoolean(fieldType)) {
            return SfObjectConditionInConditionTypes.SOQL_BOOLEAN;
        }

        if (SfObjectConditionsUtils.isFieldOfTypeDateOrDatetime(fieldType)) {
            return SfObjectConditionInConditionTypes.SOQL_DATETIME;
        }

        if (SfObjectConditionsUtils.isFieldOfTypeNonPolymorphicParent(fieldType)) {
            return SfObjectConditionInConditionTypes.SOQL_ID;
        }

        return SfObjectConditionInConditionTypes.SOQL_STRING;
    }

    static sortFlattenedConditions(conditions: FlattenedSfObjectCondition[]): FlattenedSfObjectCondition[] {
        return conditions.sort((a: FlattenedSfObjectCondition, b: FlattenedSfObjectCondition) => {
            const condA = `${a.fieldDisplayName}${a.operator}`.toLowerCase();
            const condB = `${b.fieldDisplayName}${b.operator}`.toLowerCase();

            if (condA === condB) {
                return 0;
            }
            if (condA > condB) {
                return 1;
            }
            return -1;
        });
    }

    static removeDuplicatedConditions(flattenedConditions: FlattenedSfObjectCondition[]): FlattenedSfObjectCondition[] {
        const finalFlattenedConditions: FlattenedSfObjectCondition[] = [];
        const keysToOmit = ['id', 'values'];

        flattenedConditions.forEach((condition: FlattenedSfObjectCondition) => {
            let isNotStored = true;
            for (const cond of finalFlattenedConditions) {
                if (_.isMatch(_.omit(cond, keysToOmit), _.omit(condition, keysToOmit))) {
                    isNotStored = false;
                    break;
                }
            }

            if (isNotStored) {
                finalFlattenedConditions.push(condition);
            }
        });

        return finalFlattenedConditions;
    }

    static excludeIllegalFieldTypes(field: SfObjectFieldModel) {
        // reference: https://developer.salesforce.com/docs/atlas.en-us.soql_sosl.meta/soql_sosl/sforce_api_calls_soql_select_fieldexpression.htm
        return !_.contains([SfObjectFieldTypes.Base64, SfObjectFieldTypes.Textarea], field.type);
    }
}
