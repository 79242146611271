import {Center, Loader} from '@components/mantine';
import {Platform, useQuery} from '@core/api';
import {AuthenticationEvents} from '@core/authentication';
import {LicenseContext} from '@core/license';
import {OrganizationContext} from '@core/organization';
import {LaunchDarklyContext} from '@core/feature-flags';
import {Navigate} from '@core/routes';
import {PrivilegesContext, UserContext} from '@core/user';
import {FunctionComponent, ReactNode, useContext, useEffect} from 'react';
import {doTokenRenewal} from '../../utils';

export const MandatoryResourcesLoader: FunctionComponent<{navigateOnError?: boolean; children: ReactNode}> = ({
    navigateOnError = true,
    children,
}) => {
    useEffect(() => {
        const eventListener = (e: CustomEvent) => {
            doTokenRenewal(e.detail);
        };
        document.addEventListener(AuthenticationEvents.UserNeedsTokenRenewal, eventListener);
        return () => document.removeEventListener(AuthenticationEvents.UserNeedsTokenRenewal, eventListener);
    }, []);
    const organizationCtx = useContext(OrganizationContext);
    const licenseCtx = useContext(LicenseContext);
    const userCtx = useContext(UserContext);
    const privilegesCtx = useContext(PrivilegesContext);
    const launchDarklyCtx = useContext(LaunchDarklyContext);
    const invitesQuery = useQuery({
        queryKey: ['init', 'invites'],
        queryFn: () => Platform.invites.list(),
        retry: false,
        staleTime: Infinity,
        enabled: navigateOnError,
    });

    if (navigateOnError) {
        if (invitesQuery.data?.length) {
            return <Navigate to="/invites" injectOrg={false} />;
        }

        if (!organizationCtx.query?.data && organizationCtx.query?.isSuccess) {
            return <Navigate to="/no-organizations" injectOrg={false} />;
        }

        if (organizationCtx.query?.error) {
            if ([401, 403, 404].includes(organizationCtx.query.error.status)) {
                return <Navigate to="/insufficient-privileges" injectOrg={true} />;
            }
            if (organizationCtx.query.error.status >= 400) {
                return <Navigate to="/unable-to-load" injectOrg={false} />;
            }
        }

        if (licenseCtx.query?.isError || privilegesCtx.query?.isError) {
            if (licenseCtx.query?.error?.status === 403) {
                return <Navigate to="/insufficient-privileges" injectOrg={true} />;
            }
            return <Navigate to="/unable-to-load" injectOrg={false} />;
        }
    }

    const areDependentQueriesPending =
        privilegesCtx.query?.status === undefined || licenseCtx.query?.status === undefined;
    const areQueriesLoading =
        invitesQuery.isLoading ||
        userCtx.query?.isLoading ||
        organizationCtx.query?.isLoading ||
        privilegesCtx.query?.isLoading ||
        licenseCtx.query?.isLoading ||
        launchDarklyCtx.isLoading;

    if (areDependentQueriesPending || areQueriesLoading) {
        return (
            <Center>
                <Loader />
            </Center>
        );
    }

    return children;
};
