import {ObjectReducerGenerator} from '@core/store';
import {ObjectType} from '../services/ObjectTypesResources';
import {ObjectTypesActionsTypes} from './ObjectTypesActions';

export interface IObjectTypesState {
    data: ObjectType[];
    hasIndexedCatalogContent: boolean;
    selectedSourceId: string;
}

export type IObjectTypesCompositeState = {[id: string]: IObjectTypesState};

const initialState: IObjectTypesCompositeState = {};

export const objectTypesReducer = ObjectReducerGenerator<IObjectTypesCompositeState>(
    ObjectTypesActionsTypes.updateObjectTypes,
    initialState,
);
