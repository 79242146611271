import {IReduxAction} from '@coveord/plasma-react';
import _ from 'underscore';
import {IModalSelectActionPayload, ModalSelectActionTypes} from './ModalSelectActions';

export interface IModalSelectState {
    id: string;
    value?: string;
}

export const modalSelectInitialState: IModalSelectState = {id: '', value: undefined};
export const modalSelectsInitialState: IModalSelectState[] = [];

export const modalSelectsReducer = (
    state: IModalSelectState[] = modalSelectsInitialState,
    action: IReduxAction<IModalSelectActionPayload>,
): IModalSelectState[] => {
    switch (action.type) {
        case ModalSelectActionTypes.set:
            const modalSelect = _.findWhere(state, {id: action.payload?.id});
            const shouldReplaceValue = modalSelect && !_.isUndefined(action.payload?.value);

            return modalSelect
                ? _.reject(state, (modalRadio) => modalRadio.id === action.payload?.id).concat({
                      ...modalSelect,
                      ...action.payload,
                      value: shouldReplaceValue ? action.payload?.value : modalSelect.value,
                  })
                : [...state, {...modalSelectInitialState, ...action.payload}];
        case ModalSelectActionTypes.remove:
            return _.reject(state, (modalRadio) => action.payload?.id === modalRadio.id);
        default:
            return state;
    }
};
