import {ValidationError} from '@coveord/jsadmin-common';
import * as ReactVapor from '@coveord/plasma-react';

export const EditCalculatedMetricActionsType = {
    Initialize: 'EDIT_CALCULATED_METRIC_INITIALIZE',
    Clear: 'EDIT_CALCULATED_METRIC_CLEAR',
    ApplyChanges: 'EDIT_CALCULATED_METRIC_APPLY_CHANGES',
    SetOperator: 'EDIT_CALCULATED_METRIC_SET_OPERATOR',
    SetTitle: 'EDIT_CALCULATED_METRIC_SET_TITLE',
    SetFormat: 'EDIT_CALCULATED_METRIC_SET_FORMAT',
    SetErrors: 'EDIT_CALCULATED_METRIC_SET_ERRORS',
    SetIsOpen: 'EDIT_CALCULATED_METRIC_SET_OPEN',
    SetType: 'EDIT_CALCULATED_METRIC_SET_TYPE',
    SetValue: 'EDIT_CALCULATED_METRIC_SET_VALUE',
    CancelPopupChanges: 'EDIT_CALCULATED_METRIC_CANCEL_POPUP_CHANGES',
    ApplyPopupChanges: 'EDIT_CALCULATED_METRIC_APPLY_POPUP_CHANGES',
};

export interface EditCalculatedMetricActionPayload {
    // empty
}

export interface EditCalculatedMetricInitializePayload {
    id: string;
    operator: string;
    title: string;
    format: string;
    metricA: string | number;
    metricB: string | number;
}

const initialize = (
    id: string,
    operator: string,
    title: string,
    format: string,
    metricA: string | number,
    metricB: string | number,
): ReactVapor.IReduxAction<EditCalculatedMetricInitializePayload> => ({
    type: EditCalculatedMetricActionsType.Initialize,
    payload: {id, operator, title, format, metricA, metricB},
});

const clear = (): ReactVapor.IReduxAction<EditCalculatedMetricActionPayload> => ({
    type: EditCalculatedMetricActionsType.Clear,
    payload: {},
});

export interface EditCalculatedMetricSavePayload {
    id: string;
    operator: string;
    title: string;
    format: string;
    metricA: string | number;
    metricB: string | number;
}

const save = (
    id: string,
    operator: string,
    title: string,
    format: string,
    metricA: string | number,
    metricB: string | number,
): ReactVapor.IReduxAction<EditCalculatedMetricSavePayload> => ({
    type: EditCalculatedMetricActionsType.ApplyChanges,
    payload: {id, operator, title, format, metricA, metricB},
});

export interface EditCalculatedMetricSelectorPayload {
    id: string;
}

export interface EditCalculatedMetricSetOperatorPayload extends EditCalculatedMetricActionPayload {
    operator: string;
}

const setOperator = (operator: string): ReactVapor.IReduxAction<EditCalculatedMetricSetOperatorPayload> => ({
    type: EditCalculatedMetricActionsType.SetOperator,
    payload: {operator},
});

export interface EditCalculatedMetricSetTitlePayload extends EditCalculatedMetricActionPayload {
    title: string;
}

const setTitle = (title: string): ReactVapor.IReduxAction<EditCalculatedMetricSetTitlePayload> => ({
    type: EditCalculatedMetricActionsType.SetTitle,
    payload: {title},
});

export interface EditCalculatedMetricSetFormatPayload extends EditCalculatedMetricActionPayload {
    format: string;
}

const setFormat = (format: string): ReactVapor.IReduxAction<EditCalculatedMetricSetFormatPayload> => ({
    type: EditCalculatedMetricActionsType.SetFormat,
    payload: {format},
});

export interface EditCalculatedMetricSetErrorsPayload extends EditCalculatedMetricActionPayload {
    errors: ValidationError[];
}

const setErrors = (errors: ValidationError[]): ReactVapor.IReduxAction<EditCalculatedMetricSetErrorsPayload> => ({
    type: EditCalculatedMetricActionsType.SetErrors,
    payload: {errors},
});

export interface EditCalculatedMetricSetIsOpenPayload extends EditCalculatedMetricSelectorPayload {
    isOpen: boolean;
}

const setIsOpen = (id: string, isOpen: boolean): ReactVapor.IReduxAction<EditCalculatedMetricSetIsOpenPayload> => ({
    type: EditCalculatedMetricActionsType.SetIsOpen,
    payload: {id, isOpen},
});

export interface EditCalculatedMetricSetTypePayload extends EditCalculatedMetricSelectorPayload {
    type: string;
}

const setType = (id: string, type: string): ReactVapor.IReduxAction<EditCalculatedMetricSetTypePayload> => ({
    type: EditCalculatedMetricActionsType.SetType,
    payload: {id, type},
});

export interface EditCalculatedMetricSetValuePayload extends EditCalculatedMetricSelectorPayload {
    value: string | number;
}

const setValue = (id: string, value: string): ReactVapor.IReduxAction<EditCalculatedMetricSetValuePayload> => ({
    type: EditCalculatedMetricActionsType.SetValue,
    payload: {id, value},
});

const applyPopupChanges = (id: string): ReactVapor.IReduxAction<EditCalculatedMetricSelectorPayload> => ({
    type: EditCalculatedMetricActionsType.ApplyPopupChanges,
    payload: {id},
});

const cancelPopupChanges = (id: string): ReactVapor.IReduxAction<EditCalculatedMetricSelectorPayload> => ({
    type: EditCalculatedMetricActionsType.CancelPopupChanges,
    payload: {id},
});

export const EditCalculatedMetricActions = {
    initialize,
    clear,
    save,
    setOperator,
    setTitle,
    setFormat,
    setErrors,
    setIsOpen,
    setType,
    setValue,
    applyPopupChanges,
    cancelPopupChanges,
};
