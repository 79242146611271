export const GlobalGroupsConstants = {
    Requests: {
        fetch: 'FETCH_GLOBAL_GROUPS',
        save: 'SAVE_GLOBAL_GROUP',
        delete: 'DELETE_GLOBAL_GROUP',
    },
    Routes: {
        base: '/coveo-only/global-groups',
    },
    Components: {
        table: 'GlobalGroups',
        editor: 'GlobalGroupsEditor',
    },
    actionType: 'GLOBAL_GROUPS',
    SortKeys: {
        name: 'displayName',
        members: 'members',
        identitySets: 'realms',
        orgActivated: 'organizationActivated',
    },
    Events: {
        GroupChanged: 'groups:group:changed',
    },
};
