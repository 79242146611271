import {App} from '@components/app';
import {PlatformAppTheme} from '@configurations/platform';
import {createHashRouter, RouterProvider} from '@core/routes';
import '@coveord/jsadmin-usage-analytics/stylesheets/style.scss';
import '@coveord/jsadmin-v2/stylesheets/dependencies.scss';
import '@coveord/jsadmin-v2/stylesheets/style.scss';
import '@coveord/plasma-style/dist/style.css';
import {wrapCreateBrowserRouter} from '@sentry/react';
import {FunctionComponent, Suspense, useMemo} from 'react';
import '../stylesheets/global.css';

import '@components/conditions/SetupPackage';
import '@components/projects/SetupPackage';
import '@core/license/SetupPackage';
import '@core/organization/SetupPackage';
import '@core/projects/SetupPackage';
import '@core/user/SetupPackage';
import '@coveord/jsadmin-commerce/SetupPackage';
import '@coveord/jsadmin-common/SetupPackage';
import '@coveord/jsadmin-log-browser/SetupPackage';
import '@coveord/jsadmin-search-optimization/SetupPackage';
import '@coveord/jsadmin-service/SetupPackage';
import '@coveord/jsadmin-usage-analytics/SetupPackage';
import '@coveord/jsadmin-v2/SetupPackage';
import '@pages/relevance-inspector/SetupPackage';
import '@pages/snapshot/SetupPackage';
import {mainRoutes} from '../routes';

const sentryCreateHashRouter = wrapCreateBrowserRouter(createHashRouter);
const future = {
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_skipActionErrorRevalidation: true,
};
const Content: FunctionComponent = () => {
    const mainRouter = useMemo(() => sentryCreateHashRouter(mainRoutes, {future}), []);
    return <RouterProvider future={{v7_startTransition: true}} router={mainRouter} />;
};

export const MainApplication: FunctionComponent = () => (
    <App owner="frontend-foundation" framework="Mantine">
        <PlatformAppTheme>
            <Suspense>
                <Content />
            </Suspense>
        </PlatformAppTheme>
    </App>
);
