import _ from 'underscore';
import {PickyMultiSelect, PickySelectable} from '../base-models/PickyModels';

export class CollectionUtils {
    static isMultiSelect(
        collection: Backbone.Collection<Backbone.Model>,
    ): collection is PickyMultiSelect<PickySelectable> {
        const cast = collection as PickyMultiSelect<PickySelectable>;
        return _.isFunction(cast.pickySelect) && _.isFunction(cast.selectAll) && _.isFunction(cast.selectNone);
    }

    static swapModel(collection: Backbone.Collection<Backbone.Model>, indexA: number, indexB: number): boolean {
        const collectionSize: number = collection.size();
        if (indexA !== indexB && indexA < collectionSize && indexB < collectionSize) {
            const modelA: Backbone.Model = collection.models[indexA];
            collection.models[indexA] = collection.models[indexB];
            collection.models[indexB] = modelA;

            return true;
        }

        return false;
    }
}
