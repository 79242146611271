import {InsightPanelConfigModel} from '@core/api';
import {KeyValueReducerGenerator} from '@core/store';
import {combineReducers} from 'redux';
import {IInsightPanelConfigsState} from '../InsightPanelState';
import {InsightPanelConfigActionTypes} from './InsightPanelConfigTableActions';

export const insightPanelConfigsReducer = combineReducers<IInsightPanelConfigsState>({
    data: KeyValueReducerGenerator<InsightPanelConfigModel[]>(
        InsightPanelConfigActionTypes.updateInsightPanelConfigs,
        [],
    ),
});
