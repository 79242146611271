import {
    IThunkAction,
    changeInputValue,
    ListBoxReduxActions,
    ValidationActions,
    ValidationTypes,
} from '@coveord/plasma-react';
import {CaseAssistConfigurationConstants} from '../CaseAssistConfigurationConstants';
import {EditCaseAssistConfigSelectors} from '../EditCaseAssistConfigSelectors';
import {createWithEmptyStateTabReducer} from '../WithEmptyStateTabReducer';
import {ServiceState} from '../../../ServiceState';
import {createWithQuerySyntaxValidationReducer} from '../WithQuerySyntaxValidationReducer';

const withEmptyStateReducer = createWithEmptyStateTabReducer(
    CaseAssistConfigurationConstants.Scopes.DocumentSuggestion,
);
const withQuerySyntaxValidationReducer = createWithQuerySyntaxValidationReducer(
    CaseAssistConfigurationConstants.Scopes.DocumentSuggestion,
);

export const DocumentSuggestionActionTypes = {
    ...withEmptyStateReducer.actionTypes,
    ...withQuerySyntaxValidationReducer.actionTypes,
};

const clearPendingChanges = (): IThunkAction<void, ServiceState> => async (dispatch, getState) => {
    dispatch(withEmptyStateReducer.actions.endConfiguring());

    const initialPipeline = EditCaseAssistConfigSelectors.getCaseAssistConfigPipeline(getState());
    dispatch(
        ListBoxReduxActions.selectListBoxOption(
            CaseAssistConfigurationConstants.ComponentIds.DocumentSuggestion.QueryPipelines,
            false,
            initialPipeline ?? '',
        ),
    );
    dispatch(
        ValidationActions.clearDirty(
            CaseAssistConfigurationConstants.ComponentIds.DocumentSuggestion.QueryPipelines,
            ValidationTypes.wrongInitialValue,
        ),
    );

    const initialFilter = EditCaseAssistConfigSelectors.getCaseAssistConfigFilter(getState());
    dispatch(
        changeInputValue(CaseAssistConfigurationConstants.ComponentIds.DocumentSuggestion.FilterInput, initialFilter),
    );
    dispatch(
        ValidationActions.clearDirty(
            CaseAssistConfigurationConstants.ComponentIds.DocumentSuggestion.FilterInput,
            ValidationTypes.wrongInitialValue,
        ),
    );
};

export const DocumentSuggestionActions = {
    ...withEmptyStateReducer.actions,
    ...withQuerySyntaxValidationReducer.actions,
    clearPendingChanges,
};
