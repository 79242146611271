import {IReduxAction} from '@coveord/plasma-react';
import _ from 'underscore';
import {
    EditCalculatedMetricActionPayload,
    EditCalculatedMetricActionsType,
    EditCalculatedMetricInitializePayload,
    EditCalculatedMetricSelectorPayload,
    EditCalculatedMetricSetErrorsPayload,
    EditCalculatedMetricSetFormatPayload,
    EditCalculatedMetricSetIsOpenPayload,
    EditCalculatedMetricSetOperatorPayload,
    EditCalculatedMetricSetTitlePayload,
    EditCalculatedMetricSetTypePayload,
    EditCalculatedMetricSetValuePayload,
} from '../actions/EditCalculatedMetricActions';
import {CalculatedMetricType} from '../CalculatedMetricType';
import {
    EditCalculatedMetricDefaultState,
    EditCalculatedMetricPickerState,
    EditCalculatedMetricState,
    getCalculatedMetricEditState,
} from '../EditCalculatedMetricState';

const initialize = (state: EditCalculatedMetricState, action: IReduxAction<EditCalculatedMetricInitializePayload>) => {
    const editMetricA = getCalculatedMetricEditState(action.payload.metricA);
    const editMetricB = getCalculatedMetricEditState(action.payload.metricB);

    return _.extend({}, state, EditCalculatedMetricDefaultState, {
        id: action.payload.id,
        operator: action.payload.operator,
        title: action.payload.title,
        format: action.payload.format,
        editMetricA,
        editMetricB,
    });
};

const clear = (state: EditCalculatedMetricState[], action: IReduxAction<EditCalculatedMetricActionPayload>) =>
    _.extend({}, EditCalculatedMetricDefaultState);

const setOperator = (
    state: EditCalculatedMetricPickerState,
    action: IReduxAction<EditCalculatedMetricSetOperatorPayload>,
) => _.extend({}, state, {operator: action.payload.operator});

const setTitle = (state: EditCalculatedMetricPickerState, action: IReduxAction<EditCalculatedMetricSetTitlePayload>) =>
    _.extend({}, state, {title: action.payload.title});

const setFormat = (
    state: EditCalculatedMetricPickerState,
    action: IReduxAction<EditCalculatedMetricSetFormatPayload>,
) => _.extend({}, state, {format: action.payload.format});

const setErrors = (
    state: EditCalculatedMetricPickerState,
    action: IReduxAction<EditCalculatedMetricSetErrorsPayload>,
) => _.extend({}, state, {errors: action.payload.errors});

const clearErrors = (state: EditCalculatedMetricPickerState, action: IReduxAction<EditCalculatedMetricActionPayload>) =>
    _.extend({}, state, {errors: null});

const setIsOpen = (
    state: EditCalculatedMetricPickerState,
    action: IReduxAction<EditCalculatedMetricSetIsOpenPayload>,
) => _.extend({}, state, {isOpen: action.payload.isOpen});

const setType = (state: EditCalculatedMetricPickerState, action: IReduxAction<EditCalculatedMetricSetTypePayload>) => {
    const newEditState = _.extend({}, state.edit, {
        type: action.payload.type,
        value: '',
    });
    return _.extend({}, state, {edit: newEditState});
};

const setValue = (
    state: EditCalculatedMetricPickerState,
    action: IReduxAction<EditCalculatedMetricSetValuePayload>,
) => {
    const newEditState = _.extend({}, state.edit, {
        value: action.payload.value,
    });
    return _.extend({}, state, {edit: newEditState});
};

const cancelChanges = (
    state: EditCalculatedMetricPickerState,
    action: IReduxAction<EditCalculatedMetricActionPayload>,
) =>
    _.extend({}, state, {
        edit: _.extend({}, state.applied),
        isOpen: false,
    });

const applyChanges = (
    state: EditCalculatedMetricPickerState,
    action: IReduxAction<EditCalculatedMetricActionPayload>,
) => {
    let value: string | number = state.edit.value;
    if (_.isString(value) && state.edit.type === CalculatedMetricType.Constant) {
        const parsed = Number(value);
        if (!_.isNaN(parsed)) {
            value = parsed;
        }
    }
    const editState = _.extend({}, state.edit, {value});
    return _.extend({}, state, {
        applied: _.extend({}, editState),
        isOpen: false,
    });
};

const EditCalculatedMetricReducers: {[key: string]: (...args: any[]) => any} = {
    [EditCalculatedMetricActionsType.Initialize]: initialize,
    [EditCalculatedMetricActionsType.Clear]: clear,
    [EditCalculatedMetricActionsType.SetOperator]: setOperator,
    [EditCalculatedMetricActionsType.SetTitle]: setTitle,
    [EditCalculatedMetricActionsType.SetFormat]: setFormat,
    [EditCalculatedMetricActionsType.SetErrors]: setErrors,
    [EditCalculatedMetricActionsType.ApplyPopupChanges]: clearErrors,
};

const EditCalculatedMetricPopoverReducers: {[key: string]: (...args: any[]) => any} = {
    [EditCalculatedMetricActionsType.SetIsOpen]: setIsOpen,
    [EditCalculatedMetricActionsType.SetType]: setType,
    [EditCalculatedMetricActionsType.SetValue]: setValue,
    [EditCalculatedMetricActionsType.CancelPopupChanges]: cancelChanges,
    [EditCalculatedMetricActionsType.ApplyPopupChanges]: applyChanges,
};

export type EditCalculatedMetricPayload =
    | EditCalculatedMetricActionPayload
    | EditCalculatedMetricSetOperatorPayload
    | EditCalculatedMetricSetFormatPayload
    | EditCalculatedMetricSetErrorsPayload
    | EditCalculatedMetricSelectorPayload
    | EditCalculatedMetricSetTypePayload
    | EditCalculatedMetricSetValuePayload;

const isPopupAction = (action: IReduxAction<any>): action is IReduxAction<EditCalculatedMetricSelectorPayload> =>
    action && action.payload && !!action.payload.id;

export const EditCalculatedMetricReducer = (
    state: EditCalculatedMetricState = EditCalculatedMetricDefaultState,
    action?: IReduxAction<EditCalculatedMetricPayload>,
) => {
    let newState = state;
    if (
        isPopupAction(action) &&
        state[action.payload.id] &&
        !_.isUndefined(EditCalculatedMetricPopoverReducers[action.type])
    ) {
        newState = _.extend({}, newState, {
            [action.payload.id]: EditCalculatedMetricPopoverReducers[action.type](newState[action.payload.id], action),
        });
    }
    if (EditCalculatedMetricReducers[action.type]) {
        newState = EditCalculatedMetricReducers[action.type](newState, action);
    }

    return newState;
};
