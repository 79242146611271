import {IReduxAction} from '@coveord/plasma-react';
// TODO: Refactor file (SEARCHAPI-7188)

import {
    IConditionObjectsContextKeysPayload,
    IConditionObjectsPayload,
    IConditionObjectsSearchHubPayload,
    IConditionObjectsState,
    IContextKeyPair,
} from '../interfaces';

import {ObjectsActionsTypes, StatusRequests} from '../constants';

const initialConditionObjectsState: IConditionObjectsState = {
    list: [],
    popularContextKeys: [],
    status: StatusRequests.New,
};

const setList = (state: IConditionObjectsState, action: IReduxAction<IConditionObjectsPayload>) => ({
    ...state,
    list: action.payload.conditionObjects,
    status: StatusRequests.Done,
});

const cleanConditions = (state: IConditionObjectsState, action: IReduxAction<any>) => ({
    ...state,
    list: [],
    status: StatusRequests.New,
});

const setIsPending = (state: IConditionObjectsState, action: IReduxAction<any>) => ({
    ...state,
    status: StatusRequests.Pending,
});

const setHasFailed = (state: IConditionObjectsState, action: IReduxAction<any>) => ({
    ...state,
    status: StatusRequests.Fail,
});

const setPopularSearchHubs = (
    state: IConditionObjectsState,
    action: IReduxAction<IConditionObjectsSearchHubPayload>,
) => {
    const newState = {...state};
    const searchHubObject = state.list.find((condition) => condition.name === '$searchHub');

    if (searchHubObject) {
        searchHubObject.popularValues = action.payload.popularSearchHubs;
    }

    return newState;
};

const setPopularContextKeys = (
    state: IConditionObjectsState,
    action: IReduxAction<IConditionObjectsContextKeysPayload>,
) => ({
    ...state,
    popularContextKeys: action.payload.popularContextKeys,
});

const setContextKeyValues = (state: IConditionObjectsState, action: IReduxAction<IContextKeyPair>) => {
    const newState = {...state};
    const selectedContextKeyPair = state.popularContextKeys.find((key) => key.key === action.payload.key);

    if (selectedContextKeyPair) {
        selectedContextKeyPair.values = action.payload.values;
    }
    return newState;
};

const ConditionObjectsReducer: Record<string, (...args: any[]) => IConditionObjectsState> = {
    [ObjectsActionsTypes.addConditionObjects]: setList,
    [ObjectsActionsTypes.cleanConditionObjects]: cleanConditions,
    [ObjectsActionsTypes.pendingConditionObjects]: setIsPending,
    [ObjectsActionsTypes.failConditionObjects]: setHasFailed,
    [ObjectsActionsTypes.addPopularSearchHubs]: setPopularSearchHubs,
    [ObjectsActionsTypes.addPopularContextKeys]: setPopularContextKeys,
    [ObjectsActionsTypes.addPopularContextKeyValues]: setContextKeyValues,
};

type IConditionObjectsResultPayload =
    | Record<string, unknown>
    | IConditionObjectsPayload
    | IConditionObjectsSearchHubPayload
    | IConditionObjectsContextKeysPayload
    | IContextKeyPair;

export const conditionsObjectsReducer = (
    state: IConditionObjectsState = initialConditionObjectsState,
    action?: IReduxAction<IConditionObjectsResultPayload>,
) => (ConditionObjectsReducer[action.type] !== undefined ? ConditionObjectsReducer[action.type](state, action) : state);
