import {RouteObject} from '@core/routes';

export const routes: RouteObject[] = [
    {
        path: '/invites',
        lazy: async () => {
            const {InvitesPage} = await import('../pages/invites/InvitesPage');
            return {Component: InvitesPage};
        },
    },
    {
        path: '/unable-to-load',
        lazy: async () => {
            const {UnableToLoadPage} = await import('../pages/unable-to-load/UnableToLoadPage');
            return {Component: UnableToLoadPage};
        },
    },
    {
        path: '/no-organizations',
        lazy: async () => {
            const {NoOrganizationPage} = await import('../pages/no-organization/NoOrganizationPage');
            return {Component: NoOrganizationPage};
        },
    },
    {
        path: '/:orgId/insufficient-privileges',
        lazy: async () => {
            const {InsufficientPrivilegesPage} = await import(
                '../pages/insufficient-privileges/InsufficientPrivilegesPage'
            );
            return {Component: InsufficientPrivilegesPage};
        },
    },
];
