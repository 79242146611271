import {ValidationError} from '@coveord/jsadmin-common';
import _ from 'underscore';

import {CalculatedMetricFormats} from './CalculatedMetricFormats';
import {CalculatedMetricOperators} from './CalculatedMetricOperators';
import {CalculatedMetricType} from './CalculatedMetricType';

export interface EditCalculatedMetricState {
    id: string;
    operator: string;
    title: string;
    format: string;
    editMetricA: EditCalculatedMetricPickerState;
    editMetricB: EditCalculatedMetricPickerState;
    errors: ValidationError[];
}

export interface EditCalculatedMetricPickerState {
    isOpen?: boolean;
    applied: EditCalculatedMetricPickerEditState;
    edit: EditCalculatedMetricPickerEditState;
}

export interface EditCalculatedMetricPickerEditState {
    type: string;
    value: string;
}

export const EditCalculatedMetricPickerDefaultState: EditCalculatedMetricPickerState = {
    isOpen: false,
    applied: {
        type: CalculatedMetricType.Metric,
        value: '',
    },
    edit: {
        type: CalculatedMetricType.Metric,
        value: '',
    },
};

export const EditCalculatedMetricDefaultState: EditCalculatedMetricState = {
    id: null,
    operator: CalculatedMetricOperators.Add.symbol,
    title: '',
    format: CalculatedMetricFormats.Default,
    editMetricA: EditCalculatedMetricPickerDefaultState,
    editMetricB: EditCalculatedMetricPickerDefaultState,
    errors: null,
};

export const getCalculatedMetricEditState = (metric: string | number) => {
    const isNumber = _.isNumber(metric);
    const state = {
        type: isNumber ? CalculatedMetricType.Constant : CalculatedMetricType.Metric,
        value: metric,
    };
    return _.extend({}, EditCalculatedMetricPickerDefaultState, {
        applied: _.clone(state),
        edit: _.clone(state),
    });
};
