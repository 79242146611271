import {commonLocales, CommonLocalesKeys} from '@coveord/jsadmin-common';
import {range, scale, select} from 'd3';
import _ from 'underscore';
import * as s from 'underscore.string';

declare const c3: any;

export const getBaseChartColorPattern = (totalLength: number = 0, indexOfOther: number = -1) => {
    const hasOther = indexOfOther !== -1;
    const numOfColors = hasOther ? totalLength - 1 : totalLength;

    let colorPattern: string[] = ['#0d80ef', '#4ed6ff', '#26e8cc', '#05c3a2', '#028f7b', '#94a9bf'];
    if (numOfColors > 1) {
        colorPattern = ['#004990', ...colorPattern];
    }
    if (numOfColors > 3) {
        colorPattern = ['#00284b', ...colorPattern];
    }
    if (hasOther) {
        colorPattern[indexOfOther] = '#e4eaed';
    }

    return colorPattern;
};

export const defaultColorScaleColors: string[] = ['#8fc9ff', '#0d80ef', '#004990', '#00284b'];
export const defaultNormalizedColorScaleColors: string[] = ['#0d80ef', '#00284b'];

export const makeColorScale = (min: number, max: number, ascending = true, colorRange = defaultColorScaleColors) => {
    if (colorRange.length < 1) {
        throw new Error('Invalid color range');
    }

    if (max - min <= 0) {
        return (val: number) => colorRange[colorRange.length - 1];
    }

    const incrementor = (max - min) / (colorRange.length - 1);
    const domain = range(min, max + incrementor, incrementor);
    if (!ascending) {
        domain.reverse();
    }

    return scale.linear<string, string>().domain(domain).range(colorRange).clamp(true);
};

export const makeNormalizedColorScale = (
    min: number,
    max: number,
    average: number,
    ascending = true,
    colorRange = defaultColorScaleColors,
    normalizedColorRange = defaultNormalizedColorScaleColors,
) => makeColorScale(min, max, ascending, checkWithinThreshold(min, max, average) ? colorRange : normalizedColorRange);

export const checkWithinThreshold = (min: number, max: number, average: number): boolean => {
    const THRESHOLD = 0.15; // within threshold is above this value
    return (max - min) / average > THRESHOLD;
};

export const mapDimensionToValues = (dimensions: any[]) =>
    _.map(_.clone(dimensions), (value: string | boolean) => {
        if (value === null) {
            return BaseChart.CORNER_CASE_NULL;
        } else if (value === '') {
            return BaseChart.CORNER_CASE_EMPTY;
        } else if (_.isBoolean(value)) {
            return value.toString();
        } else {
            return value;
        }
    });

export class BaseChart {
    chart: any;
    settings: any;

    protected load: any[];
    private names: {[id: string]: string};

    static CORNER_CASE_PREPEND = 'data-id-';
    static CORNER_CASE_EMPTY = BaseChart.CORNER_CASE_PREPEND + '';
    static CORNER_CASE_NULL = BaseChart.CORNER_CASE_PREPEND + 'null';
    static CORNER_CASE_OTHER = BaseChart.CORNER_CASE_PREPEND + 'other';
    static LEGEND_MAX_CHARS = 15;
    private static HIDE_CLASS = 'chart-invisible';
    private static TRANSITION_DURATION = 500;

    constructor(
        private cssSelector: string,
        protected data: any,
    ) {
        this.settings = this.getDefaultSettings();
        this.names = this.getSeriesNames();
    }

    getDefaultSettings() {
        const indexOfOther = _.findIndex(
            this.data.columns,
            (column: [string, number]) => column[0] === BaseChart.CORNER_CASE_OTHER,
        );
        const settings = {
            bindto: this.cssSelector,
            data: _.extend(
                {
                    names: this.getSeriesNamesForLegend(),
                    type: 'area-spline',
                },
                _.clone(this.data),
            ),
            color: {
                pattern: getBaseChartColorPattern(this.data.columns && this.data.columns.length, indexOfOther),
            },
            legend: {
                show: false,
            },
            transition: {
                duration: 500,
            },
            tooltip: {
                format: {
                    name: (name, ratio, id, index) => this.names[id],
                },
                // Reorder the content of the tooltip to respect the order of the data
                contents: (d, defaultTitleFormat, defaultValueFormat, color) => {
                    const c3Internal: any = this.chart.internal;
                    const series = this.getSeriesIds();
                    const ids = _.map(series, (serie: string) => _.findWhere(d, {id: serie}));
                    return c3Internal.getTooltipContent(ids, defaultTitleFormat, defaultValueFormat, color);
                },
            },
        };
        return settings;
    }

    show(animate = false, newSettings = {}) {
        const settings = _.extend(_.clone(this.settings), newSettings);
        this.load = _.clone(this.data.columns);
        const deferred = ($.Deferred as any)();

        $(this.cssSelector).addClass(BaseChart.HIDE_CLASS);
        if (animate) {
            settings.data.columns = this.getSeriesWithZeros(settings.data.columns);
            settings.oninit = () => {
                _.defer(() => {
                    this.showSeries();
                    deferred.resolve();
                });
            };
            this.chart = c3.generate(settings);
        } else {
            this.chart = c3.generate(settings);
            this.chart.show();
            deferred.resolve();
        }

        const selected = select(this.cssSelector);
        selected
            .transition()
            .delay(BaseChart.TRANSITION_DURATION)
            .duration(BaseChart.TRANSITION_DURATION)
            .style('opacity', 1);

        return deferred;
    }

    getSeriesWithZeros(data) {
        return _.map(data, (serie: any[]) => {
            if (_.isArray(serie)) {
                return _.map(serie, (value) => (_.isNumber(value) ? 0 : value));
            } else {
                return serie;
            }
        });
    }

    setSeries(values: {[key: string]: boolean}) {
        const seriesToLoad = _.compact(_.map(values, (v, k) => (v === true ? k : null)));
        this.load = _.map(seriesToLoad, (l) => this.getSerie(l));
        this.showSeries();
    }

    getSerie(metric: string) {
        return _.find(this.data.columns, (column: any[]) => column[0] === metric);
    }

    getSeriesIds() {
        return _.map(this.data.columns, (column: any[]) => column[0]);
    }

    getSeriesNames() {
        const names: {[id: string]: string} = {};
        _.each(this.getSeriesIds(), (id) => {
            names[id] = BaseChart.getSerieName(id);
        });
        return names;
    }

    getSeriesNamesForLegend() {
        const names: {[id: string]: string} = {};
        _.each(this.getSeriesIds(), (id) => {
            names[id] = s.truncate(BaseChart.getSerieName(id), BaseChart.LEGEND_MAX_CHARS);
        });
        return names;
    }

    showSeries() {
        this.chart.hide();
        this.chart.show(this.load.map((column: any[]) => column[0]));
    }

    focus(id: string | string[]) {
        this.defocus();

        if (focus) {
            this.chart.focus(id);
        }
    }

    defocus() {
        this.chart.revert();
    }

    focusData(valueToFocus: string[]) {
        const values = this.getSeriesIds();
        valueToFocus = mapDimensionToValues(valueToFocus);
        const focus = valueToFocus.length > 0 && _.some(valueToFocus, (value: string) => _.contains(values, value));
        if (focus) {
            this.chart.hide(_.difference(values, valueToFocus), {withLegend: true});
        } else if (valueToFocus.length === 0) {
            this.chart.show();
        }
    }

    resize() {
        this.chart.resize();
    }

    static getSerieName(serie: string): string {
        const titles = {
            [BaseChart.CORNER_CASE_EMPTY]: 'empty',
            [BaseChart.CORNER_CASE_NULL]: 'notApplicable',
            [BaseChart.CORNER_CASE_OTHER]: 'other',
            true: 'stringTrue',
            false: 'stringFalse',
        };

        return (titles[serie] && commonLocales.format(titles[serie] as CommonLocalesKeys)) || serie;
    }
}
