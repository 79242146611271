import {Reducer} from 'redux';
import {IReduxAction} from '@coveord/plasma-react';
import {ISearchInterfaceConfigurationResponse} from '@core/api';
import {ISearchInterfaceState} from './models/SearchInterfaceState';
import {SearchInterfaceActionsTypes} from './SearchInterfacesConstants';

export const SearchInterfacesReducer: Reducer<ISearchInterfaceState, IReduxAction> = (state, action) => {
    if (!state) {
        return {configs: []};
    }
    switch (action.type) {
        case SearchInterfaceActionsTypes.fetch:
            const configs = (action.payload?.items as ISearchInterfaceConfigurationResponse[]) ?? [];
            return {...state, configs};
        default:
            return state;
    }
};
