import {ObjectReducerGenerator} from '@core/store';
import {CardSelectGroupActionTypes} from './CardSelectGroupActions';

export type ICardSelectGroupsState = {[id: string]: ICardSelectGroupState};

export interface ICardSelectGroupState<T = unknown> {
    selectedValue: T;
}

const initialState: ICardSelectGroupsState = {};

export const cardSelectGroupReducer = ObjectReducerGenerator<ICardSelectGroupsState>(
    CardSelectGroupActionTypes.set,
    initialState,
);
