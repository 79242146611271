import {PlatformPaths} from '@configurations/platform';
import {RouteObject} from '@core/routes';

export const routes: RouteObject[] = [
    {
        path: `${PlatformPaths.Sources.fullPath}/*`,
        lazy: async () => {
            const {SourcesPage} = await import('../sources/SourcesPage');
            return {Component: SourcesPage};
        },
    },
    {
        path: '/:orgId/content/sources/edit-react/*',
        lazy: async () => {
            const {EditReactSourcePage} = await import('../sources/edit/EditReactSourcePage');
            return {Component: EditReactSourcePage};
        },
    },
    {
        path: '/:orgId/content/sources/:sourceId/metadata/*',
        lazy: async () => {
            const {MetadataPage} = await import('../sources/metadata/MetadataPage');
            return {Component: MetadataPage};
        },
    },
    {
        path: '/:orgId/content/fields/*',
        lazy: async () => {
            const {FieldsPage} = await import('../fields/FieldsPage');
            return {Component: FieldsPage};
        },
    },
    {
        path: '/:orgId/content/fields/new/*',
        lazy: async () => {
            const {EditFieldPage} = await import('../fields/edit/EditFieldPage');
            return {Component: EditFieldPage};
        },
    },
    {
        path: '/:orgId/content/fields/edit/:fieldName/*',
        lazy: async () => {
            const {EditFieldPage} = await import('../fields/edit/EditFieldPage');
            return {Component: EditFieldPage};
        },
    },
    {
        path: `${PlatformPaths.Extensions.fullPath}/*`,
        lazy: async () => {
            const {ExtensionsPage} = await import('../indexing-pipeline-extensions/ExtensionsPage');
            return {Component: ExtensionsPage};
        },
    },
    {
        path: `${PlatformPaths.SecurityIdentitiesListing.fullPath}/*`,
        lazy: async () => {
            const {SecurityIdentitiesPage} = await import('../security-cache/SecurityIdentitiesPage');
            return {Component: SecurityIdentitiesPage};
        },
    },
    {
        path: '/:orgId/content/security-identities/explore-identities/:providerId/*',
        lazy: async () => {
            const {ExploreIdentitiesPage} = await import('../security-cache/identities/ExploreIdentitiesPage');
            return {Component: ExploreIdentitiesPage};
        },
    },
    {
        path: '/:orgId/content/security-identities/relationships/:encodedModel/*',
        lazy: async () => {
            const {RelationshipBrowserPage} = await import(
                '../security-cache/browser/SecurityIdentityRelationshipBrowserPage'
            );
            return {Component: RelationshipBrowserPage};
        },
    },
    {
        path: `${PlatformPaths.Ipx.fullPath}/*`,
        lazy: async () => {
            const {MantineOrOldIPXListingPage} = await import('../in-app-widget/MantineOrOldIPXListingPage');
            return {Component: MantineOrOldIPXListingPage};
        },
    },
    {
        path: '/:orgId/search/in-app-widgets/create/*',
        lazy: async () => {
            const {MantineOrOldCreateInAppWidgetPage} = await import(
                '../in-app-widget/MantineOrOldCreateInAppWidgetPage'
            );
            return {Component: MantineOrOldCreateInAppWidgetPage};
        },
    },
    {
        path: '/:orgId/search/in-app-widgets/edit/:name/*',
        lazy: async () => {
            const {MantineOrOldEditInAppWidgetPage} = await import('../in-app-widget/MantineOrOldEditInAppWidgetPage');
            return {Component: MantineOrOldEditInAppWidgetPage};
        },
    },
    {
        path: `${PlatformPaths.SearchPageListingPage.fullPath}/*`,
        lazy: async () => {
            const {MantineOrOldSearchPageListingPage} = await import(
                '../search-pages/MantineOrOldSearchPageListingPage'
            );
            return {Component: MantineOrOldSearchPageListingPage};
        },
    },
    {
        path: '/:orgId/organization/groups/*',
        lazy: async () => {
            const {GroupsPage} = await import('../groups/GroupsPage');
            return {Component: GroupsPage};
        },
    },
    {
        path: '/:orgId/organization/groups/new/*',
        lazy: async () => {
            const {AddGroupPage} = await import('../groups/edit-group/AddGroupPage');
            return {Component: AddGroupPage};
        },
    },
    {
        path: '/:orgId/organization/groups/edit/:groupId/*',
        lazy: async () => {
            const {EditGroupPage} = await import('../groups/edit-group/EditGroupPage');
            return {Component: EditGroupPage};
        },
    },
    {
        path: '/:orgId/organization/groups/view/:groupId/*',
        lazy: async () => {
            const {EditGroupPage} = await import('../groups/edit-group/EditGroupPage');
            return {Component: EditGroupPage};
        },
    },
    {
        path: '/:orgId/organization/members/*',
        lazy: async () => {
            const {MembersPage} = await import('../organization-members/MembersPage');
            return {Component: MembersPage};
        },
    },
    {
        path: '/:orgId/organization/notifications/*',
        lazy: async () => {
            const {NotificationsPage} = await import('../notifications/NotificationsPage');
            return {Component: NotificationsPage};
        },
    },
    {
        path: '/:orgId/user/notifications/*',
        lazy: async () => {
            const {NotificationsPage} = await import('../notifications/NotificationsPage');
            return {Component: NotificationsPage};
        },
    },
    {
        path: '/:orgId/organization/temporary-access/*',
        lazy: async () => {
            const {TemporaryAccessPage} = await import('../temporary-access/TemporaryAccessPage');
            return {Component: TemporaryAccessPage};
        },
    },
    {
        path: '/:orgId/settings/*',
        lazy: async () => {
            const {SettingsPage} = await import('../settings/SettingsPage');
            return {Component: SettingsPage};
        },
    },
    {
        path: '/:orgId/organization/resource-snapshots/*',
        lazy: async () => {
            const {ResourcesSnapshotPage} = await import('../resource-snapshots/ResourceSnapshotsPage');
            return {Component: ResourcesSnapshotPage};
        },
    },
    {
        path: '/:orgId/organization/resource-snapshots/:id/synchronization/*',
        lazy: async () => {
            const {SynchronizationModalPage} = await import(
                '../resource-snapshots/synchronization-modal/SynchronizationModalPage'
            );
            return {Component: SynchronizationModalPage};
        },
    },
    {
        path: '/:orgId/updates/list/*',
        lazy: async () => {
            const {CriticalUpdatesPage} = await import('../critical-updates/components/CriticalUpdatesPage');
            return {Component: CriticalUpdatesPage};
        },
    },
    {
        path: '/:orgId/coveo-only/cluster/*',
        lazy: async () => {
            const {ClusterPage} = await import('../cluster/ClusterPage');
            return {Component: ClusterPage};
        },
    },
    {
        path: '/:orgId/coveo-only/organizations/*',
        lazy: async () => {
            const {OrganizationsPage} = await import('../organizations/OrganizationsPage');
            return {Component: OrganizationsPage};
        },
    },
    {
        path: '/:orgId/coveo-only/organizations/restore/*',
        lazy: async () => {
            const {RestoreOrganizationPage} = await import('../organizations/restore/RestoreOrganizationPage');
            return {Component: RestoreOrganizationPage};
        },
    },
    {
        path: '/:orgId/coveo-only/global-groups/*',
        lazy: async () => {
            const {GlobalGroupsPage} = await import('../global-groups/GlobalGroupsPage');
            return {Component: GlobalGroupsPage};
        },
    },
    {
        path: '/:orgId/create-test-org/*',
        lazy: async () => {
            const {AddTestOrganizationPage} = await import('../organizations/add/AddTestOrganizationPage');
            return {Component: AddTestOrganizationPage};
        },
    },
];
