import {
    CaseClassificationAxonConfigurationModel,
    CaseClassificationConfiguration,
    CaseClassificationSomeConfigurationModel,
    CaseClassificationStrategies,
    MLModel,
    ModelActivenessState,
    ModelStatus,
} from '@core/api';
import {RequestsSelectors} from '@coveord/jsadmin-common';
import {
    IItemBoxProps,
    InputSelectors,
    MultilineBoxSelectors,
    SelectSelector,
    ValidationSelectors,
} from '@coveord/plasma-react';
import {createSelector} from 'reselect';
import {ServiceState} from '../../../ServiceState';
import {CaseAssistConfigurationConstants} from '../CaseAssistConfigurationConstants';
import {ModalSelectSelectors} from '../modalSelect/ModalSelectSelectors';

const defaultStrategy = CaseClassificationStrategies.Some;

const getSavedConfiguration = (state: ServiceState) => {
    const configs = state.caseAssistManager.editCaseAssistConfig?.data?.classificationConfigurations ?? [];
    return configs.length > 0 ? (configs[0] as CaseClassificationConfiguration) : null;
};

const getSavedSomeConfiguration = createSelector(getSavedConfiguration, (config) =>
    config?.strategy === CaseClassificationStrategies.Some
        ? (config as CaseClassificationSomeConfigurationModel)
        : undefined,
);

const getSavedAxonConfiguration = createSelector(getSavedConfiguration, (config) =>
    config?.strategy === CaseClassificationStrategies.Axon
        ? (config as CaseClassificationAxonConfigurationModel)
        : undefined,
);

const hasConfig = createSelector(getSavedConfiguration, (config) => Boolean(config));

const getStrategySelectValue = (state: ServiceState) =>
    ModalSelectSelectors.getValue(state, {
        id: CaseAssistConfigurationConstants.ComponentIds.CaseClassification.StrategyModalSelect,
    });

const getSelectedStrategy = createSelector(
    getSavedConfiguration,
    getStrategySelectValue,
    (config, selectedValue) =>
        (selectedValue?.length ? selectedValue : (config?.strategy ?? defaultStrategy)) as CaseClassificationStrategies,
);

const shouldShowBlankSlate = (state: ServiceState) =>
    !state.caseAssistManager.editCaseAssistConfig.caseClassificationTab.withEmptyStateTab.configuringNew &&
    !hasConfig(state);

const getFilterValue = (state: ServiceState) =>
    InputSelectors.getValue(state, {
        id: CaseAssistConfigurationConstants.ComponentIds.CaseClassification.FilterInput,
    });

const getFieldsValues = (state: ServiceState) =>
    MultilineBoxSelectors.getIds(state, {
        id: CaseAssistConfigurationConstants.ComponentIds.CaseClassification.FieldsToPredictInput,
    })
        .map((inputConnectedId) =>
            InputSelectors.getValue(state, {
                id: inputConnectedId,
            }),
        )
        .filter((inputValue) => Boolean(inputValue))
        .map((fieldName) => ({name: fieldName}));

const getEditedSomeConfiguration = createSelector(
    getSelectedStrategy,
    getFilterValue,
    getFieldsValues,
    (strategy, filter, fieldsToPredict) => (filter ? {strategy, filter, fieldsToPredict} : undefined),
);

const isFetchingModels = RequestsSelectors.createLoadingSelector(['CASE_CLASSIFICATION_FETCH_AXON_MODELS']);

const getAxonModels = createSelector(
    (state: ServiceState) => state.caseAssistManager.editCaseAssistConfig.caseClassificationTab.axonModels,
    getSavedAxonConfiguration,
    (axonModels, savedConfig) =>
        axonModels.map((model) => ({
            value: model.id ?? '',
            displayValue: model.modelDisplayName ?? model.id,
            selected: model.id === savedConfig?.modelId,
        })) as IItemBoxProps[],
);

const getSelectedModelId = (state: ServiceState) =>
    SelectSelector.getListBoxSelected(state, {
        id: CaseAssistConfigurationConstants.ComponentIds.CaseClassification.ModelSelect,
    })[0];

const getSelectedModel = (state: ServiceState, canEditConfig: boolean) => {
    if (getSelectedStrategy(state) !== CaseClassificationStrategies.Axon) {
        return undefined;
    }
    if (canEditConfig) {
        return state.caseAssistManager.editCaseAssistConfig.caseClassificationTab.axonModels.find(
            (model) => model.id === getSelectedModelId(state),
        );
    }
    return state.caseAssistManager.editCaseAssistConfig.caseClassificationTab.axonModels[0];
};

const getSelectedModelStatus = (state: ServiceState, canEditConfig: boolean) =>
    getSelectedModel(state, canEditConfig)?.status ?? undefined;

const getSelectedModelActivenessState = (state: ServiceState, canEditConfig: boolean) =>
    getSelectedModel(state, canEditConfig)?.modelActivenessState ?? undefined;

const hasBuildingStatus = (model: MLModel) => {
    const buildingStatuses = [
        ModelStatus.IN_QUEUE,
        ModelStatus.IN_QUEUE_UPDATING,
        ModelStatus.BUILDING,
        ModelStatus.UPDATING,
        ModelStatus.ONLINE,
        ModelStatus.ONLINE_DEGRADED,
    ];
    return buildingStatuses.includes(model?.status);
};

const isModelActive = (model: MLModel) => model.modelActivenessState === ModelActivenessState.ACTIVE;

const getIsSelectedModelBuilding = (state: ServiceState, canEditConfig: boolean): boolean => {
    const model = getSelectedModel(state, canEditConfig);
    if (!model) {
        return false;
    }
    return hasBuildingStatus(model) && !isModelActive(model);
};

const getEditedAxonConfiguration = createSelector(getSelectedModelId, getSelectedStrategy, (modelId, strategy) =>
    modelId
        ? {
              strategy,
              modelId,
          }
        : undefined,
);

const getEditedConfiguration = createSelector(
    getSelectedStrategy,
    getEditedSomeConfiguration,
    getEditedAxonConfiguration,
    (strategy, editedSomeConfiguration, editedAxonConfiguration) => {
        switch (strategy) {
            case CaseClassificationStrategies.Some:
                return editedSomeConfiguration;
            case CaseClassificationStrategies.Axon:
                return editedAxonConfiguration;
            default:
                return undefined;
        }
    },
);

const getValidationIds = () => [
    CaseAssistConfigurationConstants.ComponentIds.CaseClassification.FilterInput,
    CaseAssistConfigurationConstants.ComponentIds.CaseClassification.FieldsToPredictInput,
    CaseAssistConfigurationConstants.ComponentIds.CaseClassification.StrategyModalSelect,
    CaseAssistConfigurationConstants.ComponentIds.CaseClassification.ModelSelect,
];

const getConfiguringNew = (state: ServiceState): boolean =>
    state.caseAssistManager.editCaseAssistConfig.caseClassificationTab.withEmptyStateTab.configuringNew;

const isValidating = (state: ServiceState): boolean => isValidatingQuerySyntax(state) || isValidatingFieldName(state);

const isValidatingQuerySyntax = (state: ServiceState): boolean =>
    state.caseAssistManager?.editCaseAssistConfig.caseClassificationTab.withQuerySyntaxValidation.validatingQuerySyntax
        .length > 0;

const isValidatingFieldName = (state: ServiceState): boolean => {
    const componentId = CaseAssistConfigurationConstants.ComponentIds.CaseClassification.FieldsToPredictInput;
    const componentState =
        state.caseAssistManager?.editCaseAssistConfig.caseClassificationTab.fieldsToPredict[componentId];
    const validating = Object.keys(componentState?.inputs ?? {}).some(
        (inputId) => componentState.inputs[inputId].validating,
    );

    return validating;
};

const getCurrentConfiguration = (canEditConfig: boolean) =>
    createSelector(getSavedConfiguration, getEditedConfiguration, (savedConfiguration, editedconfiguration) =>
        canEditConfig ? editedconfiguration : savedConfiguration,
    );

const getIsConfigError = (state: ServiceState, canEditConfig: boolean) => {
    const config = getCurrentConfiguration(canEditConfig)(state);
    switch (config?.strategy) {
        case CaseClassificationStrategies.Some:
            const someConfig = config as CaseClassificationSomeConfigurationModel;
            return (
                someConfig?.filter?.length &&
                !_.isEmpty(someConfig?.fieldsToPredict) &&
                ValidationSelectors.isInError(getValidationIds())(state)
            );
        case CaseClassificationStrategies.Axon:
            const axonConfig = config as CaseClassificationAxonConfigurationModel;
            return !axonConfig.modelId.length;
        default:
            return true;
    }
};

const isValidConfiguration = (state: ServiceState, canEditConfig: boolean) =>
    getCurrentConfiguration(canEditConfig)(state) &&
    !isValidating(state) &&
    !ValidationSelectors.isInError(getValidationIds())(state);

export const CaseClassificationSelectors = {
    hasConfig,
    getSelectedStrategy,
    isFetchingModels,
    getAxonModels,
    getIsSelectedModelBuilding,
    getSelectedModelStatus,
    getSelectedModelActivenessState,
    shouldShowBlankSlate,
    getEditedSomeConfiguration,
    getEditedAxonConfiguration,
    getEditedConfiguration,
    getSavedSomeConfiguration,
    getSavedAxonConfiguration,
    getSavedConfiguration,
    getCurrentConfiguration,
    getValidationIds,
    getConfiguringNew,
    isValidating,
    isValidConfiguration,
    getIsConfigError,
};
