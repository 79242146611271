import {
    ListProjectParams,
    PageModel,
    Platform,
    ProjectModel,
    UseQueryResult,
    keepPreviousData,
    useQuery,
} from '@core/api';
import {PlatformProjectHelpers} from '@core/projects';

/**
 * Returns all projects present in the organization.
 *
 * @param {ListProjectParams} requestParams Parameters that precise a filter, a sorting criteria and/or a sorting order.
 * @returns {UseQueryResult<PageModel<ProjectModel>>} List of existing projects in the organization.
 */
export const useGetAllProjects = (requestParams?: ListProjectParams): UseQueryResult<PageModel<ProjectModel>> =>
    useQuery({
        placeholderData: keepPreviousData,
        queryKey: PlatformProjectHelpers.queryKeys.list(requestParams),
        queryFn: () => Platform.project.list(requestParams),
        retry: false,
        refetchInterval: 10 * 1000,
    });
