import _ from 'underscore';

export interface TooltipOptions {
    container?: string;
    selector?: string;
    placement?: string;
    trigger?: string;
    template?: string;
    tooltipClass?: string;
    html?: boolean;
}

export class TooltipBehavior extends Marionette.Behavior {
    private tooltipOptions: TooltipOptions = {trigger: 'hover'};

    constructor(options: TooltipOptions, view) {
        super(options, view);

        _.extend(this.tooltipOptions, options);
    }

    destroy() {
        this.$('[title]')?.tooltip('destroy');
        this.$el.filter('[title]').tooltip('destroy');
    }

    onDomRefresh() {
        this.$('[title]')?.tooltip(this.tooltipOptions);
        this.addCustomClassToTooltip(this.$('[title]'));

        // If there's a title on the element itself.
        this.$el.filter('[title]').tooltip(this.tooltipOptions);
        this.addCustomClassToTooltip(this.$el.filter('[title]'));
    }

    onRender() {
        // remove the bootstrap element in the dom if the tooltip is currently active.
        // if we not remove it, the tooltip element stay on the page after the rendering
        $('.tooltip:not(.react-vapor-tooltip)').remove();
    }

    private addCustomClassToTooltip($el: JQuery) {
        if (this.options.tooltipClass) {
            const tooltip = $el.tooltip(this.tooltipOptions).data('tooltip');
            if (tooltip) {
                this.tooltipOptions.template = $(tooltip.options.template)
                    .addClass(this.options.tooltipClass)
                    .get(0).outerHTML;
                // Destroy the tooltip then spawn it again to have the new template
                $el.tooltip('destroy').tooltip(this.tooltipOptions);
            }
        }
    }
}
