import {PrivilegeModel} from '@core/api';
import {PrivilegesAccessOptions, PrivilegesConstants, PrivilegeTypes, PrivilegeUtils} from '@core/user';
import _ from 'underscore';

export interface AccessLevelItem {
    /**
     * Value of the option in the access level dropdown.
     */
    value: string;
    /**
     * Privileges types that are sent to the server when the option is selected.
     */
    givesPrivilegeTypes?: string[];
    /**
     * Privileges types that must be returned by the server for the option to be set selected.
     *
     * * Use string when privilege X is required and only X.
     * * Use string[] when at least privilege X is required.
     */
    requiredPrivilegeTypes?: string[] | string;
    /**
     * When more than one option is plausible, the weight says which one should be selected (higher is more likely to be selected)
     */
    weight: number;
}

export interface AccessLevelListAttributes {
    nullLevel: string;
    items: AccessLevelItem[];
}

export class AccessLevelList {
    constructor(
        private attributes: AccessLevelListAttributes,
        private allowedPrivileges: PrivilegeModel[] = [],
        private possiblePrivileges: PrivilegeModel[] = [],
    ) {}

    get nullLevel(): string {
        return this.attributes.nullLevel;
    }

    get availableValues(): string[] {
        return [...this.getPossibleAccessLevelsForPrivileges(), this.attributes.nullLevel];
    }

    getEnabledValues(userPrivileges = []): string[] {
        return [
            this.attributes.nullLevel,
            ...this.getRelevantAccessLevelsForPrivileges(
                this.allowedPrivileges,
                _.contains(this.getRelevantAccessLevelsForPrivileges(userPrivileges), PrivilegesAccessOptions.Custom),
            ),
        ];
    }

    getSelectedLevelForPrivileges(privileges: PrivilegeModel[] = []): string {
        return this.getRelevantAccessLevelsForPrivileges(privileges)[0] || this.attributes.nullLevel;
    }

    getPrivilegesForLevel(
        basePrivilege: PrivilegeModel,
        value: string,
        includeCreatePrivilegeIfAvailable?: boolean,
    ): PrivilegeModel[] {
        if (!basePrivilege || _.isEmpty(value) || value === this.attributes.nullLevel) {
            return [];
        }

        const associatedPrivilegesTypes = this.getPrivilegesTypesForLevel(value, includeCreatePrivilegeIfAvailable);

        return !_.isEmpty(associatedPrivilegesTypes)
            ? associatedPrivilegesTypes.map((type: string) => ({
                  ...basePrivilege,
                  type,
                  targetId: PrivilegesConstants.allTargetIds,
              }))
            : [basePrivilege];
    }

    private getRelevantAccessLevelsForPrivileges(
        privileges: PrivilegeModel[],
        includeCustomWhenEditAll = true,
    ): string[] {
        const types: string[] = _.chain(privileges)
            .reject(PrivilegeUtils.isGranularPrivilege)
            .map(PrivilegeUtils.parseBinaryPrivileges)
            .pluck('type')
            .value();

        return [
            PrivilegeUtils.hasValidGranularPrivileges(privileges) && PrivilegesAccessOptions.Custom,
            ..._.chain(this.attributes.items)
                .filter(
                    (item: AccessLevelItem) =>
                        (_.isArray(item.requiredPrivilegeTypes) &&
                            _.difference(item.requiredPrivilegeTypes, types).length === 0) ||
                        (_.isString(item.requiredPrivilegeTypes) && _.contains(types, item.requiredPrivilegeTypes)) ||
                        (_.isEmpty(item.requiredPrivilegeTypes) && _.contains(types, PrivilegeTypes.Allow)),
                )
                .reject(
                    (item: AccessLevelItem) =>
                        item.value === PrivilegesAccessOptions.Custom && !includeCustomWhenEditAll,
                )
                .sortBy('weight')
                .pluck('value')
                .value()
                .reverse(),
        ].filter(Boolean);
    }

    private getPossibleAccessLevelsForPrivileges() {
        return _.chain(this.attributes.items)
            .filter(
                (item: AccessLevelItem) =>
                    _.isEmpty(this.possiblePrivileges) ||
                    _.intersection(_.flatten([item.requiredPrivilegeTypes]), _.pluck(this.possiblePrivileges, 'type'))
                        .length > 0,
            )
            .sortBy('weight')
            .pluck('value')
            .value()
            .reverse();
    }

    private getPrivilegesTypesForLevel(value: string, includeCreatePrivilegeIfAvailable?: boolean): string[] {
        const accessLevel: AccessLevelItem = _.findWhere(this.attributes.items, {value});
        const types = _.pluck(this.possiblePrivileges, 'type');
        return (
            (accessLevel &&
                _.filter(
                    accessLevel.givesPrivilegeTypes,
                    (type: string) =>
                        _.isEmpty(this.possiblePrivileges) ||
                        (_.contains(types, type) &&
                            (type !== PrivilegeTypes.Create || !!includeCreatePrivilegeIfAvailable)),
                )) ||
            []
        );
    }
}
