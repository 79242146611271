import {createSelector} from 'reselect';
import _ from 'underscore';

import {CommonState} from '../CommonState';
import {RequestErrorPayload} from './RequestsActions';
import {RequestsState} from './RequestsReducer';

export const requestsInitialState: RequestsState = {
    loading: {},
    error: {},
    success: {},
};

type RequestLoadingIdentifier = string | {requestName: string; isLoadingWhenUnknown: boolean};

const getRequestsState = (state: CommonState): RequestsState => state.requests || requestsInitialState;

const createLoadingSelector = (requestsNames: RequestLoadingIdentifier[]) =>
    createSelector(getRequestsState, (requests: RequestsState): boolean =>
        _.some(requestsNames, (identifier: RequestLoadingIdentifier) => {
            if (_.isString(identifier)) {
                const isLoading = requests.loading[identifier];
                return _.isUndefined(isLoading) || isLoading;
            } else {
                const isLoading = requests.loading[identifier.requestName];
                return (!!identifier.isLoadingWhenUnknown && _.isUndefined(isLoading)) || isLoading;
            }
        }),
    );

const createErrorMessageSelector = (requestsNames: string[]) =>
    createSelector(
        getRequestsState,
        (requests: RequestsState): RequestErrorPayload[] =>
            _.chain(requestsNames)
                .map((requestName: string) => requests.error[requestName])
                .compact()
                .value() || [],
    );

const createLastSuccessSelector = (requestsNames: string[]) =>
    createSelector(
        getRequestsState,
        (requests: RequestsState): Date =>
            _.chain(requestsNames)
                .map((requestName: string) => requests.success[requestName])
                .compact()
                .sortBy((lastSuccess: Date) => lastSuccess.getTime())
                .reverse()
                .first()
                .value() || (null as any),
    );

export const RequestsSelectors = {
    createLoadingSelector,
    createErrorMessageSelector,
    createLastSuccessSelector,
};
