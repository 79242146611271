import {StatementsFeature} from '@core/api';
import {PathGenerator} from '@core/routes';
import {PlatformPaths} from '@configurations/platform';

import {StatementsName} from '../base/BaseStatements';

export interface StatementContext {
    readOnly: boolean;
}

const RedirectPerFeature: Partial<Record<StatementsFeature, PathGenerator>> = {
    [StatementsFeature.RankingWeight]: PlatformPaths.RankingWeights,
    [StatementsFeature.Thesaurus]: PlatformPaths.Synonyms,
    [StatementsFeature.Trigger]: PlatformPaths.Triggers,
    [StatementsFeature.Filter]: PlatformPaths.Filters,
    [StatementsFeature.QueryParamOverride]: PlatformPaths.SearchSettings,
    [StatementsFeature.QuerySuggest]: undefined,
    [StatementsFeature.Ranking]: PlatformPaths.RankingExpressions,
    [StatementsFeature.Recommendation]: undefined,
    [StatementsFeature.Stop]: undefined,
    [StatementsFeature.Top]: undefined,
    [StatementsFeature.TopClicks]: undefined,
    [StatementsFeature.ResultRankings]: PlatformPaths.ResultRanking,
};

export enum MoveDirection {
    Up,
    Down,
}

export const StatementConfigType = {
    UI: 'UI',
    Code: 'Code',
};

const tableRowActions = {
    editAction: 'edit',
    duplicateAction: 'duplicate',
    deleteAction: 'delete',
    moveUpAction: 'moveUp',
    moveDownAction: 'moveDown',
    editCodeAction: 'editCode',
    copyToAction: 'copyTo',
};

export const StatementConstants = {
    statementDescription: 'StatementDescription',
    statementConditionPicker: 'StatementConditionPicker',
    ActionTypes: {
        allStatements: 'PIPELINE_STATEMENTS',
        singleStatements: 'PIPELINE_STATEMENT',
    },
    Requests: {
        fetchAll: 'FETCH_STATEMENTS',
        move: 'MOVE_STATEMENT_POSITION',
        delete: 'DELETE_STATEMENT',
        copy: 'COPY_STATEMENT',
        fetch: 'FETCH_STATEMENT',
        save: 'SAVE_STATEMENT',
        saveWithCode: 'SAVE_STATEMENT_WITH_CODE',
    },
    RedirectPerFeature,
    tableRowActions,
};

export const StatementDocumentationIDs = {
    QueryPipelinesTable: '1791',
    MachineLearningJSON: '3175',
    EditStatementCode: '1854',
    [StatementsName.MachineLearning]: '2816',
    [StatementsName.FeaturedResults]: '3376',
    [StatementsName.RankingExpressions]: '3375',
    [StatementsName.StopWords]: '3406',
    [StatementsName.Thesaurus]: '3405',
    [StatementsName.Filters]: '3410',
    [StatementsName.Triggers]: '3413',
    [StatementsName.RankingWeights]: '3412',
    [StatementsName.QueryParameters]: '3411',
    [StatementsName.ABTest]: '3255',
    [StatementsName.Overview]: 'l1be0007',
    [StatementsName.ResultRanking]: '3234',
    [StatementsName.StatementGroups]: '3283',
    [StatementsName.MachineLearningLearnMore]: '3372',
};
