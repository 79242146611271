import {Platform} from '@core/api';
import {track} from '@core/tracking';
import {AccessSerializableAttributes, RequestsActions} from '@coveord/jsadmin-common';
import {IThunkAction, selectTab, turnOffLoading, validateInputValue} from '@coveord/plasma-react';

import {setKeyValue} from '@core/store';
import {AdminState} from '../application/Reducers';
import {SearchPage} from './models/SearchPageModel';
import {SearchPageVersion} from './models/SearchPageVersion';
import {SearchPagesConstants} from './SearchPagesConstants';
import {SearchPagesSelectors} from './SearchPagesSelectors';
import {SearchPagesValidator} from './SearchPagesValidator';

export const SearchPagesActionsTypes = {
    fetch: 'FETCH_SEARCH_PAGES',
    list: 'LIST_SEARCH_PAGES',
    setPages: 'SET_SEARCH_PAGES',
    fetchSingle: 'FETCH_SEARCH_PAGE',
    destroy: 'DESTROY_SEARCH_PAGE',
    add: 'ADD_SEARCH_PAGE',
    update: 'UPDATE_SEARCH_PAGE',
    setActiveSearchPage: 'SET_SEARCH_PAGE_MODEL',
    setCurrentVersion: 'SET_SEARCH_PAGE_CURRENT_VERSION',
    setAvailableVersions: 'SET_SEARCH_PAGE_AVAILABLE_VERSIONS',
    setNextGenPages: 'SET_NEXT_GEN_SEARCH_PAGES',
};

const fetch =
    (tableId: string, setLoading: boolean): IThunkAction<Promise<void>> =>
    async (dispatch) => {
        const serverCall = async () => {
            const response = await Platform.searchPages.list();
            dispatch(turnOffLoading([tableId]));
            dispatch(setKeyValue(SearchPagesActionsTypes.setPages, response));
        };

        await dispatch(RequestsActions.handle(SearchPagesActionsTypes.fetch, serverCall, setLoading));
    };

const fetchSearchPagesNames = async () => {
    const allSearchPages = await Platform.searchPages.list();
    return allSearchPages.map((searchPage) => searchPage.name);
};

const clearTable = (): IThunkAction<void> => (dispatch) => {
    dispatch(setKeyValue(SearchPagesActionsTypes.setPages, null));
};

const fetchSearchPageAndSearchUIVersion =
    (modelId: string, getSearchUIVersion: boolean = true): IThunkAction<Promise<void>> =>
    async (dispatch) => {
        const serverCall = async () => {
            const searchPage = await Platform.searchPages.get(modelId);

            dispatch(setKeyValue(SearchPagesActionsTypes.setActiveSearchPage, searchPage));

            if (getSearchUIVersion) {
                const {current, available} = await Platform.searchPages.getVersion(modelId);

                dispatch(
                    setKeyValue(
                        SearchPagesActionsTypes.setCurrentVersion,
                        current ? new SearchPageVersion(current.major, current.minor) : null,
                    ),
                );
                dispatch(
                    setKeyValue(
                        SearchPagesActionsTypes.setAvailableVersions,
                        available?.map((version) => new SearchPageVersion(version.major, version.minor)) ?? null,
                    ),
                );
            }
        };

        await dispatch(RequestsActions.handle(SearchPagesActionsTypes.fetchSingle, serverCall));
    };

const destroy =
    (id: string): IThunkAction<Promise<void>> =>
    async (dispatch) => {
        const action = `${SearchPagesActionsTypes.destroy}-${id}`;

        const serverCall = () => Platform.searchPages.delete(id);

        await dispatch(RequestsActions.handle(action, serverCall));

        track('deleted search page', {
            searchPageType: 'classic',
            searchPageId: id,
        });
    };

const add = (): IThunkAction<Promise<void>, AdminState> => async (dispatch, getState) => {
    const state = getState();
    const page = SearchPagesSelectors.getSearchPageToUpdateInto(state);
    const currentVersion = SearchPagesSelectors.getSearchPageCurrentVersionInputValue(state);

    const serverCall = async () => {
        const {id: modelId} = await Platform.searchPages.create(page);
        track('completed search page add', {
            searchPageId: page.id,
            searchPageType: 'classic',
        });

        if (currentVersion) {
            await Platform.searchPages.updateVersion(modelId, currentVersion.toJSON());
        }
    };

    await dispatch(RequestsActions.handle(SearchPagesActionsTypes.add, serverCall));
};

const update = (): IThunkAction<Promise<void>, AdminState> => async (dispatch, getState) => {
    const state = getState();
    const page = SearchPagesSelectors.getSearchPageToUpdateInto(state);
    const currentVersion = SearchPagesSelectors.getSearchPageCurrentVersionInputValue(state);

    const serverCall = async () => {
        await Platform.searchPages.update(page.id, page);

        if (currentVersion) {
            await Platform.searchPages.updateVersion(page.id, currentVersion.toJSON());
            track('completed search page edit', {
                searchPageId: page.id,
            });
        }
    };

    await dispatch(RequestsActions.handle(SearchPagesActionsTypes.update, serverCall));
};

const save =
    (modelIsNew: boolean, accessAttributes?: AccessSerializableAttributes): IThunkAction<Promise<void>, AdminState> =>
    (dispatch, getState) => {
        if (accessAttributes) {
            const state = getState();
            const page = state.searchPageEditor.activeSearchPage;

            dispatch(setKeyValue(SearchPagesActionsTypes.setActiveSearchPage, {...page, ...accessAttributes}));
        }
        return modelIsNew ? add()(dispatch, getState, null) : update()(dispatch, getState, null);
    };

const validate =
    (groupId: string, allWidgetsNames: string[]): IThunkAction<boolean, AdminState> =>
    (dispatch, getState) => {
        const state = getState();
        const originalName = state.searchPageEditor?.activeSearchPage?.name;

        const nameError = SearchPagesValidator.validateName(
            SearchPagesSelectors.getSearchPageNameInputValue(state),
            originalName,
            SearchPagesSelectors.getSearchPagesNames(state),
            allWidgetsNames,
        );
        const titleError = SearchPagesValidator.validateTitle(SearchPagesSelectors.getSearchPageTitleInputValue(state));

        if (nameError || titleError) {
            dispatch(selectTab(SearchPagesConstants.SearchPageEditor.Tabs.ConfigurationTab.id, groupId));
            dispatch(validateInputValue(SearchPagesConstants.SearchPageEditor.Inputs.Name, nameError === null));
            dispatch(validateInputValue(SearchPagesConstants.SearchPageEditor.Inputs.Title, titleError === null));

            return false;
        }

        return true;
    };

const clear = (): IThunkAction<void> => (dispatch) => {
    dispatch(setKeyValue(SearchPagesActionsTypes.setActiveSearchPage, null));
    dispatch(setKeyValue(SearchPagesActionsTypes.setCurrentVersion, null));
    dispatch(setKeyValue(SearchPagesActionsTypes.setAvailableVersions, null));
};

const list = (): IThunkAction<Promise<SearchPage[]>, AdminState> => (dispatch) =>
    dispatch(RequestsActions.handle(SearchPagesActionsTypes.list, () => Platform.searchPages.list()));

export const SearchPagesActions = {
    fetch,
    clearTable,
    fetchSearchPageAndSearchUIVersion,
    fetchSearchPagesNames,
    destroy,
    add,
    update,
    save,
    validate,
    clear,
    list,
};
