import {OwnedProps, useOwnership} from '@core/debug';
import {
    factory,
    Factory,
    Modal as PlasmaModal,
    ModalCssVariables,
    ModalFactory as PlasmaModalFactory,
    ModalProps as PlasmaModalProps,
    ModalRootProps as PlasmaModalRootProps,
    ModalStylesNames,
} from '@coveord/plasma-mantine';

export type ModalProps = PlasmaModalProps & OwnedProps;

export type ModalRootProps = PlasmaModalRootProps & OwnedProps;

export type ModalFactory = Omit<PlasmaModalFactory, 'props' | 'staticComponents'> & {
    props: ModalProps;
    staticComponents: Omit<PlasmaModalFactory['staticComponents'], 'Root'> & {
        Root: typeof ModalRoot;
    };
};

export const Modal = factory<ModalFactory>(({framework, owner, ...props}: ModalProps, ref) => {
    useOwnership(owner, framework, !props.opened);
    return <PlasmaModal ref={ref} {...props} />;
});

// Plasma doesn't export ModalRootFactory, need to redeclare the factory to override the props type
export type ModalRootFactory = Factory<{
    props: ModalRootProps;
    ref: HTMLDivElement;
    stylesNames: ModalStylesNames; // Plasma doesn't export ModalRootStylesNames, but it's the same as ModalStylesNames
    vars: ModalCssVariables; // Plasma doesn't export ModalRootCssVariables, but it's the same as ModalCssVariables
    compound: true;
}>;

const ModalRoot = factory<ModalRootFactory>(({framework, owner, ...props}: ModalRootProps, ref) => {
    useOwnership(owner, framework, !props.opened);
    return <PlasmaModal.Root ref={ref} {...props} />;
});

Modal.Root = ModalRoot;
Modal.Body = PlasmaModal.Body;
Modal.Overlay = PlasmaModal.Overlay;
Modal.Content = PlasmaModal.Content;
Modal.Header = PlasmaModal.Header;
Modal.Title = PlasmaModal.Title;
Modal.CloseButton = PlasmaModal.CloseButton;
Modal.Footer = PlasmaModal.Footer;
Modal.Stack = PlasmaModal.Stack;
