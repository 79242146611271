import {IOptionsDropdownSearchPayload} from '@coveord/plasma-react';
import {SfObjectConditionsCompositeState} from './SfObjectConditionsReducers';
import {SfObjectConditionDropdownOption} from './views/SfObjectConditionPropertyDropdownView';

export const SfObjectConditionsActionType = {
    AddCondition: 'ADD_CONDITION',
    ChangeConditionPropertyValue: 'CHANGE_CONDITION_PROPERTY_VALUE',
    ClearConditions: 'CLEAR_CONDITIONS',
    RemoveCondition: 'REMOVE_CONDITION',
    SetSfObjectConditionsInitialState: 'SET_SF_OBJECT_CONDITIONS_INITIAL_STATE',
    ToggleKbPublishStatus: 'TOGGLE_KB_PUBLISH_STATUS',
};

export interface SetSfObjectConditionsInitialStatePayload {
    sfObjectConditionsCompositeState: SfObjectConditionsCompositeState;
}

export interface ChangeConditionPropertyValuePayload {
    option: SfObjectConditionDropdownOption;
    property: string;
    index?: number;
}

export interface ToggleKbPublishStatusPayload {
    statusType: string;
}

export interface RemoveConditionPayload {
    index: number;
}

export type SfObjectConditionsActionPayloads =
    | SetSfObjectConditionsInitialStatePayload
    | ChangeConditionPropertyValuePayload
    | ToggleKbPublishStatusPayload
    | RemoveConditionPayload
    | IOptionsDropdownSearchPayload;

export interface SfObjectConditionsAction {
    type: string;
    payload?: {
        sfObjectConditionsCompositeState?: SfObjectConditionsCompositeState;
        option?: SfObjectConditionDropdownOption;
        property?: string;
        index?: number;
        statusType?: string;
    };
}

const addCondition = (): SfObjectConditionsAction => ({
    type: SfObjectConditionsActionType.AddCondition,
});

const changeConditionPropertyValue = (
    option: SfObjectConditionDropdownOption,
    property: string,
    index?: number,
): SfObjectConditionsAction => ({
    type: SfObjectConditionsActionType.ChangeConditionPropertyValue,
    payload: {option, property, index},
});

const clearConditions = (): SfObjectConditionsAction => ({
    type: SfObjectConditionsActionType.ClearConditions,
});

const removeCondition = (index: number): SfObjectConditionsAction => ({
    type: SfObjectConditionsActionType.RemoveCondition,
    payload: {index},
});

const setSfObjectConditionsInitialState = (
    sfObjectConditionsCompositeState: SfObjectConditionsCompositeState,
): SfObjectConditionsAction => ({
    type: SfObjectConditionsActionType.SetSfObjectConditionsInitialState,
    payload: {sfObjectConditionsCompositeState},
});

const toggleKbPublishStatus = (statusType: string): SfObjectConditionsAction => ({
    type: SfObjectConditionsActionType.ToggleKbPublishStatus,
    payload: {statusType},
});

export const SfObjectConditionsActions = {
    addCondition,
    changeConditionPropertyValue,
    clearConditions,
    removeCondition,
    setSfObjectConditionsInitialState,
    toggleKbPublishStatus,
};
