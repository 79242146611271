import Registry from '@core/registry';
import * as ReactVapor from '@coveord/plasma-react';
import _ from 'underscore';

import {FetchGroupsSuccessPayload, UAGroupsActionsType} from '../../../../groups/actions/UAGroupsActions';
import {UAInjectable} from '../../../../UAInjectable';
import {FetchUsersSuccessPayload, UAUsersActionsType} from '../../../../users/actions/UAUsersActions';
import {UALoggedUser} from '../../../../users/UALoggedUser';
import {
    AddIdentityPayload,
    FetchReportAccessPayload,
    RemoveIdentityPayload,
    ReportAccessActionsType,
    SetTypePayload,
} from '../actions/ReportAccessActions';
import {ReportAccessIdentityTypes} from '../ReportAccessIdentityTypes';
import {ReportAccessEditIdentityState, ReportAccessState} from '../ReportAccessState';
import {ReportAccessType} from '../ReportAccessType';
import {
    ReportAccessIdentityActionPayloads,
    ReportAccessIdentityDefaultState,
    ReportAccessIdentityReducer,
} from './ReportAccessIdentityReducer';
import {ReportAccessReducerUtils} from './ReportAccessReducerUtils';

export const ReportAccessDefaultState: ReportAccessState = {
    type: ReportAccessType.Public,
    addIdentityState: ReportAccessIdentityDefaultState,
    identities: [],
    accessLoaded: false,
    groups: [],
    users: [],
};

const addIdentityReducer = (state: ReportAccessState) => {
    const newState: ReportAccessState = _.extend({}, state);

    const newMember: ReportAccessEditIdentityState = _.extend(
        {},
        {
            appliedState: _.extend({}, state.addIdentityState.editionState),
            editionState: _.extend({}, state.addIdentityState.editionState),
            id: ReportAccessReducerUtils.GenerateIdentityId(),
            isOpen: false,
        },
    );
    newState.identities = [].concat(state.identities, [newMember]);
    newState.addIdentityState = _.extend({}, ReportAccessIdentityDefaultState, {
        editionState: ReportAccessReducerUtils.GetAddEditionState(newState),
    });

    return newState;
};

const removeAllowedIdentityReducer = (
    state: ReportAccessState,
    action: ReactVapor.IReduxAction<RemoveIdentityPayload>,
) => {
    let newState: ReportAccessState = _.extend({}, state);
    const newIdentities = _.reject(
        state.identities,
        (identity: ReportAccessEditIdentityState) => identity.id === action.payload.id,
    );
    newState = _.extend({}, newState, {identities: newIdentities});
    newState.addIdentityState = _.extend({}, ReportAccessIdentityDefaultState, {
        editionState: ReportAccessReducerUtils.GetAddEditionState(newState),
    });
    return newState;
};

const fetchReportAccessReducer = (
    state: ReportAccessState,
    action: ReactVapor.IReduxAction<FetchReportAccessPayload>,
) =>
    _.extend({}, state, {
        accessLoaded: true,
        type: action.payload.type,
        identities: action.payload.identities,
    });

const fetchGroupsReducer = (state: ReportAccessState, action: ReactVapor.IReduxAction<FetchGroupsSuccessPayload>) => {
    const newState = _.extend({}, state, {groups: action.payload.groups});
    newState.addIdentityState = _.extend({}, ReportAccessIdentityDefaultState, {
        editionState: ReportAccessReducerUtils.GetAddEditionState(newState),
    });
    return newState;
};

const fetchUsersReducer = (state: ReportAccessState, action: ReactVapor.IReduxAction<FetchUsersSuccessPayload>) => {
    const newState = _.extend({}, state, {users: action.payload.users});
    newState.addIdentityState = _.extend({}, ReportAccessIdentityDefaultState, {
        editionState: ReportAccessReducerUtils.GetAddEditionState(newState),
    });
    return newState;
};

const setTypeReducer = (state: ReportAccessState, action: ReactVapor.IReduxAction<SetTypePayload>) => {
    const newState = _.extend({}, state, {type: action.payload.type});
    const user: UALoggedUser = Registry.get(UAInjectable.User);
    const userHasAccess = _.some(
        state.identities,
        (identity: ReportAccessEditIdentityState) =>
            identity.appliedState.identityType === ReportAccessIdentityTypes.User &&
            identity.appliedState.identity === user.id,
    );
    if (action.payload.type === ReportAccessType.Custom && !userHasAccess) {
        const newIdentity = ReportAccessReducerUtils.GenerateCurrentUserIdentity();
        newState.identities = [].concat(state.identities, [newIdentity]);
    }

    return newState;
};

const clearModalReducer = (state: ReportAccessState, action: ReactVapor.IReduxAction<SetTypePayload>) =>
    _.extend({}, state, ReportAccessDefaultState);

const ReportAccessActionReducers: {[key: string]: (...args: any[]) => any} = {
    [ReportAccessActionsType.AddIdentity]: addIdentityReducer,
    [ReportAccessActionsType.RemoveIdentity]: removeAllowedIdentityReducer,
    [ReportAccessActionsType.FetchReportAccessSuccess]: fetchReportAccessReducer,
    [UAGroupsActionsType.FetchGroupsSuccess]: fetchGroupsReducer,
    [UAUsersActionsType.FetchUsersSuccess]: fetchUsersReducer,
    [ReportAccessActionsType.SetType]: setTypeReducer,
    [ReportAccessActionsType.ClearModal]: clearModalReducer,
};

const applyAllowedIdentityEditionReducers = (
    state: ReportAccessState,
    action: ReactVapor.IReduxAction<ReportAccessIdentityActionPayloads>,
) => {
    const newState: ReportAccessState = _.extend({}, state);

    if (_.isNull(action.payload.id)) {
        newState.addIdentityState = ReportAccessIdentityReducer(state.addIdentityState, action as any, state);
    } else {
        newState.identities = _.map(state.identities, (identity: ReportAccessEditIdentityState) => {
            if (identity.id === action.payload.id) {
                return ReportAccessIdentityReducer(identity, action, state);
            } else {
                return identity;
            }
        });
    }
    return newState;
};

const isIdentityAction = (
    action: ReactVapor.IReduxAction<any>,
): action is ReactVapor.IReduxAction<ReportAccessIdentityActionPayloads> => !_.isUndefined(action.payload.id);

export const ReportAccessReducer = (
    state: ReportAccessState = ReportAccessDefaultState,
    action: ReactVapor.IReduxAction<
        | AddIdentityPayload
        | RemoveIdentityPayload
        | FetchReportAccessPayload
        | FetchGroupsSuccessPayload
        | FetchUsersSuccessPayload
        | SetTypePayload
        | ReportAccessIdentityActionPayloads
    >,
) => {
    let newState = state;
    if (!_.isUndefined(ReportAccessActionReducers[action.type])) {
        newState = ReportAccessActionReducers[action.type](state, action);
    } else if (action.payload && isIdentityAction(action)) {
        newState = applyAllowedIdentityEditionReducers(state, action);
    }
    return newState;
};
