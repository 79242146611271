import moment from 'moment-timezone';
import {ISO_DATE_FORMAT} from '../../Constants';
import {DateRangeType, DateUtils} from '../../utils/DateUtils';

export interface DateRangeAttributes {
    range?: string;
    length?: number;
    offset?: number;
}

export class DateRangeModel extends Backbone.Model {
    timezone = DateUtils.UserTimezone;

    get range(): string {
        return this.get('range');
    }

    set range(range: string) {
        this.set('range', range);
    }

    get length(): number {
        return this.get('length');
    }

    set length(length: number) {
        this.set('length', length);
    }

    get offset(): number {
        return this.get('offset');
    }

    set offset(offset: number) {
        this.set('offset', offset);
    }

    get begin(): moment.Moment {
        return moment.tz(this.beginDate, this.timezone);
    }

    get end(): moment.Moment {
        return moment.tz(this.endDate, this.timezone);
    }

    get beginDate(): Date {
        return DateUtils.convertRangeToDates(this.toJSON(), this.timezone).begin;
    }

    get endDate(): Date {
        return DateUtils.convertRangeToDates(this.toJSON(), this.timezone).end;
    }

    defaults(): DateRangeAttributes {
        return {
            range: DateRangeType.Days,
            length: 7,
            offset: -7,
        };
    }

    setWeekRange(silent: boolean = false) {
        this.setLastXDaysRange(7, silent);
    }

    setLastWeekRange(silent: boolean = false) {
        this.set(
            {
                range: DateRangeType.Weeks,
                length: 1,
                offset: -1,
            },
            {silent: silent},
        );
    }

    setMonthRange(silent: boolean = false) {
        this.setLastXDaysRange(31, silent);
    }

    setLastMonthRange(silent: boolean = false) {
        this.set(
            {
                range: DateRangeType.Months,
                length: 1,
                offset: -1,
            },
            {silent: silent},
        );
    }

    setYearRange(silent: boolean = false) {
        this.setLastXDaysRange(365, silent);
    }

    setLastYearRange(silent: boolean = false) {
        this.set(
            {
                range: DateRangeType.Years,
                length: 1,
                offset: -1,
            },
            {silent: silent},
        );
    }

    setLastXDaysRange(days: number, silent: boolean = false) {
        this.set(
            {
                range: DateRangeType.Days,
                length: Math.max(days, 1),
                offset: 0 - days,
            },
            {silent: silent},
        );
    }

    getPeriod(offset: number) {
        return {
            begin: DateUtils.getBeginMoment(offset, this.range, this.timezone),
            end: DateUtils.getEndMoment(offset + this.length - 1, this.range, this.timezone),
        };
    }

    getBeginDateLocalTimeString(): string {
        return this.begin.format(ISO_DATE_FORMAT);
    }

    getEndDateLocalTimeString(): string {
        const now = moment.tz(this.timezone);

        let endMoment: moment.Moment = this.end.clone();
        if (endMoment.isAfter(now)) {
            endMoment = now.seconds(0).milliseconds(0);
        }

        return endMoment.format(ISO_DATE_FORMAT);
    }

    setFromDates(begin: Date, end: Date, silent = false) {
        this.set(DateUtils.convertDatesToDateRange(begin, end, this.timezone), {silent: true});
        if (!silent) {
            this.trigger('change');
        }
    }

    toJSON(options?: any): DateRangeAttributes {
        return super.toJSON(options);
    }
}
