import {InputSelectors, SelectSelector} from '@coveord/plasma-react';
import {ServiceState} from '../../ServiceState';
import {CaseAssistComponentIds} from '../CaseAssistComponentIds';

const getNewCaseAssistConfigName = (state: ServiceState): string =>
    InputSelectors.getValue(state, {id: CaseAssistComponentIds.ModalNameInput});

const getNewCaseAssistAssociatedProjectIds = (state: ServiceState): string[] =>
    SelectSelector.getListBoxSelected(state, {
        id: CaseAssistComponentIds.AssociationProjectMultiSelect,
    }) || [];

export const CreateCaseAssistConfigModalSelectors = {
    getNewCaseAssistConfigName: getNewCaseAssistConfigName,
    getNewCaseAssistAssociatedProjectIds,
};
