import * as ReactVapor from '@coveord/plasma-react';

export const DashboardActionsType = {
    Initialize: 'DASHBOARD_INITIALIZE',
    SetIsEditing: 'DASHBOARD_SET_IS_EDITING',
    SetTitle: 'DASHBOARD_SET_TITLE',
    SetTab: 'DASHBOARD_SET_TAB',
};

export interface DashboardInitializePayload {
    id: string;
    title: string;
}

const initialize = (id: string, title: string): ReactVapor.IReduxAction<DashboardInitializePayload> => ({
    type: DashboardActionsType.Initialize,
    payload: {id, title},
});

export interface DashboardSetEditingPayload {
    isEditing: boolean;
}

const setIsEditing = (isEditing: boolean): ReactVapor.IReduxAction<DashboardSetEditingPayload> => ({
    type: DashboardActionsType.SetIsEditing,
    payload: {isEditing},
});

export interface DashboardSetTitlePayload {
    title: string;
}

const setTitle = (title: string): ReactVapor.IReduxAction<DashboardSetTitlePayload> => ({
    type: DashboardActionsType.SetTitle,
    payload: {title},
});

export interface DashboardSetTabPayload {
    tab: string;
    tabNumberOfCards: number;
    dashboardNumberOfCards: number;
}

const setTab = (
    tab: string,
    tabNumberOfCards: number,
    dashboardNumberOfCards: number,
): ReactVapor.IReduxAction<DashboardSetTabPayload> => ({
    type: DashboardActionsType.SetTab,
    payload: {tab, tabNumberOfCards, dashboardNumberOfCards},
});

export const DashboardActions = {
    initialize,
    setIsEditing,
    setTitle,
    setTab,
};
