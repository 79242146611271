export interface CollapsibleBehaviorOptions {
    selector?: string;
    domRefreshCallback?: () => void;
}

export interface Collapsible {
    disable: () => void;
    enable: () => void;
    close: () => void;
    open: (id?: string) => void;
}

export interface CollapsibleView {
    collapsible: Collapsible;
}

export class CollapsibleBehavior extends Marionette.Behavior {
    private domRefreshCallback: () => void;

    constructor(options?: CollapsibleBehaviorOptions, view?) {
        super(options, view);

        this.view.collapsible = this;

        this.domRefreshCallback = options && options.domRefreshCallback;

        this.ui = {
            collapsible: this.options.selector,
            headers: `${this.options.selector} .collapsible-header`,
            bodies: '.collapsible-body',
        };
    }

    defaults() {
        return {
            selector: '.collapsible',
        };
    }

    onDomRefresh() {
        // need to check if collapsible is available
        if (this.ui.collapsible.collapsible) {
            this.ui.collapsible.collapsible();

            if (this.domRefreshCallback) {
                this.domRefreshCallback();
            }
        }
    }

    private disable() {
        this.ui.headers.attr('disabled', true);
    }

    private enable() {
        this.ui.headers.removeAttr('disabled');
    }

    private close() {
        this.ui.collapsible.removeClass('active');
        this.ui.headers.removeClass('active');

        // Apply the same slideUp animation as Materialize
        this.ui.bodies.stop(true, false).slideUp({
            duration: 350,
            queue: false,
            complete: function () {
                $(this).css('height', '');
            },
        });
    }

    private open(selector?: string) {
        if (selector) {
            this.ui.collapsible.find(`${selector} .collapsible-header`).click();
        } else {
            this.ui.headers.click();
        }
    }
}
