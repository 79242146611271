import {InputSelectors, SelectSelector, ValidationSelectors} from '@coveord/plasma-react';
import {DocumentSuggestionsConfigurations, DocumentSuggestionsStrategies} from '@core/api';
import {RequestsSelectors} from '@coveord/jsadmin-common';
import {ServiceState} from '../../../ServiceState';
import {CaseAssistConfigurationConstants} from '../CaseAssistConfigurationConstants';

const isFetchingPipelines = RequestsSelectors.createLoadingSelector(['FETCH_DOCUMENT_SUGGESTION_PIPELINES']);

const getConfigPipeline = (state: ServiceState) =>
    state.caseAssistManager.editCaseAssistConfig.data.documentSuggestionConfiguration?.pipeline ?? '';

const hasConfig = (state: ServiceState) => Boolean(getConfigPipeline(state));

const shouldShowBlankSlate = (state: ServiceState) =>
    !state.caseAssistManager.editCaseAssistConfig.documentSuggestionTab.withEmptyStateTab.configuringNew &&
    !hasConfig(state);

const getConfiguration = (
    state: ServiceState,
    canEditConfig: boolean,
): DocumentSuggestionsConfigurations | undefined =>
    canEditConfig ? getConfigurationFromForm(state) : getConfigurationFromLoadedConfig(state);

const getConfigurationFromForm = (state: ServiceState): DocumentSuggestionsConfigurations | undefined => {
    const filter = InputSelectors.getValue(state, {
        id: CaseAssistConfigurationConstants.ComponentIds.DocumentSuggestion.FilterInput,
    });
    const pipeline = SelectSelector.getListBoxSelected(state, {
        id: CaseAssistConfigurationConstants.ComponentIds.DocumentSuggestion.QueryPipelines,
    })[0];

    return pipeline ? {filter, pipeline, strategy: DocumentSuggestionsStrategies.ITD} : undefined;
};

const getConfigurationFromLoadedConfig = (state: ServiceState): DocumentSuggestionsConfigurations | undefined =>
    state.caseAssistManager?.editCaseAssistConfig.data?.documentSuggestionConfiguration;

const getValidationIds = () => [
    CaseAssistConfigurationConstants.ComponentIds.DocumentSuggestion.QueryPipelines,
    CaseAssistConfigurationConstants.ComponentIds.DocumentSuggestion.FilterInput,
];

const getConfiguringNew = (state: ServiceState) =>
    state.caseAssistManager.editCaseAssistConfig.documentSuggestionTab.withEmptyStateTab.configuringNew;

const getIsConfigError = (state: ServiceState) => ValidationSelectors.isInError(getValidationIds())(state);

const isValidating = (state: ServiceState) =>
    state.caseAssistManager.editCaseAssistConfig.documentSuggestionTab.withQuerySyntaxValidation.validatingQuerySyntax
        ?.length > 0;

const isValidConfiguration = (state: ServiceState, canEditConfig: boolean) =>
    getConfiguration(state, canEditConfig) && !isValidating(state) && !getIsConfigError(state);

export const DocumentSuggestionSelectors = {
    hasConfig,
    isFetchingPipelines,
    shouldShowBlankSlate,
    getConfiguration,
    getValidationIds,
    getConfiguringNew,
    getIsConfigError,
    isValidating,
    isValidConfiguration,
};
