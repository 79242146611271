import {SelectSelector} from '@coveord/plasma-react';

import {ConditionsState} from '../interfaces';

const getSelectedId = (state: ConditionsState, id: string): string => {
    const selectedItems = SelectSelector.getListBoxSelected(state, {id});
    return selectedItems.length ? selectedItems[0] : null;
};

export const SelectConditionSelectors = {
    getSelectedId,
};
