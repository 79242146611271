import {IReduxAction} from '@coveord/plasma-react';
import _ from 'underscore';
import {ComponentIdMap} from '../../ComponentIdMap';
import {
    CardActionPayload,
    CardActionType,
    CardMountPayload,
    CardSetDefaultTitlePayload,
    CardSetExplorerLinkPayload,
    CardSetSelectedPayload,
    CardSetTitlePayload,
} from '../actions/CardActions';
import {CalculatedMetricActionsType} from '../CalculatedMetric/actions/CalculatedMetricActions';
import {EditCalculatedMetricActionsType} from '../CalculatedMetric/actions/EditCalculatedMetricActions';
import {CalculatedMetricCardDefaultState} from '../CalculatedMetric/CalculatedMetricState';
import {
    calculatedMetricInitializeReducer,
    calculatedMetricLoadingReducer,
    calculatedMetricReloadReducer,
    calculatedMetricSaveReducer,
    calculatedMetricSetErrorReducer,
    calculatedMetricSetReducer,
    calculatedMetricUnmountReducer,
    calculatedMetricUpdateReducer,
} from '../CalculatedMetric/reducers/CalculatedMetricReducers';
import {CardDefaultState, CardsDefaultState, CardState} from '../CardState';
import {metricCardUpdateDefaultTitleReducer, metricCardUpdateReducer} from '../Metric/reducers/MetricCardReducers';
import {EditNoteCardActionsType} from '../Note/actions/EditNoteCardActions';
import {NoteCardActionsType} from '../Note/actions/NoteCardActions';
import {NoteCardDefaultState} from '../Note/NoteCardState';
import {noteCardInitializeReducer, noteCardSaveReducer} from '../Note/reducers/NoteCardReducers';
import {QuickMetricActionsType} from '../QuickMetric/actions/QuickMetricActions';
import {QuickMetricDefaultState} from '../QuickMetric/QuickMetricState';
import {VisitMetricActionsType} from '../VisitMetric/actions/VisitMetricActions';
import {VisitMetricDefaultState} from '../VisitMetric/VisitMetricState';

const mountReducer = (state: CardState[], action: IReduxAction<CardMountPayload>) => {
    let base: CardState = null;
    switch (action.payload.type) {
        case ComponentIdMap.QuickMetricComponent:
            base = QuickMetricDefaultState;
            break;
        case ComponentIdMap.VisitMetricComponent:
            base = VisitMetricDefaultState;
            break;
        case ComponentIdMap.CalculatedMetricComponent:
            base = CalculatedMetricCardDefaultState;
            break;
        case ComponentIdMap.NoteCardComponent:
            base = NoteCardDefaultState;
            break;
        default:
            base = CardDefaultState;
            break;
    }
    return [
        ...state,
        _.extend({}, base, {
            id: action.payload.id,
            type: action.payload.type,
            title: action.payload.title,
            defaultTitle: action.payload.defaultTitle,
            link: action.payload.link,
        }),
    ];
};

const unmountReducer = (state: CardState[], action: IReduxAction<CardActionPayload>) => {
    const newState = calculatedMetricUnmountReducer(state, action);
    return _.reject(newState, (card: CardState) => action.payload.id === card.id);
};

const deselectAll = (state: CardState[]) => _.map(state, (card: CardState) => ({...card, isSelected: false}));

const updateReducer = (state: CardState, action: IReduxAction<CardActionPayload>) =>
    _.extend({}, state, {isLoading: false});

const setTitleReducer = (state: CardState, action: IReduxAction<CardSetTitlePayload>) =>
    _.extend({}, state, {title: action.payload.title});

const setIsSelected = (state: CardState, action: IReduxAction<CardSetSelectedPayload>) =>
    _.extend({}, state, {isSelected: action.payload.isSelected});

const setExplorerLinkReducer = (state: CardState, action: IReduxAction<CardSetExplorerLinkPayload>) =>
    _.extend({}, state, {explorerLink: action.payload.link});

const setDefaultTitleReducer = (state: CardState, action: IReduxAction<CardSetDefaultTitlePayload>) =>
    _.extend({}, state, {defaultTitle: action.payload.defaultTitle});

const loadReducer = (state: CardState, action: IReduxAction<CardActionPayload>) =>
    _.extend({}, state, {
        isLoading: true,
        error: null,
    });

const CardActionReducers: {[key: string]: (...args: any[]) => any} = {
    [CardActionType.Load]: loadReducer,
    [CardActionType.Update]: updateReducer,
    [CardActionType.SetTitle]: setTitleReducer,
    [CardActionType.SetExplorerLink]: setExplorerLinkReducer,
    [CardActionType.SetDefaultTitle]: setDefaultTitleReducer,
    [CardActionType.SetSelected]: setIsSelected,
    [CalculatedMetricActionsType.Initialize]: calculatedMetricInitializeReducer,
    [CalculatedMetricActionsType.StartLoading]: calculatedMetricLoadingReducer,
    [CalculatedMetricActionsType.SetConst]: calculatedMetricSetReducer,
    [NoteCardActionsType.Initialize]: noteCardInitializeReducer,
    [QuickMetricActionsType.Update]: metricCardUpdateReducer,
    [QuickMetricActionsType.UpdateDefaultTitle]: metricCardUpdateDefaultTitleReducer,
    [VisitMetricActionsType.Update]: metricCardUpdateReducer,
    [VisitMetricActionsType.UpdateDefaultTitle]: metricCardUpdateDefaultTitleReducer,
};

const CardsActionReducers: {[key: string]: (...args: any[]) => any} = {
    [CardActionType.Mount]: mountReducer,
    [CardActionType.Unmount]: unmountReducer,
    [CardActionType.DeselectAll]: deselectAll,
    [CardActionType.SetError]: calculatedMetricSetErrorReducer,
    [QuickMetricActionsType.Update]: calculatedMetricUpdateReducer,
    [VisitMetricActionsType.Update]: calculatedMetricUpdateReducer,
    [CalculatedMetricActionsType.Reload]: calculatedMetricReloadReducer,
    [EditCalculatedMetricActionsType.ApplyChanges]: calculatedMetricSaveReducer,
    [EditNoteCardActionsType.ApplyChanges]: noteCardSaveReducer,
};

export type CardPayload =
    | CardActionPayload
    | CardMountPayload
    | CardSetTitlePayload
    | CardSetDefaultTitlePayload
    | CardSetExplorerLinkPayload;

export const CardReducer = (state: CardState[] = CardsDefaultState, action?: IReduxAction<CardPayload>) => {
    let newState = state;
    if (action && action.payload && action.payload.id && !_.isUndefined(CardActionReducers[action.type])) {
        newState = newState.map((card: CardState) =>
            card.id === action.payload.id ? CardActionReducers[action.type](card, action) : card,
        );
    }

    if (!_.isUndefined(CardsActionReducers[action.type])) {
        newState = CardsActionReducers[action.type](newState, action);
    }
    return newState;
};
