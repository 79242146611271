import {IReduxAction} from '@coveord/plasma-react';
import _ from 'underscore';
import {
    DashboardActionsType,
    DashboardInitializePayload,
    DashboardSetEditingPayload,
    DashboardSetTabPayload,
    DashboardSetTitlePayload,
} from '../actions/DashboardActions';
import {DashboardDefaultState, DashboardState} from '../DashboardState';

const initialize = (state: DashboardState, action: IReduxAction<DashboardInitializePayload>) =>
    _.extend({}, state, {
        id: action.payload.id,
        title: action.payload.title,
    });

const setEditing = (state: DashboardState, action: IReduxAction<DashboardSetEditingPayload>) =>
    _.extend({}, state, {isEditing: action.payload.isEditing});

const setTitle = (state: DashboardState, action: IReduxAction<DashboardSetTitlePayload>) =>
    _.extend({}, state, {title: action.payload.title});

const setTab = (state: DashboardState, action: IReduxAction<DashboardSetTabPayload>) =>
    _.extend({}, state, action.payload);

const DashboardReducers: {[key: string]: (...args: any[]) => void} = {
    [DashboardActionsType.Initialize]: initialize,
    [DashboardActionsType.SetIsEditing]: setEditing,
    [DashboardActionsType.SetTitle]: setTitle,
    [DashboardActionsType.SetTab]: setTab,
};

export type DashboardPayload = DashboardInitializePayload | DashboardSetEditingPayload | DashboardSetTitlePayload;

export const DashboardReducer = (
    state: DashboardState = DashboardDefaultState,
    action?: IReduxAction<DashboardPayload>,
) => (!_.isUndefined(DashboardReducers[action.type]) ? DashboardReducers[action.type](state, action) : state);
