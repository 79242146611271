import {combineReducers, ReducersMapObject} from 'redux';
import {insightPanelConfigsReducer} from './insightPanelConfigTable/InsightPanelConfigReducer';
import {editInsightPanelConfigReducer} from './editInsightPanelConfig/EditInsightPanelConfigReducer';
import {IInsightPanelManagerState} from './InsightPanelState';

export const reducers: ReducersMapObject = {
    insightPanelConfigs: insightPanelConfigsReducer,
    editInsightPanelConfig: editInsightPanelConfigReducer,
};

export const insightPanelReducer = combineReducers<IInsightPanelManagerState>(reducers);
