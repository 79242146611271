import {useDidUpdate} from '@components/mantine';
import {useBlockingPrompt} from '@components/page';
import {CurrentOrganization} from '@core/organization';
import {useMatch, useNavigate} from '@core/routes';
import {boundaryRoutes} from '@pages/boundaries';
import {FunctionComponent, useState} from 'react';

import {mainBusinessRoutes} from '../../routes';

export const OrganizationChangeHandler: FunctionComponent = () => {
    const orgId = CurrentOrganization.getId();
    const {setShouldDisplayBlockingPrompt} = useBlockingPrompt();
    const navigate = useNavigate();
    const match = useMatch('/:orgId/*');
    const updatedOrgId = match?.params.orgId;
    const [currentOrgIdParam, setCurrentOrgIdParam] = useState<string | null>(orgId);
    const updatedPath = match?.pathname.replace(`/${updatedOrgId}/`, '/:orgId/');
    const matchingRoute = mainBusinessRoutes.find(({path}) => path === `${updatedPath}/*`);
    const orgParamHaschanged = updatedOrgId !== currentOrgIdParam;
    const fullPageBoundaryPaths = boundaryRoutes.map((route) => route.path);

    useDidUpdate(() => {
        if (fullPageBoundaryPaths.includes(match?.pathname)) {
            return;
        }
        if (orgParamHaschanged) {
            setCurrentOrgIdParam(updatedOrgId);
        }
        if (match !== null && updatedOrgId !== orgId && orgParamHaschanged) {
            // if no route match the new hash, just redirect to this org
            if (!matchingRoute) {
                const url = new URL(window.location.href);
                url.hash = `/${updatedOrgId}/`;
                setShouldDisplayBlockingPrompt(false);
                navigate(url);
            }
        }
    }, [orgParamHaschanged]);

    return null;
};
