import {Dictionary, Locales} from '@core/locales';
import _ from 'underscore';
import {UALocalesKeys} from './UALocalesKeys';
import {StringMap} from './StringMap';

const translations = {};

// Load all JSON files from the strings folder.

type MappedLocalesTreeValue = string | MappedLocalesTree | Record<string, unknown>;

interface MappedLocalesTree {
    [key: string]: MappedLocalesTreeValue;
}

Object.keys(StringMap).forEach((key: string) => {
    const parts: string[] = key.split('/');
    const roots: string[] = parts.splice(1, parts.length - 2);

    let map: MappedLocalesTreeValue = translations;

    _.each(roots, (root) => {
        if (!map[root]) {
            _.extend(map, {[root]: {}});
        }
        map = map[root];
    });

    _.extend(map, StringMap[key]);
});

const enDictionary: Dictionary = {
    locale: 'en',
    translations,
};

export const UALocales: Locales<UALocalesKeys> = new Locales<UALocalesKeys>({
    dictionaries: [enDictionary],
    defaultLocale: 'en',
});
