/**
 * The organization's subroute defined after `/:organizationId/organization`.
 */
export const organization: Record<string, string> = {
    '/:organizationId/organization/api-access': 'viewed api keys',
    '/:organizationId/organization/api-access/edit/new': 'viewed api key add',
    '/:organizationId/organization/api-access/edit/:apiKeyId': 'viewed api key edit',
    '/:organizationId/organization/api-access/activity': 'viewed api keys activity',
    '/:organizationId/organization/groups': 'viewed groups',
    '/:organizationId/organization/groups/edit/:groupId': 'viewed group edit',
    '/:organizationId/organization/groups/activity': 'viewed groups activity',
    '/:organizationId/organization/groups/new': 'viewed group add',
    '/:organizationId/organization/members': 'viewed members',
    '/:organizationId/organization/members/new': 'viewed member add',
    '/:organizationId/organization/notifications': 'viewed notifications',
    '/:organizationId/organization/notifications/activity': 'viewed notifications activity',
    '/:organizationId/organization/notifications/add': 'viewed notification add',
    '/:organizationId/organization/notifications/edit/:notificationId': 'viewed notification edit',
    '/:organizationId/organization/notifications/statistics/:notificationId': 'viewed notification usage statistics',
    '/:organizationId/organization/projects': 'viewed projects',
    '/:organizationId/organization/projects/activity/:projectId': 'viewed project activity',
    '/:organizationId/organization/projects/view/:projectId/overview': 'viewed project overview',
    '/:organizationId/organization/projects/view/:projectId/resources': 'viewed project resources',
    '/:organizationId/organization/projects/view/:projectId/:tab/activity': 'viewed project activity',
    '/:organizationId/organization/projects/edit/:projectId/overview': 'viewed project overview edit',
    '/:organizationId/organization/projects/edit/:projectId/resources': 'viewed project resources edit',
    '/:organizationId/organization/resource-snapshots': 'viewed resource snapshots',
    '/:organizationId/organization/resource-snapshots/:snapshotId/apply': 'viewed resource snapshot apply',
    '/:organizationId/organization/resource-snapshots/create-snapshot':
        'viewed resource snapshot create of your organization',
    '/:organizationId/organization/resource-snapshots/push-snapshot/:snapshotId': 'viewed resource snapshot export',
    '/:organizationId/organization/temporary-access': 'viewed temporary access',
    '/:organizationId/organization/temporary-access/:temporaryAccessId': 'viewed temporary access details',
    '/:organizationId/organization/temporary-access/activity': 'viewed temporary access activity',
    '/:organizationId/organization/temporary-access/request': 'viewed temporary access request',
};
