import {URIUtils} from '@coveord/jsadmin-common';
import {Config} from '@core/configuration';
import {InProductExperienceModel} from '@core/api';

export const InAppWidgetConstants = {
    WidgetTableId: 'InAppWidget',
    FeatureFlags: 'featureFlag_in_app_widget',
    EmptyStateImagePath: 'images/ipx-panel-empty-state.svg',
    LevelUpIPXCourse: 'https://levelup.coveo.com/learn/courses/introduction-to-the-coveo-ipx',
    ContactUsUrl: 'https://connect.coveo.com/s/',
    Routes: {
        Base: () => URIUtils.linkToRoute('/search/in-app-widgets'),
        Edit: (widgetName: string) => URIUtils.linkToRoute(`${InAppWidgetConstants.Routes.Base()}/edit/${widgetName}`),
        EditInterface: (widget: InProductExperienceModel) => {
            const retURL = encodeURIComponent(
                `${Config.CloudPlatform.url}/admin/${InAppWidgetConstants.Routes.Base()}/edit/${widget.name}`,
            );
            return `${Config.CloudPlatform.url}/pages/${widget.orgId}/${widget.id}?edit=1&return=${retURL}&isIpx=1`;
        },
        Create: () => URIUtils.linkToRoute('/search/in-app-widgets/create'),
        ChooseCreate: () => URIUtils.linkToRoute('/search/in-app-widgets/choose-create'),
        CreateV2: () => URIUtils.linkToRoute('/search/in-app-widgets/create-v2'),
    },
    Inputs: {
        Widgets: {
            name: 'in-app-widget-name',
            title: 'in-app-widget-title',
        },
        Token: {
            searchHub: 'in-app-widget-token-searchhub',
            userDisplayName: 'in-app-widget-token-user-display-name',
            filter: 'in-app-widget-token-filter',
            userIdentities: 'in-app-widget-token-user-identities',
            userIdentitiesName: 'in-app-widget-token-user-identities-name',
            userIdentitiesProvider: 'in-app-widget-token-user-identities-provider',
        },
        Button: {
            position: 'in-app-widget-button-position',
            positionTopRight: 'in-app-widget-button-position-top-right',
            positionMiddleRight: 'in-app-widget-button-position-middle-right',
            positionBottomRight: 'in-app-widget-button-position-bottom-right',
            positionBottomCenter: 'in-app-widget-button-position-bottom-center',
            positionBottomLeft: 'in-app-widget-button-position-bottom-left',
            color: 'in-app-widget-button-color',
            textColor: 'in-app-widget-button-text-color',
            icon: 'in-app-widget-button-icon',
            iconValidator: 'in-app-widget-button-icon-validator',
            fontFamily: 'in-app-widget-button-font-family',
            fontSize: 'in-app-widget-button-font-size',
            fontSizeUnit: 'in-app-widget-button-font-size-unit',
            targetSelector: 'in-app-widget-button-target-selector',
        },
    },
    Tabs: {
        MainConfig: 'in-app-widget-main-editor-tab',
        Design: 'in-app-widget-design-editor-tab',
        SearchRelevance: 'in-app-widget-relevance-editor-tab',
        Advanced: 'in-app-widget-advanced-editor-tab',
    },
    Components: {
        Edition: 'InAppWidgetEdition',
        Creation: 'InAppWidgetCreation',
        CreationModal: 'InAppWidgetCreationModal',
        ChooseCreationModal: 'InAppWidgetChooseCreationModal',
        CreationModalV2: 'InAppWidgetChooseCreationModalV2',
    },
    TableColumn: {
        Name: 'inappwidget.name',
        LastModified: 'inappwidget.lastmodified',
        Searchhub: 'inappwidget.searchhub',
        Script: 'inappwidget.script',
    },
    Documentation: {
        MainTooltip: 'https://docs.coveo.com/en/3160/',
        ManageIdentityTooltip: 'https://docs.coveo.com/en/3160/#modifying-the-default-content-access-settings',
    },
};
