import {RouteObject} from '@core/routes';

export const routes: RouteObject[] = [
    {
        path: '/:orgId/search/relevanceInspector/*',
        lazy: async () => {
            const {RelevanceInspectorPage} = await import('../components/relevance-inspector/RelevanceInspectorPage');
            return {Component: RelevanceInspectorPage};
        },
    },
    {
        path: '/:orgId/search/relevanceInspector/:searchuid',
        lazy: async () => {
            const {RelevanceInspectorPage} = await import('../components/relevance-inspector/RelevanceInspectorPage');
            return {Component: RelevanceInspectorPage};
        },
    },
];
