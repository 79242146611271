export const PickySelectableEvents = {
    Selected: 'selected',
    Deselected: 'deselected',
};

export const SingleSelectEvents = {
    SelectOne: 'select:one',
    DeselectOne: 'deselect:one',
    ReselectOne: 'reselect:one',
};

export const MultiSelectEvents = {
    SelectAll: 'select:all',
    SelectNone: 'select:none',
    SelectSome: 'select:some',
    SelectionModified: 'select:all select:none select:some',
    Selection: 'multi-select:select',
    MultiSelection: 'multi-select:select:continue',
    AddCustomOption: 'multi-select:custom-option',
    ShowApply: 'multi-select:show-apply',
};

export class PickySelectable extends Backbone.Model {
    selected: boolean;
    select: () => void;
    deselect: () => void;
    toggleSelected: () => void;

    constructor(attributes?, options?) {
        super(attributes, options);

        const selectable = new Backbone.Picky.Selectable(this);
        $.extend(this, selectable);
    }
}

export class PickySingleSelect<TModel extends Backbone.Model> extends Backbone.Collection<TModel> {
    selected: TModel;
    pickySelect: (model: TModel) => void;
    deselect: (model: TModel) => void;

    constructor(models?, options?) {
        super(models, options);

        const singleSelect = new Backbone.Picky.SingleSelect(this);

        // The 'select' method already exists on a Backbone Collection. We're moving Picky's 'select' to 'pickySelect'.
        const pickySelect = singleSelect.select;
        delete singleSelect.select;
        singleSelect.pickySelect = pickySelect;

        $.extend(this, singleSelect);
    }
}

export interface PickyMultiSelectSelectedHash<TModel extends PickySelectable> {
    [k: string]: TModel;
}

export class PickyMultiSelect<TModel extends PickySelectable> extends Backbone.Collection<TModel> {
    pickySelect: (model: TModel) => void;
    deselect: (model: TModel) => void;
    selectAll: () => void;
    selectNone: () => void;
    toggleSelectAll: () => void;
    selected: PickyMultiSelectSelectedHash<TModel>;
    selectedLength: number;

    constructor(models?, options?) {
        super(models, options);

        const multiSelect = new Backbone.Picky.MultiSelect(this);

        // The 'select' method already exists on a Backbone Collection. We're moving Picky's 'select' to 'pickySelect'.
        const pickySelect = multiSelect.select;
        delete multiSelect.select;
        multiSelect.pickySelect = pickySelect;

        $.extend(this, multiSelect);
    }

    selectedModels(): PickySelectable[] {
        return this.filter((model: PickySelectable) => model.selected);
    }
}
