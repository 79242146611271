import {ProjectModel} from '@core/api';

import {ProjectActions} from '../actions';
import {ProjectSelectors} from '../selectors';
import {ProjectStore} from '../store';
import {ProjectLocalStorage} from './ProjectLocalStorage';

const getProjectId = (): string | null =>
    ProjectSelectors.getSelectedProjectId(ProjectStore.getState()) || ProjectLocalStorage.getProjectId();

const setProject = (selectedProject: ProjectModel): void => {
    ProjectLocalStorage.setProjectId(selectedProject.id);
    ProjectStore.dispatch(ProjectActions.updateProject(selectedProject));
    document.dispatchEvent(new Event('CLEAR_SOURCES_LIST_FILTERS', {bubbles: true}));
};

const clearProject = (): void => {
    ProjectLocalStorage.setProjectId('');
    ProjectStore.dispatch(ProjectActions.clearProject());
};

export const SelectedProject = {
    getProjectId,
    setProject,
    clearProject,
};
