/**
 * The search's subroute defined after `/:organizationId/search`.
 */
export const search: Record<string, string> = {
    '/:organizationId/search/conditions': 'viewed conditions',
    '/:organizationId/search/conditions/:conditionId': 'viewed condition edit',
    '/:organizationId/search/conditions/:conditionId/code': 'viewed condition edit code',
    '/:organizationId/search/conditions/:conditionId/note': 'viewed condition set description',
    '/:organizationId/search/conditions/new': 'viewed condition add',
    '/:organizationId/search/in-app-widgets': 'viewed in-product experiences',
    '/:organizationId/search/in-app-widgets/create': 'viewed in-product experience add',
    '/:organizationId/search/in-app-widgets/edit/:experienceName': 'viewed in-product experience edit',
    '/:organizationId/search/pipelines': 'viewed query pipelines',
    '/:organizationId/search/pipelines/:pipelineId/ab-test': 'viewed query pipeline ab test',
    '/:organizationId/search/pipelines/:pipelineId/advanced/filters': 'viewed query pipeline filters',
    '/:organizationId/search/pipelines/:pipelineId/advanced/filters/:filterId': 'viewed query pipeline filter edit',
    '/:organizationId/search/pipelines/:pipelineId/advanced/filters/:filterId/code':
        'viewed query pipeline filter edit code',
    '/:organizationId/search/pipelines/:pipelineId/advanced/filters/:filterId/copy':
        'viewed query pipeline filter copy to',
    '/:organizationId/search/pipelines/:pipelineId/advanced/filters/new': 'viewed query pipeline filter add',
    '/:organizationId/search/pipelines/:pipelineId/advanced/filters/new/code':
        'viewed query pipeline filter add with code',
    '/:organizationId/search/pipelines/:pipelineId/advanced/ranking-weights': 'viewed query pipeline ranking weights',
    '/:organizationId/search/pipelines/:pipelineId/advanced/ranking-weights/:rankingWeightId':
        'viewed query pipeline ranking weight edit',
    '/:organizationId/search/pipelines/:pipelineId/advanced/ranking-weights/:rankingWeightId/code':
        'viewed query pipeline ranking edit code',
    '/:organizationId/search/pipelines/:pipelineId/advanced/ranking-weights/:rankingWeightId/copy':
        'viewed query pipeline ranking weight copy to',
    '/:organizationId/search/pipelines/:pipelineId/advanced/ranking-weights/new':
        'viewed query pipeline ranking weight add',
    '/:organizationId/search/pipelines/:pipelineId/advanced/ranking-weights/new/code':
        'viewed query pipeline ranking weight add with code',
    '/:organizationId/search/pipelines/:pipelineId/advanced/search-settings': 'viewed query pipeline query parameters',
    '/:organizationId/search/pipelines/:pipelineId/advanced/search-settings/:queryParameterId':
        'viewed query pipeline query parameter edit',
    '/:organizationId/search/pipelines/:pipelineId/advanced/search-settings/:queryParameterId/code':
        'viewed query pipeline query parameter edit code',
    '/:organizationId/search/pipelines/:pipelineId/advanced/search-settings/:queryParameterId/copy':
        'viewed query pipeline query parameter copy to',
    '/:organizationId/search/pipelines/:pipelineId/advanced/search-settings/new':
        'viewed query pipeline query parameter add',
    '/:organizationId/search/pipelines/:pipelineId/advanced/search-settings/new/code':
        'viewed query pipeline query parameter add with code',
    '/:organizationId/search/pipelines/:pipelineId/advanced/triggers': 'viewed query pipeline triggers',
    '/:organizationId/search/pipelines/:pipelineId/advanced/triggers/:triggerId': 'viewed query pipeline trigger edit',
    '/:organizationId/search/pipelines/:pipelineId/advanced/triggers/:triggerId/code':
        'viewed query pipeline trigger edit code',
    '/:organizationId/search/pipelines/:pipelineId/advanced/triggers/:triggerId/copy':
        'viewed query pipeline trigger copy to',
    '/:organizationId/search/pipelines/:pipelineId/advanced/triggers/new': 'viewed query pipeline trigger add',
    '/:organizationId/search/pipelines/:pipelineId/advanced/triggers/new/code':
        'viewed query pipeline trigger add with code',
    '/:organizationId/search/pipelines/:pipelineId/export': 'viewed query pipeline export',
    '/:organizationId/search/pipelines/:pipelineId/import': 'viewed query pipeline import',
    '/:organizationId/search/pipelines/:pipelineId/machine-learning': 'viewed query pipeline ml model associations',
    '/:organizationId/search/pipelines/:pipelineId/machine-learning/:modelId':
        'viewed query pipeline ml model association edit',
    '/:organizationId/search/pipelines/:pipelineId/machine-learning/:modelId/json/edit':
        'viewed query pipeline ml model association edit json',
    '/:organizationId/search/pipelines/:pipelineId/machine-learning/:modelId/json/view':
        'viewed query pipeline ml model association view json',
    '/:organizationId/search/pipelines/:pipelineId/machine-learning/new':
        'viewed query pipeline ml model association add',
    '/:organizationId/search/pipelines/:pipelineId/machine-learning/new/json/edit':
        'viewed query pipeline ml model association add json',
    '/:organizationId/search/pipelines/:pipelineId/overview': 'viewed query pipeline overview',
    '/:organizationId/search/pipelines/:pipelineId/result-ranking': 'viewed query pipeline result ranking',
    '/:organizationId/search/pipelines/:pipelineId/result-ranking/featured-result/:featuredResultId':
        'viewed query pipeline featured result edit',
    '/:organizationId/search/pipelines/:pipelineId/result-ranking/featured-result/:featuredResultId/copy':
        'viewed query pipeline featured result copy to',
    '/:organizationId/search/pipelines/:pipelineId/result-ranking/featured-result/:featuredResultId/editJSON':
        'viewed query pipeline featured result edit json',
    '/:organizationId/search/pipelines/:pipelineId/result-ranking/featured-result/new':
        'viewed query pipeline featured result add',
    '/:organizationId/search/pipelines/:pipelineId/result-ranking/ranking-expression/:rankingExpressionId':
        'viewed query pipeline ranking expression edit',
    '/:organizationId/search/pipelines/:pipelineId/result-ranking/ranking-expression/:rankingExpressionId/copy':
        'viewed query pipeline ranking expression copy to',
    '/:organizationId/search/pipelines/:pipelineId/result-ranking/ranking-expression/:rankingExpressionId/editJSON':
        'viewed query pipeline ranking expression edit json',
    '/:organizationId/search/pipelines/:pipelineId/result-ranking/ranking-expression/new':
        'viewed query pipeline ranking expression add',
    '/:organizationId/search/pipelines/:pipelineId/search-terms/stop': 'viewed query pipeline stop words',
    '/:organizationId/search/pipelines/:pipelineId/search-terms/synonyms': 'viewed query pipeline thesaurus',
    '/:organizationId/search/pipelines/:pipelineId/search-terms/synonyms/:ruleId/copy':
        'viewed query pipeline thesaurus copy to',
    '/:organizationId/search/pipelines/:pipelineId/search-terms/synonyms/:thesaurusId':
        'viewed query pipeline thesaurus edit',
    '/:organizationId/search/pipelines/:pipelineId/search-terms/synonyms/:thesaurusId/code':
        'viewed query pipeline thesaurus edit code',
    '/:organizationId/search/pipelines/:pipelineId/search-terms/synonyms/new': 'viewed query pipeline thesaurus add',
    '/:organizationId/search/pipelines/:pipelineId/search-terms/synonyms/new/code':
        'viewed query pipeline thesaurus add with code',
    '/:organizationId/search/pipelines/:pipelineId/statement-groups': 'viewed groups and campaigns',
    '/:organizationId/search/pipelines/:pipelineId/statement-groups/:groupOrCampaignId':
        'viewed group or campaign edit',
    '/:organizationId/search/pipelines/:pipelineId/statement-groups/new': 'viewed group or campaign add',
    '/:organizationId/search/pipelines/new': 'viewed query pipeline add',
    '/:organizationId/search/search-pages': 'viewed search pages',
    '/:organizationId/search/search-pages/:searchPageId/share': 'viewed search page share',
    '/:organizationId/search/search-pages/:searchPageId/download': 'viewed search page download',
    '/:organizationId/search/search-pages/custom': 'viewed custom search pages',
    '/:organizationId/search/search-pages/custom/create': 'viewed custom search page create',
    '/:organizationId/search/search-pages/custom/:searchPageId/update': 'viewed custom search page update',
    '/:organizationId/search/relevanceInspector': 'viewed relevance inspector empty state',
    '/:organizationId/search/relevanceInspector/:searchuid': 'viewed relevance inspector',
};
