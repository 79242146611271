export const ReportAccessBackendType = {
    Private: 'PRIVATE',
    Public: 'PUBLIC',
};

export const ReportAccessType = {
    Private: 'private',
    Public: 'public',
    Custom: 'custom',
};
