import {ConditionModel} from '@core/api';
import {KeyValueReducerGenerator} from '@core/store';
import {stringListCompositeReducer} from '@coveord/plasma-react';
import {ReducersMapObject} from 'redux';

import {ActionsTypes} from '../constants';
import {conditionsObjectsReducer} from './ConditionsObjectsReducer';

export const conditionsReducers: ReducersMapObject = {
    condition: KeyValueReducerGenerator<ConditionModel>(ActionsTypes.editCondition),
    conditionObjects: conditionsObjectsReducer,
    conditionStatements: KeyValueReducerGenerator<ConditionModel[]>(ActionsTypes.conditionStatements, null),
    subConditions: stringListCompositeReducer,
};
