import {Config} from '@core/configuration';

export const Requests = {
    baseUrl: `${Config.CloudPlatform.url}/rest/organizations/{organizationName}/searchusagemetrics`,
    set: 'SET_SEARCH_HUBS',
    update: 'UPDATE_SEARCH_HUB',
    clear: 'CLEAR_SEARCH_HUBS',
    request: 'REQUEST_SEARCH_HUBS',
    view: 'VIEW_QUERY_LOGS',
    download: 'DOWNLOAD_QUERY_LOGS',
    Global: {
        set: 'SET_SEARCH_HUBS_GLOBAL',
        clear: 'CLEAR_SEARCH_HUBS_GLOBAL',
        request: 'REQUEST_SEARCH_HUBS_GLOBAL',
    },
    Graph: {
        set: 'SET_SEARCH_HUB_GRAPH',
        clear: 'CLEAR_SEARCH_HUB_GRAPH',
        request: 'REQUEST_SEARCH_HUB_GRAPH',
    },
    License: {
        set: 'SET_SEARCH_HUB_LICENSES',
        clear: 'CLEAR_SEARCH_HUB_LICENSES',
        request: 'REQUEST_SEARCH_HUB_LICENSES',
        update: 'UPDATE_SEARCH_HUB_LICENSES',
    },
};
