import _ from 'underscore';
import {ComponentIdMap} from '../../ComponentIdMap';
import {CardState} from '../CardState';
import {MetricCardDefaultState, MetricCardState} from '../Metric/MetricCardState';

export const isQuickMetricState = (state: CardState): state is MetricCardState =>
    state.type === ComponentIdMap.QuickMetricComponent;

export const QuickMetricDefaultState: MetricCardState = _.extend({}, MetricCardDefaultState, {
    type: ComponentIdMap.QuickMetricComponent,
});
