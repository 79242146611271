import {
    Box,
    BoxProps,
    Center,
    factory,
    Factory,
    Loader,
    StylesApiProps,
    useProps,
    useStyles,
} from '@components/mantine';
import {Outlet} from '@core/routes';
import {UserContext, UserProvider} from '@core/user';
import {ReactNode, useContext} from 'react';
import classes from './BoundaryLayout.module.css';

export interface BoundaryLayoutProps extends BoxProps, StylesApiProps<BoundaryLayoutFactory> {
    children: ReactNode;
}

export type BoundaryLayoutFactory = Factory<{
    props: BoundaryLayoutProps;
    ref: HTMLDivElement;
    stylesNames: 'root';
}>;

const defaultProps: Partial<BoundaryLayoutProps> = {};

const BoundaryLayoutContent = factory<BoundaryLayoutFactory>((props, ref) => {
    const {children, style, className, classNames, styles, unstyled, ...others} = useProps(
        'BoundaryLayout',
        defaultProps,
        props,
    );
    const getStyles = useStyles<BoundaryLayoutFactory>({
        name: 'BoundaryLayout',
        classes,
        props,
        className,
        style,
        classNames,
        styles,
        unstyled,
    });

    const userCtx = useContext(UserContext);
    if (userCtx.query?.isLoading) {
        return (
            <Center>
                <Loader />
            </Center>
        );
    }
    return (
        <Box ref={ref} {...getStyles('root')} {...others}>
            {children}
            <Outlet />
        </Box>
    );
});

export const BoundaryLayout = ({children}) => (
    <UserProvider>
        <BoundaryLayoutContent>{children}</BoundaryLayoutContent>
    </UserProvider>
);
