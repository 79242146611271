import {CaseAssistConfigurationConstants} from '../CaseAssistConfigurationConstants';

const getValidationIds = () => [
    CaseAssistConfigurationConstants.ComponentIds.NameInput,
    CaseAssistConfigurationConstants.ComponentIds.ProjectAssociationMultiSelect,
];

export const OverviewTabSelectors = {
    getValidationIds,
};
