import {RouteObject} from '@core/routes';
import {BoundaryLayout, boundaryRoutes} from '@pages/boundaries';

import {NormalLayout, RedirectToFirstPage} from '../components';
import {ErrorPage} from '../pages';
import {mainBusinessRoutes} from './mainBusinessRoutes';

export const mainRoutes: RouteObject[] = [
    {
        path: '/',
        element: <NormalLayout />,
        errorElement: <ErrorPage />,
        children: [
            ...mainBusinessRoutes,
            {path: '*', element: <RedirectToFirstPage />},
            {path: '', element: <RedirectToFirstPage />},
            {path: '/:orgId/', element: <RedirectToFirstPage />},
        ],
    },
    {
        path: '/',
        Component: BoundaryLayout,
        children: boundaryRoutes,
        errorElement: <ErrorPage />,
    },
];
