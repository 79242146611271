import {IItemBoxProps, IReduxAction} from '@coveord/plasma-react';

export const EditCardDestinationActionsType = {
    SetLandingPages: 'EDIT_CARD_DESTINATION_SET_LANDING_PAGES',
    Clear: 'EDIT_CARD_DESTINATION_CLEAR',
};

export interface EditCardDestinationSetLandingPagesPayload {
    landingPages: IItemBoxProps[];
}

export interface EditCardDestinationClearPayload {}

const setLandingPages = (landingPages: IItemBoxProps[]): IReduxAction<EditCardDestinationSetLandingPagesPayload> => ({
    type: EditCardDestinationActionsType.SetLandingPages,
    payload: {landingPages},
});

const clear = (): IReduxAction<EditCardDestinationClearPayload> => ({
    type: EditCardDestinationActionsType.Clear,
    payload: {},
});

export const EditCardDestinationActions = {
    setLandingPages,
    clear,
};
