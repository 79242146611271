import {
    InsightPanelConfigListOptions,
    InsightPanelConfigModel,
    PageModel,
    Platform,
    PlatformClientFeatures,
} from '@core/api';
import {LaunchDarklyFeatureFlags} from '@core/feature-flags';
import {CoreProjectLaunchDarklyFlags, ProjectSelectors, ProjectState} from '@core/projects';
import {setKeyValue} from '@core/store';
import {RequestsActions} from '@coveord/jsadmin-common';
import {
    ITableHOCCompositeState,
    IThunkAction,
    PaginationReduxActions,
    TableHOCUtils,
    TableWithPaginationActions,
    turnOffLoading,
} from '@coveord/plasma-react';

import {ServiceState} from '../../ServiceState';
import {InsightPanelComponentIds} from '../InsightPanelComponentIds';

export const InsightPanelConfigActionTypes = {
    fetchInsightPanelConfigs: 'FETCH_INSIGHT_PANEL_CONFIGS',
    updateInsightPanelConfigs: 'UPDATE_INSIGHT_PANEL_CONFIGS',
    delete: 'DELETE_INSIGHT_PANEL_CONFIG',
};

const getInsightPanelConfigByProject = (
    state: ProjectState,
    pageOptions: InsightPanelConfigListOptions,
): Promise<PageModel<InsightPanelConfigModel>> => {
    if (LaunchDarklyFeatureFlags.isActive(CoreProjectLaunchDarklyFlags.Projects)) {
        const project = ProjectSelectors.getSelectedProject(state);

        return Platform.withFeatures(
            // eslint-disable-next-line react-hooks/rules-of-hooks
            PlatformClientFeatures.useProjectFilter(project, 'INSIGHT_PANEL', '/ids', {}, 'ids'),
        ).insightPanelConfig.list(pageOptions);
    }

    return Platform.insightPanelConfig.list(pageOptions);
};

const fetchList = (): IThunkAction<void, ServiceState> => async (dispatch, getState) => {
    const tableId = InsightPanelComponentIds.InsightPanelConfigsTable;
    const compositeState: ITableHOCCompositeState = TableHOCUtils.getCompositeState(tableId, getState());
    const pageOptions: InsightPanelConfigListOptions = {
        page: compositeState.pageNb,
        perPage: compositeState.perPage,
        order: 'asc',
    };
    const makeRequest = async () => {
        const insightPanelConfigs = await getInsightPanelConfigByProject(getState(), pageOptions);
        if (isPagingOutOfRange(pageOptions, insightPanelConfigs.totalPages)) {
            const lastAvailablePage = Math.max(insightPanelConfigs.totalPages - 1, 0);
            dispatch(PaginationReduxActions.changePage(TableHOCUtils.getPaginationId(tableId), lastAvailablePage));
        } else {
            dispatch(setKeyValue(InsightPanelConfigActionTypes.updateInsightPanelConfigs, insightPanelConfigs.items));
            dispatch(TableWithPaginationActions.setCount(tableId, insightPanelConfigs.totalEntries));
            dispatch(turnOffLoading([tableId]));
        }
    };
    await dispatch(RequestsActions.handle(InsightPanelConfigActionTypes.fetchInsightPanelConfigs, makeRequest));
};

const isPagingOutOfRange = (pageOptions: InsightPanelConfigListOptions, totalPages: number) =>
    totalPages > 0 && (pageOptions.page ?? 0) >= totalPages;

const deleteInsightPanelConfig =
    (insightPanelConfigId: string): IThunkAction<void, ServiceState> =>
    async (dispatch) => {
        const makeRequest = async () => {
            await Platform.insightPanelConfig.delete(insightPanelConfigId);
            dispatch(InsightPanelConfigActions.fetchList());
        };
        dispatch(RequestsActions.handle(InsightPanelConfigActionTypes.delete, makeRequest));
    };

export const InsightPanelConfigActions = {
    fetchList,
    deleteInsightPanelConfig,
};
