import {PrivilegeDomains, PrivilegeOwners, PrivilegeTypes} from '@core/user';
import {PrivilegeModel} from '@core/api';

export const CreateApiKeyConstants = {
    ComponentIds: ((id: string) => ({
        SelectTypeModal: `${id}-select-type-modal`,
        CopyApiKeyModal: `${id}-copy-api-key-modal`,
        ApiKeyType: `${id}-select-api-key-type`,
    }))('CreateApiKeyComponent'),
    ActionTypes: {
        create: 'CREATE_CATALOG_API_KEY',
        updateApiKey: 'SET_CATALOG_API_KEY',
    },
};

export const getCatalogSearchApiKeyPrivileges = (): PrivilegeModel[] => [
    {
        owner: PrivilegeOwners.SearchApi,
        targetDomain: PrivilegeDomains.ExecuteQuery,
    },
];

export const getCatalogAnalyticsApiKeyPrivileges = (): PrivilegeModel[] => [
    {
        owner: PrivilegeOwners.UsageAnalytics,
        targetDomain: PrivilegeDomains.AnalyticsData,
        type: PrivilegeTypes.Edit,
    },
    {
        owner: PrivilegeOwners.UsageAnalytics,
        targetDomain: PrivilegeDomains.Impersonate,
    },
];
