import {RouteObject} from '@core/routes';
import {PlatformPaths} from '@configurations/platform';

export const routes: RouteObject[] = [
    {
        path: `${PlatformPaths.PropertiesManager.fullPath}/*`,
        lazy: async () => {
            const {ManagePropertiesPage} = await import('../pages/ManagePropertiesPage');
            return {Component: ManagePropertiesPage};
        },
    },
    {
        path: PlatformPaths.PropertiesCreate.fullPath,
        lazy: async () => {
            const {CreatePropertiesPage} = await import('../pages/CreatePropertyPage');
            return {Component: CreatePropertiesPage};
        },
    },
    {
        path: PlatformPaths.PropertiesView.fullPath,
        lazy: async () => {
            const {ViewPropertiesPage} = await import('../pages/ViewPropertyPage');
            return {Component: ViewPropertiesPage};
        },
    },
    {
        path: PlatformPaths.PropertiesEdit.fullPath,
        lazy: async () => {
            const {EditPropertiesPage} = await import('../pages/EditPropertyPage');
            return {Component: EditPropertiesPage};
        },
    },
];
