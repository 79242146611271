import {StatementGroupType} from '@core/api';

const defaultStatementGroup = {
    name: '',
    type: StatementGroupType.campaign,
    isActive: false,
    description: '',
};

export const StatementGroupsConstants = {
    Components: {
        Table: 'statement-groups-table',
        InfoBox: 'statement-groups-info-box',
        DeleteModal: 'statement-groups-delete-modal',
        AssociateGroupModal: 'statement-groups-associate-group-modal',
        PostCreationModal: 'statement-groups-post-creation-modal',
    },
    Requests: {
        fetchAll: 'FETCH_STATEMENT_GROUPS',
        fetchFacet: 'FETCH_STATEMENT_GROUPS_FACET',
        fetchSingle: 'FETCH_STATEMENT_GROUP',
        save: 'SAVE_STATEMENT_GROUP',
        toggleActive: 'TOGGLE_STATEMENT_GROUP_ACTIVE',
        delete: 'DELETE_STATEMENT_GROUP',
        batchAssociate: 'BATCH_ASSOCIATE_STATEMENT_GROUP',
    },
    ActionTypes: {
        all: 'STATEMENT_GROUPS',
        single: 'STATEMENT_GROUP',
    },
    Ids: {
        inputName: 'StatementGroupInputName',
        description: 'StatementDescription',
        selectCondition: 'StatementSelectCondition',
        dateFrom: 'StatementGroupDateFrom',
        radioDate: 'StatementGroupRadioDate',
        permanentIsEnabled: 'StatementGroupPermanentIsEnabled',
        selectQuickFilters: 'StatementGroupsQuickFilter',
    },
    Values: {
        SelectAll: '',
    },
    DocAnchors: {
        add: 'add-a-group',
        edit: 'edit-a-group',
    },
    defaultStatementGroup,
    selectGroupModalId: 'ConfirmLeaveSelectGroup',
    selectGroupDescription: 'SelectGroupDescription',
};
