/**
 * The service's subroute defined after `/:organizationId/service`.
 */
export const service: Record<string, string> = {
    '/:organizationId/service/case-assist': 'viewed case assist',
    '/:organizationId/service/case-assist/create': 'viewed case assist add',
    '/:organizationId/service/case-assist/edit/:caseAssistId': 'viewed case assist edit',
    '/:organizationId/service/insight-panel': 'viewed insight panels',
    '/:organizationId/service/insight-panel/create': 'viewed insight panel add',
    '/:organizationId/service/insight-panel/edit/:insightPanelId': 'viewed insight panel edit',
};
