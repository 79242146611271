export interface MultilineInputModelAttributes {
    value?: string;
}

export class MultilineInputModel extends Backbone.Model {
    newlyAdded = false;

    get value(): string {
        return this.get('value');
    }

    set value(value: string) {
        this.set('value', value);
    }

    defaults(): MultilineInputModelAttributes {
        return {
            value: '',
        };
    }
}
