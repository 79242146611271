import {ObjectKeyValueActions} from '@core/store';

export const CardSelectGroupActionTypes = {
    set: 'SET_CARD_SELECT_GROUP',
};

export const CardSelectGroupActions = {
    setValue: <T = unknown>(id: string, value: T) =>
        ObjectKeyValueActions.updateNested(CardSelectGroupActionTypes.set, id, {selectedValue: value}),
    remove: (id: string) => ObjectKeyValueActions.remove(CardSelectGroupActionTypes.set, id),
};
