import {IReduxAction} from '@coveord/plasma-react';

export const ReportAccessIdentityActionsType = {
    UpdateIdentity: 'REPORT_ACCESS_UPDATE_IDENTITY',
    CancelIdentityChanges: 'REPORT_ACCESS_CANCEL_IDENTITY_CHANGES',
    SetIdentity: 'REPORT_ACCESS_SET_IDENTITY',
    SetIdentityType: 'REPORT_ACCESS_SET_IDENTITY_TYPE',
    SetIsOpen: 'REPORT_ACCESS_SET_IS_OPEN',
};

export interface ReportAccessIdentityPayload {
    id: string;
}

const updateIdentity = (id: string): IReduxAction<ReportAccessIdentityPayload> => ({
    type: ReportAccessIdentityActionsType.UpdateIdentity,
    payload: {
        id,
    },
});

const cancelIdentityChanges = (id: string): IReduxAction<ReportAccessIdentityPayload> => ({
    type: ReportAccessIdentityActionsType.CancelIdentityChanges,
    payload: {
        id,
    },
});

export interface SetIdentityPayload extends ReportAccessIdentityPayload {
    identity: string;
}

const setIdentity = (id: string, identity: string): IReduxAction<SetIdentityPayload> => ({
    type: ReportAccessIdentityActionsType.SetIdentity,
    payload: {
        id,
        identity,
    },
});

export interface SetIdentityTypePayload extends ReportAccessIdentityPayload {
    identityType: string;
}

const setIdentityType = (id: string, identityType: string): IReduxAction<SetIdentityTypePayload> => ({
    type: ReportAccessIdentityActionsType.SetIdentityType,
    payload: {
        id,
        identityType,
    },
});

export interface SetIsOpenPayload extends ReportAccessIdentityPayload {
    isOpen: boolean;
}

const setIsOpen = (id: string, isOpen: boolean): IReduxAction<SetIsOpenPayload> => ({
    type: ReportAccessIdentityActionsType.SetIsOpen,
    payload: {
        id,
        isOpen,
    },
});

export const ReportAccessIdentityActions = {
    updateIdentity,
    cancelIdentityChanges,
    setIdentity,
    setIdentityType,
    setIsOpen,
};
