import {ISubNavigationItem} from '@coveord/plasma-react';

export enum ABTestRoutedSubSection {
    overview = 'overview',
    configuration = 'configuration',
}

const ABTestIDsMap = {
    subNavigationID: 'ab-test-sub-navigation',
    overview: 'overview-sub-navigation-section',
    configuration: 'configuration-sub-navigation-section',
};

const SubNavigationSections = [
    {
        id: ABTestIDsMap.overview,
        label: 'Overview',
    },
    {
        id: ABTestIDsMap.configuration,
        label: 'Configuration',
    },
] as ISubNavigationItem[];
const Components = {
    TableOverviewId: 'TableOverviewId',
    InfoBoxId: 'OverviewInfoBox',
    RatioSliderId: 'ABTestRatioSlider',
};

const ABTestActionTypes = {
    createTestQueryPipeline: 'CREATE_TEST_QUERY_PIPELINE',
};

const StatementsModalId = 'ABTestStatementModal';

const SubNavId = 'ABTestSubNav';

const RatioSliderId = 'ABTestRatioSlider';

const StopTestRadioSelectId = 'StopTestRadioSelect';

const StopModalId = 'StopModalId';

const EditModalId = 'EditModalId';

const DiscardModalId = 'DiscardTestScenarioModalId';

const KeepModalId = 'KeepTestScenarioModalId';

const StopTestChoices = {
    Keep: 'KeepTestScenario',
    Discard: 'DiscardTestScenario',
    KeepBoth: 'KeepBothPipelines',
};

const NoTestProperties = {
    splitTestEnabled: false,
    splitTestTarget: null,
    splitTestName: null,
    splitTestRatio: null,
};

const ActionBarIds = {
    pause: 'PauseABTest',
    stop: 'StopAbTest',
};
const ABTestMetrics = {
    averageNumberOfResults: 'average(numberofresults)',
    averageClickRank: 'AverageClickRank',
    performSearch: 'PerformSearch',
    clickThroughRatio: 'ClickThroughRatio',
};
const ABTestDimensions = {
    queryPipeline: 'queryPipeline',
    hasResult: 'hasResult',
    splitTestRunVersion: 'splitTestRunVersion',
};

const ABTestSplitLayoutId = 'ABTestSplitLayout';

const MetricName = {
    avgNbResults: 'averageNumberResults',
    avgClickRank: 'averageResultRank',
    performSearch: 'queryWithoutResults',
    searchClickThrough: 'searchClickThrough',
};

const BestScenario = {
    originalScenario: 'A',
    testScenario: 'B',
};

const MAX_SPLIT_TEST_NAME_LENGTH = 50;
const TRUNCATED_SPLIT_TEST_NAME_LENGTH = 29;

const ABTestPromptIds = {
    discardTestPrompt: 'discardTestScenarioModalId',
    keepTestPrompt: 'keepTestScenarioModalId',
    keepBothPrompt: 'keepBothPipelineModalId',
};

export const ABTestConstants = {
    ABTestPromptIds,
    ABTestIDsMap,
    StatementsModalId,
    SubNavId,
    ABTestSplitLayoutId,
    SubNavigationSections,
    ABTestActionTypes,
    StopTestChoices,
    StopTestRadioSelectId,
    NoTestProperties,
    ActionBarIds,
    StopModalId,
    Components,
    ABTestMetrics,
    ABTestDimensions,
    RatioSliderId,
    MetricName,
    BestScenario,
    EditModalId,
    MAX_SPLIT_TEST_NAME_LENGTH,
    TRUNCATED_SPLIT_TEST_NAME_LENGTH,
};
