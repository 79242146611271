export const Components = {
    deleteConditionPrompt: (conditionId: string) => `delete-condition-prompt-${conditionId}`,
    tableId: 'conditions-table',
    descriptionInput: 'condition-description-input',
    conditionConfigurationBoxIds: (id: string) => ({
        queryParameter: `condition-configuration-box-query-parameter-${id}`,
        contextInput: `condition-configuration-box-context-input-${id}`,
        contextType: `condition-configuration-box-context-type-${id}`,
        operator: `condition-configuration-box-operator-${id}`,
        dateOperator: `condition-configuration-box-date-operator-${id}`,
        dateValues: `condition-configuration-box-date-values-${id}`,
        values: `condition-configuration-box-values-${id}`,
        booleanValues: `condition-configuration-box-boolean-values-${id}`,
        combineOperator: `condition-configuration-box-combine-operator-${id}`,
    }),
    Edit: {
        modalId: 'edit-condition-modal',
        descriptionModalId: 'edit-condition-description-modal',
        codeModalId: 'edit-condition-with-code-modal',
        id: 'edit-condition',
    },
    defaultConditionId: 'new',
    addConditionModalId: 'select-condition-modal',
    addConditionId: 'select-condition',
    conditionsTableId: 'conditions-table',
};
