export enum QPLContextTypes {
    StringType = 'string',
    BooleanType = 'boolean',
}

export enum QPLJoinOperators {
    And = 'and',
    Or = 'or',
}

const Features = {
    Condition: 'when',
};

export const QPL = {
    Features,
};
