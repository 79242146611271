export interface DisabledRowBehaviorOptions {
    isEnabled: () => boolean;
}

// Don't forgot to add a div with wrapper class after each td in ur template
export class DisabledRowBehavior extends Marionette.Behavior {
    constructor(options?, view?) {
        super(options, view);
    }

    onDomRefresh() {
        if (this.view && this.view.model && this.view.model.isEnabled) {
            this.$el.toggleClass('disabled', !this.view.model.isEnabled());
        }
    }
}
