const extractValuesFromUADimensionValues = (value: string): string[] => {
    try {
        const parsedValue = JSON.parse(value);
        const values = Array.isArray(parsedValue)
            ? parsedValue.reduce(
                  (array, acc) => (Array.isArray(acc) || typeof acc === 'object' ? array : [...array, acc]),
                  [],
              ) // Reject array and object since we only want the root primitive values.
            : [parsedValue];
        return values.map((val: any) => val.toString());
    } catch (error) {
        return [value];
    }
};

export const ConditionsObjectsHelpers = {
    extractValuesFromUADimensionValues,
};
