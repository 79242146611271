import {SubscriptionModel} from '@core/api';
import {BasePayload, IReduxAction} from '@coveord/plasma-react';
import _ from 'underscore';

import {BaseSourceModelAttributes} from '../sources/sources/BaseSourceModel';
import {
    NotificationPayload,
    NotificationsActionTypes,
    NotificationsPayload,
    SubscriptionPayload,
} from './NotificationsActions';

export type NotificationsState = {[id: string]: NotificationSubState};

export type NotificationSubState = {
    list: SubscriptionModel[];
    showingDetailsOf: string;
    showingStatsOf: string;
    source: BaseSourceModelAttributes;
};

export const notificationInitialSubState: NotificationSubState = {
    list: [],
    showingDetailsOf: '',
    showingStatsOf: '',
    source: null,
};

const writeToState = (
    state: NotificationsState,
    id: string,
    newState: Partial<NotificationSubState>,
): NotificationsState =>
    _.isString(id)
        ? {
              ...state,
              [id]: {
                  ...notificationInitialSubState,
                  ...state[id],
                  ...newState,
              },
          }
        : state;

const set = (state: NotificationsState, {id, notifications}: NotificationsPayload): NotificationsState =>
    writeToState(state, id, {
        list: notifications,
    });

const remove = (state: NotificationsState, {id}: BasePayload): NotificationsState =>
    _.has(state, id) ? _.omit(state, id) : state;

const showDetails = (state: NotificationsState, {id, notificationId}: NotificationPayload): NotificationsState =>
    writeToState(state, id, {
        showingDetailsOf: notificationId,
        showingStatsOf: '',
    });

const showStats = (state: NotificationsState, {id, notificationId}: NotificationPayload): NotificationsState =>
    writeToState(state, id, {
        showingDetailsOf: '',
        showingStatsOf: notificationId,
    });

const clearOpened = (state: NotificationsState, {id}: BasePayload): NotificationsState =>
    writeToState(state, id, {
        showingDetailsOf: '',
        showingStatsOf: '',
        source: null,
    });

const setSourceSubscription = (state: NotificationsState, {id, source}: SubscriptionPayload) =>
    writeToState(state, id, {
        source,
    });

const reducers = {
    [NotificationsActionTypes.setList]: set,
    [NotificationsActionTypes.remove]: remove,
    [NotificationsActionTypes.showDetails]: showDetails,
    [NotificationsActionTypes.showStats]: showStats,
    [NotificationsActionTypes.clearOpened]: clearOpened,
    [NotificationsActionTypes.setSourceSubscription]: setSourceSubscription,
};

export const notificationsReducer = (state: NotificationsState = {}, action: IReduxAction<any>): NotificationsState =>
    action && action.payload && action.type && _.has(reducers, action.type)
        ? reducers[action.type](state, action.payload)
        : state;
