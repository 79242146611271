import {IReduxAction} from '@coveord/plasma-react';

import {
    EditCardDestinationActionsType,
    EditCardDestinationClearPayload,
    EditCardDestinationSetLandingPagesPayload,
} from './EditCardDestinationActions';
import {EditCardDestinationDefaultState, EditCardDestinationState} from './EditCardDestinationState';

const setLandingPages = (
    state: EditCardDestinationState,
    action: IReduxAction<EditCardDestinationSetLandingPagesPayload>,
): EditCardDestinationState => ({...state, landingPages: action.payload.landingPages});

const clear = (
    state: EditCardDestinationState,
    action: IReduxAction<EditCardDestinationClearPayload>,
): EditCardDestinationState => EditCardDestinationDefaultState;

const EditCardDestinationReducers: {[key: string]: (...args: any[]) => EditCardDestinationState} = {
    [EditCardDestinationActionsType.SetLandingPages]: setLandingPages,
    [EditCardDestinationActionsType.Clear]: clear,
};

export type EditCardDestinationPayload = EditCardDestinationSetLandingPagesPayload | EditCardDestinationClearPayload;

export const EditCardDestinationReducer = (
    state: EditCardDestinationState = EditCardDestinationDefaultState,
    action?: IReduxAction<EditCardDestinationPayload>,
): EditCardDestinationState => {
    if (EditCardDestinationReducers[action.type]) {
        return EditCardDestinationReducers[action.type](state, action);
    }

    return state;
};
