import {RealmModel} from '@core/api';

export interface SamlReduxState {
    enabled: boolean;
    realms: RealmModel[];
}

const defaultState: SamlReduxState = {
    enabled: null,
    realms: null,
};

export const SamlConstants = {
    samlActionsType: 'SAML',
    defaultState,
    Requests: {
        testSaml: 'FETCH_SAML_ENABLED',
        fetchRealms: 'FETCH_SAML_REALMS',
        fetchProvider: 'FETCH_SAML_PROVIDER',
    },
};
