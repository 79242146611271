import {ISearchEndpointOptions, SearchEndpoint} from 'coveo-search-ui';
import {Config} from '@core/configuration';
import {AccessTokenUtils} from '@core/authentication';

export const configureCloudV2Endpoint = (
    organizationId: string,
    viewAllContent: boolean,
    endpointOptions: ISearchEndpointOptions = {},
) => {
    const newEndpointOptions = {...endpointOptions};

    if (viewAllContent) {
        newEndpointOptions.queryStringArguments = {
            organizationId,
            viewAllContent,
        };
    }

    SearchEndpoint.configureCloudV2Endpoint(
        organizationId,
        AccessTokenUtils.getToken(),
        Config.SearchApi.url,
        newEndpointOptions,
    );
};
