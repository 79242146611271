import {CrawlingModuleEntity, Platform} from '@core/api';
import {setKeyValue} from '@core/store';
import {RequestsActions} from '@coveord/jsadmin-common';
import {IThunkAction} from '@coveord/plasma-react';
import {AdminState} from '../../../../application/Reducers';

export const CrawlingModuleActionTypes = {
    fetch: 'FETCH_CRAWLING_MODULES',
    set: 'SET_CRAWLING_MODULES',
};

const fetchCrawlingModules = (): IThunkAction<Promise<CrawlingModuleEntity[]>, AdminState> => async (dispatch) => {
    const makeRequest = async () => {
        const crawlingModules = await Platform.crawlingModule.list();
        dispatch(setKeyValue(CrawlingModuleActionTypes.set, crawlingModules));
        return crawlingModules;
    };
    return dispatch(RequestsActions.handle(CrawlingModuleActionTypes.fetch, makeRequest));
};

export const CrawlingModuleActions = {
    fetchCrawlingModules,
};
