import {SourceModel} from '@core/api';
import {KeyValueReducerGenerator} from '@core/store';
import {combineReducers} from 'redux';
import {ISourcesState} from '../CatalogState';
import {SourcesActionsTypes} from './SourcesActions';

export const sourcesReducer = combineReducers<ISourcesState>({
    data: KeyValueReducerGenerator<SourceModel[]>(SourcesActionsTypes.updateSources, []),
    map: KeyValueReducerGenerator<Map<string, string>>(SourcesActionsTypes.updateSourceIdToNameMap, new Map()),
});
