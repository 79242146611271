import {notifications} from '@components/mantine';
import {Config} from '@core/configuration';
// TODO UITOOL 8786
// eslint-disable-next-line @helpers/no-jsadmin-import
import {RequestsActions} from '@coveord/jsadmin-common';
import {IReduxAction, IThunkAction} from '@coveord/plasma-react';

import {ObjectsActionsTypes, Requests} from '../constants';
import {ConditionsObjectsHelpers} from '../helpers';
import {
    ConditionsState,
    IConditionObjects,
    IConditionObjectsContextKeysPayload,
    IConditionObjectsPayload,
    IConditionObjectsSearchHubPayload,
    IContextKeyPair,
} from '../interfaces';
import {ConditionsSelectors} from '../selectors';
import {Locales} from '../strings/Locales';

// TODO: Refactor file (SEARCHAPI-7187)
interface IUADimensionValues {
    values: any[];
}

const popularValuesHistoricPeriodInDays = 7;

const addConditionObjects = (conditionObjects: IConditionObjects[]): IReduxAction<IConditionObjectsPayload> => ({
    type: ObjectsActionsTypes.addConditionObjects,
    payload: {
        conditionObjects,
    },
});

const addPopularSearchHubs = (popularSearchHubs: string[]): IReduxAction<IConditionObjectsSearchHubPayload> => ({
    type: ObjectsActionsTypes.addPopularSearchHubs,
    payload: {
        popularSearchHubs,
    },
});

const addPopularContextKeys = (
    popularContextKeys: IContextKeyPair[],
): IReduxAction<IConditionObjectsContextKeysPayload> => ({
    type: ObjectsActionsTypes.addPopularContextKeys,
    payload: {
        popularContextKeys,
    },
});

const addPopularContextKeyValues = (key: string, values: string[]): IReduxAction<IContextKeyPair> => ({
    type: ObjectsActionsTypes.addPopularContextKeyValues,
    payload: {
        key,
        values,
    },
});

const cleanConditionObjects = (): IReduxAction<IConditionObjectsPayload> => ({
    type: ObjectsActionsTypes.cleanConditionObjects,
});

const pendingConditionObjects = (): IReduxAction<IConditionObjectsPayload> => ({
    type: ObjectsActionsTypes.pendingConditionObjects,
});

const failConditionObjects = (): IReduxAction<IConditionObjectsPayload> => ({
    type: ObjectsActionsTypes.failConditionObjects,
});

const fetchConditionObjects =
    (organizationId: string = '', reset: boolean = false): IThunkAction<JQueryPromise<any>, ConditionsState> =>
    (dispatch, getState) => {
        const conditionObjects = ConditionsSelectors.getConditionObjects(getState());
        if (!conditionObjects.length || reset) {
            dispatch(pendingConditionObjects());
            dispatch(RequestsActions.request(Requests.fetchObjects));
            return $.get(`${Config.SearchApi.url}/admin/pipelines/config/objects`)
                .then((response: IConditionObjects[]) => {
                    dispatch(addConditionObjects(response));
                    dispatch(RequestsActions.success(Requests.fetchObjects));
                })
                .fail((xhr: JQueryXHR) => {
                    (xhr as any).errorHandled = true;
                    notifications.showError({
                        title: Locales.format('conditions.fetchConditionObjectsError'),
                        message: xhr.responseJSON && xhr.responseJSON.message,
                    });
                    dispatch(RequestsActions.failure(Requests.fetchObjects, xhr.responseJSON));
                    dispatch(failConditionObjects());
                });
        }
        return $.Deferred<IContextKeyPair[]>().resolve([]).promise();
    };

const fetchCustomDimensionValues =
    (organizationId: string, contextKeyPair: IContextKeyPair): IThunkAction<void, ConditionsState> =>
    (dispatch) => {
        const requestName = `${ObjectsActionsTypes.requestDimensionValues}_${contextKeyPair.dimensionName}`;
        dispatch(RequestsActions.request(requestName));

        const {key, dimensionName} = contextKeyPair;
        // Date range for popular values
        const to = new Date();
        const from = new Date();
        from.setDate(from.getDate() - popularValuesHistoricPeriodInDays);

        // https://usageanalytics.coveo.com/docs/read/#!/Dimensions_API_-_Version_15/get_v15_dimensions_custom_dimension_values
        $.get(`${Config.UsageAnalytics.url}/dimensions/custom/${dimensionName}/values`, {
            org: organizationId,
            from: from.toISOString(),
            to: to.toISOString(),
        }).then(
            (response: IUADimensionValues) => {
                const values = response.values
                    .filter((value) => !!value[dimensionName] && value[dimensionName].toString().length <= 100)
                    .sort((valueA, valueB) => valueA?.OccurrenceCount - valueB?.OccurrenceCount)
                    .reverse()
                    .map((resultPair: any) => {
                        const value = resultPair[dimensionName];
                        return ConditionsObjectsHelpers.extractValuesFromUADimensionValues(value);
                    })
                    .flat()
                    .reduce((result, acc) => (result.includes(acc) ? result : [...result, acc]), []);

                dispatch(addPopularContextKeyValues(key, values));
                dispatch(RequestsActions.success(requestName));
            },
            (xhr: JQueryXHR) => {
                (xhr as any).errorHandled = true;
                dispatch(RequestsActions.failure(requestName));
            },
        );
    };

export const ConditionsObjectsActions = {
    fetchConditionObjects,
    fetchCustomDimensionValues,
    addConditionObjects,
    addPopularSearchHubs,
    addPopularContextKeys,
    addPopularContextKeyValues,
    cleanConditionObjects,
};
