import {IReduxAction} from '@coveord/plasma-react';

export interface IObjectKeyValuePayload {
    actionType: ObjectActionTypes;
}

type ObjectActionTypes = 'UPDATE_NESTED_OBJECT' | 'REPLACE_OBJECT' | 'UPDATE_OBJECT' | 'REMOVE_OBJECT';

export interface IUpdateNestedObjectKeyValuePayload<T extends object> extends IObjectKeyValuePayload {
    key: keyof T;
    value: Partial<T[keyof T]>;
}

const updateNestedObjectKeyValue = <T extends object>(
    type: string,
    key: keyof T,
    value: Partial<T[typeof key]>,
): IReduxAction<IUpdateNestedObjectKeyValuePayload<T>> => ({
    type,
    payload: {actionType: 'UPDATE_NESTED_OBJECT', key, value},
});

const updateNestedObjectKeyValueReducer = <S extends object>(
    state: S,
    action: IReduxAction<IUpdateNestedObjectKeyValuePayload<Partial<S>>>,
): S =>
    action.payload
        ? {
              ...state,
              [action.payload.key]: {
                  ...((state && state[action.payload.key]) || {}),
                  ...action.payload.value,
              },
          }
        : state;

export interface IUpdateObjectKeyValuePayload<T> extends IObjectKeyValuePayload {
    value: Partial<T>;
}

const updateObjectKeyValue = <T>(type: string, value: Partial<T>): IReduxAction<IUpdateObjectKeyValuePayload<T>> => ({
    type,
    payload: {actionType: 'UPDATE_OBJECT', value},
});

const updateObjectKeyValueReducer = <T extends object>(
    state: T,
    action: IReduxAction<IUpdateObjectKeyValuePayload<T>>,
): T =>
    action.payload
        ? {
              ...state,
              ...action.payload.value,
          }
        : state;

export interface IReplaceObjectKeyValuePayload<T> extends IObjectKeyValuePayload {
    values: T;
}

const replaceObjectKeyValue = <T>(type: string, values: T): IReduxAction<IReplaceObjectKeyValuePayload<T>> => ({
    type,
    payload: {actionType: 'REPLACE_OBJECT', values},
});

const replaceObjectKeyValueReducer = <T extends object>(
    state: T,
    action: IReduxAction<IReplaceObjectKeyValuePayload<T>>,
): T =>
    action.payload
        ? {
              ...action.payload.values,
          }
        : state;

export interface IRemoveObjectKeyValuePayload<T> extends IObjectKeyValuePayload {
    key: keyof T;
}

const removeObjectKeyValue = <T extends object>(
    type: string,
    key: keyof T,
): IReduxAction<IRemoveObjectKeyValuePayload<T>> => ({
    type,
    payload: {actionType: 'REMOVE_OBJECT', key},
});

const removeObjectKeyValueReducer = <T extends object>(
    state: T,
    action: IReduxAction<IRemoveObjectKeyValuePayload<T>>,
): T => {
    if (action.payload) {
        const {[action.payload.key]: _omitted, ...rest} = state;
        return rest as T;
    } else {
        return state;
    }
};

const reducers: Record<
    ObjectActionTypes,
    <T extends object>(
        state: T,
        action: IReduxAction<
            | IUpdateNestedObjectKeyValuePayload<T>
            | IUpdateObjectKeyValuePayload<T>
            | IReplaceObjectKeyValuePayload<T>
            | IRemoveObjectKeyValuePayload<T>
        >,
    ) => T
> = {
    UPDATE_NESTED_OBJECT: updateNestedObjectKeyValueReducer,
    REPLACE_OBJECT: replaceObjectKeyValueReducer,
    UPDATE_OBJECT: updateObjectKeyValueReducer,
    REMOVE_OBJECT: removeObjectKeyValueReducer,
};

export const ObjectKeyValueActions = {
    updateNested: updateNestedObjectKeyValue,
    replace: replaceObjectKeyValue,
    update: updateObjectKeyValue,
    remove: removeObjectKeyValue,
};

export const ObjectReducerGenerator =
    <T extends object>(actionType: string, defaultState: T = {} as T) =>
    (
        state = defaultState,
        action: IReduxAction<
            | IUpdateNestedObjectKeyValuePayload<T>
            | IUpdateObjectKeyValuePayload<T>
            | IReplaceObjectKeyValuePayload<T>
            | IRemoveObjectKeyValuePayload<T>
        >,
    ): T => {
        if (
            action.type === actionType &&
            action.payload &&
            Object.prototype.hasOwnProperty.call(reducers, action.payload.actionType)
        ) {
            return reducers[action.payload.actionType](state, action);
        }

        return state;
    };
