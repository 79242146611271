import {notifications} from '@components/mantine';
import {PlatformNoHandlers} from '@core/api';
import {RequestsActions} from '@coveord/jsadmin-common';
import {IReduxAction, IThunkAction} from '@coveord/plasma-react';

import {AdminState} from '../../application/Reducers';
import {Locales} from '../../Locales';

export interface ResourceTypesActionPayload {
    resourceTypes: string[];
}

export const ResourceTypesActionTypes = {
    set: 'SET_RESOURCE_TYPES',
};

export const ResourceTypesRequests = {
    fetch: 'FETCH_RESOURCE_TYPES',
};

const set = (resourceTypes: string[]): IReduxAction<ResourceTypesActionPayload> => ({
    type: ResourceTypesActionTypes.set,
    payload: {resourceTypes},
});

const fetch = (): IThunkAction<void, AdminState> => (dispatch) => {
    const getResourceTypes = async () => {
        try {
            const resourceTypes = await PlatformNoHandlers.activity.getResourceTypes();
            dispatch(set(resourceTypes));
        } catch (e) {
            notifications.showError(Locales.format('resourceTypesFetchError'));
        }
    };
    return dispatch(RequestsActions.handle(ResourceTypesRequests.fetch, getResourceTypes));
};

export const ResourceTypesActions = {
    fetch,
    set,
};
