import {deepClone} from '@coveord/jsadmin-common';
import {
    DropdownSearchActions,
    IDropdownOption,
    IOptionsDropdownSearchPayload,
    IReduxAction,
} from '@coveord/plasma-react';
import _ from 'underscore';
import {Locales} from '../../../../../Locales';
import {SfObjectFieldTypes} from '../../fields/SfObjectFieldModel';
import {KbPublishStatus} from '../SfObjectModel';
import {SfObjectConditionOperators} from './operators/SfObjectConditionsOperators';
import {SfObjectConditionsAction, SfObjectConditionsActionType} from './SfObjectConditionsActions';
import {SfObjectConditionsUtils} from './SfObjectConditionsUtils';

export interface FlattenedSfObjectCondition {
    id: string;
    fieldDisplayName?: string;
    fieldName: string;
    fieldType: string;
    operator: string;
    values: string[];
}

export const SfFlattenedConditionDisplayedProperty = {
    FieldName: 'fieldName',
    Operator: 'operator',
    Values: 'values',
};

export interface SfObjectConditionsCompositeState {
    conditions: FlattenedSfObjectCondition[];
    conditionAdder: FlattenedSfObjectCondition;
    kbPublishStatus?: KbPublishStatus;
}

export interface SfObjectConditionsActionsReducers {
    [key: string]: (
        state: SfObjectConditionsCompositeState,
        action: IReduxAction<any>,
    ) => SfObjectConditionsCompositeState;
}

export const setSfObjectConditionsInitialStateReducer = (
    state: SfObjectConditionsCompositeState,
    action: SfObjectConditionsAction,
): SfObjectConditionsCompositeState => action.payload.sfObjectConditionsCompositeState;

export const changeConditionPropertyValueReducer = (
    state: SfObjectConditionsCompositeState,
    action: SfObjectConditionsAction,
): SfObjectConditionsCompositeState => {
    const newState: SfObjectConditionsCompositeState = deepClone(state);
    const {option, property, index} = action.payload;

    const previousOperator = _.isNumber(index) ? state.conditions[index].operator : state.conditionAdder.operator;

    const conditionToModify = _.isNumber(index) ? newState.conditions[index] : newState.conditionAdder;

    conditionToModify[property] =
        property === SfFlattenedConditionDisplayedProperty.Values ? [option.value] : option.value;

    const hasSwitchedConditionType: boolean =
        SfObjectConditionsUtils.isInCondition(previousOperator) !==
        SfObjectConditionsUtils.isInCondition(conditionToModify.operator);
    if (SfFlattenedConditionDisplayedProperty.Operator === property && hasSwitchedConditionType) {
        conditionToModify.values = SfObjectConditionsUtils.isInCondition(conditionToModify.operator) ? [] : [''];
    }

    if (property === SfFlattenedConditionDisplayedProperty.FieldName) {
        conditionToModify.fieldDisplayName = option.displayValue;
        if (conditionToModify.fieldType !== option.fieldType) {
            conditionToModify.fieldType = option.fieldType;
        }
    }

    conditionToModify.id = SfObjectConditionsUtils.generateNewId();

    return newState;
};

export const toggleKbPublishStatusReducer = (
    state: SfObjectConditionsCompositeState,
    action: SfObjectConditionsAction,
): SfObjectConditionsCompositeState => {
    const newState: SfObjectConditionsCompositeState = deepClone(state);
    newState.kbPublishStatus[action.payload.statusType] = !newState.kbPublishStatus[action.payload.statusType];

    return newState;
};

export const addConditionReducer = (state: SfObjectConditionsCompositeState): SfObjectConditionsCompositeState => {
    const newState: SfObjectConditionsCompositeState = deepClone(state);

    newState.conditionAdder.id = SfObjectConditionsUtils.generateNewId();
    newState.conditions = [deepClone(newState.conditionAdder), ...newState.conditions];

    newState.conditionAdder = _.extend(newState.conditionAdder, {
        id: SfObjectConditionsUtils.generateNewId(),
        fieldName: Locales.format('selectFieldName'),
        operator: Locales.format('selectOperator'),
        values: [''],
        fieldType: SfObjectFieldTypes.String,
    });

    return newState;
};

export const removeConditionReducer = (
    state: SfObjectConditionsCompositeState,
    action: SfObjectConditionsAction,
): SfObjectConditionsCompositeState => {
    const newState: SfObjectConditionsCompositeState = deepClone(state);
    newState.conditions.splice(action.payload.index, 1);

    // reassign a new id to each condition otherwise it won't re-render properly and will break the UI
    newState.conditions = SfObjectConditionsUtils.assignNewIds(newState.conditions);

    return newState;
};

export const clearConditionsReducer = (state: SfObjectConditionsCompositeState): SfObjectConditionsCompositeState =>
    _.extend(deepClone(state), {conditions: []});

export const copyMultiselectSelectedOptionsInConditionReducer = (
    state: SfObjectConditionsCompositeState,
    action: IReduxAction<IOptionsDropdownSearchPayload>,
): SfObjectConditionsCompositeState => {
    const newState: SfObjectConditionsCompositeState = deepClone(state);
    const conditionId = action.payload.id.split('-value')[0];
    const conditionToModify =
        _.findWhere(newState.conditions, {id: conditionId}) ||
        (newState.conditionAdder.id === conditionId ? newState.conditionAdder : undefined);

    if (conditionToModify) {
        if ((action as any).type === DropdownSearchActions.deselectAllOptions) {
            conditionToModify.values = [];
        } else if ((action as any).type === DropdownSearchActions.deselectOption) {
            conditionToModify.values.splice(conditionToModify.values.indexOf(action.payload.selectedOptionValue), 1);
        } else {
            conditionToModify.values = action.payload.dropdownOptions
                .filter((option: IDropdownOption) => option.selected)
                .map((option: IDropdownOption) => option.value);
        }

        return newState;
    }

    return state;
};

export const sfObjectConditionsActionsReducers: SfObjectConditionsActionsReducers = {
    [SfObjectConditionsActionType.SetSfObjectConditionsInitialState]: setSfObjectConditionsInitialStateReducer,
    [SfObjectConditionsActionType.ChangeConditionPropertyValue]: changeConditionPropertyValueReducer,
    [SfObjectConditionsActionType.ToggleKbPublishStatus]: toggleKbPublishStatusReducer,
    [SfObjectConditionsActionType.AddCondition]: addConditionReducer,
    [SfObjectConditionsActionType.RemoveCondition]: removeConditionReducer,
    [SfObjectConditionsActionType.ClearConditions]: clearConditionsReducer,
    [DropdownSearchActions.close]: copyMultiselectSelectedOptionsInConditionReducer,
    [DropdownSearchActions.deselectOption]: copyMultiselectSelectedOptionsInConditionReducer,
    [DropdownSearchActions.deselectAllOptions]: copyMultiselectSelectedOptionsInConditionReducer,
};

export const defaultSfObjectConditionsCompositeState: SfObjectConditionsCompositeState = {
    conditionAdder: {
        id: 'default',
        fieldName: '',
        fieldType: '',
        operator: SfObjectConditionOperators.EQUALS,
        values: [''],
    },
    conditions: [],
    kbPublishStatus: {online: false, archived: false, draft: false},
};

export const sfObjectConditionsReducers = (
    state: SfObjectConditionsCompositeState = defaultSfObjectConditionsCompositeState,
    action: SfObjectConditionsAction,
) =>
    !_.isUndefined(sfObjectConditionsActionsReducers[action.type])
        ? sfObjectConditionsActionsReducers[action.type](state, action)
        : state;
