import {
    CaseClassificationAxonConfigurationModel,
    CaseClassificationSomeConfigurationModel,
    CaseClassificationStrategies,
    Platform,
} from '@core/api';
import {setKeyValue} from '@core/store';
import {RequestsActions} from '@coveord/jsadmin-common';
import {
    changeInputValue,
    IThunkAction,
    ListBoxReduxActions,
    ValidationActions,
    ValidationTypes,
} from '@coveord/plasma-react';
import {ServiceState} from '../../../ServiceState';
import {CaseAssistConfigurationConstants} from '../CaseAssistConfigurationConstants';
import {ModalSelectActions} from '../modalSelect/ModalSelectActions';
import {createWithEmptyStateTabReducer} from '../WithEmptyStateTabReducer';
import {createWithQuerySyntaxValidationReducer} from '../WithQuerySyntaxValidationReducer';
import {CaseClassificationSelectors} from './CaseClassificationSelectors';
import {FieldsToPredictActions, FieldsToPredictActionTypes} from './FieldsToPredictActions';

const withEmptyStateReducer = createWithEmptyStateTabReducer(
    CaseAssistConfigurationConstants.Scopes.CaseClassification,
);
const withQuerySyntaxValidationReducer = createWithQuerySyntaxValidationReducer(
    CaseAssistConfigurationConstants.Scopes.CaseClassification,
);

export const CaseClassificationActionTypes = {
    ...withEmptyStateReducer.actionTypes,
    ...withQuerySyntaxValidationReducer.actionTypes,
    ...FieldsToPredictActionTypes,
    fetchModels: 'CASE_CLASSIFICATION_FETCH_AXON_MODELS',
    updateModels: 'CASE_CLASSIFICATION_UPDATE_AXON_MODELS',
};

const componentIds = {
    filter: CaseAssistConfigurationConstants.ComponentIds.CaseClassification.FilterInput,
    fieldsToPredict: CaseAssistConfigurationConstants.ComponentIds.CaseClassification.FieldsToPredictInput,
    strategySelect: CaseAssistConfigurationConstants.ComponentIds.CaseClassification.StrategyModalSelect,
    modelSelect: CaseAssistConfigurationConstants.ComponentIds.CaseClassification.ModelSelect,
};

const fetchModels = (): IThunkAction => (dispatch) => {
    const makeRequest = async () => {
        const models = await Platform.ml.models.list([
            CaseAssistConfigurationConstants.MLEngineIds.CaseClassificationModel,
        ]);
        dispatch(setKeyValue(CaseClassificationActionTypes.updateModels, models));
        return models;
    };
    return dispatch(RequestsActions.handle(CaseClassificationActionTypes.fetchModels, makeRequest));
};

const clearSomeConfigForm = (): IThunkAction<void, ServiceState> => async (dispatch) => {
    dispatch(changeInputValue(componentIds.filter, ''));
    dispatch(FieldsToPredictActions.setFields(componentIds.fieldsToPredict, []));

    dispatch(ValidationActions.clearDirty(componentIds.filter, ValidationTypes.wrongInitialValue));
    dispatch(ValidationActions.clearError(componentIds.filter, ValidationTypes.nonEmpty));
};

const clearAxonConfigForm = (): IThunkAction<void, ServiceState> => async (dispatch) => {
    dispatch(ListBoxReduxActions.selectListBoxOption(componentIds.modelSelect, false, ''));
};

const clearPendingChanges = (): IThunkAction<void, ServiceState> => async (dispatch, getState) => {
    const state = getState();
    dispatch(withEmptyStateReducer.actions.endConfiguring());

    const config = CaseClassificationSelectors.getSavedConfiguration(state);

    dispatch(ModalSelectActions.setModalSelect(componentIds.strategySelect, config?.strategy));

    switch (config?.strategy) {
        case CaseClassificationStrategies.Some:
            dispatch(clearAxonConfigForm());
            const someConfig = config as CaseClassificationSomeConfigurationModel;

            dispatch(changeInputValue(componentIds.filter, someConfig?.filter));
            dispatch(ValidationActions.clearDirty(componentIds.filter, ValidationTypes.wrongInitialValue));
            dispatch(ValidationActions.clearError(componentIds.filter, ValidationTypes.nonEmpty));
            dispatch(
                FieldsToPredictActions.setFields(
                    componentIds.fieldsToPredict,
                    someConfig?.fieldsToPredict!.map((field) => field.name),
                ),
            );
            break;
        case CaseClassificationStrategies.Axon:
            dispatch(clearSomeConfigForm());
            const axonConfig = config as CaseClassificationAxonConfigurationModel;

            dispatch(ListBoxReduxActions.selectListBoxOption(componentIds.modelSelect, false, axonConfig.modelId));
            dispatch(ValidationActions.clearDirty(componentIds.modelSelect, ValidationTypes.wrongInitialValue));
            dispatch(ValidationActions.clearError(componentIds.modelSelect, ValidationTypes.nonEmpty));
            break;
        default:
            break;
    }

    return true;
};

export const CaseClassificationActions = {
    ...withEmptyStateReducer.actions,
    ...withQuerySyntaxValidationReducer.actions,
    fetchModels,
    clearPendingChanges,
};
