import * as ReactVapor from '@coveord/plasma-react';
import _ from 'underscore';
import {
    ReportAccessIdentityActionsType,
    ReportAccessIdentityPayload,
    SetIdentityPayload,
    SetIdentityTypePayload,
    SetIsOpenPayload,
} from '../actions/ReportAccessIdentityActions';
import {ReportAccessIdentityTypes} from '../ReportAccessIdentityTypes';
import {ReportAccessEditIdentityState, ReportAccessState} from '../ReportAccessState';
import {ReportAccessReducerUtils} from './ReportAccessReducerUtils';

export const ReportAccessIdentityDefaultState: ReportAccessEditIdentityState = {
    appliedState: null,
    editionState: {
        identityType: ReportAccessIdentityTypes.User,
        identity: '',
    },
    id: null,
    isOpen: false,
};

const setIsOpenReducer = (state: ReportAccessEditIdentityState, action: ReactVapor.IReduxAction<SetIsOpenPayload>) =>
    _.extend({}, state, {isOpen: action.payload.isOpen});

const setIdentityReducer = (
    state: ReportAccessEditIdentityState,
    action: ReactVapor.IReduxAction<SetIdentityPayload>,
) => {
    const editionState = _.extend({}, state.editionState, {identity: action.payload.identity});
    return _.extend({}, state, {editionState});
};

const setIdentityTypeReducer = (
    state: ReportAccessEditIdentityState,
    action: ReactVapor.IReduxAction<SetIdentityTypePayload>,
    fullState: ReportAccessState,
) => {
    const isUserType = action.payload.identityType === ReportAccessIdentityTypes.User;
    const usersWithoutSelected = ReportAccessReducerUtils.GetUsersWithoutSelected(fullState);
    const groupsWithoutSelected = ReportAccessReducerUtils.GetGroupsWithoutSelected(fullState);

    const identity = isUserType
        ? usersWithoutSelected.length && usersWithoutSelected[0].username
        : groupsWithoutSelected.length && groupsWithoutSelected[0].id;

    const editionState = _.extend({}, state.editionState, {
        identityType: action.payload.identityType,
        identity: identity,
    });
    return _.extend({}, state, {editionState});
};

const updateIdentityReducer = (
    state: ReportAccessEditIdentityState,
    action: ReactVapor.IReduxAction<ReportAccessIdentityPayload>,
) =>
    _.extend({}, state, {
        appliedState: _.extend({}, state.editionState),
        isOpen: false,
    });

const cancelIdentityChangesReducer = (
    state: ReportAccessEditIdentityState,
    action: ReactVapor.IReduxAction<ReportAccessIdentityPayload>,
    fullState: ReportAccessState,
) =>
    _.extend({}, state, {
        editionState: _.isNull(state.appliedState)
            ? ReportAccessReducerUtils.GetAddEditionState(fullState)
            : state.appliedState,
        isOpen: false,
    });

const ReportAccessIdentityActionReducers: {[key: string]: (...args: any[]) => any} = {
    [ReportAccessIdentityActionsType.UpdateIdentity]: updateIdentityReducer,
    [ReportAccessIdentityActionsType.CancelIdentityChanges]: cancelIdentityChangesReducer,
    [ReportAccessIdentityActionsType.SetIdentity]: setIdentityReducer,
    [ReportAccessIdentityActionsType.SetIdentityType]: setIdentityTypeReducer,
    [ReportAccessIdentityActionsType.SetIsOpen]: setIsOpenReducer,
};

export interface ReportAccessIdentityActionPayloads
    extends ReportAccessIdentityPayload,
        SetIdentityPayload,
        SetIdentityTypePayload,
        SetIsOpenPayload {
    // nothing to add
}

export const ReportAccessIdentityReducer = (
    state: ReportAccessEditIdentityState,
    action: ReactVapor.IReduxAction<ReportAccessIdentityActionPayloads>,
    fullState: ReportAccessState,
) => {
    let newState = state;
    if (!_.isUndefined(ReportAccessIdentityActionReducers[action.type])) {
        newState = ReportAccessIdentityActionReducers[action.type](state, action, fullState);
    }
    return newState;
};
