import {combineReducers, ReducersMapObject} from 'redux';
import {ICaseAssistManagerState} from './CaseAssistState';
import {caseAssistConfigsReducer} from './caseAssistConfigTable/CaseAssistConfigsReducer';
import {editCaseAssistConfigReducer} from './editCaseAssistConfig/EditCaseAssistConfigReducer';
import {createCaseAssistConfigReducer} from './createCaseAssistConfig/CreateCaseAssistConfigReducer';

export const reducers: ReducersMapObject = {
    createCaseAssistConfig: createCaseAssistConfigReducer,
    caseAssistConfigs: caseAssistConfigsReducer,
    editCaseAssistConfig: editCaseAssistConfigReducer,
};

export const caseAssistReducer = combineReducers<ICaseAssistManagerState>(reducers);
