import {IReduxAction} from '@coveord/plasma-react';
import {LogicalIndexesActionTypes} from './LogicalIndexActions';
import {AvailableIndexes} from './LogicalIndexSelectors';

export type LogicalIndexState = {
    availableIndexes?: AvailableIndexes;
};

export const logicalIndexReducer = (state: LogicalIndexState = {}, action: IReduxAction<any>): LogicalIndexState => {
    switch (action && action.type) {
        case LogicalIndexesActionTypes.set:
            return {
                availableIndexes: _.indexBy(action.payload.logicalIndexes, 'id'),
            };
        default:
            return state || {};
    }
};
