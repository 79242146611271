import {IReduxAction} from '@coveord/plasma-react';
import {combineReducers, Reducer} from 'redux';
import _ from 'underscore';

import {RequestErrorPayload} from './RequestsActions';

export type LoadingState = {[requestName: string]: boolean};
export type ErrorState = {[requestName: string]: RequestErrorPayload};
export type SuccessState = {[requestName: string]: Date};
export type RequestsState = {
    loading: LoadingState;
    error: ErrorState;
    success: SuccessState;
};

const RequestStateName = {
    clean: 'CLEAN',
    request: 'REQUEST',
    failure: 'FAILURE',
};

const cleanCurrentState = (requestName: string, state: LoadingState | ErrorState | SuccessState) =>
    _.omit(state, requestName);

const loadingReducer = (state: LoadingState = {}, {type}: IReduxAction<any>): LoadingState => {
    const matches = /(.*)_(REQUEST|SUCCESS|FAILURE|CLEAN)$/i.exec(type);

    if (!matches) {
        return state;
    }

    const [wholeMatch, requestName, requestState] = matches;

    if (requestState === RequestStateName.clean) {
        return cleanCurrentState(requestName, state);
    }

    return {
        ...state,
        [requestName]: requestState === RequestStateName.request,
    };
};

const errorReducer = (state: ErrorState = {}, {type, payload}: IReduxAction<RequestErrorPayload>) => {
    const matches = /(.*)_(REQUEST|FAILURE|CLEAN|SUCCESS)$/i.exec(type);

    if (!matches) {
        return state;
    }

    const [wholeMatch, requestName, requestState] = matches;

    if (requestState === RequestStateName.failure) {
        return {...state, [requestName]: payload};
    }

    return _.omit(state, requestName);
};

const successReducer = (state: SuccessState = {}, {type}: IReduxAction<any>) => {
    const matches = /(.*)_(SUCCESS|CLEAN)$/i.exec(type);

    if (!matches) {
        return state;
    }

    const [wholeMatch, requestName, requestState] = matches;

    if (requestState === RequestStateName.clean) {
        return cleanCurrentState(requestName, state);
    }

    return {
        ...state,
        [requestName]: new Date(),
    };
};

export const requestsReducer: Reducer<RequestsState> = combineReducers<RequestsState>({
    loading: loadingReducer,
    error: errorReducer,
    success: successReducer,
});
