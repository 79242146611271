import {IReduxAction} from '@coveord/plasma-react';
import _ from 'underscore';
import {MetricCardUpdateDefaultTitlePayload, MetricCardUpdatePayload} from '../actions/MetricCardActions';
import {MetricCardState} from '../MetricCardState';

export const metricCardUpdateReducer = (state: MetricCardState, action: IReduxAction<MetricCardUpdatePayload>) =>
    _.extend({}, state, {value: action.payload.value});

export const metricCardUpdateDefaultTitleReducer = (
    state: MetricCardState,
    action: IReduxAction<MetricCardUpdateDefaultTitlePayload>,
) => _.extend({}, state, {defaultTitle: action.payload.title});
