import _ from 'underscore';
import {ComponentIdMap} from '../../ComponentIdMap';
import {CardDefaultState, CardState} from '../CardState';
import {CalculatedMetricFormats} from './CalculatedMetricFormats';
import {CalculatedMetricOperators} from './CalculatedMetricOperators';

export interface CalculatedMetricCardState extends CardState {
    operator: string;
    format: string;
    metricA: string;
    metricB: string;
    valueA: number;
    valueB: number;
}

export const CalculatedMetricCardDefaultState: CalculatedMetricCardState = _.extend({}, CardDefaultState, {
    type: ComponentIdMap.CalculatedMetricComponent,
    operator: CalculatedMetricOperators.Add.symbol,
    format: CalculatedMetricFormats.Default,
    metricA: null,
    metricB: null,
    valueA: null,
    valueB: null,
});
