import {Box, Button, CloseButton, Header, Modal, Progress} from '@coveord/plasma-mantine';
import {Children, ReactElement, useEffect, useMemo, useState} from 'react';
import {ModalProps} from '../modal';
import ModalWizardClasses from './ModalWizard.module.css';
import {ModalWizardStep, ModalWizardStepProps, ResolveStep} from './ModalWizardStep';

export interface ModalWizardProps extends Omit<ModalProps, 'centered' | 'title'> {
    /**
     * The label of the cancel button
     *
     * @default "Cancel"
     */
    cancelButtonLabel?: string;
    /**
     * The label of the next button
     *
     * @default "Next"
     */
    nextButtonLabel?: string;
    /**
     * The label of the previous button
     *
     * @default "Previous"
     */
    previousButtonLabel?: string;
    /**
     * The label of the finish button
     *
     * @default "Finish"
     */
    finishButtonLabel?: string;
    /**
     * A callback function that is executed when the user clicks on the next button
     */
    onNext?: (newStep: number, setCurrentStep?: React.Dispatch<number>) => unknown;
    /**
     * A callback function that is executed when the user clicks on the previous button
     */
    onPrevious?: (newStep: number) => unknown;
    /**
     * A function that is executed when user completes all the steps.
     */
    onFinish?: () => unknown;
    /**
     * Determine if user interacted with any steps in the modal wizard
     */
    isDirty?: () => boolean;
    /**
     * A function to confirm close if the state is dirty before closing
     */
    handleDirtyState?: () => boolean;
    /**
     * Children to display in modal wizard
     * */
    children?: Array<ReturnType<typeof ModalWizardStep>>;
    /**
     * Indicates if step validation should be performed when clicking on to the next step
     * If true, the next step will always be enabled
     *
     * @default false
     */
    isStepValidatedOnNext?: boolean;
    /**
     * Indicates if content was loading and its completion
     */
    isLoading?: boolean;
    /**
     * Finish button to display on the last step
     *
     * @param isValid Indicates whether the current step is valid or not
     */
    renderFinishButton?: (isValid: boolean) => React.ReactElement;
    /**
     * Render custom button in the footer
     *
     * @param isFirstStep Indicates if the current step is the first one
     */
    renderCustomButton?: (isFirstStep: boolean) => React.ReactElement;
}

interface ModalWizardType {
    (props: ModalWizardProps): ReactElement;
    Step: typeof ModalWizardStep;
}

export const ModalWizard: ModalWizardType = ({
    cancelButtonLabel = 'Cancel',
    nextButtonLabel = 'Next',
    previousButtonLabel = 'Previous',
    finishButtonLabel = 'Finish',
    opened,
    onNext,
    onPrevious,
    onClose,
    onFinish,
    isDirty,
    handleDirtyState,
    children,
    isStepValidatedOnNext,
    isLoading,
    renderFinishButton,
    renderCustomButton,
    ...modalProps
}) => {
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const modalSteps = (Children.toArray(children) as ReactElement[]).filter((child) => child.type === ModalWizardStep);

    const numberOfSteps = modalSteps.length;
    const isFirstStep = currentStepIndex === 0;
    const isLastStep = currentStepIndex === numberOfSteps - 1;
    const currentStep = modalSteps.filter((step: ReactElement, index: number) => index === currentStepIndex)[0];
    const isValidationOnNext = isStepValidatedOnNext || currentStep.props.validateOnNext;
    const {isValid} = isValidationOnNext
        ? {isValid: true}
        : (currentStep?.props?.validateStep?.(currentStepIndex, numberOfSteps) ?? {isValid: true});

    useEffect(() => {
        if (opened) {
            setCurrentStepIndex(0);
        }
    }, [opened]);

    const handleClose = (confirmDirty: boolean) => {
        if (confirmDirty) {
            const isModalDirty = isDirty?.() ?? false;
            if (isModalDirty) {
                const discardChanges = handleDirtyState?.() ?? true;
                if (!discardChanges) {
                    return;
                }
            }
        }
        onClose?.();
    };

    const resolveStepDependentProp = <P extends keyof ModalWizardStepProps>(
        prop: P,
    ): ResolveStep<ModalWizardStepProps[P]> =>
        typeof currentStep.props[prop] === 'function'
            ? currentStep.props[prop](currentStepIndex + 1, numberOfSteps)
            : currentStep.props[prop];

    const getProgress = useMemo(
        () => (currStepIndex: number) => {
            const totalNumberOfSteps = modalSteps.filter((step) => step.props.countsAsProgress).length;
            const numberOfCompletedSteps = modalSteps.filter(
                (step, index) => step.props.countsAsProgress && index <= currStepIndex,
            ).length;
            return (numberOfCompletedSteps / totalNumberOfSteps) * 100;
        },
        [isLoading],
    );

    return (
        <Modal
            opened={opened}
            classNames={{content: ModalWizardClasses.content, body: ModalWizardClasses.body}}
            centered
            onClose={() => handleClose(true)}
            withCloseButton={false}
            padding={0}
            {...modalProps}
        >
            <Modal.Header className={ModalWizardClasses.modalHeader}>
                <Header
                    p="lg"
                    pr="md"
                    w="100%"
                    variant="secondary"
                    description={resolveStepDependentProp('description')}
                    borderBottom={!currentStep.props.showProgressBar}
                >
                    {resolveStepDependentProp('title')}
                    {resolveStepDependentProp('docLink') ? (
                        <Header.DocAnchor
                            href={resolveStepDependentProp('docLink')}
                            label={resolveStepDependentProp('docLinkTooltipLabel')}
                        />
                    ) : null}
                    <Header.Actions>
                        <CloseButton aria-label={'close-modal'} onClick={() => handleClose(true)} />
                    </Header.Actions>
                </Header>
                {currentStep.props.showProgressBar && (
                    <Progress w="100%" color="navy.5" size="sm" radius={0} value={getProgress(currentStepIndex)} />
                )}
            </Modal.Header>
            <Modal.Body>
                <Box p="lg">{currentStep}</Box>
                <Modal.Footer sticky mt="auto">
                    <Button
                        name={isFirstStep ? cancelButtonLabel : previousButtonLabel}
                        variant="outline"
                        onClick={() => {
                            if (isFirstStep) {
                                handleClose(true);
                            } else {
                                onPrevious?.(currentStepIndex - 1);
                                setCurrentStepIndex(currentStepIndex - 1);
                            }
                        }}
                    >
                        {isFirstStep ? cancelButtonLabel : previousButtonLabel}
                    </Button>
                    {!!renderCustomButton && renderCustomButton(isFirstStep)}
                    {!!renderFinishButton && isLastStep ? (
                        renderFinishButton?.(isValid)
                    ) : (
                        <Button
                            disabledTooltip={currentStep.props.disabledTooltipLabel}
                            disabled={!isValid}
                            onClick={() => {
                                if (isLastStep) {
                                    onFinish?.() ?? handleClose(false);
                                } else {
                                    onNext?.(currentStepIndex + 1, setCurrentStepIndex);

                                    if (!isValidationOnNext) {
                                        setCurrentStepIndex(currentStepIndex + 1);
                                    }
                                }
                            }}
                        >
                            {isLastStep ? finishButtonLabel : nextButtonLabel}
                        </Button>
                    )}
                </Modal.Footer>
            </Modal.Body>
        </Modal>
    );
};

ModalWizard.Step = ModalWizardStep;
