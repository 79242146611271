import {SourceType} from '@core/api';
import {setKeyValue} from '@core/store';
import {changeInputValue, IThunkAction} from '@coveord/plasma-react';

import {AdminState} from '../../../../../src/application/Reducers';
import {ServiceNowSourceConstants} from '../../../sources/servicenow/ServiceNowSourceConstants';

const OAuth2ActionTypes = {
    setOAuth2Tokens: 'SET-OAUTH2-TOKENS',
};

const setServiceNowOAuth2InputData =
    ({
        oauthRefreshTokenGuid,
        instanceUrl,
        clientId,
        clientSecretGuid,
    }: Record<string, string>): IThunkAction<void, AdminState> =>
    (dispatch) => {
        dispatch(setKeyValue(OAuth2ActionTypes.setOAuth2Tokens, {oauthRefreshTokenGuid, clientId, clientSecretGuid}));
        dispatch(changeInputValue(ServiceNowSourceConstants.Components.InstanceURLInput, instanceUrl));
    };

const setOAuth2InputData = {
    [SourceType.SERVICENOW]: setServiceNowOAuth2InputData,
};

export {OAuth2ActionTypes, setOAuth2InputData};
