import _ from 'underscore';
import {MultilineInputModel} from '../multiline-input/MultilineInputModel';

export class MultilineInputCollection extends Backbone.Collection<MultilineInputModel> {
    constructor(models?: any[], options = {}) {
        super(
            models,
            _.extend(options, {
                model: MultilineInputModel,
            }),
        );
    }

    fromStringArray(values: string[]) {
        this.reset(
            _.map(
                values,
                (value) =>
                    new MultilineInputModel({
                        value: value,
                    }),
            ),
        );
    }

    fromObjectArray(objects: Array<{[key: string]: any}>, valueKey = 'value') {
        this.reset(
            _.map(
                objects,
                (obj: {[key: string]: any}) =>
                    new MultilineInputModel({
                        value: obj[valueKey],
                    }),
            ),
        );
    }

    toStringArray(): string[] {
        return this.pluck('value');
    }

    toObjectArray(valueKey = 'value'): any[] {
        return _.map(this.models, (model: MultilineInputModel) => ({[valueKey]: model.value}));
    }
}
