import {IReduxAction} from '@coveord/plasma-react';
import _ from 'underscore';
import {ICatalogsState} from '../CatalogState';
import {CatalogsActionsTypes, IUpdateCatalogsPayload} from './CatalogsActions';

const initialState: ICatalogsState = {
    data: [],
};

const updateCatalogs = (state: ICatalogsState, action: IReduxAction<IUpdateCatalogsPayload>): ICatalogsState => ({
    ...state,
    data: action.payload!.catalogs,
});

const CatalogsReducers: Record<string, (...args: any[]) => ICatalogsState> = {
    [CatalogsActionsTypes.updateCatalogs]: updateCatalogs,
};

export const catalogsReducer = (
    state: ICatalogsState = initialState,
    action: IReduxAction = {type: '', payload: {}},
) => (!_.isUndefined(CatalogsReducers[action.type]) ? CatalogsReducers[action.type](state, action) : state);
