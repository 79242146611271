import {IItemBoxProps} from '@coveord/plasma-react';

export interface EditVisitMetricTimeseriesState {
    metric: string;
    metrics: IItemBoxProps[];
    filters: string[];
    exclusionFilters: string[];
    eventsExclusion: string[];
    useExclusionFilters: boolean;
    useEventsExclusion: boolean;
}

export const EditVisitMetricTimeseriesDefaultState: EditVisitMetricTimeseriesState = {
    metric: undefined,
    metrics: [],
    filters: [],
    exclusionFilters: [],
    eventsExclusion: [],
    useExclusionFilters: false,
    useEventsExclusion: false,
};
