import {setKeyValue} from '@core/store';
import {IThunkAction} from '@coveord/plasma-react';

import {AdminState} from '../../../../application/Reducers';

export const SecurityPopupAcknowledgmentActionsType = {
    SetSecurityPopupAcknowledgment: 'SET_SECURITY_POPUP_ACKNOWLEDGMENT',
};

const setSecurityPopupAcknowledgment =
    (value: boolean): IThunkAction<void, AdminState> =>
    async (dispatch) =>
        dispatch(setKeyValue(SecurityPopupAcknowledgmentActionsType.SetSecurityPopupAcknowledgment, value));

export const SecurityPopupAcknowledgmentActions = {
    setSecurityPopupAcknowledgment,
};
