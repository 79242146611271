import {ProductHierarchyModel, VariantHierarchyModel, AvailabilityHierarchyModel} from '@core/api';

export const CatalogConfigurationConstants = {
    ComponentIds: ((id: string) => ({
        Modal: `${id}-modal`,
        DeletionConfirmationModal: `${id}-deletion-confirmation-modal`,
        NameInput: `${id}-name-input`,
        DescriptionInput: `${id}-description-input`,
        ScopeQueryInput: `${id}-scope-query-input`,
        ScopeSourceSelect: `${id}-scope-source-select`,
        ProductHierarchy: `${id}-hierarchy`,
        AvailabilityHierarchy: `${id}-availability-hierarchy`,
        AddButton: `${id}-button-add`,
        CancelButton: `${id}-button-cancel`,
        Inputs: {
            Product: {
                objectType: 'product-object-type',
                idField: 'product-id-field',
            },
            Variant: {
                objectType: 'variant-object-type',
                idField: 'variant-id-field',
            },
            Availability: {
                idField: 'availability-id-field',
                objectType: 'availability-object-type',
                skuField: 'availability-sku-field',
            },
        },
        HasVariant: `${id}-has-variant`,
        HasAvailability: `${id}-has-availability`,
        PreviewCatalogSelect: `${id}-preview-catalog-select`,
        AvailabilityPreviewCatalogSelect: `${id}-availability-preview-catalog-select`,
    }))('CatalogConfigurationComponent'),
    DirtyId: 'catalog-configuration-dirty',
    Default: {
        Product: {
            idField: 'ec_product_id',
            objectType: 'Product',
        } as ProductHierarchyModel,
        Variant: {
            idField: 'ec_variant_id',
            objectType: 'Variant',
            fields: [],
        } as VariantHierarchyModel,
        Availability: {
            availableSkusField: 'ec_available_items',
            idField: 'ec_availability_id',
            objectType: 'Availability',
            fields: [],
        } as AvailabilityHierarchyModel,
    },
    Tabs: {
        groupId: 'editconfiguration',
        overview: 'overview',
        productId: 'product',
        availabilityId: 'availability',
        standardFieldMappingId: 'standardfieldmapping',
        standardFieldsId: 'standardfields',
    },
    ActionsTypes: {
        fetch: 'FETCH_CATALOG_CONFIGURATION',
        create: 'CREATE_CATALOG_CONFIGURATION',
        update: 'UPDATE_CATALOG_CONFIGURATION',
        save: 'SAVE_CATALOG_CONFIGURATION',
        delete: 'DELETE_CATALOG_CONFIGURATION',
    },
};

export const CatalogConfigurationValidationIds = {
    Product: [
        CatalogConfigurationConstants.ComponentIds.Inputs.Product.idField,
        CatalogConfigurationConstants.ComponentIds.Inputs.Product.objectType,
        CatalogConfigurationConstants.ComponentIds.Inputs.Variant.idField,
        CatalogConfigurationConstants.ComponentIds.Inputs.Variant.objectType,
    ],
    Availability: [
        CatalogConfigurationConstants.ComponentIds.Inputs.Availability.idField,
        CatalogConfigurationConstants.ComponentIds.Inputs.Availability.objectType,
        CatalogConfigurationConstants.ComponentIds.Inputs.Availability.skuField,
    ],
};
