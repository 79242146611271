import {useInAppExperience} from '@components/navigation';
import {Guard} from '@components/security';
import {LicenseHelper, LicenseSelectors} from '@core/license';
import {Navigate, useLocation} from '@core/routes';
import {OrganizationStatusWatcher} from '@coveord/jsadmin-v2';
import {FunctionComponent} from 'react';
import {useSelector} from 'react-redux';

import {PageViewTracker} from '../../tracking';
import {SideNavigation} from '../navigation';
import {OrganizationChangeHandler, OrganizationDeprecationNotification} from '../organization';
import {Layout} from './Layout';

const ExpiredTrialHandler: FunctionComponent = () => {
    const trialExpired = useSelector(LicenseSelectors.isTrialExpired);
    const {pathname} = useLocation();

    if (trialExpired && pathname !== '/home' && pathname !== '/login') {
        return <Navigate to="/home" />;
    }

    return null;
};

export const NormalLayout: FunctionComponent = () => {
    useInAppExperience();
    return (
        <Layout>
            <Layout.Menu>
                <Guard canRender={({license}) => !LicenseHelper.isTrialExpired(license)}>
                    <SideNavigation />
                </Guard>
            </Layout.Menu>
            <Layout.Footer>
                <PageViewTracker />
                <div id="IpxScript"></div>
                <OrganizationStatusWatcher />
                <OrganizationChangeHandler />
                <ExpiredTrialHandler />
                <OrganizationDeprecationNotification />
            </Layout.Footer>
        </Layout>
    );
};
