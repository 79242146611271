import {IReduxAction} from '@coveord/plasma-react';

import {ResourceTypesActionPayload, ResourceTypesActionTypes} from './ResourceTypesActions';

export const resourceTypesInitialState: string[] = [];

export const resourceTypesReducer = (
    state: string[] = [],
    {type, payload}: IReduxAction<ResourceTypesActionPayload>,
): string[] => {
    switch (type) {
        case ResourceTypesActionTypes.set:
            return payload.resourceTypes;
        default:
            return state;
    }
};
