import {PlatformPaths} from '@configurations/platform';
import {RouteObject} from '@core/routes';

export const routes: RouteObject[] = [
    {
        path: `${PlatformPaths.ApiKeysList.fullPath}/*`,
        lazy: async () => {
            const {APIKeysListingPage} = await import('../pages/api-keys/api-keys-list/APIKeysListingPage');
            return {Component: APIKeysListingPage};
        },
    },
    {
        path: `${PlatformPaths.AddApiKey.fullPath}/*`,
        lazy: async () => {
            const {APIKeysCreationPage} = await import('../pages/api-keys/api-keys-creation/APIKeysCreationPage');
            return {Component: APIKeysCreationPage};
        },
    },
    {
        path: `${PlatformPaths.ViewApiKey.fullPath}/*`,
        lazy: async () => {
            const {ApiKeysViewPage} = await import('../pages/api-keys/api-keys-details/ApiKeysViewPage');
            return {Component: ApiKeysViewPage};
        },
    },
    {
        path: `${PlatformPaths.EditApiKey.fullPath}/*`,
        lazy: async () => {
            const {ApiKeysEditPage} = await import('../pages/api-keys/api-keys-details/ApiKeysEditPage');
            return {Component: ApiKeysEditPage};
        },
    },
];
