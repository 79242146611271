import {Platform} from '@core/api';
import {ObjectKeyValueActions} from '@core/store';
import {RequestsActions} from '@coveord/jsadmin-common';
import {IThunkAction} from '@coveord/plasma-react';
import {CommerceState} from '../../CommerceState';
import {getSourceServiceSingleton, ISourceService} from '../services/SourceService';
import {IPrerequisitesState} from './PrerequisitesReducer';

export const PrerequisitesActionTypes = {
    update: 'UPDATE_PREREQUISITES',
    hasSource: 'HAS_SOURCE_REQUEST',
    isOrganizationPaused: 'IS_ORGANIZATION_PAUSED_REQUEST',
    hasExecuteQueryPrivilege: 'EXECUTE_QUERY',
};

const validateIsOrganizationPaused =
    (orgId: string): IThunkAction<void, CommerceState> =>
    async (dispatch) => {
        const isOrganizationPaused = await dispatch(
            RequestsActions.handle(PrerequisitesActionTypes.isOrganizationPaused, async () => {
                const organization = await Platform.organization.status(orgId);
                return organization.paused;
            }),
        );
        dispatch(
            ObjectKeyValueActions.update<IPrerequisitesState>(PrerequisitesActionTypes.update, {
                isOrganizationPaused,
            }),
        );
    };

const validateHasSource =
    (orgId: string, sourceService: ISourceService = getSourceServiceSingleton()): IThunkAction<void, CommerceState> =>
    async (dispatch) => {
        const hasSource = await dispatch(
            RequestsActions.handle(PrerequisitesActionTypes.hasSource, async () => sourceService.hasSource(orgId)),
        );
        dispatch(
            ObjectKeyValueActions.update<IPrerequisitesState>(PrerequisitesActionTypes.update, {
                hasSource,
            }),
        );
    };

const validatePrerequisites =
    (organizationId: string): IThunkAction<void, CommerceState> =>
    (dispatch) => {
        dispatch(validateIsOrganizationPaused(organizationId));
        dispatch(validateHasSource(organizationId));
    };

export const PrerequisitesActions = {
    validatePrerequisites,
    validateIsOrganizationPaused,
};
