export const FieldFilterSetActionsType = {
    add: 'ADD_FIELD_FILTERS',
    remove: 'REMOVE_FIELD_FILTERS',
    edit: 'EDIT_FIELD_FILTERS',
};

const add = (id: string) => ({
    type: FieldFilterSetActionsType.add,
    payload: {id},
});

const remove = (id: string) => ({
    type: FieldFilterSetActionsType.remove,
    payload: {id},
});

export const FieldFilterSetActions = {
    add,
    remove,
};
