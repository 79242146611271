import {
    Box,
    BoxProps,
    Image,
    factory,
    useProps,
    type CompoundStylesApiProps,
    type Factory,
    type MantineSize,
} from '@components/mantine';
import {SourceType} from '@core/api';

import AdobeExperienceManager from '../../../images/source-type-image/adobe-experience-manager.svg';
import amazonS3 from '../../../images/source-type-image/amazonS3.svg';
import box from '../../../images/source-type-image/box.svg';
import catalog from '../../../images/source-type-image/catalog.svg';
import confluence from '../../../images/source-type-image/confluence.svg';
import custom from '../../../images/source-type-image/custom.svg';
import database from '../../../images/source-type-image/database.svg';
import dropbox from '../../../images/source-type-image/dropbox.svg';
import genericRest from '../../../images/source-type-image/generic-rest.svg';
import googleDrive from '../../../images/source-type-image/google-drive.svg';
import graphQl from '../../../images/source-type-image/graphql.svg';
import jira from '../../../images/source-type-image/jira.svg';
import jive from '../../../images/source-type-image/jive.svg';
import khoros from '../../../images/source-type-image/khoros.svg';
import microsoftDynamics from '../../../images/source-type-image/microsoft-dynamics.svg';
import push from '../../../images/source-type-image/push.svg';
import rss from '../../../images/source-type-image/rss.svg';
import salesforce from '../../../images/source-type-image/salesforce.svg';
import SAP from '../../../images/source-type-image/sap.svg';
import servicenow from '../../../images/source-type-image/servicenow.svg';
import sharepointOnline from '../../../images/source-type-image/sharepoint-online.svg';
import sitecore from '../../../images/source-type-image/sitecore.svg';
import sitemap from '../../../images/source-type-image/sitemap.svg';
import slack from '../../../images/source-type-image/slack.svg';
import fileType from '../../../images/source-type-image/type-file.svg';
import web from '../../../images/source-type-image/web.svg';
import youtube from '../../../images/source-type-image/youtube.svg';
import zendesk from '../../../images/source-type-image/zendesk.svg';
import {Locales} from '../../../strings';
import {useSourceIdentityContext} from '../SourceIdentityContext';

const SOURCE_TYPE_IMAGE_MAPPINGS: Record<SourceType, SVGElement> = {
    [SourceType.ADOBE_EXPERIENCE_MANAGER]: AdobeExperienceManager,
    [SourceType.AMAZONS3]: amazonS3,
    [SourceType.BOX_ENTERPRISE2]: box,
    [SourceType.CATALOG]: catalog,
    [SourceType.CONFLUENCE]: confluence,
    [SourceType.CONFLUENCE2]: confluence,
    [SourceType.CONFLUENCE2_HOSTED]: confluence,
    [SourceType.CUSTOM]: custom,
    [SourceType.DATABASE]: database,
    [SourceType.DROPBOX_FOR_BUSINESS]: dropbox,
    [SourceType.FILE]: fileType,
    [SourceType.GENERIC_REST]: genericRest,
    [SourceType.GOOGLE_DRIVE_DOMAIN_WIDE]: googleDrive,
    [SourceType.GRAPHQL]: graphQl,
    [SourceType.JIRA2]: jira,
    [SourceType.JIRA2_HOSTED]: jira,
    [SourceType.JIVE]: jive,
    [SourceType.JIVE_HOSTED]: jive,
    [SourceType.KHOROS]: khoros,
    [SourceType.LITHIUM]: khoros,
    [SourceType.MICROSOFT_DYNAMICS]: microsoftDynamics,
    [SourceType.PUSH]: push,
    [SourceType.RSS]: rss,
    [SourceType.SALESFORCE]: salesforce,
    [SourceType.SALESFORCE_SITES]: salesforce,
    [SourceType.SAP]: SAP,
    [SourceType.SERVICENOW]: servicenow,
    [SourceType.SHAREPOINT]: sharepointOnline,
    [SourceType.SHAREPOINT_ONLINE2]: sharepointOnline,
    [SourceType.SITECORE]: sitecore,
    [SourceType.SITEMAP]: sitemap,
    [SourceType.SLACK]: slack,
    [SourceType.WEB2]: web,
    [SourceType.YOUTUBE]: youtube,
    [SourceType.ZENDESK]: zendesk,
};

type SourceIdentityTypeImageSize = MantineSize | 'xxl';
export type SourceIdentityTypeImageStylesNames = 'typeImage';
export type SourceIdentityTypeImageFactory = Factory<{
    props: SourceIdentityTypeImageProps;
    ref: HTMLDivElement;
    stylesNames: SourceIdentityTypeImageStylesNames;
    compound: true;
}>;

export interface SourceIdentityTypeImageProps extends BoxProps, CompoundStylesApiProps<SourceIdentityTypeImageFactory> {
    /**
     * The image size of the type to display.
     *
     * @default: 'lg'
     */
    size: SourceIdentityTypeImageSize;
}

const defaultProps: Partial<SourceIdentityTypeImageProps> = {
    size: 'lg',
};

export const SourceIdentityTypeImage = factory<SourceIdentityTypeImageFactory>((props, ref) => {
    const {size, ...others} = useProps('SourceIdentityTypeImage', defaultProps, props);
    const {getStyles, sourceType} = useSourceIdentityContext();

    return (
        <Box ref={ref} data-size={size} {...getStyles('typeImage')} {...others}>
            <Image
                src={SOURCE_TYPE_IMAGE_MAPPINGS[sourceType]}
                aria-label={Locales.format('SourceIdentity.TypeImage.ariaLabel', {
                    sourceType: Locales.format(`SourceIdentity.sourceType.${sourceType}.label`),
                })}
            />
        </Box>
    );
});
SourceIdentityTypeImage.displayName = '@resourceIdentity/source/SourceIdentity/TypeImage';
