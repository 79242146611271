/**
 * The user's subroute defined after `/:organizationId/user`.
 */
export const user: Record<string, string> = {
    '/:organizationId/user/notifications': 'viewed user notifications',
    '/:organizationId/user/notifications/activity': 'viewed user notifications activity',
    '/:organizationId/user/notifications/add': 'viewed user notification add',
    '/:organizationId/user/notifications/edit/:notificationId': 'viewed user notification edit',
    '/:organizationId/user/notifications/statistics/:notificationId': 'viewed user notification usage statistics',
};
