export const ObjectsActionsTypes = {
    addConditionObjects: 'ADD_CONDITION_OBJECTS',
    editConditionObjects: 'EDIT_CONDITION_OBJECTS',
    cleanConditionObjects: 'CLEAN_CONDITION_OBJECTS',
    pendingConditionObjects: 'PENDING_CONDITION_OBJECTS',
    failConditionObjects: 'FAIL_CONDITION_OBJECTS',
    addPopularSearchHubs: 'ADD_POPULAR_SEARCH_HUBS',
    addPopularContextKeys: 'ADD_POPULAR_CONTEXT_KEYS',
    addPopularContextKeyValues: 'ADD_POPULAR_CONTEXT_KEY_VALUES',
    requestSearchHubs: 'REQUEST_SEARCH_HUBS',
    requestContextKeys: 'REQUEST_CONTEXT_KEYS',
    requestDimensionValues: 'REQUEST_DIMENSION_VALUES',
};
