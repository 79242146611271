import {NoteCardStyles} from './NoteCardStyles';

export interface EditNoteCardState {
    value: string;
    style: string;
    title: string;
}

export const EditNoteCardDefaultState: EditNoteCardState = {
    value: '',
    style: NoteCardStyles.Info,
    title: '',
};
