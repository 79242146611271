import {AppShell, Divider, ScrollArea} from '@components/mantine';
import {
    Navigation,
    NavigationLink,
    NavigationSearch,
    NavigationSection,
    useNavigation,
    useSections,
} from '@components/navigation';
import {Guard, useAccessControl} from '@components/security';
import {
    CommerceServiceLaunchDarklyFeatureFlags,
    PlatformLaunchDarklyFeatureFlags,
    PlatformPaths,
} from '@configurations/platform';
import {LicenseSection, ModifierType, Platform, useQuery} from '@core/api';
import {LaunchDarklyFeatureFlags, LDProject} from '@core/feature-flags';
import {LicenseHelper} from '@core/license';
import {CoreProjectLaunchDarklyFlags} from '@core/projects';
import {
    AiSize16Px,
    BehaviouralAnalyticsSize16Px,
    CartSize16Px,
    CoveoIconSize16Px,
    DatabaseSize16Px,
    HomeSize16Px,
    OrganizationSize16Px,
    SearchSize16Px,
    ServiceSupportSize16Px,
} from '@coveord/plasma-react-icons';
import {ProjectGlobalFilter, ProjectUnsupportedPanelAlert} from '@pages/project';
import {FunctionComponent} from 'react';
import {Locales} from '../../strings';

export const SideNavigation: FunctionComponent = () => {
    const {searchable} = useNavigation();
    const [expandedSections, toggleSection] = useSections();

    const {user: fetchedUser, license: fetchedLicense} = useAccessControl();

    const {data: organizationData, isLoading: isLoadingOrganizationData} = useQuery({
        queryKey: ['organization'],
        queryFn: () => Platform.organization.getDefinition(),
    });

    return (
        <Navigation.SideBar
            pt={
                LaunchDarklyFeatureFlags.isActive(CoreProjectLaunchDarklyFlags.Projects) &&
                fetchedUser.canViewProjects(fetchedLicense)
                    ? 0
                    : 'md'
            }
            pb="md"
        >
            <Guard
                canEdit={({user, license}) => user.canCreateProjects(license)}
                canRender={({user, license}) =>
                    LaunchDarklyFeatureFlags.isActive(CoreProjectLaunchDarklyFlags.Projects) &&
                    user.canViewProjects(license)
                }
            >
                <AppShell.Section display="flex">
                    <ProjectGlobalFilter />
                    <ProjectUnsupportedPanelAlert />
                </AppShell.Section>
                <Divider pb="sm" />
            </Guard>
            {searchable && (
                <AppShell.Section px="sm" pb="sm">
                    <NavigationSearch.Control />
                </AppShell.Section>
            )}
            <AppShell.Section component={ScrollArea} grow>
                <Guard canRender={({license}) => LicenseHelper.isTrial(license)}>
                    <NavigationLink
                        to="/home"
                        label={Locales.format('SideNavigation.Link.home')}
                        leftSection={<HomeSize16Px height={16} />}
                    />
                </Guard>
                <NavigationSection
                    leftSection={<DatabaseSize16Px height={16} />}
                    label={Locales.format('SideNavigation.Section.content')}
                    defaultOpened={expandedSections.content}
                    onClick={() => toggleSection('content')}
                >
                    <Guard canRender={({user}) => user.canViewSources}>
                        <NavigationLink label={Locales.format('SideNavigation.Link.sources')} to="/content/sources" />
                    </Guard>
                    <Guard canRender={({user}) => user.canViewSources}>
                        <NavigationLink label={Locales.format('SideNavigation.Link.logBrowser')} to="/logs/browser" />
                    </Guard>
                    <Guard canRender={({user}) => user.canViewFields}>
                        <NavigationLink label={Locales.format('SideNavigation.Link.fields')} to="/content/fields" />
                    </Guard>
                    <Guard canRender={({user}) => user.canExecuteQuery}>
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.contentBrowser')}
                            to="/content/browser"
                        />
                    </Guard>
                    <Guard canRender={({user}) => user.canViewExtensions}>
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.extensions')}
                            to="/content/extensions"
                        />
                    </Guard>
                    <Guard canRender={({user}) => user.canViewSecurityProviders}>
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.securityIdentities')}
                            to="/content/security-identities"
                        />
                    </Guard>
                    <Guard
                        canRender={({user, license}) =>
                            !!license.properties?.content?.onPremisesSourcesEnabled &&
                            user.canViewSources &&
                            user.canViewCrawlingModule
                        }
                    >
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.crawlingModules')}
                            to="/content/crawling-module"
                        />
                    </Guard>
                </NavigationSection>
                <NavigationSection
                    label={Locales.format('SideNavigation.Section.commerce')}
                    leftSection={<CartSize16Px height={16} />}
                    defaultOpened={expandedSections.commerce}
                    onClick={() => toggleSection('commerce')}
                >
                    <Guard canRender={({user}) => user.canViewCatalogs}>
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.catalogs')}
                            to="/commerce/catalogs"
                        />
                    </Guard>
                    <Guard canRender={({user}) => user.canViewCatalogs}>
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.storefrontAssociations')}
                            to="/commerce/storefront"
                        />
                    </Guard>
                    <Guard
                        canRender={({user}) =>
                            LaunchDarklyFeatureFlags.isActive(
                                CommerceServiceLaunchDarklyFeatureFlags.ProductListingsV1,
                                LDProject.COMMERCE_SERVICE,
                            ) && user.canViewProductListings
                        }
                    >
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.productListing')}
                            to="/commerce/productlistings"
                            badge="beta"
                        />
                    </Guard>
                </NavigationSection>
                <NavigationSection
                    label={Locales.format('SideNavigation.Section.service')}
                    leftSection={<ServiceSupportSize16Px height={16} />}
                    defaultOpened={expandedSections.service}
                    onClick={() => toggleSection('service')}
                >
                    <Guard canRender={({user}) => user.canViewCaseAssist}>
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.caseAssist')}
                            to="/service/case-assist"
                        />
                    </Guard>
                    <Guard canRender={({user}) => user.canViewInsightPanel}>
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.insightPanel')}
                            to="/service/insight-panel"
                        />
                    </Guard>
                </NavigationSection>
                <NavigationSection
                    label={Locales.format('SideNavigation.Section.search')}
                    leftSection={<SearchSize16Px height={16} />}
                    defaultOpened={expandedSections['search-optimization']}
                    onClick={() => toggleSection('search-optimization')}
                >
                    <Guard canRender={({user}) => user.canViewPipelines}>
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.queryPipelines')}
                            to="/search/pipelines"
                        />
                    </Guard>
                    <Guard canRender={({user}) => user.canViewPipelines}>
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.conditions')}
                            to="/search/conditions"
                        />
                    </Guard>
                    <Guard canRender={({user}) => user.canExecuteQuery && user.canReplayAnyQuery}>
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.relevanceInspector')}
                            to="/search/relevanceInspector"
                        />
                    </Guard>
                    <Guard canRender={({user}) => user.canViewSearchPages}>
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.searchPages')}
                            to="/search/search-pages"
                        />
                    </Guard>
                    <Guard canRender={({user}) => user.canViewSearchPages}>
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.inProductExperience')}
                            to="/search/in-app-widgets"
                        />
                    </Guard>
                </NavigationSection>
                <NavigationSection
                    label={Locales.format('SideNavigation.Section.machineLearning')}
                    leftSection={<AiSize16Px height={16} />}
                    defaultOpened={expandedSections['machine-learning']}
                    onClick={() => toggleSection('machine-learning')}
                >
                    <Guard canRender={({user}) => user.canViewPipelines && user.canViewMachineLearningModels}>
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.models')}
                            to="/machine-learning/models"
                        />
                    </Guard>
                </NavigationSection>
                <NavigationSection
                    label={Locales.format('SideNavigation.Section.analytics')}
                    leftSection={<BehaviouralAnalyticsSize16Px height={16} />}
                    defaultOpened={expandedSections.analytics}
                    onClick={() => toggleSection('analytics')}
                >
                    <Guard canRender={({user}) => user.canSeeReports}>
                        <NavigationLink label={Locales.format('SideNavigation.Link.reports')} to="/usage/reports" />
                    </Guard>
                    <Guard canRender={({user}) => user.canViewDataHealth}>
                        <NavigationLink label={Locales.format('SideNavigation.Link.dataHealth')} to="/usage/health" />
                    </Guard>
                    <Guard
                        canRender={({user}) =>
                            !isLoadingOrganizationData &&
                            organizationData?.modifiers?.filter(
                                (mod) => mod.modifierType === ModifierType.extension && mod.id.includes('Ecommerce'),
                            ).length > 0 &&
                            user.canSeeReports
                        }
                    >
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.advancedReports')}
                            to="/usage/advanced-reports"
                        />
                    </Guard>
                    <Guard canRender={({user}) => user.canViewVisitBrowser}>
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.visitBrowser')}
                            to="/usage/visit"
                            badge={
                                LaunchDarklyFeatureFlags.isActive(
                                    PlatformLaunchDarklyFeatureFlags.VisitBrowserFeatureFlag,
                                )
                                    ? 'wip'
                                    : undefined
                            }
                        />
                    </Guard>
                    <Guard
                        canRender={({user, license}) =>
                            (user.canViewExports || user.canViewSnowflakeReaderAccounts) &&
                            (license?.properties?.[LicenseSection.usageAnalytics]?.['exportEnabled'] ||
                                license?.properties?.[LicenseSection.usageAnalytics]?.['snowflakeReaderAccountEnabled'])
                        }
                    >
                        <NavigationLink label={Locales.format('SideNavigation.Link.rawData')} to="/usage/raw-data" />
                    </Guard>
                    <Guard canRender={({user}) => user.canViewDimensions}>
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.dimensions')}
                            to="/usage/dimensions"
                        />
                    </Guard>
                    <Guard canRender={({user}) => user.canViewProperties}>
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.properties')}
                            to={PlatformPaths.PropertiesManager.path}
                            badge="beta"
                        />
                    </Guard>
                    <Guard canRender={({user}) => user.canSeeNamedFilters}>
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.namedFilters')}
                            to="/usage/filters/reporting"
                        />
                    </Guard>
                    <Guard canRender={({user}) => user.canSeePermissionFilters}>
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.permissionFilters')}
                            to="/usage/filters/permissions"
                        />
                    </Guard>
                </NavigationSection>
                <NavigationSection
                    label={Locales.format('SideNavigation.Section.organization')}
                    leftSection={<OrganizationSize16Px height={16} />}
                    defaultOpened={expandedSections.organization}
                    onClick={() => toggleSection('organization')}
                >
                    <Guard
                        canRender={({user, license}) =>
                            LaunchDarklyFeatureFlags.isActive(CoreProjectLaunchDarklyFlags.Projects) &&
                            user.canViewProjects(license)
                        }
                    >
                        <NavigationLink
                            to="/organization/projects"
                            label={Locales.format('SideNavigation.Link.projects')}
                        />
                    </Guard>
                    <Guard canRender={({user}) => user.canViewOrganization}>
                        <NavigationLink label={Locales.format('SideNavigation.Link.settings')} to="/settings" />
                    </Guard>
                    <Guard canRender={({user}) => user.canViewGroups}>
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.groups')}
                            to="/organization/groups"
                        />
                    </Guard>
                    <Guard canRender={({user}) => user.canViewGroups}>
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.members')}
                            to="/organization/members"
                        />
                    </Guard>
                    <Guard canRender={({user}) => user.canViewTemporaryAccess}>
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.temporaryAccess')}
                            to="/organization/temporary-access"
                        />
                    </Guard>
                    <Guard canRender={({user}) => user.canViewApiKeys}>
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.apiKeys')}
                            to="/organization/api-access"
                        />
                    </Guard>
                    <Guard canRender={({user}) => user.canViewActivities}>
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.activityBrowser')}
                            to="/activity/browser"
                        />
                    </Guard>
                    <Guard canRender={({user}) => user.canViewNotifications}>
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.notifications')}
                            to="/organization/notifications"
                        />
                    </Guard>
                    <Guard
                        canRender={({user, license}) =>
                            !!license.properties?.organization?.snapshotsEnabled && user.canViewResourceSnapshots
                        }
                    >
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.resourceSnapshots')}
                            to="/organization/resource-snapshots"
                        />
                    </Guard>
                </NavigationSection>
                <Guard canRender={({user}) => user.isInternalUser}>
                    <NavigationSection
                        label={Locales.format('SideNavigation.Section.coveoOnly')}
                        leftSection={<CoveoIconSize16Px height={16} />}
                        defaultOpened={expandedSections.internal}
                        onClick={() => toggleSection('internal')}
                    >
                        <Guard canRender={({user}) => user.canViewClusters}>
                            <NavigationLink
                                label={Locales.format('SideNavigation.Link.cluster')}
                                to="/coveo-only/cluster"
                            />
                        </Guard>
                        <Guard canRender={({user}) => user.canViewIndexes}>
                            <NavigationLink
                                label={Locales.format('SideNavigation.Link.indexes')}
                                to="/coveo-only/index"
                            />
                        </Guard>
                        <NavigationLink
                            label={Locales.format('SideNavigation.Link.organizations')}
                            to="/coveo-only/organizations"
                        />
                        <Guard canRender={({user}) => user.canViewGlobalGroup}>
                            <NavigationLink
                                label={Locales.format('SideNavigation.Link.globalGroups')}
                                to="/coveo-only/global-groups"
                            />
                        </Guard>
                    </NavigationSection>
                </Guard>
            </AppShell.Section>
        </Navigation.SideBar>
    );
};
