import {CommonActions, IReduxAction} from '@coveord/plasma-react';

import {ILogsFacets, logsFacetsInitialState} from './LogsFacetsConstants';
import {ILogsFacetsActionPayload, LogsFacetsActionsType} from './LogsFacetsActions';

export const logsFacetsReducer = (
    state: ILogsFacets = logsFacetsInitialState,
    action: IReduxAction<ILogsFacetsActionPayload>,
): ILogsFacets => {
    switch (action.type) {
        case LogsFacetsActionsType.update:
            return action.payload.facets;
        case CommonActions.clearState:
            return logsFacetsInitialState;
        default:
            return state;
    }
};
