import {PrivilegeDomains, PrivilegeOwners, PrivilegesConstants, PrivilegeTypes} from '@core/user';
import {PrivilegeModel} from '@core/api';
import {createContext} from 'react';

export const GroupEditorContext = createContext<{id: string; isNew: boolean; isReadOnly: boolean}>(null);

export const GroupEditorConstants = {
    RComponent: 'EditGroupRComponent',
    newGroupId: 'new-group-being-created',
    actionType: 'GROUP_EDITOR',
    Components: ((prefix: string) => ({
        Tabs: ((suffix: string) => ({
            config: `${prefix}configuration${suffix}`,
            privileges: `${prefix}privileges${suffix}`,
            members: `${prefix}members${suffix}`,
            access: `${prefix}access${suffix}`,
        }))('-tab'),
        nameInput: `${prefix}name-input`,
        realms: `${prefix}realms-chooser`,
        membersTable: `${prefix}members-table`,
        inviteModal: `${prefix}invite-member-modal`,
        inviteUsernameInput: `${prefix}invite-username-input`,
        inviteEmailInput: `${prefix}invite-email-input`,
        inviteProviderChooser: `${prefix}invite-provider-chooser`,
        sendEmailCheckbox: `${prefix}send-invite-email-checkbox`,
    }))('edit-group-'),
    Requests: {
        fetchGroup: 'FETCH_GROUP_TO_EDIT',
        saveGroup: 'SAVE_GROUP',
        fetchGroupPrivileges: 'FETCH_GROUP_PRIVILEGES',
        fetchInvitesAndMembers: 'FETCH_GROUP_INVITES_AND_MEMBERS',
    },
    minimalPrivileges: [
        {
            targetDomain: PrivilegeDomains.Organization,
            owner: PrivilegeOwners.Platform,
            type: PrivilegeTypes.View,
            targetId: PrivilegesConstants.allTargetIds,
        },
    ] as PrivilegeModel[],
    SortKeys: {
        email: 'email',
        name: 'name',
        provider: 'provider',
    },
};
