/**
 * The coveo-only's subroute defined after `/:organizationId/coveo-only`.
 */
export const coveoOnly: Record<string, string> = {
    '/:organizationId/coveo-only/cluster': 'viewed cluster',
    '/:organizationId/coveo-only/cluster/activity': 'viewed cluster activity',
    '/:organizationId/coveo-only/global-groups': 'viewed global groups',
    '/:organizationId/coveo-only/global-groups/:globalGroupId': 'viewed global group edit',
    '/:organizationId/coveo-only/global-groups/activity': 'viewed global groups activity',
    '/:organizationId/coveo-only/global-groups/new': 'viewed global group add',
    '/:organizationId/coveo-only/organizations': 'viewed coveo only organizations',
    '/:organizationId/coveo-only/organizations/:selectedOrganizationId/activity':
        'viewed coveo only organization activity',
    '/:organizationId/coveo-only/organizations/new': 'viewed coveo only organization add',
    '/:organizationId/coveo-only/organizations/restore': 'viewed coveo only organization restore',
    '/:organizationId/coveo-only/organizations/temporary-access/:selectedOrganizationId/request':
        'viewed coveo only organization request temporary privileges',
};
