import {ReducersMapObject} from 'redux';

import {cardSelectGroupReducer} from './CardSelect/CardSelectGroupReducer';
import {catalogReducer} from './Catalog/CatalogReducers';
import {productListingReducer} from './ProductListings/ProductListingReducers';

export const onlyCommerceReducers: ReducersMapObject = {
    catalogManager: catalogReducer,
    productListingManager: productListingReducer,
    cardSelectGroups: cardSelectGroupReducer,
};
