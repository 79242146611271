export const CalculatedMetricFormats = {
    Default: 'default',
    Currency: 'currency',
    Percentage: 'percentage',
    Custom: 'custom',
};

export const CalculatedMetricFormatsValue = {
    [CalculatedMetricFormats.Default]: '{value}',
    [CalculatedMetricFormats.Currency]: '${value}',
    [CalculatedMetricFormats.Percentage]: '{100*value}%',
};
