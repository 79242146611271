import {IReduxAction} from '@coveord/plasma-react';
import _ from 'underscore';

import {IEditAssociationState} from '../../../../SearchOptimizationState';
import {
    AssociationsActionTypes,
    ISetAssociationModelsPayload,
    ISetAssociationRankingModifierPayload,
    ISetAssociationSelectedModelIdPayload,
} from '../actions/AssociationsActions';

export const initialAssociationsState: IEditAssociationState = {
    models: null,
    rankingModifier: null,
    selectedModelId: null,
};

const setModels = (state: IEditAssociationState, action: IReduxAction<ISetAssociationModelsPayload>) => ({
    ...state,
    models: action.payload.models,
});

const setRankingModifier = (
    state: IEditAssociationState,
    action: IReduxAction<ISetAssociationRankingModifierPayload>,
) => ({
    ...state,
    rankingModifier: action.payload.rankingModifier,
});

const setSelectedModelId = (
    state: IEditAssociationState,
    action: IReduxAction<ISetAssociationSelectedModelIdPayload>,
) => ({
    ...state,
    selectedModelId: action.payload.selectedModelId,
});

const clear = (state: IEditAssociationState, action: IReduxAction) => ({
    ...state,
    rankingModifier: initialAssociationsState.rankingModifier,
    selectedModelId: initialAssociationsState.selectedModelId,
});

const EditAssociationReducers: Record<string, (...args: any[]) => IEditAssociationState> = {
    [AssociationsActionTypes.setModels]: setModels,
    [AssociationsActionTypes.setRankingModifier]: setRankingModifier,
    [AssociationsActionTypes.setSelectedModelId]: setSelectedModelId,
    [AssociationsActionTypes.clear]: clear,
};

export type IEditAssociationPayload =
    | Record<string, unknown>
    | ISetAssociationModelsPayload
    | ISetAssociationRankingModifierPayload
    | ISetAssociationSelectedModelIdPayload;

export const editAssociationReducer = (
    state: IEditAssociationState = initialAssociationsState,
    action?: IReduxAction<IEditAssociationPayload>,
) =>
    !_.isUndefined(EditAssociationReducers[action.type]) ? EditAssociationReducers[action.type](state, action) : state;
