import {RequestsSelectors} from '@coveord/jsadmin-common';
import {ScopeModel} from '@core/api';
import {InputSelectors, SelectSelector} from '@coveord/plasma-react';
import {createSelector} from 'reselect';
import {CommerceState} from '../CommerceState';
import {locales} from '../Locales';
import {CatalogConfigurationConstants} from './CatalogConfiguration/CatalogConfigurationConstants';
import {CatalogConstants} from './CatalogConstants';
import {SourcesActionsTypes} from './SourceSelect/SourcesActions';
import {SourcesSelectors} from './SourceSelect/SourcesSelectors';

const isCatalogLoading = RequestsSelectors.createLoadingSelector([CatalogConstants.ActionsTypes.fetch]);
const isSourceLoading = RequestsSelectors.createLoadingSelector([SourcesActionsTypes.fetch]);
const catalogErrors = RequestsSelectors.createErrorMessageSelector([CatalogConstants.ActionsTypes.fetch]);
const hasLoadingErrors = (state: CommerceState) => catalogErrors(state).some((error) => !!error);

const getCatalog = (state: CommerceState) => state.catalogManager.editCatalog.catalog;

const getCatalogId = createSelector(getCatalog, (foundCatalog) =>
    foundCatalog ? foundCatalog.id : locales.format('catalogNotFound'),
);

const getCatalogName = createSelector(getCatalog, (foundCatalog) =>
    foundCatalog ? foundCatalog.name : locales.format('catalogNotFound'),
);

const getCatalogDescription = createSelector(getCatalog, (foundCatalog) =>
    foundCatalog ? foundCatalog.description : locales.format('catalogNotFound'),
);

const getCatalogSource = createSelector(getCatalog, (foundCatalog) => foundCatalog?.sourceId);

const getCatalogAvailabilitySource = createSelector(getCatalog, (foundCatalog) =>
    foundCatalog && !!foundCatalog.availabilitySourceId ? foundCatalog.availabilitySourceId : undefined,
);

const getCatalogConfiguration = createSelector(getCatalog, (foundCatalog) => foundCatalog.configuration);

const getCatalogSourceId = createSelector(getCatalog, (foundCatalog) => foundCatalog.sourceId!);

const getCatalogAvailabilitySourceId = createSelector(getCatalog, (foundCatalog) => foundCatalog.availabilitySourceId);

const getCatalogStandardFields = createSelector(
    getCatalog,
    (foundCatalog) => foundCatalog.configuration?.fieldsMapping || {},
);

const getCatalogScopeQuery = createSelector(getCatalog, (foundCatalog) => foundCatalog?.scope?.query || '');

const getCatalogScopeSourceQuery = createSelector(
    getCatalog,
    SourcesSelectors.getCatalogEnabledSourceIdToNameMap,
    (foundCatalog, sourcesMap) => {
        const catalogSources: string[] =
            ((foundCatalog?.availabilitySourceId || foundCatalog?.sourceId) && [
                ...(foundCatalog?.availabilitySourceId ? [foundCatalog?.availabilitySourceId] : []),
                ...(foundCatalog?.sourceId ? [foundCatalog?.sourceId] : []),
            ]) ||
            (foundCatalog?.scope && 'sourceIds' in foundCatalog?.scope && foundCatalog?.scope.sourceIds) ||
            [];

        return catalogSources.length > 0
            ? `@source==(\"${catalogSources
                  .map((id) => sourcesMap[id])
                  .filter((name) => !!name)
                  .join('","')}\")`
            : '';
    },
);

const getCatalogScopeSourceIds = createSelector(
    getCatalog,
    (foundCatalog) =>
        (foundCatalog?.scope && 'sourceIds' in foundCatalog?.scope && foundCatalog?.scope.sourceIds) || [],
);

const getScopeQuery = (state: CommerceState) =>
    InputSelectors.getValue(state, {
        id: CatalogConfigurationConstants.ComponentIds.ScopeQueryInput,
    });

const getScopeSources = (state: CommerceState) =>
    SelectSelector.getMultiSelectSelectedValues(state, {
        id: CatalogConfigurationConstants.ComponentIds.ScopeSourceSelect,
    });

const getScope = createSelector(
    getScopeQuery,
    getScopeSources,
    SourcesSelectors.getCatalogEnabledSources,
    (scopeQuery, scopeSourcesIds, sources): ScopeModel | undefined => {
        if (scopeSourcesIds && scopeSourcesIds.length > 0) {
            const sourceNames = scopeSourcesIds
                .map((sourceId) => sources.find((source) => source.id === sourceId)?.name)
                .filter((name) => !!name);
            return {query: `@source==("${sourceNames.join('","')}")`, sourceIds: scopeSourcesIds};
        } else if (scopeQuery) {
            return {query: scopeQuery};
        } else {
            return undefined;
        }
    },
);

export const CatalogSelectors = {
    isCatalogLoading,
    isSourceLoading,
    hasLoadingErrors,
    getCatalog,
    getCatalogId,
    getCatalogName,
    getCatalogDescription,
    getCatalogSource,
    getCatalogAvailabilitySource,
    getCatalogConfiguration,
    getCatalogSourceId,
    getCatalogAvailabilitySourceId,
    getCatalogStandardFields,
    getCatalogScopeQuery,
    getCatalogScopeSourceQuery,
    getCatalogScopeSourceIds,
    getScope,
};
