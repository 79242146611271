import {FieldOrigin, FieldTypes} from '@core/api';
import {IItemBoxProps} from '@coveord/plasma-react';

import {Locales} from '../Locales';

export const FieldsConstants = {
    Requests: {
        fetchAll: 'FETCH_FIELDS',
        fetch: 'FETCH_FIELD',
        create: 'CREATE_FIELD',
        update: 'UPDATE_FIELD',
        delete: 'DELETE_FIELD',
        set: 'SET_FIELD',
    },
    Components: {
        Table: 'Fields',
        Edit: 'EditField',
        FieldName: 'FieldName',
        FieldType: 'FieldType',
        FieldDescription: 'FieldDescription',
        Facet: 'Facet',
        MultiValueFacet: 'MultiValueFacet',
        Sortable: 'Sortable',
        AdvancedSettings: 'AdvancedSettings',
        SearchOperator: 'SearchOperator',
        DisplayableInResults: 'DisplayableInResults',
        FreeTextSearch: 'FreeTextSearch',
        Ranking: 'Ranking',
        Stemming: 'Stemming',
        UseCacheForSort: 'UseCacheForSort',
        UseCacheForComputedFields: 'UseCacheForComputedFields',
        UseCacheForNestedQueries: 'UseCacheForNestedQueries',
        UseCacheForNumericQueries: 'UseCacheForNumericQueries',
    },
    TypeLabels: {
        /* eslint-disable id-blacklist */
        All: Locales.format('ALL'),
        String: Locales.format('STRING'),
        Date: Locales.format('DATE'),
        Double: Locales.format('DOUBLE'),
        Long: Locales.format('LONG'),
        Long64: Locales.format('LONG_64'),
        Vector: Locales.format('VECTOR'),
        /* eslint-enable */
    },
    OriginLabels: {
        All: Locales.format('ALL'),
        System: Locales.format('default'),
        User: Locales.format('other'),
    },
    SortKeys: {
        name: 'name',
        type: 'fieldType',
        origin: 'sourceType',
        facet: 'facet',
        multiValueFacet: 'multiValueFacet',
        sortable: 'sort',
    },
    Labels: {
        name: Locales.format('name'),
        type: Locales.format('type'),
        origin: Locales.format('origin'),
        usedInSources: Locales.format('usedInSources'),
        facet: Locales.format('facet'),
        multiValueFacet: Locales.format('multiValueFacet'),
        sortable: Locales.format('sortable'),
    },
};

export const FieldTypeOptions: IItemBoxProps[] = [
    {
        value: FieldTypes.STRING,
        displayValue: FieldsConstants.TypeLabels.String,
        selected: true,
    },
    {
        value: FieldTypes.LONG,
        displayValue: FieldsConstants.TypeLabels.Long,
        selected: false,
    },
    {
        value: FieldTypes.LONG_64,
        displayValue: FieldsConstants.TypeLabels.Long64,
        selected: false,
    },
    {
        value: FieldTypes.DOUBLE,
        displayValue: FieldsConstants.TypeLabels.Double,
        selected: false,
    },
    {
        value: FieldTypes.DATE,
        displayValue: FieldsConstants.TypeLabels.Date,
        selected: false,
    },
    {
        value: FieldTypes.VECTOR,
        displayValue: FieldsConstants.TypeLabels.Vector,
        selected: false,
    },
];

export const FieldTypeOptionsLabels: Record<FieldTypes, string> = {
    [FieldTypes.STRING]: FieldsConstants.TypeLabels.String,
    [FieldTypes.LONG]: FieldsConstants.TypeLabels.Long,
    [FieldTypes.LONG_64]: FieldsConstants.TypeLabels.Long64,
    [FieldTypes.DOUBLE]: FieldsConstants.TypeLabels.Double,
    [FieldTypes.DATE]: FieldsConstants.TypeLabels.Date,
    [FieldTypes.VECTOR]: FieldsConstants.TypeLabels.Vector,
};

export const FieldsOriginOptions = [
    {
        value: FieldOrigin.ALL,
    },
    {
        value: FieldOrigin.SYSTEM,
        label: FieldsConstants.OriginLabels.System,
    },
    {
        value: FieldOrigin.USER,
        label: FieldsConstants.OriginLabels.User,
    },
];

export const FieldsEvents = {
    CancelEdition: 'field:cancel',
    DeleteField: 'field:delete',
    DeleteFieldConfirmed: 'deleteField',
    DeleteFieldsConfirmed: 'deleteFields',
    FieldCreated: 'field:fieldCreated',
    UpdateField: 'field:save',
};

export const DEFAULT_MULTI_VALUE_FACET_TOKENIZERS = ';';
