import {IThunkAction} from '@coveord/plasma-react';
import {setKeyValue} from '@core/store';
import {RequestsActions, RequestsSelectors} from '@coveord/jsadmin-common';
import {Platform, CatalogMetadata} from '@core/api';
import {CommerceState} from '../../../CommerceState';

export const MetadataSuggestionsActionType = {
    fetchProductMetadataResults: 'FETCH_PRODUCT_METADATA_RESULTS',
    fetchVariantMetadataResults: 'FETCH_VARIANT_METADATA_RESULTS',
    fetchAvailabilityMetadataResults: 'FETCH_AVAILABILITY_METADATA_RESULTS',
};

const isRequestLoading = (requestKey: string) => RequestsSelectors.createLoadingSelector([requestKey]);

const fetchMetadataSuggestions =
    (objectType: string, selectedSourceId: string, actionType: string): IThunkAction<void, CommerceState> =>
    async (dispatch, getState) => {
        if (selectedSourceId && objectType) {
            await dispatch(
                RequestsActions.handle(actionType, async () => {
                    const data: CatalogMetadata = await Platform.catalogContent.getMetadataValues(selectedSourceId, {
                        objectType: objectType,
                    });

                    const result = Object.entries(data.metadataValuesByName).map(([key, value]) => ({
                        name: key.toLowerCase(),
                        examples: value,
                    }));
                    dispatch(setKeyValue(actionType, result));
                }),
            );
        }
    };

export const MetadataSuggestionsActions = {
    fetchMetadataSuggestions,
    isRequestLoading,
};
