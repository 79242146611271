import {ProjectModel} from '@core/api';
import {IReduxAction} from '@coveord/plasma-react';

export interface ProjectActionsPayload {
    project: ProjectModel | null;
}

export const ProjectActionsType = {
    update: 'UPDATE_PROJECT',
    clear: 'CLEAR_PROJECT',
};

const updateProject = (project: ProjectModel | null): IReduxAction<ProjectActionsPayload> => ({
    type: ProjectActionsType.update,
    payload: {project},
});

const clearProject = (): IReduxAction => ({type: ProjectActionsType.clear});

export const ProjectActions = {
    updateProject,
    clearProject,
};
