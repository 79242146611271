export const baseServicePath: string = 'service/';

export enum ServicePathsMap {
    caseAssistConfigs = 'case-assist',
    editCaseAssistConfig = 'case-assist/edit/:id',
    createCaseAssistConfig = 'create',
    insightPanelConfigs = 'insight-panel',
    editInsightPanelConfig = 'insight-panel/edit/:id',
    createInsightPanelConfig = 'create',
    duplicateInsightPanelConfig = 'insight-panel/duplicate/:id/:name',
}

export const ServiceRoutesGenerators = {
    caseAssistConfigs: ServicePathsMap.caseAssistConfigs,
    editCaseAssistConfig: (name: string) =>
        ServicePathsMap.editCaseAssistConfig.replace(':id', encodeURIComponent(name)) as ServicePathsMap,
    createCaseAssistConfig: ServicePathsMap.createCaseAssistConfig,
    insightPanelConfigs: ServicePathsMap.insightPanelConfigs,
    editInsightPanelConfig: (name: string) =>
        ServicePathsMap.editInsightPanelConfig.replace(':id', encodeURIComponent(name)) as ServicePathsMap,
    createInsightPanelConfig: ServicePathsMap.createInsightPanelConfig,
    duplicateInsightPanelConfig: (id: string, name: string) =>
        ServicePathsMap.duplicateInsightPanelConfig
            .replace(':id', encodeURIComponent(id))
            .replace(':name', encodeURIComponent(name)) as ServicePathsMap,
};
