import {useUncontrolled} from '@components/mantine';
import {useMatch, useNavigate, useResolvedPath} from '@core/routes';
import {useCallback, useMemo} from 'react';

import {SubnavLayoutPanels} from './SubnavLayout.types';

type SubnavLayoutUnconditionedProps<T> = {
    routed: boolean;
    value?: T;
    onChange?: (value: T) => void;
};

type UseSubnavLayoutControlledReturn<T extends string> =
    | {
          defaultTab: T;
          activeTab: T;
          setActiveTab: (tab: T) => void;
      }
    | {
          defaultTab: T;
          activeTab: undefined;
          setActiveTab: undefined;
      };

export const useSubnavLayoutControlled = <T extends string>(
    panels: SubnavLayoutPanels,
    {routed, value, onChange}: SubnavLayoutUnconditionedProps<T>,
): UseSubnavLayoutControlledReturn<T> => {
    const navigate = useNavigate();

    const defaultTab = useMemo(
        () => (panels.find((tab) => tab.props.default)?.props.path ?? panels[0].props.path) as T,
        [panels],
    );

    const {pathname: basePath} = useResolvedPath('.', {relative: 'route'});
    const initActiveTab = useMatch(basePath + `/:tab/*`)?.params.tab ?? defaultTab;
    const valueControlled = (routed ? initActiveTab : defaultTab) as T;
    const [activeTab, _setActiveTab] = useUncontrolled<T>({
        value: value ?? valueControlled,
        defaultValue: valueControlled,
        finalValue: null,
        onChange: onChange ?? undefined,
    });

    const setActiveTab = useCallback(
        (tab: T) => {
            _setActiveTab(tab);
            if (routed) {
                navigate(`./${tab}`);
            }
        },
        [_setActiveTab, navigate, routed],
    );

    if (routed || (value && onChange)) {
        return {
            defaultTab,
            activeTab,
            setActiveTab,
        };
    }

    return {
        defaultTab,
        activeTab: undefined,
        setActiveTab: undefined,
    };
};
