import * as ReactVapor from '@coveord/plasma-react';
import _ from 'underscore';
import {UsageAnalyticsSettingsNavigationId, UsageAnalyticsSettingsState} from './UsageAnalyticsSettingsState';

const selectTabReducer = (
    state: UsageAnalyticsSettingsState,
    action: ReactVapor.IReduxAction<ReactVapor.ISubNavigationActionPayload>,
) => {
    if (action.payload.id === UsageAnalyticsSettingsNavigationId) {
        return _.extend({}, state, {tab: action.payload.selected});
    }
    return state;
};

const UsageAnalyticsSettingsActionReducers = {
    [ReactVapor.SubNavigationActions.select]: selectTabReducer,
};

export const UsageAnalyticsSettingsReducers = (
    state: UsageAnalyticsSettingsState = {tab: ''},
    action: ReactVapor.IReduxAction<ReactVapor.ISubNavigationActionPayload>,
) => {
    let newState = state;
    if (!_.isUndefined(UsageAnalyticsSettingsActionReducers[action.type])) {
        newState = UsageAnalyticsSettingsActionReducers[action.type](state, action);
    }
    return newState;
};
