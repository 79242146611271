import {ProjectModel} from '@core/api';
import {LicenseState} from '@core/license';
import {getStore} from '@core/store';
import {UserState} from '@core/user';

export interface ProjectState extends UserState, LicenseState {
    project: ProjectModel | null;
}

export const ProjectStore = getStore<ProjectState>();
