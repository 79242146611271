import {KeyValueReducerGenerator} from '@core/store';
import {combineReducers, ReducersMapObject} from 'redux';
import {CreateApiKeyConstants} from './ApiKey/CreateApiKeyConstants';
import {ICreateApiKeyState, initialStateCatalogApiKey} from './ApiKey/CreateCatalogApiKeyReducers';
import {configurationReducer} from './CatalogConfiguration/CatalogConfigurationReducers';
import {ICatalogManagerState} from './CatalogState';
import {catalogsReducer} from './CatalogTable/CatalogsReducer';
import {editCatalogReducer} from './EditCatalog/EditCatalogReducer';
import {objectTypesReducer} from './ObjectTypes/ObjectTypesReducer';
import {prerequisitesReducer} from './Prerequisites/PrerequisitesReducer';
import {sourcesReducer} from './SourceSelect/SourcesReducer';
import {StandardFieldsActionsTypes} from './StandardFields/StandardFieldsActions';
import {IFieldsSuggestionsState} from './StandardFields/StandardFieldsState';
import {MetadataSuggestionsActionType} from './CatalogConfiguration/Metadata/MetadataSuggestionsActions';

export const reducers: ReducersMapObject<ICatalogManagerState> = {
    catalogs: catalogsReducer,
    catalogsPrerequisites: prerequisitesReducer,
    editCatalog: editCatalogReducer,
    objectTypes: objectTypesReducer,
    sources: sourcesReducer,
    editConfiguration: configurationReducer,
    fieldsSuggestions: KeyValueReducerGenerator<IFieldsSuggestionsState>(
        StandardFieldsActionsTypes.updateStandardFields,
        [],
    ),
    productsSuggestions: KeyValueReducerGenerator<IFieldsSuggestionsState>(
        MetadataSuggestionsActionType.fetchProductMetadataResults,
        [],
    ),
    variantsSuggestions: KeyValueReducerGenerator<IFieldsSuggestionsState>(
        MetadataSuggestionsActionType.fetchVariantMetadataResults,
        [],
    ),
    availabilitiesSuggestions: KeyValueReducerGenerator<IFieldsSuggestionsState>(
        MetadataSuggestionsActionType.fetchAvailabilityMetadataResults,
        [],
    ),
    createCatalogApiKey: KeyValueReducerGenerator<ICreateApiKeyState>(
        CreateApiKeyConstants.ActionTypes.updateApiKey,
        initialStateCatalogApiKey,
    ),
};

export const catalogReducer = combineReducers<ICatalogManagerState>(reducers);
