import {createSelector} from 'reselect';
import {InputSelectors, JSONEditorSelectors, SelectSelector} from '@coveord/plasma-react';
import {CommerceState} from '../../CommerceState';
import {ProductListingConstants} from '../../ProductListings/ProductListingConstants';

const {ComponentIds} = ProductListingConstants;

const getProductListingName = (state: CommerceState) => InputSelectors.getValue(state, {id: ComponentIds.NameInput});
const getProductListingCatalogId = (state: CommerceState) =>
    SelectSelector.getSelectedValue(state, {id: ComponentIds.CatalogsSelect});
const getProductListingUrls = (state: CommerceState) => [InputSelectors.getValue(state, {id: ComponentIds.UrlInput})];
const getProductListingIncludeFilters = (state: CommerceState) =>
    JSON.parse(JSONEditorSelectors.getValue(state, ComponentIds.IncludeFilter));
const getProductListingExcludeFilters = (state: CommerceState) =>
    JSON.parse(JSONEditorSelectors.getValue(state, ComponentIds.ExcludeFilter));

const getProductListingToCreate = createSelector(
    getProductListingCatalogId,
    getProductListingName,
    getProductListingUrls,
    getProductListingIncludeFilters,
    getProductListingExcludeFilters,
    (catalogId, name, urls, include, exclude) => ({
        catalogId,
        name,
        urls,
        include,
        exclude,
    }),
);

export const ProductListingOnboardingSelectors = {
    getProductListingToCreate,
};
