import _ from 'underscore';
import {
    AllowedIdentitiesActionsType,
    IAllowedIdentitiesAction,
    IdentityFeedbackState,
} from '../actions/AllowedIdentitiesActions';
import {
    AllowedIdentityEditionActionsType,
    IAllowedIdentityEditionAction,
} from '../actions/AllowedIdentityEditionActions';
import {AllowedIdentityAttributes} from '../models/AllowedIdentityModel';
import {
    allowedIdentityEditionReducers,
    AllowedIdentityEditionState,
    defaulAllowedIdentityEditionState,
} from './AllowedIdentityEditionReducers';

export interface AllowedIdentityCompositeState {
    addAllowedIdentityState: AllowedIdentityEditionState;
    allowedIdentities: AllowedIdentityEditionState[];
    identityFeedbackState: string;
}

export const defaultAllowedIdentityCompositeState: AllowedIdentityCompositeState = {
    addAllowedIdentityState: _.extend({}, defaulAllowedIdentityEditionState),
    allowedIdentities: [],
    identityFeedbackState: IdentityFeedbackState.VALID,
};

interface AllowedIdentitiesActionsReducers {
    [key: string]: (
        state: AllowedIdentityCompositeState,
        action: IAllowedIdentitiesAction,
    ) => AllowedIdentityCompositeState;
}

const generateAllowedIdentityId = (): string => _.uniqueId('allowedIdentity');

const setIdentityFeedbackStateReducer = (
    state: AllowedIdentityCompositeState,
    action: IAllowedIdentitiesAction,
): AllowedIdentityCompositeState =>
    _.extend({}, state, {
        identityFeedbackState: action.payload.identityFeedbackState,
    });

const setAllowedIdentitiesReducer = (
    state: AllowedIdentityCompositeState,
    action: IAllowedIdentitiesAction,
): AllowedIdentityCompositeState =>
    _.extend({}, state, {
        allowedIdentities: _.map(
            action.payload.allowedIdentities,
            (allowedIdentity: AllowedIdentityAttributes): AllowedIdentityEditionState => ({
                appliedState: _.extend({}, allowedIdentity),
                editionState: _.extend({}, allowedIdentity),
                id: generateAllowedIdentityId(),
                isOpen: false,
            }),
        ),
    });

const addAllowedIdentityReducer = (state: AllowedIdentityCompositeState): AllowedIdentityCompositeState => {
    const newState: AllowedIdentityCompositeState = _.extend({}, state);

    const newMember: AllowedIdentityEditionState = _.extend(
        {},
        {
            appliedState: _.extend({}, state.addAllowedIdentityState.editionState),
            editionState: _.extend({}, state.addAllowedIdentityState.editionState),
            id: generateAllowedIdentityId(),
            isOpen: false,
        },
    );
    newState.allowedIdentities = [].concat(state.allowedIdentities, [newMember]);

    newState.addAllowedIdentityState = _.extend({}, defaulAllowedIdentityEditionState);

    newState.identityFeedbackState = IdentityFeedbackState.VALID;

    return newState;
};

const removeAllowedIdentityReducer = (
    state: AllowedIdentityCompositeState,
    action: IAllowedIdentitiesAction,
): AllowedIdentityCompositeState => {
    const newAllowedIdentities = _.reject(
        state.allowedIdentities,
        (allowedIdentity: AllowedIdentityEditionState) => allowedIdentity.id === action.payload.id,
    );

    return _.extend({}, state, {
        allowedIdentities: newAllowedIdentities,
        identityFeedbackState: newAllowedIdentities.length
            ? IdentityFeedbackState.VALID
            : IdentityFeedbackState.WARNING,
    });
};

const clearAllowedIdentitiesStatesReducer = (): AllowedIdentityCompositeState =>
    _.extend({}, defaultAllowedIdentityCompositeState);

const applyAllowedIdentityEditionReducers = (
    state: AllowedIdentityCompositeState,
    action: IAllowedIdentityEditionAction,
): AllowedIdentityCompositeState => {
    const newState: AllowedIdentityCompositeState = _.extend({}, state);

    if (_.isNull(action.payload.id)) {
        newState.addAllowedIdentityState = allowedIdentityEditionReducers(state.addAllowedIdentityState, action);
    } else {
        newState.allowedIdentities = _.map(state.allowedIdentities, (allowedIdentity: AllowedIdentityEditionState) =>
            allowedIdentityEditionReducers(allowedIdentity, action),
        );
    }
    return newState;
};

const allowedIdentitiesActionsReducers: AllowedIdentitiesActionsReducers = {
    [AllowedIdentitiesActionsType.SetIdentityFeedbackState]: setIdentityFeedbackStateReducer,
    [AllowedIdentitiesActionsType.SetAllowedIdentities]: setAllowedIdentitiesReducer,
    [AllowedIdentitiesActionsType.AddAllowedIdentity]: addAllowedIdentityReducer,
    [AllowedIdentitiesActionsType.RemoveAllowedIdentity]: removeAllowedIdentityReducer,
    [AllowedIdentitiesActionsType.ClearAllowedIdentitiesStates]: clearAllowedIdentitiesStatesReducer,
    [AllowedIdentityEditionActionsType.ApplyChanges]: applyAllowedIdentityEditionReducers,
    [AllowedIdentityEditionActionsType.CancelChanges]: applyAllowedIdentityEditionReducers,
    [AllowedIdentityEditionActionsType.ChangeAdditinalInfo]: applyAllowedIdentityEditionReducers,
    [AllowedIdentityEditionActionsType.ChangeIdentity]: applyAllowedIdentityEditionReducers,
    [AllowedIdentityEditionActionsType.ChangeIdentityType]: applyAllowedIdentityEditionReducers,
    [AllowedIdentityEditionActionsType.ChangeSecurityProvider]: applyAllowedIdentityEditionReducers,
    [AllowedIdentityEditionActionsType.ToggleOpen]: applyAllowedIdentityEditionReducers,
};

export const allowedIdentitiesReducers = (
    state: AllowedIdentityCompositeState = defaultAllowedIdentityCompositeState,
    action: IAllowedIdentitiesAction,
) =>
    _.isUndefined(allowedIdentitiesActionsReducers[action.type])
        ? state
        : allowedIdentitiesActionsReducers[action.type](state, action);
