import {SourceModel} from '@core/api';
import {KeyValueReducerGenerator, ObjectReducerGenerator} from '@core/store';
import {ReducersMapObject} from 'redux';
import {accessTabsReducer} from '../access/access-tab/AccessTabsReducer';
import {DimensionModelAttributes, DimensionValue} from '../dimensions/DimensionsCommon';
import {DimensionValuesActionsType} from '../dimensions/actions/DimensionValuesActions';
import {DimensionsActionsType} from '../dimensions/actions/DimensionsActions';
import {EventFilterSetActionsType} from '../event-filters/actions/EventFilterSetActions';
import {EventFilterActionsType} from '../event-filters/actions/EventFiltersActions';
import {eventFilterSetReducer} from '../event-filters/reducers/EventFilterSetReducers';
import {FieldFilterActionTypes} from '../field-filters/actions/FieldFilterActions';
import {FieldFilterSetActionsType} from '../field-filters/actions/FieldFilterSetActions';
import {fieldFilterSetReducer} from '../field-filters/reducers/FieldFilterSetReducers';
import {fieldFilterReducer} from '../field-filters/reducers/FieldFiltersReducers';
import {globalConfigurationReducer} from '../globalConfiguration/GlobalConfigurationReducers';
import {searchUIReducer} from '../search-ui/reducers/SearchUIReducer';
import {SourcesActionTypes} from '../sources/SourcesConstants';
import {eventFilterReducer} from './../event-filters/reducers/EventFiltersReducers';
import {CommonState} from './CommonState';
import {requestsReducer} from './requests/RequestsReducer';

export const commonReducers: ReducersMapObject<CommonState> = {
    dimensions: KeyValueReducerGenerator<DimensionModelAttributes[]>(DimensionsActionsType.set, []),
    dimensionValues: ObjectReducerGenerator<Record<string, DimensionValue[]>>(DimensionValuesActionsType.set, {}),
    eventFilters: eventFilterReducer,
    eventFilterSets: eventFilterSetReducer,
    editedEventFilterSetId: KeyValueReducerGenerator<string>(EventFilterSetActionsType.edit),
    eventFilterIdInEditMode: KeyValueReducerGenerator<string>(EventFilterActionsType.setEditMode),
    fieldFilters: fieldFilterReducer,
    fieldFilterSets: fieldFilterSetReducer,
    editedFieldFilterSetId: KeyValueReducerGenerator<string>(FieldFilterSetActionsType.edit),
    fieldFilterIdInEditMode: KeyValueReducerGenerator<string>(FieldFilterActionTypes.setEditMode),
    requests: requestsReducer,
    accessTabs: accessTabsReducer,
    searchUI: searchUIReducer,
    global: globalConfigurationReducer,
    allSources: KeyValueReducerGenerator<SourceModel[]>(SourcesActionTypes.updateSources),
};
