import {Platform, useQuery, UseQueryResult, UserModel} from '@core/api';
import Registry from '@core/registry';
import {createContext, useContext} from 'react';
import {useDispatch} from 'react-redux';
import {updateUser} from '../user';

export const UserContext = createContext<{query: UseQueryResult<UserModel> | undefined}>({
    query: undefined,
});

/**
 * This hook provides the user model, separated from its privileges and access.
 * @returns {UserModel}
 */
export const useUserModel = (): UserModel => {
    const context = useContext(UserContext);

    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }

    if (!context.query?.data) {
        throw new Error('User data is not available');
    }

    return context.query.data;
};

export const UserProvider = ({children}) => {
    const dispatch = useDispatch();

    const userQuery = useQuery({
        queryKey: ['init', 'user'],
        queryFn: async () => {
            const user = await Platform.user.get();
            dispatch(updateUser(user));
            Registry.register('userModel', user);
            return user;
        },
        initialData: Registry.get('userModel', {optional: true}),
        staleTime: Infinity,
    });

    return <UserContext.Provider value={{query: userQuery}}>{children}</UserContext.Provider>;
};
