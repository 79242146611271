import {Center, Loader} from '@components/mantine';
import loadable, {DefaultComponent} from '@loadable/component';

export const Loadable = <T extends unknown>(doImport: (props: T) => Promise<DefaultComponent<T>>) =>
    loadable<T>(doImport, {
        fallback: (
            <Center style={{flexGrow: 1}}>
                <Loader />
            </Center>
        ),
    });
