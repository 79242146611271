import {DateRangeModel, DateUtils} from '@coveord/jsadmin-common';
import * as ReactVapor from '@coveord/plasma-react';
import _ from 'underscore';

import {ConfigUtils} from '../../../utils/ConfigUtils';
import {ValidatedDimensionResultState} from '../StrictValidationState';
import {ValidatedDimension, validatedDimensions} from '../ValidatedDimensions';

export const StrictValidationActionsType = {
    ApplyFetchDone: 'STRICT_VALIDATION_APPLY_DONE',
    ApplyFetchFail: 'STRICT_VALIDATION_APPLY_FAIL',
    DimensionFetchDone: 'STRICT_VALIDATION_DIMENSION_FETCH_DONE',
    DimensionFetchFail: 'STRICT_VALIDATION_DIMENSION_FETCH_FAIL',
    DimensionFetchStarted: 'STRICT_VALIDATION_DIMENSION_FETCH_STARTED',
    SaveDone: 'STRICT_VALIDATION_SAVE_DONE',
    SaveFailed: 'STRICT_VALIDATION_SAVE_FAILED',
    SaveStarted: 'STRICT_VALIDATION_STARTED',
    ResetApplyState: 'STRICT_VALIDATION_RESET',
    Toggle: 'STRICT_VALIDATION_TOGGLE_ROW',
};

export interface StrictValidationApplyPayload {
    result: boolean;
}

export interface StrictValidationTogglePayload {
    id: string;
}

export interface StrictValidationDimensionFetchDonePayload extends StrictValidationTogglePayload {
    results: ValidatedDimensionResultState;
}

const toggle = (id: string, shouldFetch: boolean) => (dispatch) => {
    dispatch(ReactVapor.toggleRowOpened(id));
    if (shouldFetch) {
        dispatch({
            type: StrictValidationActionsType.DimensionFetchStarted,
            payload: {id},
        });

        const dateRange = new DateRangeModel();
        dateRange.timezone = DateUtils.ApplicationTimezone;
        dateRange.setLastXDaysRange(31, true);

        if (_.contains(_.pluck(validatedDimensions, 'id'), id)) {
            const validationTestUrl: string = `${ConfigUtils.backendUrl}/admin/account/strictValidationTest`;
            const validatedDimension: ValidatedDimension = _.findWhere(validatedDimensions, {id});
            const param = {
                from: dateRange.getBeginDateLocalTimeString(),
                to: dateRange.getEndDateLocalTimeString(),
                d: validatedDimension.returnName,
            };
            $.get(validationTestUrl, param)
                .done((rawData) => {
                    const results = _.map(rawData.validationTestResults, (testResult: any) => ({
                        count: testResult.count,
                        value: testResult[_.findKey(testResult, (key) => key !== 'count')],
                    }));
                    dispatch({
                        type: StrictValidationActionsType.DimensionFetchDone,
                        payload: {id, results},
                    });
                })
                .fail((xhr: JQueryXHR) => {
                    xhr.errorHandled = true;
                    dispatch({
                        type: StrictValidationActionsType.DimensionFetchFail,
                        payload: {id},
                    });
                });
        } else {
            dispatch({
                type: StrictValidationActionsType.DimensionFetchFail,
                payload: {id},
            });
        }
    }
};

const toggleApply = (apply: boolean) => (dispatch) => {
    const url = `${ConfigUtils.backendUrl}/admin/account`;
    dispatch({type: StrictValidationActionsType.SaveStarted});
    $.get(url)
        .done((rawData) => {
            rawData.useStrictFieldValidation = apply;
            $.ajax({
                url,
                method: 'PUT',
                contentType: 'application/json',
                dataType: 'json',
                data: JSON.stringify(rawData),
            })
                .done(() => dispatch({type: StrictValidationActionsType.SaveDone}))
                .fail((xhr: JQueryXHR) => {
                    xhr.errorHandled = true;
                    dispatch({type: StrictValidationActionsType.SaveFailed});
                });
        })
        .fail((xhr: JQueryXHR) => {
            xhr.errorHandled = true;
            dispatch({type: StrictValidationActionsType.SaveFailed});
        });
};

const fetchApply = () => (dispatch) => {
    const url: string = `${ConfigUtils.backendUrl}/admin/account`;
    $.get(url)
        .done((rawData) => {
            dispatch({
                type: StrictValidationActionsType.ApplyFetchDone,
                payload: {result: rawData.useStrictFieldValidation},
            });
        })
        .fail(() => dispatch({type: StrictValidationActionsType.ApplyFetchFail}));
};

export const StrictValidationActions = {
    toggleApply,
    toggle,
    fetchApply,
};
