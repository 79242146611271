import {Locales} from '../../../Locales';

export const ServiceNowSourceConstants = {
    Components: {
        ID: 'edit-servicenow-source-component',
        SourceNameInput: 'servicenow-name-input',
        EntityTable: 'entity-table',
        FieldsTable: 'fields-table',
        InstanceURLInput: 'servicenow-source-instance-url-input',
    },
    Requests: {
        fetchTableEntities: 'FETCH_ENTITIES_SERVICENOW',
        fetchFields: 'FETCH_FIELDS',
        setEntity: 'SET_ENTITY',
        setObjectsToGet: 'SET_OBJECTS_TO_GET',
        setServiceNowEntitiesLoading: 'SET_SERVICENOW_ENTITIES_LOADING',
        setServiceNowFieldsLoading: 'SET_SERVICENOW_FIELDS_LOADING',
    },
    ServiceNowHosts: ['.service-now.com', '.servicenow.com', '.servicenowservices.com'],
    OAuth2LabelName: Locales.format('serviceNowOAuth2LabelName'),
};
