import {Platform} from '@core/api';
import {
    addInput,
    IDispatch,
    InputSelectors,
    IReduxAction,
    IThunkAction,
    MultilineBoxSelectors,
    removeInput,
    updateValueStringList,
    UUID,
    ValidationActions,
    ValidationSelectors,
} from '@coveord/plasma-react';
import {ServiceState} from '../../../ServiceState';
import {locales} from '../../../locales/Locales';

export const FieldsToPredictActionTypes = {
    beginValidateFieldExist: 'BEGIN_VALIDATE_FIELD_EXIST',
    endValidateFieldExist: 'END_VALIDATE_FIELD_EXIST',
};

export const FieldsToPredictValidationTypes = {
    invalidFormat: 'invalid-format',
    fieldDoesNotExist: 'does-not-exist',
};

const setFields =
    (id: string, fields: string[]): IThunkAction<void, ServiceState> =>
    (dispatch, getState) => {
        const oldInputIds = MultilineBoxSelectors.getIds(getState(), {id});
        const newInputs = createInputs(dispatch, fields);

        dispatch(updateValueStringList(id, newInputs));
        removeInputs(dispatch, oldInputIds);
        dispatch(aggregateErrors(id));
    };

const createInputs = (dispatch: IDispatch, fields: string[]): string[] =>
    [...fields, ''].map((fieldName) => {
        const inputId = UUID.generate();
        dispatch(addInput(inputId, fieldName));
        return inputId;
    });

const removeInputs = (dispatch: IDispatch, ids: string[]): void =>
    ids.forEach((id) => {
        dispatch(ValidationActions.clearError(id));
        dispatch(removeInput(id));
    });

const aggregateErrors =
    (id: string): IThunkAction<void, ServiceState> =>
    async (dispatch, getState) => {
        let skip = false;

        const fieldInputsIds = MultilineBoxSelectors.getIds(getState(), {id});

        const fieldNames = fieldInputsIds
            .map((inputId) => InputSelectors.getValue(getState(), {id: inputId}))
            .filter((inputValue) => Boolean(inputValue));

        const isEmpty = fieldInputsIds.length > 0 && fieldNames.length === 0;
        if (isEmpty) {
            dispatch(ValidationActions.setError(id, locales.format('fieldsToPredictEmptyError')));
            skip = true;
        }

        const duplicateName = fieldNames.find((fieldName, index) => fieldNames.indexOf(fieldName) !== index);
        if (!skip && duplicateName) {
            dispatch(
                ValidationActions.setError(
                    id,
                    formatErrorMessage(duplicateName, locales.format('fieldsToPredictDuplicationErrorDetail')),
                ),
            );
            skip = true;
        }

        const firstInputError = getFirstInputBoxError(getState(), id);
        if (!skip && firstInputError) {
            dispatch(ValidationActions.setError(id, firstInputError));
            skip = true;
        }

        if (!skip) {
            dispatch(ValidationActions.clearError(id));
        }
    };

const getFirstInputBoxError = (state: ServiceState, id: string) => {
    const inputIds = MultilineBoxSelectors.getIds(state, {id});
    const inputErrors = ValidationSelectors.getAnyError(inputIds)(state);

    return inputErrors.length > 0 ? inputErrors[0].value : '';
};

const validateFieldNameFormat =
    (id: string, inputId: string): IThunkAction<void, ServiceState> =>
    async (dispatch, getState) => {
        let skip = false;
        const fieldName = InputSelectors.getValue(getState(), {id: inputId});

        if (fieldName.length === 0) {
            dispatch(ValidationActions.clearError(inputId, FieldsToPredictValidationTypes.invalidFormat));
            skip = true;
        }

        if (!skip && nameIsTooLong(fieldName)) {
            const message = formatErrorMessage(fieldName, locales.format('fieldsToPredictFieldNameTooLongErrorDetail'));
            dispatch(ValidationActions.setError(inputId, message, FieldsToPredictValidationTypes.invalidFormat));
            skip = true;
        }

        if (!skip && nameFormatIsInvalid(fieldName)) {
            const message = formatErrorMessage(fieldName, locales.format('fieldsToPredictFieldNameFormatErrorDetail'));
            dispatch(ValidationActions.setError(inputId, message, FieldsToPredictValidationTypes.invalidFormat));
            skip = true;
        }

        if (!skip) {
            // Everything went fine so far, clear the errors
            dispatch(ValidationActions.clearError(inputId, FieldsToPredictValidationTypes.invalidFormat));
        }

        dispatch(aggregateErrors(id));
    };

const nameIsTooLong = (fieldName: string) => fieldName.length > 255;

const nameFormatIsInvalid = (fieldName: string) => !/^[a-z][a-z0-9_]*$/.test(fieldName);

const formatErrorMessage = (fieldName: string, detailedMessage: string) => {
    const shortenedFieldName = fieldName.length > 25 ? `${fieldName.substr(0, 22)}...` : fieldName;
    const errorMessage = locales.format('fieldsToPredictFieldNameError').replace('{0}', shortenedFieldName);
    return `${errorMessage} ${detailedMessage}`;
};

export interface IValidateFieldToPredictNamePayload {
    id: string;
    inputId: string;
}

export interface IEndValidateFieldToPredictNamePayload extends IValidateFieldToPredictNamePayload {
    valid: boolean;
}

const beginValidateFieldExist = (id: string, inputId: string): IReduxAction<IValidateFieldToPredictNamePayload> => ({
    type: FieldsToPredictActionTypes.beginValidateFieldExist,
    payload: {
        id,
        inputId,
    },
});

const endValidateFieldExist = (
    id: string,
    inputId: string,
    valid: boolean,
): IReduxAction<IEndValidateFieldToPredictNamePayload> => ({
    type: FieldsToPredictActionTypes.endValidateFieldExist,
    payload: {
        id,
        inputId,
        valid,
    },
});

const validateFieldNameExists =
    (id: string, inputId: string): IThunkAction<void, ServiceState> =>
    async (dispatch, getState) => {
        const doesNotExistError = locales.format('fieldsToPredictFieldNameDoesNotExistErrorDetail');
        const hasOtherError = ValidationSelectors.getAnyError([inputId])(getState()).some(
            (error) => error.validationType !== FieldsToPredictValidationTypes.fieldDoesNotExist,
        );

        if (hasOtherError) {
            // If other errors are detected on the input field, we can skip this
            // long-running validation because the user has to change the input value anyway.

            // Just make sure to clear the "does not exist error" so it does not stick
            dispatch(ValidationActions.clearError(inputId, FieldsToPredictValidationTypes.fieldDoesNotExist));
            dispatch(aggregateErrors(id));
            return;
        }

        dispatch(beginValidateFieldExist(id, inputId));

        const fieldName = InputSelectors.getValue(getState(), {id: inputId});

        const exist = await fieldExistInPlatform(fieldName);

        const updateValidationAction = !exist
            ? ValidationActions.setError(
                  inputId,
                  formatErrorMessage(fieldName, doesNotExistError),
                  FieldsToPredictValidationTypes.fieldDoesNotExist,
              )
            : ValidationActions.clearError(inputId, FieldsToPredictValidationTypes.fieldDoesNotExist);
        dispatch(updateValidationAction);
        dispatch(aggregateErrors(id));
        dispatch(endValidateFieldExist(id, inputId, exist));
    };

const fieldExistInPlatform = async (fieldName: string) => {
    let isFound = false;
    let page = 0;
    let hasNextPage = false;
    do {
        const resultPage = await Platform.field.list({
            filter: fieldName,
            page,
        });

        hasNextPage = resultPage.totalPages > page + 1;
        page++;
        isFound = resultPage.items.some((fieldItem) => fieldItem.name === fieldName);
    } while (!isFound && hasNextPage);

    return isFound;
};

export const FieldsToPredictActions = {
    setFields,
    validateFieldNameFormat,
    validateFieldNameExists,
    aggregateErrors,
    beginValidateFieldExist,
    endValidateFieldExist,
};
