import {PageModel, Platform, SourceModel} from '@core/api';
import {setKeyValue} from '@core/store';
import {RequestsActions} from '@coveord/jsadmin-common';
import {IThunkAction} from '@coveord/plasma-react';
import {CommerceState} from '../../CommerceState';

export const SourcesActionsTypes = {
    fetch: 'COMMERCE_FETCH_SOURCES',
    updateSources: 'COMMERCE_UPDATE_SOURCES',
    updateSourceIdToNameMap: 'COMMERCE_UPDATE_SOURCE_ID_TO_NAME_MAP',
};

const updateSources = (sources: SourceModel[]) => setKeyValue(SourcesActionsTypes.updateSources, sources);
const updateSourceIdToNameMap = (sourceIdToNameMap: Map<string, string>) =>
    setKeyValue(SourcesActionsTypes.updateSourceIdToNameMap, sourceIdToNameMap);

const fetchSources = (): IThunkAction<void, CommerceState> => async (dispatch) => {
    await dispatch(
        RequestsActions.handle(SourcesActionsTypes.fetch, async () => {
            const allSources = await fetchAllSources();
            dispatch(updateSources(allSources));
            dispatch(
                updateSourceIdToNameMap(
                    allSources.reduce(
                        (map: Map<string, string>, source) => ({
                            ...map,
                            ...(source.id && source.name ? {[source.id]: source.name} : {}),
                        }),
                        new Map<string, string>(),
                    ),
                ),
            );
        }),
    );
};

const fetchAllSources = async (): Promise<SourceModel[]> => {
    const sourcesPerPage = 100;

    let sources: SourceModel[] = [];
    let page = 0;
    let pageModel: PageModel<SourceModel, 'sourceModels'>;
    do {
        pageModel = await Platform.source.list({page, perPage: sourcesPerPage});
        sources = sources.concat(pageModel.sourceModels || []);

        ++page;
    } while (pageModel.totalPages > page);

    return sources;
};

export const SourcesActions = {
    fetchSources,
};
