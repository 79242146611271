/**
 * The content's subroute defined after `/:organizationId/content`.
 */
export const content: Record<string, string> = {
    '/:organizationId/content/browser': 'viewed content browser',
    '/:organizationId/content/crawling-module': 'viewed crawling modules',
    '/:organizationId/content/crawling-module/activity': 'viewed crawling modules activity',
    '/:organizationId/content/extensions': 'viewed extensions',
    '/:organizationId/content/extensions/:extensionId': 'viewed extension edit',
    '/:organizationId/content/extensions/:extensionId/:extensionName/versions': 'viewed extension versions',
    '/:organizationId/content/extensions/:extensionId/:extensionName/versions/:versionId':
        'viewed extension version edit',
    '/:organizationId/content/extensions/:extensionId/disable': 'viewed extension disable',
    '/:organizationId/content/extensions/:extensionId/info': 'viewed extension usage analytics',
    '/:organizationId/content/extensions/activity': 'viewed extensions activity',
    '/:organizationId/content/extensions/new': 'viewed extension add',
    '/:organizationId/content/fields': 'viewed fields',
    '/:organizationId/content/fields/activity': 'viewed fields activity',
    '/:organizationId/content/fields/edit/:fieldName': 'viewed field edit',
    '/:organizationId/content/fields/new': 'viewed field add',
    '/:organizationId/coveo-only/index': 'viewed indexes',
    '/:organizationId/coveo-only/index/activity': 'viewed indexes activity',
    '/:organizationId/coveo-only/index/copyIndex/:indexId': 'viewed index copy',
    '/:organizationId/coveo-only/index/details/:indexId': 'viewed index details',
    '/:organizationId/coveo-only/index/editJson/:indexId': 'viewed index edit json',
    '/:organizationId/coveo-only/index/new': 'viewed index add',
    '/:organizationId/coveo-only/index/resizeDisk/:indexId': 'viewed index disk space add',
    '/:organizationId/coveo-only/index/restore/:indexId': 'viewed index restore backup',
    '/:organizationId/content/security-identities': 'viewed security identities',
    '/:organizationId/content/security-identities/activity': 'viewed security identities activity',
    '/:organizationId/content/security-identities/edit/:securityProvider': 'viewed security provider edit json',
    '/:organizationId/content/security-identities/explore-identities/:securityProvider':
        'viewed security provider identities',
    '/:organizationId/content/security-identities/global-stats': 'viewed security identities global stats',
    '/:organizationId/content/security-identities/relationships/:identityId': 'viewed security identity relationships',
    '/:organizationId/content/security-identities/schedules/:securityProvider': 'viewed security provider schedules',
    '/:organizationId/content/security-identities/stats/:securityProvider': 'viewed security provider additional stats',
    '/:organizationId/content/sources': 'viewed sources',
    '/:organizationId/content/sources/:editType/:sourceType/:sourceId/access': 'viewed source edit access',
    '/:organizationId/content/sources/:editType/:sourceType/:sourceId/configuration':
        'viewed source edit configuration',
    '/:organizationId/content/sources/:editType/:sourceType/:sourceId/security': 'viewed source edit content security',
    '/:organizationId/content/sources/:editType/:sourceType/NEW/access': 'viewed source add access',
    '/:organizationId/content/sources/:editType/:sourceType/NEW/configuration': 'viewed source add configuration',
    '/:organizationId/content/sources/:editType/:sourceType/NEW/security': 'viewed source add content security',
    '/:organizationId/content/sources/:sourceId/metadata': 'viewed source metadata',
    '/:organizationId/content/sources/:sourceId/metadata/activity': 'viewed source metadata activity',
    '/:organizationId/content/sources/:sourceId/metadata/:metadataName/mappings/create':
        'viewed source metadata mapping create',
    '/:organizationId/content/sources/:sourceId/metadata/:metadataName/mappings/view':
        'viewed source metadata mapping view',
    '/:organizationId/content/sources/:sourceId/metadata/:metadataName/mappings/create/field/create':
        'viewed source metadata mapping field create',
    '/:organizationId/content/sources/:sourceId/metadata/:metadataName/mappings/create/field/edit/:fieldName':
        'viewed source metadata mapping field edit',
    '/:organizationId/content/sources/add': 'viewed source type add',
    '/:organizationId/content/sources/duplicate/:sourceType/:sourceId': 'viewed source duplicate',
    '/:organizationId/content/sources/editExtension/:sourceId': 'viewed source extensions',
    '/:organizationId/content/sources/editExtension/:sourceId/addExtension': 'viewed source extension apply',
    '/:organizationId/content/sources/editExtension/:sourceId/addExtension/manageExtensions/:extensionId':
        'viewed source extension edit',
    '/:organizationId/content/sources/editExtension/:sourceId/addExtension/manageExtensions/NEW':
        'viewed source extension add',
    '/:organizationId/content/sources/editExtension/:sourceId/addItem': 'viewed source extensions item type add',
    '/:organizationId/content/sources/editJSON/:sourceId': 'viewed source edit json',
    '/:organizationId/content/sources/editMapping/:sourceId/addMapping/editField/:fieldId':
        'viewed source mapping field edit',
    '/:organizationId/content/sources/editMapping/:sourceId/addMapping/editField/NEW':
        'viewed source mapping field add',
    '/:organizationId/content/sources/editMapping/:sourceId': 'viewed source mappings',
    '/:organizationId/content/sources/editMapping/:sourceId/addItem': 'viewed source mappings item type add',
    '/:organizationId/content/sources/editMapping/:sourceId/addMapping': 'viewed source mapping apply',
    '/:organizationId/content/sources/editSchedules/:sourceType/:sourceId': 'viewed source schedules',
    '/:organizationId/content/sources/subscribe-notifications/:sourceId/:notificationId':
        'viewed source subscription to notifications edit',
    '/:organizationId/content/sources/subscribe-notifications/:sourceId/NEW':
        'viewed source subscribe to notifications',
    '/:organizationId/content/sources/viewActivity/:sourceId': 'viewed source activity',
    '/:organizationId/content/sources/:sourceType/:category/create': 'viewed source add',
    '/:organizationId/content/sources/:sourceType/:category/create/configuration': 'viewed source add configuration',
    '/:organizationId/content/sources/:sourceType/:category/create/configuration/identification':
        'viewed source add source configuration',
    '/:organizationId/content/sources/:sourceType/:category/create/configuration/database-connection-parameters':
        'viewed source add database connection parameters',
    '/:organizationId/content/sources/:sourceType/:category/create/configuration/database-configuration':
        'viewed source add database configuration',
    '/:organizationId/content/sources/:sourceType/:category/create/configuration/advanced-settings':
        'viewed source add advanced settings',
    '/:organizationId/content/sources/:sourceType/:category/create/content-security':
        'viewed source add content security',
    '/:organizationId/content/sources/:sourceType/:category/create/access/groups': 'viewed source add access groups',
    '/:organizationId/content/sources/:sourceType/:category/create/access/api-keys':
        'viewed source add access api keys',
    '/:organizationId/content/sources/:sourceType/:category/edit/:sourceId/configuration':
        'viewed source edit configuration',
    '/:organizationId/content/sources/:sourceType/:category/edit/:sourceId/configuration/crawling-rules':
        'viewed source edit crawling rules',
    '/:organizationId/content/sources/:sourceType/:category/edit/:sourceId/configuration/web-scraping':
        'viewed source edit web scraping',
    '/:organizationId/content/sources/:sourceType/:category/edit/:sourceId/configuration/web-scraping/:configPathNumber/basic-configuration':
        'viewed source edit web scraping basic configuration',
    '/:organizationId/content/sources/:sourceType/:category/edit/:sourceId/configuration/web-scraping/:configPathNumber/content-exclusion':
        'viewed source edit web scraping content exclusion',
    '/:organizationId/content/sources/:sourceType/:category/edit/:sourceId/configuration/web-scraping/:configPathNumber/custom-metadata':
        'viewed source edit web scraping custom metadata',
    '/:organizationId/content/sources/:sourceType/:category/edit/:sourceId/configuration/web-scraping/json':
        'viewed source edit web scraping json configuration',
    '/:organizationId/content/sources/:sourceType/:category/edit/:sourceId/configuration/additional-content':
        'viewed source edit additional content',
    '/:organizationId/content/sources/:sourceType/:category/edit/:sourceId/configuration/database-connection-parameters':
        'viewed source edit connection parameters',
    '/:organizationId/content/sources/:sourceType/:category/edit/:sourceId/configuration/database-configuration':
        'viewed source edit database configuration',
    '/:organizationId/content/sources/:sourceType/:category/edit/:sourceId/configuration/advanced-settings':
        'viewed source edit advanced settings',
    '/:organizationId/content/sources/:sourceType/:category/edit/:sourceId/configuration/authentication':
        'viewed source edit authentication',
    '/:organizationId/content/sources/:sourceType/:category/edit/:sourceId/configuration/channels':
        'viewed source edit channels',
    '/:organizationId/content/sources/:sourceType/:category/edit/:sourceId/configuration/content-to-index':
        'viewed source edit content to index',
    '/:organizationId/content/sources/:sourceType/:category/edit/:sourceId/configuration/messages':
        'viewed source edit messages',
    '/:organizationId/content/sources/:sourceType/:category/edit/:sourceId/content-security':
        'viewed source edit content security',
    '/:organizationId/content/sources/:sourceType/:category/edit/:sourceId/access/groups':
        'viewed source edit access groups',
    '/:organizationId/content/sources/:sourceType/:category/edit/:sourceId/access/api-keys':
        'viewed source edit access api keys',
};
