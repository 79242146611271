import {PrivilegeModel} from '@core/api';
import {PrivilegesAccessOptions, PrivilegesConstants, PrivilegeTypes} from '@core/user';

import {AccessLevelList, AccessLevelListAttributes} from './AccessLevelList';

export interface AccessLevelListFactoryOptions {
    domain: string;
    allowedPrivileges?: PrivilegeModel[];
    possiblePrivileges?: PrivilegeModel[];
    granular?: boolean;
}

export class AccessLevelListFactory {
    private static readonly canCannot: AccessLevelListAttributes = {
        nullLevel: PrivilegesAccessOptions.None,
        items: [
            {
                value: PrivilegesAccessOptions.Can,
                weight: 0,
            },
        ],
    };

    private static readonly editViewNone: AccessLevelListAttributes = {
        nullLevel: PrivilegesAccessOptions.None,
        items: [
            {
                value: PrivilegesAccessOptions.View,
                givesPrivilegeTypes: [PrivilegeTypes.View],
                requiredPrivilegeTypes: PrivilegeTypes.View,
                weight: 0,
            },
            {
                value: PrivilegesAccessOptions.Edit,
                givesPrivilegeTypes: [PrivilegeTypes.Edit, PrivilegeTypes.Create, PrivilegeTypes.View],
                requiredPrivilegeTypes: [PrivilegeTypes.Edit],
                weight: 1,
            },
        ],
    };

    private static readonly pushAndView: AccessLevelListAttributes = {
        nullLevel: PrivilegesAccessOptions.None,
        items: [
            {
                value: PrivilegesAccessOptions.Push,
                givesPrivilegeTypes: [PrivilegeTypes.Edit],
                requiredPrivilegeTypes: PrivilegeTypes.Edit,
                weight: 0,
            },
            {
                value: PrivilegesAccessOptions.View,
                givesPrivilegeTypes: [PrivilegeTypes.View],
                requiredPrivilegeTypes: PrivilegeTypes.View,
                weight: 1,
            },
            {
                value: PrivilegesAccessOptions.PushAndView,
                givesPrivilegeTypes: [PrivilegeTypes.Edit, PrivilegeTypes.View],
                requiredPrivilegeTypes: [PrivilegeTypes.Edit, PrivilegeTypes.View],
                weight: 2,
            },
        ],
    };

    private static readonly granularPushSource: AccessLevelListAttributes = {
        nullLevel: PrivilegesAccessOptions.None,
        items: [
            {
                value: PrivilegesAccessOptions.Custom,
                weight: 1,
            },
            {
                value: PrivilegesAccessOptions.AllowAllSources,
                weight: 2,
            },
        ],
    };

    private static readonly granularPushProvider: AccessLevelListAttributes = {
        nullLevel: PrivilegesAccessOptions.None,
        items: [
            {
                value: PrivilegesAccessOptions.Custom,
                weight: 1,
            },
            {
                value: PrivilegesAccessOptions.AllowAllProviders,
                weight: 2,
            },
        ],
    };

    private static readonly granular: AccessLevelListAttributes = {
        nullLevel: PrivilegesAccessOptions.None,
        items: [
            {
                value: PrivilegesAccessOptions.ViewAll,
                givesPrivilegeTypes: [PrivilegeTypes.View, PrivilegeTypes.Create],
                requiredPrivilegeTypes: PrivilegeTypes.View,
                weight: 1,
            },
            {
                value: PrivilegesAccessOptions.Custom,
                givesPrivilegeTypes: [PrivilegeTypes.View],
                requiredPrivilegeTypes: [PrivilegeTypes.Edit],
                weight: 2,
            },
            {
                value: PrivilegesAccessOptions.EditAll,
                givesPrivilegeTypes: [PrivilegeTypes.Edit, PrivilegeTypes.Create, PrivilegeTypes.View],
                requiredPrivilegeTypes: [PrivilegeTypes.Edit],
                weight: 3,
            },
        ],
    };

    private static readonly createOrNone: AccessLevelListAttributes = {
        nullLevel: PrivilegesAccessOptions.None,
        items: [
            {
                value: PrivilegesAccessOptions.Create,
                givesPrivilegeTypes: [PrivilegeTypes.Create],
                requiredPrivilegeTypes: PrivilegeTypes.Create,
                weight: 0,
            },
        ],
    };

    private static readonly defaultAccessLevelList: AccessLevelListAttributes = AccessLevelListFactory.editViewNone;

    private static readonly domainToAccessLevelList: {[domainId: string]: AccessLevelListAttributes} = {
        // Add domain specific access levels options here
        // UA
        ADMINISTRATE_USAGE_ANALYTICS_NORMAL: AccessLevelListFactory.canCannot,
        IMPERSONATE_USAGE_ANALYTICS_NORMAL: AccessLevelListFactory.canCannot,
        QUERY_SUGGEST_USAGE_ANALYTICS_NORMAL: AccessLevelListFactory.canCannot,
        VIEW_ALL_REPORTS_USAGE_ANALYTICS_NORMAL: AccessLevelListFactory.canCannot,
        ANALYTICS_DATA_USAGE_ANALYTICS_NORMAL: AccessLevelListFactory.pushAndView,
        DELETE_USER_ANALYTICS_DATA_USAGE_ANALYTICS_NORMAL: AccessLevelListFactory.canCannot,
        // Search API
        IMPERSONATE_SEARCH_API_NORMAL: AccessLevelListFactory.canCannot,
        EXECUTE_QUERY_SEARCH_API_NORMAL: AccessLevelListFactory.canCannot,
        VIEW_ALL_CONTENT_SEARCH_API_NORMAL: AccessLevelListFactory.canCannot,
        AUTHENTICATION_EDITOR_SEARCH_API_NORMAL: AccessLevelListFactory.canCannot,
        // Customer Service
        USE_CASE_ASSIST_CUSTOMER_SERVICE_NORMAL: AccessLevelListFactory.canCannot,
        // Knowledge
        CHUNK_INSPECTOR_KNOWLEDGE_NORMAL: AccessLevelListFactory.canCannot,
        KNOWLEDGE_HUB_KNOWLEDGE_NORMAL: AccessLevelListFactory.canCannot,
        // Machine Learning
        ALLOW_CONTENT_PREVIEW_COVEO_ML_NORMAL: AccessLevelListFactory.canCannot,
        // Content
        CRAWLING_MODULE_LOG_REQUEST_PLATFORM_NORMAL: AccessLevelListFactory.editViewNone,
        // Coveo only
        ORGANIZATION_INFRASTRUCTURE_PLATFORM_GLOBAL: AccessLevelListFactory.createOrNone,
        MANAGE_CUSTOMER_LICENSE_PLATFORM_INTERNAL: AccessLevelListFactory.canCannot,
        MANAGE_CUSTOMER_LICENSE_OVERRIDES_PLATFORM_INTERNAL: AccessLevelListFactory.canCannot,
        // Granular
        [PrivilegesConstants.granularDomains.PushDocuments]: AccessLevelListFactory.granularPushSource,
        [PrivilegesConstants.granularDomains.PushIdentities]: AccessLevelListFactory.granularPushProvider,
        [PrivilegesConstants.granularDomains.Sources]: AccessLevelListFactory.granular,
        [PrivilegesConstants.granularDomains.ApiKeys]: AccessLevelListFactory.granular,
        [PrivilegesConstants.granularDomains.Extensions]: AccessLevelListFactory.granular,
        [PrivilegesConstants.granularDomains.Pipelines]: AccessLevelListFactory.granular,
        [PrivilegesConstants.granularDomains.Groups]: AccessLevelListFactory.granular,
    };

    static getAccessLevelList({
        domain,
        allowedPrivileges = [],
        possiblePrivileges = [],
    }: AccessLevelListFactoryOptions): AccessLevelList {
        const attributes =
            AccessLevelListFactory.domainToAccessLevelList[domain] || AccessLevelListFactory.defaultAccessLevelList;
        return new AccessLevelList(attributes, allowedPrivileges, possiblePrivileges);
    }
}
