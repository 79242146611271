import _ from 'underscore';

export class BackboneUtils {
    static formatAttributeChange(attributeName: string): string {
        return 'change:' + attributeName;
    }

    static extractNestedAttributeValue(attributeName: string, model: Backbone.Model) {
        if (_.isString(attributeName)) {
            const attributeNameParts = attributeName.split('.');

            let nested = model.get(attributeNameParts[0]);
            for (let i = 1; i < attributeNameParts.length; i++) {
                const part = attributeNameParts[i];
                nested = nested[part];
            }

            return nested;
        }

        return model.get(attributeName);
    }

    static cloneModels<TModel extends Backbone.Model>(collection: Backbone.Collection<TModel>): TModel[] {
        return collection.map((model: Backbone.Model) => model.clone());
    }

    static cleanJSONString(val: any) {
        let potentialJavaScriptObject: any;

        if (_.isString(val)) {
            try {
                potentialJavaScriptObject = JSON.parse(val);
            } catch (e) {
                potentialJavaScriptObject = undefined;
            }

            if (_.isObject(potentialJavaScriptObject)) {
                return JSON.stringify(potentialJavaScriptObject);
            }
        }

        return val;
    }

    static isModelEdited(model: Backbone.Model, editedAttributes: any): boolean {
        // Make sure to always compare JavaScript Object with Object.
        const originalAttributes = JSON.parse(JSON.stringify(model.attributes));

        // For all keys in edited attributes, if a value has changed, the model is dirty
        return _.some(editedAttributes, (val, key) => {
            const cleanEditedValue = BackboneUtils.cleanJSONString(val);
            const cleanOriginalValue = BackboneUtils.cleanJSONString(originalAttributes[key]);

            return cleanOriginalValue != null && !_.isEqual(cleanEditedValue, cleanOriginalValue);
        });
    }
}
