import {IReduxAction} from '@coveord/plasma-react';
import {
    FieldsToPredictActionTypes,
    IEndValidateFieldToPredictNamePayload,
    IValidateFieldToPredictNamePayload,
} from './FieldsToPredictActions';

export type IFieldsToPredictState = Record<string, IFieldsToPredictInstanceState>;

export interface IFieldsToPredictInstanceState {
    inputs: {[key: string]: IFieldsToPredictInputState};
}

export interface IFieldsToPredictInputState {
    validating: boolean;
    valid: boolean;
}

export const FieldsToPredictInitialState = {};

const beginValidateFieldExist = (
    state: IFieldsToPredictState,
    action: IReduxAction<IValidateFieldToPredictNamePayload>,
): IFieldsToPredictState => {
    if (!action.payload?.id || !action.payload?.inputId) {
        return state;
    }

    const id = action.payload!.id;
    const inputId = action.payload!.inputId;

    return {
        ...state,
        [id]: {
            ...state[id],
            inputs: {
                ...state[id]?.inputs,
                [inputId]: {
                    ...(state[id]?.inputs[inputId] ?? {valid: false}),
                    validating: true,
                },
            },
        },
    };
};

const endValidateFieldExist = (
    state: IFieldsToPredictState,
    action: IReduxAction<IEndValidateFieldToPredictNamePayload>,
): IFieldsToPredictState => {
    if (!action.payload?.id || !action.payload?.inputId) {
        return state;
    }

    const id = action.payload!.id;
    const inputId = action.payload!.inputId;

    return {
        ...state,
        [id]: {
            ...state[id],
            inputs: {
                ...state[id]?.inputs,
                [inputId]: {
                    ...state[id]?.inputs[inputId],
                    validating: false,
                    valid: action.payload!.valid,
                },
            },
        },
    };
};

const fieldsToPredictReducers = {
    [FieldsToPredictActionTypes.beginValidateFieldExist]: beginValidateFieldExist,
    [FieldsToPredictActionTypes.endValidateFieldExist]: endValidateFieldExist,
};

export const fieldsToPredictReducer = (state, action) =>
    _.isUndefined(fieldsToPredictReducers[action.type]) ? state : fieldsToPredictReducers[action.type](state, action);
