import {PlatformPaths} from '@configurations/platform';
import {RouteObject} from '@core/routes';

export const MLRoutes: RouteObject[] = [
    {
        path: `${PlatformPaths.MachineLearningModels.fullPath}/*`,
        lazy: async () => {
            const {ModelsPage} = await import('../components/models-page');
            return {Component: ModelsPage};
        },
    },
    {
        path: `${PlatformPaths.EditMachineLearningModelByTab.fullPath}/*`,
        lazy: async () => {
            const {ModelEdition} = await import('../components/model-edition');
            return {Component: ModelEdition};
        },
    },
];
