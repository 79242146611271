import {IReduxAction} from '@coveord/plasma-react';
import _ from 'underscore';
import {GlobalSetPayload} from '../actions';
import {Requests} from '../constants';

export interface MetricStat {
    id: string;
    value: number;
}

export interface SearchHubGlobalState {
    [key: string]: MetricStat;
}

const set = (state: SearchHubGlobalState, action: IReduxAction<GlobalSetPayload>) => ({
    ...state,
    [action.payload.id]: {id: action.payload.id, value: action.payload.value},
});

const GlobalReducers: {[key: string]: (...args: any[]) => SearchHubGlobalState} = {
    [Requests.Global.set]: set,
    [Requests.Global.clear]: () => null,
};

type GlobalPayload = Record<string, unknown> | GlobalSetPayload;
export const searchHubGlobalReducers = (
    state: SearchHubGlobalState = null,
    action: IReduxAction<GlobalPayload>,
): SearchHubGlobalState => {
    if (!_.isUndefined(GlobalReducers[action.type])) {
        return GlobalReducers[action.type](state, action);
    }

    return state;
};
