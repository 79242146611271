import {IReduxAction} from '@coveord/plasma-react';
import {
    generateActions,
    generateActionTypes,
    IWithQuerySyntaxValidationActions,
    IWithQuerySyntaxValidationActionTypes,
    IWithQuerySyntaxValidationPayload,
} from './WithQuerySyntaxValidationActions';

export interface IWithQuerySyntaxValidationState {
    validatingQuerySyntax: string[];
}

export interface IWithQuerySyntaxValidationReducer {
    actionTypes: IWithQuerySyntaxValidationActionTypes;
    actions: IWithQuerySyntaxValidationActions;
    initialState: IWithQuerySyntaxValidationState;
    reducer: WithQuerySyntaxValidationReducer;
}

export type WithQuerySyntaxValidationReducer = <TState extends IWithQuerySyntaxValidationState>(
    state: TState,
    action: IReduxAction<any>,
) => TState;

const generateReducersMapping = (
    actionTypes: IWithQuerySyntaxValidationActionTypes,
): {[key: string]: WithQuerySyntaxValidationReducer} => ({
    [actionTypes.beginValidateSyntax]: beginValidateSyntax,
    [actionTypes.endValidateSyntax]: endValidateSyntax,
});

export const createWithQuerySyntaxValidationReducer = (context: string): IWithQuerySyntaxValidationReducer => {
    const actionTypes = generateActionTypes(context);

    return {
        actionTypes,
        actions: generateActions(context, actionTypes),
        initialState: {
            validatingQuerySyntax: [],
        },
        reducer: (state, action) =>
            withQuerySyntaxValidationReducer(generateReducersMapping(actionTypes), state, action),
    };
};

const beginValidateSyntax = <TState extends IWithQuerySyntaxValidationState>(
    state: TState,
    action: IReduxAction<IWithQuerySyntaxValidationPayload>,
): TState =>
    action?.payload?.id
        ? {...state, validatingQuerySyntax: [...state.validatingQuerySyntax, action.payload.id]}
        : state;

const endValidateSyntax = <TState extends IWithQuerySyntaxValidationState>(
    state: TState,
    action: IReduxAction<IWithQuerySyntaxValidationPayload>,
): TState =>
    action?.payload?.id
        ? {...state, validatingQuerySyntax: state.validatingQuerySyntax.filter((id) => id !== action.payload!.id)}
        : state;

const withQuerySyntaxValidationReducer = <TState extends IWithQuerySyntaxValidationState>(
    reducers: {[key: string]: WithQuerySyntaxValidationReducer},
    state: TState,
    action: IReduxAction<any> = {type: '', payload: {}},
): TState => (_.isUndefined(reducers[action.type]) ? state : reducers[action.type](state, action));
