import moment from 'moment';
import {IThunkAction} from '@coveord/plasma-react';
import _ from 'underscore';

import {Config} from '@core/configuration';
import {ObjectKeyValueActions} from '@core/store';
import {CommonState} from '../../redux/CommonState';
import {RequestsActions} from '../../redux/requests/RequestsActions';
import {DimensionValue} from '../DimensionsCommon';

export const DimensionValuesActionsType = {
    set: 'SET_DIMENSION_VALUES',
    request: 'REQUEST_DIMENSION_VALUES',
};

const getValues =
    (
        dimension: string,
        from = moment().subtract(1, 'M').toISOString(),
        to = moment().toISOString(),
    ): IThunkAction<JQueryPromise<any>, CommonState> =>
    (dispatch) => {
        dispatch(RequestsActions.request(DimensionValuesActionsType.request));

        const cleanDimension = _.last(dimension.split('.'));
        return $.get(`${Config.UsageAnalytics.url}/dimensions/${cleanDimension}/values`, {from, to})
            .done((response: {values: DimensionValue[]}) => {
                dispatch(ObjectKeyValueActions.update(DimensionValuesActionsType.set, {[dimension]: response.values}));
                dispatch(RequestsActions.success(DimensionValuesActionsType.request));
            })
            .fail((xhr: JQueryXHR) => {
                xhr.errorHandled = true;
                dispatch(RequestsActions.failure(DimensionValuesActionsType.request));
            });
    };

export const DimensionValuesActions = {
    getValues,
};
