import {IReduxAction} from '@coveord/plasma-react';
import _ from 'underscore';
import {ComponentIdMap} from '../../../ComponentIdMap';
import {CardActionPayload, CardSetErrorPayload} from '../../actions/CardActions';
import {CardState} from '../../CardState';
import {MetricCardUpdatePayload} from '../../Metric/actions/MetricCardActions';
import {MetricCardState} from '../../Metric/MetricCardState';
import {CalculatedMetricInitializePayload, CalculatedMetricSetPayload} from '../actions/CalculatedMetricActions';
import {EditCalculatedMetricSavePayload} from '../actions/EditCalculatedMetricActions';
import {CalculatedMetricCardState} from '../CalculatedMetricState';

export const isCalculatedMetricState = (state: CardState): state is CalculatedMetricCardState =>
    state.type === ComponentIdMap.CalculatedMetricComponent;

export const calculatedMetricInitializeReducer = (
    state: CardState,
    action: IReduxAction<CalculatedMetricInitializePayload>,
) =>
    _.extend({}, state, {
        metricA: action.payload.metricA,
        metricB: action.payload.metricB,
        operator: action.payload.operator,
        format: action.payload.format,
    });

export const calculatedMetricLoadingReducer = (
    state: CalculatedMetricCardState,
    action: IReduxAction<CardActionPayload>,
) =>
    _.extend({}, state, {
        valueA: null,
        valueB: null,
        isLoading: true,
    });

export const calculatedMetricSetReducer = (
    state: CalculatedMetricCardState,
    action: IReduxAction<CalculatedMetricSetPayload>,
) =>
    _.extend({}, state, {
        valueA: _.isNumber(action.payload.valueA) ? action.payload.valueA : state.valueA,
        valueB: _.isNumber(action.payload.valueB) ? action.payload.valueB : state.valueB,
    });

export const calculatedMetricSaveReducer = (
    state: CardState[],
    action: IReduxAction<EditCalculatedMetricSavePayload>,
) => {
    const {operator, title, format, metricA, metricB} = action.payload;
    const metricCardA = _.isString(metricA) ? (_.findWhere(state, {id: metricA}) as MetricCardState) : null;
    const metricCardB = _.isString(metricB) ? (_.findWhere(state, {id: metricB}) as MetricCardState) : null;
    const valueA = metricCardA ? metricCardA.value : metricA || 0;
    const valueB = metricCardB ? metricCardB.value : metricB || 0;

    return _.map(state, (cardState: CardState) => {
        if (cardState.id === action.payload.id) {
            return _.extend({}, cardState, {operator, title, format, metricA, metricB, valueA, valueB});
        } else {
            return cardState;
        }
    });
};

export const calculatedMetricSetErrorReducer = (state: CardState[], action: IReduxAction<CardSetErrorPayload>) =>
    _.map(state, (cardState: CardState) => {
        if (isCalculatedMetricState(cardState)) {
            const newState = _.extend({}, cardState);
            if (newState.metricA === action.payload.id) {
                newState.valueA = null;
                newState.error = action.payload.error;
                return newState;
            } else if (newState.metricB === action.payload.id) {
                newState.valueB = null;
                newState.error = action.payload.error;
                return newState;
            }
        }
        return cardState;
    });

export const calculatedMetricUpdateReducer = (state: CardState[], action: IReduxAction<MetricCardUpdatePayload>) =>
    _.map(state, (cardState: CardState) => {
        if (isCalculatedMetricState(cardState)) {
            const newState = _.extend({}, cardState);
            if (newState.metricA === action.payload.id) {
                newState.valueA = action.payload.value;
                newState.error = null;
                return newState;
            }
            if (newState.metricB === action.payload.id) {
                newState.valueB = action.payload.value;
                newState.error = null;
                return newState;
            }
        }
        return cardState;
    });

export const calculatedMetricReloadReducer = (state: CardState[], action: IReduxAction<MetricCardUpdatePayload>) =>
    _.map(state, (cardState: CardState) => {
        if (isCalculatedMetricState(cardState) && cardState.id === action.payload.id) {
            const newState = _.extend({}, cardState);
            _.each(
                [
                    {value: 'valueA', metric: newState.metricA},
                    {value: 'valueB', metric: newState.metricB},
                ],
                (property) => {
                    if (_.isString(property.metric)) {
                        const other = _.findWhere(state, {id: property.metric}) as MetricCardState;
                        if (_.isUndefined(other)) {
                            // The card don't need to load if the other was deleted
                            newState.isLoading = false;
                        } else if (other.value !== null) {
                            newState[property.value] = other.value;
                        }
                    }
                },
            );
            return newState;
        }
        return cardState;
    });

export const calculatedMetricUnmountReducer = (state: CardState[], action: IReduxAction<CardActionPayload>) =>
    _.map(state, (cardState: CardState) => {
        const newState = _.extend({}, cardState);
        if (isCalculatedMetricState(newState)) {
            if (newState.metricA === action.payload.id) {
                newState.valueA = null;
            }
            if (newState.metricB === action.payload.id) {
                newState.valueB = null;
            }
        }
        return newState;
    });
