import {UAUserAttributes} from './UAUser';

export interface UAUsersState {
    error: boolean;
    loaded: boolean;
    users: UAUserAttributes[];
}

export const UAUsersDefaultState: UAUsersState = {
    error: false,
    loaded: false,
    users: [],
};
