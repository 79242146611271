import {LicenseSelectors} from '@core/license';
import {useNavigate} from '@core/routes';
import {FunctionComponent, useEffect} from 'react';
import {useSelector} from 'react-redux';

export const RedirectToFirstPage: FunctionComponent = () => {
    const isTrial = useSelector(LicenseSelectors.isTrialLicense);
    const navigate = useNavigate();
    useEffect(() => {
        const firstAvailableLink = Array.from(document.querySelectorAll<HTMLAnchorElement>('a[data-navlink]'))[0];
        if (firstAvailableLink) {
            firstAvailableLink.click();
        } else if (isTrial) {
            // When a trial is expired there is no nav displayed, so no available link
            navigate('/home');
        } else {
            // No access to any sidenav links nor /home. For boundary routes (see pages/boundaries).
            window.location.reload();
        }
    }, []);
    return null;
};
