import {OrganizationConfigurationModel, RegionConfigurationModel} from '@core/api';
import {KeyValueReducerGenerator} from '@core/store';
import {ReducersMapObject, combineReducers} from 'redux';
import {GlobalConfigurationConstants} from './GlobalConfigurationConstants';

export interface GlobalConfigurationState {
    regions: RegionConfigurationModel[];
    organization: OrganizationConfigurationModel;
}

const reducers: ReducersMapObject<GlobalConfigurationState> = {
    regions: KeyValueReducerGenerator<RegionConfigurationModel[]>(GlobalConfigurationConstants.actionTypes.regions),
    organization: KeyValueReducerGenerator<OrganizationConfigurationModel>(
        GlobalConfigurationConstants.actionTypes.organization,
    ),
};

export const globalConfigurationReducer = combineReducers<GlobalConfigurationState>(reducers);
