import _ from 'underscore';

import {UNDEFINED_GROUPBY_KEY} from '../../Constants';
import {PickySelectable} from '../PickyModels';

export class GroupItemsCollection<TModel extends PickySelectable> extends Backbone.Collection<TModel> {
    private p_groupKey: string;

    get groupKey(): string {
        return this.p_groupKey;
    }

    get selectedLength(): number {
        return this.filter((model: PickySelectable) => model.selected).length;
    }

    constructor(models, key: string) {
        super(models);
        this.p_groupKey = _.isUndefined(key) || _.isNull(key) ? UNDEFINED_GROUPBY_KEY : key.toString();
    }
}
