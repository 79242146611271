import {BasePayload} from '@coveord/plasma-react';
import {Filter} from '../FilterInterface';

export const EventFilterActionsType = {
    add: 'ADD_EVENT_FILTER',
    remove: 'REMOVE_EVENT_FILTER',
    setEditMode: 'SET_EDIT_MODE_EVENT_FILTER',
    edit: 'EDIT_EVENT_FILTER',
};

export interface EventFilterActionPayload extends BasePayload {
    filter: Filter;
    eventFilterSetId: string;
}

const add = (id: string, eventFilterSetId: string, filter: Filter) => ({
    type: EventFilterActionsType.add,
    payload: {id, eventFilterSetId, filter},
});

const set = (id: string, eventFilterSetId: string, filter: Filter) => ({
    type: EventFilterActionsType.edit,
    payload: {id, eventFilterSetId, filter},
});

const remove = (id: string) => ({
    type: EventFilterActionsType.remove,
    payload: {id},
});

export const EventFilterActions = {
    add,
    set,
    remove,
};
