import {GlobalPrivilegeModel, PrivilegeModel} from '@core/api';
import {
    ArraysOfObjects,
    PrivilegeLevels,
    PrivilegesAccessOptions,
    PrivilegesConstants,
    PrivilegesListMap,
    PrivilegeTypes,
    PrivilegeUtils,
} from '@core/user';
import {compose} from 'redux';

import {DefaultGroup, DefaultGroupsState, DefaultGroupsUtils} from '../../groups/DefaultGroups';
import {Locales} from '../../Locales';
import {AccessLevelListFactory} from '../access-level-list/AccessLevelListFactory';
import {isBinaryDomain} from '../granular-privileges-table/GranularPrivilegesTableConstants';
import {PrivilegesPresetChooser} from './PrivilegesPresetChooser';
import {
    AccessLevelDropdownAttributes,
    CanCreateAttributes,
    PrivilegesTableRow,
    PrivilegesTableSection,
} from './PrivilegesTable';
import {
    PrivilegesTableContext,
    PrivilegesTableRowContext,
    PrivilegesTableSelectorProps,
} from './PrivilegesTableSelectors';
import {PrivilegeWarning, PrivilegeWarningProps} from './PrivilegeWarning';

export interface PrivilegeLike extends PrivilegeModel {
    [key: string]: any;
}

const normalizePrivileges = compose(
    (cleanedUserPrivileges: PrivilegeModel[]) => ArraysOfObjects.uniq(cleanedUserPrivileges),
    (allUserPrivileges: PrivilegeLike[]) =>
        (allUserPrivileges &&
            allUserPrivileges
                .filter(
                    (privilege: PrivilegeLike) =>
                        _.isUndefined(privilege.level) || privilege.level === PrivilegeLevels.Normal,
                )
                .map((privilege: PrivilegeLike) => {
                    const attributesToOmit = _.compact([
                        'level',
                        'global',
                        privilege.type === PrivilegeTypes.Allow && 'type',
                    ]);
                    return _.omit(privilege, ...attributesToOmit);
                })
                .reduce(
                    (memo: PrivilegeModel[], privilege: PrivilegeModel) =>
                        privilege.type === PrivilegeTypes.Edit &&
                        privilege.targetId === PrivilegesConstants.allTargetIds
                            ? [...memo, privilege, {...privilege, type: PrivilegeTypes.Create}]
                            : [...memo, privilege],
                    [],
                )) ||
        [],
);

const getSortedKeys = <T extends Record<string, unknown>>(obj: T): string[] => _.keys(obj).sort();

const computeAppliedPreset = (
    props: PrivilegesTableSelectorProps,
    appliedPrivileges: PrivilegeModel[],
    possiblePrivileges: PrivilegeModel[],
    defaultGroups: DefaultGroupsState,
): string => {
    if (!_.isEmpty(possiblePrivileges)) {
        const viewPrivileges = possiblePrivileges.filter(
            (privilege: PrivilegeModel) => privilege.type === PrivilegeTypes.View,
        );
        const minimumPrivileges = props.minimumPrivileges || [];
        const appliedDefaultGroup: DefaultGroup = _.find(defaultGroups, (defaultGroup: DefaultGroup) => {
            const groupPrivileges = DefaultGroupsUtils.getDefaultGroupPrivilegesWithTargetId(
                defaultGroup,
                props.AppliedPrivilegesList,
            );

            return PrivilegeUtils.listsAreEqual(groupPrivileges, appliedPrivileges);
        });
        if (appliedDefaultGroup && !_.contains(PrivilegesPresetChooser.excludedDefaultGroups, appliedDefaultGroup.id)) {
            return appliedDefaultGroup.id;
        } else if (PrivilegeUtils.listsAreEqual(possiblePrivileges, appliedPrivileges)) {
            return PrivilegesPresetChooser.Values.Maximum;
        } else if (
            PrivilegeUtils.listsAreEqual(viewPrivileges, appliedPrivileges) &&
            props.AvailablePrivilegesList !== 'apikeys-privileges'
        ) {
            return PrivilegesPresetChooser.Values.View;
        } else if (PrivilegeUtils.listsAreEqual(minimumPrivileges, appliedPrivileges)) {
            return PrivilegesPresetChooser.Values.Minimum;
        }
    }

    return null;
};

const getPrivilegeDomainsForSection = (section: string, privilegesBySection: PrivilegesListMap): string[] =>
    Object.keys(PrivilegeUtils.groupByDomain(section && privilegesBySection && privilegesBySection[section]));

const computeAppliedPrivileges =
    (domain: string, accessLevel: string) =>
    (context: PrivilegesTableContext): PrivilegeModel[] => {
        const domainScopedContext: PrivilegesTableRowContext = context[domain];
        const hasPushPrivileges = isBinaryDomain(domain);

        if (_.isEmpty(domainScopedContext) || _.isEmpty(domainScopedContext.availablePrivileges)) {
            return [];
        }

        const basePrivilege = domainScopedContext.availablePrivileges[0];
        const domainSupportsGranularPrivileges = PrivilegeUtils.isGranularDomain(domain);
        const includeCreatePrivilegeIfAvailable =
            (domainSupportsGranularPrivileges &&
                PrivilegeUtils.hasCreatePrivilege(domainScopedContext.appliedPrivileges, basePrivilege)) ||
            (domainSupportsGranularPrivileges && accessLevel === PrivilegesAccessOptions.EditAll) ||
            accessLevel === PrivilegesAccessOptions.Edit;

        if (accessLevel === PrivilegesAccessOptions.Custom && domainSupportsGranularPrivileges) {
            if (hasPushPrivileges) {
                return domainScopedContext.appliedPrivileges.filter(
                    (privilege: PrivilegeModel) => privilege.targetId !== PrivilegesConstants.allTargetIds,
                );
            }
            return [
                ..._.filter(
                    domainScopedContext.appliedPrivileges,
                    (privilege: PrivilegeModel) =>
                        PrivilegeUtils.isGranularPrivilege(privilege) ||
                        (includeCreatePrivilegeIfAvailable && privilege.type === PrivilegeTypes.Create),
                ),
                {...basePrivilege, type: PrivilegeTypes.View, targetId: PrivilegesConstants.allTargetIds},
            ];
        }

        return AccessLevelListFactory.getAccessLevelList({
            domain,
            allowedPrivileges: domainScopedContext.userPrivileges,
            possiblePrivileges: domainScopedContext.availablePrivileges,
            granular: domainScopedContext.props.supportsGranularPrivileges,
        }).getPrivilegesForLevel(basePrivilege, accessLevel, includeCreatePrivilegeIfAvailable);
    };

const buildPrivilegesContext = (
    props: PrivilegesTableSelectorProps,
    availablePrivilegesByDomain: PrivilegesListMap,
    possiblePrivilegesByDomain: PrivilegesListMap,
    appliedPrivilegesByDomain: PrivilegesListMap,
    userPrivilegesByDomain: PrivilegesListMap,
    initialPrivilegesByDomain: PrivilegesListMap,
    exclusivePrivilegesByDomain: PrivilegesListMap,
): PrivilegesTableContext =>
    Object.keys(availablePrivilegesByDomain).reduce((accumulator: PrivilegesTableContext, domain: string) => {
        accumulator[domain] = {
            props,
            domain,
            availablePrivileges: availablePrivilegesByDomain[domain] || [],
            possiblePrivileges: possiblePrivilegesByDomain[domain] || [],
            appliedPrivileges: appliedPrivilegesByDomain[domain] || [],
            userPrivileges: userPrivilegesByDomain[domain] || [],
            initialPrivileges: initialPrivilegesByDomain[domain] || [],
            exclusivePrivileges: exclusivePrivilegesByDomain[domain] || [],
        };
        return accumulator;
    }, {});

const computeTableRows = (sectionPrivilegeDomains: string[], context: PrivilegesTableContext): PrivilegesTableRow[] =>
    _.chain(sectionPrivilegeDomains)
        .map((domain: string) => {
            const domainScopedContext = context[domain];
            return {
                id: domain,
                ...getNameColumn(domainScopedContext),
                ...getScopeColumn(domainScopedContext),
                ...getAccessLevelColumn(domainScopedContext),
                ...getCanCreateColumn(domainScopedContext),
                ...getHasChanged(domainScopedContext),
            };
        })
        .sortBy('name')
        .value();

const getNameColumn = ({availablePrivileges}: PrivilegesTableRowContext): {name: string} => {
    let name: string;

    if (_.isEmpty(availablePrivileges)) {
        name = '';
    } else {
        const targetDomain = availablePrivileges[0].targetDomain;
        name = Locales.formatOrHumanize(`${targetDomain}_DisplayName`, {
            defaultTranslation: targetDomain,
            reportMissingKey: true,
        });
    }

    return {name};
};

const getScopeColumn = ({availablePrivileges}: PrivilegesTableRowContext): {scope: string} => {
    const scope =
        (!_.isEmpty(availablePrivileges) && (availablePrivileges[0] as GlobalPrivilegeModel).level) ||
        PrivilegeLevels.Normal;

    return {scope};
};

const getAccessLevelColumn = (
    context: PrivilegesTableRowContext,
): {accessLevelDropdown: AccessLevelDropdownAttributes} => {
    const accessLevelList = AccessLevelListFactory.getAccessLevelList({
        domain: context.domain,
        allowedPrivileges: context.possiblePrivileges,
        possiblePrivileges: context.availablePrivileges,
        granular: context.props.supportsGranularPrivileges,
    });
    return {
        accessLevelDropdown: {
            accessLevels: accessLevelList.availableValues,
            enabledLevels: accessLevelList.getEnabledValues(context.userPrivileges),
            selectedLevel: accessLevelList.getSelectedLevelForPrivileges(context.appliedPrivileges),
            isUserAuthorizedToExpandCollapsible:
                isBinaryDomain(context.domain) ||
                _.contains(_.pluck(context.userPrivileges, 'type'), PrivilegeTypes.View),
            warnings: getAccessLevelWarning(context),
        },
    };
};

const getAccessLevelWarning = (context: PrivilegesTableRowContext): PrivilegeWarningProps[] => {
    const warnings: PrivilegeWarningProps[] = [];
    const privilegesAdded = ArraysOfObjects.difference(context.appliedPrivileges, context.initialPrivileges);
    const privilegesNotAllowedForUserButSetOnTheResource = ArraysOfObjects.difference(
        context.initialPrivileges,
        context.userPrivileges,
    );

    if (
        !_.isEmpty(context.exclusivePrivileges) &&
        !PrivilegeUtils.hasAllPrivileges(context.appliedPrivileges, context.exclusivePrivileges)
    ) {
        warnings.push({
            type: PrivilegeWarning.Type.Lockout,
            affectedPrivileges: ArraysOfObjects.difference(context.exclusivePrivileges, context.appliedPrivileges),
        });
    }

    if (!PrivilegeUtils.hasAllPrivileges(context.appliedPrivileges, privilegesNotAllowedForUserButSetOnTheResource)) {
        warnings.push({
            type: PrivilegeWarning.Type.Reduction,
            affectedPrivileges: ArraysOfObjects.difference(
                privilegesNotAllowedForUserButSetOnTheResource,
                context.appliedPrivileges,
            ),
        });
    }

    if (PrivilegeUtils.hasSomePrivileges(privilegesAdded, PrivilegesConstants.potentPrivileges)) {
        warnings.push({
            type: PrivilegeWarning.Type.Potent,
            affectedPrivileges: ArraysOfObjects.intersection(PrivilegesConstants.potentPrivileges, privilegesAdded),
        });
    }
    return warnings;
};

const getCanCreateColumn = (context: PrivilegesTableRowContext): {canCreate?: CanCreateAttributes} => {
    const hasCreate = (privileges): boolean =>
        PrivilegeUtils.hasCreatePrivilege(privileges, context.availablePrivileges[0]);
    return hasCreate(context.availablePrivileges)
        ? {
              canCreate: {
                  domainSupportsCustomCreate: PrivilegeUtils.isGranularDomain(context.domain),
                  userHasCreateForDomain: hasCreate(context.possiblePrivileges),
                  createIsCurrentlyApplied: hasCreate(context.appliedPrivileges),
              },
          }
        : {};
};

const getHasChanged = (context: PrivilegesTableRowContext): {hasChanged: boolean} => {
    const hasChanged = !PrivilegeUtils.listsAreEqual(context.initialPrivileges, context.appliedPrivileges);

    return {hasChanged};
};

const reduceToPossiblePrivileges = (
    dirtyPrivileges: PrivilegeModel[],
    userPrivileges: PrivilegeModel[],
    possiblePrivileges: PrivilegeModel[],
): PrivilegeModel[] => {
    const partition = _.partition(dirtyPrivileges, PrivilegeUtils.isGranularPrivilege);
    return ArraysOfObjects.uniq([
        ...verifyGranularPrivileges(partition[0], userPrivileges),
        ...verifyGranularPrivileges(_.filter(possiblePrivileges, PrivilegeUtils.isGranularPrivilege), dirtyPrivileges),
        ...ArraysOfObjects.intersection(partition[1], possiblePrivileges),
    ]);
};

const verifyGranularPrivileges = (
    dirtyPrivileges: PrivilegeModel[],
    allowedPrivileges: PrivilegeModel[],
): PrivilegeModel[] =>
    _.reduce(
        dirtyPrivileges,
        (verifiedPrivileges: PrivilegeModel[], granularPrivilege: PrivilegeModel): PrivilegeModel[] => {
            const viewAll: PrivilegeModel = {
                ...granularPrivilege,
                targetId: PrivilegesConstants.allTargetIds,
                type: PrivilegeTypes.View,
            };
            const editAll: PrivilegeModel = {
                ...granularPrivilege,
                targetId: PrivilegesConstants.allTargetIds,
                type: PrivilegeTypes.Edit,
            };

            return PrivilegeUtils.hasPrivilege(allowedPrivileges, viewAll) &&
                PrivilegeUtils.hasSomePrivileges(allowedPrivileges, [granularPrivilege, editAll])
                ? [...verifiedPrivileges, granularPrivilege]
                : verifiedPrivileges;
        },
        [],
    );

const getSectionsFromPrivileges = (
    privilegesGroupedBySection: PrivilegesListMap,
    context: PrivilegesTableContext,
): PrivilegesTableSection[] =>
    getSortedKeys(privilegesGroupedBySection).map((section: string): PrivilegesTableSection => {
        const sectionDomains = getPrivilegeDomainsForSection(section, privilegesGroupedBySection);
        const warningsCount: number = sectionDomains.reduce(
            (currentCount: number, domain: string) => currentCount + getAccessLevelWarning(context[domain]).length,
            0,
        );
        const count: number = sectionDomains.filter(
            (domain: string) => getHasChanged(context[domain]).hasChanged,
        ).length;

        return {
            name: section,
            count,
            hasWarning: warningsCount > 0,
        };
    });

export const PrivilegesTableSelectorsUtils = {
    normalizePrivileges,
    getSortedKeys,
    computeAppliedPreset,
    getPrivilegeDomainsForSection,
    computeAppliedPrivileges,
    computeTableRows,
    reduceToPossiblePrivileges,
    getSectionsFromPrivileges,
    buildPrivilegesContext,
};
