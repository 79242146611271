import _ from 'underscore';
import * as s from 'underscore.string';

import {BrowserUtils} from './BrowserUtils';
import {ValidationError} from './ValidationError';

export class UI {
    static adminStyleSheet: HTMLStyleElement;
    static supportCssRuleManipulation: boolean;

    static Animations = {
        Bounce: 'bounce',
        Flash: 'flash',
        Pulse: 'pulse',
        RubberBand: 'rubberBand',
        Shake: 'shake',
        Swing: 'swing',
        Tada: 'tada',
        Wobble: 'wobble',
        Jello: 'jello',
        FadeIn: 'fadeIn',
        ZoomIn: 'zoomIn',
    };

    static selectText($el: JQuery) {
        const node = $el[0];
        const range = document.createRange();
        range.setStartBefore(node);
        range.setEndAfter(node);

        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
    }

    static elementIsOnRightHalfOfScreen(element: JQuery): boolean {
        const elementOffset = element.offset().left;
        const windowHalf = Math.ceil($(window).width() / 2);
        return elementOffset > windowHalf;
    }

    static elementIsOnLeftHalfOfScreen(element: JQuery): boolean {
        return !UI.elementIsOnRightHalfOfScreen(element);
    }

    static findAdminStyleSheet() {
        if (!UI.adminStyleSheet) {
            const head: HTMLHeadElement = document.head || document.getElementsByTagName('head')[0];
            const style = document.createElement('style');
            style.type = 'text/css';
            head.appendChild(style);

            UI.adminStyleSheet = style;
        }
    }

    static supportsCssRuleManipulations() {
        UI.findAdminStyleSheet();
        if (UI.supportCssRuleManipulation === undefined) {
            try {
                UI.adminStyleSheet.appendChild(document.createTextNode(''));
                UI.supportCssRuleManipulation = true;
            } catch (e) {
                UI.supportCssRuleManipulation = false;
            }
        }

        return UI.supportCssRuleManipulation;
    }

    static addCssRule(rule: string) {
        UI.findAdminStyleSheet();
        if (UI.adminStyleSheet) {
            try {
                UI.adminStyleSheet.appendChild(document.createTextNode(rule));
            } catch (e) {
                // Do nothing; only style will be missing.
            }
        }
    }

    static deleteCssRule(selectorText: string) {
        UI.findAdminStyleSheet();

        if (UI.adminStyleSheet) {
            for (let i = 0; i < UI.adminStyleSheet.childNodes.length; i++) {
                const childNode = UI.adminStyleSheet.childNodes[i];
                if (childNode.nodeType === Node.TEXT_NODE && s.include(childNode.nodeValue, selectorText)) {
                    UI.adminStyleSheet.removeChild(childNode);
                    break;
                }
            }
        }
    }

    static animate($el: JQuery, animation: string, callback = _.noop) {
        // See https://github.com/daneden/animate.css/blob/master/animate-config.json for all available animations
        $el.addClass('animated ' + animation);
        $el.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', () => {
            $el.removeClass('animated ' + animation);
            callback();
        });
    }

    static focusInputAndMoveCursorToEnd($el: JQuery) {
        // See http://davidwalsh.name/caret-end (modified for FF support)
        const el = $el.get(0);
        if (typeof el.selectionStart === 'number') {
            el.selectionStart = el.selectionEnd = el.value.length;
        } else if (typeof el.createTextRange !== 'undefined') {
            const range = el.createTextRange();
            range.collapse(false);
            range.select();
        }
        $el.focus();
    }

    static isElementInViewport(el: JQuery): boolean {
        const rect: ClientRect = el[0].getBoundingClientRect();
        return rect.top >= 0 && rect.left >= 0 && rect.bottom <= $(window).height() && rect.right <= $(window).width();
    }

    static isElementInScrollParent(child: JQuery, parent: JQuery, offset?: {top: number; left: number}): boolean {
        if (!offset) {
            offset = UI.getRelativeOffset(child, parent);
        }

        const childOffset = child.offset();
        const parentOffset = child.parent().offset();

        const isUp = offset.top - parent.scrollTop() >= 0;
        const isDown = offset.top - parent.scrollTop() + child.height() < parent.height();
        const isLeft = offset.left - parent.scrollLeft() >= 0;
        const isRight = offset.left - parent.scrollLeft() + child.width() < parent.width();

        return isUp && isDown && isLeft && isRight;
    }

    static scrollIntoView(el: JQuery, animate = false, complete?: (...args: any[]) => any, parent?, toScroll?: JQuery) {
        const parentToScroll = parent ? parent : $('html, body');
        const offset = UI.getRelativeOffset(el, parentToScroll);
        if (toScroll) {
            offset.top = toScroll.scrollTop() + el.offset().top - toScroll.offset().top;
        }
        toScroll = toScroll ? toScroll : parentToScroll;

        if ((toScroll && !UI.isElementInScrollParent(el, toScroll, offset)) || !UI.isElementInViewport(el)) {
            if (animate) {
                toScroll.stop().animate(
                    {
                        scrollTop: offset.top,
                        scrollLeft: offset.left,
                    },
                    {
                        complete: complete,
                    },
                );
            } else {
                el[0].scrollIntoView();
            }
        } else if (complete) {
            complete();
        }
    }

    static getRelativeOffset(el: JQuery, parent, offset = {top: 0, left: 0}) {
        // $('body') is always != $('body')... but get(0) return the HTMLElement that is ===
        if (!parent.first().is(el.parent().get(0)) && !el.parent().first().is($('body').get(0))) {
            const childOffset = el.offset();
            const parentOffset = el.parent().offset();

            offset.top += childOffset.top - parentOffset.top;
            offset.left += childOffset.left - parentOffset.left;

            return UI.getRelativeOffset(el.parent(), parent, offset);
        } else {
            return offset;
        }
    }

    static showFormValidationErrors(errors: ValidationError[], adminSettingTable: JQuery) {
        _.each(errors, (error: ValidationError) => {
            const erroneousField: JQuery = adminSettingTable
                .find('input[name="' + error.attributeName + '"]')
                .closest('tr');
            erroneousField.addClass('input-validation-error');
            erroneousField.find('.input-validation-error-message').text(error.message);
        });
    }

    static showValidationErrorsInCoveoTableForm(errors: ValidationError[], coveoForm: JQuery) {
        _.each(errors, (error: ValidationError) => {
            let erroneousField: JQuery;

            if (!_.isUndefined(error.attributeName)) {
                erroneousField = coveoForm.find('input[name="' + error.attributeName + '"]').closest('div.form-group');
            } else if (!_.isUndefined(error.jQuerySelector)) {
                erroneousField = coveoForm.find(error.jQuerySelector).closest('div.form-group');
            }

            if (!_.isUndefined(erroneousField)) {
                if (
                    !_.isUndefined(error.isMultilineInput) &&
                    error.isMultilineInput &&
                    !_.isUndefined(error.jQuerySelector)
                ) {
                    erroneousField.addClass('multiline-input-validation-error');
                    coveoForm.find(error.jQuerySelector).addClass('erroneous-input');
                } else {
                    erroneousField.addClass('input-validation-error');
                }

                erroneousField.find('.input-validation-error-message').text(error.message);

                this.showHiddenJsAlertError(erroneousField, '.panel', '.panel-heading a');
            }
        });
    }

    static showHiddenJsAlertError(
        erroneousField: JQuery,
        collapsibleSectionSelector: string,
        collapsibleHeaderSelector: string,
    ) {
        const collapsibleSectionElement = erroneousField.closest(collapsibleSectionSelector);
        collapsibleSectionElement.addClass('input-validation-error');
        if (collapsibleSectionElement.length > 0) {
            const collapseHeaderElement = collapsibleSectionElement.find(collapsibleHeaderSelector);
            if (collapseHeaderElement.length > 0) {
                const warningSVG = collapseHeaderElement.find('svg');
                warningSVG.addClass('coveo-icon-docs icon mod-error mr1 mod-14');
                collapseHeaderElement.addClass('text mod-error');
                collapseHeaderElement.children('.js-alert-error').removeClass('hidden');
            }
        }
    }

    static hideJsAlertError(
        inputBloc: JQuery,
        collapseSectionElementSelectior: string,
        collapsibleHeaderElementSelector: string,
        event?: JQuery.TriggeredEvent,
    ) {
        inputBloc.removeClass('input-validation-error');
        const collapseSectionElement = $(event.currentTarget).closest(collapseSectionElementSelectior);
        if (collapseSectionElement.length > 0) {
            if (collapseSectionElement.find('.input-validation-error').length === 0) {
                const collapsibleHeaderElement = collapseSectionElement.find(collapsibleHeaderElementSelector);
                if (collapsibleHeaderElement.length > 0) {
                    collapsibleHeaderElement.removeClass('text mod-error');
                    collapsibleHeaderElement.children('.js-alert-error').toggleClass('hidden');
                }
            }
        }
    }

    static clearValidationErrorsInCoveoTableForm(event: JQuery.TriggeredEvent) {
        const inputBloc: JQuery = $(event.currentTarget).closest('.input-validation-error');
        if (inputBloc.length > 0) {
            this.hideJsAlertError(inputBloc, '.panel', '.panel-heading a', event);
        } else {
            const multilineBlock: JQuery = $(event.currentTarget).closest('.multiline-input-validation-error');
            if (multilineBlock.length > 0) {
                let erroneousInput: JQuery;
                if (_.contains($('button.btn-link.admin-delete'), event.currentTarget)) {
                    erroneousInput = $(event.currentTarget).parent().parent().find('.erroneous-input');
                } else {
                    erroneousInput = $(event.currentTarget).closest('.erroneous-input');
                }
                if (erroneousInput.length > 0) {
                    erroneousInput.removeClass('erroneous-input');
                }
                if (multilineBlock.find('.erroneous-input').length === 0) {
                    multilineBlock.removeClass('multiline-input-validation-error');
                }
            }
        }
    }

    static clearAllValidationErrorsInCoveoTableForm(coveoForm: JQuery) {
        const errors = coveoForm.find('.input-validation-error');
        _.each(errors, (error) => {
            $(error).removeClass('input-validation-error');
        });
    }

    static showValidationErrorsInCoveoForm(errors: ValidationError[], coveoForm: JQuery) {
        _.each(errors, (error: ValidationError) => {
            let inputInError: JQuery;
            const selector = !!error.isMultilineInput ? '.input-wrapper' : '.form-group';

            if (!_.isUndefined(error.attributeName)) {
                inputInError = coveoForm.find('input[name="' + error.attributeName + '"]');

                /**
                 * TODO instead of using attributeName, we should use jQuerySelector
                 *
                 * Putting the generic-form-error span in the inputInError object is breaking the initial method behavior. For example,
                 * we currently set the invalid class on the generic-form-error span, which is useless.
                 *
                 * The real way to make it work here would be to use the jQuerySelector (or jQueryElement) to target the input, textarea,
                 * checkbox or dropdown/widget you want to validate, then let the magic happen, a.k.a. find the closest form-group and IN
                 * that form-group, try to find the generic-form-error span.
                 *
                 * I did that! Now simply stop using attributeName to find the generic-form-error span!
                 *
                 * The attributeName should ONLY be used for input elements. Otherwise, use jQuerySelector or jQueryElement.
                 */
                if (!inputInError.length) {
                    inputInError = coveoForm.find('.generic-form-error[name="' + error.attributeName + '"]');
                    if (inputInError.length) {
                        inputInError.text(error.message);
                    }
                }
            } else if (!_.isUndefined(error.jQuerySelector)) {
                inputInError = coveoForm.find(error.jQuerySelector);
            } else if (error.jQueryElement) {
                inputInError = error.jQueryElement;
            }
            if (!_.isUndefined(inputInError)) {
                const formGroup = inputInError.closest(selector);

                inputInError.toggleClass('invalid', true);
                UI.forceEdgeRendering(inputInError.next('label'));

                formGroup.find('label').each((index, labelElement) => {
                    if (!_.isEmpty(error.message)) {
                        $(labelElement).attr('data-invalid-message', error.message);
                    }
                });
                formGroup.find('.generic-form-error').text(error.message);

                this.showHiddenJsAlertError(formGroup, 'li', '.collapsible-header');
            }
        });
    }

    static clearValidationErrorInCoveoForm(event?: JQuery.TriggeredEvent, $inputInError?: JQuery) {
        if (event) {
            const $input = $(event.currentTarget).closest('.invalid');
            $input.removeClass('invalid');
            UI.forceEdgeRendering($input.next('label'));
            $(event.currentTarget).closest('.generic-form-error').text('');
            const inputBloc: JQuery = $(event.currentTarget).closest('.input-validation-error');
            if (inputBloc.length > 0) {
                this.hideJsAlertError(inputBloc, 'li', '.collapsible-header', event);
            }
        }
        if ($inputInError) {
            $inputInError.find('.generic-form-error').text('');
            const $input = $inputInError.closest('.form-group').find('.invalid');
            $input.removeClass('invalid');
            UI.forceEdgeRendering($input.next('label'));
        }
    }

    static forceEdgeRendering(elementToRender: JQuery<HTMLElement>) {
        if (BrowserUtils.isEdge) {
            // Add/remove a class on the label to force rendering in edge UA-2672
            elementToRender.addClass('forceEdgeRendering').removeClass('forceEdgeRendering');
        }
    }

    static clearAllValidationErrorInCoveoForm(coveoForm: JQuery | JQuery<Element>) {
        coveoForm.find('.invalid').removeClass('invalid');
        coveoForm.find('.generic-form-error').text('');
    }

    static toggleFullscreen(state: boolean) {
        const adminInterface = document.querySelector('.CoveoAdminInterface');
        const hamburger = document.querySelector('.header-hamburger');
        const navigationWrapper = document.querySelector('.navigation-wrapper');

        if (adminInterface) {
            adminInterface.classList.toggle('mod-fullscreen', state);
        }
        if (hamburger) {
            hamburger.classList.toggle('hidden', state);
        }
        if (navigationWrapper) {
            navigationWrapper.classList.toggle('navigation-wrapper-opened', !state);
        }
    }

    static tryCopyTextToClipboard(text: string): boolean {
        const $el = $('body')
            .append($('<textarea type="text" name="fname" class="textToCopyInput" />').text(text))
            .find('.textToCopyInput');

        const success = UI.tryCopyElementContentToClipboard($el);
        $el.remove();
        return success;
    }

    static tryCopyElementContentToClipboard($el: JQuery): boolean {
        const el = $el.get()[0] as HTMLInputElement;
        let successful = false;
        if (el) {
            el.select();
            try {
                successful = document.execCommand('copy');
            } catch (err) {
                _.noop();
            }
        }
        return successful;
    }

    static clearTextSelection() {
        if (window.getSelection) {
            if (window.getSelection().empty) {
                window.getSelection().empty();
            } else if (window.getSelection().removeAllRanges) {
                window.getSelection().removeAllRanges();
            }
        }
    }

    static removeFromDom = (el: HTMLElement): void => {
        if (el && el.parentNode) {
            el.parentNode.removeChild(el);
        }
    };

    static clearDomNode(nodeId: string) {
        // The while loop is much faster than setting innerHtml to ''
        // See https://jsperf.com/innerhtml-vs-removechild/15

        const rootNode = document.getElementById(nodeId);
        while (rootNode && rootNode.lastChild) {
            rootNode.removeChild(rootNode.lastChild);
        }
    }
}
