import {Box, BoxProps, Grid} from '@components/mantine';
import {FunctionComponent, ReactNode} from 'react';

import {SplitLayoutLeft} from './SplitLayoutLeft';
import {SplitLayoutRight} from './SplitLayoutRight';
import classes from './SplitLayout.module.css';

export interface SplitLayoutProps extends BoxProps {
    children: ReactNode;
}

type SplitLayoutComponent = FunctionComponent<SplitLayoutProps> & {
    Left: typeof SplitLayoutLeft;
    Right: typeof SplitLayoutRight;
};

export const SplitLayout: SplitLayoutComponent = ({children, ...props}) => (
    <Box display="flex" flex="1" {...props}>
        <Grid classNames={{inner: classes.inner}} gutter={0} w="100%">
            {children}
        </Grid>
    </Box>
);
SplitLayout.Left = SplitLayoutLeft;
SplitLayout.Right = SplitLayoutRight;
SplitLayout.displayName = '@components/page/layouts/SplitLayout';
