import {DateUtils, formatBigNumber} from '@coveord/jsadmin-common';
import d3 from 'd3';
import deepExtend from 'lodash.merge';
import moment from 'moment-timezone';
import _ from 'underscore';

import {UALocales} from '../UALocales';
import {BaseChart} from './BaseChart';

export const getDateChartColorPattern = (numOfColors: number = 0) => {
    const colorPattern: string[] = [
        'var(--deprecated-azure)',
        'var(--deprecated-turquoise)',
        'var(--deprecated-anakiwa)',
        'var(--deprecated-nepal)',
    ];
    return numOfColors > 2 ? ['var(--deprecated-blue-8)', ...colorPattern] : colorPattern;
};

export const Intervals = {
    Auto: 'AUTO',
    Minute: 'MINUTE',
    Hour: 'HOUR',
    Day: 'DAY',
    Week: 'WEEK',
    Month: 'MONTH',
};

export const IntervalMaxMinutes = {
    Minute: 60,
    Hour: 60 * 60, // 60 mins - 60 hours
    Day: 60 * 24 * 60, // 60 hours - 60 days
    Week: 180 * 24 * 60, // 60 days - 180 days
};

export const C3IntervalFormat = {
    MINUTE: '%Hh%M, %b %e',
    HOUR: '%Hh, %b %e',
    DAY: '%b %e',
    WEEK: '%b %e',
    MONTH: '%b %Y',
};

export const MomentIntervalFormat = {
    MINUTE: 'H[h]mm MMM D, YYYY',
    HOUR: 'H[h] MMM D, YYYY',
    DAY: 'MMM D, YYYY',
    WEEK: 'Wo [week] MMM, YYYY',
    MONTH: 'MMMM YYYY',
};

export const DateChartType = {
    AreaSpline: 'area-spline',
    Line: 'line',
    Bar: 'bar',
};

export interface DisplayOptions {
    interval?: string;
    dataDisplay?: string;
}

export class DateChart extends BaseChart {
    interval: string;
    dataDisplay: string;

    static XAxisLabelWidth = 70;
    static XAxisLabelPadding = 20;

    constructor(cssSelector: string, data: any, options?, displayOptions: DisplayOptions = {}) {
        super(cssSelector, data);
        _.defaults(displayOptions, {
            interval: Intervals.Day,
            dataDisplay: DateChartType.Line,
        });
        this.interval = displayOptions.interval;
        this.dataDisplay = displayOptions.dataDisplay;
        let settings = {};
        if (this.settings.data.columns && this.settings.data.columns[0]) {
            const axes = _.chain(this.settings.data.columns)
                .filter((column: any[]) => column[0] !== 'x')
                .reduce((memo: {[key: string]: string}, column: any[]) => {
                    memo[column[0]] = 'y2';
                    return memo;
                }, {})
                .value();
            const paddingLeft = 10;
            settings = {
                data: {
                    type: this.dataDisplay,
                    axes,
                },
                point: {
                    show: false,
                },
                color: {
                    // data.columns always contains the "x" column which we have to ignore for the number of colors
                    pattern: getDateChartColorPattern(this.data.columns && this.data.columns.length - 1),
                },
                axis: {
                    x: {
                        type: 'timeseries',
                        localtime: false,
                        tick: {
                            fit: true,
                            outer: false,
                            format: (x: Date) => this.formatDate(x),
                        },
                    },
                    y: {
                        show: false,
                    },
                    y2: {
                        show: true,
                        tick: {
                            format: (y) => (y >= 0 ? formatBigNumber(y) : ''),
                            fit: true,
                            outer: true,
                        },
                        min: 0,
                    },
                },
                tooltip: {
                    format: {
                        title: (date: Date) =>
                            this.isDateIncomplete(date)
                                ? `${this.formatDate(date, true)} (${UALocales.format('Chart.DateChart.incomplete')})`
                                : this.formatDate(date, true),
                        value: (value, ratio, id) => formatBigNumber(value),
                    },
                    position: (d: any[], width: number, height: number, element: HTMLElement) => {
                        const c3Internal: any = this.chart.internal;
                        const c3TooltipOffset = 20; // From c3_chart_internal_fn.tooltipPosition in c3.js

                        const half = this.chart.element.getBoundingClientRect().width / 2;
                        const pos = c3Internal.tooltipPosition(d, width, height, element);
                        const pointX = c3Internal.x(d[0].x);
                        const offset = c3Internal.getCurrentPaddingLeft(true);

                        const newLeft = pointX > half ? pointX + offset - width - c3TooltipOffset : pos.left;

                        return {
                            top: pos.top,
                            left: Math.max(newLeft, paddingLeft),
                        };
                    },
                },
                grid: {
                    y: {
                        show: true,
                    },
                },
                padding: {
                    left: paddingLeft,
                },
                spline: {
                    interpolation: {type: 'monotone'},
                },
            };
        }
        deepExtend(this.settings, settings);

        if (options) {
            deepExtend(this.settings, options);
        }
    }

    private isDateIncomplete(date: Date) {
        const interval = this.interval.toLowerCase() as moment.unitOfTime.Diff;
        return moment().isSameOrBefore(date, interval);
    }

    private formatDate(x: Date, full = false) {
        const intervalFormat = C3IntervalFormat[this.interval] || C3IntervalFormat.DAY;
        const format = (d: Date) => d3.time.format(intervalFormat)(DateUtils.dateToBrowserTimezone(d));
        let formatted = format(x);
        if (full && this.interval === Intervals.Week) {
            const end = moment(x).endOf('week').toDate();
            formatted += ' - ' + format(end);
        }
        return formatted;
    }
}
