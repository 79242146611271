import {formatNumber} from '@coveord/jsadmin-common';
import {Localization} from '@core/locales';
import {GetLogsOrFacetsRequestBodyModel} from '@core/api';
import {IFacet, IFacetState} from '@coveord/plasma-react';
import _ from 'underscore';
import * as s from 'underscore.string';

import {ILogsFacets, LogFacetsStatsRow, LogsFacetTypes} from '../facets/LogsFacetsConstants';
import {locales} from '../Locales';
import {ISourcesState} from '../sources/LogsSourcesReducers';

const extractSelectedRowsFromFacetsState = (facets: IFacetState[]): ILogsFacets =>
    _.reduce<IFacetState, ILogsFacets>(
        facets,
        (selectedState: ILogsFacets, facet: IFacetState) => ({...selectedState, [facet.facet]: facet.selected}),
        {},
    );

const formatFacetFromAPI = (facet: LogFacetsStatsRow): IFacet[] =>
    _.map(facet, (count: number, name: string) => ({name, count: formatNumber(count), formattedName: ''}));

const formatRowsForAPI = (facets: IFacetState[]): GetLogsOrFacetsRequestBodyModel => {
    const extracted = extractSelectedRowsFromFacetsState(facets);
    return _.mapObject(
        extractExcludeFacetsForAPI(extracted),
        (row: IFacet[]) => _.map(row, ({name}: IFacet) => name) as any,
    );
};

const extractExcludeFacetsForAPI = (facets: ILogsFacets): ILogsFacets =>
    _.reduce<string, ILogsFacets>(
        _.keys(facets),
        (old: ILogsFacets, key: string) => ({...old, ...splitExcludeAndNotExcludeRows(key, facets[key])}),
        {},
    );

const splitExcludeAndNotExcludeRows = (key: string, facets: IFacet[]): ILogsFacets =>
    _.reduce<IFacet, ILogsFacets>(
        facets,
        (old: ILogsFacets, facet: IFacet) => {
            const index: string = formatIndexIfFacetIsExclude(key, facet.exclude);

            return {
                ...old,
                [index]: [...(old[index] || []), facet],
            };
        },
        {},
    );

const formatIndexIfFacetIsExclude = (key: string, exclude: boolean): string => {
    const formattedKey: string = exclude ? `exclude${s.capitalize(key)}` : key;
    return key === LogsFacetTypes.sourcesIds && exclude ? 'excludeSourceIds' : formattedKey;
};

export interface FacetRowInformation {
    name: string;
    needsFormat: boolean;
    sources: ISourcesState;
    count?: string;
    formattedName?: string;
    tooltipLabel?: string;
}

const formatFacetRow = (facetRowInformation: FacetRowInformation): IFacet => ({
    name: facetRowInformation.name,
    count: facetRowInformation.count || '',
    formattedName: facetRowInformation.needsFormat
        ? facetRowInformation.formattedName ||
          Localization.convertToSentenceCase(locales.formatOrHumanize(facetRowInformation.name))
        : facetRowInformation.sources[facetRowInformation.name] || facetRowInformation.name,
    ...(!!facetRowInformation.tooltipLabel && {tooltipLabel: facetRowInformation.tooltipLabel}),
});

export const FacetRowFormatter = {
    extractSelectedRowsFromFacetsState,
    formatFacetFromAPI,
    formatRowsForAPI,
    formatFacetRow,
};
