import {ProjectModel, ProjectResourceType} from '@core/api';

import {ProjectLocalStorage} from '../storage';
import {ProjectState} from '../store';

const getSelectedProject = (state: ProjectState): ProjectModel | null => state.project ?? null;

const getSelectedProjectId = (state: ProjectState): string | null =>
    getSelectedProject(state)?.id || ProjectLocalStorage.getProjectId();

const isResourceTypeAssociated = (state: ProjectState, resourceType: ProjectResourceType): boolean => {
    const selectedProject = getSelectedProject(state);
    const associatedResources = selectedProject?.resources?.[resourceType] ?? [];

    return associatedResources.length > 0;
};

export const ProjectSelectors = {
    getSelectedProject,
    getSelectedProjectId,
    isResourceTypeAssociated,
};
