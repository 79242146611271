import {CurrentOrganization} from '@core/organization';
import * as s from 'underscore.string';
import URI from 'urijs';
import validator from 'validator';
import {sanitizeOrganizationId} from '@core/configuration';

import {Strings} from './StringsUtils';

export interface NavigateOptions extends Backbone.NavigateOptions {}

export class URL {
    static isValidUrl(url: string): boolean {
        return validator.isURL(url);
    }

    static normalizeUrl(value: string) {
        if (value && value.length > 0 && s.trim(value).length > 0) {
            if (s.include(value, '://')) {
                const valueWithoutProtocol = value.split('://')[1];
                const lastPart = s.strRight(valueWithoutProtocol, '/');
                if (s.include(lastPart, '?')) {
                    return value;
                }
                if (!s.include(lastPart, '.') || !s.include(valueWithoutProtocol, '/')) {
                    return Strings.appendIfNotPresent(value, '/');
                }
            } else {
                return URL.normalizeUrl('http://' + value);
            }
        }
        return value;
    }

    static normalizeYoutubeUrl(value: string) {
        if (value && value.length && s.trim(value).length) {
            if (!s.include(value, '://')) {
                return 'http://' + value;
            }
            return value;
        }
    }

    static removeQueryString(url): string {
        if (s.contains(url, '?')) {
            url = url.split('?')[0];
        }
        return url;
    }
}

export class URIUtils {
    static getCurrentRoute(): string {
        return window.location.hash.replace(/^#/, '');
    }

    static getQueryParams(hash?: string): {[key: string]: any} {
        const uri = new URI(hash || this.getCurrentRoute());
        const obj = uri.query(true);
        return obj;
    }

    static getParamFromUrl(param: string) {
        const url = new URLSearchParams(window.location.search);
        return url.get(param);
    }

    static getUrlHash(): string {
        return URIUtils.getCurrentRoute().split('?')[0];
    }

    static getCleanHash(): string {
        const organization = sanitizeOrganizationId(CurrentOrganization.getId());
        return URIUtils.getUrlHash().replace(new RegExp(`^${organization}`), '');
    }

    static getUrlQuerystring() {
        const hash = URIUtils.getCurrentRoute().split('?');
        if (hash.length) {
            return hash.splice(1).join('?');
        }
        return '';
    }

    static navigateExternalURI(uri: string, openInCurrentTab = false) {
        if (uri) {
            window.open(uri, openInCurrentTab ? '_self' : '_blank');
        }
    }

    private static normalizeFragment(fragment: string): string {
        const organization = sanitizeOrganizationId(CurrentOrganization.getId());
        const target = fragment.replace(new RegExp(`^#?/?(?:${organization})?/?`), '');
        return organization ? `/${sanitizeOrganizationId(organization)}/${target}` : `/${target}`;
    }

    /**
     * @deprecated in React components you should use `useNavigate` or `<Navigate to="..." />` from `@core/routes` instead
     *
     * @param fragment
     * @param options
     */
    static navigate(fragment: string, options: NavigateOptions = {trigger: true}) {
        const link = URIUtils.normalizeFragment(fragment);
        if (options.trigger) {
            window.location.hash = `#${link}`;
        } else if (options.replace) {
            window.history.replaceState({}, '', `#${link}`);
        } else {
            window.history.pushState({}, '', `#${link}`);
        }
    }

    /**
     * @deprecated use "<Link to={...} />" or if in a polymorphic Mantine component "component={Link} to={...}"
     * instead of "href={URIUtils.linkToRoute(...)}"
     *
     * @param fragment
     */
    static linkToRoute(fragment: string) {
        return `#${URIUtils.normalizeFragment(fragment)}`;
    }
}
