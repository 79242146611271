import {track} from '@core/tracking';

const trackSwitchProjectFilter = (projectId: string): void => {
    track(
        {action: 'switched', subject: 'project filter'},
        {
            projectId,
        },
    );
};

const trackClearProjectFilter = (projectId?: string): void => {
    track(
        {action: 'cleared', subject: 'project filter'},
        {
            projectId,
        },
    );
};

const trackLoadProjectFilter = (projectId: string): void => {
    track(
        {action: 'loaded', subject: 'project filter'},
        {
            projectId,
        },
    );
};

export const FilterTracking = {trackSwitchProjectFilter, trackClearProjectFilter, trackLoadProjectFilter};
