import {setKeyValue} from '@core/store';
import {generateFetchQueryKey, RequestsActions} from '@coveord/jsadmin-common';
import {IThunkAction} from '@coveord/plasma-react';
import {Platform, CatalogMetadataName} from '@core/api';
import {CommerceState} from '../../CommerceState';
import {CatalogConfigurationSelectors} from '../CatalogConfiguration/CatalogConfigurationSelectors';
import {SourcesSelectors} from '../SourceSelect/SourcesSelectors';

export const StandardFieldsActionsTypes = {
    updateStandardFields: 'UPDATE_STANDARD_FIELDS_SUGGESTIONS',
    fetchFieldsSuggestions: 'FETCH_FIELDS_SUGGESTIONS',
};

const fetchFields =
    ({
        productObjectType,
        variantObjectType,
        availabilityObjectType,
        selectedSourceId,
    }: {
        productObjectType: string;
        selectedSourceId: string;
        variantObjectType?: string;
        availabilityObjectType?: string;
    }): IThunkAction<void, CommerceState> =>
    async (dispatch, getState) => {
        const state = getState();

        const sourceIds = CatalogConfigurationSelectors.getSelectedOrSavedCatalogProductSource(state);
        const sourceIdsMap = SourcesSelectors.getCatalogEnabledSourceIdToNameMap(state);
        const sourceNames = sourceIds.filter((value) => !!value).map((sourceId) => sourceIdsMap[sourceId]);

        const requestKey = generateFetchQueryKey(StandardFieldsActionsTypes.fetchFieldsSuggestions);
        dispatch(RequestsActions.request(requestKey));

        if (sourceNames.length === 0) {
            dispatch(setKeyValue(StandardFieldsActionsTypes.updateStandardFields, []));
            dispatch(RequestsActions.success(requestKey));
            return;
        }

        try {
            if (productObjectType && selectedSourceId) {
                const data: CatalogMetadataName = await Platform.catalogContent.getMetadata(selectedSourceId, {
                    objectType: productObjectType,
                });
                const metadata = data.metadataNames.map((name) => ({
                    name: name.toLowerCase(),
                    examples: [],
                    objectTypes: [productObjectType],
                }));
                dispatch(setKeyValue(StandardFieldsActionsTypes.updateStandardFields, metadata));
            }

            dispatch(RequestsActions.success(requestKey));
        } catch (e) {
            dispatch(RequestsActions.failure(requestKey));
        }
    };

export const StandardFieldsActions = {
    fetchFields,
};
