export const ABTestMetricName = {
    AvgClickRank: 'AvgClickRank',
    AvgNumberOfResults: 'AvgNumberOfResults',
    NoResults: 'NoResults',
    NoResultsRatio: 'NoResultsRatio',
    SearchRatio: 'SearchRatio',
    WithResults: 'WithResults',
};
export type ABTestMetric = (typeof ABTestMetricName)[keyof typeof ABTestMetricName];

export interface ABTestComparison {
    pipelineA: number | null;
    pipelineB: number | null;
    bestScenario?: string;
}

export type ABTestPipelinesComparisonMetrics = Record<ABTestMetric, ABTestComparison>;

export type ABTestPipelinesComparison = {
    bestScenario: string;
    metrics: ABTestPipelinesComparisonMetrics;
};
