import {Guard, GuardProps} from '@components/security';
import {OwnedProps, useOwnership} from '@core/debug';
import {FunctionComponent, ReactNode} from 'react';

export interface TabsLayoutPanelProps extends Partial<GuardProps>, Partial<OwnedProps> {
    /**
     * URL identifier of the tab panel. It will be used as segment to navigate to it.
     * Must be unique among the tab panels of the same TabsLayout.
     */
    path: string;
    /**
     * Label of the tab panel. It will be used as the text of the tab button.
     */
    label: ReactNode;
    /**
     * Content of the tab panel. It will be rendered when the tab is active.
     */
    children: ReactNode;
    /**
     * If true, this tab panel will be the active one when the tab layout is rendered without a specific tab path.
     * If not provided, the first tab panel will be the default one.
     * If multiple tab panels have the default prop set to true, the first one will be the default one.
     */
    default?: boolean;
}

export const TabsLayoutPanel: FunctionComponent<TabsLayoutPanelProps> = ({
    canEdit,
    canRender,
    children,
    fallback,
    owner,
    framework,
    invisible,
}) => {
    useOwnership(owner, framework, invisible);
    return (
        <Guard canRender={canRender ?? true} canEdit={canEdit} fallback={fallback}>
            {children}
        </Guard>
    );
};
