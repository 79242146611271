import {Config} from '@core/configuration';
import {Options, PickySelectable, SelectOption, Strings, ValidationError} from '@coveord/jsadmin-common';
import {FieldTypes} from '@core/api';
import _ from 'underscore';

import {Locales} from '../Locales';
import {FieldCollection} from './FieldCollection';
import {DEFAULT_MULTI_VALUE_FACET_TOKENIZERS} from './FieldsConstants';

export const FieldTypesSelectOptions: SelectOption[] = [
    {
        value: FieldTypes.STRING,
        label: Locales.format(FieldTypes.STRING),
        otherProperty: {property: 'data-nightwatch', value: FieldTypes.STRING},
    },
    {
        value: FieldTypes.LONG,
        label: Locales.format(FieldTypes.LONG),
        otherProperty: {property: 'data-nightwatch', value: FieldTypes.LONG},
    },
    {
        value: FieldTypes.LONG_64,
        label: Locales.format(FieldTypes.LONG_64),
        otherProperty: {property: 'data-nightwatch', value: FieldTypes.LONG_64},
    },
    {
        value: FieldTypes.DOUBLE,
        label: Locales.format(FieldTypes.DOUBLE),
        otherProperty: {property: 'data-nightwatch', value: FieldTypes.DOUBLE},
    },
    {
        value: FieldTypes.DATE,
        label: Locales.format(FieldTypes.DATE),
        otherProperty: {property: 'data-nightwatch', value: FieldTypes.DATE},
    },
];

export interface SourcesUsingField {
    id: string;
    name: string;
}

export interface FieldModelAttributes {
    name: string;
    type: string;
    description: string;
    system: boolean;

    // Always true for non-string fields
    sort: boolean;
    facet: boolean;

    // For string fields only
    // Always false for non-string fields
    multiValueFacet: boolean;
    multiValueFacetTokenizers?: string;
    mergeWithLexicon: boolean;
    ranking: boolean;
    stemming: boolean;
    sources: SourcesUsingField[];

    // Optimization settings for all fields
    // TODO editable by Coveo admin's only
    includeInQuery: boolean;
    includeInResults: boolean;
    useCacheForComputedFacet: boolean;
    useCacheForNestedQuery: boolean;
    useCacheForSort: boolean;

    // Optimization settings for non-string fields only
    // TODO editable by Coveo admin's only
    useCacheForNumericQuery: boolean;
}

export interface FieldModelOptions {
    newlyCreated: boolean;
}

export class FieldModel extends PickySelectable {
    static urlRoot = `${Config.CloudPlatform.url}/rest/organizations/{organizationName}/sources/fields`;

    collection: FieldCollection;
    fieldTypesOptions: Options;

    private newlyCreated: boolean;

    get facet(): boolean {
        return this.get('facet');
    }

    get mappings(): any[] {
        return this.get('mappings');
    }

    get mergeWithLexicon(): boolean {
        return this.get('mergeWithLexicon');
    }

    get multiValueFacet(): boolean {
        return this.get('multiValueFacet');
    }

    get multiValueFacetTokenizers(): string {
        return this.get('multiValueFacetTokenizers');
    }

    get name(): string {
        return this.get('name');
    }

    get ranking(): boolean {
        return this.get('ranking');
    }

    get sort(): boolean {
        return this.get('sort');
    }

    get stemming(): boolean {
        return this.get('stemming');
    }

    get system(): boolean {
        return this.get('system');
    }

    get type(): string {
        return this.get('type');
    }

    get useCacheForNumericQuery(): string {
        return this.get('useCacheForNumericQuery');
    }

    get useCacheForSort(): string {
        return this.get('useCacheForSort');
    }

    get sources(): SourcesUsingField[] {
        return this.get('sources');
    }

    defaults(): FieldModelAttributes {
        return {
            name: '',
            type: FieldTypes.STRING,
            description: '',
            system: false,

            facet: false,
            multiValueFacet: false,
            multiValueFacetTokenizers: DEFAULT_MULTI_VALUE_FACET_TOKENIZERS,
            mergeWithLexicon: false,
            ranking: false,
            sort: false,
            stemming: false,
            sources: [],

            includeInQuery: true,
            includeInResults: true,
            useCacheForComputedFacet: false,
            useCacheForNestedQuery: false,
            useCacheForSort: false,

            useCacheForNumericQuery: false,
        };
    }

    constructor(attributes?, options?: FieldModelOptions) {
        super(attributes, options);

        this.fieldTypesOptions = new Options();
        this.fieldTypesOptions.setSelectOptions(FieldTypesSelectOptions);
        this.fieldTypesOptions.pickySelectFromValueOrFirst(this.type);

        // Since the idAttribute is the name, this is the only way to really know if a model is new or not.
        this.newlyCreated = options && options.newlyCreated;

        this.urlRoot = FieldModel.urlRoot;

        this.idAttribute = 'name';
    }

    isNew(): boolean {
        return _.isEmpty(this.name) || this.newlyCreated;
    }

    save(attributes?: FieldModelAttributes, options: Backbone.ModelSaveOptions = {}) {
        if (attributes && !this.isNew()) {
            options.url = `${this.urlRoot}/${attributes.name}`;
        }

        return super.save(attributes, options).done(() => (this.newlyCreated = false));
    }

    validate(attributes: FieldModelAttributes): ValidationError[] {
        const errors: ValidationError[] = [];

        if (!FieldModel.isNameValid(attributes.name)) {
            errors.push({
                attributeName: 'name',
                message: Locales.format('fieldNameCannotContainSpecialChars'),
            });
        }

        if (!this.isNew()) {
            if (attributes.name !== this.name) {
                errors.push({
                    attributeName: 'name',
                    message: Locales.format('fieldNameCannotBeChanged'),
                });
            }

            if (attributes.type !== this.type) {
                errors.push({
                    attributeName: 'type',
                    message: Locales.format('fieldTypeCannotBeChanged'),
                });
            }
        }

        if (!attributes.includeInQuery && !attributes.includeInResults) {
            errors.push({
                attributeName: 'includeInQuery',
                message: Locales.format('fieldIncludeInQueryAndIncludeInResultsCannotBothBeUnselected'),
            });
        }

        if (attributes.useCacheForSort && !attributes.sort) {
            errors.push({
                attributeName: 'useCacheForSort',
                message: Locales.format('fieldUseCacheForSortRequireSort'),
            });
        }

        if (attributes.type === FieldTypes.STRING) {
            if (attributes.facet && attributes.multiValueFacet) {
                errors.push({
                    attributeName: 'facet',
                    message: Locales.format('fieldFacetAndMultiValueFacetMutuallyExclusive'),
                });
            }

            if (Strings.isEmpty(attributes.multiValueFacetTokenizers)) {
                errors.push({
                    attributeName: 'multiValueFacetTokenizers',
                    message: Locales.format('fieldMultiValueFacetTokenizersCannotBeEmpty'),
                });
            }

            if (attributes.useCacheForComputedFacet) {
                errors.push({
                    attributeName: 'useCacheForComputedFacet',
                    message: Locales.format('fieldCannotUseCacheForComputedFacetOnString'),
                });
            }

            if (attributes.useCacheForNumericQuery) {
                errors.push({
                    attributeName: 'useCacheForNumericQuery',
                    message: Locales.format('fieldCannotUseCacheForNumericQueryOnString'),
                });
            }

            if (attributes.useCacheForNestedQuery && !(attributes.facet || attributes.multiValueFacet)) {
                errors.push({
                    attributeName: 'useCacheForNestedQuery',
                    message: Locales.format('fieldOnlyNumericOrFacetCanUseCacheForNestedQuery'),
                });
            }
        } else {
            if (attributes.multiValueFacet) {
                errors.push({
                    attributeName: 'multiValueFacet',
                    message: Locales.format('fieldCannotUseMultiValueFacetForNonStringFields'),
                });
            }

            if (attributes.mergeWithLexicon) {
                errors.push({
                    attributeName: 'mergeWithLexicon',
                    message: Locales.format('fieldCannotUseMergeWithLexiconForNonStringFields'),
                });
            }

            if (attributes.ranking) {
                errors.push({
                    attributeName: 'ranking',
                    message: Locales.format('fieldCannotUseRankingForNonStringFields'),
                });
            }

            if (attributes.stemming) {
                errors.push({
                    attributeName: 'stemming',
                    message: Locales.format('fieldCannotUseStemmingForNonStringFields'),
                });
            }

            if (this.isNew()) {
                if (!attributes.facet) {
                    errors.push({
                        attributeName: 'facet',
                        message: Locales.format('facetTrueForNonStringFields'),
                    });
                }

                if (!attributes.sort) {
                    errors.push({
                        attributeName: 'sort',
                        message: Locales.format('sortTrueForNonStringFields'),
                    });
                }
            }
        }

        return errors.length ? errors : null;
    }

    static isNameValid(fieldName: string): boolean {
        const allowedChar = new RegExp('^[a-z]+[a-z0-9_]*$');
        return allowedChar.test(fieldName);
    }
}
