import {InputValidatorResponse} from '@coveord/jsadmin-common';
import isEmail from 'validator/lib/isEmail';
import isFQDN from 'validator/lib/isFQDN';

import {Locales} from '../../Locales';

const isGroup = (group: string) => {
    if (!group.includes('@')) {
        return false;
    }

    if (group === '*@*') {
        return true;
    }

    const [user, domain] = group.split('@');
    if (user === '') {
        return false;
    }

    if (user === '*') {
        return isFQDN(domain, {allow_wildcard: true});
    }
    if (domain.startsWith('*.')) {
        return isEmail(`${user}@${domain.substring(2)}`);
    }

    return isEmail(group);
};

type InputValidatorWithOptions = (value: string) => {
    status: 'valid' | 'invalid' | 'warning';
    message?: string;
};

const validateInput: InputValidatorWithOptions = (value: string): InputValidatorResponse => {
    if (!!isGroup(value?.trim())) {
        return {status: 'valid'};
    }

    return {
        status: 'invalid',
        message: Locales.format('securityIdentityProviders.identityModal.validation.invalidGroup'),
    };
};

export const GroupValidator = {
    validateInput,
};
