export const Cookies = {
    VisitorId: 'coveo_visitorId',
};

export class CookieUtils {
    static getVisitorId(): string | undefined {
        const regex = new RegExp(`${Cookies.VisitorId}=([^;]+)`);
        const match = document.cookie.match(regex);
        return match ? match[1] : undefined;
    }
}
