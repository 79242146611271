import {combineReducers} from 'redux';

import {ObjectReducerGenerator} from '@core/store';
import {SearchUIConstants} from '../SearchUIConstants';
import {SearchUIState} from '../SearchUIState';

export const searchUIReducer = combineReducers<SearchUIState>({
    queries: ObjectReducerGenerator(SearchUIConstants.actionTypes.setQuery, {}),
    shouldExecuteQuery: ObjectReducerGenerator(SearchUIConstants.actionTypes.setShouldExecuteQuery, {}),
    shouldClearQuery: ObjectReducerGenerator(SearchUIConstants.actionTypes.setShouldClearQuery, {}),
});
