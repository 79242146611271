export class SearchPageVersion {
    constructor(
        public major: string,
        public minor: string,
    ) {}

    toString() {
        return `${this.major}.${this.minor}`;
    }

    toJSON() {
        return {major: this.major, minor: this.minor};
    }

    equals(otherVersion: SearchPageVersion | null) {
        if (otherVersion === null) {
            return false;
        }
        return otherVersion.major === this.major && otherVersion.minor === this.minor;
    }

    static fromString(version: string): SearchPageVersion {
        if (version.match(/^[0-9]+\.[0-9]+$/)) {
            const [major, minor] = version.split('.');
            return new SearchPageVersion(major, minor);
        }
        return null;
    }
}
