import {useDidUpdate} from '@components/mantine';
import {DefaultError, UseQueryResult} from '@core/api';
import {ReactNode, useEffect} from 'react';
import {usePageContext} from '../../contexts';
import {InnerPageBody} from './PageBody';

type SimplifiedQueryResult<TData, TError> = Pick<UseQueryResult<TData, TError>, 'isLoading' | 'isPending' | 'data'>;

export interface PageQueryProps<TData, TError = DefaultError> {
    query: UseQueryResult<TData, TError> | SimplifiedQueryResult<TData, TError>;
    emptyStateComponent: ReactNode;
    getHasData?: (data?: TData) => boolean;
}

const defaultGetHasData = (data: unknown) => {
    if (Array.isArray(data)) {
        return data.length > 0;
    }

    if (typeof data === 'object' && 'totalEntries' in data && typeof data.totalEntries === 'number') {
        return data.totalEntries > 0;
    }

    console.error('Could not determine if data is empty');
    return false;
};

export const PageQuery = <TData, TError = DefaultError>({
    query,
    getHasData = defaultGetHasData,
    emptyStateComponent,
}: PageQueryProps<TData, TError>) => {
    const {isEmpty, setIsEmpty, setIsLoading} = usePageContext();

    useEffect(() => {
        if (!query.isPending && query.data) {
            const hasData = getHasData(query.data);
            setIsEmpty(!hasData);
        }
    }, [query.data, query.isPending]);

    useDidUpdate(() => {
        setIsLoading(query.isLoading);
    }, [query.isLoading]);

    useEffect(() => {
        // when mounting the query can be in cache. In this case remove the loading
        if (!query.isPending) {
            setIsLoading(false);
        }
    }, []);

    return isEmpty ? <InnerPageBody>{emptyStateComponent}</InnerPageBody> : null;
};
