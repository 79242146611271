import {RequestsActions} from '@coveord/jsadmin-common';
import {IThunkAction} from '@coveord/plasma-react';

import {ObjectKeyValueActions} from '@core/store';
import {CatalogObjectType, Platform} from '@core/api';
import {CommerceState} from '../../CommerceState';
import {ObjectType} from '../services/ObjectTypesResources';
import {CatalogConfigurationSelectors} from '../CatalogConfiguration/CatalogConfigurationSelectors';

export const ObjectTypesActionsTypes = {
    updateObjectTypes: 'UPDATE_OBJECT_TYPES',
    fetch: 'FETCH_OBJECT_TYPES',
};

const updateObjectTypes = (
    id: string,
    objectTypes: ObjectType[],
    hasIndexedCatalogContent: boolean,
    selectedSourceId: string,
) =>
    ObjectKeyValueActions.updateNested(ObjectTypesActionsTypes.updateObjectTypes, id, {
        data: objectTypes,
        selectedSourceId: selectedSourceId,
        hasIndexedCatalogContent,
    });

const getValidCatalogContent = async (sourceIds: string[]) => {
    for (const sourceId of sourceIds) {
        try {
            const data: CatalogObjectType = await Platform.catalogContent.getObjectTypes(sourceId);

            if (data.hasIndexedCatalogContent) {
                return {data: data, selectedSourceId: sourceId};
            }
        } catch (e) {
            continue;
        }
    }

    const dataDefault: CatalogObjectType = {hasIndexedCatalogContent: false, objectTypeValues: []};
    return {data: dataDefault, selectedSourceId: sourceIds[0]};
};

const fetchObjectTypes =
    (id: string): IThunkAction<Promise<void>, CommerceState> =>
    async (dispatch, getState) => {
        const state = getState();
        const sourceIds = CatalogConfigurationSelectors.getSelectedOrSavedCatalogProductSource(state);

        await dispatch(
            RequestsActions.handle(ObjectTypesActionsTypes.fetch, async () => {
                const validContent = await getValidCatalogContent(sourceIds);

                const objectTypes: ObjectType[] = validContent.data.objectTypeValues.map((value) => ({
                    value,
                    lookupValue: value,
                    numberOfResults: 1,
                }));

                // if the list is empty but hasCatalogContent is true then no objecttype on documents -> invalid
                // otherwise there's no content on the source -> suggestion
                dispatch(
                    updateObjectTypes(
                        id,
                        objectTypes,
                        validContent.data.hasIndexedCatalogContent,
                        validContent.selectedSourceId,
                    ),
                );
            }),
        );
    };

export const ObjectTypesActions = {
    fetchObjectTypes,
    getValidCatalogContent,
    remove: (id: string) => ObjectKeyValueActions.remove(ObjectTypesActionsTypes.updateObjectTypes, id),
};
