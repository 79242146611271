import {IReduxAction} from '@coveord/plasma-react';

import {Organization} from '../store';

export interface OrganizationActionsPayload {
    organization: Organization;
}

export const OrganizationActions = {
    updateOrganization: 'UPDATE_ORGANIZATION',
};

export const updateOrganization = (organization: Organization): IReduxAction<OrganizationActionsPayload> => ({
    type: OrganizationActions.updateOrganization,
    payload: {organization},
});
