import {LicenseModel, OrganizationModel} from '@core/api';
import {Config} from '@core/configuration';
import {Debug} from '@core/debug';
import {LDFlagValue, basicLogger, initialize} from 'launchdarkly-js-client-sdk';

export interface LaunchDarklyProject {
    checkFeatureFlag(key: string, defaultValue?: LDFlagValue): LDFlagValue;
    shutdown(): Promise<void>;
}

export class LaunchDarkly {
    private initPromises: Array<Promise<void>> = [];

    searchPages: LaunchDarklyProject;
    platform: LaunchDarklyProject;
    customerService: LaunchDarklyProject;
    searchInterfaces: LaunchDarklyProject;
    commerceService: LaunchDarklyProject;

    constructor(
        private readonly organization: OrganizationModel,
        private readonly license: LicenseModel,
    ) {
        this.searchPages = this.createLaunchDarklyClient(Config.LaunchDarkly.SearchPages.clientID);
        this.platform = this.createLaunchDarklyClient(Config.LaunchDarkly.Platform.clientID);
        this.customerService = this.createLaunchDarklyClient(Config.LaunchDarkly.CustomerService.clientID);
        this.searchInterfaces = this.createLaunchDarklyClient(Config.LaunchDarkly.SearchInterface.clientID);
        this.commerceService = this.createLaunchDarklyClient(Config.LaunchDarkly.CommerceService.clientID);
    }

    initialize(): Promise<any> {
        return Promise.all(this.initPromises);
    }

    async tearDown() {
        Promise.all([
            this.searchPages.shutdown(),
            this.platform.shutdown(),
            this.customerService.shutdown(),
            this.searchInterfaces.shutdown(),
            this.commerceService.shutdown(),
        ]);
    }

    private createLaunchDarklyClient(clientID: string): LaunchDarklyProject {
        const contextForOrg = {
            kind: 'user',
            key: this.organization.id,
            licenseType: this.license.type,
            licenseProductType: this.license.productType!,
            licenseProductEdition: this.license.productEdition!,
        };
        const options = {
            logger: basicLogger({
                level: 'warn',
            }),
        };

        const launchDarklyClient = initialize(clientID, contextForOrg, options);

        const promise = new Promise<void>((resolve) => {
            launchDarklyClient.on('ready', () => {
                Debug.setState('launchDarklyTool', (prev) => ({...prev, ...launchDarklyClient.allFlags()}));
                resolve();
            });
        });

        this.initPromises.push(promise);

        return {
            checkFeatureFlag: (key: string, defaultValue: LDFlagValue = false): LDFlagValue => {
                let launchDarklyFlagValue: LDFlagValue = launchDarklyClient.variation(key, defaultValue);
                const launchDarklyToolOverride = Debug.getState('launchDarklyTool');
                if (launchDarklyToolOverride?.hasOwnProperty(key)) {
                    launchDarklyFlagValue = launchDarklyToolOverride[key];
                }

                return launchDarklyFlagValue;
            },
            shutdown: launchDarklyClient.close,
        };
    }
}
