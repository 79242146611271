import {Arrays, PrivilegeUtils} from '@core/user';
import {compose} from 'redux';
import {createSelector, createStructuredSelector} from 'reselect';

import {AdminState} from '../../application/Reducers';
import {PrivilegesTableSelectorProps, PrivilegesTableSelectors} from '../privileges-table/PrivilegesTableSelectors';
import {GranularResource} from './GranularPrivilegesTableActions';
import {GranularPrivilegesTableUtils as Utils} from './GranularPrivilegesTableUtils';

export interface GranularPrivilegesTableSelectorProps extends PrivilegesTableSelectorProps {
    domain: string;
    [key: string]: any;
}

const getDomain = (state: AdminState, props: GranularPrivilegesTableSelectorProps) => props.domain;

const getTableIsLoading = (state: AdminState, props: GranularPrivilegesTableSelectorProps): boolean =>
    !!state.granularPrivilegesTables &&
    !!state.granularPrivilegesTables[props.domain] &&
    !!state.granularPrivilegesTables[props.domain].loading;

const getTableResources = (state: AdminState, props: GranularPrivilegesTableSelectorProps): GranularResource[] =>
    (state.granularPrivilegesTables &&
        state.granularPrivilegesTables[props.domain] &&
        state.granularPrivilegesTables[props.domain].resources) ||
    [];

export const getAppliedPrivileges = createSelector(
    [PrivilegesTableSelectors.getAppliedPrivilegesGroupedByDomain, getDomain],
    compose(Utils.filterGranularPrivileges, _.result),
);

export const getGranularPrivileges = createSelector(
    [PrivilegesTableSelectors.getGranularPrivilegesGroupedByDomain, getDomain],
    compose(Utils.filterGranularPrivileges, _.result),
);

const getAppliedPrivilegesByResourcesId = createSelector(
    [getAppliedPrivileges, getGranularPrivileges],
    compose(PrivilegeUtils.groupByTargetId, Arrays.swapIfEmpty),
);

const getPossiblePrivilegesByResourcesId = createSelector(
    [PrivilegesTableSelectors.getPossiblePrivilegesGroupedByDomain, getDomain],
    compose(PrivilegeUtils.groupByTargetId, _.result),
);

const getInitialPrivilegesByResourcesId = createSelector(
    [PrivilegesTableSelectors.getInitialPrivilegesGroupedByDomain, getDomain],
    compose(PrivilegeUtils.groupByTargetId, _.result),
);

const getTableRows = createSelector(
    [
        getDomain,
        getTableResources,
        getAppliedPrivilegesByResourcesId,
        getPossiblePrivilegesByResourcesId,
        getInitialPrivilegesByResourcesId,
    ],
    Utils.computeTableRows,
);

const makeGetGranularPrivilegesTableStateProps = () =>
    createStructuredSelector({
        tableData: getTableRows,
        loading: getTableIsLoading,
    });

export const GranularPrivilegesTableSelectors = {
    makeGetGranularPrivilegesTableStateProps,
    getAppliedPrivilegesByResourcesId,
    getInitialPrivilegesByResourcesId,
};
