import {AccessTokenUtils} from '@core/authentication';
import {
    IInputState,
    InputSelectors,
    RadioSelectSelectors,
    SelectSelector,
    TextAreaSelectors,
} from '@coveord/plasma-react';
import {chain, groupBy, isEmpty, negate, partition} from 'underscore';
import {
    ButtonPositionHorizontalResponseModel,
    ButtonPositionVerticalResponseModel,
    IPXButtonModel,
    InProductExperienceModel,
    RestTokenParams,
    RestUserId,
} from '@core/api';
import {AdminState} from '../application/Reducers';
import {InAppWidgetConstants} from './InAppWidgetConstants';
import {
    DefaultInAppWidgetPage,
    isButtonIconInvalid,
    isFontFamilyInvalid,
    isFontSizeInvalid,
    isNameInvalid,
    isSearchHubInvalid,
    isTitleInvalid,
} from './InAppWidgetModels';

const getInAppWidget = (originalWidget: InProductExperienceModel, state: AdminState): InProductExperienceModel => ({
    ...originalWidget,
    name: getInAppWidgetName(state),
    title: InputSelectors.getValue(state, {id: InAppWidgetConstants.Inputs.Widgets.title}),
    button: getInAppWidgetButton(originalWidget.button, state),
    token: getInAppWidgetToken(originalWidget.token, state),
});

const getInAppWidgetName = (state: AdminState) =>
    InputSelectors.getValue(state, {id: InAppWidgetConstants.Inputs.Widgets.name});

const getInAppWidgetNameIsInvalid = (state: AdminState, otherWidgetsNames: string[], otherSearchPagesNames: string[]) =>
    isNameInvalid(getInAppWidgetName(state), otherWidgetsNames, otherSearchPagesNames);

const getInAppWidgetTitle = (state: AdminState) =>
    InputSelectors.getValue(state, {id: InAppWidgetConstants.Inputs.Widgets.title});

const getInAppWidgetTitleIsInvalid = (state: AdminState) => isTitleInvalid(getInAppWidgetTitle(state));

const getInAppWidgetSearchHub = (state: AdminState) =>
    InputSelectors.getValue(state, {id: InAppWidgetConstants.Inputs.Token.searchHub});

const getInAppWidgetSearchHubIsInvalid = (state: AdminState) => isSearchHubInvalid(getInAppWidgetSearchHub(state));

const getInAppFontFamilyIsInvalid = (state: AdminState) => isFontFamilyInvalid(getButtonFontFamily(state));

const getInAppFontSize = (state: AdminState) => {
    const value = InputSelectors.getValue(state, {id: InAppWidgetConstants.Inputs.Button.fontSize});
    return value ? value.trim() : null;
};

const getInAppFontSizeIsInvalid = (state: AdminState) => isFontSizeInvalid(getInAppFontSize(state));

const getAnyInputIsInvalid = (state: AdminState, otherWidgetsNames: string[], allSearchPagesNames) =>
    getInAppWidgetNameIsInvalid(state, otherWidgetsNames, allSearchPagesNames) ||
    getInAppWidgetTitleIsInvalid(state) ||
    getInAppWidgetSearchHubIsInvalid(state) ||
    getInAppFontFamilyIsInvalid(state) ||
    getInAppWidgetTokenUserIdentitiesIsInvalid(state) ||
    getButtonIconIsInvalid(state);

const getInAppWidgetButton = (originalButton: IPXButtonModel, state: AdminState): IPXButtonModel => ({
    ...originalButton,
    buttonColor: {
        color: getInAppWidgetButtonColor(state),
        textColor: getInAppWidgetButtonTextColor(state),
    },
    buttonFont: {
        family: getButtonFontFamily(state),
        size: getButtonFontSizeAndUnits(state),
    },
    buttonIcon: getButtonIcon(state),
    buttonPosition: getButtonPosition(state),
    buttonTargetSelector: getButtonTargetSelector(state),
});

const getInAppWidgetToken = (originalToken: RestTokenParams, state: AdminState): RestTokenParams => ({
    ...originalToken,
    searchHub: getInAppWidgetSearchHub(state),
    filter: InputSelectors.getValue(state, {id: InAppWidgetConstants.Inputs.Token.filter}),
    userDisplayName: InputSelectors.getValue(state, {id: InAppWidgetConstants.Inputs.Token.userDisplayName}),
    userIds: getInAppWidgetTokenUserIdentities(state),
});

const getInAppWidgetTokenUserIdentities = (state: AdminState): RestUserId[] => {
    const allInputs = getInAppWidgetTokenUserIdentitiesInputsStateValid(state);
    if (!allInputs) {
        return [];
    }

    return allInputs
        .filter(([name, provider]) => !isEmpty(name.value) && !isEmpty(provider.value))
        .map(([name, provider]) => ({name: name.value, provider: provider.value}));
};

const getInAppWidgetTokenUserIdentitiesInputsState = (state: AdminState): {[position: string]: IInputState[]} => {
    if (!state.inputs) {
        return {};
    }

    const allInputs = state.inputs.filter((inputState) =>
        inputState.id.startsWith(InAppWidgetConstants.Inputs.Token.userIdentities),
    );

    return groupUserIdentityInputsByPosition(allInputs);
};

const groupUserIdentityInputsByPosition = (inputs: IInputState[]) => {
    const groupedByIdx = groupBy(inputs, (inputState: IInputState) => {
        const matches = /(position-[a-z0-9]+)/.exec(inputState.id);
        if (matches && matches[1]) {
            return matches[1];
        }
        return null;
    });

    return groupedByIdx;
};

const invalidTokenUserIdentitiesInputsState = (inputsState: IInputState[]) => {
    const [emptyInputs, nonEmptyInputs] = partition(inputsState, (inputState: IInputState) =>
        isEmpty(inputState.value),
    );
    const userIdentityIsInvalid = nonEmptyInputs.length !== 0 ? emptyInputs.length !== 0 : false;
    return userIdentityIsInvalid;
};

const getInAppWidgetTokenUserIdentitiesInputsStateInvalid = (state: AdminState) =>
    chain(getInAppWidgetTokenUserIdentitiesInputsState(state))
        .filter(invalidTokenUserIdentitiesInputsState)
        .flatten()
        .filter((inputState: IInputState) => isEmpty(inputState.value))
        .value() as IInputState[];

const getInAppWidgetTokenUserIdentitiesIsInvalid = (state: AdminState) =>
    getInAppWidgetTokenUserIdentitiesInputsStateInvalid(state).length !== 0;

const getInAppWidgetTokenUserIdentitiesInputsStateValid = (state: AdminState) =>
    chain(getInAppWidgetTokenUserIdentitiesInputsState(state))
        .filter(negate(invalidTokenUserIdentitiesInputsState))
        .value();

const getInAppWidgetButtonColor = (state: AdminState) => {
    let value = InputSelectors.getValue(state, {id: InAppWidgetConstants.Inputs.Button.color});
    if (!value) {
        value = DefaultInAppWidgetPage().button.buttonColor.color;
    }

    return value;
};

const getInAppWidgetButtonTextColor = (state: AdminState) => {
    let value = InputSelectors.getValue(state, {id: InAppWidgetConstants.Inputs.Button.textColor});
    if (!value) {
        value = DefaultInAppWidgetPage().button.buttonColor.textColor;
    }

    return value;
};

const getButtonFontFamily = (state: AdminState) =>
    InputSelectors.getValue(state, {id: InAppWidgetConstants.Inputs.Button.fontFamily});

const getButtonFontSizeAndUnits = (state: AdminState) => {
    const size = InputSelectors.getValue(state, {id: InAppWidgetConstants.Inputs.Button.fontSize});
    const units = SelectSelector.getListBoxSelected(state, {id: InAppWidgetConstants.Inputs.Button.fontSizeUnit});
    const sizeAsString = `${size ? size.trim() : '12'}`;
    const unitsAsString = `${units && units[0] ? units[0] : 'px'}`;

    return `${sizeAsString}${unitsAsString}`;
};

const getButtonIcon = (state: AdminState): IPXButtonModel['buttonIcon'] => {
    const icon = TextAreaSelectors.getValue(state, InAppWidgetConstants.Inputs.Button.icon);
    return {value: icon};
};

const getButtonIconIsInvalid = (state: AdminState): boolean => isButtonIconInvalid(getButtonIcon(state).value);

const getButtonPosition = (state: AdminState): IPXButtonModel['buttonPosition'] => {
    const button = InAppWidgetConstants.Inputs.Button;
    const position = RadioSelectSelectors.getValue(state, {id: button.position});

    switch (position) {
        case button.positionTopRight:
            return {
                horizontalPosition: ButtonPositionHorizontalResponseModel.Right,
                verticalPosition: ButtonPositionVerticalResponseModel.Top,
            };

        case button.positionMiddleRight:
            return {
                horizontalPosition: ButtonPositionHorizontalResponseModel.Right,
                verticalPosition: ButtonPositionVerticalResponseModel.Middle,
            };

        case button.positionBottomLeft:
            return {
                horizontalPosition: ButtonPositionHorizontalResponseModel.Left,
                verticalPosition: ButtonPositionVerticalResponseModel.Bottom,
            };

        case button.positionBottomCenter:
            return {
                horizontalPosition: ButtonPositionHorizontalResponseModel.Center,
                verticalPosition: ButtonPositionVerticalResponseModel.Bottom,
            };

        case button.positionBottomRight:
            return {
                horizontalPosition: ButtonPositionHorizontalResponseModel.Right,
                verticalPosition: ButtonPositionVerticalResponseModel.Bottom,
            };

        default:
            const defaultPosition = DefaultInAppWidgetPage().button.buttonPosition;
            const message = 'Encountered unknown position. Falling back to default:';
            console.warn(message, defaultPosition);

            return defaultPosition;
    }
};

const getButtonTargetSelector = (state: AdminState) =>
    InputSelectors.getValue(state, {id: InAppWidgetConstants.Inputs.Button.targetSelector});

const getIPXv1 = ({ipxs}: AdminState) => ipxs ?? [];

export const getIPXv2 = ({ipxsV2}: AdminState) => ipxsV2?.items ?? [];

// used for v1 routes
export const getAuthorizationHeader = (): HeadersInit => ({
    Authorization: `Bearer ${AccessTokenUtils.getToken()}`,
});

const hasIPXs = (state: AdminState) =>
    (InAppWidgetSelectors.getIPXv1(state)?.length ?? 0) + (InAppWidgetSelectors.getIPXv2(state)?.length ?? 0) > 0;

export const InAppWidgetSelectors = {
    getInAppWidget,
    getInAppWidgetNameIsInvalid,
    getInAppWidgetTitleIsInvalid,
    getInAppWidgetSearchHubIsInvalid,
    getInAppFontFamilyIsInvalid,
    getInAppFontSizeIsInvalid,
    getButtonIconIsInvalid,
    getInAppWidgetTokenUserIdentitiesInputsState,
    getInAppWidgetTokenUserIdentitiesInputsStateValid,
    getInAppWidgetTokenUserIdentitiesInputsStateInvalid,
    getAnyInputIsInvalid,
    getInAppWidgetButtonColor,
    getInAppWidgetButtonTextColor,
    getButtonPosition,
    getButtonTargetSelector,
    groupUserIdentityInputsByPosition,
    getIPXv1,
    getIPXv2,
    hasIPXs,
};
