import {PrivilegeModel} from '@core/api';
import {PrivilegeUtils, PrivilegesAccessOptions, PrivilegesConstants, PrivilegesListMap} from '@core/user';
import {ApiKeyModelAttributes, DateUtils} from '@coveord/jsadmin-common';
import {callIfDefined} from '@coveord/plasma-react'; // eslint-disable-line import/no-deprecated

import {SearchPage} from '../../search-pages/models/SearchPageModel';
import {BaseSourceModelAttributes} from '../../sources/sources/BaseSourceModel';
import {ExtensionAttributes} from './../../indexing-pipeline-extensions/table/ExtensionModels';
import {GranularPrivilegesTableRow} from './GranularPrivilegesTable';
import {GranularResource} from './GranularPrivilegesTableActions';
import {isBinaryDomain} from './GranularPrivilegesTableConstants';

const filterGranularPrivileges = (privileges: PrivilegeModel[]) =>
    _.filter(privileges, PrivilegeUtils.isGranularPrivilege);

const computeTableRows = (
    domain: string,
    resources: GranularResource[],
    appliedPrivilegesById: PrivilegesListMap,
    possiblePrivilegesById: PrivilegesListMap,
    initialPrivilegesById: PrivilegesListMap,
): GranularPrivilegesTableRow[] => {
    if (_.isEmpty(resources)) {
        return [];
    }

    return resources.map(({id, name, additionalInfo}: GranularResource): GranularPrivilegesTableRow => {
        const appliedPrivileges = appliedPrivilegesById[id] || [];
        const initialPrivileges = initialPrivilegesById[id] || [];
        const possiblePrivileges = possiblePrivilegesById[id] || [];
        const isPushPrivileges = isBinaryDomain(domain);
        return {
            id,
            ...(additionalInfo && {additionalInfo}),
            resourceName: name,
            selectedLevel: isPushPrivileges
                ? _.isEmpty(appliedPrivileges)
                    ? PrivilegesAccessOptions.DenyPush
                    : PrivilegesAccessOptions.AllowPush
                : _.isEmpty(appliedPrivileges)
                  ? PrivilegesAccessOptions.View
                  : PrivilegesAccessOptions.Edit,
            disabled:
                _.isEmpty(possiblePrivileges) && _.isEmpty(possiblePrivilegesById[PrivilegesConstants.allTargetIds]),
            hasChanged: !PrivilegeUtils.listsAreEqual(appliedPrivileges, initialPrivileges),
        };
    });
};

const parseGranularResource = (targetDomain: string, data: any): GranularResource => ({
    id: data.id,
    name: data.displayName,
    // eslint-disable-next-line import/no-deprecated
    ...callIfDefined(parseMethods[targetDomain], data),
});

const parseSourceResource = ({name, sourceType}: BaseSourceModelAttributes): Partial<GranularResource> => ({
    name,
    additionalInfo: sourceType,
});

const parseApiKeyResource = ({createdDate}: ApiKeyModelAttributes): Partial<GranularResource> => ({
    additionalInfo: DateUtils.format(createdDate, 'lll'),
});

const parseExtentionResource = ({name}: ExtensionAttributes): Partial<GranularResource> => ({
    name,
});

const parseQueryPipelineResource = ({name}: {name: string; [key: string]: any}): Partial<GranularResource> => ({
    name,
});

const parseSecurityProviderResource = ({name}: SearchPage): Partial<GranularResource> => ({
    name,
});

const parseMethods: {[domain: string]: (data: any) => Partial<GranularResource>} = {
    [PrivilegesConstants.granularDomains.Sources]: parseSourceResource,
    [PrivilegesConstants.granularDomains.PushDocuments]: parseSourceResource,
    [PrivilegesConstants.granularDomains.PushIdentities]: parseSecurityProviderResource,
    [PrivilegesConstants.granularDomains.ApiKeys]: parseApiKeyResource,
    [PrivilegesConstants.granularDomains.Extensions]: parseExtentionResource,
    [PrivilegesConstants.granularDomains.Pipelines]: parseQueryPipelineResource,
};

export const GranularPrivilegesTableUtils = {
    filterGranularPrivileges,
    computeTableRows,
    parseGranularResource,
};
