import _ from 'underscore';
import {ComponentIdMap} from '../../ComponentIdMap';
import {CardState} from '../CardState';
import {MetricCardDefaultState, MetricCardState} from '../Metric/MetricCardState';

export const isVisitMetricState = (state: CardState): state is MetricCardState =>
    state.type === ComponentIdMap.VisitMetricComponent;

export const VisitMetricDefaultState: MetricCardState = _.extend({}, MetricCardDefaultState, {
    type: ComponentIdMap.VisitMetricComponent,
});
