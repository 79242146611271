import {forwardRef} from 'react';
import {LinkProps, Link as ReactRouterLink} from 'react-router-dom';

import {useOrganization} from '@core/organization';
import {injectOrganizationId} from '../utilities/injectOrganizationId';

// data-remove-listener attribute disables the LinkHandler in app package
export const Link = forwardRef<HTMLAnchorElement, LinkProps>(({to, ...props}, ref) => {
    const {id: orgId} = useOrganization();
    return <ReactRouterLink ref={ref} {...props} to={injectOrganizationId(to, orgId)} data-remove-listener />;
});
