import _ from 'underscore';
import {Action} from '../action-bar/ActionsModel';
import {PickySelectableEvents} from '../base-models/PickyModels';
import {CollectionUtils} from '../utils/CollectionUtils';
import {JQueryUtils} from '../utils/JQueryUtils';
import {UI} from '../utils/UIUtils';

export const SelectableItemBehaviorEvents = {
    DoubleClick: 'selectable-item-view:double-click',
};

export interface SelectableItemBehaviorOptions {
    ignoredTargets?: string[];
}

export class SelectableItemBehavior extends Marionette.Behavior {
    options: SelectableItemBehaviorOptions;
    selectionLocked: boolean;

    static SELECTED_ROW_CLASS = 'selected';
    static UNSELECTABLE_ROW_CLASS = 'js-unselectable';
    static SELECTION_LOCK_DELAY = 300; // prevent double click selection with a calibrated lock

    defaults(): SelectableItemBehaviorOptions {
        return {
            ignoredTargets: [],
        };
    }

    constructor(options?: any, view?: any) {
        super(options, view);

        this.modelEvents = {
            [PickySelectableEvents.Selected]: this.highlightSelected,
            [PickySelectableEvents.Deselected]: this.highlightSelected,
        };

        this.selectionLocked = false;

        // For an unknown reason, behavior constructor is called before setting the model.
        // Model can still be accessed using view.options.
        const model = this.view.options.model;

        if (model && model.select && model.deselect) {
            if (!(model.collection && model.collection.selectAll && model.collection.selectNone)) {
                throw new TypeError(
                    'SelectableItemBehavior view.model.collection must be an instance of PickyMultiSelect.',
                );
            }
        } else {
            throw new TypeError('SelectableItemBehavior view.model must be an instance of PickySelectable.');
        }
    }

    events() {
        return {
            mousedown: this.onMouseDownRow,
            click: this.onClickRow,
            dblclick: this.onDoubleClickRow,
        };
    }

    private onMouseDownRow(event: JQuery.TriggeredEvent) {
        if (this.selectionLocked) {
            event.preventDefault();
        }
    }

    private onClickRow(event: JQuery.TriggeredEvent) {
        if (!this.$el.hasClass(SelectableItemBehavior.UNSELECTABLE_ROW_CLASS)) {
            if (!JQueryUtils.isMatchingSelectors(event.target, this.options.ignoredTargets)) {
                const model = this.view.model;
                const hasGroupedActions =
                    _.isFunction(model.getActions) && _.some(model.getActions(), (action: Action) => !!action.grouped);

                if (
                    (!!event.metaKey || !!event.ctrlKey) === false ||
                    !CollectionUtils.isMultiSelect(model.collection) ||
                    !hasGroupedActions
                ) {
                    model.collection.selectNone();
                }

                const modelSelected = model.selected;
                if (_.isUndefined(modelSelected) || modelSelected === false) {
                    model.select();
                }
            }
        }

        if (!this.selectionLocked) {
            this.selectionLocked = true;
            setTimeout(() => {
                this.selectionLocked = false;
            }, SelectableItemBehavior.SELECTION_LOCK_DELAY);
        }
    }

    private onDoubleClickRow(event: JQuery.TriggeredEvent) {
        if (!this.$el.hasClass(SelectableItemBehavior.UNSELECTABLE_ROW_CLASS)) {
            if (!JQueryUtils.isMatchingSelectors(event.target, this.options.ignoredTargets)) {
                UI.clearTextSelection();

                this.view.trigger(SelectableItemBehaviorEvents.DoubleClick);
            }
        }
    }

    private highlightSelected() {
        this.view.$el.toggleClass(SelectableItemBehavior.SELECTED_ROW_CLASS, !!this.view.model.selected);
    }

    private onRender() {
        this.highlightSelected();
    }
}
