import {BackboneWrapper} from '@components/page';
import {FunctionComponent, lazy} from 'react';

const LazyOrganizationStatusWatcher = lazy(async () => {
    const module = await import('./StatusComponent');
    return {
        default: () => (
            <BackboneWrapper
                componentClass={module.StatusComponent}
                recipe={module.StatusComponentRecipes}
                owner="organization"
                framework="Backbone"
                invisible
            />
        ),
    };
});

export const OrganizationStatusWatcher: FunctionComponent = () => <LazyOrganizationStatusWatcher />;
