import {ConditionsObjectsActions} from '@components/conditions';
import {addReducer} from '@core/store';
import {UserSelectors} from '@core/user';
import {searchOptimizationReducers} from './Reducers';
import {SOStore} from './Store';

addReducer(searchOptimizationReducers);

document.addEventListener('init-application', () => {
    if (UserSelectors.getPrivilegesValidator(SOStore.getState()).canViewPipelines) {
        SOStore.dispatch(ConditionsObjectsActions.fetchConditionObjects());
    }
});
