import {IReduxAction} from '@coveord/plasma-react';
import {Action} from 'redux';

export const EditTimeseriesActionsType = {
    SetTitle: 'EDIT_TIMESERIES_SET_TITLE',
    SetHeaderHref: 'EDIT_TIMESERIES_SET_HEADER_HREF',
    SetShowMetrics: 'EDIT_TIMESERIES_SET_SHOW_METRICS',
    SetShowPeak: 'EDIT_TIMESERIES_SET_SHOW_PEAK',
    SetShowAverage: 'EDIT_TIMESERIES_SET_SHOW_AVERAGE',
    SetShowTrend: 'EDIT_TIMESERIES_SET_SHOW_TREND',
    Clear: 'EDIT_TIMESERIES_CLEAR',
};

const clear = (): IReduxAction<Action> => ({
    type: EditTimeseriesActionsType.Clear,
});

export interface EditTimeseriesSetTitlePayload {
    title: string;
}

const setTitle = (title: string): IReduxAction<EditTimeseriesSetTitlePayload> => ({
    type: EditTimeseriesActionsType.SetTitle,
    payload: {title},
});

export interface EditTimeseriesSetHeaderHrefPayload {
    headerHref: string;
}

const setHeaderHref = (headerHref: string): IReduxAction<EditTimeseriesSetHeaderHrefPayload> => ({
    type: EditTimeseriesActionsType.SetHeaderHref,
    payload: {headerHref},
});

export interface EditTimeseriesSetShowPayload {
    show: boolean;
}

const setShowMetrics = (show: boolean): IReduxAction<EditTimeseriesSetShowPayload> => ({
    type: EditTimeseriesActionsType.SetShowMetrics,
    payload: {show},
});

const setShowPeak = (show: boolean): IReduxAction<EditTimeseriesSetShowPayload> => ({
    type: EditTimeseriesActionsType.SetShowPeak,
    payload: {show},
});

const setShowAverage = (show: boolean): IReduxAction<EditTimeseriesSetShowPayload> => ({
    type: EditTimeseriesActionsType.SetShowAverage,
    payload: {show},
});

const setShowTrend = (show: boolean): IReduxAction<EditTimeseriesSetShowPayload> => ({
    type: EditTimeseriesActionsType.SetShowTrend,
    payload: {show},
});

export const EditTimeseriesActions = {
    setTitle,
    setHeaderHref,
    setShowMetrics,
    setShowPeak,
    setShowAverage,
    setShowTrend,
    clear,
};
