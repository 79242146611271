import * as PlasmaStyle from '@coveord/plasma-style';
import _ from 'underscore';
import {PageEvents} from '../base-models/pageable/PageEvents';
import {PageableState} from '../base-models/PageableState';
import {PickySelectable, SingleSelectEvents} from '../base-models/PickyModels';
import {ServerTableState} from '../base-models/ServerTableState';
import {FilterablePageableSelectableSortable} from '../base-models/TableCollectionBuilder';
import {Objects} from '../utils/ObjectsUtils';
import {Options, SelectOption, SingleSelectWidget} from '../widgets/SingleSelect';
import './_pagination.scss';

import paginationView from './pagination-view.ejs';

export const PerPageNumbers: number[] = [25, 50, 100];

export const PaginationEvents = {
    PageLoading: 'pagination:PageLoading',
};

export const DefaultPaginationViewOptions = {
    showItemsPerPage: true,
};

export const DefaultPerPageOptions: SelectOption[] = _.map(PerPageNumbers, (value: number) => ({
    value,
    label: value.toString(),
}));

export interface PaginationViewOptions {
    collection: FilterablePageableSelectableSortable<PickySelectable>;
    showItemsPerPage?: boolean;
    onPageChange?: (page: number) => void;
    state: PageableState | ServerTableState;
}

export class PaginationView extends Marionette.LayoutView<Backbone.Model> {
    collection: FilterablePageableSelectableSortable<PickySelectable>;
    options: PaginationViewOptions;

    private itemsPerPageRegion: Marionette.Region;
    private itemsPerPageOptions: Options;
    private showItemsPerPage: boolean;

    constructor(options: PaginationViewOptions) {
        super(
            _.extend(
                {
                    className: 'pagination-container',
                    events: {
                        'click [data-page]': 'onPageChange',
                    },
                    collectionEvents: Objects.createObject([PageEvents.PageChanged, 'render']),
                },
                _.defaults(options, DefaultPaginationViewOptions),
            ),
        );

        this.template = paginationView;
        this.templateHelpers = {
            PlasmaStyle: PlasmaStyle,
            getPagesRange: () => options.state.getPagesRange(),
            isPageActive: (page: number) => options.state.page === page,
            shouldShowPagination: () => this.shouldShowPagination(),
            shouldShowItemsPerPage: () => this.shouldShowItemsPerPage(),
        };

        this.showItemsPerPage = !!options.showItemsPerPage;
    }

    initialize() {
        this.ui = {
            pageLink: '[data-page]',
            itemsPerPage: '.js-items-per-page',
        };
    }

    onRender() {
        if (this.showItemsPerPage) {
            this.setUpItemsPerPageSingleSelect(this.options.state.modelsPerPage);
        }
        this.delegateEvents();
    }

    onDomRefresh() {
        this.showPerPagePagination();
    }

    showLoading() {
        this.$el.addClass('loading-view');
    }

    hideLoading() {
        this.$el.removeClass('loading-view');
    }

    toggleLoading(show: boolean) {
        this.$el.toggleClass('loading-view', show);
    }

    private showPerPagePagination() {
        if (this.ui.itemsPerPage.length) {
            if (!this.itemsPerPageRegion) {
                this.addRegions({
                    itemsPerPageRegion: '.js-items-per-page',
                });
            }

            if (this.showItemsPerPage) {
                const itemsPerPageWidget = new SingleSelectWidget(this.itemsPerPageOptions);
                itemsPerPageWidget.show(this.itemsPerPageRegion, {
                    valueFormatter: (value) => value,
                    flatSelect: true,
                });
            }
        }
    }

    private shouldShowPagination(): boolean {
        return this.getVisibleItemsLength() > this.options.state.modelsPerPage;
    }

    private shouldShowItemsPerPage() {
        return this.showItemsPerPage && this.getVisibleItemsLength() > DefaultPerPageOptions[0].value;
    }

    private getVisibleItemsLength(): number {
        return this.collection.filterable
            ? this.collection.filterable.getVisibleCount()
            : this.options.state.totalEntries;
    }

    private onPageChange(event: JQuery.TriggeredEvent) {
        const $el = $(event.currentTarget);
        const page = $el.data('page');
        if (!$el.hasClass('disabled')) {
            this.trigger(PaginationEvents.PageLoading);
            if (this.options.onPageChange) {
                this.options.onPageChange(page);
            } else {
                this.options.state.setPage(page);
            }
        } else {
            event.preventDefault();
        }
    }

    private setUpItemsPerPageSingleSelect(modelsPerPage: number) {
        let optionMin = 0;
        const nbItems: number = this.getVisibleItemsLength();
        const options = _.filter(DefaultPerPageOptions, (option: SelectOption) => {
            const show = optionMin < nbItems;
            optionMin = option.value;
            return show;
        });
        this.itemsPerPageOptions = new Options();

        this.itemsPerPageOptions.setSelectOptions(options);
        this.itemsPerPageOptions.pickySelectFromValueOrFirst(modelsPerPage);

        this.listenTo(this.itemsPerPageOptions, SingleSelectEvents.SelectOne, this.onSelectItemsPerPageValue);
    }

    private onSelectItemsPerPageValue() {
        const selectedValue: number = parseInt(this.itemsPerPageOptions.selected.value, 10);

        if (selectedValue && selectedValue !== this.options.state.modelsPerPage) {
            // Calculate the new page that will also contain first results of the current page, considering new itemsPerPage.
            this.trigger(PaginationEvents.PageLoading);
            const adjustedPage = Math.floor(
                (this.options.state.page * this.options.state.modelsPerPage) / selectedValue,
            );
            this.options.state.modelsPerPage = selectedValue;

            // At this point we need to wait until SingleSelect.SelectOne is done, or the pageChange will destroy the widget early.
            _.defer(() => this.options.state.setPage(adjustedPage));
        }
    }
}
