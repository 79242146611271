/**
 * The Merchandising hub app routes defined after `/:organizationId`.
 */
export const merchandisingHub: Record<string, string> = {
    // listing
    '/:organizationId/listings': 'viewed product listings manager',
    '/:organizationId/listings/:listingId': 'viewed listing page details',
    '/:organizationId/listings/global/:ruleType/create': 'viewed listings manager global rule create',
    '/:organizationId/listings/global/rule/:ruleId/:ruleType/edit': 'viewed listings manager global rule edit',
    '/:organizationId/listings/:ruleType/create': 'viewed listings manager rule create - from listings manager',
    '/:organizationId/listings/:listingId/:ruleType/create':
        'viewed listings manager rule create - from listing details',
    '/:organizationId/listings/:listingId/rule/:ruleId/:ruleType/edit': 'viewed listings manager rule edit',
    // search
    '/:organizationId/search': 'viewed search manager',
    '/:organizationId/search/:expressionId/details': 'viewed query details',
    '/:organizationId/search/global/:ruleType/create': 'viewed search manager global rule create',
    '/:organizationId/search/global/rule/:ruleId/:ruleType/edit': 'viewed search manager global rule edit',
    '/:organizationId/search/:ruleType/create': 'viewed search manager rule create - from search manager',
    '/:organizationId/search/:configurationId/rule/:ruleId/:ruleType/edit': 'viewed search manager rule edit',
    // recs
    '/:organizationId/recommendations': 'viewed recommendations manager',
    '/:organizationId/recommendations/:recommendationId/details': 'viewed recommendation details',
    '/:organizationId/recommendations/:recommendationId/strategy': 'viewed recommendation strategy editor',
    '/:organizationId/recommendations/slot/create': 'viewed slot editor - create',
    '/:organizationId/recommendations/:ruleType/create': 'viewed rule editor - from recommendation manager',
    '/:organizationId/recommendations/:recommendationId/:ruleType/create':
        'viewed rule editor page - from recommendation details',
    '/:organizationId/recommendations/:recommendationId/rule/:ruleId/:ruleType/edit':
        'viewed recommendations manager rule edit',
    // facets
    '/:organizationId/facets': 'viewed facets manager',
};
