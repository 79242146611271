export const ProductNames = {
    COVEO_CLOUD: 'COVEO_CLOUD',
    SALESFORCE: 'SALESFORCE',
    SITECORE: 'SITECORE',
    DYNAMICS: 'DYNAMICS',
};

export const DeprecatedProductTypes = {
    TRIAL: 'TRIAL',
    STANDARD: 'STANDARD',
    INTERNAL: 'INTERNAL',
    SANDBOX: 'SANDBOX',
    SALES: 'SALES',
    ALLIANCE: 'ALLIANCE',
    TEST: 'TEST',
};

export const ProductTypes = {
    TRIAL: 'Trial',
    TEST: 'Test',
    DEMO: 'Demo',
    INTERNAL: 'Internal',
    PARTNER: 'Partner',
    SANDBOX: 'Sandbox',
    PRODUCTION: 'Production',
};

export const ProductTypesAliases = {
    Trial: ['trial'],
    Test: ['test', 'developer'],
    Demo: ['demo', 'poc', 'sales'],
    Internal: ['internal'],
    Partner: ['partner', 'alliance'],
    Sandbox: ['sandbox', 'non-production'],
    Production: ['production', 'standard', 'free'],
};

export const DeprecatedProductEditions = {
    FREE: 'FREE',
    STANDARD: 'STANDARD',
    BASE: 'BASE',
    PRO: 'PRO',
    ENTERPRISE: 'ENTERPRISE',
};

export const ProductEditions = {
    FREE: 'Free',
    STANDARD: 'Standard',
    BASE: 'Base',
    PRO: 'Pro',
    ENTERPRISE: 'Enterprise',
};
