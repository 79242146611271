import {FunctionComponent, ReactElement, createContext, useContext} from 'react';

import {TabsLayoutPanelProps} from './TabsLayoutPanel';

type TabsLayoutContextType = {
    tabs: Array<ReactElement<TabsLayoutPanelProps, FunctionComponent<TabsLayoutPanelProps>>>;
    activeTab?: string;
};

export const TabsLayoutContext = createContext<TabsLayoutContextType>({tabs: []});

export const TabsLayoutProvider = TabsLayoutContext.Provider;

/**
 * Hook to get the context of the TabsLayout.
 * @internal
 */
export const useTabsLayoutInternal = () => {
    const context = useContext(TabsLayoutContext);
    if (!context) {
        throw new Error('useTabsLayout must be used within a TabsLayoutProvider');
    }

    return context;
};

/**
 * @returns The URL identifier of the active tab panel.
 */
export const useTabsLayout = () => {
    const {activeTab} = useTabsLayoutInternal();

    return activeTab;
};
