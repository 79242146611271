import {BasePayload, IReduxAction} from '@coveord/plasma-react';
import _ from 'underscore';

import {EventFilterActionPayload, EventFilterActionsType} from '../actions/EventFiltersActions';
import {Filter} from '../FilterInterface';
import {EventFilterSetPayload} from './EventFilterSetReducers';

export interface EventFilterState {
    id: string;
    filter: Filter;
    eventFilterSetId: string;
}

export interface EventFilterStates {
    [id: string]: EventFilterState;
}

const areFiltersSimilar = (filter1: EventFilterState, filter2: EventFilterState): boolean =>
    filter1.eventFilterSetId === filter2.eventFilterSetId &&
    filter1.filter.key === filter2.filter.key &&
    filter1.filter.operator === filter2.filter.operator;

const shouldMergeWithOtherFilter = (state: EventFilterStates, filter: EventFilterState): boolean =>
    _.some(state, (f: EventFilterState): boolean => areFiltersSimilar(f, filter));

const mergeWithOtherFilters = (state: EventFilterStates, eventFilter: EventFilterState) => {
    const similarFilters = _.values(_.pick(state, (f) => areFiltersSimilar(f, eventFilter))).concat(eventFilter);
    const filtersValues = similarFilters.reduce((values, {filter}) => _.uniq([...values, ...filter.values]), []);
    const filtersToRemove = _.rest(similarFilters);
    const filterToKeep = {
        ...similarFilters[0],
        filter: {
            ...similarFilters[0].filter,
            values: filtersValues,
        },
    };

    return _.extend(_.omit(state, _.pluck(filtersToRemove, 'id')), {[filterToKeep.id]: filterToKeep});
};

const add = (state: EventFilterStates, action: IReduxAction<EventFilterActionPayload>) => {
    if (shouldMergeWithOtherFilter(state, action.payload)) {
        return mergeWithOtherFilters(state, action.payload);
    } else {
        return {...state, [action.payload.id]: action.payload};
    }
};

const remove = (state: EventFilterStates, action: IReduxAction<BasePayload>) => _.omit(state, action.payload.id);

const edit = (state: EventFilterStates, action: IReduxAction<EventFilterActionPayload>) => {
    if (shouldMergeWithOtherFilter(_.omit(state, action.payload.id), action.payload)) {
        return mergeWithOtherFilters(state, action.payload);
    } else {
        return {
            ...state,
            ...action.payload,
        };
    }
};

const EventFilterReducers: {[key: string]: (...args: any[]) => EventFilterStates} = {
    [EventFilterActionsType.add]: add,
    [EventFilterActionsType.remove]: remove,
};

const EventFilterReducer: {[key: string]: (...args: any[]) => EventFilterState} = {
    [EventFilterActionsType.edit]: edit,
};

export const eventFilterReducer = (
    state: EventFilterStates = {},
    action: IReduxAction<EventFilterSetPayload>,
): EventFilterStates => {
    if (!_.isUndefined(EventFilterReducers[action.type])) {
        return EventFilterReducers[action.type](state, action);
    }

    if (
        action &&
        action.payload &&
        action.payload.id &&
        state[action.payload.id] &&
        !_.isUndefined(EventFilterReducer[action.type])
    ) {
        return {
            ...state,
            [action.payload.id]: EventFilterReducer[action.type](state[action.payload.id], action),
        };
    }

    return state;
};
