import _ from 'underscore';

export class Assert {
    static handleFailure(message = 'Assertion Failed!') {
        /* eslint-disable no-console */
        if (console.trace) {
            console.trace();
        }
        /* eslint-enable */
        throw new AssertionFailedError(message);
    }

    static fail(message?: string) {
        Assert.handleFailure(message);
    }

    static check(condition: boolean, message?: string) {
        if (!condition) {
            Assert.fail(message);
        }
    }

    static isNotUndefined(obj: any) {
        Assert.check(!_.isUndefined(obj), 'Value should not be undefined.');
    }

    static isString(obj: any) {
        Assert.check(_.isString(obj), 'Value should be a string.');
    }
}

export class AssertionFailedError implements Error {
    name = 'AssertionFailedError';

    constructor(public message: string) {}
}
