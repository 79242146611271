import {PlatformPaths} from '@configurations/platform';
import {RouteObject} from '@core/routes';

export const routes: RouteObject[] = [
    {
        path: `${PlatformPaths.Storefront.fullPath}/*`,
        lazy: async () => {
            const {StorefrontAssociationListPage} = await import('../pages/StorefrontAssociationListPage');
            return {Component: StorefrontAssociationListPage};
        },
    },
    {
        path: PlatformPaths.StorefrontCreate.fullPath,
        lazy: async () => {
            const {StorefrontAssociationCreationPage} = await import('../pages/StorefrontAssociationCreationPage');
            return {Component: StorefrontAssociationCreationPage};
        },
    },
    {
        path: PlatformPaths.StorefrontEdit.fullPath,
        lazy: async () => {
            const {StorefrontAssociationEditPage} = await import('../pages/StorefrontAssociationEditPage');
            return {Component: StorefrontAssociationEditPage};
        },
    },
    {
        path: PlatformPaths.StorefrontOverview.fullPath,
        lazy: async () => {
            const {StorefrontAssociationOverviewPage} = await import('../pages/StorefrontAssociationOverviewPage');
            return {Component: StorefrontAssociationOverviewPage};
        },
    },
];
