import {IReduxAction} from '@coveord/plasma-react';
import {ReducersMapObject} from 'redux';
import {UserActions, UserReduxActionsPayload} from '../../user/actions/UserActions';
import {UserRedux} from '../interfaces/UserInterface';
import {UserState} from '../store';

export const defaultUserState: UserRedux = {};

const reducer = (state: UserRedux = defaultUserState, action: IReduxAction<UserReduxActionsPayload>): UserRedux => {
    switch (action.type) {
        case UserActions.updateUser:
            return {
                ...state,
                ...action.payload?.user,
                additionalInformation: {
                    ...state.additionalInformation,
                    ...action.payload?.user.additionalInformation,
                },
            };
        default:
            return state;
    }
};

export const userReducer: ReducersMapObject<UserState> = {
    user: reducer,
};
