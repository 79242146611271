import {RouteObject} from '@core/routes';

export const routes: RouteObject[] = [
    {
        path: '/:orgId/activity/browser',
        lazy: async () => {
            const {ActivityBrowserPage} = await import('../pages/ActivityBrowserPage');
            return {Component: ActivityBrowserPage};
        },
    },
];
