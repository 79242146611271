import {IReduxAction} from '@coveord/plasma-react';

import {GroupsActionTypes} from './GlobalGroupsActions';

export type GlobalGroupsState = {[id: string]: GlobalGroupState};

export interface GlobalGroupState {
    privilegesReadOnly: boolean;
    name: string;
}

export const globalGroupInitialState: GlobalGroupState = {
    privilegesReadOnly: false,
    name: '',
};

export const globalGroupsReducer = (state: GlobalGroupsState = {}, action: IReduxAction<any>): GlobalGroupsState => {
    switch (action.type) {
        case GroupsActionTypes.remove:
            return _.omit(state, action.payload.id);
        case GroupsActionTypes.add:
        case GroupsActionTypes.updateName:
        case GroupsActionTypes.privilegesReadOnly:
            return {
                ...state,
                [action.payload.id]: {
                    ...globalGroupInitialState,
                    ...state[action.payload.id],
                    ...action.payload,
                },
            };
        default:
            return state;
    }
};
