import {CollapsibleBehavior} from './CollapsibleBehavior';
import {DisabledRowBehavior} from './DisabledRowBehavior';
import {LastUpdateLabelBehavior} from './LastUpdateLabelBehavior';
import {PreventNavigateBehavior} from './PreventNavigateBehavior';
import {ScheduleDropdownsBehavior} from './ScheduleDropdownsBehavior';
import {SelectableItemBehavior} from './SelectableItemBehavior';
import {StickyHeaderBehavior} from './StickyHeaderBehavior';
import {TabsBehavior} from './TabsBehavior';
import {TooltipBehavior} from './TooltipBehavior';

/**
 * @deprecated Only returns the argument
 */
export const Behaviors = (behaviors: any) => behaviors;

export const setBehaviorsLookup = () => {
    Marionette.Behaviors.behaviorsLookup = () => ({
        CollapsibleBehavior,
        DisabledRowBehavior,
        LastUpdateLabelBehavior,
        SelectableItemBehavior,
        TabsBehavior,
        TooltipBehavior,
        ScheduleDropdownsBehavior,
        PreventNavigateBehavior,
        StickyHeaderBehavior,
    });
};
