import {InputSelectors, SelectSelector} from '@coveord/plasma-react';
import {AdminState} from '../application/Reducers';
import {SearchPage} from './models/SearchPageModel';
import {SearchPageVersion} from './models/SearchPageVersion';
import {SearchPagesConstants} from './SearchPagesConstants';

const getSearchPages = (state: AdminState) => state.searchPages ?? [];
const getSearchPagesNames = (state: AdminState) => getSearchPages(state)?.map((searchPage) => searchPage.name);
const getSearchPageNameInputValue = (state: AdminState) =>
    InputSelectors.getValue(state, {id: SearchPagesConstants.SearchPageEditor.Inputs.Name}) || '';
const getSearchPageTitleInputValue = (state: AdminState) =>
    InputSelectors.getValue(state, {id: SearchPagesConstants.SearchPageEditor.Inputs.Title}) || '';
const getSearchPageCurrentVersionInputValue = (state: AdminState) => {
    const versionString = SelectSelector.getListBoxSelected(state, {
        id: SearchPagesConstants.SearchPageEditor.Inputs.UIVersion,
    })?.[0];
    return versionString ? SearchPageVersion.fromString(versionString) : null;
};

const getSearchPageToUpdateInto = (state: AdminState): SearchPage => ({
    ...(state.searchPageEditor?.activeSearchPage || {}),
    name: getSearchPageNameInputValue(state),
    title: getSearchPageTitleInputValue(state),
});

const getNextGenSearchPages = (state: AdminState) => state.nextGenSearchPages.items;

export const SearchPagesSelectors = {
    getSearchPages,
    getSearchPagesNames,
    getSearchPageNameInputValue,
    getSearchPageTitleInputValue,
    getSearchPageCurrentVersionInputValue,
    getSearchPageToUpdateInto,
    getNextGenSearchPages,
};
