import {Dictionary, Locales as LocalesGenerator} from '@core/locales';

import {LocalesKeys} from '../generated/LocalesKeys';
import Alert from './Alert.json';
import Api from './Api.json';
import EmptyState from './EmptyState.json';
import FormValidation from './FormValidation.json';
import Modal from './Modal.json';
import ProjectCreationPage from './ProjectCreationPage.json';
import ProjectEditonPage from './ProjectEditionPage.json';
import ProjectGlobalFilter from './ProjectGlobalFilter.json';
import ProjectResources from './ProjectResources.json';
import ProjectsListPage from './ProjectsListPage.json';
import ProjectViewPage from './ProjectViewPage.json';
import ResourcesSidePanel from './ResourcesSidePanel.json';
import Shared from './Shared.json';
import UnsupportedPanelAlert from './UnsupportedPanelAlert.json';

const req: Record<string, Record<string, any>> = {
    'strings/Alert': Alert,
    'strings/Api': Api,
    'strings/EmptyState': EmptyState,
    'strings/FormValidation': FormValidation,
    'strings/Modal': Modal,
    'strings/ProjectCreationPage': ProjectCreationPage,
    'strings/ProjectEditionPage': ProjectEditonPage,
    'strings/ProjectGlobalFilter': ProjectGlobalFilter,
    'strings/ProjectsListPage': ProjectsListPage,
    'strings/ProjectViewPage': ProjectViewPage,
    'strings/ProjectResources': ProjectResources,
    'strings/UnsupportedPanelAlert': UnsupportedPanelAlert,
    'strings/ResourcesSidePanel': ResourcesSidePanel,
    'strings/Shared': Shared,
};

let translations = {};

Object.keys(req).forEach((key) => {
    const parts: string[] = key.split('/');
    const roots: string[] = parts.splice(0, parts.length - 2);

    let map: any = translations;

    roots.forEach((root: string) => {
        if (!map[root]) {
            map = {...map, [root]: {}};
        }
        map = map[root];
    });

    translations = {...map, ...req[key]};
});

const enDictionary: Dictionary = {
    locale: 'en',
    translations,
};

export const Locales: LocalesGenerator<LocalesKeys> = new LocalesGenerator<LocalesKeys>({
    dictionaries: [enDictionary],
    defaultLocale: 'en',
});
