import {ResourceSnapshotType} from '@core/api';
import {Locales} from '../Locales';

export const ResourceSnapshotsActionTypes = {
    fetchResourceSnapshots: 'RESOURCE_SNAPSHOTS_LIST',
    fetchResourceSnapshot: 'RESOURCE_SNAPSHOTS_GET',
    createResourceSnapshotFromFile: 'RESOURCE_SNAPSHOTS_CREATE_FROM_FILE',
    createResourceSnapshotFromOrganization: 'RESOURCE_SNAPSHOTS_CREATE_FROM_ORGANIZATION',
    deleteResourceSnapshot: 'RESOURCE_SNAPSHOT_DELETE',
    fetchSynchronizationPlan: 'RESOURCE_SNAPSHOT_SYNCHRONIZATION_PLAN',
    updateSynchronizationPlan: 'RESOURCE_SNAPSHOT_SYNCHRONIZATION_PLAN_UPDATE',
};

export const ResourceSnapshotConstants = {
    Components: {
        ResourceSnapshots: 'ResourceSnapshots',
        PushSnapshotModal: 'PushSnapshotModal',
        SnapshotOrganizationPicker: 'snapshot-organization-dropdown-push',
        PushSnapshotInputNote: 'PushSnapshotInputNote',
        CreateFromFileInputNote: 'CreateFromFileInputNote',
        CreateSnapshotFromFileModal: 'CreateSnapshotFromFileModal',
        CreateSnapshotFilePicker: 'CreateSnapshotFilePicker',
        SynchronizationModal: 'SynchronizationModal',
        CreateSnapshotMenuConnected: 'CreateSnapshotMenuConnected',
        CreateSnapshotFromOrganizationModal: 'CreateSnapshotFromOrganizationModal',
        CreateSnapshotFromOrganizationModalInputNote: 'CreateSnapshotFromOrganizationModalInputNote',
        CreateSnapshotFromOrganizationFieldRadioSelect: 'CreateSnapshotFromOrganizationFieldRadioSelect',
        CreateSnapshotFromOrganizationFieldRadioAll: 'CreateSnapshotFromOrganizationFieldRadioAll',
        CreateSnapshotFromOrganizationFieldRadioSpecific: 'CreateSnapshotFromOrganizationFieldRadioSpecific',
        CreateSnapshotFromOrganizationExtensionRadioSelect: 'CreateSnapshotFromOrganizationExtensionRadioSelect',
        CreateSnapshotFromOrganizationQueryPipelineRadioSelect:
            'CreateSnapshotFromOrganizationQueryPipelineRadioSelect',
        CreateSnapshotFromOrganizationSearchPageRadioSelect: 'CreateSnapshotFromOrganizationSearchPageRadioSelect',
        CreateSnapshotFromOrganizationMLModelRadioSelect: 'CreateSnapshotFromOrganizationMLModelRadioSelect',
        CreateSnapshotFromOrganizationNotificationsRadioSelect:
            'CreateSnapshotFromOrganizationNotificationsRadioSelect',
        CreateSnapshotFromOrganizationSourcesRadioSelect: 'CreateSnapshotFromOrganizationSourcesRadioSelect',
        CreateSnapshotFromOrganizationSecurityProvidersRadioSelect:
            'CreateSnapshotFromOrganizationSecurityProvidersRadioSelect',
        CreateSnapshotFromOrganizationInsightPanelConfigurationRadioSelect:
            'CreateSnapshotFromOrganizationInsightPanelConfigurationsRadioSelect',
        CreateSnapshotFromOrganizationExtensionRadioAll: 'CreateSnapshotFromOrganizationExtensionRadioAll',
        CreateSnapshotFromOrganizationExtensionRadioSpecific: 'CreateSnapshotFromOrganizationExtensionRadioSpecific',
        CreateSnapshotFromOrganizationQueryPipelineRadioAll: 'CreateSnapshotFromOrganizationQueryPipelineRadioAll',
        CreateSnapshotFromOrganizationSearchPageRadioAll: 'CreateSnapshotFromOrganizationSearchPageRadioAll',
        CreateSnapshotFromOrganizationMLModelRadioAll: 'CreateSnapshotFromOrganizationMLModelRadioAll',
        CreateSnapshotFromOrganizationNotificationRadioAll: 'CreateSnapshotFromOrganizationNotificationRadioAll',
        CreateSnapshotFromOrganizationSourceRadioAll: 'CreateSnapshotFromOrganizationSourceRadioAll',
        CreateSnapshotFromOrganizationSecurityProviderRadioAll:
            'CreateSnapshotFromOrganizationSecurityProviderRadioAll',
        CreateSnapshotFromOrganizationInsightPanelConfigurationRadioAll:
            'CreateSnapshotFromOrganizationInsightPanelConfigurationRadioAll',
        CreateSnapshotFromOrganizationQueryPipelineRadioSpecific:
            'CreateSnapshotFromOrganizationQueryPipelineRadioSpecific',
        CreateSnapshotFromOrganizationSearchPageRadioSpecific: 'CreateSnapshotFromOrganizationSearchPageRadioSpecific',
        CreateSnapshotFromOrganizationMLModelRadioSpecific: 'CreateSnapshotFromOrganizationMLModelRadioSpecific',
        CreateSnapshotFromOrganizationNotificationRadioSpecific:
            'CreateSnapshotFromOrganizationNotificationRadioSpecific',
        CreateSnapshotFromOrganizationSourceRadioSpecific: 'CreateSnapshotFromOrganizationSourceRadioSpecific',
        CreateSnapshotFromOrganizationSecurityProviderRadioSpecific:
            'CreateSnapshotFromOrganizationSecurityProviderRadioSpecific',
        CreateSnapshotFromOrganizationInsightPanelConfigurationRadioSpecific:
            'CreateSnapshotFromOrganizationInsightPanelConfigurationRadioSpecific',
        CreateSnapshotFromOrganizationFieldCheckbox: 'CreateSnapshotFromOrganizationFieldCheckbox',
        CreateSnapshotFromOrganizationExtensionCheckbox: 'CreateSnapshotFromOrganizationExtensionCheckbox',
        CreateSnapshotFromOrganizationQueryPipelineCheckbox: 'CreateSnapshotFromOrganizationQueryPipelineCheckbox',
        CreateSnapshotFromOrganizationSearchPageCheckbox: 'CreateSnapshotFromOrganizationSearchPageCheckbox',
        CreateSnapshotFromOrganizationMLModelCheckbox: 'CreateSnapshotFromOrganizationMLModelCheckbox',
        CreateSnapshotFromOrganizationNotificationCheckbox: 'CreateSnapshotFromOrganizationNotificationCheckbox',
        CreateSnapshotFromOrganizationSourceCheckbox: 'CreateSnapshotFromOrganizationSourceCheckbox',
        CreateSnapshotFromOrganizationSecurityProviderCheckbox:
            'CreateSnapshotFromOrganizationSecurityProviderCheckbox',
        CreateSnapshotFromOrganizationInsightPanelConfigurationCheckbox:
            'CreateSnapshotFromOrganizationInsightPanelConfigurationCheckbox',
        IncludeDependenciesAutomaticallyCheckbox: 'IncludeDependenciesAutomaticallyCheckbox',
        SynchronizationTable: 'SynchronizationTable',
    },
    Inputs: {
        getSnapshotResources: {
            FIELD: 'fields-to-snapshot',
            EXTENSION: 'extensions-to-snapshot',
            QUERY_PIPELINES: 'query-pipelines-to-snapshot',
            SEARCH_PAGES: 'search-pages-to-snapshot',
            ML_MODELS: 'ml-models-to-snapshot',
            NOTIFICATIONS: 'notifications-to-snapshot',
            SOURCES: 'sources-to-snapshot',
            SECURITY_PROVIDERS: 'security-providers-to-snapshot',
            INSIGHT_PANEL_CONFIGURATION: 'insight-panel-configuration',
        },
        allResources: 'all-resources',
        specificResources: 'specific-resources',
    },
    Routes: {
        CreateSnapshotFromOrganizationModal: () => `/organization/resource-snapshots/create-snapshot`,
        ResourceSnapshots: () => '/organization/resource-snapshots/',
        PushSnapshotModal: (snapshotId: string) => `/organization/resource-snapshots/push-snapshot/${snapshotId}`,
        SynchronizationModal: (snapshotId: string) => `/organization/resource-snapshots/${snapshotId}/synchronization`,
    },
    ResourceTypesHierarchy: {
        FIELD: [],
        EXTENSION: [],
        QUERY_PIPELINE: [
            'ResourceSnapshots.filterRules',
            'ResourceSnapshots.queryParameterRules',
            'ResourceSnapshots.conditions',
            'ResourceSnapshots.rankingWeightRules',
            'ResourceSnapshots.resultRanking',
            'ResourceSnapshots.statementGroups',
            'ResourceSnapshots.stopWordRules',
            'ResourceSnapshots.thesaurusRules',
            'ResourceSnapshots.triggerRules',
            'ResourceSnapshots.mlModelAssociations',
        ],
        SEARCH_PAGE: [],
        ML_MODEL: ['ResourceSnapshots.artModel', 'ResourceSnapshots.qsModel', 'ResourceSnapshots.erModel'],
        NOTIFICATION: [],
        SOURCE: [],
        SECURITY_PROVIDER: [],
        INSIGHT_PANEL_CONFIGURATION: ['ResourceSnapshots.queryPipelines', 'ResourceSnapshots.insightPanelInterfaces'],
    },
};

export const resourceTypeLabelName = {
    [ResourceSnapshotType.extension]: Locales.format('Extensions.displayName'),
    [ResourceSnapshotType.field]: Locales.format('fields'),
    [ResourceSnapshotType.filter]: Locales.format('ResourceSnapshots.filterRules'),
    [ResourceSnapshotType.insightPanelConfiguration]: Locales.format('ResourceSnapshots.insightPanelConfigurations'),
    [ResourceSnapshotType.insightPanelInterface]: Locales.format('ResourceSnapshots.insightPanelInterfaces'),
    [ResourceSnapshotType.mapping]: Locales.format('ResourceSnapshots.mappings'),
    [ResourceSnapshotType.mlModelAssociation]: Locales.format('ResourceSnapshots.mlModelAssociations'),
    [ResourceSnapshotType.mlModel]: Locales.format('ResourceSnapshots.MLModels'),
    [ResourceSnapshotType.queryParameter]: Locales.format('ResourceSnapshots.queryParameterRules'),
    [ResourceSnapshotType.queryPipelineCondition]: Locales.format('ResourceSnapshots.conditions'),
    [ResourceSnapshotType.queryPipeline]: Locales.format('ResourceSnapshots.queryPipelines'),
    [ResourceSnapshotType.rankingWeight]: Locales.format('ResourceSnapshots.rankingWeightRules'),
    [ResourceSnapshotType.searchPage]: Locales.format('ResourceSnapshots.searchPages'),
    [ResourceSnapshotType.securityProvider]: Locales.format('ResourceSnapshots.securityProviders'),
    [ResourceSnapshotType.source]: Locales.format('sources'),
    [ResourceSnapshotType.stopWord]: Locales.format('ResourceSnapshots.stopWordRules'),
    [ResourceSnapshotType.subscription]: Locales.format('ResourceSnapshots.notifications'),
    [ResourceSnapshotType.thesaurus]: Locales.format('ResourceSnapshots.thesaurusRules'),
    [ResourceSnapshotType.trigger]: Locales.format('ResourceSnapshots.triggerRules'),
    [ResourceSnapshotType.statementGroup]: Locales.format('ResourceSnapshots.statementGroups'),
    [ResourceSnapshotType.resultRanking]: Locales.format('ResourceSnapshots.resultRankings'),
    [ResourceSnapshotType.featuredResult]: Locales.format('ResourceSnapshots.featuredResultRules'),
    [ResourceSnapshotType.rankingExpression]: Locales.format('ResourceSnapshots.rankingExpressionRules'),
};
