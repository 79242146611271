/**
 * The commerce's subroute defined after `/:organizationId/commerce`.
 */
export const commerce: Record<string, string> = {
    '/:organizationId/commerce/catalogs': 'viewed catalogs',
    '/:organizationId/commerce/catalogs/apikey/:catalogId': 'viewed catalog api key add',
    '/:organizationId/commerce/catalogs/configurations': 'viewed catalogs configurations',
    '/:organizationId/commerce/catalogs/create': 'viewed catalog add',
    '/:organizationId/commerce/catalogs/edit/:catalogId': 'viewed catalog edit',
    '/:organizationId/commerce/configurations/edit/:configurationId': 'viewed catalogs configuration edit',
    '/:organizationId/commerce/productlistings': 'viewed product listings',
    '/:organizationId/commerce/productlistings/catalogs/:catalogId': 'viewed product listings catalog',
    '/:organizationId/commerce/productlistings/create': 'viewed product listing add',
};
