import {createSelector} from 'reselect';
import _ from 'underscore';
import {ServiceState} from '../../../ServiceState';

import {IModalSelectState, modalSelectInitialState} from './ModalSelectReducers';

const get = (state: ServiceState, {id}: {id: string}): IModalSelectState =>
    _.findWhere(state.caseAssistManager.editCaseAssistConfig.modalSelects, {id}) || modalSelectInitialState;

const getValue = createSelector(get, (modalSelect: IModalSelectState) => modalSelect && modalSelect.value);

export const ModalSelectSelectors = {
    get,
    getValue,
};
