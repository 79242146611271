import {notifications} from '@components/mantine';
import {ActivityOperation, PlatformNoHandlers} from '@core/api';
import {RequestsActions} from '@coveord/jsadmin-common';
import {IReduxAction, IThunkAction} from '@coveord/plasma-react';

import {AdminState} from '../../application/Reducers';
import {Locales} from '../../Locales';

export interface OperationTypesActionPayload {
    operationTypes: ActivityOperation[];
}

export const OperationTypesActionTypes = {
    set: 'SET_OPERATION_TYPES',
};

export const OperationTypesRequests = {
    fetch: 'FETCH_OPERATION_TYPES',
};

const set = (operationTypes: ActivityOperation[]): IReduxAction<OperationTypesActionPayload> => ({
    type: OperationTypesActionTypes.set,
    payload: {operationTypes},
});

const fetch =
    (includeInternal: boolean): IThunkAction<void, AdminState> =>
    (dispatch) => {
        const getOperationTypes = async () => {
            try {
                const operationTypes = await PlatformNoHandlers.activity.getOperationTypes(includeInternal);
                dispatch(set(operationTypes));
            } catch (e) {
                notifications.showError(Locales.format('operationTypesFetchError'));
            }
        };
        return dispatch(RequestsActions.handle(OperationTypesRequests.fetch, getOperationTypes));
    };

export const OperationTypesActions = {
    fetch,
    set,
};
