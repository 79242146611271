import {IReduxAction} from '@coveord/plasma-react';

import {OrganizationActions, OrganizationActionsPayload} from '../actions';
import {Organization} from '../store';

export const defaultOrganizationState: Organization = {};

export const organizationReducer = (
    state: Organization = defaultOrganizationState,
    action: IReduxAction<OrganizationActionsPayload>,
): Organization => {
    switch (action.type) {
        case OrganizationActions.updateOrganization:
            return {...state, ...action.payload?.organization};
        default:
            return state;
    }
};
