import {IReduxAction} from '@coveord/plasma-react';

const START_CONFIGURING_ACTION = 'START_CONFIGURING_TAB';
const END_CONFIGURING_ACTION = 'END_CONFIGURING_TAB';

const INITIAL_STATE: IWithEmptyStateTabState = {
    configuringNew: false,
};

interface IWithEmptyStateTabActionTypes {
    startConfiguring: string;
    endConfiguring: string;
}

export interface IWithEmptyStateTabActions {
    startConfiguring: () => IReduxAction<void>;
    endConfiguring: () => IReduxAction<void>;
}

export interface IWithEmptyStateTabState {
    configuringNew: boolean;
}

export type WithEmptyStateTabReducer = <TState extends IWithEmptyStateTabState>(
    state: TState,
    action: IReduxAction<void>,
) => TState;

export interface IWithEmptyStateTabReducer {
    actionTypes: IWithEmptyStateTabActionTypes;
    actions: IWithEmptyStateTabActions;
    initialState: IWithEmptyStateTabState;
    reducer: WithEmptyStateTabReducer;
}

const generateActionTypes = (context: string): IWithEmptyStateTabActionTypes => ({
    startConfiguring: `${context}_${START_CONFIGURING_ACTION}`,
    endConfiguring: `${context}_${END_CONFIGURING_ACTION}`,
});

const generateActions = (actionTypes: IWithEmptyStateTabActionTypes): IWithEmptyStateTabActions => ({
    startConfiguring: () => ({
        type: actionTypes.startConfiguring,
    }),
    endConfiguring: () => ({
        type: actionTypes.endConfiguring,
    }),
});

const generateReducersMapping = (
    actionTypes: IWithEmptyStateTabActionTypes,
): {[key: string]: WithEmptyStateTabReducer} => ({
    [actionTypes.startConfiguring]: startConfiguring,
    [actionTypes.endConfiguring]: endConfiguring,
});

export const createWithEmptyStateTabReducer = (context: string): IWithEmptyStateTabReducer => {
    const actionTypes = generateActionTypes(context);

    return {
        actionTypes,
        actions: generateActions(actionTypes),
        initialState: INITIAL_STATE,
        reducer: (state, action) => withEmptyStateTabReducer(generateReducersMapping(actionTypes), state, action),
    };
};

const startConfiguring = <TState extends IWithEmptyStateTabState>(
    state: TState,
    action: IReduxAction<void>,
): TState => ({
    ...state,
    configuringNew: true,
});

const endConfiguring = <TState extends IWithEmptyStateTabState>(state: TState, action: IReduxAction<void>): TState => ({
    ...state,
    configuringNew: false,
});

const withEmptyStateTabReducer = <TState extends IWithEmptyStateTabState>(
    reducers: {[key: string]: WithEmptyStateTabReducer},
    state: TState = {...INITIAL_STATE} as TState,
    action: IReduxAction<any> = {type: '', payload: {}},
): TState => (_.isUndefined(reducers[action.type]) ? state : reducers[action.type](state, action));
