<% const commonLocales = require('../CommonLocales').commonLocales; %>
<% const PlasmaStyle = require('@coveord/plasma-style'); %>

<% if (ctx.shouldShowItemsPerPage()) { %>
<div class="items-per-page prepended-flat-select">
    <div class="flat-select-prepend">
        <%- commonLocales.format('resultsPerPage') %>:
    </div>
    <div class="js-items-per-page"></div>
</div>
<div class="flex-auto">
    <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
    </div>
</div>
<% } %>
<% if (ctx.shouldShowPagination()) { %>
<div class="pagination">
    <div class="flat-select">
        <% _.each(ctx.getPagesRange(), function(pagerange){ %>
        <% if (pagerange.previous || pagerange.next) { %>
        <a class="flat-select-option mod-link <%- ctx.isPageActive(pagerange.page) ? 'disabled' : 'selectable' %>"
           data-page="<%- pagerange.page %>">
            <% if (pagerange.previous) { %>
            <%= PlasmaStyle.SVG('#coveo-icon-arrow-left-rounded', 'icon icon-small mod-lg', 'pagination-icon') %>
            <%- commonLocales.format('previous') %>
            <% } else { %>
            <%- commonLocales.format('next') %>
            <%= PlasmaStyle.SVG('#coveo-icon-arrow-right-rounded', 'icon icon-small mod-lg', 'pagination-icon') %>
            <% } %>
        </a>
        <% } else { %>
        <a class="flat-select-option <%- ctx.isPageActive(pagerange.page) ? '' : 'selectable' %>" data-page="<%- pagerange.page %>">
            <%- pagerange.label %>
        </a>
        <% } %>
        <% }) %>
    </div>
</div>
<% } %>
