export const getCleanRedirectURI = (redirectUri: string): string => {
    const redirectURL = new URL(redirectUri);
    const hashHasQueryString = /[^\?]+(\?.*)/.test(redirectURL.hash);
    const hashDoesNotExists = redirectURL.hash === '';
    const redirectParamAlreadyIncluded = redirectURL.hash.includes('redirect=true');
    if (hashDoesNotExists) {
        redirectURL.hash = '#/?redirect=true';
    } else if (!hashHasQueryString) {
        redirectURL.hash = redirectURL.hash + '?redirect=true';
    } else if (!redirectParamAlreadyIncluded) {
        redirectURL.hash = redirectURL.hash + '&redirect=true';
    }
    return redirectURL.toString();
};
