import {Factory, factory, Group, GroupProps, useProps} from '@coveord/plasma-mantine';
import {Children, ReactNode} from 'react';
import {useEmptyStateContext} from './EmptyStateContext';

export type EmptyStateActionsStylesName = 'actionsContainer';

export interface EmptyStateActionsProps extends GroupProps {
    children: ReactNode;
}

export type EmptyStateActionsFactory = Factory<{
    props: EmptyStateActionsProps;
    ref: HTMLDivElement;
    stylesNames: EmptyStateActionsStylesName;
    compound: true;
}>;

const defaultProps: Partial<EmptyStateActionsProps> = {
    gap: 'sm',
};

export const EmptyStateActions = factory<EmptyStateActionsFactory>((_props, ref) => {
    const ctx = useEmptyStateContext();
    const {children, ...others} = useProps('EmptyStateActions', defaultProps, _props);
    const actionsCount = Children.count(children);
    return actionsCount > 0 ? (
        <Group
            ref={ref}
            {...ctx.getStyles('actionsContainer')}
            justify={ctx.variant === 'small' ? 'center' : 'flex-start'}
            {...others}
        >
            {children}
        </Group>
    ) : null;
});
