import {isRouteErrorResponse, useRouteError} from '@core/routes';
import {ErrorPage as BaseErrorPage, NotFoundPage} from '@pages/boundaries';
import {captureException} from '@sentry/react';
import {FunctionComponent, useEffect} from 'react';

import {Layout, SideNavigation} from '../components';

export const ErrorPage: FunctionComponent = () => {
    const error = useRouteError();
    useEffect(() => {
        if (error) {
            captureException(error, {level: 'fatal'});
        }
    }, [error]);

    return (
        <Layout>
            <Layout.Menu>
                <SideNavigation />
            </Layout.Menu>
            {isRouteErrorResponse(error) && error.status === 404 ? <NotFoundPage /> : <BaseErrorPage />}
        </Layout>
    );
};
