import {NavLink, NavLinkProps} from '@components/mantine';
import {FunctionComponent, HTMLAttributes, useEffect, useRef, useState} from 'react';

export const NavigationSection: FunctionComponent<NavLinkProps & HTMLAttributes<HTMLAnchorElement>> = ({
    children,
    ...others
}) => {
    const [isEmpty, setEmpty] = useState(false);
    const ref = useRef<HTMLDivElement>();

    useEffect(() => {
        if (ref.current) {
            setEmpty(!ref.current.hasChildNodes());
        }
    }, [ref.current]);

    return (
        !isEmpty && (
            <NavLink {...others}>
                <div ref={ref} data-navsection={others.label}>
                    {children}
                </div>
            </NavLink>
        )
    );
};
