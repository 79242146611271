import moment from 'moment-timezone';
import * as ReactVapor from '@coveord/plasma-react';
import _ from 'underscore';
import {UALocales} from '../../../../UALocales';
import {ConfigUtils} from '../../../../utils/ConfigUtils';
import {ReportTemplateCollection} from '../models/ReportTemplateCollection';
import {
    ReportTemplateChildParameter,
    ReportTemplateModel,
    ReportTemplateParameter,
} from '../models/ReportTemplateModel';

export const ReportTemplateActionsType = {
    SetTemplates: 'SET_TEMPLATES',
    SelectTemplate: 'SELECT_TEMPLATE',
    ClearTemplateParameters: 'CLEAR_TEMPLATE_PARAMETERS',
    FetchParamSuggestionSuccess: 'FETCH_PARAM_SUGGESTION_FULFILLED',
    FetchParamSuggestionFailure: 'FETCH_PARAM_SUGGESTION_REJECTED',
    ChangeTemplateParameterValue: 'CHANGE_TEMPLATE_PARAMETER_VALUE',
};

export interface SetTemplatesPayload {
    collection: ReportTemplateCollection;
}

const setTemplates = (templates: ReportTemplateCollection): ReactVapor.IReduxAction<SetTemplatesPayload> => ({
    type: ReportTemplateActionsType.SetTemplates,
    payload: {
        collection: templates,
    },
});

export interface SelectTemplatePayload {
    model: ReportTemplateModel;
}

const selectTemplate = (template: ReportTemplateModel): ReactVapor.IReduxAction<SelectTemplatePayload> => ({
    type: ReportTemplateActionsType.SelectTemplate,
    payload: {
        model: template,
    },
});

export interface ClearTemplateParametersPayload {
    // no payload
}

const clearParameters = (): ReactVapor.IReduxAction<ClearTemplateParametersPayload> => ({
    type: ReportTemplateActionsType.ClearTemplateParameters,
    payload: {},
});

export interface FetchParamSuggestionSuccessPayload {
    suggestions: string[];
    param: ReportTemplateParameter | ReportTemplateChildParameter;
}

export interface FetchParamSuggestionFailurePayload {
    error: string;
    param: ReportTemplateParameter | ReportTemplateChildParameter;
}

const doFetchSuggestions = (
    url: string,
    filter: string,
    param: ReportTemplateParameter | ReportTemplateChildParameter,
    dispatch: (...args: any[]) => void,
    isChild = false,
) => {
    // round the minute to the last multiple of 5 to hit the cache
    const end = moment();
    end.minutes(Math.floor(end.minutes() / 5) * 5)
        .seconds(0)
        .milliseconds(0);
    $.get(url, {
        from: moment().subtract(1, 'month').startOf('day').toISOString(),
        to: end.toISOString(),
        f: filter,
    }).done((data) => {
        const values = _.chain(data.values)
            .pluck(param.dimension)
            .reject((value: string) => value === null || value === '')
            .value();

        if (filter !== '' || values.length || isChild) {
            dispatch({
                type: ReportTemplateActionsType.FetchParamSuggestionSuccess,
                payload: {
                    suggestions: values,
                    param: param,
                },
            });
        } else {
            dispatch({
                type: ReportTemplateActionsType.FetchParamSuggestionFailure,
                payload: {
                    error: UALocales.format('Reports.Template.errorNoSuggestionsTitle'),
                    param: param,
                },
            });
        }
    });
};

const doFetchCustomDimensionSuggestions = (
    url: string,
    param: ReportTemplateParameter,
    dispatch: (...args: any[]) => void,
) => {
    $.get(url).done((data) => {
        const values = _.pluck(data, 'returnName');

        if (values.length) {
            dispatch({
                type: ReportTemplateActionsType.FetchParamSuggestionSuccess,
                payload: {
                    suggestions: values,
                    param: param,
                },
            });
        } else {
            dispatch({
                type: ReportTemplateActionsType.FetchParamSuggestionFailure,
                payload: {
                    error: UALocales.format('Reports.Template.errorNoSuggestionsTitle'),
                    param: param,
                },
            });
        }
    });
};

const fetchSuggestions = (param: ReportTemplateParameter) => (dispatch) => {
    const url = `${ConfigUtils.backendUrl}/dimensions/${param.dimension}/values`;
    doFetchSuggestions(url, param.filter ?? '', param, dispatch);
};

const fetchCustomDimensionSuggestions = (param: ReportTemplateParameter) => (dispatch) => {
    const url = `${ConfigUtils.backendUrl}/dimensions/custom`;
    doFetchCustomDimensionSuggestions(url, param, dispatch);
};

export interface ChangeTemplateParameterValuePayload {
    param: ReportTemplateParameter | ReportTemplateChildParameter;
    value: string | string[];
    parentId: string;
}

const changeTemplateParameterValue = (
    param: ReportTemplateParameter,
    value: string | string[],
    parentId = '',
): ReactVapor.IReduxAction<ChangeTemplateParameterValuePayload> => ({
    type: ReportTemplateActionsType.ChangeTemplateParameterValue,
    payload: {
        param,
        value,
        parentId,
    },
});

const fetchChildSuggestions = (param: ReportTemplateChildParameter, parentValue: string | string[]) => (dispatch) => {
    const url = `${ConfigUtils.backendUrl}/dimensions/${param.dimension}/values`;
    let filter = '';
    if (_.isArray(parentValue)) {
        filter = _.map(parentValue, (value: string) =>
            param.filter.replace(/\{\{(.+)}}/, (match, g) => parentValue.toString()),
        ).join(' OR ');
    } else {
        filter = param.filter.replace(/\{\{(.+)}}/, (match, g) => parentValue as string);
    }
    doFetchSuggestions(url, filter, param, dispatch, true);
};

export const ReportTemplateActions = {
    setTemplates,
    selectTemplate,
    clearParameters,
    fetchSuggestions,
    fetchCustomDimensionSuggestions,
    changeTemplateParameterValue,
    fetchChildSuggestions,
};
