const analyticsSettingsActionsType = {
    fetchV15AccountInfo: 'FETCH_V15_ACCOUNT_INFO',
    updateV15Account: 'UPDATE_V15_ACCOUNT',
};

const internalEventMultilineId = 'internalEventsId';

export const analyticsSettingsContants = {
    internalEventMultilineId,
    analyticsSettingsActionsType,
};
