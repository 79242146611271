import {Combobox, ComboboxItem, Group, InputBase, Tooltip, useCombobox, useTableContext} from '@components/mantine';
import {PrivilegeModel} from '@core/api';
import {FunctionComponent, useMemo} from 'react';
import {Locales} from '../../strings/Locales';
import {usePrivilegeRow} from './PrivilegeTableContext';
import {PrivilegeRow} from './PrivilegesTable.types';
import {PrivilegesTableUtils} from './PrivilegesTableUtils';
import {PrivilegesTableWarnings} from './PrivilegesTableWarnings';
import {LocalesKeys} from '../../generated/LocalesKeys';

interface AccessLevelOption extends ComboboxItem {
    /**
     * Retrieves the privileges that should be given when the access level option is selected.
     */
    toPrivileges: (
        owner: PrivilegeModel['owner'],
        targetDomain: PrivilegeModel['targetDomain'],
        level: PrivilegeModel['level'],
        systemPrivileges: PrivilegeModel[],
    ) => PrivilegeModel[];
    /**
     * The privileges that must be selected for this access level to be selected.
     */
    fromPrivileges: (privileges: PrivilegeModel[]) => boolean;
}

const forbidden: AccessLevelOption = {
    value: 'NONE',
    label: Locales.format('AccessLevelPicker.option.NONE'),
    toPrivileges: () => [],
    fromPrivileges: () => true,
};

const editViewNone: AccessLevelOption[] = [
    {
        value: 'EDIT',
        label: Locales.format('AccessLevelPicker.option.EDIT'),
        toPrivileges: (owner, targetDomain, level, systemPrivileges) => {
            const createPrivilegesExists = systemPrivileges.some(PrivilegesTableUtils.isCreatePrivilege);
            return createPrivilegesExists
                ? [
                      {owner, targetDomain, level, type: 'EDIT', targetId: '*'},
                      {owner, targetDomain, level, type: 'CREATE', targetId: '*'},
                      {owner, targetDomain, level, type: 'VIEW', targetId: '*'},
                  ]
                : [
                      {owner, targetDomain, level, type: 'EDIT', targetId: '*'},
                      {owner, targetDomain, level, type: 'VIEW', targetId: '*'},
                  ];
        },
        fromPrivileges: (privileges) => privileges.some(({type}) => type === 'EDIT'),
    },
    {
        value: 'VIEW',
        label: Locales.format('AccessLevelPicker.option.VIEW'),
        toPrivileges: (owner, targetDomain, level) => [{owner, targetDomain, level, type: 'VIEW', targetId: '*'}],
        fromPrivileges: (privileges) => privileges.some(({type}) => type === 'VIEW'),
    },
    forbidden,
];

const canCannot: AccessLevelOption[] = [
    {
        value: 'ALLOWED',
        label: Locales.format('AccessLevelPicker.option.ALLOWED'),
        toPrivileges: (owner, targetDomain, level) => [{owner, targetDomain, level, targetId: '*'}],
        fromPrivileges: (privileges) =>
            privileges.some(({owner, targetDomain, targetId}) => owner && targetDomain && targetId === '*'),
    },
    forbidden,
];

const createOrNone: AccessLevelOption[] = [
    {
        value: 'CREATE',
        label: Locales.format('AccessLevelPicker.option.CREATE'),
        toPrivileges: (owner, targetDomain, level) => [{owner, targetDomain, level, type: 'CREATE', targetId: '*'}],
        fromPrivileges: (privileges) => privileges.some(({type}) => type === 'CREATE'),
    },
    forbidden,
];

const pushAndView: AccessLevelOption[] = [
    {
        value: 'PUSH_AND_VIEW',
        label: Locales.format('AccessLevelPicker.option.PUSH_AND_VIEW'),
        toPrivileges: (owner, targetDomain, level) => [
            {owner, targetDomain, level, type: 'EDIT', targetId: '*'},
            {owner, targetDomain, level, type: 'VIEW', targetId: '*'},
        ],
        fromPrivileges: (privileges) =>
            privileges.some(({type}) => type === 'EDIT') && privileges.some(({type}) => type === 'VIEW'),
    },
    {
        value: 'VIEW',
        label: Locales.format('AccessLevelPicker.option.VIEW'),
        toPrivileges: (owner, targetDomain, level) => [{owner, targetDomain, level, type: 'VIEW', targetId: '*'}],
        fromPrivileges: (privileges) => privileges.some(({type}) => type === 'VIEW'),
    },
    {
        value: 'PUSH',
        label: Locales.format('AccessLevelPicker.option.PUSH'),
        toPrivileges: (owner, targetDomain, level) => [{owner, targetDomain, level, type: 'EDIT', targetId: '*'}],
        fromPrivileges: (privileges) => privileges.some(({type}) => type === 'EDIT'),
    },
    forbidden,
];

const granularEdit: AccessLevelOption[] = [
    {
        value: 'EDIT_ALL',
        label: Locales.format('AccessLevelPicker.option.EDIT_ALL'),
        toPrivileges: (owner, targetDomain, level) => [
            {owner, targetDomain, level, type: 'EDIT', targetId: '*'},
            {owner, targetDomain, level, type: 'CREATE', targetId: '*'},
            {owner, targetDomain, level, type: 'VIEW', targetId: '*'},
        ],
        fromPrivileges: (privileges) => privileges.some(({type, targetId}) => type === 'EDIT' && targetId === '*'),
    },
    {
        value: 'CUSTOM',
        label: Locales.format('AccessLevelPicker.option.CUSTOM'),
        toPrivileges: (owner, targetDomain, level) => [{owner, targetDomain, level, type: 'VIEW', targetId: '*'}],
        fromPrivileges: (privileges) => privileges.some(({type}) => type === 'EDIT'),
    },
    {
        value: 'VIEW_ALL',
        label: Locales.format('AccessLevelPicker.option.VIEW_ALL'),
        toPrivileges: (owner, targetDomain, level) => [{owner, targetDomain, level, type: 'VIEW', targetId: '*'}],
        fromPrivileges: (privileges) => privileges.some(({type}) => type === 'VIEW'),
    },
    forbidden,
];

const granularAllow = (allowString: string): AccessLevelOption[] => [
    {
        value: allowString,
        label: Locales.format(`AccessLevelPicker.option.${allowString}` as LocalesKeys),
        toPrivileges: (owner, targetDomain, level) => [{owner, targetDomain, level, targetId: '*'}],
        fromPrivileges: (privileges) => privileges.some(({targetId}) => targetId === '*'),
    },
    {
        value: 'CUSTOM',
        label: Locales.format('AccessLevelPicker.option.CUSTOM'),
        toPrivileges: () => [],
        fromPrivileges: (privileges) => privileges.some(({targetId}) => !!targetId),
    },
    forbidden,
];

const getBaseOptionsForPrivilegeId = (privilegeId: string): AccessLevelOption[] => {
    if (PrivilegesTableUtils.isGranularPrivilegeId(privilegeId)) {
        switch (privilegeId) {
            case 'PUSH_IDENTITY_PLATFORM':
                return granularAllow('ALLOW_ALL_PROVIDERS');
            case 'PUSH_DOCUMENT_PLATFORM':
                return granularAllow('ALLOW_FOR_ALL_SOURCES');
            default:
                return granularEdit;
        }
    }
    switch (privilegeId) {
        case 'ADMINISTRATE_USAGE_ANALYTICS':
        case 'IMPERSONATE_USAGE_ANALYTICS':
        case 'QUERY_SUGGEST_USAGE_ANALYTICS':
        case 'VIEW_ALL_REPORTS_USAGE_ANALYTICS':
        case 'DELETE_USER_ANALYTICS_DATA_USAGE_ANALYTICS':
        case 'IMPERSONATE_SEARCH_API':
        case 'EXECUTE_QUERY_SEARCH_API':
        case 'VIEW_ALL_CONTENT_SEARCH_API':
        case 'AUTHENTICATION_EDITOR_SEARCH_API':
        case 'USE_CASE_ASSIST_CUSTOMER_SERVICE':
        case 'ALLOW_CONTENT_PREVIEW_COVEO_ML':
        case 'MANAGE_CUSTOMER_LICENSE_PLATFORM_INTERNAL':
        case 'MANAGE_CUSTOMER_LICENSE_OVERRIDES_PLATFORM_INTERNAL':
        case 'KNOWLEDGE_HUB_KNOWLEDGE':
        case 'CHUNK_INSPECTOR_KNOWLEDGE':
            return canCannot;
        case 'ANALYTICS_DATA_USAGE_ANALYTICS':
            return pushAndView;
        case 'ORGANIZATION_INFRASTRUCTURE_PLATFORM_GLOBAL':
            return createOrNone;
        default:
            return editViewNone;
    }
};

const getOptions = (
    privilegeId: string,
    availablePrivileges: PrivilegeModel[],
    systemPrivileges: PrivilegeModel[],
): AccessLevelOption[] =>
    getBaseOptionsForPrivilegeId(privilegeId)
        .filter((option) => option.fromPrivileges(systemPrivileges))
        .map((option) => ({
            ...option,
            disabled: !option.fromPrivileges(availablePrivileges),
        }));

export const AccessLevelPicker: FunctionComponent<PrivilegeRow> = (row) => {
    const {onChange, selectedPrivileges, customSelected, selectCustom, readOnly} = usePrivilegeRow(row.id);
    const {store: table} = useTableContext();
    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
    });
    const data = useMemo(
        () => getOptions(row.id, row.availablePrivileges, row.systemPrivileges),
        [row.id, row.availablePrivileges, row.systemPrivileges],
    );
    const selectedOption = data.find((option) =>
        customSelected ? option.value === 'CUSTOM' : option.fromPrivileges(selectedPrivileges),
    );

    const options = data.map((option) => (
        <Tooltip
            label={
                option.value === 'CUSTOM'
                    ? Locales.format('AccessLevelPicker.disabledCustom', {
                          name: row.name,
                          resourceName: Locales.formatOrHumanize(`PrivilegesRows.${row.targetDomain}.granular`, {
                              defaultTranslation: row.name,
                          }),
                      })
                    : Locales.format('AccessLevelPicker.disabledOption', {
                          accessLevel: option.label,
                          name: row.name,
                      })
            }
            disabled={!option.disabled}
            key={option.value}
        >
            <Combobox.Option value={option.value} disabled={option.disabled}>
                {option.value === 'NONE' ? Locales.format('AccessLevelPicker.option.noAccess') : option.label}
            </Combobox.Option>
        </Tooltip>
    ));

    return (
        <Group wrap="nowrap" gap="xs">
            <Combobox
                store={combobox}
                readOnly={readOnly}
                onOptionSubmit={(value) => {
                    onChange(
                        data
                            .find((option) => option.value === value)
                            .toPrivileges(row.owner, row.targetDomain, row.level, row.systemPrivileges)
                            .map(PrivilegesTableUtils.removeUndefinedProperties),
                    );
                    if (value === 'CUSTOM') {
                        selectCustom(true);
                        table.setExpanded({[row.id]: true});
                    } else {
                        selectCustom(false);
                    }
                    combobox.closeDropdown();
                }}
            >
                <Combobox.Target>
                    <InputBase
                        pointer
                        rightSection={<Combobox.Chevron />}
                        rightSectionPointerEvents="none"
                        w={178}
                        readOnly={readOnly}
                        onClick={() => {
                            if (!readOnly) {
                                combobox.toggleDropdown();
                            }
                        }}
                        value={selectedOption.label}
                        onChange={() => {
                            combobox.openDropdown();
                        }}
                    />
                </Combobox.Target>
                <Combobox.Dropdown>
                    <Combobox.Options>{options}</Combobox.Options>
                </Combobox.Dropdown>
            </Combobox>
            <PrivilegesTableWarnings {...row} />
        </Group>
    );
};
