import {deepClone} from '@coveord/jsadmin-common';
import {IDropdownOption} from '@coveord/plasma-react';
import {Locales} from '../../../../../../Locales';
import {SfObjectFieldTypes} from '../../../fields/SfObjectFieldModel';

export const SfObjectConditionOperators = {
    EQUALS: 'EQUALS',
    NOT_EQUALS: 'NOT_EQUALS',
    LESS_THAN: 'LESS_THAN',
    LESS_OR_EQUAL_THAN: 'LESS_OR_EQUAL_THAN',
    GREATER_THAN: 'GREATER_THAN',
    GREATER_OR_EQUAL_THAN: 'GREATER_OR_EQUAL_THAN',
    LIKE: 'LIKE',
    NOT_LIKE: 'NOT_LIKE',
    INCLUDES: 'INCLUDES',
    EXCLUDES: 'EXCLUDES',
    IN: 'IN',
    NOT_IN: 'NOT_IN',
};

export const SfObjectConditionOperatorsDisplayValues = {
    EQUALS: Locales.format('conditionOperator_EQUALS'),
    NOT_EQUALS: Locales.format('conditionOperator_NOT_EQUALS'),
    LESS_THAN: Locales.format('conditionOperator_LESS_THAN'),
    LESS_OR_EQUAL_THAN: Locales.format('conditionOperator_LESS_OR_EQUAL_THAN'),
    GREATER_THAN: Locales.format('conditionOperator_GREATER_THAN'),
    GREATER_OR_EQUAL_THAN: Locales.format('conditionOperator_GREATER_OR_EQUAL_THAN'),
    LIKE: Locales.format('conditionOperator_LIKE'),
    NOT_LIKE: Locales.format('conditionOperator_NOT_LIKE'),
    INCLUDES: Locales.format('conditionOperator_INCLUDES'),
    EXCLUDES: Locales.format('conditionOperator_EXCLUDES'),
    IN: Locales.format('conditionOperator_IN'),
    NOT_IN: Locales.format('conditionOperator_NOT_IN'),
};

export const mapSfObjectFieldTypesToSpecialOperatorOptions = (fieldType: string): IDropdownOption[] => {
    const isBoolean = fieldType === SfObjectFieldTypes.Boolean || fieldType === SfObjectFieldTypes.Boolean2;
    const isMultiPicklist = fieldType === SfObjectFieldTypes.MultiPicklist;

    return deepClone([
        {
            value: SfObjectConditionOperators.EQUALS,
            displayValue: SfObjectConditionOperatorsDisplayValues.EQUALS,
            selected: false,
            custom: false,
        },
        {
            value: SfObjectConditionOperators.NOT_EQUALS,
            displayValue: SfObjectConditionOperatorsDisplayValues.NOT_EQUALS,
            selected: false,
            custom: false,
        },
        {
            value: SfObjectConditionOperators.LESS_THAN,
            displayValue: SfObjectConditionOperatorsDisplayValues.LESS_THAN,
            selected: false,
            custom: false,
            hidden: isBoolean || isMultiPicklist,
        },
        {
            value: SfObjectConditionOperators.LESS_OR_EQUAL_THAN,
            displayValue: SfObjectConditionOperatorsDisplayValues.LESS_OR_EQUAL_THAN,
            selected: false,
            custom: false,
            hidden: isBoolean || isMultiPicklist,
        },
        {
            value: SfObjectConditionOperators.GREATER_THAN,
            displayValue: SfObjectConditionOperatorsDisplayValues.GREATER_THAN,
            selected: false,
            custom: false,
            hidden: isBoolean || isMultiPicklist,
        },
        {
            value: SfObjectConditionOperators.GREATER_OR_EQUAL_THAN,
            displayValue: SfObjectConditionOperatorsDisplayValues.GREATER_OR_EQUAL_THAN,
            selected: false,
            custom: false,
            hidden: isBoolean || isMultiPicklist,
        },
        {
            value: SfObjectConditionOperators.LIKE,
            displayValue: SfObjectConditionOperatorsDisplayValues.LIKE,
            selected: false,
            custom: false,
            hidden: isBoolean || isMultiPicklist,
        },
        {
            value: SfObjectConditionOperators.NOT_LIKE,
            displayValue: SfObjectConditionOperatorsDisplayValues.NOT_LIKE,
            selected: false,
            custom: false,
            hidden: isBoolean || isMultiPicklist,
        },
        {
            value: SfObjectConditionOperators.IN,
            displayValue: SfObjectConditionOperatorsDisplayValues.IN,
            selected: false,
            custom: false,
            hidden: isBoolean || isMultiPicklist,
        },
        {
            value: SfObjectConditionOperators.NOT_IN,
            displayValue: SfObjectConditionOperatorsDisplayValues.NOT_IN,
            selected: false,
            custom: false,
            hidden: isBoolean || isMultiPicklist,
        },
        {
            value: SfObjectConditionOperators.INCLUDES,
            displayValue: SfObjectConditionOperatorsDisplayValues.INCLUDES,
            selected: false,
            custom: false,
            hidden: !isMultiPicklist,
        },
        {
            value: SfObjectConditionOperators.EXCLUDES,
            displayValue: SfObjectConditionOperatorsDisplayValues.EXCLUDES,
            selected: false,
            custom: false,
            hidden: !isMultiPicklist,
        },
    ]);
};
