import {GenericError, OrganizationModel, Platform, useQuery, UseQueryResult} from '@core/api';
import {sanitizeOrganizationId} from '@core/configuration';
import Registry from '@core/registry';
import {createContext, useContext} from 'react';
import {OrganizationLocalStorage} from '../OrganizationLocalStorage';

export const OrganizationContext = createContext<{
    query: UseQueryResult<OrganizationModel | null, GenericError> | undefined;
}>({
    query: undefined,
});

export const useOrganization = (): OrganizationModel => {
    const context = useContext(OrganizationContext);
    if (!context || !context.query) {
        throw new Error('useOrganization must be used within an OrganizationProvider');
    }

    if (!context.query.data) {
        throw new Error('Organization data is not available');
    }

    return context.query.data;
};

const fetchOrganizationModel = async (currentOrg: string | null): Promise<OrganizationModel | null> => {
    try {
        if (!currentOrg || currentOrg === 'unable' || currentOrg === 'invites') {
            throw new Error('Not a real organization, pick the first org instead');
        }
        const organization = await Platform.organization.get(currentOrg!);
        return organization;
    } catch (error) {
        const list = await Platform.organization.list({page: 0, perPage: 1, sortBy: 'displayName'});
        return list.items?.[0] ?? null;
    }
};
const EXTRACT_ORG_ID_FROM_URL_HASH = /#\/(\w+)/;

/**
 * Provides the organization context to its children.
 *
 * Constraint:
 * * has to be within context of the QueryClientProvider.
 */
export const OrganizationProvider = ({children}) => {
    const currentOrg =
        sanitizeOrganizationId(EXTRACT_ORG_ID_FROM_URL_HASH.exec(window.location.hash)?.[1]) ||
        OrganizationLocalStorage.getOrganizationFromLocalStorage() ||
        null;

    // fetch the organization from the URL or the store if it's available.
    const query = useQuery<OrganizationModel | null, GenericError>({
        queryKey: ['init', 'organization', currentOrg],
        queryFn: async () => {
            const organization = await fetchOrganizationModel(currentOrg);
            OrganizationLocalStorage.setOrganizationInLocalStorage(organization?.id ?? '');
            Registry.register('organization_id', organization?.id ?? '');
            Registry.register('organization', organization);
            return organization;
        },
        retry: false,
        initialData: Registry.get('organization', {optional: true}),
        staleTime: Infinity,
    });

    return <OrganizationContext.Provider value={{query}}>{children}</OrganizationContext.Provider>;
};
