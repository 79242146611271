import {notifications as mantineNotifications} from '@components/mantine';
import {EditSubscriptionModel, Platform, SubscriptionModel} from '@core/api';
import {RequestsActions} from '@coveord/jsadmin-common';
import {BasePayload, IReduxAction, IThunkAction} from '@coveord/plasma-react';

import {Locales} from '../Locales';
import {BaseSourceModelAttributes} from '../sources/sources/BaseSourceModel';

export interface NotificationsPayload extends BasePayload {
    notifications: SubscriptionModel[];
}

export interface NotificationPayload extends BasePayload {
    notificationId: string;
}

export interface SubscriptionPayload extends BasePayload {
    source: BaseSourceModelAttributes;
}

export const NotificationsActionTypes = {
    setList: 'SET_NOTIFICATIONS_LIST',
    showDetails: 'SHOW_NOTIFICATION_DETAILS',
    showStats: 'SHOW_NOTIFICATION_STATS',
    setSourceSubscription: 'SET_SOURCE_FOR_SUBSCRIPTION',
    remove: 'REMOVE_NOTIFICATIONS_LIST',
    clearOpened: 'CLEAR_OPENED_NOTIFICATION',
};

export const NotificationsRequests = {
    fetch: 'FETCH_NOTIFICATIONS',
    show: 'SHOW_NOTIFICATION',
    create: 'CREATE_NOTIFICATION',
    edit: 'SAVE_NOTIFICATION',
    delete: 'DELETE_NOTIFICATION',
    toggleEnabled: 'TOGGLE_NOTIFICATION',
    test: 'TEST_NOTIFICATION',
};

const setList = (id: string, notifications: SubscriptionModel[]): IReduxAction<NotificationsPayload> => ({
    type: NotificationsActionTypes.setList,
    payload: {
        id,
        notifications,
    },
});

const showStats = (id: string, notificationId: string): IReduxAction<NotificationPayload> => ({
    type: NotificationsActionTypes.showStats,
    payload: {
        id,
        notificationId,
    },
});

const showDetails = (id: string, notificationId: string): IReduxAction<NotificationPayload> => ({
    type: NotificationsActionTypes.showDetails,
    payload: {
        id,
        notificationId,
    },
});

const setSourceSubscription = (id: string, source: BaseSourceModelAttributes): IReduxAction<SubscriptionPayload> => ({
    type: NotificationsActionTypes.setSourceSubscription,
    payload: {
        id,
        source,
    },
});

const remove = (id: string): IReduxAction<BasePayload> => ({
    type: NotificationsActionTypes.remove,
    payload: {
        id,
    },
});

const clearOpened = (id: string): IReduxAction<BasePayload> => ({
    type: NotificationsActionTypes.clearOpened,
    payload: {
        id,
    },
});

const list =
    (isOrgWide: boolean, listId?: string): IThunkAction<Promise<SubscriptionModel[]>> =>
    (dispatch) => {
        const fetchAllNotifications = async () => {
            const notifications = isOrgWide
                ? await Platform.notification.subscription.list()
                : await Platform.notification.subscription.listCurrent();

            if (listId) {
                dispatch(setList(listId, notifications));
            }
            return notifications;
        };

        return dispatch(
            RequestsActions.handle(
                listId ? `${NotificationsRequests.fetch}_${listId}` : NotificationsRequests.fetch,
                fetchAllNotifications,
            ),
        );
    };

const show =
    (
        isOrgWide: boolean,
        notificationId: string,
    ): IThunkAction<ReturnType<typeof Platform.notification.subscription.show>> =>
    (dispatch) => {
        const fetchNotification = async () => {
            const notification = isOrgWide
                ? await Platform.notification.subscription.show(notificationId)
                : await Platform.notification.subscription.showCurrent(notificationId);
            return notification;
        };

        return dispatch(RequestsActions.handle(NotificationsRequests.show, fetchNotification));
    };

const create =
    (isOrgWide: boolean, notificationModel: EditSubscriptionModel): IThunkAction<Promise<SubscriptionModel | void>> =>
    (dispatch) => {
        const createNotification = async () => {
            const notification = isOrgWide
                ? await Platform.notification.subscription.create(notificationModel)
                : await Platform.notification.subscription.createCurrent(notificationModel);

            mantineNotifications.showSuccess(Locales.format('notificationAddedSuccessfully'));
            return notification;
        };

        return dispatch(RequestsActions.handle(NotificationsRequests.create, createNotification));
    };

const edit =
    (isOrgWide: boolean, notificationModel: SubscriptionModel): IThunkAction<Promise<SubscriptionModel | void>> =>
    async (dispatch) => {
        const editNotification = async () => {
            const notification = isOrgWide
                ? await Platform.notification.subscription.edit(notificationModel.id, notificationModel)
                : await Platform.notification.subscription.editCurrent(notificationModel.id, notificationModel);

            mantineNotifications.showSuccess(Locales.format('notificationSavedSuccessfully'));
            return notification;
        };

        return dispatch(RequestsActions.handle(NotificationsRequests.edit, editNotification));
    };

const deleteNotification =
    (isOrgWide: boolean, notificationId: string): IThunkAction<Promise<void>> =>
    (dispatch) => {
        const deleteCall = async () => {
            isOrgWide
                ? await Platform.notification.subscription.delete(notificationId)
                : await Platform.notification.subscription.deleteCurrent(notificationId);

            mantineNotifications.showSuccess(Locales.format('notificationUnsubscribedSuccesfully'));
        };

        return dispatch(RequestsActions.handle(NotificationsRequests.delete, deleteCall));
    };

const toggle =
    (isOrgWide: boolean, notificationModel: SubscriptionModel & EditSubscriptionModel): IThunkAction<Promise<void>> =>
    (dispatch) => {
        const toggleState = notificationModel.enabled ? 'disable' : 'enable';

        const toggleNotification = async () => {
            if (toggleState === 'enable') {
                isOrgWide
                    ? await Platform.notification.subscription.enable(notificationModel.id)
                    : await Platform.notification.subscription.enableCurrent(notificationModel.id);
            } else {
                isOrgWide
                    ? await Platform.notification.subscription.disable(notificationModel.id)
                    : await Platform.notification.subscription.disableCurrent(notificationModel.id);
            }
        };

        return dispatch(RequestsActions.handle(NotificationsRequests.toggleEnabled, toggleNotification));
    };

const test =
    (isOrgWide: boolean, notificationId: string): IThunkAction<Promise<void>> =>
    (dispatch) => {
        const testNotification = async () => {
            isOrgWide
                ? await Platform.notification.subscription.test(notificationId)
                : await Platform.notification.subscription.testCurrent(notificationId);

            mantineNotifications.showSuccess(Locales.format('notificationTestSuccess'));
        };

        return dispatch(RequestsActions.handle(NotificationsRequests.test, testNotification));
    };

export const NotificationsActions = {
    show,
    setList,
    showDetails,
    showStats,
    setSourceSubscription,
    remove,
    clearOpened,
    delete: deleteNotification,
    toggle,
    test,
    create,
    edit,
    list,
};
