import {IReduxAction} from '@coveord/plasma-react';
import _ from 'underscore';
import {SetPayload, UpdatePayload} from '../actions';
import {Requests} from '../constants';

export interface SearchHubState {
    searchHub: string;
    assignment: string;
    normalQueries: number;
    recommendationQueries: number;
    staticQueries: number;
    users: number;
    productListingQueries: number;
}

export interface SearchHubsState {
    [id: string]: SearchHubState;
}

const set = (state: SearchHubsState, action: IReduxAction<SetPayload>) => _.indexBy(action.payload.hubs, 'searchHub');

const update = (state: SearchHubsState, action: IReduxAction<UpdatePayload>): SearchHubsState => ({
    ...state,
    [action.payload.hub.searchHub]: {
        ...state[action.payload.hub.searchHub],
        ...action.payload.hub,
    },
});

const Reducers: {[key: string]: (...args: any[]) => SearchHubsState} = {
    [Requests.set]: set,
    [Requests.update]: update,
    [Requests.clear]: () => ({}), // return empty object
};

export const searchHubReducers = (state: SearchHubsState = {}, action: IReduxAction<SetPayload>): SearchHubsState => {
    if (!_.isUndefined(Reducers[action.type])) {
        return Reducers[action.type](state, action);
    }

    return state;
};
