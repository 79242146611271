import {CommonActions, IReduxAction} from '@coveord/plasma-react';

import {ILogBrowserActionsPayload} from '../LogBrowserStore';
import {SourcesActions} from './LogsSourcesActions';

export interface ISourcesState {
    [key: string]: string;
}

export const sourcesInitialState = {};

export const sourcesReducer = (
    state: ISourcesState = sourcesInitialState,
    action: IReduxAction<ILogBrowserActionsPayload>,
): ISourcesState => {
    switch (action.type) {
        case SourcesActions.update:
            return action.payload.sources;
        case CommonActions.clearState:
            return sourcesInitialState;
        default:
            return state;
    }
};
