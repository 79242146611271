import _ from 'underscore';
import {
    AllowedIdentityEditionActionsType,
    IAllowedIdentityEditionAction,
} from '../actions/AllowedIdentityEditionActions';
import {AllowedIdentityAttributes, defaultAllowedIdentityAttributes} from '../models/AllowedIdentityModel';

export interface AllowedIdentityEditionState {
    appliedState: AllowedIdentityAttributes;
    editionState: AllowedIdentityAttributes;
    id: string;
    isOpen: boolean;
}

export const defaulAllowedIdentityEditionState: AllowedIdentityEditionState = {
    appliedState: null,
    editionState: _.extend({}, defaultAllowedIdentityAttributes),
    id: null,
    isOpen: false,
};

const applyChangesReducer = (
    state: AllowedIdentityEditionState,
    action: IAllowedIdentityEditionAction,
): AllowedIdentityEditionState =>
    action.payload.id !== state.id
        ? state
        : {
              id: state.id,
              editionState: state.editionState,
              appliedState: _.extend({}, state.editionState),
              isOpen: false,
          };

const cancelChangesReducer = (
    state: AllowedIdentityEditionState,
    action: IAllowedIdentityEditionAction,
): AllowedIdentityEditionState =>
    action.payload.id !== state.id
        ? state
        : {
              id: state.id,
              appliedState: state.appliedState,
              editionState: _.extend(
                  {},
                  _.isNull(state.appliedState) ? defaultAllowedIdentityAttributes : state.appliedState,
              ),
              isOpen: false,
          };

const changeAdditinalInfoReducer = (
    state: AllowedIdentityEditionState,
    action: IAllowedIdentityEditionAction,
): AllowedIdentityEditionState =>
    action.payload.id !== state.id
        ? state
        : {
              id: state.id,
              appliedState: state.appliedState,
              editionState: _.extend({}, state.editionState, {
                  additionalInfo: action.payload.additionalInfo,
              }),
              isOpen: state.isOpen,
          };

const changeIdentityReducer = (
    state: AllowedIdentityEditionState,
    action: IAllowedIdentityEditionAction,
): AllowedIdentityEditionState =>
    action.payload.id !== state.id
        ? state
        : {
              id: state.id,
              appliedState: state.appliedState,
              editionState: _.extend({}, state.editionState, {
                  identity: action.payload.identity,
              }),
              isOpen: state.isOpen,
          };

const changeIdentityTypeReducer = (
    state: AllowedIdentityEditionState,
    action: IAllowedIdentityEditionAction,
): AllowedIdentityEditionState =>
    action.payload.id !== state.id
        ? state
        : {
              id: state.id,
              appliedState: state.appliedState,
              editionState: _.extend({}, state.editionState, {
                  identityType: action.payload.identityType,
              }),
              isOpen: state.isOpen,
          };

const changeSecurityProviderReducer = (
    state: AllowedIdentityEditionState,
    action: IAllowedIdentityEditionAction,
): AllowedIdentityEditionState =>
    action.payload.id !== state.id
        ? state
        : {
              id: state.id,
              appliedState: state.appliedState,
              editionState: _.extend({}, state.editionState, {
                  securityProvider: action.payload.securityProvider,
              }),
              isOpen: state.isOpen,
          };

const toggleOpenReducer = (
    state: AllowedIdentityEditionState,
    action: IAllowedIdentityEditionAction,
): AllowedIdentityEditionState =>
    action.payload.id !== state.id
        ? state
        : {
              id: state.id,
              appliedState: state.appliedState,
              editionState: state.editionState,
              isOpen: action.payload.isOpen,
          };

interface AllowedIdentityEditionActionsReducers {
    [key: string]: (
        state: AllowedIdentityEditionState,
        action: IAllowedIdentityEditionAction,
    ) => AllowedIdentityEditionState;
}

const allowedIdentityEditionActionsReducers: AllowedIdentityEditionActionsReducers = {
    [AllowedIdentityEditionActionsType.ApplyChanges]: applyChangesReducer,
    [AllowedIdentityEditionActionsType.CancelChanges]: cancelChangesReducer,
    [AllowedIdentityEditionActionsType.ChangeAdditinalInfo]: changeAdditinalInfoReducer,
    [AllowedIdentityEditionActionsType.ChangeIdentity]: changeIdentityReducer,
    [AllowedIdentityEditionActionsType.ChangeIdentityType]: changeIdentityTypeReducer,
    [AllowedIdentityEditionActionsType.ChangeSecurityProvider]: changeSecurityProviderReducer,
    [AllowedIdentityEditionActionsType.ToggleOpen]: toggleOpenReducer,
};

export const allowedIdentityEditionReducers = (
    state: AllowedIdentityEditionState = defaulAllowedIdentityEditionState,
    action: IAllowedIdentityEditionAction,
): AllowedIdentityEditionState =>
    _.isUndefined(allowedIdentityEditionActionsReducers[action.type])
        ? state
        : allowedIdentityEditionActionsReducers[action.type](state, action);
