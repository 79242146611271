import {KeyValueReducerGenerator} from '@core/store';
import {combineReducers} from 'redux';
import {SearchPagesActionsTypes} from './SearchPagesActions';
import {SearchPage, SearchPageEditorState} from './models/SearchPageModel';
import {SearchPageVersion} from './models/SearchPageVersion';

export const searchPageEditorReducer = combineReducers<SearchPageEditorState>({
    activeSearchPage: KeyValueReducerGenerator<SearchPage>(SearchPagesActionsTypes.setActiveSearchPage),
    currrentVersion: KeyValueReducerGenerator<SearchPageVersion>(SearchPagesActionsTypes.setCurrentVersion),
    availableVersions: KeyValueReducerGenerator<SearchPageVersion[]>(SearchPagesActionsTypes.setAvailableVersions),
});
