import {RouteObject} from '@core/routes';
import {ServicePathsMap, baseServicePath} from './RoutesDefinition';

export const serviceRoutes: RouteObject[] = [
    {
        path: `/:orgId/${baseServicePath}${ServicePathsMap.editCaseAssistConfig}/*`,
        lazy: async () => {
            const {EditCaseAssistPage} = await import('../caseAssist/EditCaseAssistPage');
            return {Component: EditCaseAssistPage};
        },
    },
    {
        path: `/:orgId/${baseServicePath}${ServicePathsMap.caseAssistConfigs}/*`,
        lazy: async () => {
            const {CaseAssistPage} = await import('../caseAssist/CaseAssistPage');
            return {Component: CaseAssistPage};
        },
    },
    {
        path: `/:orgId/${baseServicePath}${ServicePathsMap.editInsightPanelConfig}/*`,
        lazy: async () => {
            const {EditInsightPanelPage} = await import('../insightPanel/EditInsightPanelPage');
            return {Component: EditInsightPanelPage};
        },
    },
    {
        path: `/:orgId/${baseServicePath}${ServicePathsMap.insightPanelConfigs}/*`,
        lazy: async () => {
            const {InsightPanelPage} = await import('../insightPanel/InsightPanelPage');
            return {Component: InsightPanelPage};
        },
    },
];
