import {Component} from 'react';
import {
    addDatePicker,
    applyDatePicker,
    CalendarSelectionRuleType,
    changeDatePickerLowerLimit,
    DatePickerBox /* eslint-disable-line import/no-deprecated */,
    DatePickerDropdownConnected /* eslint-disable-line import/no-deprecated */,
    IDatePickerState,
    IDispatch,
    ReduxConnect /* eslint-disable-line import/no-deprecated */,
} from '@coveord/plasma-react';

import {AdminState} from '../../application/Reducers';

export interface OrganizationSettingsLicenseDatePickerOwnProps {
    currentDate: Date;
    onChange: (date: Date) => void;
    readonly?: boolean;
}

export interface OrganizationSettingsLicenseDatePickerStateProps {
    dateSelected?: Date;
}

export interface OrganizationSettingsLicenseDatePickerDispatchProps {
    onMount?: () => void;
    onMounted?: () => void;
}

export interface OrganizationSettingsLicenseDatePickerProps
    extends OrganizationSettingsLicenseDatePickerOwnProps,
        OrganizationSettingsLicenseDatePickerStateProps,
        OrganizationSettingsLicenseDatePickerDispatchProps {}

const mapStateToProps = (state: AdminState): OrganizationSettingsLicenseDatePickerStateProps => {
    const datePicker: IDatePickerState = _.findWhere(state.datePickers, {id: OrganizationSettingsLicenseDatePicker.Id});
    return {
        dateSelected: datePicker && datePicker.appliedLowerLimit,
    };
};

const mapDispatchToProps = (
    dispatch: IDispatch,
    ownProps: OrganizationSettingsLicenseDatePickerOwnProps,
): OrganizationSettingsLicenseDatePickerDispatchProps => ({
    // We need to manually add the date picker because we are not showing the right side with the date selection
    onMount: () =>
        dispatch(
            addDatePicker(
                OrganizationSettingsLicenseDatePicker.Id,
                false,
                undefined,
                // eslint-disable-next-line import/no-deprecated
                DatePickerBox.getCalendarId(OrganizationSettingsLicenseDatePicker.Id),
                false,
                false,
                true,
            ),
        ),
    onMounted: () => {
        if (ownProps.currentDate) {
            dispatch(changeDatePickerLowerLimit(OrganizationSettingsLicenseDatePicker.Id, ownProps.currentDate));
            dispatch(applyDatePicker(OrganizationSettingsLicenseDatePicker.Id));
        }
    },
});

@ReduxConnect(mapStateToProps, mapDispatchToProps)
export class OrganizationSettingsLicenseDatePicker extends Component<OrganizationSettingsLicenseDatePickerProps> {
    static Id: string = 'license-date-picker';

    UNSAFE_componentWillMount() {
        this.props.onMount();
    }

    componentDidMount() {
        this.props.onMounted();
    }

    UNSAFE_componentWillReceiveProps(nextProps: OrganizationSettingsLicenseDatePickerProps) {
        if (this.props.dateSelected !== nextProps.dateSelected && nextProps.dateSelected !== this.props.currentDate) {
            this.props.onChange(nextProps.dateSelected);
        }
    }

    render() {
        return (
            <DatePickerDropdownConnected
                datesSelectionBoxes={[]}
                id={OrganizationSettingsLicenseDatePicker.Id}
                selectionRules={[
                    {
                        test: (date: Date) => date >= new Date(),
                        isFor: CalendarSelectionRuleType.all,
                    },
                ]}
                readonly={this.props.readonly}
                simple
            />
        );
    }
}
