export interface DashboardState {
    isEditing: boolean;
    title: string;
    id: string;
    tab: string;
    tabNumberOfCards: number;
    dashboardNumberOfCards: number;
}

export const DashboardDefaultState: DashboardState = {
    isEditing: false,
    title: '',
    id: '',
    tab: '',
    tabNumberOfCards: 0,
    dashboardNumberOfCards: 0,
};
