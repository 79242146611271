import {MLAssociationModel, MLModel} from '@core/api';
import {CheckboxSelectors, RadioSelectSelectors, SliderSelectors} from '@coveord/plasma-react';
import {createSelector, createStructuredSelector} from 'reselect';
import _ from 'underscore';

import {SelectConditionSelectors} from '@components/conditions';
import {IActiveQueryPipelineState, SearchOptimizationState} from '../../../SearchOptimizationState';
import {
    AssociationModelTypes,
    AssociationModelTypesRestrictionRules,
    AssociationsConstants,
    EditAssociationsIds,
} from './AssociationsConstants';
import {filterAssociationModels} from './AssociationsUtils';

const getState = (state: SearchOptimizationState): SearchOptimizationState => state;

const getModels = (state: SearchOptimizationState): MLModel[] =>
    filterAssociationModels(state.editAssociation?.models ?? []);

const getAllModels = createSelector(getModels, (models): MLModel[] => models);

const getIndexedModels = createSelector(getModels, (models): Record<string, MLModel> => _.indexBy(models, 'id'));

const getRules = (state: SearchOptimizationState): MLAssociationModel[] => state.associations?.rules ?? null;

const getAssociationCount = (state: SearchOptimizationState): number => state.associations?.totalEntries ?? null;

const getSelectedModelId = (state: SearchOptimizationState): string => state.editAssociation?.selectedModelId || null;

const getSelectedConditionId = (state: SearchOptimizationState): string =>
    SelectConditionSelectors.getSelectedId(state, EditAssociationsIds.SelectCondition);

const getRulesEngineId = createSelector(
    getIndexedModels,
    getRules,
    (modelsByEngineId: Record<string, MLModel>, rules: MLAssociationModel[]): string[] =>
        _.compact(rules?.map((rule) => modelsByEngineId[rule.modelId]?.engineId)),
);

const getAssociationModelTypesRestrictionRules = createSelector(
    getRulesEngineId,
    (rulesEngineId: string[]): string[] => {
        const rulesRestriction = _.flatten(
            rulesEngineId.map((modelType) => AssociationModelTypesRestrictionRules[modelType]),
        );
        return _.uniq(rulesRestriction);
    },
);

const getRankingModifier = (state: SearchOptimizationState): number =>
    SliderSelectors.getSliderValue(state, {id: AssociationsConstants.Components.RankingModifier});

const getInitialRankingModifier = (state: SearchOptimizationState): number => state.editAssociation?.rankingModifier;

const getSelectedModel = createSelector(
    getModels,
    getSelectedModelId,
    (models: MLModel[], id: string): MLModel => _.findWhere(models, {id}),
);

const getModelType = createSelector(getSelectedModel, (model: MLModel): string => model?.engineId ?? null);

const getActiveQueryPipeline = createSelector(
    (state: SearchOptimizationState): IActiveQueryPipelineState => state.activeQueryPipeline || null,
    (pipeline: IActiveQueryPipelineState): IActiveQueryPipelineState => pipeline,
);

const getBasicAttributes = createStructuredSelector({
    rankingModifier: getRankingModifier,
    modelId: getSelectedModelId,
    condition: getSelectedConditionId,
    useAdvancedConfiguration: () => false,
});

const getTopclicksAttributes = (state: SearchOptimizationState) => ({
    matchBasicExpression: CheckboxSelectors.getIsSelected(state, {id: EditAssociationsIds.MatchQuery}),
    matchAdvancedExpression: CheckboxSelectors.getIsSelected(state, {id: EditAssociationsIds.MatchAdvanced}),
    intelligentTermDetection: CheckboxSelectors.getIsSelected(state, {id: EditAssociationsIds.MatchITD}),
});

const getFacetsenseAttributes = (state: SearchOptimizationState): Partial<MLAssociationModel> => ({
    customQueryParameters: {
        facetOrdering: {
            isEnabled: CheckboxSelectors.getIsSelected(state, {id: EditAssociationsIds.FacetOrdering}),
        },
        facetValueOrdering: {
            isEnabled: CheckboxSelectors.getIsSelected(state, {id: EditAssociationsIds.FacetValueOrdering}),
        },
        automaticSelection: {
            isEnabled: CheckboxSelectors.getIsSelected(state, {id: EditAssociationsIds.AutomaticSelection}),
        },
        rankingBoost: {
            isEnabled: CheckboxSelectors.getIsSelected(state, {id: EditAssociationsIds.FacetRankingBoost}),
        },
    },
});

const getEcommerceAttributes = (state: SearchOptimizationState) => ({
    customQueryParameters: {
        submodel: RadioSelectSelectors.getValue(state, {id: EditAssociationsIds.ECommerceSubmodel}),
    },
});

const serializeAssociation = createSelector(
    getState,
    getModelType,
    getBasicAttributes,
    (state: SearchOptimizationState, modelType: AssociationModelTypes, basicAttributes) => {
        switch (modelType) {
            case AssociationModelTypes.TopClicks:
                return {
                    ...basicAttributes,
                    ...getTopclicksAttributes(state),
                };
            case AssociationModelTypes.FacetSense:
                return {
                    ...basicAttributes,
                    ...getFacetsenseAttributes(state),
                };
            case AssociationModelTypes.ECommerce:
                return {
                    ...basicAttributes,
                    ...getEcommerceAttributes(state),
                };
            default:
                return basicAttributes;
        }
    },
);

const getAssociationJSON = (state: SearchOptimizationState): string => state.editAssociationJSON;

const getAssociation = (state: SearchOptimizationState, id: string): MLAssociationModel =>
    _.findWhere(state.associations?.rules, {id});

const getAssociationCondition = createSelector(getAssociation, (association) => association?.condition);

export const AssociationSelectors = {
    getAssociation,
    getActiveQueryPipeline,
    getAssociationCondition,
    serializeAssociation,
    getModels,
    getAllModels,
    getIndexedModels,
    getAssociationCount,
    getRankingModifier,
    getSelectedModel,
    getRules,
    getRulesEngineId,
    getAssociationModelTypesRestrictionRules,
    getAssociationJSON,
    getInitialRankingModifier,
    getSelectedModelId,
};
