import {getTokenRenewalParams, AuthenticationEvents} from '@core/authentication';
import {Locales} from '../strings/Locales';

// Needed by Firefox
interface ScreenExt extends Screen {
    left: number;
    top: number;
}

export const doTokenRenewal = (provider: string) => {
    const {url, w, h} = getTokenRenewalParams(provider);

    // Fixes dual-screen position
    const screenInfo = screen as ScreenExt;

    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screenInfo.left;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : screenInfo.top;

    const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
          ? document.documentElement.clientWidth
          : screenInfo.width;
    const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
          ? document.documentElement.clientHeight
          : screenInfo.height;

    const left = width / 2 - w / 2 + dualScreenLeft;
    const top = height / 2 - h / 2 + dualScreenTop;
    const newWindow = window.open(
        url,
        Locales.format('logInAgain'),
        'scrollbars=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left,
    );
    newWindow?.addEventListener(
        'beforeunload',
        () => window.dispatchEvent(new Event(AuthenticationEvents.TokenRenewalCompleted)),
        {once: true},
    );

    try {
        newWindow?.focus();
    } catch (e) {
        const event = new Event(AuthenticationEvents.ShowPopUpsBlockedPrompt, {bubbles: true});
        document.dispatchEvent(event);
    }
};
