import {IReduxAction} from '@coveord/plasma-react';
import _ from 'underscore';

import {
    GranularPrivilegesTableActionTypes,
    GranularPrivilegesTablePayload,
    GranularResource,
} from './GranularPrivilegesTableActions';

export type GranularPrivilegesTablesState = {[id: string]: GranularPrivilegesTableState};

export interface GranularPrivilegesTableState {
    id: string;
    resources: GranularResource[];
    loading: boolean;
}

export const granularPrivilegesTableInitialState: GranularPrivilegesTableState = {
    id: undefined,
    resources: [],
    loading: false,
};

export const granularPrivilegesTablesReducer = (
    state: GranularPrivilegesTablesState = {},
    action: IReduxAction<Partial<GranularPrivilegesTablePayload>>,
): GranularPrivilegesTablesState => {
    switch (action.type) {
        case GranularPrivilegesTableActionTypes.remove:
            return _.has(state, action.payload.id) ? _.omit(state, action.payload.id) : state;
        case GranularPrivilegesTableActionTypes.add:
            return {
                ...state,
                [action.payload.id]: {
                    ...granularPrivilegesTableInitialState,
                    ...action.payload,
                },
            };
        case GranularPrivilegesTableActionTypes.setLoading:
            return {
                ...state,
                [action.payload.id]: {
                    ...granularPrivilegesTableInitialState,
                    ...state[action.payload.id],
                    ...action.payload,
                },
            };
        default:
            return state;
    }
};
