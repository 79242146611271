const accountInputId = 'account';
const accountNameInputId = 'accountName';
const extensionDateInputId = 'extensionDate';
const deletionDateInputId = 'deletionDate';
const productTypeDropdownId = 'productType';
const productEditionDropdownId = 'productEdition';
const extensionsMultiSelectId = 'extensions';
const overrideConfigurationModalId = 'overrideConfigurationModal';
const overrideConfigurationOperationSingleSelectId = 'overrideOperation';
const overrideConfigurationKeySingleSelectId = 'overrideKey';
const overrideConfigurationValueSingleSelectId = 'overrideValue';
const overrideConfigurationModalSaveButtonTestId = 'overrideConfigurationModalSaveButtonTestId';

export const DetailsComponentConstants = {
    accountInputId,
    accountNameInputId,
    extensionDateInputId,
    deletionDateInputId,
    productTypeDropdownId,
    productEditionDropdownId,
    extensionsMultiSelectId,
    overrideConfigurationModalId,
    overrideConfigurationOperationSingleSelectId,
    overrideConfigurationKeySingleSelectId,
    overrideConfigurationValueSingleSelectId,
    overrideConfigurationModalSaveButtonTestId,
};
