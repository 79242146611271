import {Localization} from '@core/locales';
import {Locales} from '../strings/Locales';

const OperatorValues = {
    Is: 'is',
    IsBetween: 'isBetween',
    IsDefined: 'isDefined',
    IsEmpty: 'isEmpty',
    IsNull: 'isNull',
    IsPopulated: 'isPopulated',
    IsUndefined: 'isUndefined',
    IsNot: 'is not',
    IsNotBetween: 'isNotBetween',
    IsNotEmpty: 'isNotEmpty',
    IsNotNull: 'isNotNull',
    IsNotPopulated: 'isNotPopulated',
    Contains: 'contains',
    DoesntContain: "doesn't contain",
    Matches: 'matches',
    DoesntMatch: "doesn't match",
};

const OperatorSpecialValues = {
    [OperatorValues.IsBetween]: 'is between',
    [OperatorValues.IsNotBetween]: 'is not between',
    [OperatorValues.IsEmpty]: 'is empty',
    [OperatorValues.IsNotEmpty]: 'is not empty',
    [OperatorValues.IsPopulated]: 'is populated',
    [OperatorValues.IsNotPopulated]: 'is not populated',
    [OperatorValues.IsNull]: 'is null',
    [OperatorValues.IsNotNull]: 'is not null',
    [OperatorValues.IsUndefined]: 'is undefined',
    [OperatorValues.IsDefined]: 'is defined',
};

const NegationOperators = {
    [OperatorValues.Contains]: OperatorValues.DoesntContain,
    [OperatorValues.Matches]: OperatorValues.DoesntMatch,
    [OperatorValues.Is]: OperatorValues.IsNot,
    [OperatorValues.IsBetween]: OperatorValues.IsNotBetween,
    [OperatorValues.IsEmpty]: OperatorValues.IsNotEmpty,
    [OperatorValues.IsNotEmpty]: OperatorValues.IsEmpty,
    [OperatorValues.IsPopulated]: OperatorValues.IsNotPopulated,
    [OperatorValues.IsNotPopulated]: OperatorValues.IsPopulated,
    [OperatorValues.IsUndefined]: OperatorValues.IsDefined,
    [OperatorValues.IsNull]: OperatorValues.IsNotNull,
};

export const DateOperatorsByName = {
    [OperatorValues.IsBetween]: Localization.convertToSentenceCase(Locales.format('conditions.isBetween')),
    [OperatorValues.IsNotBetween]: Localization.convertToSentenceCase(Locales.format('conditions.isNotBetween')),
};

const BinaryFieldOperatorsByName = {
    [OperatorValues.Is]: Localization.convertToSentenceCase(Locales.format('conditions.is')),
    [OperatorValues.IsNot]: Localization.convertToSentenceCase(Locales.format('conditions.isNot')),
    [OperatorValues.Contains]: Localization.convertToSentenceCase(Locales.format('conditions.contains')),
    [OperatorValues.DoesntContain]: Localization.convertToSentenceCase(Locales.format('conditions.doesntContain')),
    [OperatorValues.Matches]: Localization.convertToSentenceCase(Locales.format('conditions.matches')),
    [OperatorValues.DoesntMatch]: Localization.convertToSentenceCase(Locales.format('conditions.doesntMatch')),
};

const UnaryFieldOperatorsByName = {
    [OperatorValues.IsEmpty]: Localization.convertToSentenceCase(Locales.format('conditions.isEmpty')),
    [OperatorValues.IsNotEmpty]: Localization.convertToSentenceCase(Locales.format('conditions.isNotEmpty')),
    [OperatorValues.IsPopulated]: Localization.convertToSentenceCase(Locales.format('conditions.isPopulated')),
    [OperatorValues.IsNotPopulated]: Localization.convertToSentenceCase(Locales.format('conditions.isNotPopulated')),
};

const ValueOperatorsByName = {
    ...BinaryFieldOperatorsByName,
    ...UnaryFieldOperatorsByName,
};

// For some operators like isEmpty and isPopulated, we don't support an explicit negation.
// For example, we need to use not ($query isPopulated) to negate the expression.
export const OperatorsThatRequireExplicitNegation = [OperatorValues.IsNotPopulated, OperatorValues.IsNotEmpty];

export const Operators = {
    OperatorValues,
    OperatorSpecialValues,
    OperatorsThatRequireExplicitNegation,
    NegationOperators,
    DateOperatorsByName,
    UnaryFieldOperatorsByName,
    ValueOperatorsByName,
};
