import {InputSelectors} from '@coveord/plasma-react';
import {ServiceState} from '../../../ServiceState';
import {CaseAssistConfigurationConstants} from '../CaseAssistConfigurationConstants';

export interface OverviewModel {
    name?: string;
}

const getConfiguration = (state: ServiceState): OverviewModel | undefined => {
    const name = InputSelectors.getValue(state, {id: CaseAssistConfigurationConstants.ComponentIds.NameInput});
    return name.trim().length > 0 ? {name} : undefined;
};

export const OverviewSelectors = {
    getConfiguration,
};
