import {LicenseContext} from '@core/license';
import {OrganizationContext} from '@core/organization';
import Registry from '@core/registry';
import {createContext, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {LaunchDarkly} from './LaunchDarkly';

export const LaunchDarklyContext = createContext<{isLoading: boolean}>({isLoading: true});

export const LaunchDarklyProvider = ({children}) => {
    const organizationCtx = useContext(OrganizationContext);
    const licenseCtx = useContext(LicenseContext);
    const launchDarklyRef = useRef<LaunchDarkly | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const initClient = useCallback(async () => {
        setIsLoading(true);
        await launchDarklyRef.current?.initialize();
        setIsLoading(false);
    }, []);

    useEffect(() => {
        if (!launchDarklyRef.current && organizationCtx.query?.isSuccess && licenseCtx.query?.isSuccess) {
            const launchDarkly = new LaunchDarkly(organizationCtx.query?.data!, licenseCtx.query?.data!);
            Registry.register('launchDarkly', launchDarkly);
            launchDarklyRef.current = launchDarkly;
            initClient();
        }
        return () => {
            launchDarklyRef.current?.tearDown();
        };
    }, [
        organizationCtx.query?.isSuccess,
        licenseCtx.query?.isSuccess,
        organizationCtx.query?.data,
        licenseCtx.query?.data,
    ]);
    return <LaunchDarklyContext.Provider value={{isLoading}}>{children}</LaunchDarklyContext.Provider>;
};
