import {RouteObject} from '@core/routes';
import {Loadable} from '@components/page';
import {logBrowserBasePath} from '../Constants';

export const routes: RouteObject[] = [
    {
        path: `/:orgId/${logBrowserBasePath}/*`,
        Component: Loadable(() => import('../LogBrowserPage').then((module) => module.LogBrowserPage)),
    },
];
