import {Config} from '@core/configuration';

export class ConfigUtils {
    static get backendUrl(): string {
        return Config.UsageAnalytics.url;
    }

    static get backendAlternateUrls(): string[] {
        return Config.UsageAnalytics.alts;
    }
}
