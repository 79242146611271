import _ from 'underscore';
import {commonLocales} from '../CommonLocales';
import {ButtonGroupWidget} from '../widgets/ButtonGroup';
import {NumericInputWidget} from '../widgets/NumericInput';
import {SingleSelectWidget} from '../widgets/SingleSelect';
import {TimePickerWidget} from '../widgets/TimePicker';

export interface ScheduleDropdownsBehaviorOptions {
    dropdown?: string;
    editable?: boolean;
}

export class ScheduleDropdownsBehavior extends Marionette.Behavior {
    options: ScheduleDropdownsBehaviorOptions;

    constructor(options?: ScheduleDropdownsBehaviorOptions, view?) {
        super(options, view);

        this.options = _.defaults(this.options, {
            editable: true,
        });

        this.view.addRegions({
            minuteDropdownRegion: '.MinuteDropdown span',
            hourDropdownRegion: '.HourDropdown span',
            monthDropdownRegion: '.MonthDropdown span',
            frequencyDropdownRegion: '.FrequencyDropdown',
            daysOfTheWeekRegion: '.DaysOfTheWeek span',
            dayOfTheMonthRegion: '.DayOfTheMonth span',
            timepickerRegion: '.TimePicker span',
        });

        this.ui = {
            refreshSettings: '.admin-schedule',
            enabled: '.admin-schedule-header input[type=checkbox]',
        };

        this.modelEvents = {
            'change:frequency': this.onChangeFrequency,
        };
    }

    protected events() {
        return {
            'change .admin-schedule-header input[type=checkbox]': this.onClickEnabled,
        };
    }

    protected onDomRefresh() {
        (this.options.dropdown ? this.$el.find(this.options.dropdown) : this.$el).toggleClass(
            'enabled',
            this.view.model.enabled,
        );
        new SingleSelectWidget(this.view.model.minuteOptions).show(this.view.getRegion('minuteDropdownRegion'));
        new SingleSelectWidget(this.view.model.hourOptions).show(this.view.getRegion('hourDropdownRegion'));
        new SingleSelectWidget(this.view.model.everyMonth).show(this.view.getRegion('monthDropdownRegion'));
        new SingleSelectWidget(this.view.model.frequencyOptions).show(this.view.getRegion('frequencyDropdownRegion'), {
            flatSelect: false,
            valueFormatter: (value: string) =>
                commonLocales.formatOrDefault(`${value}_each_n`, {
                    defaultTranslation: value,
                    reportMissingKey: true,
                }),
        });
        new ButtonGroupWidget(this.view.model.weekDays).show(this.view.getRegion('daysOfTheWeekRegion'));
        new NumericInputWidget(this.view.model.dayOfMonth).show(this.view.getRegion('dayOfTheMonthRegion'));
        new TimePickerWidget(this.view.model.time).show(this.view.getRegion('timepickerRegion'));

        if (!this.options.editable) {
            this.$el.find(':input').prop('disabled', true);
        }
    }

    protected onChangeFrequency() {
        if (this.view.isRendered) {
            this.ui.refreshSettings.removeClass((i, className) => {
                const arr: string[] = className.match(/admin-schedule-frequency-[a-z]*/g);
                return arr.join(' ');
            });
            this.ui.refreshSettings.addClass(
                'admin-schedule-frequency-' + this.view.model.frequency.type.toLowerCase(),
            );
        }
    }

    protected onClickEnabled() {
        this.view.model.enabled = this.ui.enabled.prop('checked');
        (this.options.dropdown ? this.$el.find(this.options.dropdown) : this.$el).toggleClass(
            'enabled',
            this.view.model.enabled,
        );
    }
}
