import {NavigationBlockerProvider} from '@components/page';
import {MandatoryResourcesLoader} from '@components/session';
import {LaunchDarklyProvider} from '@core/feature-flags';
import {LicenseProvider} from '@core/license';
import {OrganizationProvider} from '@core/organization';
import {PrivilegesProvider, UserProvider} from '@core/user';
import {FunctionComponent, PropsWithChildren} from 'react';

export const AppLayout: FunctionComponent<PropsWithChildren> = ({children}) => (
    <NavigationBlockerProvider>
        <UserProvider>
            <OrganizationProvider>
                <PrivilegesProvider>
                    <LicenseProvider>
                        <LaunchDarklyProvider>
                            <MandatoryResourcesLoader>{children}</MandatoryResourcesLoader>
                        </LaunchDarklyProvider>
                    </LicenseProvider>
                </PrivilegesProvider>
            </OrganizationProvider>
        </UserProvider>
    </NavigationBlockerProvider>
);
