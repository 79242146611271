import {IReduxAction} from '@coveord/plasma-react';
import _ from 'underscore';

export const NoteCardActionsType = {
    Initialize: 'NOTE_CARD_INITIALIZE',
};

export interface NoteCardInitializePayload {
    title: string;
    value: string;
    style: string;
    isMarkdown: boolean;
}

const initialize = (id: string, attrs: NoteCardInitializePayload): IReduxAction<NoteCardInitializePayload> => ({
    type: NoteCardActionsType.Initialize,
    payload: _.extend({id}, attrs),
});

export const NoteCardActions = {
    initialize,
};
