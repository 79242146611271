import {ProjectModel} from '@core/api';
import {IReduxAction} from '@coveord/plasma-react';
import {ReducersMapObject} from 'redux';

import {ProjectActionsPayload, ProjectActionsType} from '../actions';
import {ProjectState} from '../store';

const initialState = null;

const projectReducers = (
    state: ProjectModel | null = initialState,
    action: IReduxAction<ProjectActionsPayload>,
): ProjectModel | null => {
    switch (action.type) {
        case ProjectActionsType.update:
            return state ? {...state, ...(action.payload?.project ?? {})} : (action.payload?.project ?? null);
        case ProjectActionsType.clear:
            return initialState;
        default:
            return state;
    }
};

export const rootReducer: ReducersMapObject<ProjectState> = {
    project: projectReducers,
};
