import {ConditionsActionsTypes, conditionsObjectsReducer} from '@components/conditions';
import {ConditionModel, PipelineModel, ResultRanking, StatementGroupList, StatementModel} from '@core/api';

import {KeyValueReducerGenerator, ObjectReducerGenerator} from '@core/store';
import {stringListCompositeReducer} from '@coveord/plasma-react';
import {ReducersMapObject} from 'redux';
import {ActiveQueryPipelineReducer} from './query-pipelines/active-query-pipeline/ActiveQueryPipelineReducer';
import {QueryPipelinesConstants} from './query-pipelines/QueryPipelinesConstants';
import {AssociationsActionTypes} from './statements/basics/associations/actions/AssociationsActions';
import {associationsReducer} from './statements/basics/associations/reducers/AssociationsReducers';
import {editAssociationReducer} from './statements/basics/associations/reducers/EditAssociationReducers';
import {EditStatementCodeActionTypes} from './statements/edit-code/EditStatementCodeActions';
import {ResultRankingConstants} from './statements/result-ranking/constants/ResultRankingConstants';
import {ListStatementGroupsRequestTypes} from './statements/statement-groups/actions/StatementGroupsActions';
import {StatementGroupsConstants} from './statements/statement-groups/StatementGroupsConstants';
import {StatementConstants} from './statements/StatementConstants';

export const searchOptimizationReducers: ReducersMapObject = {
    activeQueryPipeline: ActiveQueryPipelineReducer,
    associations: associationsReducer,
    queryPipelines: KeyValueReducerGenerator<PipelineModel[]>(QueryPipelinesConstants.actionType, null),
    condition: KeyValueReducerGenerator<ConditionModel>(ConditionsActionsTypes.editCondition, null),
    conditionObjects: conditionsObjectsReducer,
    conditionStatements: KeyValueReducerGenerator<ConditionModel[]>(ConditionsActionsTypes.conditionStatements, null),
    editAssociation: editAssociationReducer,
    editAssociationJSON: KeyValueReducerGenerator<string>(AssociationsActionTypes.setJSON, '{}'),
    keywords: stringListCompositeReducer,
    editStatement: KeyValueReducerGenerator<StatementModel>(StatementConstants.ActionTypes.singleStatements),
    editStatementCode: KeyValueReducerGenerator<string>(EditStatementCodeActionTypes.value),
    statements: KeyValueReducerGenerator<StatementModel[]>(StatementConstants.ActionTypes.allStatements, null),
    resultRanking: KeyValueReducerGenerator<ResultRanking>(ResultRankingConstants.ActionTypes.single),
    subConditions: stringListCompositeReducer,
    statementGroups: ObjectReducerGenerator<Record<ListStatementGroupsRequestTypes, StatementGroupList>>(
        StatementGroupsConstants.ActionTypes.all,
    ),
};
