import {Dictionary, Locales} from '@core/locales';

import {ServiceLocalesKeys} from '../generated/ServiceLocalesKeys';
import {toTranslate} from './ServiceLocales';

const generateDictionary = (locale: string): Dictionary => {
    const translations = Object.keys(toTranslate).reduce(
        (all, key) => ({
            ...all,
            ...toTranslate[key],
        }),
        {},
    );

    return {
        locale,
        translations,
    };
};

const dictionaries = [generateDictionary('en')];

export const locales: Locales<ServiceLocalesKeys> = new Locales<ServiceLocalesKeys>({
    dictionaries: dictionaries,
    defaultLocale: 'en',
});
