import {IItemBoxProps} from '@coveord/plasma-react';
import {DateChartType} from '../../../charts/DateChart';

export interface EditDimensionTimeseriesState {
    metrics: IItemBoxProps[];
    dimensions: IItemBoxProps[];
    dimensionValues: string[];
    displayAs: string;
    filters: string;
    headerHref: string;
    headerHrefs: IItemBoxProps[];
    showMetrics: boolean;
    showTrend: boolean;
    showAverage: boolean;
    showPeak: boolean;
    metric?: string;
    dimension?: string;
}

export const EditDimensionTimeseriesDefaultState: EditDimensionTimeseriesState = {
    metrics: [],
    dimensions: [],
    dimensionValues: [],
    displayAs: DateChartType.Line,
    filters: '',
    headerHref: '',
    headerHrefs: [],
    showMetrics: false,
    showTrend: false,
    showAverage: true,
    showPeak: true,
};
