export const EventFilterSetActionsType = {
    add: 'ADD_EVENT_FILTERS',
    remove: 'REMOVE_EVENT_FILTERS',
    edit: 'EDIT_EVENT_FILTERS',
};

const add = (id: string) => ({
    type: EventFilterSetActionsType.add,
    payload: {id},
});

const remove = (id: string) => ({
    type: EventFilterSetActionsType.remove,
    payload: {id},
});

export const EventFilterSetActions = {
    add,
    remove,
};
