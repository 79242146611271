import {GlobalPrivilegeModel, PrivilegeModel} from '@core/api';
import {ArraysOfObjects, PrivilegeOwners, PrivilegeUtils, PrivilegesListSelector, SOPrivilegeDomains} from '@core/user';
import {ISubNavigationState, SelectSelector} from '@coveord/plasma-react';
import {createSelector, createStructuredSelector} from 'reselect';

import {AdminState} from '../../application/Reducers';
import {DefaultGroupsSelectors} from '../../groups/DefaultGroups';
import {PrivilegesTableSelectorsUtils as Utils} from './PrivilegesTableSelectorsUtils';

type AnyPrivilegeModel = PrivilegeModel | GlobalPrivilegeModel;
export interface PrivilegesTableSelectorProps {
    AppliedPrivilegesList: string;
    AvailablePrivilegesList: string;
    AllowedPrivilegesList: string;
    GranularPrivilegesList: string;
    InitialPrivilegesList: string;
    ExclusivePrivilegesList: string;
    SubNavigation: string;
    Table: string;
    PresetDropdown: string;
    ToastContainerEl: string;
    minimumPrivileges?: AnyPrivilegeModel[];
    supportsGranularPrivileges?: boolean;
    [key: string]: any;
}

export interface PrivilegesTableRowContext {
    props: PrivilegesTableSelectorProps;
    domain: string;
    availablePrivileges: AnyPrivilegeModel[];
    possiblePrivileges: AnyPrivilegeModel[];
    appliedPrivileges: AnyPrivilegeModel[];
    userPrivileges: AnyPrivilegeModel[];
    initialPrivileges: AnyPrivilegeModel[];
    exclusivePrivileges?: AnyPrivilegeModel[];
}

export type PrivilegesTableContext = Record<string, PrivilegesTableRowContext>;

const getProps = (state: AdminState, props: PrivilegesTableSelectorProps) => props;

const getAppliedPrivilegesList = (state: AdminState, props: {AppliedPrivilegesList: string; [key: string]: any}) =>
    PrivilegesListSelector.getPrivilegesList(state, props.AppliedPrivilegesList);

const getGranularPrivilegesList = (state: AdminState, props: PrivilegesTableSelectorProps) =>
    PrivilegesListSelector.getPrivilegesList(state, props.GranularPrivilegesList);

const getAllPrivilegesList = (state: AdminState, props: PrivilegesTableSelectorProps): PrivilegeModel[] =>
    PrivilegesListSelector.getPrivilegesList(state, props.AvailablePrivilegesList);

const getAllowedPrivilegesList = (state: AdminState, props: PrivilegesTableSelectorProps): PrivilegeModel[] =>
    (state.privilegesLists && state.privilegesLists[props.AllowedPrivilegesList]) || null;

const getInitialPrivilegesList = (state: AdminState, props: PrivilegesTableSelectorProps): PrivilegeModel[] =>
    PrivilegesListSelector.getPrivilegesList(state, props.InitialPrivilegesList);

const getExclusivePrivilegesList = (state: AdminState, props: PrivilegesTableSelectorProps): PrivilegeModel[] =>
    PrivilegesListSelector.getPrivilegesList(state, props.ExclusivePrivilegesList);

const getSelectedSection = (state: AdminState, props: PrivilegesTableSelectorProps): string => {
    const subNav: ISubNavigationState = _.findWhere(state.subNavigations, {
        id: props.SubNavigation,
    });
    return subNav && subNav.selected;
};

const getSelectedPreset = (state: AdminState, props: PrivilegesTableSelectorProps): string =>
    SelectSelector.getListBoxSelected(state, {id: props.PresetDropdown})[0];

const getAvailablePrivilegesGroupedByService = createSelector(getAllPrivilegesList, PrivilegeUtils.groupByService);
const getNormalUserPrivileges = createSelector(PrivilegesListSelector.getUserPrivilegesList, Utils.normalizePrivileges);
const getAvailablePrivilegesForUser = createSelector(
    [getNormalUserPrivileges, getInitialPrivilegesList],
    ArraysOfObjects.union,
);
const getGranularPrivilegesGroupedByDomain = createSelector(getGranularPrivilegesList, PrivilegeUtils.groupByDomain);
const getPossiblePrivilegesForUser = createSelector(
    getAllPrivilegesList,
    getNormalUserPrivileges,
    getAvailablePrivilegesForUser,
    Utils.reduceToPossiblePrivileges,
);
const getAllowedPrivileges = createSelector(
    getAllowedPrivilegesList,
    getPossiblePrivilegesForUser,
    (allowedPrivileges, possiblePrivilegesForUser) => allowedPrivileges || possiblePrivilegesForUser,
);
const getPrivilegesDomainsForCurrentSection = createSelector(
    getSelectedSection,
    getAvailablePrivilegesGroupedByService,
    Utils.getPrivilegeDomainsForSection,
);

const getAvailablePrivilegesGroupedByDomain = createSelector(getAllPrivilegesList, PrivilegeUtils.groupByDomain);
const getPossiblePrivilegesGroupedByDomain = createSelector(getAllowedPrivileges, PrivilegeUtils.groupByDomain);
const getAppliedPrivilegesGroupedByDomain = createSelector(getAppliedPrivilegesList, PrivilegeUtils.groupByDomain);
const getUserPrivilegesGroupedByDomain = createSelector(getNormalUserPrivileges, PrivilegeUtils.groupByDomain);
const getInitialPrivilegesGroupedByDomain = createSelector(getInitialPrivilegesList, PrivilegeUtils.groupByDomain);
const getExclusivePrivilegesGroupedByDomain = createSelector(getExclusivePrivilegesList, PrivilegeUtils.groupByDomain);

const getPrivilegesContext = createSelector(
    [
        getProps,
        getAvailablePrivilegesGroupedByDomain,
        getPossiblePrivilegesGroupedByDomain,
        getAppliedPrivilegesGroupedByDomain,
        getUserPrivilegesGroupedByDomain,
        getInitialPrivilegesGroupedByDomain,
        getExclusivePrivilegesGroupedByDomain,
    ],
    Utils.buildPrivilegesContext,
);

const getAppliedPreset = createSelector(
    [getProps, getAppliedPrivilegesList, getAllowedPrivileges, DefaultGroupsSelectors.getAll, getNormalUserPrivileges],
    Utils.computeAppliedPreset,
);

const makeGetPrivileges = (domain: string, accessLevel: string) =>
    createSelector(getPrivilegesContext, Utils.computeAppliedPrivileges(domain, accessLevel));

const getSections = createSelector(
    [getAvailablePrivilegesGroupedByService, getPrivilegesContext],
    Utils.getSectionsFromPrivileges,
);
const getTableRows = createSelector(
    [getPrivilegesDomainsForCurrentSection, getPrivilegesContext],
    Utils.computeTableRows,
);

const getIsImpersonateAllowed = (state: AdminState, props: PrivilegesTableSelectorProps) =>
    PrivilegesListSelector.getPrivilegesList(state, props.AppliedPrivilegesList).some(
        (privilege: PrivilegeModel) =>
            privilege.targetDomain === SOPrivilegeDomains.Impersonate && privilege.owner === PrivilegeOwners.SearchApi,
    );
const getIsExecuteQueryAllowed = (state: AdminState, props: PrivilegesTableSelectorProps) =>
    PrivilegesListSelector.getPrivilegesList(state, props.AppliedPrivilegesList).some(
        (privilege: PrivilegeModel) => privilege.targetDomain === SOPrivilegeDomains.ExecuteQuery,
    );

const makePrivilegesTableStateProps = () =>
    createStructuredSelector({
        tableData: getTableRows,
        selectedSection: getSelectedSection,
        sections: getSections,
        selectedPreset: getSelectedPreset,
        appliedPreset: getAppliedPreset,
        isImpersonateAllowed: getIsImpersonateAllowed,
        isExecuteQueryAllowed: getIsExecuteQueryAllowed,
        appliedPrivilegesList: getAppliedPrivilegesList,
    });
export const PrivilegesTableSelectors = {
    getAppliedPrivilegesList,
    getNormalUserPrivileges,
    getGranularPrivilegesGroupedByDomain,
    getPossiblePrivilegesForUser,
    getAllowedPrivileges,
    getAvailablePrivilegesGroupedByDomain,
    getPossiblePrivilegesGroupedByDomain,
    getAppliedPrivilegesGroupedByDomain,
    getUserPrivilegesGroupedByDomain,
    getInitialPrivilegesGroupedByDomain,
    getExclusivePrivilegesGroupedByDomain,
    makeGetPrivileges,
    makePrivilegesTableStateProps,
    getInitialPrivilegesList,
};
