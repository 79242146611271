import {Anchor, Text, notifications} from '@components/mantine';
import {Platform, useQuery} from '@core/api';
import {Translation} from '@core/locales';
import {CurrentOrganization} from '@core/organization';
import {Link} from '@core/routes';

import {FunctionComponent, useEffect} from 'react';

import {Locales} from '../../strings';
import {NotificationsTracking} from '../../tracking';

export const OrganizationDeprecationNotification: FunctionComponent = () => {
    const orgId = CurrentOrganization.getId();
    const {data: organizationMonitoringEndpointUsageModel} = useQuery({
        queryKey: ['organizationMonitoring', orgId],
        queryFn: () => Platform.organizationMonitoring.get(orgId),
    });

    useEffect(() => {
        const wrongEndpointPercentageForSearch =
            organizationMonitoringEndpointUsageModel?.searchDetails.wrongEndpointsDetails.reduce(
                (sum, current) => sum + current.requestDetails.totalRequestPercentage,
                0,
            );

        const wrongEndpointPercentageForAnalytics =
            organizationMonitoringEndpointUsageModel?.analyticsDetails.wrongEndpointsDetails.reduce(
                (sum, current) => sum + current.requestDetails.totalRequestPercentage,
                0,
            );

        const shouldShowAlertBox =
            (wrongEndpointPercentageForAnalytics ?? 0) > 1 || (wrongEndpointPercentageForSearch ?? 0) > 1;

        if (shouldShowAlertBox) {
            NotificationsTracking.trackDnsDeprecationNotificationsOpened();
            notifications.showError({
                id: 'organization-dns-deprecation-notification',
                title: Locales.format('OrganizationDnsDeprecation.endpointAlertBoxTitle'),
                message: (
                    <Text>
                        <Translation t={Locales} i18nKey="OrganizationDnsDeprecation.endpointAlertBoxDescription">
                            <Anchor
                                component={Link}
                                to={'/settings/organization/traffic'}
                                onClick={NotificationsTracking.trackDnsDeprecationNotificationsViewDetailsClicked}
                            ></Anchor>
                        </Translation>
                    </Text>
                ),
                onClose: () => {
                    NotificationsTracking.trackDnsDeprecationNotificationsClosed();
                },
            });
        }
    }, [organizationMonitoringEndpointUsageModel]);

    return null;
};
