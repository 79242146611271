export interface CalculatedMetricOperator {
    symbol: string;
    calculate: (a: number, b: number) => number;
}

export const CalculatedMetricOperators = {
    Add: {symbol: '+', calculate: (a: number, b: number) => a + b},
    Subtract: {symbol: '-', calculate: (a: number, b: number) => a - b},
    Divide: {symbol: '÷', calculate: (a: number, b: number) => a / b},
    Multiply: {symbol: '×', calculate: (a: number, b: number) => a * b},
};
