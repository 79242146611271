import {GenericError, LicenseModel, Platform, useQuery, UseQueryResult} from '@core/api';
import {OrganizationContext} from '@core/organization';
import Registry from '@core/registry';
import {createContext, useContext} from 'react';
import {useDispatch} from 'react-redux';
import {LicenseActions} from '../actions';

export const LicenseContext = createContext<{query: UseQueryResult<LicenseModel, GenericError> | null}>({query: null});

export const useLicense = (): LicenseModel => {
    const context = useContext(LicenseContext);

    if (!context || !context.query) {
        throw new Error('useLicense must be used within a LicenseProvider');
    }

    if (!context.query.data) {
        throw new Error('License data is not available');
    }

    return context.query.data;
};

export const LicenseProvider = ({children}) => {
    const organizationCtx = useContext(OrganizationContext);
    const orgId = organizationCtx.query?.data?.id;
    const dispatch = useDispatch();
    const licenseQuery = useQuery<LicenseModel, GenericError>({
        queryKey: ['init', 'license', orgId],
        queryFn: async () => {
            const license = await Platform.license.full();
            dispatch(LicenseActions.update(license));
            Registry.register('license', license);
            return license;
        },
        retry: false,
        enabled: organizationCtx.query?.isSuccess, // only fetch license if organization query is successful
        initialData: Registry.get('license', {optional: true}),
        staleTime: Infinity,
    });
    return <LicenseContext.Provider value={{query: licenseQuery}}>{children}</LicenseContext.Provider>;
};
