import {RequestsSelectors} from '@coveord/jsadmin-common';
import {CatalogModel, CreateCatalogConfigurationModel, IAssociatedCatalogModel} from '@core/api';
import {InputSelectors, SelectSelector} from '@coveord/plasma-react';
import {createSelector} from 'reselect';
import {CardSelectGroupSelectors} from '../../CardSelect/CardSelectGroupSelectors';
import {CommerceState} from '../../CommerceState';
import {CatalogConstants} from '../CatalogConstants';
import {CatalogSelectors} from '../CatalogSelectors';
import {SourcesSelectors} from '../SourceSelect/SourcesSelectors';
import {CatalogConfigurationConstants} from './CatalogConfigurationConstants';

const {ComponentIds} = CatalogConstants;

// copied here from `../EditCatalog/EditCatalogSelectors.tsx` to prevent circular dependency
const getCatalog = (state: CommerceState) => state.catalogManager.editCatalog.catalog;
const getConfiguration = (state: CommerceState) => state.catalogManager.editConfiguration.configuration;

const catalogConfigurationErrors = RequestsSelectors.createErrorMessageSelector([
    CatalogConfigurationConstants.ActionsTypes.fetch,
]);
const hasLoadingErrors = (state: CommerceState) => catalogConfigurationErrors(state).some((error) => !!error);

const isCatalogConfigurationLoading = RequestsSelectors.createLoadingSelector([
    CatalogConfigurationConstants.ActionsTypes.fetch,
]);

export type ICatalogConfiguration = Pick<CatalogModel, 'name' | 'description' | 'scope'> & {
    hasVariant: boolean;
    hasAvailability: boolean;
};

export const getEditedPartialCatalog = (state: CommerceState): ICatalogConfiguration => ({
    name: InputSelectors.getValue(state, {
        id: CatalogConstants.ComponentIds.NameInput,
    }),
    description: InputSelectors.getValue(state, {
        id: CatalogConstants.ComponentIds.DescriptionInput,
    }),
    scope: CatalogSelectors.getScope(state),
    hasVariant: CardSelectGroupSelectors.getSelectedValue(state, {
        id: CatalogConfigurationConstants.ComponentIds.ProductHierarchy,
    }) as boolean,
    hasAvailability: CardSelectGroupSelectors.getSelectedValue(state, {
        id: CatalogConfigurationConstants.ComponentIds.AvailabilityHierarchy,
    }) as boolean,
});

export type IConfiguration = Pick<CreateCatalogConfigurationModel, 'name' | 'product' | 'variant' | 'availability'> & {
    hasVariant: boolean;
    hasAvailability: boolean;
};

const getEditedProductIdField = (state: CommerceState) =>
    SelectSelector.getListBoxSelected(state, {
        id: CatalogConfigurationConstants.ComponentIds.Inputs.Product.idField,
    })[0];

const getEditedProductObjectType = (state: CommerceState) =>
    SelectSelector.getListBoxSelected(state, {
        id: CatalogConfigurationConstants.ComponentIds.Inputs.Product.objectType,
    })[0];

const getEditedVariantIdField = (state: CommerceState) =>
    SelectSelector.getListBoxSelected(state, {
        id: CatalogConfigurationConstants.ComponentIds.Inputs.Variant.idField,
    })[0];

const getEditedVariantObjectType = (state: CommerceState) =>
    SelectSelector.getListBoxSelected(state, {
        id: CatalogConfigurationConstants.ComponentIds.Inputs.Variant.objectType,
    })[0];

const getEditedAvailabilitySkuField = (state: CommerceState) =>
    SelectSelector.getListBoxSelected(state, {
        id: CatalogConfigurationConstants.ComponentIds.Inputs.Availability.skuField,
    })[0];

const getEditedAvailabilityObjectType = (state: CommerceState) =>
    SelectSelector.getListBoxSelected(state, {
        id: CatalogConfigurationConstants.ComponentIds.Inputs.Availability.objectType,
    })[0];

const getEditedAvailabilityIdField = (state: CommerceState) =>
    SelectSelector.getListBoxSelected(state, {
        id: CatalogConfigurationConstants.ComponentIds.Inputs.Availability.idField,
    })[0];

const getSelectedPreviewCatalogId = (state: CommerceState) =>
    SelectSelector.getListBoxSelected(state, {
        id: CatalogConfigurationConstants.ComponentIds.PreviewCatalogSelect,
    })[0];

const getSelectedAvailabilityPreviewCatalogId = (state: CommerceState) =>
    SelectSelector.getListBoxSelected(state, {
        id: CatalogConfigurationConstants.ComponentIds.AvailabilityPreviewCatalogSelect,
    })[0];

const getCatalogProductAutoFields = createSelector(getCatalog, (foundCatalog) => foundCatalog?.product?.fields || []);

const getCatalogVariantAutoFields = createSelector(getCatalog, (foundCatalog) => foundCatalog?.variant?.fields || []);

const getCatalogAvailabilityAutoFields = createSelector(
    getCatalog,
    (foundCatalog) => foundCatalog?.availability?.fields || [],
);

const getInitialProductIdField = createSelector(getConfiguration, (configuration) =>
    configuration && configuration.product ? configuration.product.idField : '',
);

const getProductIdField = createSelector(
    getEditedProductIdField,
    getInitialProductIdField,
    (edited, initial) => edited || initial,
);

const getInitialProductObjectType = createSelector(getConfiguration, (configuration) =>
    configuration ? configuration.product.objectType : '',
);

const getProductObjectType = createSelector(
    getEditedProductObjectType,
    getInitialProductObjectType,
    (edited, initial) => edited || initial,
);

const getProduct = createSelector(getProductIdField, getProductObjectType, (idField, objectType) => ({
    idField,
    objectType,
}));

const hasVariant = createSelector(
    getConfiguration,
    (foundConfiguration) => !!(foundConfiguration && foundConfiguration.variant),
);

const getInitialVariantIdField = createSelector(getConfiguration, (foundCatalog) =>
    foundCatalog && foundCatalog.variant ? foundCatalog.variant.idField : '',
);

const getVariantIdField = createSelector(
    getEditedVariantIdField,
    getInitialVariantIdField,
    (edited, initial) => edited || initial,
);

const getInitialVariantObjectType = createSelector(getConfiguration, (foundCatalog) =>
    foundCatalog && foundCatalog.variant ? foundCatalog.variant.objectType : '',
);

const getVariantObjectType = createSelector(
    getEditedVariantObjectType,
    getInitialVariantObjectType,
    (edited, initial) => edited || initial,
);

const getVariant = createSelector(getVariantIdField, getVariantObjectType, (idField, objectType) => ({
    idField,
    objectType,
}));

const hasAvailability = createSelector(
    getConfiguration,
    (foundConfiguration) => !!(foundConfiguration && foundConfiguration.availability),
);

const getInitialAvailabilityIdField = createSelector(getConfiguration, (foundCatalog) =>
    foundCatalog && foundCatalog.availability ? foundCatalog.availability.idField! : '',
);

const getAvailabilityIdField = createSelector(
    getEditedAvailabilityIdField,
    getInitialAvailabilityIdField,
    (edited, initial) => edited || initial,
);

const getInitialAvailabilityObjectType = createSelector(getConfiguration, (foundCatalog) =>
    foundCatalog && foundCatalog.availability ? foundCatalog.availability.objectType : '',
);

const getAvailabilityObjectType = createSelector(
    getEditedAvailabilityObjectType,
    getInitialAvailabilityObjectType,
    (edited, initial) => edited || initial,
);

const getInitialAvailabilitySkuField = createSelector(getConfiguration, (foundCatalog) =>
    foundCatalog && foundCatalog.availability ? foundCatalog.availability.availableSkusField : '',
);

const getAvailabilitySkuField = createSelector(
    getEditedAvailabilitySkuField,
    getInitialAvailabilitySkuField,

    (edited, initial) => edited || initial,
);

const getAvailability = createSelector(
    getAvailabilityIdField,
    getAvailabilityObjectType,
    getAvailabilitySkuField,
    (idField, objectType, availableSkusField) => ({idField, objectType, availableSkusField}),
);

const getAssociatedCatalogs = createSelector(
    getConfiguration,
    (configuration) => configuration.associatedCatalogs || [],
);

const getProductSourcesFromAllAssociatedCatalogs = createSelector(
    getAssociatedCatalogs,
    (associatedCatalogs) =>
        associatedCatalogs
            .map((associatedCatalog) => associatedCatalog.sourceId)
            .filter((sourceId) => !!sourceId) as string[],
);

const getAvailabilitySourcesFromAllAssociatedCatalogs = createSelector(
    getAssociatedCatalogs,
    (associatedCatalogs) =>
        associatedCatalogs
            .map((associatedCatalog) => associatedCatalog.availabilitySourceId || associatedCatalog.sourceId)
            .filter((sourceId) => !!sourceId) as string[],
);

const getSelectedProductSource = (state: CommerceState) =>
    SelectSelector.getSelectedValue(state, {id: ComponentIds.SourceSelect});
const getSelectedAvailabilitySource = (state: CommerceState) =>
    SelectSelector.getSelectedValue(state, {id: ComponentIds.AvailabilitySourceSelect});

const getSelectedOrSavedCatalogProductSource = createSelector(
    getSelectedProductSource,
    CatalogSelectors.getCatalogSourceId,
    getProductSourcesFromAllAssociatedCatalogs,
    (selectedProductSource, catalogProductSource, productSourcesFromAllAssociatedCatalogs) =>
        (selectedProductSource && [selectedProductSource]) ||
        (catalogProductSource && [catalogProductSource]) ||
        productSourcesFromAllAssociatedCatalogs,
);

const getSelectedOrSavedCatalogAvailabilitySource = createSelector(
    getSelectedAvailabilitySource,
    CatalogSelectors.getCatalogAvailabilitySourceId,
    getSelectedProductSource,
    CatalogSelectors.getCatalogSourceId,
    getAvailabilitySourcesFromAllAssociatedCatalogs,
    (
        selectedAvailabilitySource,
        catalogAvailabilitySource,
        selectedProductSource,
        catalogProductSource,
        availabilitySourcesFromAllAssociatedCatalogs,
    ) =>
        (selectedAvailabilitySource && [selectedAvailabilitySource]) ||
        (selectedProductSource && [selectedProductSource]) ||
        (catalogAvailabilitySource && [catalogAvailabilitySource]) ||
        (catalogProductSource && [catalogProductSource]) ||
        availabilitySourcesFromAllAssociatedCatalogs,
);

const isSelectedCatalogId = (selectedPreviewCatalogId: string) => (catalog: IAssociatedCatalogModel) =>
    !selectedPreviewCatalogId || catalog.id === selectedPreviewCatalogId;

const getProductSourceQuery = createSelector(
    getSelectedOrSavedCatalogProductSource,
    SourcesSelectors.getCatalogEnabledSourceIdToNameMap,
    (selectedOrSavedCatalogProductSource, sourcesMap) =>
        buildSourceQuery(selectedOrSavedCatalogProductSource, sourcesMap),
);

const getProductPreviewQuery = createSelector(
    getAssociatedCatalogs,
    getSelectedPreviewCatalogId,
    SourcesSelectors.getCatalogEnabledSourceIdToNameMap,
    (associatedCatalogs, selectedPreviewCatalogId, sourcesMap) =>
        buildSourceQuery(
            associatedCatalogs
                .filter(isSelectedCatalogId(selectedPreviewCatalogId))
                .map((associatedCatalog) => associatedCatalog.sourceId)
                .filter((sourceId) => !!sourceId) as string[],
            sourcesMap,
        ),
);

const getAvailabilitySourceQuery = createSelector(
    getSelectedOrSavedCatalogAvailabilitySource,
    SourcesSelectors.getCatalogEnabledSourceIdToNameMap,
    (selectedOrSavedCatalogAvailabilitySource, sourcesMap) =>
        buildSourceQuery(selectedOrSavedCatalogAvailabilitySource, sourcesMap),
);

const getAvailabilityPreviewQuery = createSelector(
    getAssociatedCatalogs,
    getSelectedAvailabilityPreviewCatalogId,
    SourcesSelectors.getCatalogEnabledSourceIdToNameMap,
    (associatedCatalogs, selectedPreviewCatalogId, sourcesMap) =>
        buildSourceQuery(
            associatedCatalogs
                .filter(isSelectedCatalogId(selectedPreviewCatalogId))
                .map((associatedCatalog) => associatedCatalog.availabilitySourceId || associatedCatalog.sourceId)
                .filter((sourceId) => !!sourceId) as string[],
            sourcesMap,
        ),
);

const buildSourceQuery = (sourceIds: string[], sourcesMap: Map<string, string>) =>
    sourceIds.length > 0
        ? `@source==(\"${sourceIds
              .map((id) => sourcesMap[id])
              .filter((name) => !!name)
              .join('","')}\")`
        : '';

export const CatalogConfigurationSelectors = {
    isCatalogConfigurationLoading,
    hasLoadingErrors,

    getConfiguration,

    getEditedPartialCatalog,
    getEditedProductIdField,
    getEditedProductObjectType,
    getEditedVariantIdField,
    getEditedVariantObjectType,
    getEditedAvailabilitySkuField,
    getEditedAvailabilityObjectType,
    getEditedAvailabilityIdField,
    getCatalogProductAutoFields,
    getCatalogVariantAutoFields,
    getCatalogAvailabilityAutoFields,

    getInitialProductIdField,
    getProductIdField,
    getInitialProductObjectType,
    getProductObjectType,
    getProduct,

    hasVariant,
    getInitialVariantIdField,
    getVariantIdField,
    getInitialVariantObjectType,
    getVariantObjectType,
    getVariant,

    hasAvailability,
    getInitialAvailabilityIdField,
    getAvailabilityIdField,
    getInitialAvailabilityObjectType,
    getAvailabilityObjectType,
    getInitialAvailabilitySkuField,
    getAvailabilitySkuField,
    getAvailability,

    getSelectedOrSavedCatalogProductSource,
    getSelectedOrSavedCatalogAvailabilitySource,

    getProductSourceQuery,
    getAvailabilitySourceQuery,

    getSelectedPreviewCatalogId,
    getProductPreviewQuery,
    getAvailabilityPreviewQuery,
};
