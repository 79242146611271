import {Page} from '@components/page';
import {Navigate, useLocation} from '@core/routes';
import {FunctionComponent} from 'react';

import {Locales} from '../../strings';
import {NotFoundContent} from './NotFoundContent';

export const NotFoundPage: FunctionComponent = () => {
    const location = useLocation();
    const fixedPath = location.pathname.replace(/\/+/g, '/'); // replace multiple slashes with a single one;
    if (location.pathname !== fixedPath) {
        return <Navigate to={fixedPath} />;
    }

    return (
        <Page title={Locales.format('notFound.metaTitle')} canRender framework="Mantine" owner="frontend-foundation">
            <NotFoundContent />
        </Page>
    );
};
