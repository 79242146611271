import {CaseClassifications, ContextField, ContextFields, DocumentSuggestions} from '@core/api';
import {ObjectReducerGenerator} from '@core/store';
import {combineReducers, ReducersMapObject} from 'redux';
import {CaseAssistPreviewActionTypes} from './CaseAssistPreviewActions';
import {CaseAssistPreviewInitialState, ICaseAssistPreviewState} from './CaseAssistPreviewConstants';

const fieldReducers = {
    subject: ObjectReducerGenerator<ContextField>(
        CaseAssistPreviewActionTypes.updateSubject,
        CaseAssistPreviewInitialState.fields.subject,
    ),
    description: ObjectReducerGenerator<ContextField>(
        CaseAssistPreviewActionTypes.updateDescription,
        CaseAssistPreviewInitialState.fields.description,
    ),
};

const reducers: ReducersMapObject<ICaseAssistPreviewState> = {
    fields: combineReducers<ContextFields>(fieldReducers),
    documentSuggestions: ObjectReducerGenerator<DocumentSuggestions>(
        CaseAssistPreviewActionTypes.updateDocumentSuggestions,
        CaseAssistPreviewInitialState.documentSuggestions,
    ),
    caseClassifications: ObjectReducerGenerator<CaseClassifications>(
        CaseAssistPreviewActionTypes.updateCaseClassifications,
        CaseAssistPreviewInitialState.caseClassifications,
    ),
};

export const caseAssistPreviewReducer = combineReducers<ICaseAssistPreviewState>(reducers);
