import {IReduxAction, IThunkAction, ListBoxReduxActions, ValidationActions} from '@coveord/plasma-react';
import {ProjectFieldSelectors} from '../selectors';
import {ProjectFieldState} from '../store/ProjectFieldStore';

export interface ProjectFieldActionsPayload {
    projectIds: string[];
}

export const ProjectFieldActionsType = {
    initializeProjectField: 'INITIALIZE_PROJECT_FIELD',
};

const initializeProjectFieldState = (projectIds: string[]): IReduxAction<ProjectFieldActionsPayload> => ({
    type: ProjectFieldActionsType.initializeProjectField,
    payload: {projectIds},
});

const initializeProjectField =
    (componentId: string, projectIds: string[]): IThunkAction<void, ProjectFieldState> =>
    async (dispatch) => {
        dispatch(initializeProjectFieldState(projectIds));
        dispatch(setProjectField(componentId, false, projectIds));
    };

const setProjectField =
    (componentId: string, dirty: boolean, projectIds: string[]): IThunkAction<void, ProjectFieldState> =>
    async (dispatch) => {
        dispatch(ListBoxReduxActions.removeListBox(componentId));
        dispatch(
            ListBoxReduxActions.addListBox(
                componentId,
                projectIds.map((newValue) => ({
                    value: newValue,
                    displayValue: newValue,
                    selected: true,
                })),
                true,
            ),
        );
        dispatch(ValidationActions.setDirty(componentId, dirty));
    };

const resetProjectField =
    (componentId: string): IThunkAction<void, ProjectFieldState> =>
    async (dispatch, getState) => {
        const initialProjects = ProjectFieldSelectors.getInitialProjects(getState());
        dispatch(setProjectField(componentId, false, initialProjects));
    };

export const ProjectFieldActions = {
    initializeProjectField,
    initializeProjectFieldState,
    resetProjectField,
    setProjectField,
};
