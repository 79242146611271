import {ActivityOperation} from '@core/api';
import {IReduxAction} from '@coveord/plasma-react';

import {OperationTypesActionPayload, OperationTypesActionTypes} from './OperationTypesActions';

export const operationTypesInitialState: ActivityOperation[] = [];

export const operationTypesReducer = (
    state: ActivityOperation[] = [],
    {type, payload}: IReduxAction<OperationTypesActionPayload>,
): ActivityOperation[] => {
    switch (type) {
        case OperationTypesActionTypes.set:
            return payload.operationTypes;
        default:
            return state;
    }
};
