import {OwnedProps, useOwnership} from '@core/debug';
import {FunctionComponent, useEffect} from 'react';

interface BackboneWrapperProps extends OwnedProps {
    componentClass: any;
    options?: any;
    recipe?: any;
    dependencies?: any;
    contentClasses?: string;
    renderDependencies?: any[];
}

/**
 * Allows to render a backbone component using react
 */
export const BackboneWrapper: FunctionComponent<BackboneWrapperProps> = ({
    componentClass,
    options = {},
    recipe = null,
    dependencies = {},
    contentClasses,
    renderDependencies = [],
    owner,
    framework,
    invisible,
}) => {
    useOwnership(owner, framework, invisible);
    useEffect(() => {
        const optionsWithDefaults = Object.assign({}, options, {
            ComponentFactory: {activeComponents: {}},
            headerContainerId: '#backbone-component-header',
            mainContainerId: '#backbone-component-content',
        });

        if (recipe) {
            const prototypes = [recipe];

            let parent = Object.getPrototypeOf(recipe);
            while (parent.name) {
                prototypes.unshift(parent);
                parent = Object.getPrototypeOf(parent);
            }

            prototypes.forEach((recipeClass: any) => {
                const properties = Object.getOwnPropertyNames(recipeClass).filter(
                    (property: string) => property !== 'prototype',
                );
                properties.forEach((dependencyName) => {
                    if (
                        recipeClass.hasOwnProperty(dependencyName) &&
                        typeof recipeClass[dependencyName] === 'function'
                    ) {
                        dependencies[dependencyName] = recipeClass[dependencyName](optionsWithDefaults, dependencies);
                    }
                });
            });
        }

        const classInstance = new componentClass(optionsWithDefaults, dependencies);
        try {
            queueMicrotask(() => {
                classInstance.start();
            });
        } catch (e) {
            console.error(e);
            classInstance.destroy();
        }
        return () => {
            classInstance.destroy();
        };
    }, [componentClass].concat(renderDependencies));

    return (
        <div className="backbone-wrapper">
            <div id="backbone-component-header" />
            <div id="backbone-component-content" className={contentClasses} />
        </div>
    );
};
