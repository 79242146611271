import {Config} from '@core/configuration';
import {CurrentOrganization} from '@core/organization';
import Registry from '@core/registry';
import {Injectable, OrganizationModel} from '@coveord/jsadmin-common';

import {Locales} from '../Locales';

export const SearchPagesConstants = {
    SortKeys: {
        Name: 'NAME',
        LastModified: 'MODIFIED',
        URL: 'URL',
    },
    Routes: {
        Base: '/search/search-pages',
        Edit: `rest/organizations/${CurrentOrganization.getId()}/pages`,
        InterfaceEditorPage: (name: string) => {
            const organization: OrganizationModel = Registry.get(Injectable.Organization);
            const pageApi = `pages/${organization.id}/${name}`;
            const addressToReturnTo = encodeURIComponent(
                `${Config.CloudPlatform.url}/admin/#${organization.id}/search/search-pages/`,
            );
            return `${Config.Search.url}/${pageApi}?edit=1&return=${addressToReturnTo}`;
        },
    },
    SearchPageEditor: {
        Inputs: {
            Name: 'Name',
            Title: 'Title',
            UIVersion: 'SearchPagesSearchUIVersionDropdown',
        },
        Tabs: {
            ConfigurationTab: {
                id: 'search-pages-configuration',
                title: Locales.format('configuration'),
            },
            AccessTab: {
                id: 'search-pages-access',
                title: Locales.format('access'),
            },
        },
        NewId: 'NEW',
    },
    Components: {
        CreationModalV2: 'SearchInterfaceCreationModalV2',
    },
};
