import _ from 'underscore';
import {MATCHES_FILTER, MATCHES_PREDICATE} from '../FilterableCollection';
import {PickySelectable} from '../PickyModels';
import {GroupItemsCollection} from './GroupItemsCollection';

export interface GroupModelAttributes {
    groupKey: string;
    groupItems?: GroupItemsCollection<PickySelectable>;
    collapsed: boolean;
}

export class GroupModel extends PickySelectable {
    get groupKey(): string {
        return this.get('groupKey');
    }

    get groupItems(): GroupItemsCollection<PickySelectable> {
        return this.get('groupItems');
    }

    set groupItems(collection: GroupItemsCollection<PickySelectable>) {
        this.set('groupItems', collection);
    }

    get isCollapsed(): boolean {
        return this.get('collapsed');
    }

    set isCollapsed(collapsed: boolean) {
        this.set('collapsed', collapsed);
    }

    constructor(attributes: GroupModelAttributes, options?) {
        super(attributes, options);
        if (_.isUndefined(attributes.groupItems)) {
            this.groupItems = new GroupItemsCollection<PickySelectable>(this, attributes.groupKey);
        }
    }

    toggleCollapsed() {
        this.isCollapsed = !this.isCollapsed;
    }

    matchesFilter(): number {
        return this.groupItems.reduce((memo: number, item: Backbone.Model) => {
            const isMatch = item[MATCHES_FILTER] !== false && item[MATCHES_PREDICATE] !== false ? 1 : 0;
            return memo + isMatch;
        }, 0);
    }
}
