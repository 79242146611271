import {notifications} from '@components/mantine';
import {
    CreateInProductExperienceResponse,
    IPXInterfaceConfiguration,
    InProductExperienceModel,
    PageModel,
    Platform,
    PlatformClientFeatures,
    PlatformNoHandlers,
} from '@core/api';
import {LaunchDarklyFeatureFlags} from '@core/feature-flags';
import {CoreProjectLaunchDarklyFlags, ProjectSelectors} from '@core/projects';
import {setKeyValue} from '@core/store';
import {track} from '@core/tracking';
import {RequestsActions} from '@coveord/jsadmin-common';
import {IThunkAction, TableWithPaginationActions, selectTab, validateInputValue} from '@coveord/plasma-react';

import {Locales} from '../Locales';
import {AdminState} from '../application/Reducers';
import {SearchPagesActions} from '../search-pages/SearchPagesActions';
import {InAppWidgetConstants} from './InAppWidgetConstants';
import {ipxModelToCreateUpdateIPXModel} from './InAppWidgetModels';
import {InAppWidgetSelectors} from './InAppWidgetSelectors';

export const InAppWidgetActionTypes = {
    fetchWidgets: 'FETCH_IN_APP_WIDGETS',
    fetchWidget: 'FETCH_IN_APP_WIDGET',
    saveWidget: 'SAVE_IN_APP_WIDGET',
    createWidget: 'CREATE_IN_APP_WIDGET',
    deleteWidget: 'DELETE_IN_APP_WIDGET',
    setWidgets: 'SET_WIDGETS',
    setV2Widgets: 'SET_V2_WIDGETS',
};

const getIPXFieldValidationConstants = function* (
    state: AdminState,
    otherWidgetsNames: string[],
    allSearchPagesNames: string[],
): Generator<{isValid: boolean; tab: string; input: string}> {
    yield {
        isValid: !InAppWidgetSelectors.getInAppWidgetNameIsInvalid(state, otherWidgetsNames, allSearchPagesNames),
        tab: InAppWidgetConstants.Tabs.MainConfig,
        input: InAppWidgetConstants.Inputs.Widgets.name,
    };
    yield {
        isValid: !InAppWidgetSelectors.getInAppWidgetTitleIsInvalid(state),
        tab: InAppWidgetConstants.Tabs.MainConfig,
        input: InAppWidgetConstants.Inputs.Widgets.title,
    };
    yield {
        isValid: !InAppWidgetSelectors.getInAppWidgetSearchHubIsInvalid(state),
        tab: InAppWidgetConstants.Tabs.MainConfig,
        input: InAppWidgetConstants.Inputs.Token.searchHub,
    };
    yield {
        isValid: !InAppWidgetSelectors.getInAppFontFamilyIsInvalid(state),
        tab: InAppWidgetConstants.Tabs.Design,
        input: InAppWidgetConstants.Inputs.Button.fontFamily,
    };
    yield {
        isValid: !InAppWidgetSelectors.getInAppFontSizeIsInvalid(state),
        tab: InAppWidgetConstants.Tabs.Design,
        input: InAppWidgetConstants.Inputs.Button.fontSize,
    };
    yield {
        isValid: !InAppWidgetSelectors.getButtonIconIsInvalid(state),
        tab: InAppWidgetConstants.Tabs.Design,
        input: InAppWidgetConstants.Inputs.Button.iconValidator,
    };
};

const fetchWidgetsNames = async () => {
    const v1Names = ((await Platform.ipx.list()) ?? []).map(({name}) => name);
    const v2Names = (
        (await PlatformNoHandlers.ipxInterface.list()) ?? {items: [], totalEntries: 0, totalPages: 0}
    ).items.map(({name}) => name);
    return v1Names.concat(v2Names);
};

const fetchAllWidgetsNames = (): IThunkAction<Promise<string[]>, AdminState> => async (dispatch) => {
    dispatch(RequestsActions.request(InAppWidgetActionTypes.fetchWidgets));

    try {
        const widgetsName = await fetchWidgetsNames();
        dispatch(RequestsActions.success(InAppWidgetActionTypes.fetchWidgets));
        return widgetsName;
    } catch (e) {
        dispatch(RequestsActions.failure(InAppWidgetActionTypes.fetchWidgets, e.message));
        notifications.showError(Locales.format('InAppWidget.fetchWidgetsErrors'));
    }
};

const fetchWidgets =
    (tableId: string): IThunkAction<Promise<void>, AdminState> =>
    async (dispatch, getState) => {
        dispatch(RequestsActions.request(InAppWidgetActionTypes.fetchWidgets));
        let v1Widgets: InProductExperienceModel[];
        let v2Widgets: PageModel<IPXInterfaceConfiguration>;
        try {
            v1Widgets = await fetchV1Widgets(getState());
            v2Widgets = await fetchV2Widgets(getState());
        } catch (e) {
            dispatch(RequestsActions.failure(InAppWidgetActionTypes.fetchWidgets));
            throw e;
        }
        dispatch(setKeyValue(InAppWidgetActionTypes.setWidgets, v1Widgets));
        dispatch(setKeyValue(InAppWidgetActionTypes.setV2Widgets, v2Widgets));
        const count = v1Widgets.length + (v2Widgets?.totalEntries ?? 0);
        dispatch(TableWithPaginationActions.setCount(tableId, count));
        dispatch(RequestsActions.success(InAppWidgetActionTypes.fetchWidgets));
    };

const getV1WidgetsByProject = async (state: AdminState) => {
    if (LaunchDarklyFeatureFlags.isActive(CoreProjectLaunchDarklyFlags.Projects)) {
        const project = ProjectSelectors.getSelectedProject(state);
        const widgets = await Platform.withFeatures(
            // eslint-disable-next-line react-hooks/rules-of-hooks
            PlatformClientFeatures.useProjectFilter(project, 'IN_PRODUCT_EXPERIENCE', '/ids'),
        ).ipx.list();

        return widgets;
    }

    return await Platform.ipx.list();
};

const fetchV1Widgets = async (state: AdminState) => {
    let response: InProductExperienceModel[] = [];
    try {
        response = await getV1WidgetsByProject(state);
    } catch (e) {
        notifications.showError(Locales.format('InAppWidget.fetchWidgetsErrors'));
    }
    return response;
};

const getV2WidgetsByProject = async (state: AdminState) => {
    if (LaunchDarklyFeatureFlags.isActive(CoreProjectLaunchDarklyFlags.Projects)) {
        const project = ProjectSelectors.getSelectedProject(state);
        return await PlatformNoHandlers.withFeatures(
            // eslint-disable-next-line react-hooks/rules-of-hooks
            PlatformClientFeatures.useProjectFilter(project, 'IN_PRODUCT_EXPERIENCE', '/bulk/get', {}, 'ids'),
        ).ipxInterfaceExperimental.list({
            page: 0,
            perPage: 100,
            order: 'asc',
        });
    }

    return await PlatformNoHandlers.ipxInterfaceExperimental.list({
        page: 0,
        perPage: 100,
        order: 'asc',
    });
};

const fetchV2Widgets = async (state: AdminState) => {
    let response: PageModel<IPXInterfaceConfiguration>;
    try {
        response = await getV2WidgetsByProject(state);
    } catch (e) {
        notifications.showError(Locales.format('InAppWidget.fetchWidgetsErrors'));
    }
    return response;
};

const fetchWidget =
    (widgetName: string): IThunkAction<Promise<InProductExperienceModel>, AdminState> =>
    async (dispatch) => {
        dispatch(RequestsActions.request(InAppWidgetActionTypes.fetchWidget));
        let widget: InProductExperienceModel;
        try {
            widget = (await PlatformNoHandlers.ipx.list(widgetName))[0];
        } catch ({errorCode}) {
            dispatch(RequestsActions.failure(InAppWidgetActionTypes.fetchWidget, {errorCode}));
            notifications.showError(Locales.format('InAppWidget.fetchWidgetError', {widgetName}));
        }
        dispatch(RequestsActions.success(InAppWidgetActionTypes.fetchWidget));
        return widget;
    };

const saveWidget =
    (originalWidget: InProductExperienceModel): IThunkAction<Promise<void>, AdminState> =>
    async (dispatch, getState) => {
        dispatch(RequestsActions.request(InAppWidgetActionTypes.saveWidget));
        const widget = InAppWidgetSelectors.getInAppWidget(originalWidget, getState());
        try {
            await PlatformNoHandlers.ipx.update(widget.id, ipxModelToCreateUpdateIPXModel(widget));
        } catch ({errorCode}) {
            dispatch(RequestsActions.failure(InAppWidgetActionTypes.saveWidget, {errorCode}));
            return notifications.showError(Locales.format('InAppWidget.saveWidgetError', {widgetName: widget.name}));
        }
        track('submitted IPX edit', {
            ipxId: originalWidget.id,
        });
        dispatch(RequestsActions.success(InAppWidgetActionTypes.saveWidget));
    };

const createIPX =
    (originalWidget: InProductExperienceModel): IThunkAction<Promise<CreateInProductExperienceResponse>, AdminState> =>
    async (dispatch, getState) => {
        dispatch(RequestsActions.request(InAppWidgetActionTypes.createWidget));
        const widget = InAppWidgetSelectors.getInAppWidget(originalWidget, getState());
        let newWidget: CreateInProductExperienceResponse;
        try {
            newWidget = await PlatformNoHandlers.ipx.create(ipxModelToCreateUpdateIPXModel(widget));
        } catch ({errorCode}) {
            dispatch(RequestsActions.failure(InAppWidgetActionTypes.createWidget, {errorCode}));
            notifications.showError(Locales.format('InAppWidget.createWidgetError', {widgetName: widget.name}));
            return newWidget;
        }
        track('submitted IPX add', {
            ipxId: originalWidget.id,
        });
        dispatch(RequestsActions.success(InAppWidgetActionTypes.createWidget));
        return newWidget;
    };

const deleteIPX =
    (widget: InProductExperienceModel): IThunkAction<Promise<void>, AdminState> =>
    async (dispatch) => {
        dispatch(RequestsActions.request(InAppWidgetActionTypes.deleteWidget));
        try {
            await PlatformNoHandlers.ipx.delete(widget.id);
        } catch ({errorCode}) {
            dispatch(RequestsActions.failure(InAppWidgetActionTypes.deleteWidget, {errorCode}));
            return notifications.showError(Locales.format('InAppWidget.deleteWidgetError', {widgetName: widget.name}));
        }
        dispatch(RequestsActions.success(InAppWidgetActionTypes.deleteWidget));
        track('deleted IPX', {ipxId: widget.id});
    };

const deleteIPXv2 =
    (ipx: IPXInterfaceConfiguration): IThunkAction<Promise<void>, AdminState> =>
    async (dispatch) => {
        dispatch(RequestsActions.request(InAppWidgetActionTypes.deleteWidget));

        try {
            PlatformNoHandlers.ipxInterface.delete(ipx.id);
        } catch ({errorCode}) {
            dispatch(RequestsActions.failure(InAppWidgetActionTypes.deleteWidget, {errorCode}));
            return notifications.showError(Locales.format('InAppWidget.deleteWidgetError', {widgetName: ipx.name}));
        }
        dispatch(RequestsActions.success(InAppWidgetActionTypes.deleteWidget));
        return track('deleted IPX', {ipxId: ipx.id});
    };

const fetchOtherWidgetNames = async (initialName: string) => {
    const allNames = await fetchWidgetsNames();
    return allNames.filter((name) => name !== initialName);
};

const validate =
    (originalWidget: InProductExperienceModel): IThunkAction<Promise<boolean>, AdminState> =>
    async (dispatch, getState) => {
        const state = getState();

        const invalidUserIdentities = InAppWidgetSelectors.getInAppWidgetTokenUserIdentitiesInputsStateInvalid(state);
        if (invalidUserIdentities.length !== 0) {
            invalidUserIdentities.forEach(({id}) => dispatch(validateInputValue(id, false)));
            dispatch(selectTab(InAppWidgetConstants.Tabs.SearchRelevance));
            return false;
        }

        let otherWidgetsNames: string[] = [];
        let allSearchPagesNames: string[] = [];
        try {
            [otherWidgetsNames, allSearchPagesNames] = await Promise.all([
                fetchOtherWidgetNames(originalWidget.name),
                SearchPagesActions.fetchSearchPagesNames(),
            ]);
        } catch (e) {
            return false;
        }

        const handleFieldValidity = ({isValid, tab, input}: {isValid: boolean; tab: string; input: string}) => {
            if (isValid) {
                return true;
            }
            dispatch(selectTab(tab));
            dispatch(validateInputValue(input, false));
            return false;
        };
        const generator = getIPXFieldValidationConstants(state, otherWidgetsNames, allSearchPagesNames);
        for (const constants of generator) {
            if (!handleFieldValidity(constants)) {
                return false;
            }
        }
        return true;
    };

export const InAppWidgetActions = {
    fetchWidgets,
    fetchAllWidgetsNames,
    fetchWidget,
    saveWidget,
    createIPX,
    deleteIPX,
    deleteIPXv2,
    validate,
};
