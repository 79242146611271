import Registry from '@core/registry';
import _ from 'underscore';

import {UAGroupAttributes} from '../../../../groups/UAGroup';
import {UAInjectable} from '../../../../UAInjectable';
import {UALoggedUser} from '../../../../users/UALoggedUser';
import {UAUserAttributes} from '../../../../users/UAUser';
import {ReportAccessIdentityTypes} from '../ReportAccessIdentityTypes';
import {ReportAccessEditAttributes, ReportAccessEditIdentityState, ReportAccessState} from '../ReportAccessState';

export class ReportAccessReducerUtils {
    static SelectedIdentities(state: ReportAccessState): string[] {
        return _.map(state.identities, (identity: ReportAccessEditIdentityState) => identity.appliedState.identity);
    }

    static GenerateIdentityId(): string {
        return _.uniqueId('reportAccessIdentity');
    }

    static GetUsersWithoutSelected(state: ReportAccessState): UAUserAttributes[] {
        const selectedIdentities = ReportAccessReducerUtils.SelectedIdentities(state);
        return _.reject(state.users, (user: UAUserAttributes) => _.contains(selectedIdentities, user.username));
    }

    static GetGroupsWithoutSelected(state: ReportAccessState): UAGroupAttributes[] {
        const selectedIdentities = ReportAccessReducerUtils.SelectedIdentities(state);
        return _.reject(state.groups, (group: UAGroupAttributes) => _.contains(selectedIdentities, group.id));
    }

    static GetAddEditionState(state: ReportAccessState) {
        let editionState: ReportAccessEditAttributes = {
            identity: '',
            identityType: ReportAccessIdentityTypes.User,
        };

        const usersWithoutSelected = ReportAccessReducerUtils.GetUsersWithoutSelected(state);
        if (usersWithoutSelected.length) {
            editionState = {
                identity: usersWithoutSelected[0].username,
                identityType: ReportAccessIdentityTypes.User,
            };
        } else {
            const groupsWithoutSelected = ReportAccessReducerUtils.GetGroupsWithoutSelected(state);
            if (groupsWithoutSelected.length) {
                editionState = {
                    identity: groupsWithoutSelected[0].id,
                    identityType: ReportAccessIdentityTypes.Group,
                };
            }
        }
        return editionState;
    }

    static GenerateCurrentUserIdentity(): ReportAccessEditIdentityState {
        const user: UALoggedUser = Registry.get(UAInjectable.User);
        const userState: ReportAccessEditAttributes = {
            identity: user.id,
            identityType: ReportAccessIdentityTypes.User,
        };

        return {
            appliedState: _.extend({}, userState),
            editionState: _.extend({}, userState),
            id: ReportAccessReducerUtils.GenerateIdentityId(),
            isOpen: false,
        };
    }
}
