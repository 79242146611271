import {PlatformPaths} from '@configurations/platform';
import {RouteObject} from '@core/routes';

export const routes: RouteObject[] = [
    {
        path: `${PlatformPaths.ContentBrowser.fullPath}/*`,
        lazy: async () => {
            const {ContentBrowserPage} = await import('../pages/ContentBrowserPage');
            return {Component: ContentBrowserPage};
        },
    },
];
