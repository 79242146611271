import {IReduxAction} from '@coveord/plasma-react';
import _ from 'underscore';

import {UALocales} from '../../../UALocales';
import {
    ChangeTemplateParameterValuePayload,
    ClearTemplateParametersPayload,
    FetchParamSuggestionFailurePayload,
    FetchParamSuggestionSuccessPayload,
    ReportTemplateActionsType,
    SelectTemplatePayload,
    SetTemplatesPayload,
} from './actions/ReportTemplateActions';
import {ReportTemplateChildParameter, ReportTemplateParameter} from './models/ReportTemplateModel';
import {ReportTemplateState} from './ReportTemplateState';

export const ReportTemplateDefaultState: ReportTemplateState = {
    templates: null,
    selected: null,
    params: {},
};

const isChildParameter = (
    param: ReportTemplateParameter | ReportTemplateChildParameter,
): param is ReportTemplateChildParameter => !_.isUndefined((param as any).unique);

const setTemplatesReducer = (state: ReportTemplateState, action: IReduxAction<SetTemplatesPayload>) =>
    _.extend({}, state, {templates: action.payload.collection});

const selectTemplateReducer = (state: ReportTemplateState, action: IReduxAction<SelectTemplatePayload>) =>
    _.extend({}, state, {selected: action.payload.model});

const clearTemplateParametersReducer = (
    state: ReportTemplateState,
    action: IReduxAction<ClearTemplateParametersPayload>,
) => _.extend({}, state, {params: {}});

const ChangeTemplateParameterValueReducer = (
    state: ReportTemplateState,
    action: IReduxAction<ChangeTemplateParameterValuePayload>,
) => {
    let params = {...state.params};
    const id = action.payload.param.id;
    const validationError =
        action.payload.value === '' && action.payload.param.mandatory
            ? UALocales.format('Reports.Template.valueIsMandatory')
            : null;
    const param = _.extend({}, state.params[id] || {}, {
        validationError,
        value: action.payload.value,
        type: action.payload.param.type,
    });
    const actionParam = action.payload.param;
    if (isChildParameter(actionParam) && action.payload.parentId) {
        const parent: ReportTemplateParameter = _.findWhere(state.selected.params, {
            id: action.payload.parentId,
        }) as any;
        const childrenIds = _.pluck(parent.children, 'id');
        _.each(childrenIds, (childrenId: string) => {
            const child = _.extend({}, state.params[childrenId] || {}, {without: action.payload.value});
            _.extend(params, {[childrenId]: child});
        });
    } else {
        const hasValue = action.payload.value !== '';

        _.each(actionParam.children, (child: ReportTemplateChildParameter) => {
            const childParam = state.params[child.id];

            params = {
                ...params,
                [child.id]: {
                    ...childParam,
                    validationError: null,
                    value: hasValue ? null : childParam.value,
                    suggestions: hasValue ? null : childParam.suggestions,
                },
            };
        });
    }

    params = {...params, [id]: param};
    return {...state, params};
};

const fetchParamSuggestionSuccessReducer = (
    state: ReportTemplateState,
    action: IReduxAction<FetchParamSuggestionSuccessPayload>,
) => {
    const suggestions = action.payload.suggestions;
    const id = action.payload.param.id;
    const param = _.extend({}, state.params[id] || {}, {
        suggestions: suggestions,
        mandatory: action.payload.param.mandatory !== false,
    });

    if (param.mandatory && suggestions.length === 0) {
        param.validationError = UALocales.format('Reports.Template.errorNoSuggestionsTitle');
    }

    const params = _.extend(state.params, {[id]: param});
    return _.extend({}, state, {params: params});
};

const fetchParamSuggestionFailureReducer = (
    state: ReportTemplateState,
    action: IReduxAction<FetchParamSuggestionFailurePayload>,
) => {
    const id = action.payload.param.id;
    const param = _.extend({}, state.params[id] || {}, {
        suggestions: [],
        error: {
            title: action.payload.error,
        },
    });

    const errorDescription = UALocales.formatOrDefault(
        'Reports.Template'[`parameter_${state.selected.id}_${id}_ErrorNoSuggestionsDescription`],
        {defaultTranslation: ''},
    );
    param.error.description = errorDescription || UALocales.format('Reports.Template.errorNoSuggestionsTitle');

    const params = _.extend(state.params, {[id]: param});
    return _.extend({}, state, {params: params});
};

const ReportTemplatesActionReducers: {[key: string]: (...args: any[]) => any} = {
    [ReportTemplateActionsType.SetTemplates]: setTemplatesReducer,
    [ReportTemplateActionsType.SelectTemplate]: selectTemplateReducer,
    [ReportTemplateActionsType.ClearTemplateParameters]: clearTemplateParametersReducer,
    [ReportTemplateActionsType.ChangeTemplateParameterValue]: ChangeTemplateParameterValueReducer,
    [ReportTemplateActionsType.FetchParamSuggestionSuccess]: fetchParamSuggestionSuccessReducer,
    [ReportTemplateActionsType.FetchParamSuggestionFailure]: fetchParamSuggestionFailureReducer,
};

export interface ReportTemplateActionPayloads
    extends SetTemplatesPayload,
        SelectTemplatePayload,
        ClearTemplateParametersPayload,
        ChangeTemplateParameterValuePayload,
        FetchParamSuggestionSuccessPayload {
    // nothing to add
}

export const ReportTemplateReducer = (
    state: ReportTemplateState = ReportTemplateDefaultState,
    action: IReduxAction<ReportTemplateActionPayloads>,
) => {
    let newState = state;
    if (!_.isUndefined(ReportTemplatesActionReducers[action.type])) {
        newState = ReportTemplatesActionReducers[action.type](state, action);
    }
    return newState;
};
