import isEqual from 'fast-deep-equal';

import {DebugStoreType, DebugStoreTypeKey} from '../types';

type StoreOverloadedType = Partial<DebugStoreType>;

const getStorage = (): StoreOverloadedType => JSON.parse(localStorage.getItem('debugBar-overloaded') ?? '{}');
const resetStorage = (): void => {
    localStorage.removeItem('debugBar-overloaded');
};
const setStorage = (storeOverloaded: StoreOverloadedType): void => {
    localStorage.setItem('debugBar-overloaded', JSON.stringify(storeOverloaded));
};

const getByKey = (key: DebugStoreTypeKey): DebugStoreType[DebugStoreTypeKey] | undefined => {
    const storeOverloaded = getStorage();

    return storeOverloaded[key];
};

const isKeyActive = (store: DebugStoreType, key: DebugStoreTypeKey): boolean => {
    const storeOverloaded = getStorage();
    if (storeOverloaded[key] === undefined) {
        return false;
    }

    return !isEqual(store[key], storeOverloaded[key]);
};

const resetByKey = (key: DebugStoreTypeKey): void => {
    const storeOverloaded = getStorage();
    storeOverloaded[key] = undefined;
    setStorage(storeOverloaded);
};

export const StoreOverloaded = {
    get: getStorage,
    getByKey,
    isKeyActive,
    reset: resetStorage,
    resetByKey,
    set: setStorage,
};
