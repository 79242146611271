// eslint-disable-next-line import/no-deprecated
import {callIfDefined} from '@coveord/plasma-react';
import _ from 'underscore';
import * as s from 'underscore.string';

export const TabsBehaviorEvents = {
    SwitchTab: 'tabs-behavior:switch-tab',
};

export interface Tabs {
    [tabName: string]: string;
}

export interface InjectedTab {
    key: string;
    label: string;
    url: string;
}

export interface TabsBehaviorOptions {
    tabs: Tabs;
    defaultTab: string;
    onClickTab?: (...args: any[]) => any;
}

export class TabsBehavior extends Marionette.Behavior {
    private previous: string;

    constructor(options: TabsBehaviorOptions, view) {
        super(options, view);

        this.ui = {
            tabs: '.tab-navigation .tab',
            activeTab: '.tab-navigation .tab.active',
            tabPanes: '.tab-content .tab-pane',
        };

        // This is adding those methods to the view using the behavior
        this.view.getActiveTab = (): string => this.getActiveTab();

        this.view.getPreviousTab = (): string => (_.isEmpty(this.previous) ? options.defaultTab : this.previous);

        this.view.setActiveTab = (tabName: string) => {
            this.setActiveTab(tabName);
        };

        // options can be an object of type TabsBehaviorOptions or Tabs
        this.view.addRegions(this.convertTabNamesToRegion(options.tabs || options));

        this.listenTo(view, TabsBehaviorEvents.SwitchTab, this.setActiveTab);
    }

    events() {
        return {
            'click .tab-navigation .tab.enabled': this.options.onClickTab || this.onClickTab,
        };
    }

    private convertTabNamesToRegion(tabs) {
        return _.chain(tabs)
            .values()
            .map((value: any) => [s.decapitalize(value) + 'Region', '#' + value])
            .object()
            .value();
    }

    private getActiveTab(): string {
        // Using this.ui isn't working here because it's always returning the first active tab, so we use jQery.find instead.
        return this.$el.find('.tab-navigation .tab.active').data('tab') || '';
    }

    private onClickTab(event: JQuery.TriggeredEvent) {
        event.stopPropagation();
        const tabName = $(event.currentTarget).data('tab');
        this.view.setActiveTab(tabName);
    }

    private setActiveTab(tabName: string) {
        if (this.getActiveTab() !== tabName && typeof this.ui.tabs !== 'string') {
            this.previous = this.getActiveTab();

            this.ui.tabs.removeClass('active');
            this.ui.tabPanes.removeClass('active');

            const previousActiveTab = this.$el.find('div.active-tab-bottom-line');
            previousActiveTab.removeClass('active');

            const tab = this.$el.find('[data-tab=' + tabName + ']');
            tab.addClass('active');

            const activeTabButtomBar = this.$el.find('[data-buttom-bar=' + tabName + ']');
            activeTabButtomBar.addClass('active-tab-bottom-line active');

            const pane = this.$el.find('#' + tabName);
            pane.addClass('active');

            // eslint-disable-next-line import/no-deprecated
            callIfDefined(this.view.onAfterTabChanged);
        }
    }
}
