import {FilterConditions} from '@core/api';
import {BasePayload, IReduxAction} from '@coveord/plasma-react';
import {FieldFilterActionPayload, FieldFilterActionTypes} from '../actions/FieldFilterActions';
import {FieldFilterSetPayload} from './FieldFilterSetReducers';

export interface FieldFilterState {
    id: string;
    filter: FilterConditions;
    fieldFilterSetId: string;
}

export interface FieldFilterStates {
    [id: string]: FieldFilterState;
}

const add = (state: FieldFilterStates, action: IReduxAction<FieldFilterActionPayload>) => ({
    ...state,
    [action.payload.id]: action.payload,
});

const remove = (state: FieldFilterStates, action: IReduxAction<BasePayload>) => _.omit(state, action.payload.id);

const edit = (state: FieldFilterStates, action: IReduxAction<FieldFilterActionPayload>) => ({
    ...state,
    ...action.payload,
});

const FieldFilterReducers: {[key: string]: (...args: any[]) => FieldFilterStates} = {
    [FieldFilterActionTypes.add]: add,
    [FieldFilterActionTypes.remove]: remove,
};

const FieldFilterReducer: {[key: string]: (...args: any[]) => FieldFilterState} = {
    [FieldFilterActionTypes.edit]: edit,
};

export const fieldFilterReducer = (
    state: FieldFilterStates = {},
    action: IReduxAction<FieldFilterSetPayload>,
): FieldFilterStates => {
    if (!_.isUndefined(FieldFilterReducers[action.type])) {
        return FieldFilterReducers[action.type](state, action);
    }

    if (action?.payload?.id && state[action.payload.id] && !_.isUndefined(FieldFilterReducer[action.type])) {
        return {
            ...state,
            [action.payload.id]: FieldFilterReducer[action.type](state[action.payload.id], action),
        };
    }

    return state;
};
