/* eslint-disable @helpers/no-then-catch-finally */
import {AccessTokenScopes, Config} from '@core/configuration';
import {CurrentOrganization} from '@core/organization';
import {cleanupSearchParams} from '@core/routes';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import {AuthenticationEvents} from './constants';
import {GlobalEvents} from './constants/GlobalEvents';
import {AuthenticationTracking} from './tracking';
import {AccessTokenUtils, getCleanRedirectURI} from './utils';

dayjs.extend(duration);
dayjs.extend(isSameOrAfter);

const OAUTH2_ERROR_PARAM = 'error=';

let tokenExpirationTimer: number;

interface TokenRenewalParameters {
    url: string;
    w: number;
    h: number;
}

export const tokenRenewalProps = {
    promptId: 'token-renewal-prompt',
    verificationDelay: dayjs.duration(1, 'minutes'),
    threshold: dayjs.duration(15, 'minutes'),
    windowWidth: 600,
    windowHeight: 650,
};

export const startTokenExpirationTimer = () => {
    tokenExpirationTimer = window.setInterval(
        verifyTokenExpiration,
        tokenRenewalProps.verificationDelay.asMilliseconds(),
    );
};

export const doLogout = () => {
    const storedAccessTokenBeforeClearing = AccessTokenUtils.getToken();
    clearAccessTokenAndCache();

    // Do a logout on the platform as well, and continue to a new login or specified page.
    AuthenticationTracking.trackLogout();
    sendLogoutRequest(storedAccessTokenBeforeClearing);
};

export const clearAccessToken = () => {
    clearTokenExpirationTimeout();
    AccessTokenUtils.clear();
};

export const doAuthorizeUser = () => {
    clearAccessTokenAndCache();

    if (window.location.href.includes(OAUTH2_ERROR_PARAM)) {
        clearOAuth2Error();
        doLogout();
        return;
    }
    let redirectUrl = window.location.href;

    // If we are here because of the /#newsession route, use the base admin URL as redirect to prevent redirection loop.
    if (window.location.hash.includes('newsession')) {
        const urlWithoutHash = window.location.href.replace(window.location.hash, '');
        const hashWithoutNewSession = window.location.hash.replace('newsession', '');
        redirectUrl = `${urlWithoutHash}${hashWithoutNewSession}`;
    }

    const authorizeUrl = getAuthorizeUrl(redirectUrl);

    window.location.assign(authorizeUrl);
};

export const getTokenRenewalParams = (provider: string) => {
    const redirectUri = `${window.location.origin}${window.location.pathname.replace(
        '/index.html',
        '/',
    )}platformOauthRedirectHandler.html`;
    const authorizeOptions = {
        provider,
        skipLoginPage: provider === 'SAML',
    };

    const params: TokenRenewalParameters = {
        url: getAuthorizeUrl(redirectUri, authorizeOptions),
        w: tokenRenewalProps.windowWidth,
        h: tokenRenewalProps.windowHeight,
    };

    return params;
};

/* * * * * * * * * * * * * *
 *   Private functions
 * * * * * * * * * ** * * * */

const clearOAuth2Error = () => {
    window.location.href = window.location.href.replace(new RegExp(`&?${OAUTH2_ERROR_PARAM}.*`, 'i'), '');
};

const clearTokenExpirationTimeout = () => {
    clearInterval(tokenExpirationTimer);
};

const verifyTokenExpiration = () => {
    const expiration = dayjs(AccessTokenUtils.getExpiration());
    const now = dayjs();
    const diff = dayjs.duration(expiration.diff(now));
    const isTokenStillActive = dayjs(expiration).isSameOrAfter(now);
    const needRenewal = diff.asMilliseconds() <= tokenRenewalProps.threshold.asMilliseconds();

    if (isTokenStillActive && needRenewal) {
        clearTokenExpirationTimeout();
        const event = new Event(AuthenticationEvents.ShowTokenRenewalPrompt, {bubbles: true});
        document.dispatchEvent(event);
    }
};

const clearAccessTokenAndCache = () => {
    const event = new Event(GlobalEvents.AppIsUnloading, {bubbles: true});
    document.dispatchEvent(event);

    clearAccessToken();
    localStorage.removeItem('backboneCache');
};

const sendLogoutRequest = (accessToken: string) => {
    const logoutUrl = new URL('/logout', Config.CloudPlatform.url);
    const search = new URLSearchParams(
        cleanupSearchParams({
            organizationId: CurrentOrganization.getId(),
            continue: getAuthorizeUrl(`${window.location.origin}${window.location.pathname}${window.location.search}`),
        }),
    );

    logoutUrl.search = search.toString();
    fetch(logoutUrl.toString(), {
        method: 'GET',
        headers: {Authorization: `Bearer ${accessToken}`},
        redirect: 'manual',
    })
        .then((response) => {
            window.location.assign(response.url);
        })
        .catch((error) => {
            console.error(error);
        });
};

const getAuthorizeUrl = (redirectUri: string, queryParams?: {[key: string]: any}): string => {
    const orgId = CurrentOrganization.getId();
    const redirectURL = getCleanRedirectURI(redirectUri);
    const search = new URLSearchParams(
        cleanupSearchParams({
            client_id: Config.CloudPlatform.clientId,
            response_type: Config.CloudPlatform.responseType,
            redirect_uri: redirectURL,
            scope: Object.values(AccessTokenScopes).join(' '),
            organizationId: orgId === 'newsession' ? undefined : orgId,
            ...queryParams,
        }),
    );

    const authorizeUrl = new URL(Config.CloudPlatform.authorizePath, Config.CloudPlatform.url);
    authorizeUrl.search = search.toString();

    return authorizeUrl.toString();
};
