<% const PlasmaStyle = require('@coveord/plasma-style'); %>

<<%- ctx.tag %> class="btn dropdown-toggle pr5" type="button" id="<%- ctx.dropdownToggleId() %>" data-toggle="dropdown"
        title="<%- ctx.currentSelectedDescription() %>" <%- ctx.customAttributes() %> >

    <% if (!_.isEmpty(ctx.prependText())) { %>
    <span class="dropdown-prepend"><%- ctx.prependText() %></span>
    <% } %>

    <% var iconClass = ctx.currentSelectedValueIcon();
    if (PlasmaStyle.svg && PlasmaStyle.svg[iconClass]) { %>
    <%= PlasmaStyle.svg[iconClass].render('icon ' + ctx.currentSelectedSvgClass(), ctx.currentSelectedSvgSpanClass() + ' value-icon', s.stripTags(ctx.currentSelectedValue())) %>
    <% } else if (iconClass && s.contains(iconClass, 'coveo-icon-')) { %>
    <%= PlasmaStyle.SVG(iconClass, 'icon icon-small', ctx.currentSelectedSvgSpanClass() + ' value-icon', s.stripTags(ctx.currentSelectedValue())) %>
    <% } else if (iconClass) { %>
    <i class="<%- iconClass %>" title="<%- s.stripTags(ctx.currentSelectedValue()) %>"></i>
    <% } %>

    <span class="dropdown-selected-value"><%- ctx.currentSelectedValue() %></span>
    <span class="dropdown-toggle-arrow"></span>
</<%- ctx.tag %>>
<ul class="dropdown-menu" role="menu"></ul>
