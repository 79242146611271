import {notifications} from '@components/mantine';
import {CaseAssistConfigModel, New, Platform, PlatformNoHandlers} from '@core/api';
import {RequestsActions} from '@coveord/jsadmin-common';
import {closeModal, IThunkAction} from '@coveord/plasma-react';
import {HandleSaveProjectAssociationCallbackMethod} from '@components/projects';

import {ComponentIds} from '../../components/ComponentIds';
import {locales} from '../../locales/Locales';
import {ServiceState} from '../../ServiceState';
import {CreateCaseAssistConfigModalSelectors} from './CreateCaseAssistConfigModalSelectors';

export const CreateCaseAssistConfigActionTypes = {
    fetchAll: 'FETCH_ALL_CASE_ASSIST_CONFIGS',
    create: 'CREATE_CASE_ASSIST_CONFIG',
    setCreatedConfigId: 'SET_CREATED_CASE_ASSIST_CONFIG_ID',
    clearCreatedConfigId: 'CLEAR_CREATED_CASE_ASSIST_CONFIG_ID',
};

const fetchAllCaseAssistConfigs = (): IThunkAction<Promise<CaseAssistConfigModel[]>, ServiceState> => (dispatch) => {
    const makeRequest = async () => {
        const result = await Platform.caseAssistConfig.list();
        return result.configurations;
    };
    return dispatch(RequestsActions.handle(CreateCaseAssistConfigActionTypes.fetchAll, makeRequest));
};

const createCaseAssistConfig =
    (handleSaveProjectAssociation: HandleSaveProjectAssociationCallbackMethod): IThunkAction<void, ServiceState> =>
    async (dispatch, getState) => {
        const state = getState();
        const projectIds = CreateCaseAssistConfigModalSelectors.getNewCaseAssistAssociatedProjectIds(state);
        const configToSave: New<CaseAssistConfigModel> = {
            name: CreateCaseAssistConfigModalSelectors.getNewCaseAssistConfigName(state),
        };
        dispatch(RequestsActions.request(CreateCaseAssistConfigActionTypes.create));
        try {
            const config = await PlatformNoHandlers.caseAssistConfig.create(configToSave);
            // Summit: save associated projects
            await handleSaveProjectAssociation({
                resourceId: config.id,
                newAssociatedProjectIds: projectIds,
            });
            dispatch(RequestsActions.success(CreateCaseAssistConfigActionTypes.create));
            dispatch(CreateCaseAssistConfigActions.setCreatedConfigId(config.id));
        } catch (exception) {
            dispatch(RequestsActions.failure(CreateCaseAssistConfigActionTypes.create, exception));
            notifications.showError(locales.format('createCaseAssistConfigFailed'));
            dispatch(closeModal(ComponentIds.CaseAssist.CreateCaseAssistConfig));
        }
    };

const setCreatedConfigId = (configId: string) => ({
    type: CreateCaseAssistConfigActionTypes.setCreatedConfigId,
    payload: {
        id: configId,
    },
});

const clearCreatedConfigId = () => ({
    type: CreateCaseAssistConfigActionTypes.clearCreatedConfigId,
    payload: {},
});

export const CreateCaseAssistConfigActions = {
    fetchAllCaseAssistConfigs,
    createCaseAssistConfig,
    setCreatedConfigId,
    clearCreatedConfigId,
};
