import {RouteObject} from '@core/routes';

export const routes: RouteObject[] = [
    {
        path: '/:orgId/usage/health/*',
        lazy: async () => {
            const {DataHealthPage} = await import('../components/DataHealthPage');
            return {Component: DataHealthPage};
        },
    },
];
