import {Box, Tooltip} from '@components/mantine';
import {PrivilegeModel} from '@core/api';
import {PrivilegeUtils} from '@core/user';
import {AttentionSize16Px, CircleArrowBottomSize16Px} from '@coveord/plasma-react-icons';
import {PureComponent, ReactNode} from 'react';
import {Locales} from '../../Locales';
import {LocalesKeys} from '../../LocalesKeys';

export interface PrivilegeWarningProps {
    type: string;
    affectedPrivileges?: PrivilegeModel[];
}

export class PrivilegeWarning extends PureComponent<PrivilegeWarningProps> {
    static Type = {
        Lockout: 'LOCKOUT',
        Reduction: 'REDUCTION',
        Potent: 'POTENT',
    };

    render() {
        return (
            <Tooltip
                maw={300}
                label={Locales.format(`warningTooltip_${this.props.type}` as LocalesKeys, {
                    formattedPrivilegesPassive: PrivilegeUtils.toSentence(this.props.affectedPrivileges),
                    formattedPrivilegesActive: PrivilegeUtils.toSentence(this.props.affectedPrivileges, true),
                    smart_count: (this.props.affectedPrivileges && this.props.affectedPrivileges.length) || 0,
                })}
            >
                <Box ml="xs" c="warning">
                    {this.privilegeWarningIconMappings[this.props.type]}
                </Box>
            </Tooltip>
        );
    }

    private privilegeWarningIconMappings: Record<string, ReactNode> = {
        [PrivilegeWarning.Type.Reduction]: <CircleArrowBottomSize16Px height={16} />,
        [PrivilegeWarning.Type.Lockout]: <AttentionSize16Px height={16} />,
        [PrivilegeWarning.Type.Potent]: <AttentionSize16Px height={16} />,
    };
}
