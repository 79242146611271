import {DateRangeAttributes} from '@coveord/jsadmin-common';
import {IItemBoxProps} from '@coveord/plasma-react';
import {DateChartType} from '../../../charts/DateChart';

export interface EditTimeseriesState {
    title: string;
    dateRange: DateRangeAttributes;
    displayAs: string;
    headerHref: string;
    headerHrefs: IItemBoxProps[];
    showMetrics: boolean;
    showTrend: boolean;
    showAverage: boolean;
    showPeak: boolean;
}

export const EditTimeseriesDefaultState: EditTimeseriesState = {
    title: '',
    dateRange: {},
    displayAs: DateChartType.Line,
    headerHref: '',
    headerHrefs: [],
    showMetrics: false,
    showTrend: false,
    showAverage: true,
    showPeak: true,
};
