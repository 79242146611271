import {IFacet} from '@coveord/plasma-react';

export const LogsFacetTypes = {
    sourcesIds: 'sourcesIds',
    operations: 'operations',
    results: 'results',
    tasks: 'tasks',
    resourceIds: 'resourceIds',
};

export const needsFormatFacets: string[] = [
    LogsFacetTypes.operations,
    LogsFacetTypes.results,
    LogsFacetTypes.tasks,
    LogsFacetTypes.resourceIds,
];

export interface LogFacetsStatsRow {
    [row: string]: number;
}
export interface LogFacetsStats {
    sourcesIds?: LogFacetsStatsRow;
    operations?: LogFacetsStatsRow;
    results?: LogFacetsStatsRow;
    tasks?: LogFacetsStatsRow;
    resourceIds?: LogFacetsStatsRow;
    [key: string]: LogFacetsStatsRow;
}

export interface ILogsFacets {
    sourcesIds?: IFacet[];
    operations?: IFacet[];
    results?: IFacet[];
    tasks?: IFacet[];
    resourceIds?: IFacet[];
    [key: string]: IFacet[];
}

export const logsFacetsInitialState: ILogsFacets = {
    sourcesIds: [],
    operations: [],
    results: [],
    tasks: [],
    resourceIds: [],
};

export interface ILogsFacetsForAPI {
    sourcesIds?: string[];
    operations?: string[];
    results?: string[];
    tasks?: string[];
    resourceIds?: string[];
    [key: string]: string[];
}
