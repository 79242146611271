import {Platform} from '@core/api';
import {RequestsActions} from '@coveord/jsadmin-common';
import {UserSelectors} from '@core/user';
import {ExtensionModel, FacetsModel, FacetsWithCountsModel, GetFacetsParams} from '@core/api';
import {
    facetsInitialState,
    IFacet,
    IReduxAction,
    ITableHOCCompositeState,
    IThunkAction,
    TableHOCUtils,
} from '@coveord/plasma-react';
import _ from 'underscore';

import {LOGS_TABLE_ID} from '../Constants';
import {ILogBrowserState} from '../LogBrowserReducers';
import {FacetRowFormatter} from '../utils/FacetRowFormatter';
import {ILogsFacets, LogFacetsStatsRow} from './LogsFacetsConstants';

export const LogsFacetsActionsType = {
    update: 'UPDATE_LOGS_FACETS',
    fetch: 'FETCH_LOGS_FACETS',
    fetchResourceName: 'FETCH_RESOURCE_NAMES',
    facetsStatsFetch: 'FACET_STATS_FETCH',
    facetsFetch: 'FACET_FETCH',
};

export interface ILogsFacetsActionPayload {
    facets: ILogsFacets;
}

const update = (facets: ILogsFacets): IReduxAction<ILogsFacetsActionPayload> => ({
    type: LogsFacetsActionsType.update,
    payload: {
        facets,
    },
});

const fetch = (): IThunkAction<void | Promise<any>, ILogBrowserState> => (dispatch, getState) => {
    const currentState = getState();
    const {dateLimits, filter}: ITableHOCCompositeState = TableHOCUtils.getCompositeState(LOGS_TABLE_ID, currentState);
    if (dateLimits?.[0]) {
        const params: GetFacetsParams = {
            from: dateLimits[0].toISOString(),
            to: dateLimits[1].toISOString(),
            documentId: filter,
        };
        const facetsToUpdate: ILogsFacets = {};

        const makeRequest = () =>
            Promise.all([
                dispatch(LogsFacetsActions.facetsFetch(params)),
                dispatch(LogsFacetsActions.facetsStatsFetch(params)),
                dispatch(LogsFacetsActions.fetchResourceName()),
            ]).then(([facetsModel, facetsWithCountsModel, resourcesNames]) => {
                _.each<any>(facetsModel, (facet: string[], key: string) => {
                    facetsToUpdate[key] = _.map(facet, (value: string) => ({name: value, formattedName: ''}));
                });
                _.each<any>(facetsWithCountsModel, (row: LogFacetsStatsRow, key: string) => {
                    const formatted = FacetRowFormatter.formatFacetFromAPI(row);
                    facetsToUpdate[key] = _.map(facetsToUpdate[key], (facet: IFacet) => {
                        const count = (_.findWhere(formatted, {name: facet.name}) || {count: ''}).count;
                        return {...facet, count};
                    });
                });
                _.each(facetsToUpdate.resourceIds, (facet: IFacet) => {
                    if (facet.name in resourcesNames) {
                        facet.formattedName = resourcesNames[facet.name].name;
                        facet.tooltipLabel = facet.name;
                    }
                });
                dispatch(LogsFacetsActions.update(facetsToUpdate));
            });
        return dispatch(RequestsActions.handle(LogsFacetsActionsType.fetch, makeRequest));
    }
};

const facetsFetch =
    (params: GetFacetsParams): IThunkAction<Promise<FacetsModel>, ILogBrowserState> =>
    () =>
        Platform.logs.getFacets(params, FacetRowFormatter.formatRowsForAPI(facetsInitialState));

const facetsStatsFetch =
    (params: GetFacetsParams): IThunkAction<Promise<FacetsWithCountsModel>, ILogBrowserState> =>
    (dispatch, getState) =>
        Platform.logs.getFacetsStats(params, FacetRowFormatter.formatRowsForAPI(getState().facets));

const fetchResourceName =
    (): IThunkAction<Promise<Record<string, ExtensionModel>>, ILogBrowserState> => async (dispatch, getState) => {
        const state = getState();
        const user = UserSelectors.getPrivilegesValidator(state);
        if (user.canViewExtensions) {
            const extensions = await Platform.extension.list();

            return _.indexBy(extensions, 'id');
        }
        return {};
    };

export const LogsFacetsActions = {
    update,
    fetch,
    fetchResourceName,
    facetsStatsFetch,
    facetsFetch,
};
