import {PickySelectable} from '@coveord/jsadmin-common';
import _ from 'underscore';

import {MappingModelAttributes} from '../../BaseSourceModel';
import {SfObjectModel} from '../objects/SfObjectModel';
import {SfObjectFieldMappingsModel} from './SfObjectFieldMappingsModel';
import {SfRelationship, SfRelationshipObjectFieldCollection} from './SfRelationshipObjectFieldCollection';

export const SfObjectFieldTypes = {
    /* eslint-disable id-blacklist */
    Address: 'address',
    Base64: 'base64',
    Boolean: 'boolean',
    Boolean2: '_boolean',
    Category: 'category',
    Combobox: 'combobox',
    Currency: 'currency',
    Double: 'double',
    Double2: '_double',
    Date: 'date',
    Datetime: 'datetime',
    Email: 'email',
    Id: 'id',
    Int: 'int',
    Int2: '_int',
    MultiPicklist: 'multipicklist',
    NonPolymorphicParent: 'NON_POLYMORPHIC_PARENT',
    Percent: 'percent',
    Phone: 'phone',
    Picklist: 'picklist',
    Reference: 'reference',
    String: 'string',
    Textarea: 'textarea',
    Time: 'time',
    Url: 'url',
    /* eslint-enable */
};

export const SfRelationshipTypes = {
    Child: 'CHILD',
    NonPolymorphicParent: 'NON_POLYMORPHIC_PARENT',
};

export interface SfObjectFieldModelAttributes {
    id?: string;
    indexed: boolean;
    label: string;
    mandatory: boolean;
    mappings?: MappingModelAttributes[];
    name: string;
    type: string;
}

export interface SfObjectFieldModelArgs {
    object: SfObjectModel;
}

export class SfObjectFieldModel extends PickySelectable {
    private object: SfObjectModel;
    relationshipObjectCollection: SfRelationshipObjectFieldCollection;

    get indexed(): boolean {
        return this.get('indexed');
    }

    set indexed(indexed: boolean) {
        this.set('indexed', indexed);
    }

    get label(): string {
        return this.get('label');
    }

    set label(label: string) {
        this.set('label', label);
    }

    get mandatory(): boolean {
        return this.get('mandatory');
    }

    set mandatory(mandatory: boolean) {
        this.set('mandatory', mandatory);
    }

    get mappings(): MappingModelAttributes[] {
        return this.get('mappings');
    }

    set mappings(mappings: MappingModelAttributes[]) {
        this.set('mappings', mappings);
    }

    get name(): string {
        return this.get('name');
    }

    set name(name: string) {
        this.set('name', name);
    }

    get objectIndexed(): boolean {
        return this.object.indexed;
    }

    get type(): string {
        return this.get('type');
    }

    set type(type: string) {
        this.set('type', type);
    }

    get relationshipName(): string {
        return this.get('relationshipName');
    }

    set relationshipName(relationshipName: string) {
        this.set('relationshipName', relationshipName);
    }

    get referenceField(): string {
        return this.get('referenceField');
    }

    set referenceField(referenceField: string) {
        this.set('referenceField', referenceField);
    }

    get referencesTo(): string[] {
        return this.get('referencesTo');
    }

    set referencesTo(referencesTo: string[]) {
        this.set('relationshipParent', referencesTo);
    }

    get relationshipType(): string {
        return this.get('relationshipType');
    }

    set relationshipType(relationshipType: string) {
        this.set('relationshipType', relationshipType);
    }

    get relationshipNameField(): string {
        return this.get('relationshipNameField');
    }

    get relationshipParent(): boolean {
        return this.get('relationshipParent');
    }

    get relationshipPolymorphic(): boolean {
        return this.get('relationshipPolymorphic');
    }

    constructor(args: SfObjectFieldModelArgs, attributes?: SfObjectFieldModelAttributes, options?: any) {
        super(attributes, options);

        this.object = args.object;
    }

    defaults(): SfObjectFieldModelAttributes {
        return {
            indexed: false,
            label: '',
            mandatory: false,
            name: '',
            type: SfObjectFieldTypes.String,
        };
    }

    getMappingsModel() {
        return new SfObjectFieldMappingsModel({
            fields: _.pluck(this.mappings, 'fieldName'),
            sfFieldLabel: this.label,
        });
    }

    setMappingsFromFieldList(fieldList: string[]) {
        this.mappings = _.map(fieldList, (fieldName: string) => this.getMappingModelAttributes(fieldName));
    }

    addObjectRelationship(relationshipObjectFields: SfRelationshipObjectFieldCollection) {
        this.getObjectRelationship().fields = relationshipObjectFields.models;
    }

    getObjectRelationship(): SfRelationship {
        return _.findWhere(this.object.relationships, {relationshipName: this.relationshipName});
    }

    private getMappingModelAttributes(fieldName: string): MappingModelAttributes {
        return {
            fieldName,
            type: this.object.name,
            content: this.name,
        };
    }
}
