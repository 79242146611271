import Mitt from 'mitt';
import type {Emitter} from 'mitt';
import _ from 'underscore';

export interface Channel {
    on(type: string, handler: (...args: any[]) => any): void;
    off(type: string, handler: (...args: any[]) => any): void;
    emit(type: string, event?: any): void;
}

export const Channels = {
    Widgets: 'widgets',
};

export class Radio {
    private static channels: {[key: string]: Channel} = {};

    static channel(channel: string): Channel {
        if (_.isUndefined(Radio.channels[channel])) {
            Radio.channels[channel] = Mitt() as Emitter;
        }
        return Radio.channels[channel];
    }
}
