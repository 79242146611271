import {Tabs} from '@components/mantine';
import {Navigate, Outlet, Route, Routes} from '@core/routes';
import {Fragment, FunctionComponent} from 'react';

import {useSubnavLayoutInternal} from './SubnavLayout.context';
import {SubnavLayoutPanels} from './SubnavLayout.types';

interface RenderRoutesProps {
    defaultTab: string;
    panels: SubnavLayoutPanels;
}

const RenderRoutes: FunctionComponent<RenderRoutesProps> = ({defaultTab, panels}) => (
    <Routes>
        <Route path="/" element={<Outlet />}>
            <Route index element={<Navigate to={`./${defaultTab}`} replace />} />

            {panels.map((tab) => (
                <Route key={tab.props.path} path={`${tab.props.path}/*`} element={tab} />
            ))}

            <Route path="*" element={<Navigate to={`./${defaultTab}`} replace />} />
        </Route>
    </Routes>
);

interface RenderTabsProps {
    panels: SubnavLayoutPanels;
}

const RenderTabs: FunctionComponent<RenderTabsProps> = ({panels}) => (
    <Fragment>
        {panels.map((tab) => (
            <Tabs.Panel key={tab.props.path} value={tab.props.path}>
                {tab}
            </Tabs.Panel>
        ))}
    </Fragment>
);

export const SubnavLayoutBody: FunctionComponent = () => {
    const {defaultTab, panels, routed} = useSubnavLayoutInternal();

    return routed ? <RenderRoutes defaultTab={defaultTab} panels={panels} /> : <RenderTabs panels={panels} />;
};
