import './_sticky-header.scss';

export interface StickyHeaderBehaviorOptions {
    smallHeaderOffset?: number;
    dropShadowOffset?: number;
}

export class StickyHeaderBehavior extends Marionette.Behavior {
    private scheduledAnimationFrame = false;
    private isSmall = false;

    defaults() {
        return {
            smallHeaderOffset: 50,
            dropShadowOffset: 1,
        };
    }

    onAttach() {
        $('.application-container').on('scroll', this.onScroll);
        $('#backbone-component-header').addClass('mod-sticky');
    }

    onDestroy() {
        $('.application-container').off('scroll', this.onScroll);
        $('#backbone-component-header').removeClass('mod-sticky');
    }

    private setScrollState = () => {
        this.scheduledAnimationFrame = false;
        const scrollTop = document.querySelector('.application-container').scrollTop;

        const minForSmall = this.isSmall ? 0 : this.options.smallHeaderOffset;
        this.isSmall = scrollTop > minForSmall;

        $('#backbone-component-header')
            .toggleClass('mod-small', this.isSmall)
            .toggleClass('mod-scrolled', scrollTop > this.options.dropShadowOffset);
    };

    private onScroll = () => {
        if (this.scheduledAnimationFrame) {
            return;
        }

        this.scheduledAnimationFrame = true;
        requestAnimationFrame(this.setScrollState);
    };
}
