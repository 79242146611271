export interface InConditionValue {
    type: string;
    value?: string;
}

export const SfObjectConditionInConditionTypes = {
    SOQL_STRING: 'SOQL_STRING',
    SOQL_ID: 'SOQL_ID',
    SOQL_DATETIME: 'SOQL_DATETIME',
    SOQL_BOOLEAN: 'SOQL_BOOLEAN',
};
