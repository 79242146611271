import {PrivilegeHolderType, PrivilegeModel} from '@core/api';
import {callIfDefined, IReduxAction} from '@coveord/plasma-react'; // eslint-disable-line import/no-deprecated
import _ from 'underscore';

import {
    AccessTableActionTypes,
    PrivilegesAccessOptions,
    PrivilegesConstants,
    PrivilegesListActionTypes,
    PrivilegesListPayload,
    PrivilegeTypes,
} from '@core/user';
import {AccessStateItem} from '../access-table/AccessTableActions';

export type AccessTabsState = {[tableId: string]: AccessStateItem[]};

type UpdateAccessArgs = {
    state: AccessStateItem[];
    id: string;
    newProps: Partial<AccessStateItem>;
};

type PrivilegesListUpdatesArgs = {
    accessItemBeingUpdated: AccessStateItem;
    changedPrivilegesFlags: {
        hasGranularEdit: boolean;
        hasEdit: boolean;
        hasView: boolean;
        isEmpty: boolean;
    };
};

export const accessTabsReducer = (state: AccessTabsState = {}, action: IReduxAction<any>): AccessTabsState => {
    switch (action && action.type) {
        case AccessTableActionTypes.remove:
            return _.omit(state, action.payload.id);
        case AccessTableActionTypes.set:
            return {...state, [action.payload.id]: action.payload.tableData};
        case AccessTableActionTypes.changeAccessLevel:
            return {
                ...state,
                [action.payload.id]: updateAccesStateItem({
                    state: state[action.payload.id],
                    id: action.payload.privilegeHolderId,
                    newProps: {accessLevel: action.payload.accessLevel},
                }),
            };
        case PrivilegesListActionTypes.set:
        case PrivilegesListActionTypes.addPrivilege:
        case PrivilegesListActionTypes.removePrivilege:
            return state[action.payload.id]
                ? {...state, [action.payload.id]: accessItemsPrivilegesReducer(state[action.payload.id], action)}
                : state;
        default:
            return state || {};
    }
};

const accessItemsPrivilegesReducer = (
    state: AccessStateItem[],
    {type, payload: {id, privileges}}: IReduxAction<PrivilegesListPayload>,
): AccessStateItem[] => {
    const accessItemBeingUpdated: AccessStateItem = _.findWhere(state, {id});

    if (_.isEmpty(state) || !accessItemBeingUpdated) {
        return state;
    }

    const relevantChangedPrivileges = privileges.filter(
        (privilege: PrivilegeModel) =>
            _.contains(_.values(PrivilegeHolderType), privilege.targetDomain) &&
            (privilege.targetId === id || privilege.targetId === PrivilegesConstants.allTargetIds) &&
            accessItemBeingUpdated.privilegeHolderType === privilege.targetDomain,
    );

    const changedPrivilegesFlags = {
        hasGranularEdit: !!_.findWhere(relevantChangedPrivileges, {targetId: id}),
        hasEdit: !!_.findWhere(relevantChangedPrivileges, {type: PrivilegeTypes.Edit}),
        hasView: !!_.findWhere(relevantChangedPrivileges, {type: PrivilegeTypes.View}),
        isEmpty: _.isEmpty(relevantChangedPrivileges),
    };

    // eslint-disable-next-line import/no-deprecated
    const newProps: Partial<AccessStateItem> = callIfDefined(privilegesListUpdates[type], {
        accessItemBeingUpdated,
        changedPrivilegesFlags,
    });

    return updateAccesStateItem({state, id, newProps});
};

const updateAccesStateItem = ({state, id, newProps}: UpdateAccessArgs): AccessStateItem[] => {
    if (!state) {
        return [];
    }

    return _.isEmpty(newProps)
        ? state
        : state.map((currentItem: AccessStateItem) =>
              currentItem.id === id ? {...currentItem, ...newProps} : currentItem,
          );
};

const overwritePrivileges = ({changedPrivilegesFlags}: PrivilegesListUpdatesArgs): Partial<AccessStateItem> => {
    if (changedPrivilegesFlags.isEmpty) {
        return {accessLevel: PrivilegesAccessOptions.None, enabled: false};
    } else if (changedPrivilegesFlags.hasGranularEdit) {
        return {accessLevel: PrivilegesAccessOptions.Edit, enabled: true};
    } else if (changedPrivilegesFlags.hasEdit) {
        return {accessLevel: PrivilegesAccessOptions.Edit, enabled: false};
    } else if (changedPrivilegesFlags.hasView) {
        return {accessLevel: PrivilegesAccessOptions.View, enabled: true};
    }
};

const addPrivileges = ({
    accessItemBeingUpdated,
    changedPrivilegesFlags,
}: PrivilegesListUpdatesArgs): Partial<AccessStateItem> => {
    if (changedPrivilegesFlags.hasGranularEdit) {
        return {accessLevel: PrivilegesAccessOptions.Edit, enabled: true};
    } else if (changedPrivilegesFlags.hasEdit) {
        return {accessLevel: PrivilegesAccessOptions.Edit, enabled: false};
    } else if (changedPrivilegesFlags.hasView) {
        return {
            accessLevel:
                accessItemBeingUpdated.accessLevel !== PrivilegesAccessOptions.Edit
                    ? PrivilegesAccessOptions.View
                    : accessItemBeingUpdated.accessLevel,
            enabled:
                accessItemBeingUpdated.accessLevel === PrivilegesAccessOptions.Edit
                    ? accessItemBeingUpdated.enabled
                    : true,
        };
    }
};

const removePrivileges = ({
    accessItemBeingUpdated,
    changedPrivilegesFlags,
}: PrivilegesListUpdatesArgs): Partial<AccessStateItem> => {
    if (changedPrivilegesFlags.hasEdit) {
        return {
            accessLevel:
                accessItemBeingUpdated.accessLevel !== PrivilegesAccessOptions.None
                    ? PrivilegesAccessOptions.View
                    : accessItemBeingUpdated.accessLevel,
            enabled: accessItemBeingUpdated.accessLevel !== PrivilegesAccessOptions.None,
        };
    } else if (changedPrivilegesFlags.hasView) {
        return {accessLevel: PrivilegesAccessOptions.None, enabled: false};
    }
};

const privilegesListUpdates: {[actionType: string]: (args: PrivilegesListUpdatesArgs) => Partial<AccessStateItem>} = {
    [PrivilegesListActionTypes.set]: overwritePrivileges,
    [PrivilegesListActionTypes.addPrivilege]: addPrivileges,
    [PrivilegesListActionTypes.removePrivilege]: removePrivileges,
};
