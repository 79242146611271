import {Platform} from '@core/api';
import {RequestsActions} from '@coveord/jsadmin-common';
import {BasePayload, IReduxAction, IThunkAction} from '@coveord/plasma-react';

import {setKeyValue} from '@core/store';
import {AdminState} from '../application/Reducers';
import {GlobalGroupsConstants} from './GlobalGroupsConstants';

export const GroupsActionTypes = {
    add: 'ADD_GROUP',
    remove: 'REMOVE_GROUP',
    privilegesReadOnly: 'SET_PRIVILEGES_READ_ONLY',
    updateName: 'UPDATE_GROUP_NAME',
};

export interface PrivilegeReadOnlyPayload extends BasePayload {
    privilegesReadOnly: boolean;
}

export interface GroupNamePayload extends BasePayload {
    name: string;
}

const add = (id: string): IReduxAction<BasePayload> => ({
    type: GroupsActionTypes.add,
    payload: {
        id,
    },
});

const removeState = (id: string): IReduxAction<BasePayload> => ({
    type: GroupsActionTypes.remove,
    payload: {
        id,
    },
});

const setPrivilegesReadOnly = (id: string, privilegesReadOnly: boolean): IReduxAction<PrivilegeReadOnlyPayload> => ({
    type: GroupsActionTypes.privilegesReadOnly,
    payload: {
        id,
        privilegesReadOnly,
    },
});

const updateName = (id: string, name: string): IReduxAction<GroupNamePayload> => ({
    type: GroupsActionTypes.updateName,
    payload: {
        id,
        name,
    },
});

const fetchAll = (): IThunkAction<Promise<void>, AdminState> => (dispatch) => {
    const makeRequest = async () => {
        const globalGroups = await Platform.globalGroup.list();
        dispatch(setKeyValue(GlobalGroupsConstants.actionType, globalGroups));
    };
    return dispatch(RequestsActions.handle(GlobalGroupsConstants.Requests.fetch, makeRequest));
};

const remove =
    (groupId: string): IThunkAction<ReturnType<typeof Platform.globalGroup.delete>, AdminState> =>
    (dispatch) => {
        const makeRequest = async () => Platform.globalGroup.delete(groupId);
        return dispatch(RequestsActions.handle(GlobalGroupsConstants.Requests.delete, makeRequest));
    };

export const GlobalGroupsActions = {
    fetchAll,
    delete: remove,
    add,
    removeState,
    setPrivilegesReadOnly,
    updateName,
};
