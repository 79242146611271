import {PipelineModel, Platform} from '@core/api';
import {GlobalConstants, RequestsActions} from '@coveord/jsadmin-common';
import {IThunkAction} from '@coveord/plasma-react';

import {setKeyValue} from '@core/store';
import {SearchOptimizationState} from '../../SearchOptimizationState';
import {ABTestConstants} from '../../statements/ab-test/ABTestConstants';

export const QueryPipelineActionsType = {
    request: 'REQUEST_PIPELINE',
    duplicate: 'DUPLICATE_PIPELINE',
    update: 'UPDATE_PIPELINE',
};

const fetch =
    (
        queryPipelineId: string,
        isTestQueryPipeline = false,
    ): IThunkAction<Promise<PipelineModel | void>, SearchOptimizationState> =>
    (dispatch) => {
        if (!queryPipelineId) {
            return Promise.reject('Skipped fetch query pipeline');
        }

        if (queryPipelineId === GlobalConstants.urlParameters.new) {
            return Promise.resolve();
        }

        const handleFetch = async () => {
            const pipeline: PipelineModel = await Platform.pipeline.get(queryPipelineId);
            if (isTestQueryPipeline) {
                dispatch(setKeyValue(ABTestConstants.ABTestActionTypes.createTestQueryPipeline, pipeline));
            }
            return pipeline;
        };

        return dispatch(RequestsActions.handle(QueryPipelineActionsType.request, handleFetch));
    };

const update =
    (queryPipeline: PipelineModel): IThunkAction<Promise<PipelineModel>, SearchOptimizationState> =>
    (dispatch) =>
        dispatch(
            RequestsActions.handle(QueryPipelineActionsType.update, () => Platform.pipeline.update(queryPipeline)),
        );

export const QueryPipelineActions = {
    fetch,
    update,
};
