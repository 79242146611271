import moment from 'moment-timezone';

import {DATE_FORMAT_SHORT} from '../Constants';
import {DateRangeAttributes} from '../widgets/date-range-picker/DateRangeModel';

declare const jstz: any;

export const DateRangeType = {
    Hours: 'hours',
    Days: 'days',
    Weeks: 'weeks',
    Months: 'months',
    Years: 'years',
};

/* eslint-disable no-underscore-dangle */
export class DateUtils {
    static UserTimezone: string = jstz.determine().name();
    private static timestampSec = /^\d{9,10}$/;
    private static timestampMillisec = /^\d{12,13}$/;

    private static _ApplicationTimezone = DateUtils.UserTimezone;
    static get ApplicationTimezone(): string {
        const w: any = window as any;
        const windowDateUtils = w.JsAdminCommon && w.JsAdminCommon.DateUtils ? w.JsAdminCommon.DateUtils : DateUtils;
        return windowDateUtils._ApplicationTimezone;
    }

    static set ApplicationTimezone(timezone: string) {
        const w: any = window as any;
        const windowDateUtils = w.JsAdminCommon && w.JsAdminCommon.DateUtils ? w.JsAdminCommon.DateUtils : DateUtils;
        windowDateUtils._ApplicationTimezone = timezone;
    }

    static UTCTimezone: string = 'Greenwich';

    static format(date: Date | number, format: string, timezone = DateUtils.ApplicationTimezone) {
        return moment.tz(date, timezone).format(format);
    }

    static toLocalDate(date: Date): Date {
        return DateUtils.LocalDateToApplicationMoment(date).toDate();
    }

    static LastWeekStart(timezone: string = DateUtils.UserTimezone) {
        return DateUtils.MomentToDate(moment.tz(timezone).subtract(1, 'week').startOf('week'));
    }

    static LastWeekEnd(timezone: string = DateUtils.UserTimezone) {
        return DateUtils.MomentToDate(moment.tz(timezone).subtract(1, 'week').endOf('week'), true);
    }

    static LastMonthStart(timezone: string = DateUtils.UserTimezone) {
        return DateUtils.MomentToDate(moment.tz(timezone).subtract(1, 'month').startOf('month'));
    }

    static LastMonthEnd(timezone: string = DateUtils.UserTimezone) {
        return DateUtils.MomentToDate(moment.tz(timezone).subtract(1, 'month').endOf('month'), true);
    }

    static LastYearStart(timezone: string = DateUtils.UserTimezone) {
        return DateUtils.MomentToDate(moment.tz(timezone).subtract(1, 'year').startOf('year'));
    }

    static LastYearEnd(timezone: string = DateUtils.UserTimezone) {
        return DateUtils.MomentToDate(moment.tz(timezone).subtract(1, 'year').endOf('year'), true);
    }

    static TodayStart(timezone: string = DateUtils.UserTimezone) {
        return DateUtils.MomentToDate(moment.tz(timezone).startOf('day'));
    }

    static TodayEnd(timezone: string = DateUtils.UserTimezone) {
        return DateUtils.MomentToDate(moment.tz(timezone).endOf('day'), true);
    }

    static get ApplicationToday() {
        return DateUtils.MomentToDate(moment.tz(DateUtils.ApplicationTimezone));
    }

    static YesterdayStart(timezone: string = DateUtils.UserTimezone) {
        return DateUtils.MomentToDate(moment.tz(timezone).subtract(1, 'day'));
    }

    static YesterdayEnd(timezone: string = DateUtils.UserTimezone) {
        return DateUtils.MomentToDate(moment.tz(timezone).subtract(1, 'day'), true);
    }

    static LastXDaysDate(days: number, timezone: string = DateUtils.UserTimezone) {
        return DateUtils.MomentToDate(moment.tz(timezone).subtract(days, 'days'));
    }

    static MomentToDate(m: moment.Moment, end = false): Date {
        const d = new Date(m.format(DATE_FORMAT_SHORT));
        if (end) {
            d.setHours(23, 59, 59, 999);
        }
        return d;
    }

    static LocalDateToApplicationMoment(date: Date, timezone: string = DateUtils.ApplicationTimezone) {
        return moment
            .tz(moment(date).format('MM/DD/YYYY'), 'MM/DD/YYYY', timezone)
            .hours(date.getHours())
            .minutes(date.getMinutes())
            .seconds(date.getSeconds())
            .milliseconds(date.getMilliseconds());
    }

    static dateToBrowserTimezone(date: Date, timezone: string = DateUtils.ApplicationTimezone) {
        // Be careful, this method creates a date with the moment date & hour, but in the user timezone
        // E.g., 2015-08-31T14:00:00-10:00 => 2015-08-31T14:00:00-4:00
        const m = moment.tz(date, timezone);
        return DateUtils.momentToBrowserDate(m);
    }

    static momentToBrowserDate(m: moment.Moment): Date {
        // Be careful, this method creates a date with the moment date & hour, but in the user timezone
        // E.g., 2015-08-31T14:00:00-10:00 => 2015-08-31T14:00:00-4:00
        const d = new Date(m.format(DATE_FORMAT_SHORT));
        d.setHours(m.hours());
        d.setMinutes(m.minutes());
        d.setSeconds(m.seconds());
        d.setMilliseconds(m.milliseconds());

        return d;
    }

    static TimezoneCode(timezone: string = DateUtils.UserTimezone) {
        return moment.tz(timezone).format('z');
    }

    static TimezoneLongCode(timezone: string = DateUtils.UserTimezone) {
        return timezone + ' (GMT ' + moment.tz(timezone).format('Z') + ')';
    }

    static isTimestamp(time: number | string): boolean {
        return DateUtils.timestampSec.test(time.toString()) || DateUtils.timestampMillisec.test(time.toString());
    }

    static isSecondsTimestamp(time: number | string): boolean {
        return DateUtils.timestampSec.test(time.toString());
    }

    static isMsTimestamp(time: number | string): boolean {
        return DateUtils.timestampMillisec.test(time.toString());
    }

    static getBeginMoment(offset: number, range: string, timezone: string = DateUtils.UserTimezone) {
        return moment
            .tz(timezone)
            .add(offset.toString(), range as any)
            .startOf(range as any);
    }

    static getEndMoment(offset: number, range: string, timezone: string = DateUtils.UserTimezone) {
        return moment
            .tz(timezone)
            .add(offset.toString(), range as any)
            .endOf(range as any);
    }

    static convertDatesToDateRange(begin: Date, end: Date, timezone: string = DateUtils.UserTimezone) {
        const beginMoment = moment.tz(begin, timezone);
        const endMoment = moment.tz(end, timezone);
        const lengthInHours = endMoment.diff(beginMoment, 'hours') + 1;

        const isStartOfYear = beginMoment.clone().startOf('year').isSame(beginMoment);
        const isEndOfYear = endMoment.clone().endOf('year').isSame(endMoment);
        const isStartOfMonth = beginMoment.clone().startOf('month').isSame(beginMoment);
        const isEndOfMonth = endMoment.clone().endOf('month').isSame(endMoment);
        const isStartOfWeek = beginMoment.clone().startOf('week').isSame(beginMoment);
        const isEndOfWeek = endMoment.clone().endOf('week').isSame(endMoment);

        let range = DateRangeType.Days;
        if (isStartOfYear && isEndOfYear) {
            range = DateRangeType.Years;
        } else if (isStartOfMonth && isEndOfMonth) {
            range = DateRangeType.Months;
        } else if (isStartOfWeek && isEndOfWeek) {
            range = DateRangeType.Weeks;
        } else if (lengthInHours < 24) {
            range = DateRangeType.Hours;
        }

        const rangeAsAny = range as any;

        const rangeStart = moment.tz(timezone).startOf(rangeAsAny);
        const offset = beginMoment.diff(rangeStart, rangeAsAny);
        const length = endMoment.diff(beginMoment, rangeAsAny) + 1;

        return {offset, length, range};
    }

    static convertRangeToDates(dateRange: DateRangeAttributes, timezone: string = DateUtils.UserTimezone) {
        const {range, length, offset} = dateRange;
        const begin = DateUtils.getBeginMoment(offset, range, timezone).toDate();
        const end = DateUtils.getEndMoment(offset + length - 1, range, timezone).toDate();

        return {begin, end};
    }

    static convertDurationToMoment(
        duration: moment.Duration,
        timezone: string = DateUtils.UserTimezone,
    ): moment.Moment {
        return moment
            .tz(timezone)
            .subtract(duration.milliseconds(), 'milliseconds')
            .subtract(duration.seconds(), 'seconds')
            .subtract(duration.minutes(), 'minutes')
            .subtract(duration.hours(), 'hours')
            .subtract(duration.days(), 'days')
            .subtract(duration.weeks(), 'weeks')
            .subtract(duration.months(), 'months')
            .subtract(duration.years(), 'years');
    }
}
