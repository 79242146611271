import {LicenseModel} from '@core/api';
import {IReduxAction} from '@coveord/plasma-react';
import {ReducersMapObject} from 'redux';
import {LicenseActions, LicenseActionsPayload} from '../actions/LicenseActions';
import {LicenseState} from '../store/LicenseStore';

export const defaultLicenseState: LicenseModel = {} as LicenseModel;

const reducer = (state = defaultLicenseState, action: IReduxAction<LicenseActionsPayload>): LicenseModel => {
    switch (action.type) {
        case LicenseActions.ActionsType.UPDATE_LICENSE:
            return {...state, ...action.payload?.license};
        default:
            return state;
    }
};

export const licenseReducer: ReducersMapObject<LicenseState> = {
    license: reducer,
};
