import {Platform} from '@core/api';
import {RequestsActions} from '@coveord/jsadmin-common';
import {IReduxAction, IThunkAction} from '@coveord/plasma-react';
import _ from 'underscore';

import {ILogBrowserState} from '../LogBrowserReducers';
import {ISourcesState} from './LogsSourcesReducers';

export const SourcesActions = {
    update: 'UPDATE_SOURCES',
    fetch: 'FETCH_SOURCES',
};

export interface ISourcesActionPayload {
    sources: ISourcesState;
}

export interface ISource {
    id: string;
    name: string;
}

const update = (sources: ISourcesState): IReduxAction<ISourcesActionPayload> => ({
    type: SourcesActions.update,
    payload: {
        sources,
    },
});

const fetch = (): IThunkAction<Promise<void>, ILogBrowserState> => (dispatch) => {
    const makeRequest = async () => {
        const totalPages = (await Platform.source.list())?.totalPages;
        const pages = [...Array(totalPages).keys()];
        const fullSourceModels = await Promise.all(
            pages.map(async (page) => {
                const res = await Platform.source.list({page: page});
                return res.sourceModels;
            }),
        ).then((result) => result.flat());

        const sources: ISourcesState = _.reduce(
            fullSourceModels,
            (memo: ISourcesState, source: ISource) => ({...memo, [source.id]: source.name}),
            {},
        );
        dispatch(update(sources));
    };
    return dispatch(RequestsActions.handle(SourcesActions.fetch, makeRequest));
};

export const LogsSourcesActions = {
    fetch,
    update,
};
