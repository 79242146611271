export const GroupConstants = {
    Components: {
        table: 'Groups',
    },
    Request: {
        fetchAll: 'fetch-groups',
        delete: 'delete-group',
        save: 'save-group',
    },
    Routes: {
        base: '/organization/groups',
    },
    SortKeys: {
        name: 'displayName',
        members: 'members',
        identitySets: 'realms',
    },
    actionType: 'GROUPS_LIST',
};
