import {PipelineModel} from '@core/api';

export const QueryPipelinesConstants = {
    table: 'query-pipelines-table',
    accessesId: 'query-pipelines-tables-accesses',
    actionType: 'QUERY_PIPELINES',
    Requests: {
        fetch: 'FETCH_QUERY_PIPELINES',
        duplicate: 'DUPLICATE_QUERY_PIPELINE',
        delete: 'DELETE_QUERY_PIPELINE',
        makeDefault: 'SET_DEFAULT_QUERY_PIPELINE',
    },
    docUrl: 'https://docs.coveo.com/en/1611/',
    levelUpUrl: 'https://levelup.coveo.com/learn/courses/introduction-to-query-pipelines',
};

export type PipelinesTableRow = PipelineModel & {abTest?: string; hasFlag?: boolean};
