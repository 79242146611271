import _ from 'underscore';
import {PickyMultiSelect, PickySelectable} from '../base-models/PickyModels';
import {commonLocales} from '../CommonLocales';
import {CommonLocalesKeys} from '../CommonLocalesKeys';
import {getChildViewEvent} from '../utils/Utils';

import button from './button.ejs';

const ButtonGroupEvents = {
    SELECT: 'button-group:select',
};

export class MultiSelectItems extends PickyMultiSelect<Item> {
    constructor(models?, options = {}) {
        super(
            models,
            _.extend(
                {
                    model: Item,
                },
                options,
            ),
        );
    }

    setOptions(options: string[]) {
        for (let i = 0; i < options.length; i++) {
            this.add(
                new Item({
                    value: options[i],
                }),
            );
        }
    }

    selectFromValues(values: string[]) {
        this.each((item: Item) => {
            if (_.contains(values, item.value)) {
                item.select();
            }
        });
    }

    deepClone(): MultiSelectItems {
        return new MultiSelectItems(this.toJSON());
    }

    get values(): string[] {
        return _.map(this.selected, (model: Item) => model.value);
    }
}

export class Item extends PickySelectable {
    getValue() {
        return this.get('value');
    }

    get value(): string {
        return this.getValue();
    }
}

class ButtonView extends Marionette.ItemView<Item> {
    letterCount: number;
    disabled: boolean;

    constructor(options?) {
        super(
            _.extend(
                {
                    tagName: 'button',
                    className: 'btn',
                    attributes: {
                        type: 'button',
                    },
                    modelEvents: {
                        'selected deselected': 'onToggleSelected',
                    },
                },
                options,
            ),
        );
        this.template = button;

        this.letterCount = options.letterCount;
        this.disabled = options.disabled;
        this.templateHelpers = {
            printValue: () => options.valueFormatter(this.model),
        };
    }

    triggers() {
        return {
            click: ButtonGroupEvents.SELECT,
        };
    }

    onRender() {
        this.onToggleSelected();

        if (this.letterCount === 1) {
            this.$el.addClass('btn-single-letter');
        }

        if (this.disabled) {
            this.$el.attr('disabled', 'disabled');
        }
    }

    onToggleSelected() {
        this.$el.toggleClass('active', this.model.selected === true);

        const key = this.model.get('value') + '_full';
        let value = commonLocales.format(key as CommonLocalesKeys);
        if (_.isEqual(key, value)) {
            value = commonLocales.format(this.model.get('value'));
        }
        const title = this.model.selected
            ? commonLocales.format('deselectValue', {value: value})
            : commonLocales.format('selectValue', {value: value});
        this.$el.attr('title', title);
    }
}

class ButtonGroupView extends Marionette.CollectionView<Backbone.Model, MultiSelectItems> {
    constructor(options?) {
        super(
            _.extend(
                {
                    childView: ButtonView,
                    childViewOptions: {
                        letterCount: options.letterCount,
                        disabled: options.disabled,
                        valueFormatter: options.valueFormatter,
                    },
                },
                options,
            ),
        );
    }
}

export interface ButtonGroupOptions {
    letterCount?: number;
    disabled?: boolean;
    viewClasses?: string[];
    valueFormatter?: (model: Item) => string;
}

export class ButtonGroupWidget extends Marionette.Object {
    constructor(private items: MultiSelectItems) {
        super();
    }

    show(region: Marionette.Region, options: ButtonGroupOptions = {}) {
        options = _.defaults(options, this.defaults());
        const buttonGroup = new ButtonGroupView({
            collection: this.items,
            letterCount: options.letterCount,
            disabled: options.disabled,
            valueFormatter: options.valueFormatter,
            className: ['btn-group', 'btn-group-square'].concat(options.viewClasses).join(' '),
        });
        region.show(buttonGroup);

        this.listenTo(buttonGroup, getChildViewEvent(ButtonGroupEvents.SELECT), this.onSelectOption);
    }

    private defaults(): ButtonGroupOptions {
        const letterCount = 1;
        return {
            letterCount: letterCount,
            disabled: false,
            viewClasses: [],
            valueFormatter: (model: Item) =>
                commonLocales.format(model.value as CommonLocalesKeys).substr(0, letterCount),
        };
    }

    private onSelectOption(view: ButtonView) {
        view.model.toggleSelected();
    }
}
