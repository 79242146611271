import _ from 'underscore';

export type EJSTemplate = (...args: any[]) => string | JQuery | Element;

export enum KeyCode {
    BACKSPACE = 8,
    TAB = 9,
    ENTER = 13,
    ESCAPE = 27,
    SPACEBAR = 32,
    END = 35,
    HOME = 36,
    DELETE = 46,
    ARROW_UP = 38,
    ARROW_DOWN = 40,
    ARROW_LEFT = 37,
    ARROW_RIGHT = 39,
    C = 67,
    K = 75,
}

export const URL_ACCESSIBLE_PAGE_TOKEN = 'confirm';

export const MAX_LOGS_TO_KEEP = 25;

export const BACKBONE_METHODS_MAP = {
    create: 'POST',
    update: 'PUT',
    delete: 'DELETE',
    patch: 'PATCH',
    read: 'GET',
};

export const BACKBONE_REVERSE_METHOD_MAPS = _.invert(BACKBONE_METHODS_MAP);

export const STATIC_MODAL_IDS: string[] = [];
export const ISO_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZZ';
export const DATE_FORMAT_SHORT = 'MM/DD/YYYY';
export const DATETIME_FORMAT_LONG = 'MM/DD/YYYY HH:mm:ss.SSS';
export const TOOLTIP_DISMISS_TIME: number = 2000;
export const TOGGLE_ANIMATION_DURATION: number = 250;
export const COLLAPSE_EXPAND_ANIMATION_DURATION: number = 400;

export const OnlineHelp = {
    WhatsNew: 'http://onlinehelp.coveo.com/en/cloud/coveo_cloud_new_features.htm',
    TermsAgreements: 'http://www.coveo.com/en/company/terms-agreements',
    ContactUs: 'http://www.coveo.com/Home/company/contact-us',
};

export const ALERT_DISMISS_TIME: number = 3000;
export const TOOLTIP_SHOW_DELAY: number = 200;
export const TEXTAREA_HIGHLIGHT_COLOR = 'rgba(249,198,201,0.7)';

export const LONG_AJAX_REQUESTS_MESSAGE_ID = 'long-ajax-requests-message-id';
export const AJAX_TIMEOUT = 900000; // 15 minutes

export const PAGE_ORGANIZATION_DEACTIVATED = 'organization-deactivated';

export const LONG_AJAX_REQUEST_DELAY = 3000;

export const DAYS_OF_THE_WEEK = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

export const DATE_FORMAT_SHORT_FILENAME = 'MM-DD-YYYY';
export const DATE_FORMAT_LONG = 'MMM D, YYYY';
export const DATE_TIME_FORMAT_LONG = 'MMM D, YYYY [at] h:mm A';
export const SQL_DATE_FORMAT = 'YYYY-MM-DD H:m:s.S';

export const OUT_OF_SERVICE_REFRESH_RETRY = 300000; // 5 minutes.
export const TEN_SECONDS_INTERVAL = 10000;

export const UNDEFINED_GROUPBY_KEY = 'Undefined';

export const DEFAULT_REGION_CONTAINER = '.CoveoAdminApplicationContainer #admin';

// Scheduling
export interface Frequency {
    type: string;
    hourMinute?: HourMinute;
    weekDays?: string[];
    monthDay?: number;
}

export const FrequencyType = {
    MINUTELY: 'MINUTELY',
    HOURLY: 'HOURLY',
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
};

export const FREQUENCY_TYPES_WITH_TIME = [FrequencyType.DAILY, FrequencyType.WEEKLY, FrequencyType.MONTHLY];

export interface HourMinute {
    hour: number;
    minute: number;
}

export const CommonEvents = {
    Cancel: 'common:cancel',
    Create: 'common:create',
    Delete: 'common:delete',
    Save: 'common:save',
    ToggleEditMode: 'common:toggle-edit-mode',
};

export const RootDOMSelectors = {
    header: 'ComponentHeader',
    main: 'main',
    modal: 'RModal',
};

export const GlobalConstants = {
    urlParameters: {
        new: 'new',
    },
    Events: {
        offline: 'connection:offline',
        authorizeUser: 'helper:authorize',
    },
};

export const SearchHub = {
    AdminConsole: 'AdminConsole',
};

export const Injectable = {
    User: 'user',
    Organization: 'organization',
    Condition: 'condition',
    Pipeline: 'pipeline',
    CloudPlatformUrl: 'cloudPlatform',
    Configuration: 'configuration',
    LaunchDarkly: 'launchDarkly',
};
