import {PlatformPaths} from '@configurations/platform';
import {RouteObject} from '@core/routes';

export const routes: RouteObject[] = [
    {
        path: `${PlatformPaths.CrawlingModule.fullPath}/*`,
        lazy: async () => {
            const {CrawlingModulesListPage} = await import('../pages');
            return {Component: CrawlingModulesListPage};
        },
    },
];
