import {IReduxAction} from '@coveord/plasma-react';
import {Action} from 'redux';
import _ from 'underscore';
import {EditDimensionTimeseriesActionsType} from '../actions/EditDimensionTimeseriesActions';
import {
    EditTimeseriesActionsType,
    EditTimeseriesSetHeaderHrefPayload,
    EditTimeseriesSetShowPayload,
    EditTimeseriesSetTitlePayload,
} from '../actions/EditTimeseriesActions';
import {EditVisitMetricTimeseriesActionsType} from '../actions/EditVisitMetricTimeseriesActions';
import {EditTimeseriesDefaultState, EditTimeseriesState} from '../EditTimeseriesState';

const initialize = (state: EditTimeseriesState, action: IReduxAction<EditTimeseriesPayload>): EditTimeseriesState =>
    _.extend({}, state, EditTimeseriesDefaultState, action.payload);

const clear = (): EditTimeseriesState => _.extend({}, EditTimeseriesDefaultState);

const setTitle = (
    state: EditTimeseriesState,
    action: IReduxAction<EditTimeseriesSetTitlePayload>,
): EditTimeseriesState => _.extend({}, state, {title: action.payload.title});

const setHeaderHref = (
    state: EditTimeseriesState,
    action: IReduxAction<EditTimeseriesSetHeaderHrefPayload>,
): EditTimeseriesState => _.extend({}, state, {headerHref: action.payload.headerHref});

const setShowMetrics = (
    state: EditTimeseriesState,
    action: IReduxAction<EditTimeseriesSetShowPayload>,
): EditTimeseriesState => _.extend({}, state, {showMetrics: action.payload.show});
const setShowPeak = (
    state: EditTimeseriesState,
    action: IReduxAction<EditTimeseriesSetShowPayload>,
): EditTimeseriesState => _.extend({}, state, {showPeak: action.payload.show});
const setShowAverage = (
    state: EditTimeseriesState,
    action: IReduxAction<EditTimeseriesSetShowPayload>,
): EditTimeseriesState => _.extend({}, state, {showAverage: action.payload.show});
const setShowTrend = (
    state: EditTimeseriesState,
    action: IReduxAction<EditTimeseriesSetShowPayload>,
): EditTimeseriesState => _.extend({}, state, {showTrend: action.payload.show});

const EditTimeseriesReducers: {[key: string]: (...args: any[]) => EditTimeseriesState} = {
    [EditDimensionTimeseriesActionsType.Initialize]: initialize,
    [EditVisitMetricTimeseriesActionsType.Initialize]: initialize,
    [EditTimeseriesActionsType.Clear]: clear,
    [EditTimeseriesActionsType.SetTitle]: setTitle,
    [EditTimeseriesActionsType.SetHeaderHref]: setHeaderHref,
    [EditTimeseriesActionsType.SetShowMetrics]: setShowMetrics,
    [EditTimeseriesActionsType.SetShowPeak]: setShowPeak,
    [EditTimeseriesActionsType.SetShowAverage]: setShowAverage,
    [EditTimeseriesActionsType.SetShowTrend]: setShowTrend,
};

export type EditTimeseriesPayload =
    | Action
    | EditTimeseriesSetTitlePayload
    | EditTimeseriesSetHeaderHrefPayload
    | EditTimeseriesSetShowPayload;

export const EditTimeseriesReducer = (
    state: EditTimeseriesState = EditTimeseriesDefaultState,
    action?: IReduxAction<EditTimeseriesPayload>,
): EditTimeseriesState =>
    EditTimeseriesReducers[action.type] ? EditTimeseriesReducers[action.type](state, action) : state;
