import {LicenseModel} from '@core/api';
import {useLicense} from '@core/license';
import {UserPrivilegesValidator, useUser} from '@core/user';

/**
 * Retrieves the current license model, and user model with its privileges and access.
 *
 * @deprecated Use useUser from @core/user and useLicense from @core/license instead.
 */
export const useAccessControl = (): {user: UserPrivilegesValidator; license: LicenseModel} => {
    const {access: user} = useUser();
    const license = useLicense();

    return {user, license};
};
