import {CachedCatalogFieldStatsModel, CatalogModel} from '@core/api';
import {ObjectReducerGenerator} from '@core/store';
import {AnyAction, CombinedState, combineReducers} from 'redux';
import {EditCatalogConstants} from './EditCatalogConstants';

export type IEditCatalogState = ReturnType<typeof editCatalogReducer>;
export type ISelectedObjectState = typeof initialSelectedObject;

export const initialCatalogState: CatalogModel = {
    id: '',
    name: '',
    description: '',
    product: {
        idField: '',
        objectType: '',
    },
};

const initialCatalogFieldsStatsState: CachedCatalogFieldStatsModel = {
    fieldStats: [],
    lastUpdated: 0,
    nextUpdate: 0,
};

const initialSelectedObject = {
    field: '',
    value: '',
    name: '',
};

export const editCatalogReducer: (
    state: CombinedState<{
        catalog;
        selectedAvailability;
        selectedProduct;
        catalogFields;
        catalogFieldStats;
    }>,
    action: AnyAction,
) => CombinedState<{
    catalog: CatalogModel;
    selectedAvailability: {field: string; value: string; name: string};
    selectedProduct: {field: string; value: string; name: string};
    catalogFieldStats: CachedCatalogFieldStatsModel;
}> = combineReducers({
    catalog: ObjectReducerGenerator(EditCatalogConstants.ActionsTypes.updateCatalog, initialCatalogState),
    selectedAvailability: ObjectReducerGenerator(
        EditCatalogConstants.ActionsTypes.updateSelectedObject,
        initialSelectedObject,
    ),
    selectedProduct: ObjectReducerGenerator(
        EditCatalogConstants.ActionsTypes.updateSelectedProductObject,
        initialSelectedObject,
    ),
    catalogFieldStats: ObjectReducerGenerator(
        EditCatalogConstants.ActionsTypes.fetchFieldStats,
        initialCatalogFieldsStatsState,
    ),
});
