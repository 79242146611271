import {PrivilegeModel} from '@core/api';
import {BasePayload, IReduxAction} from '@coveord/plasma-react';
import {ReducersMapObject} from 'redux';
import {omit, reject} from 'underscore';
import {PrivilegesListActionTypes} from '../../privileges/actions/PrivilegesListActions';
import {
    PrivilegeOwners,
    PrivilegesAccessOptions,
    PrivilegesConstants,
    PrivilegeTypes,
} from '../constants/PrivilegesConstants';
import {PrivilegesListsState} from '../store/PrivilegesListStore';
import {ArraysOfObjects} from '../utils/ArraysUtils';

export interface AccessLevelPayload extends BasePayload {
    accessLevel: string;
    privilegeHolderId: string;
    privilegeHolderType: string;
}

export const AccessTableActionTypes = {
    set: 'SET_ACCESS_TABLE_DATA',
    changeAccessLevel: 'CHANGE_ACCESS_LEVEL',
    remove: 'REMOVE_ACCESS_TABLE',
};

export type PrivilegesListsStateModel = {[listId: string]: PrivilegeModel[]};

export const privilegesListReducer = (
    state: PrivilegesListsStateModel = {},
    action: IReduxAction<any>,
): PrivilegesListsStateModel => {
    switch (action.type) {
        case PrivilegesListActionTypes.remove:
            return omit(state, action.payload.id);
        case PrivilegesListActionTypes.set:
            return {...state, [action.payload.id]: action.payload.privileges};
        case PrivilegesListActionTypes.addPrivilege:
            return {
                ...state,
                [action.payload.id]: [
                    ...(state[action.payload.id] || []),
                    ...ArraysOfObjects.difference(action.payload.privileges, state[action.payload.id]),
                ],
            };
        case PrivilegesListActionTypes.removePrivilege:
            return {
                ...state,
                [action.payload.id]: ArraysOfObjects.difference(state[action.payload.id], action.payload.privileges),
            };
        case AccessTableActionTypes.changeAccessLevel:
            return state[action.payload.privilegeHolderId]
                ? {
                      ...state,
                      [action.payload.id]: updatePrivilegesFromAccessTable(
                          state[action.payload.privilegeHolderId],
                          action.payload,
                      ),
                      [`${action.payload.id}-granulars`]: updatePrivilegesFromAccessTable(
                          state[action.payload.privilegeHolderId],
                          action.payload,
                      ),
                  }
                : state;
        default:
            return state;
    }
};

const updatePrivilegesFromAccessTable = (
    currentPrivileges: PrivilegeModel[],
    payload: AccessLevelPayload,
): PrivilegeModel[] => {
    const newPrivileges: PrivilegeModel[] =
        payload.accessLevel === PrivilegesAccessOptions.Edit
            ? [
                  ...currentPrivileges,
                  {
                      type: PrivilegeTypes.Edit,
                      targetId: payload.privilegeHolderId,
                      targetDomain: payload.privilegeHolderType,
                      owner: PrivilegeOwners.Platform,
                  },
                  {
                      type: PrivilegeTypes.View,
                      targetId: PrivilegesConstants.allTargetIds,
                      targetDomain: payload.privilegeHolderType,
                      owner: PrivilegeOwners.Platform,
                  },
              ]
            : reject(
                  currentPrivileges,
                  (privilege: PrivilegeModel) =>
                      privilege.type === PrivilegeTypes.Edit &&
                      privilege.targetDomain === payload.privilegeHolderType &&
                      privilege.targetId === payload.privilegeHolderId &&
                      privilege.targetId === payload.id,
              );

    return ArraysOfObjects.uniq(newPrivileges);
};

export const privilegesListReducers: ReducersMapObject<PrivilegesListsState> = {
    privilegesLists: privilegesListReducer,
};
