export interface StrictValidationApplyState {
    isFetched: boolean;
    result: boolean;
    isFailed: boolean;
    saveFailed: boolean;
    saveDone: boolean;
    saveStarted: boolean;
}

export interface ValidatedDimensionState {
    isFetched: boolean;
    results: ValidatedDimensionResultState[];
    isFailed: boolean;
}

export interface ValidatedDimensionResultState {
    value: string;
    count: string;
}

export interface StrictValidationState {
    applyState: StrictValidationApplyState;
    validatedDimensions: {
        id_origin_level_1: ValidatedDimensionState;
        id_origin_level_2: ValidatedDimensionState;
        id_language: ValidatedDimensionState;
        id_user_id: ValidatedDimensionState;
        id_user_name: ValidatedDimensionState;
        id_event_visitor: ValidatedDimensionState;
        id_query_pipeline: ValidatedDimensionState;
        id_cause_v2: ValidatedDimensionState;
        id_source_name: ValidatedDimensionState;
        id_document_sys_uri_hash: ValidatedDimensionState;
        id_custom_event_type: ValidatedDimensionState;
        id_custom_event_value: ValidatedDimensionState;
    };
}

const blankValidatedDimensionState = (): ValidatedDimensionState => ({
    isFetched: false,
    results: [],
    isFailed: false,
});

export const StrictValidationDefaultState: StrictValidationState = {
    applyState: {
        isFetched: false,
        result: undefined,
        isFailed: false,
        saveFailed: false,
        saveDone: false,
        saveStarted: false,
    },
    validatedDimensions: {
        id_origin_level_1: blankValidatedDimensionState(),
        id_origin_level_2: blankValidatedDimensionState(),
        id_language: blankValidatedDimensionState(),
        id_user_id: blankValidatedDimensionState(),
        id_user_name: blankValidatedDimensionState(),
        id_event_visitor: blankValidatedDimensionState(),
        id_query_pipeline: blankValidatedDimensionState(),
        id_cause_v2: blankValidatedDimensionState(),
        id_source_name: blankValidatedDimensionState(),
        id_document_sys_uri_hash: blankValidatedDimensionState(),
        id_custom_event_type: blankValidatedDimensionState(),
        id_custom_event_value: blankValidatedDimensionState(),
    },
};
