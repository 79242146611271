<% const PlasmaStyle = require('@coveord/plasma-style'); %>

<span class="enabled <%- ctx.isSelected() ? 'selected-value state-selected ' : '' %> <%- ctx.otherClasses %>" title="<%- ctx.formatDescription() %>"
    <%= ctx.hasOtherProperties() ? ctx.getOtherProperties() : '' %>
    <%= ctx.hasOtherProperty() ? ctx.otherProperty.property + '="' + ctx.otherProperty.value + '"' : '' %>>
    <% if (PlasmaStyle.svg && PlasmaStyle.svg[ctx.iconClass]) { %>
    <%= PlasmaStyle.svg[ctx.iconClass].render('icon ' + ctx.svgClass, ctx.svgSpanClass + ' value-icon') %>
    <% } else if (ctx.iconClass && s.contains(iconClass, 'coveo-icon-')) { %>
    <%= PlasmaStyle.SVG(ctx.iconClass, 'icon icon-small ' + ctx.svgClass, ctx.svgSpanClass + ' value-icon') %>
    <% } else if (ctx.iconClass) { %>
    <i class="<%- ctx.iconClass %>" title="<%- ctx.formatValue() %>"></i>
    <% } %>
    <%- ctx.formatValue() %>
</span>
