import {UALocales} from '../../UALocales';

const events = {
    search: UALocales.format('Settings.StrictValidation.search'),
    click: UALocales.format('Settings.StrictValidation.click'),
    view: UALocales.format('Settings.StrictValidation.view'),
    custom: UALocales.format('Settings.StrictValidation.custom'),
};

const returnNames = {
    originLevel1: 'originLevel1',
    originLevel2: 'originLevel2',
    language: 'language',
    userID: 'userId',
    userName: 'DISPLAYNAME',
    visitorID: 'EVENT.VISITOR',
    queryPipeline: 'QUERYPIPELINE',
    causeV2: 'causeV2',
    sourceName: 'sourceName',
    documentSysURIHash: 'documentSysUriHash',
    customEventType: 'customEventType',
    customEventValue: 'customEventValue',
};

const allEvents = [events.search, events.click, events.view, events.custom].join(', ');
const originRegex = '[\\w &\\+\\-]+';
const partialRegex = '\\.\\.|\\\\|\\||\\[|\\]';
const wordRegex = '[\\w \\-]+';

export interface ValidatedDimension {
    returnName: string;
    displayName: string;
    regex: string;
    id: string;
    relatedEvents: string;
}

export const validatedDimensions: ValidatedDimension[] = [
    {
        returnName: returnNames.originLevel1,
        displayName: UALocales.format('Settings.StrictValidation.originLevel1'),
        regex: `${UALocales.format('Settings.StrictValidation.ruleExactly')} ${originRegex}`,
        id: 'id_origin_level_1',
        relatedEvents: allEvents,
    },
    {
        returnName: returnNames.originLevel2,
        displayName: UALocales.format('Settings.StrictValidation.originLevel2'),
        regex: `${UALocales.format('Settings.StrictValidation.ruleExactly')} ${originRegex}`,
        id: 'id_origin_level_2',
        relatedEvents: allEvents,
    },
    {
        returnName: returnNames.language,
        displayName: UALocales.format('Settings.StrictValidation.language'),
        regex: `${UALocales.format('Settings.StrictValidation.ruleExactly')} ${wordRegex}`,
        id: 'id_language',
        relatedEvents: allEvents,
    },
    {
        returnName: returnNames.userID,
        displayName: UALocales.format('Settings.StrictValidation.userID'),
        regex: `${UALocales.format('Settings.StrictValidation.ruleExactly')} [\\w \\-\\\\@+&\\.]+`,
        id: 'id_user_id',
        relatedEvents: allEvents,
    },
    {
        returnName: returnNames.userName,
        displayName: UALocales.format('Settings.StrictValidation.userName'),
        regex: `${UALocales.format('Settings.StrictValidation.rulePartially')} \\.\\.|\\||\\[|\\]`,
        id: 'id_user_name',
        relatedEvents: allEvents,
    },
    {
        returnName: returnNames.visitorID,
        displayName: UALocales.format('Settings.StrictValidation.visitorID'),
        regex: `${UALocales.format(
            'Settings.StrictValidation.ruleExactly',
        )} ^[0_9a_f]{8}_[0_9a_f]{4}_[0_9a_f]{4}_[0_9a_f]{4}_[0_9a_f]{12}$`,
        id: 'id_event_visitor',
        relatedEvents: allEvents,
    },
    {
        returnName: returnNames.queryPipeline,
        displayName: UALocales.format('Settings.StrictValidation.queryPipeline'),
        regex: `${UALocales.format('Settings.StrictValidation.ruleExactly')} [\\w &\\(\\)\\-\\.]+`,
        id: 'id_query_pipeline',
        relatedEvents: [events.search, events.click].join(', '),
    },
    {
        returnName: returnNames.causeV2,
        displayName: UALocales.format('Settings.StrictValidation.causeV2'),
        regex: `${UALocales.format('Settings.StrictValidation.ruleExactly')} [\\w &\\-\\.]+`,
        id: 'id_cause_v2',
        relatedEvents: [events.search, events.click].join(', '),
    },
    {
        returnName: returnNames.sourceName,
        displayName: UALocales.format('Settings.StrictValidation.sourceName'),
        regex: `${UALocales.format('Settings.StrictValidation.rulePartially')} ${partialRegex}`,
        id: 'id_source_name',
        relatedEvents: events.click,
    },
    {
        returnName: returnNames.documentSysURIHash,
        displayName: UALocales.format('Settings.StrictValidation.sysURIHash'),
        regex: `${UALocales.format('Settings.StrictValidation.ruleExactly')} [\\w\\/ðñ+]{16}`,
        id: 'id_document_sys_uri_hash',
        relatedEvents: events.click,
    },
    {
        returnName: returnNames.customEventType,
        displayName: UALocales.format('Settings.StrictValidation.eventType'),
        regex: `${UALocales.format('Settings.StrictValidation.ruleExactly')} ${wordRegex}`,
        id: 'id_custom_event_type',
        relatedEvents: events.custom,
    },
    {
        returnName: returnNames.customEventValue,
        displayName: UALocales.format('Settings.StrictValidation.eventValue'),
        regex: `${UALocales.format('Settings.StrictValidation.rulePartially')} ${partialRegex}`,
        id: 'id_custom_event_value',
        relatedEvents: events.custom,
    },
];
