import {PipelineModel, TrendsModel} from '@core/api';
import * as s from 'underscore.string';

import {locales} from '../../Locales';
import {ABTestConstants} from './ABTestConstants';
import {ABTestMetricName, ABTestPipelinesComparison} from './ABTestTypes';

const parseAnalytics = (analytics: TrendsModel) =>
    analytics?.combinations.map((queryPipeline) => ({
        ...queryPipeline,
        AverageClickRank: queryPipeline.AverageClickRank < 1.0 ? null : queryPipeline.AverageClickRank,
    }));

const filterHasResult = (abTestAnalytics: Array<Record<string, any>>, queryPipeline: string, hasResult: boolean) =>
    // eslint-disable-next-line @helpers/no-underscore
    _.findWhere(abTestAnalytics, {hasResult, splitTestRunVersion: queryPipeline});

const averageClickRankFormat = (filteredAnalytics: number) => (filteredAnalytics < 1.0 ? null : filteredAnalytics);

const noResultsRatioFormat = (value: number | null, total: number | null) => {
    if (value !== 0 && value !== null && total !== 0 && total !== null) {
        return value / total;
    }
    return null;
};

const bestLowerValueMetric = (pipelineAValue: number, pipelineBValue: number) => {
    if (pipelineAValue === pipelineBValue) {
        return null;
    }
    return pipelineAValue < pipelineBValue
        ? ABTestConstants.BestScenario.originalScenario
        : ABTestConstants.BestScenario.testScenario;
};

const bestHigherValueMetric = (pipelineAValue: number, pipelineBValue: number) => {
    if (pipelineAValue === pipelineBValue) {
        return null;
    }
    return pipelineAValue > pipelineBValue
        ? ABTestConstants.BestScenario.originalScenario
        : ABTestConstants.BestScenario.testScenario;
};

const findBestMetricScenario = (pipelineAValue: number, pipelineBValue: number, isLowerBetter: boolean) => {
    let bestScenario: string;
    if (pipelineAValue && pipelineBValue) {
        isLowerBetter
            ? (bestScenario = bestLowerValueMetric(pipelineAValue, pipelineBValue))
            : (bestScenario = bestHigherValueMetric(pipelineAValue, pipelineBValue));
    } else if (pipelineAValue && !pipelineBValue) {
        bestScenario = ABTestConstants.BestScenario.originalScenario;
    } else if (!pipelineAValue && pipelineBValue) {
        bestScenario = ABTestConstants.BestScenario.testScenario;
    }
    return bestScenario;
};

const comparePipelines = (
    data: Array<Record<string, any>>,
    activeQueryPipeline: PipelineModel,
    activeTestPipeline: PipelineModel,
): ABTestPipelinesComparison => {
    let bestScenario: string;

    const activeQueryPipelineWithResults = filterHasResult(data, activeQueryPipeline.name, true);
    const activeQueryPipelineWithoutResults = filterHasResult(data, activeQueryPipeline.name, false);
    const activeTestPipelineWithResults = filterHasResult(data, activeTestPipeline.name, true);
    const activeTestPipelineWithoutResults = filterHasResult(data, activeTestPipeline.name, false);

    const bestAvgClickRank = findBestMetricScenario(
        averageClickRankFormat(activeQueryPipelineWithResults?.AverageClickRank),
        averageClickRankFormat(activeTestPipelineWithResults?.AverageClickRank),
        true,
    );
    const bestSearchRatio = findBestMetricScenario(
        activeQueryPipelineWithResults?.ClickThroughRatio,
        activeTestPipelineWithResults?.ClickThroughRatio,
        false,
    );

    const pipelineANoResultsRation = noResultsRatioFormat(
        activeQueryPipelineWithoutResults?.PerformSearch ?? null,
        activeQueryPipelineWithResults?.PerformSearch ?? null,
    );
    const pipelineBNoResultsRation = noResultsRatioFormat(
        activeTestPipelineWithoutResults?.PerformSearch ?? null,
        activeTestPipelineWithResults?.PerformSearch ?? null,
    );

    const bestNoResultsRatio = findBestMetricScenario(pipelineANoResultsRation, pipelineBNoResultsRation, true);

    const scenarioScore: number = calculateScenarioScore([bestAvgClickRank, bestSearchRatio, bestNoResultsRatio]);

    if (scenarioScore > 0) {
        bestScenario = locales.format('keepOriginalScenario');
    } else if (scenarioScore < 0) {
        bestScenario = locales.format('keepTestScenario');
    } else {
        bestScenario = locales.format('cantConcludeOnScenario');
    }

    return {
        bestScenario,
        metrics: {
            [ABTestMetricName.AvgClickRank]: {
                pipelineA: averageClickRankFormat(activeQueryPipelineWithResults?.AverageClickRank) ?? null,
                pipelineB: averageClickRankFormat(activeTestPipelineWithResults?.AverageClickRank) ?? null,
                bestScenario: bestAvgClickRank,
            },
            [ABTestMetricName.AvgNumberOfResults]: {
                pipelineA:
                    activeQueryPipelineWithResults?.[ABTestConstants.ABTestMetrics.averageNumberOfResults] ?? null,
                pipelineB:
                    activeTestPipelineWithResults?.[ABTestConstants.ABTestMetrics.averageNumberOfResults] ?? null,
            },
            [ABTestMetricName.NoResults]: {
                pipelineA: activeQueryPipelineWithoutResults?.PerformSearch ?? null,
                pipelineB: activeTestPipelineWithoutResults?.PerformSearch ?? null,
            },
            [ABTestMetricName.NoResultsRatio]: {
                pipelineA: pipelineANoResultsRation,
                pipelineB: pipelineBNoResultsRation,
                bestScenario: bestNoResultsRatio,
            },
            [ABTestMetricName.SearchRatio]: {
                pipelineA: activeQueryPipelineWithResults?.ClickThroughRatio
                    ? activeQueryPipelineWithResults?.ClickThroughRatio
                    : null,
                pipelineB: activeTestPipelineWithResults?.ClickThroughRatio
                    ? activeTestPipelineWithResults?.ClickThroughRatio
                    : null,
                bestScenario: bestSearchRatio,
            },
            [ABTestMetricName.WithResults]: {
                pipelineA: activeQueryPipelineWithResults?.PerformSearch ?? null,
                pipelineB: activeTestPipelineWithResults?.PerformSearch ?? null,
            },
        },
    };
};

const calculateScenarioScore = (metrics: string[]) => {
    let score: number = 0;
    _.each(metrics, (value) => {
        if (value === ABTestConstants.BestScenario.originalScenario) {
            score += 1;
        } else if (value === ABTestConstants.BestScenario.testScenario) {
            score -= 1;
        }
    });
    return score;
};

const truncateABTestName = (pipelineName: string) => {
    const secondsEpoch = Math.floor(new Date().getTime() / 1000);
    return `${
        pipelineName.length < ABTestConstants.TRUNCATED_SPLIT_TEST_NAME_LENGTH
            ? pipelineName
            : s.truncate(pipelineName, ABTestConstants.TRUNCATED_SPLIT_TEST_NAME_LENGTH)
    }-mirror-${secondsEpoch}`;
};

export const ABTestUtils = {
    parseAnalytics,
    comparePipelines,
    truncateABTestName,
};
