import {BasePayload, IListBoxPayload, IReduxAction, ListBoxActions} from '@coveord/plasma-react';
import _ from 'underscore';
import {EventFilterSetActionsType} from '../actions/EventFilterSetActions';

export interface EventFilterSetState {
    id: string;
}

export interface EventFilterSetStates {
    [id: string]: EventFilterSetState;
}

const add = (state: EventFilterSetStates, action: IReduxAction<BasePayload>) => ({
    ...state,
    [action.payload.id]: {
        id: action.payload.id,
    },
});

const remove = (state: EventFilterSetStates, action: IReduxAction<BasePayload>) => _.omit(state, action.payload.id);

const onSelect = (state: EventFilterSetState, action: IReduxAction<IListBoxPayload>) => ({
    ...state,
    [action.payload.id]: {...state[action.payload.id]},
});

const EventFilterSetReducers: {[key: string]: (...args: any[]) => EventFilterSetStates} = {
    [EventFilterSetActionsType.add]: add,
    [EventFilterSetActionsType.remove]: remove,
};

const EventFilterSetReducer: {[key: string]: (...args: any[]) => EventFilterSetState} = {
    [ListBoxActions.select]: onSelect,
};

export type EventFilterSetPayload = BasePayload | IListBoxPayload;

export const eventFilterSetReducer = (
    state: EventFilterSetStates = {},
    action: IReduxAction<EventFilterSetPayload>,
): EventFilterSetStates => {
    if (!_.isUndefined(EventFilterSetReducers[action.type])) {
        return EventFilterSetReducers[action.type](state, action);
    }

    if (
        action &&
        action.payload &&
        action.payload.id &&
        state[action.payload.id] &&
        !_.isUndefined(EventFilterSetReducer[action.type])
    ) {
        return {
            ...state,
            [action.payload.id]: EventFilterSetReducer[action.type](state[action.payload.id], action),
        };
    }

    return state;
};
