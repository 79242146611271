import {RouteObject} from '@core/routes';

export const routes: RouteObject[] = [
    {
        path: '/:orgId/home',
        lazy: async () => {
            const {TrialDashboard} = await import('../trial-dashboard');
            return {Component: TrialDashboard};
        },
    },
];
