import {
    AccountInfoModelV15,
    ActivityOperation,
    ApiKeyModel,
    CrawlingModuleEntity,
    DefinitionModel,
    DetailedSecurityCacheMemberModel,
    GlobalGroupModel,
    GroupModel,
    InProductExperienceModel,
    IPXInterfaceConfiguration,
    PageModel,
    ResourceSnapshotsModel,
    ResourceSnapshotsSynchronizationPlanModel,
    SearchPageInterfaceConfiguration,
    SecurityProviderModelWithStatus,
    SimpleSchemaEntity,
    SourceFieldModel,
    SourceModel,
} from '@core/api';
import {ProjectState} from '@core/projects';
import {KeyValueReducerGenerator, ObjectReducerGenerator} from '@core/store';
import {CommonState, TrialHomePageConstants} from '@coveord/jsadmin-common';
import {ReducersMapObject} from 'redux';
import {CriticalUpdatesState} from '../critical-updates/CriticalUpdatesConstants';
import {FieldsConstants} from '../fields/FieldsConstants';
import {GlobalGroupsConstants} from '../global-groups/GlobalGroupsConstants';
import {globalGroupsReducer, GlobalGroupsState} from '../global-groups/GlobalGroupsReducer';
import {defaultGroupsReducer, DefaultGroupsState} from '../groups/DefaultGroups';
import {GroupEditorConstants} from '../groups/edit-group/GroupEditorConstants';
import {GroupConstants} from '../groups/GroupConstants';
import {InAppWidgetActionTypes} from '../in-app-widget/InAppWidgetActions';
import {notificationsReducer, NotificationsState} from '../notifications/NotificationsReducers';
import {operationTypesReducer} from '../notifications/user-notifications/OperationTypesReducer';
import {resourceTypesReducer} from '../notifications/user-notifications/ResourceTypesReducer';
import {
    granularPrivilegesTablesReducer,
    GranularPrivilegesTablesState,
} from '../privileges/granular-privileges-table/GranularPrivilegesTableReducers';
import {ProjectDropdownActionsType} from '../projects/ProjectDropdownActions';
import {ResourceSnapshotsActionTypes} from '../resource-snapshots/ResourceSnapshotsConstants';
import {SamlConstants, SamlReduxState} from '../saml/SamlConstants';
import {ISearchInterfaceState} from '../search-interfaces/models/SearchInterfaceState';
import {SearchInterfacesReducer} from '../search-interfaces/SearchInterfacesReducer';
import {searchPageEditorReducer} from '../search-pages/EditSearchPagesReducer';
import {SearchPage, SearchPageEditorState} from '../search-pages/models/SearchPageModel';
import {SearchPagesActionsTypes} from '../search-pages/SearchPagesActions';
import {SecurityCacheActionTypes} from '../security-cache/SecurityCacheConstants';
import {analyticsSettingsContants} from '../settings-react/analytics/analyticsSettingsConstants';
import {SettingActionTypes} from '../settings-react/SettingsActions';
import {
    searchHubGlobalReducers,
    SearchHubGlobalState,
    searchHubGraphReducers,
    SearchHubGraphState,
    searchHubLicenseReducers,
    SearchHubLicenseState,
    searchHubReducers,
    SearchHubsState,
} from '../settings/search/search-hub';
import {SecurityPopupAcknowledgmentActionsType} from '../sources/edit/content-security-tab/security-popup-acknowledgment/SecurityPopupAcknowledgmentActions';
import {SchemaSourceConstants} from '../sources/schema-sources/components/SchemaSourceConstants';
import {SchemaSourceObject, SimpleSchemaEntityWithFields} from '../sources/schema-sources/SchemaSourceTypes';
import {
    allowedIdentitiesReducers,
    AllowedIdentityCompositeState,
} from '../sources/security/reducers/AllowedIdentitiesReducers';
import {OAuth2ActionTypes} from '../sources/shared/authentication-forms/oauth2-authentication/OAuth2AuthenticationActions';
import {
    OAuth2InitialState,
    OAuth2StateType,
} from '../sources/shared/authentication-forms/oauth2-authentication/OAuth2AuthenticationSelectors';
import {CrawlingModuleActionTypes} from '../sources/shared/component/crawling-module/CrawlingModuleActions';
import {logicalIndexReducer, LogicalIndexState} from '../sources/shared/component/logical-index/LogicalIndexReducer';
import {PushSourceApiKeyActionType} from '../sources/sources/pushR/PushSourceConstants';
import {
    SfObjectConditionsCompositeState,
    sfObjectConditionsReducers,
} from '../sources/sources/salesforce/objects/conditions/SfObjectConditionsReducers';
import {salesforceReducer, SalesforceState} from '../sources/sources/salesforce/SalesforceReducers';
import {SourcesConstants} from '../sources/SourcesConstants';

export interface AdminState extends CommonState, ProjectState {
    openModals: string[];
    allowedIdentitiesCompositeState?: AllowedIdentityCompositeState;
    sfObjectConditionsCompositeState?: SfObjectConditionsCompositeState;
    resourceTypes: string[];
    operationTypes: ActivityOperation[];
    addNotificationNeedsReset: boolean;
    criticalUpdates: CriticalUpdatesState;
    notifications: NotificationsState;
    globalGroup: GlobalGroupsState;
    groupEditor: GroupModel;
    groups: GroupModel[];
    globalGroups: GlobalGroupModel[];
    defaultGroups?: DefaultGroupsState;
    salesforce: SalesforceState;
    granularPrivilegesTables: GranularPrivilegesTablesState;
    searchHubs: SearchHubsState;
    searchHubStats: SearchHubGlobalState;
    searchHubMetrics: SearchHubLicenseState[];
    searchHubGraphs: SearchHubGraphState;
    logicalIndex?: LogicalIndexState;
    securityProviders: SecurityProviderModelWithStatus[];
    securityIdentities: DetailedSecurityCacheMemberModel[];
    securityIdentitiesErrorCode: string | null;
    securityPopupAcknowledgment: boolean;
    saml: SamlReduxState;
    field: SourceFieldModel;
    sources: SourceModel[];
    editSource: SourceModel;
    overrideId: string;
    organizationDefinition: DefinitionModel;
    snapshots: ResourceSnapshotsModel[];
    snapshot: ResourceSnapshotsModel;
    synchronizationPlan: ResourceSnapshotsSynchronizationPlanModel;
    updatedSynchronizationPlan: ResourceSnapshotsSynchronizationPlanModel;
    schemaEntities: Record<string, SimpleSchemaEntity>; // all entities of the current source
    selectedSchemaEntity: SimpleSchemaEntityWithFields; // currently selected entity with fields
    sourceEntities: Record<string, SchemaSourceObject>; // objects to get of the current source
    referencedEntityAndReferencer: {entity: SimpleSchemaEntityWithFields; referencerId: string}; // an object containing a schema entity referenced by the selected field (referencer)
    searchPages: SearchPage[];
    searchPageEditor: SearchPageEditorState;
    oauth2Tokens: OAuth2StateType;
    uaAdminAccount: AccountInfoModelV15;
    pushSourceApiKey: string;
    trial: Record<string, boolean>;
    searchInterfaces: ISearchInterfaceState;
    spo2Base64Certificate: string;
    apiKeys: ApiKeyModel[];
    ipxs: InProductExperienceModel[];
    ipxsV2: PageModel<IPXInterfaceConfiguration>;
    crawlingModules: CrawlingModuleEntity[];
    nextGenSearchPages: PageModel<SearchPageInterfaceConfiguration>;
    projectIds?: string[];
}

export const adminReducers: ReducersMapObject = {
    allowedIdentitiesCompositeState: allowedIdentitiesReducers,
    sfObjectConditionsCompositeState: sfObjectConditionsReducers,
    resourceTypes: resourceTypesReducer,
    operationTypes: operationTypesReducer,
    notifications: notificationsReducer,
    globalGroup: globalGroupsReducer,
    groupEditor: ObjectReducerGenerator<GroupModel>(GroupEditorConstants.actionType, null),
    groups: KeyValueReducerGenerator<GroupModel[]>(GroupConstants.actionType, []),
    globalGroups: KeyValueReducerGenerator<GlobalGroupModel[]>(GlobalGroupsConstants.actionType, []),
    securityProviders: KeyValueReducerGenerator<SecurityProviderModelWithStatus[]>(
        SecurityCacheActionTypes.fetchProviders,
        null,
    ),
    securityIdentities: KeyValueReducerGenerator<DetailedSecurityCacheMemberModel[]>(
        SecurityCacheActionTypes.fetchIdentities,
        null,
    ),
    securityIdentitiesErrorCode: KeyValueReducerGenerator<string | null>(
        SecurityCacheActionTypes.fetchIdentitiesErrorCode,
        null,
    ),
    securityPopupAcknowledgment: KeyValueReducerGenerator<boolean>(
        SecurityPopupAcknowledgmentActionsType.SetSecurityPopupAcknowledgment,
        false,
    ),
    defaultGroups: defaultGroupsReducer,
    salesforce: salesforceReducer,
    granularPrivilegesTables: granularPrivilegesTablesReducer,
    searchHubs: searchHubReducers,
    searchHubStats: searchHubGlobalReducers,
    searchHubMetrics: searchHubLicenseReducers,
    searchHubGraphs: searchHubGraphReducers,
    logicalIndex: logicalIndexReducer,
    saml: ObjectReducerGenerator<SamlReduxState>(SamlConstants.samlActionsType, SamlConstants.defaultState),
    field: KeyValueReducerGenerator<SourceFieldModel>(FieldsConstants.Requests.set),
    sources: KeyValueReducerGenerator<SourceModel[]>(SourcesConstants.Requests.fetch),
    editSource: KeyValueReducerGenerator<SourceModel>(SourcesConstants.Requests.set),
    snapshots: KeyValueReducerGenerator<ResourceSnapshotsModel[]>(
        ResourceSnapshotsActionTypes.fetchResourceSnapshots,
        [],
    ),
    overrideId: KeyValueReducerGenerator<string>(SettingActionTypes.updateOverrideId, null),
    organizationDefinition: KeyValueReducerGenerator<DefinitionModel>(
        SettingActionTypes.updateOrganizationDefinition,
        null,
    ),
    snapshot: KeyValueReducerGenerator<ResourceSnapshotsModel>(ResourceSnapshotsActionTypes.fetchResourceSnapshot),
    synchronizationPlan: KeyValueReducerGenerator<ResourceSnapshotsSynchronizationPlanModel>(
        ResourceSnapshotsActionTypes.fetchSynchronizationPlan,
    ),
    updatedSynchronizationPlan: KeyValueReducerGenerator<ResourceSnapshotsSynchronizationPlanModel>(
        ResourceSnapshotsActionTypes.updateSynchronizationPlan,
    ),
    schemaEntities: KeyValueReducerGenerator<Record<string, SimpleSchemaEntity>>(
        SchemaSourceConstants.Requests.fetchTableEntities,
        null,
    ),
    selectedSchemaEntity: KeyValueReducerGenerator<SimpleSchemaEntityWithFields>(
        SchemaSourceConstants.Requests.fetchSelectedSchemaEntity,
        null,
    ),
    sourceEntities: KeyValueReducerGenerator<Record<string, SchemaSourceObject>>(
        SchemaSourceConstants.Requests.setSourceEntities,
        null,
    ),
    referencedEntityAndReferencer: KeyValueReducerGenerator<{
        entity: SimpleSchemaEntityWithFields;
        referencerId: string;
    }>(SchemaSourceConstants.Requests.fetchSelectedSchemaReference, null),
    searchPages: KeyValueReducerGenerator<SearchPage[]>(SearchPagesActionsTypes.setPages, null),
    searchPageEditor: searchPageEditorReducer,
    oauth2Tokens: KeyValueReducerGenerator<OAuth2StateType>(OAuth2ActionTypes.setOAuth2Tokens, OAuth2InitialState),
    uaAdminAccount: ObjectReducerGenerator<AccountInfoModelV15>(
        analyticsSettingsContants.analyticsSettingsActionsType.fetchV15AccountInfo,
        null,
    ),
    pushSourceApiKey: KeyValueReducerGenerator<string>(PushSourceApiKeyActionType.set, null),
    trial: ObjectReducerGenerator<Record<string, boolean>>(TrialHomePageConstants.actionType, {}),
    searchInterfaces: SearchInterfacesReducer,
    ipxs: KeyValueReducerGenerator<InProductExperienceModel[]>(InAppWidgetActionTypes.setWidgets, null),
    ipxsV2: KeyValueReducerGenerator<PageModel<IPXInterfaceConfiguration>>(InAppWidgetActionTypes.setV2Widgets, null),
    crawlingModules: KeyValueReducerGenerator<CrawlingModuleEntity[]>(CrawlingModuleActionTypes.set, null),
    nextGenSearchPages: KeyValueReducerGenerator<PageModel<SearchPageInterfaceConfiguration>>(
        SearchPagesActionsTypes.setNextGenPages,
        {items: [], totalEntries: 0, totalPages: 0},
    ),
    projectIds: KeyValueReducerGenerator<string[]>(ProjectDropdownActionsType.update, null),
};
