import {Dictionary, Locales as CommonLocales} from '@core/locales';
import {commonLocales, CommonLocalesKeys} from '@coveord/jsadmin-common';
import _ from 'underscore';
import {LocalesKeys} from './LocalesKeys';
import Activity from './strings/Activity.json';
import AdobeExperienceManager from './strings/AdobeExperienceManager.json';
import ApiKeys from './strings/ApiKeys.json';
import Application from './strings/Application.json';
import Cluster from './strings/Cluster.json';
import ClusterCluster from './strings/Cluster/Cluster.json';
import ContentBrowser from './strings/ContentBrowser.json';
import CrawlingModules from './strings/CrawlingModules.json';
import CriticalUpdates from './strings/CriticalUpdates.json';
import Extensions from './strings/Extensions.json';
import Fields from './strings/Fields.json';
import GlobalContent from './strings/GlobalContent.json';
import Groups from './strings/Groups.json';
import InAppWidget from './strings/InAppWidget/InAppWidget.json';
import Limits from './strings/Limits.json';
import Members from './strings/Members.json';
import MicrosoftDynamics from './strings/MicrosoftDynamics.json';

import navigation from './strings/navigation.json';
import Nodes from './strings/Nodes.json';
import Notifications from './strings/Notifications.json';
import Organization from './strings/Organization.json';
import Backups from './strings/Organizations/Backups/Backups.json';
import Organizations from './strings/Organizations/Organizations.json';
import Panels from './strings/Panels.json';
import Permissions from './strings/Permissions.json';
import PlatformErrorCodes from './strings/PlatformErrorCodes.json';
import Privileges from './strings/Privileges.json';
import ResourceSnapshots from './strings/ResourceSnapshots/ResourceSnapshots.json';
import Salesforce from './strings/Salesforce.json';
import SAP from './strings/SAP.json';
import Schedules from './strings/Schedules.json';
import SchemaSources from './strings/SchemaSources.json';
import SearchContent from './strings/SearchContent.json';
import SearchInterfaces from './strings/SearchInterfaces.json';
import SearchPages from './strings/SearchPages.json';
import SearchPagesSearchPages from './strings/SearchPages/SearchPages.json';
import SecurityIdentities from './strings/SecurityIdentities.json';
import ServerStatus from './strings/ServerStatus.json';
import Settings from './strings/Settings.json';
import Informations from './strings/Settings/Informations.json';
import SettingsLimits from './strings/Settings/Limits.json';
import SearchHub from './strings/Settings/SearchHub/SearchHub.json';
import SourceDocumentationUrls from './strings/SourceDocumentationUrls.json';
import SourceExtensions from './strings/SourceExtensions.json';
import SourceGraphQl from './strings/SourceGraphQl.json';
import SourceJSONEditor from './strings/SourceJSONEditor.json';
import SourceMappings from './strings/SourceMappings.json';
import SourceMetadata from './strings/SourceMetadata.json';
import Sources from './strings/Sources/Sources.json';
import SourcesGlobal from './strings/SourcesGlobal.json';
import SourceTypes from './strings/SourceTypes.json';
import Strings from './strings/Strings.json';
import TemporaryAccess from './strings/TemporaryAccess.json';
import TrialHomePage from './strings/TrialHomePage/TrialHomePage.json';

const req: Record<string, Record<string, any>> = {
    'strings/navigation': navigation,
    'strings/Permissions': Permissions,
    'strings/Organizations/Organizations': Organizations,
    'strings/Organizations/Backups/Backups': Backups,
    'strings/Settings/SearchHub/SearchHub': SearchHub,
    'strings/Settings/Limits': SettingsLimits,
    'strings/Settings/Informations': Informations,
    'strings/SearchContent': SearchContent,
    'strings/Cluster/Cluster': ClusterCluster,
    'strings/SchemaSources': SchemaSources,
    'strings/SourceJSONEditor': SourceJSONEditor,
    'strings/ContentBrowser': ContentBrowser,
    'strings/SearchPages/SearchPages': SearchPagesSearchPages,
    'strings/Settings': Settings,
    'strings/SourceDocumentationUrls': SourceDocumentationUrls,
    'strings/CriticalUpdates': CriticalUpdates,
    'strings/Limits': Limits,
    'strings/Panels': Panels,
    'strings/PlatformErrorCodes': PlatformErrorCodes,
    'strings/Privileges': Privileges,
    'strings/Fields': Fields,
    'strings/Members': Members,
    'strings/Extensions': Extensions,
    'strings/ServerStatus': ServerStatus,
    'strings/ApiKeys': ApiKeys,
    'strings/Schedules': Schedules,
    'strings/ResourceSnapshots/ResourceSnapshots': ResourceSnapshots,
    'strings/Cluster': Cluster,
    'strings/Notifications': Notifications,
    'strings/SearchInterfaces': SearchInterfaces,
    'strings/Salesforce': Salesforce,
    'strings/Organization': Organization,
    'strings/Strings': Strings,
    'strings/Groups': Groups,
    'strings/AdobeExperienceManager': AdobeExperienceManager,
    'strings/SAP': SAP,
    'strings/Sources/Sources': Sources,
    'strings/TemporaryAccess': TemporaryAccess,
    'strings/TrialHomePage/TrialHomePage': TrialHomePage,
    'strings/SearchPages': SearchPages,
    'strings/Application': Application,
    'strings/SourceMappings': SourceMappings,
    'strings/MicrosoftDynamics': MicrosoftDynamics,
    'strings/InAppWidget/InAppWidget': InAppWidget,
    'strings/GlobalContent': GlobalContent,
    'strings/Activity': Activity,
    'strings/Nodes': Nodes,
    'strings/CrawlingModules': CrawlingModules,
    'strings/SourceExtensions': SourceExtensions,
    'strings/SecurityIdentities': SecurityIdentities,
    'strings/SourceGraphQl': SourceGraphQl,
    'strings/SourceMetadata': SourceMetadata,
    'strings/SourcesGlobal': SourcesGlobal,
    'strings/SourceTypes': SourceTypes,
};

const translations = {};

Object.keys(req).forEach((key) => {
    const parts: string[] = key.split('/');
    const roots: string[] = parts.splice(1, parts.length - 2);

    let map: any = translations;

    _.each(roots, (root: string) => {
        if (!map[root]) {
            _.extend(map, {[root]: {}});
        }
        map = map[root];
    });

    _.extend(map, req[key]);
});

const enDictionary: Dictionary = {
    translations,
    locale: 'en',
};

export const Locales = new CommonLocales<LocalesKeys | CommonLocalesKeys>({
    dictionaries: [enDictionary],
    defaultLocale: 'en',
    fallbackLocales: commonLocales,
});
