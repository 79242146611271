import {GlobalConstants} from '@coveord/jsadmin-common';

export enum AssociationModelTypes {
    TopClicks = 'topclicks',
    QuerySuggest = 'querysuggest',
    EventRecommendation = 'eventrecommendation',
    FacetSense = 'facetsense',
    ECommerce = 'ecommerce',
    CaseClassification = 'caseclassification',
    SmartSnippets = 'mlquestionanswering',
}

export const EditAssociationsIds = {
    Modal: 'EditAssociationModal',
    Model: 'EditAssociationModel',
    MatchAdvanced: 'EditAssociationMatchAdvanced',
    MatchQuery: 'EditAssociationMatchQuery',
    MatchITD: 'EditAssociationMatchITD',
    SelectCondition: 'EditAssociationSelectCondition',
    PositionModal: 'EditAssociationPositionModal',
    Position: 'EditAssociationPosition',
    FacetOrdering: 'EditAssociationFacetOrdering',
    FacetValueOrdering: 'EditAssociationFacetValueOrdering',
    FacetRankingBoost: 'EditAssociationFacetRankingBoost',
    ECommerceSubmodel: 'EditAssociationECommerceSubmodel',
    AutomaticSelection: 'EditAssociationAutoSelect',
};

export const ECommerceSubmodelTypes = {
    User: 'user',
    FrequentBought: 'frequentBought',
    FrequentViewed: 'frequentViewed',
    CartRecommender: 'cart',
    PopularViewed: 'popularViewed',
    PopularBought: 'popularBought',
};

enum ConfigParams {
    intelligentTermDetection = 'intelligentTermDetection',
    matchAdvancedExpression = 'matchAdvancedExpression',
    matchBasicExpression = 'matchBasicExpression',
    rankingModifier = 'rankingModifier',
    submodel = 'submodel',
}

export const AssociationModelTypesRestrictionRules = {
    [AssociationModelTypes.FacetSense]: [AssociationModelTypes.EventRecommendation],
    [AssociationModelTypes.TopClicks]: [AssociationModelTypes.EventRecommendation],
    [AssociationModelTypes.QuerySuggest]: [AssociationModelTypes.EventRecommendation],
    [AssociationModelTypes.EventRecommendation]: [
        AssociationModelTypes.TopClicks,
        AssociationModelTypes.QuerySuggest,
        AssociationModelTypes.FacetSense,
    ],
};

const Routes = {
    Table: (pipelineId: string) => `/search/pipelines/${pipelineId}/machine-learning/`,
    Edit: (pipelineId: string, id: string = GlobalConstants.urlParameters.new) => `${Routes.Table(pipelineId)}${id}`,
    EditJSON: (pipelineId: string, id: string = GlobalConstants.urlParameters.new) =>
        `${Routes.Edit(pipelineId, id)}/json/edit`,
    ViewJSON: (pipelineId: string, id: string = GlobalConstants.urlParameters.new) =>
        `${Routes.Edit(pipelineId, id)}/json/view`,
};

const Components = {
    ToggleModal: 'association-toggle-modal',
    SwitchToEditJSONModal: 'association-switch-to-edit-json',
    RankingModifier: 'ranking-modifier-slider',
    InfoBox: 'machine-learning-infobox',
};

const getModelId = (modelEngine: string, associationId: string) => associationId?.split(`${modelEngine}_`)[1];

export const AssociationsConstants = {
    Routes,
    Components,
    ConfigParams,
    getModelId,
};
