/**
 * Cleans up a search params object.
 * Removes keys whose values are falsy
 * @param params The search params to set in the url
 */
export const cleanupSearchParams = (params: Record<string, string | null | undefined>): Record<string, string> =>
    Object.entries(params).reduce((memo, [param, paramValue]) => {
        if (paramValue) {
            memo[param] = paramValue;
        }
        return memo;
    }, {});
