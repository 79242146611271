import {Config} from '@core/configuration';
import {PrivilegesConstants} from '@core/user';

const organizationUrl = `${Config.CloudPlatform.url}/rest/organizations/{organizationName}`;

export const granularResourcesUrl = {
    [PrivilegesConstants.granularDomains.Groups]: `${organizationUrl}/groups`,
    [PrivilegesConstants.granularDomains.ApiKeys]: `${organizationUrl}/apikeys`,
    [PrivilegesConstants.granularDomains.Extensions]: `${organizationUrl}/extensions`,
    [PrivilegesConstants.granularDomains.Pipelines]: `${Config.SearchApi.url}/v1/admin/pipelines/`,
    [PrivilegesConstants.granularDomains.PushIdentities]: `${organizationUrl}/securityproviders`,
};

export const sourceDomain = 'SOURCE_PLATFORM_NORMAL';
export const searchDomain = 'QUERY_PIPELINE_SEARCH_API_NORMAL';
export const pushDocumentDomain = 'PUSH_DOCUMENT_PLATFORM_NORMAL';
export const pushIdentityDomain = 'PUSH_IDENTITY_PLATFORM_NORMAL';

export const isBinaryDomain = (domain: string) => domain === pushDocumentDomain || domain === pushIdentityDomain;
