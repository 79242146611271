import {PickyMultiSelect, PickySelectable} from '../PickyModels';
import {GroupItemsCollection} from './GroupItemsCollection';
import {GroupModel} from './GroupModel';

export class GroupCollection extends PickyMultiSelect<GroupModel> {
    models: GroupModel[];

    constructor(groups?: Array<GroupItemsCollection<PickySelectable>>, comparator?: any, collapse: boolean = true) {
        super();

        if (groups) {
            groups.forEach((group: GroupItemsCollection<PickySelectable>) => {
                const groupModel = new GroupModel({
                    groupKey: group.groupKey,
                    groupItems: group,
                    collapsed: collapse,
                });
                this.add(groupModel);
            });
        }

        this.comparator = comparator ? comparator : this.defaultComparator;
    }

    defaultComparator(group: GroupModel): any {
        return group.groupKey;
    }
}
