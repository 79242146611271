import {CommonState} from '@coveord/jsadmin-common';
import {ReducersMapObject} from 'redux';
import {ILogsFacets} from './facets/LogsFacetsConstants';
import {logsFacetsReducer} from './facets/LogsFacetsReducers';
import {ILogsState, logsReducer} from './logs/LogsReducers';
import {ISourcesState, sourcesReducer} from './sources/LogsSourcesReducers';

export interface ILogBrowserState extends CommonState {
    logs?: ILogsState;
    logsFacets?: ILogsFacets;
    logSources?: ISourcesState;
}

export const LogBrowserReducers: ReducersMapObject = {
    logs: logsReducer,
    logsFacets: logsFacetsReducer,
    logSources: sourcesReducer,
};
