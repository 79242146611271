import {
    Combobox,
    ComboboxProps,
    ComboboxStylesNames,
    factory,
    Factory,
    InputBase,
    Skeleton,
    Stack,
    StylesApiProps,
    useCombobox,
    useProps,
    useStyles,
} from '@components/mantine';
import {Platform, Region, useQuery} from '@core/api';
import {Config, sanitizeOrganizationId} from '@core/configuration';
import {useOrganization} from '@core/organization';
import {useNavigate} from '@core/routes';
import {Children, cloneElement, isValidElement, ReactElement, ReactNode, useState} from 'react';
import {Locales} from '../../strings/Locales';
import {OrganizationPickerProvider} from './OrganizationPickerContext';
import {OrganizationPickerFooter} from './OrganizationPickerFooter';
import {OrganizationPickerOption} from './OrganizationPickerOption';
import {OrganizationPickerOptions} from './OrganizationPickerOptions';
import {OrganizationPickerRegions} from './OrganizationPickerRegions';
import {OrganizationPickerSearch} from './OrganizationPickerSearch';
import {OrganizationPickerTarget} from './OrganizationPickerTarget';
import classes from './OrganizationPicker.module.css';

export type OrganizationPickerStylesNames = ComboboxStylesNames | 'loading' | 'scroll-area';

export interface OrganizationPickerProps
    extends Omit<ComboboxProps, 'styles' | 'classNames' | 'vars'>,
        StylesApiProps<OrganizationPickerFactory> {
    children?: ReactNode;
    comboboxProps?: ComboboxProps;
    /**
     * The organization id currently selected
     */
    value?: string;
    /**
     * Called when the user selects an organization
     */
    onChange?: (value: string, region: Region) => void;
    /**
     * Whether to enable the query to fetch the current organization
     */
    enableCurrentOrganizationQuery?: boolean;
}

export type OrganizationPickerFactory = Factory<{
    props: OrganizationPickerProps;
    ref: HTMLButtonElement;
    stylesNames: OrganizationPickerStylesNames;
    staticComponents: {
        Target: typeof OrganizationPickerTarget;
        Search: typeof OrganizationPickerSearch;
        Regions: typeof OrganizationPickerRegions;
        Option: typeof OrganizationPickerOption;
        Footer: typeof OrganizationPickerFooter;
    };
}>;

const defaultProps: Partial<OrganizationPickerProps> = {
    enableCurrentOrganizationQuery: true,
};

export const OrganizationPicker = factory<OrganizationPickerFactory>((_props, _ref) => {
    const {
        children,
        classNames,
        styles,
        vars,
        comboboxProps,
        value,
        onChange,
        enableCurrentOrganizationQuery,
        ...others
    } = useProps('OrganizationPicker', defaultProps, _props);
    const getStyles = useStyles<OrganizationPickerFactory>({
        name: 'OrganizationPicker',
        classes,
        vars,
        classNames,
        props: _props,
        styles,
    });
    const {id: orgId} = useOrganization();
    const [filter, setFilter] = useState('');
    const [region, setRegion] = useState(Config.CloudPlatform.region as Region);

    const currentOrganizationId = value ?? orgId;

    const navigate = useNavigate();

    const {data: currentOrganization, isLoading: currentOrganizationQueryIsLoading} = useQuery({
        queryKey: ['organization', currentOrganizationId],
        queryFn: () => Platform.organization.get(currentOrganizationId, {additionalFields: 'license'}),
        retry: 0,
        enabled: enableCurrentOrganizationQuery,
        refetchOnWindowFocus: false,
    });

    const combobox = useCombobox({
        onDropdownClose: () => {
            combobox.resetSelectedOption();
            if (search) {
                setFilter('');
            }
        },
        onDropdownOpen: () => {
            if (search) {
                combobox.focusSearchInput();
            }
            combobox.selectFirstOption();
        },
    });

    const childrenArray = Children.toArray(children) as ReactElement[];
    const search = childrenArray.find((child) => child.type === OrganizationPickerSearch);
    const regions = childrenArray.find((child) => child.type === OrganizationPickerRegions);
    const target = childrenArray.find(
        (child): child is ReactElement => isValidElement(child) && child.type === OrganizationPickerTarget,
    );
    const footer = childrenArray.find((child) => child.type === OrganizationPickerFooter);
    const option = childrenArray.find((child) => child.type === OrganizationPickerOption);

    const clonedTarget = target
        ? cloneElement(target, {
              combobox,
              currentOrg: {id: currentOrganizationId, ...currentOrganization},
          })
        : null;

    if (currentOrganizationQueryIsLoading) {
        return <Skeleton {...getStyles('loading')} />;
    }

    const onSubmit = (organizationId: string) => {
        combobox.closeDropdown();
        if (onChange) {
            onChange(organizationId, region);
        } else if (region !== Config.CloudPlatform.region) {
            let locationSearch = window.location.search;
            let origin = window.location.origin;

            if (['localhost', 'local.cloud.coveo.com'].includes(window.location.hostname)) {
                const queryString = new URLSearchParams(locationSearch);

                region === Region.US ? queryString.delete('region') : queryString.set('region', region);

                locationSearch = '?' + queryString.toString();
            } else {
                origin = Config.CloudPlatform.getUrlForRegion(region);
            }

            window.location.href = `${origin}${window.location.pathname}${locationSearch}#/${sanitizeOrganizationId(organizationId)}/`;
        } else {
            navigate(`/${organizationId}`, {injectOrg: false});
            window.location.reload();
        }
    };

    return (
        <OrganizationPickerProvider value={{filter, setFilter, combobox, getStyles, region, setRegion}}>
            <Combobox
                onOptionSubmit={onSubmit}
                store={combobox}
                withinPortal
                classNames={classNames}
                keepMounted={false}
                {...comboboxProps}
                {...others}
            >
                <Combobox.Target>
                    {clonedTarget ?? (
                        <InputBase
                            miw="19rem"
                            rightSection={<Combobox.Chevron />}
                            component="button"
                            type="button"
                            pointer
                            onClick={() => combobox.toggleDropdown()}
                            rightSectionPointerEvents="none"
                        >
                            {currentOrganization?.displayName ??
                                currentOrganizationId ??
                                Locales.format('OrganizationPicker.placeholder')}
                        </InputBase>
                    )}
                </Combobox.Target>
                <Combobox.Dropdown {...getStyles('dropdown')}>
                    {regions || search ? (
                        <Combobox.Header {...getStyles('header')}>
                            <Stack gap="xs">
                                {regions}
                                {search}
                            </Stack>
                        </Combobox.Header>
                    ) : null}
                    <OrganizationPickerOptions option={option} />
                    {footer}
                </Combobox.Dropdown>
            </Combobox>
        </OrganizationPickerProvider>
    );
});

OrganizationPicker.Regions = OrganizationPickerRegions;
OrganizationPicker.Target = OrganizationPickerTarget;
OrganizationPicker.Search = OrganizationPickerSearch;
OrganizationPicker.Option = OrganizationPickerOption;
OrganizationPicker.Footer = OrganizationPickerFooter;
