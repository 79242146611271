import deepExtend from 'lodash.merge';
import _ from 'underscore';

import {Assert} from '../helpers/Assert';

export interface ObjectEqualityOptions {
    considerEmptyAndUndefinedAsEqual: boolean;
    keyNames: string[];
}

export class DefaultObjectEqualityOptions implements ObjectEqualityOptions {
    considerEmptyAndUndefinedAsEqual = true;
    keyNames: string[] = [];
}

export class Objects {
    static mergeThenExtend(obj, mergedObject, extendedObject) {
        return _.extend(deepExtend(obj, mergedObject), extendedObject);
    }

    // Useful method when creating an object with variables or constants as keys, in a context you can't declare
    // an object then use it (e.g. in a constructor, when call to super constructor should be the first method call).
    static createObject(...pairs: any[][]) {
        const object = {};
        for (let i = 0; i < pairs.length; i++) {
            Assert.isNotUndefined(pairs[i][0]);
            Assert.isNotUndefined(pairs[i][1]);
            Assert.isString(pairs[i][0]);

            object[pairs[i][0]] = pairs[i][1];
        }
        return object;
    }

    /**
     * Compare if two objects are equal, by specifying an explicit list of keys to compare.
     * Warning: this method will not always check all provided keys,
     * only keys that are also present on either provided object are considered.
     * Warning: If no (or default) options are provided, this method uses an empty list of keys.
     * This effectively considers all inputs equal, since no properties are compared.
     *
     * @deprecated Please use an alternative object equality method.
     * @param o1 The first object to compare.
     * @param o2 The second object to compare.
     * @param options Optional comparison options, including a list of the keys to compare.
     * @returns Whether the objects are considered equal with the provided options.
     */
    static isEqual(
        o1: {[key: string]: any},
        o2: {[key: string]: any},
        options: ObjectEqualityOptions = new DefaultObjectEqualityOptions(),
    ) {
        let match = true;
        const allKeys = _.uniq(_.intersection(options.keyNames, _.union(_.keys(o1), _.keys(o2))));
        for (let i = 0; i < allKeys.length; i++) {
            const key = allKeys[i];
            const value1 = o1[key];
            const value2 = o2[key];

            match = _.isEqual(value1, value2);
            if (!match && options.considerEmptyAndUndefinedAsEqual) {
                match = _.isEmpty(value1) && _.isEmpty(value2);
            }

            if (!match) {
                break;
            }
        }

        return match;
    }

    /**
     * Check if an object contain same attributes (key and value) or less than another object.
     */
    static isSubobject<T>(base: T, subobject: Partial<T> | T): boolean {
        return (
            _.isObject(base) &&
            _.isObject(subobject) &&
            _.every(subobject, (value: any, key: string) =>
                _.isObject(value) ? base[key] && Objects.isSubobject(base[key], value) : value === base[key],
            )
        );
    }

    static sortKeys = <T extends Record<string, unknown>>(obj): T =>
        _.isObject(obj)
            ? Object.keys(obj)
                  .sort()
                  .reduce((acc: any, key: string) => ({...acc, [key]: obj[key]}), {})
            : {};

    static filterEntries = <T, ReturnType extends Partial<T> = Partial<T>>(
        obj: T,
        predicate: (value: [string, unknown], index: number, array: Array<[string, unknown]>) => boolean,
    ): Partial<T> => Object.fromEntries(Object.entries(obj).filter(predicate)) as ReturnType;
}
