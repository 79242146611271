import * as PlasmaStyle from '@coveord/plasma-style';
import _ from 'underscore';
import {SingleSelectEvents} from '../../base-models/PickyModels';
import {Option} from '../SingleSelect';

import flatSelectOption from './flat-select-option.ejs';

export interface FlatSelectOptionViewOptions {
    descriptionFormatter?: (value: string) => string;
    useButtonTag?: boolean;
    valueAsDescription?: string;
    valueFormatter?: (value: string) => string;
    model?: Option; // Is sent by the parent view
}

export class FlatSelectOptionView extends Marionette.ItemView<Option> {
    constructor(options: FlatSelectOptionViewOptions) {
        super(
            _.extend(
                {
                    events: {
                        click: 'onSelect',
                    },
                    tagName: options.useButtonTag ? 'button' : 'a',
                    attributes: () =>
                        _.extend(
                            {
                                'data-nightwatch-value': options.model.value,
                                'data-value': options.model.value,
                            },
                            options.useButtonTag ? {type: 'button'} : {},
                        ),
                    className: 'flat-select-option' + (options.useButtonTag ? ' btn' : ''),
                },
                options,
            ),
        );

        this.template = flatSelectOption;
        this.templateHelpers = {
            PlasmaStyle: PlasmaStyle,
            formatValue: () => this.options.valueFormatter(this.model.label),
            formatDescription: () => {
                if (options.valueAsDescription) {
                    return this.templateHelpers.formatValue();
                }
                return options.descriptionFormatter(this.model.value);
            },
            isSelected: () => this.model.selected,
            iconClass: this.model.iconClass,
            svgClass: this.model.svgClass,
            svgSpanClass: this.model.svgSpanClass,
        };
    }

    onRender() {
        this.toggleSelected();

        this.$el.attr({
            title: this.options.descriptionFormatter(this.model.value),
        });
    }

    toggleSelected() {
        this.$el.toggleClass('selectable', !this.model.selected);
    }

    private onSelect() {
        if (!this.$el.closest('.flat-select').hasClass('disabled')) {
            this.trigger(SingleSelectEvents.SelectOne);
        }
    }
}
