import {PlatformPaths} from '@configurations/platform';
import {PathGenerator, matchPath} from '@core/routes';

const projectSupportedPanels = [
    // Content section
    PlatformPaths.Sources,
    PlatformPaths.Extensions,
    PlatformPaths.SecurityIdentitiesListing,
    PlatformPaths.CrawlingModule,
    // Commerce section
    PlatformPaths.CatalogsList,
    // Service section
    PlatformPaths.CaseAssist,
    PlatformPaths.InsightPanel,
    // Search section
    PlatformPaths.QueryPipelines,
    PlatformPaths.SearchPageListingPage,
    PlatformPaths.Ipx,
    // Machine Learning section
    PlatformPaths.MachineLearningModels,
    // Analytics section
    PlatformPaths.Reports,
];

/**
 * Determines whether the current page or sub-page is supported by projects
 *
 * @param pathname Current location's pathname
 * @returns {boolean} Whether the current page is a supported resource panel by projects
 */
const isPanelProjectSupported = (pathname: string): boolean =>
    projectSupportedPanels.some((panelPath: PathGenerator) => !!matchPath(`${panelPath.fullPath}/*`, pathname));

/**
 * Determines whether the current page is the Projects panel or a sub-page of it
 *
 * @param pathname Current location's pathname
 * @returns {boolean} Whether the current page is the Projects panel or a related to it
 */
const isPanelProject = (pathname: string): boolean => !!matchPath(`${PlatformPaths.Projects.fullPath}/*`, pathname);

export const PanelHelpers = {
    isPanelProjectSupported,
    isPanelProject,
};
