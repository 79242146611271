import {SourceType} from '@core/api';

export const sourcesBasePath = '/content/sources/';

export const confirmRebuildModalId = 'confirmRebuildModalId';

export const SourcesConstants = {
    Components: {
        SourceMappingsItem: 'SourceMappingsItem',
        ConfigurationTab: 'configuration',
        ContentToIndexTab: 'content-to-index',
        ContentSecurityTab: 'security',
        AccessTab: 'access',
        SFOrganizationTab: 'organization',
        SourceNameInput: 'SourceNameInput',
        CrawlingModuleDropdown: 'CrawlingModuleDropdown',
        EnableOCRCheckbox: 'EnableOCRCheckbox',
        EnableOCRRadioSelect: 'EnableOCRRadioSelect',
        LogicalIndexDropdown: 'LogicalIndexDropdown',
        AssociationProjectMultiSelect: 'AssociationProjectMultiSelect',
        AccessTabConfiguration: 'AccessTabConfiguration',
        ObjectsToGetTab: 'ObjectsToGetTab',
    },
    Routes: {
        BasePath: sourcesBasePath,
        AddSource: `${sourcesBasePath}add`,
        EditMappings: `${sourcesBasePath}editMapping`,
        EditSourceBasePath: `${sourcesBasePath}edit`,
        EditSourceBasePathReact: `${sourcesBasePath}edit-react`,
    },
    Requests: {
        duplicate: 'DUPLICATE_SOURCE',
        fetch: 'FETCH_SOURCE',
        fetchAll: 'FETCH_ALL_SOURCES',
        save: 'SAVE_SOURCE',
        set: 'SET_SOURCE',
    },
};

export const EditSourceComponentEvents = {
    CancelEdition: 'sources:source:edit:cancel',
    ChangeTab: 'sources:source:edit:changeTab',
    ChangeTabAndValidate: 'sources:source:edit:changeTabAndValidate',
    SaveSource: 'sources:source:edit:save',
    SourceChanged: 'sources:source:changed',
    NavigateBackToSources: 'sources:source:navigateBackToSources',
    UpdateSourceAttributes: 'sources:source:updateAttributes',
};

export const SourceComponentsEvents = {
    EditJSONClosed: 'component:edit-json:close',
    EditJSONClosing: 'component:edit-json:closing',
    AddSourceClosing: 'component:add-source:closing',
    AddSourceClosed: 'sources:source:add:closed',
    RefreshesClosed: 'component:refreshes:close',
    RefreshesClosing: 'component:refreshes:closing',
    DuplicateClosed: 'component:duplicate:close',
    DuplicateClosing: 'component:duplicate:closing',
    ExtensionClosed: 'component:extension:close',
    ExtensionClosing: 'component:extension:closing',
    MappingClosed: 'component:mapping:close',
    MappingClosing: 'component:mapping:closing',
};

export const SourcesRoutes = {
    base: '',
    add: 'add',
    editShortcut: 'edit/:type/:id',
    editShortcutReact: 'edit-react/:type/:id',
    edit: 'edit/:type/:id/:activeTab(/?:queryString)',
    editReact: 'edit-react/:type/:id/:activeTab(/?:queryString)',
    editAccess: 'edit/:type/:id/access(/?:queryString)',
    editMappings: 'editMapping/:id/',
    addMappingItem: 'editMapping/:id/addItem',
    addMapping: 'editMapping/:id/addMapping',
    editMappingField: 'editMapping/:id/addMapping/editField/:fieldName',
    duplicate: `duplicate/:sourceType/:sourceId`,
    editJSON: 'editJSON/:id',
    editExtension: 'editExtension/:id',
    addExtensionItem: 'editExtension/:id/addItem',
    addExtension: 'editExtension/:id/addExtension',
    manageSourceExtension: 'editExtension/:id/addExtension/manageExtensions/:extensionId',
    editOAuth: 'editOAuth/?*queryString',
    editRefresh: 'editSchedules/:type/:id',
    activities: 'viewActivity/:id',
    notifications: 'subscribe-notifications/:id/:notificationId',
    metadata: ':id/metadata',
};

export const SourceSystem = {
    ADOBE_EXPERIENCE_MANAGER: 'ADOBE_EXPERIENCE_MANAGER',
    AMAZONS3: 'AMAZONS3',
    BOX: 'BOX',
    CATALOG: 'CATALOG',
    CONFLUENCE: 'CONFLUENCE',
    DATABASE: 'DATABASE',
    GOOGLE_DRIVE: 'GOOGLE_DRIVE',
    DROPBOX: 'DROPBOX',
    FILE: 'FILE',
    GENERIC_REST: 'GENERIC_REST',
    GRAPHQL: 'GRAPHQL',
    JIRA: 'JIRA',
    JIVE: 'JIVE',
    LITHIUM: 'LITHIUM',
    MICROSOFT_DYNAMICS: 'MICROSOFT_DYNAMICS',
    PUSH: 'PUSH',
    RSS: 'RSS',
    SALESFORCE: 'SALESFORCE',
    SAP: 'SAP',
    SERVICENOW: 'SERVICENOW',
    SHAREPOINT: 'SHAREPOINT',
    SITECORE: 'SITECORE',
    SITEMAP: 'SITEMAP',
    SLACK: 'SLACK',
    WEB: 'WEB',
    YOUTUBE: 'YOUTUBE',
    ZENDESK: 'ZENDESK',
};

export const SourceTypesCannotBeBuiltList = [SourceType.PUSH, SourceType.CATALOG];

export const SourcesErrors = {
    SourceAlreadyExists: 'SOURCE_ALREADY_EXISTS',
};

export const SourcesRequests = {
    save: 'SAVE_SOURCE',
    duplicate: 'DUPLICATE',
};

export const ActionsOnError = {
    REJECT_DOCUMENT: 'REJECT_DOCUMENT',
    SKIP_EXTENSION: 'SKIP_EXTENSION',
};

export const DefaultLogicalIndex = 'default';

export enum OCRFileType {
    Image = 'image',
    PDF = 'pdf',
    All = 'all',
}

export const OCRFileTypesMapping = {
    all: [OCRFileType.Image, OCRFileType.PDF],
    image: [OCRFileType.Image],
    pdf: [OCRFileType.PDF],
};

export enum WebDriverType {
    Chrome = 'Chrome',
}
