export const PushSourceConstants = {
    Components: {
        ID: 'edit-source-source-component',
        PushCreateApiKeyCheckbox: 'push-create-api-key-checkbox',
        PushApiUrl: 'push-copy-api-url-input',
        PushApiKeyModal: 'push-api-key-modal',
    },
};

export const PushSourceApiKeyActionType = {
    set: 'SET_PUSH_SOURCE_API_KEY',
};
