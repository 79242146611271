import {Locales} from '../Locales';

// Search page name validation
const isNameNotEmpty = (name: string) => !_.isEmpty(name?.trim());
const isNameCharactersValid = (name: string) => /^[A-Za-z0-9\-_]*$/.test(name);
const isNameUnique = (name: string, originalName: string, allPagesNames: string[], allWidgetsNames: string[]) =>
    !allPagesNames
        .concat(allWidgetsNames)
        .filter((existingName) => !originalName || existingName.toLowerCase() !== originalName.toLowerCase())
        .some((existingName) => existingName.toLowerCase() === name.toLowerCase());
const isNameLengthAppropriate = (name: string) => name.length <= 50;
const validateName = (name: string, originalName: string, allPagesName: string[], allWidgetsNames: string[]) => {
    const notEmpty = isNameNotEmpty(name);
    const charactersValid = isNameCharactersValid(name);
    const unique = isNameUnique(name, originalName, allPagesName, allWidgetsNames);
    const lengthAppropriate = isNameLengthAppropriate(name);

    if (!notEmpty) {
        return Locales.format('searchPageNameCantBeEmpty');
    }

    if (!charactersValid) {
        return Locales.format('searchPageNameMustBeValid');
    }

    if (!unique) {
        return Locales.format('searchPageNameAlreadyExist');
    }

    if (!lengthAppropriate) {
        return Locales.format('searchPageNameLengthError');
    }

    return null;
};

// Search page title validation
const isTitleNotEmpty = (title: string) => !_.isEmpty(title?.trim());
const isTitleLengthAppropriate = (title: string) => title.length <= 200;
const validateTitle = (title: string) => {
    const notEmpty = isTitleNotEmpty(title);
    const lengthAppropriate = isTitleLengthAppropriate(title);

    if (!notEmpty) {
        return Locales.format('searchPageTitleCantBeEmpty');
    }

    if (!lengthAppropriate) {
        return Locales.format('searchPageTitleLengthError');
    }

    return null;
};

export const SearchPagesValidator = {
    validateName,
    validateTitle,
};
