/**
 * The settings's subroute defined after `/:organizationId/settings`.
 */
export const settings: Record<string, string> = {
    '/:organizationId/settings/analytics/internal-events': 'viewed settings analytics internal events',
    '/:organizationId/settings/analytics/strict-validation': 'viewed settings analytics strict validation',
    '/:organizationId/settings/coveo-only/json-configuration': 'viewed settings coveo only json configuration',
    '/:organizationId/settings/license/consumption': 'viewed settings license consumption',
    '/:organizationId/settings/license/details': 'viewed settings license details',
    '/:organizationId/settings/license/limits': 'viewed settings license limits',
    '/:organizationId/settings/license/sources': 'viewed settings license sources',
    '/:organizationId/settings/organization/details': 'viewed settings organization details',
    '/:organizationId/settings/organization/sso': 'viewed settings organization single sign-on',
    '/:organizationId/settings/organization/traffic': 'viewed settings organization traffic',
    '/:organizationId/settings/organization/regions': 'viewed settings organization regions',
};
