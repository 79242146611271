import {matchRoutes} from '@core/routes';

import {pagesMapping} from './pages-mapping';
import {ActionType, track} from './track';

/**
 * Track pages according to predefined mapping.
 *
 * @param {string} url - The page's url to track.
 * @param {'commerce-hub' |'knowledge-hub' | 'platform'}  The application where we follow the url to track.
 *
 * @example ```ts
 * trackPageView('https://example.com');
 * ```
 * @example ```ts
 * trackPageView('https://example.com', 'commerce-hub');
 * ```
 */
export const trackPageView = (
    url: string,
    currentApp: 'commerce-hub' | 'knowledge-hub' | 'platform' = 'platform',
): void => {
    const match = matchRoutes(
        Object.keys(pagesMapping[currentApp]).map((path) => ({path})),
        url,
    );

    const route = match?.[0]?.route?.path ?? null;
    if (route) {
        const routeParams = match?.[0]?.params ?? {};
        const eventProperties: Record<string, any> = {
            normalizedUri: route,
            ...routeParams,
        };
        const [action, ...subject] = pagesMapping[currentApp][route].split(' ');

        track({action: action as ActionType, subject: subject.join(' ')}, eventProperties);
    }
};
