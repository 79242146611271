import {To, useNavigate as useReactRouterNavigate} from 'react-router-dom';

import {OrganizationContext} from '@core/organization';
import {useContext} from 'react';
import {CustomNavigateOptions} from '../types';
import {injectOrganizationId} from '../utilities/injectOrganizationId';

export const useNavigate: () => {
    (to: To, options?: CustomNavigateOptions): void;
    (delta: number): void;
} = () => {
    const originalNavigate = useReactRouterNavigate();
    const organizationCtx = useContext(OrganizationContext);

    return (to: To | number, options?: CustomNavigateOptions) => {
        if (typeof to === 'number') {
            originalNavigate(to);
            return;
        }

        const orgId = organizationCtx.query?.data?.id;

        const isInjectingOrg = (options?.injectOrg === undefined || options?.injectOrg) && orgId;
        delete options?.injectOrg;

        originalNavigate(isInjectingOrg ? injectOrganizationId(to, orgId) : to, options);
    };
};
