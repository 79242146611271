import moment from 'moment';
import {GroupCollection} from '../base-models/groupable/GroupCollection';
import {commonLocales} from '../CommonLocales';

export interface LastUpdateLabelBehaviorOptions {
    format?: string;
}

export class LastUpdateLabelBehavior extends Marionette.Behavior {
    private lastUpdateTime = moment().format(this.options.format);

    defaults(): LastUpdateLabelBehaviorOptions {
        return {
            format: 'LTS',
        };
    }

    constructor(options?: LastUpdateLabelBehaviorOptions, view?) {
        super(options, view);

        this.ui = {
            lastUpdateLabel: '.js-last-update',
        };

        // Check if collection is an instance of GroupCollection to support GroupableTableView.
        // We use this.view.options here since this.view.collection and this.view.originalCollection are not yet defined.
        if (this.view.options.collection instanceof GroupCollection) {
            this.listenTo(this.view.options.originalCollection, 'sync', this.updateLastUpdateTime);
        } else {
            this.listenTo(this.view.options.collection, 'sync', this.updateLastUpdateTime);
        }
    }

    onRender() {
        this.setLastUpdate();
    }

    private updateLastUpdateTime() {
        this.lastUpdateTime = moment().format(this.options.format);
        this.setLastUpdate();
    }

    private setLastUpdate() {
        $(this.ui.lastUpdateLabel).text(
            commonLocales.format('lastUpdateWithTime', {lastUpdateTime: this.lastUpdateTime}),
        );
    }
}
