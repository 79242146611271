import {PipelineModel, Platform} from '@core/api';
import {LicenseSelectors} from '@core/license';
import {CurrentOrganization} from '@core/organization';
import {PlatformPaths} from '@configurations/platform';
import {ProductTypes, RequestsActions, URIUtils} from '@coveord/jsadmin-common';
import {IReduxAction, IReduxActionsPayload, IThunkAction} from '@coveord/plasma-react';
import moment from 'moment';

import {ABTestPipelinesComparison} from '../../statements/ab-test/ABTestTypes';
import {SearchOptimizationState} from '../../SearchOptimizationState';
import {ABTestConstants} from '../../statements/ab-test/ABTestConstants';
import {ABTestUtils} from '../../statements/ab-test/ABTestUtils';
import {QueryPipelineActions, QueryPipelineActionsType} from '../actions/QueryPipelineActions';

export const ActiveQueryPipelineActionsTypes = {
    set: 'SET_ACTIVE_PIPELINE',
    remove: 'REMOVE_ACTIVE_PIPELINE',
    fetchABTestAnalytics: 'FETCH_PIPELINE_ANALYTICS',
    initABTest: 'INITIALIZE_AB_TEST',
    clearInitABTest: 'CLEAR_INITIALIZE_AB_TEST',
};

export interface IActiveQueryPipelinePayload extends IReduxActionsPayload {
    activeQueryPipeline?: PipelineModel;
}

const initializeABTest = (): IReduxAction => ({
    type: ActiveQueryPipelineActionsTypes.initABTest,
});

const clearInitABTest = (): IReduxAction => ({
    type: ActiveQueryPipelineActionsTypes.clearInitABTest,
});

const set = (activeQueryPipeline: PipelineModel): IReduxAction<IActiveQueryPipelinePayload> => ({
    type: ActiveQueryPipelineActionsTypes.set,
    payload: {
        activeQueryPipeline,
    },
});

const remove = (): IReduxAction => ({
    type: ActiveQueryPipelineActionsTypes.remove,
});

const fetchAndSet =
    (queryPipelineId: string): IThunkAction<Promise<void | PipelineModel>, SearchOptimizationState> =>
    async (dispatch) => {
        dispatch(RequestsActions.request(ActiveQueryPipelineActionsTypes.set));
        try {
            const queryPipeline = await dispatch(QueryPipelineActions.fetch(queryPipelineId));
            dispatch(set(queryPipeline as PipelineModel));
            dispatch(RequestsActions.success(ActiveQueryPipelineActionsTypes.set));
            return queryPipeline;
        } catch {
            dispatch(remove());
            URIUtils.navigate(PlatformPaths.QueryPipelines(), {trigger: true});
            dispatch(RequestsActions.failure(ActiveQueryPipelineActionsTypes.set));
        }
    };

const updateAndSet =
    (queryPipeline: PipelineModel): IThunkAction<Promise<PipelineModel>, SearchOptimizationState> =>
    (dispatch) => {
        const handleUpdate = async () => {
            const updatedQueryPipeline = await dispatch(QueryPipelineActions.update(queryPipeline));
            dispatch(set(queryPipeline));
            return updatedQueryPipeline;
        };
        return dispatch(RequestsActions.handle(QueryPipelineActionsType.update, handleUpdate));
    };

const fetchABTestAnalytics =
    (
        activeQueryPipeline: PipelineModel,
        activeTestPipeline: PipelineModel,
    ): IThunkAction<Promise<ABTestPipelinesComparison>, SearchOptimizationState> =>
    (dispatch, getState) => {
        const state = getState();
        const licenseType = LicenseSelectors.getLicenseType(state);
        const serverCall = async () => {
            const response = await Platform.ua.statistics.listCombinedData({
                m: [
                    ABTestConstants.ABTestMetrics.averageClickRank,
                    ABTestConstants.ABTestMetrics.performSearch,
                    ABTestConstants.ABTestMetrics.clickThroughRatio,
                    ABTestConstants.ABTestMetrics.averageNumberOfResults,
                ],
                from: moment()
                    .subtract(licenseType === ProductTypes.TRIAL ? 1 : 2, 'months')
                    .toISOString(),
                to: moment().toISOString(),
                d: [ABTestConstants.ABTestDimensions.splitTestRunVersion, ABTestConstants.ABTestDimensions.hasResult],
                f: [
                    `(${ABTestConstants.ABTestDimensions.splitTestRunVersion} IN ["${activeQueryPipeline.name}","${activeTestPipeline.name}"])`,
                ],
                n: 1000,
                org: CurrentOrganization.getId(),
            });
            const analytics = ABTestUtils.parseAnalytics(response);
            return ABTestUtils.comparePipelines(analytics, activeQueryPipeline, activeTestPipeline);
        };
        return dispatch(RequestsActions.handle(ActiveQueryPipelineActionsTypes.fetchABTestAnalytics, serverCall));
    };

export const ActiveQueryPipelineActions = {
    set,
    fetchAndSet,
    updateAndSet,
    remove,
    fetchABTestAnalytics,
    initializeABTest,
    clearInitABTest,
};
