import {IReduxAction} from '@coveord/plasma-react';
import _ from 'underscore';
import {IProductListingsForCatalogState} from './ProductListingState';
import {IProductListingsService} from '../Catalog/services/ProductListingsService';
import {IUpdateProductListingsForCatalogPayload, ProductListingsActionsTypes} from './ProductListingsActions';

const initialState: IProductListingsForCatalogState = {
    data: [],
};

const updateProductListingsForCatalog = (
    state: IProductListingsService,
    action: IReduxAction<IUpdateProductListingsForCatalogPayload>,
): IProductListingsForCatalogState => ({
    ...state,
    data: action.payload!.productListingsForCatalog,
});

const ProductListingsReducers: Record<string, (...args: any[]) => IProductListingsForCatalogState> = {
    [ProductListingsActionsTypes.updateProductListingsForCatalog]: updateProductListingsForCatalog,
};

export const productListingsReducer = (
    state: IProductListingsForCatalogState = initialState,
    action: IReduxAction = {type: '', payload: {}},
) =>
    !_.isUndefined(ProductListingsReducers[action.type]) ? ProductListingsReducers[action.type](state, action) : state;
