import _ from 'underscore';
import {SingleSelectEvents} from '../base-models/PickyModels';
import {Option, Options} from './SingleSelect';

import radioOption from './radio-option.ejs';

export class RadioSelectOptionView extends Marionette.ItemView<Option> {
    constructor(options?) {
        super(
            _.extend(
                {
                    events: {
                        click: 'onSelect',
                    },
                    className: 'radio-option',
                },
                options,
            ),
        );

        this.template = radioOption;
        this.templateHelpers = {
            formatValue: () =>
                this.options.valueFormatter ? this.options.valueFormatter(this.model.label) : this.model.label,
            value: () => this.model.value || '',
            selected: () => !!this.model.selected,
            name: () => this.options.radioSelectName,
        };
    }

    onSelect(e: JQuery.TriggeredEvent) {
        if (!this.$el.closest('.radio-select').hasClass('disabled')) {
            this.trigger(SingleSelectEvents.SelectOne);
        }
    }

    onRender() {
        if (!this.model.selected) {
            this.$el.addClass('selectable');
        }

        this.$el.attr({
            title: this.options.descriptionFormatter(this.model.value),
        });
    }

    onAttach() {
        if (this.model.selected) {
            this.$el.find('input').focus();
        }
    }
}

export class RadioSelectView extends Marionette.CollectionView<Backbone.Model, Options> {
    constructor(options?) {
        super(
            _.extend(
                {
                    childView: RadioSelectOptionView,
                    childViewOptions: {
                        radioSelectName: options.radioSelectName || 'enabledOptions',
                        valueFormatter: options.valueFormatter,
                        descriptionFormatter: options.descriptionFormatter,
                    },
                    className: 'form-control radio-select',
                    collectionEvents: {
                        'select:one': 'render',
                    },
                },
                options,
            ),
        );
    }
}
