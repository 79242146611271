export class BrowserUtils {
    static get isEdge(): boolean {
        const edgeRegex = /edge\/\d+\.?\w*/i;
        return edgeRegex.test(BrowserUtils.getUserAgent());
    }

    static getUserAgent(): string {
        return navigator.userAgent;
    }
}
