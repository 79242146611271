import {IndexType} from '@core/api';

export const ResultRankingConstants = {
    Components: {
        ResultRankingNameModal: {
            id: 'result-ranking-name-modal-id',
            nameInputId: 'result-ranking-name-input-id',
            userNoteInputId: 'result-ranking-user-note-input-id',
        },
    },
    Form: {
        Checkboxes: {
            RulePrefix: 'result-ranking-checkbox-',
        },
        Slider: 'result-ranking-modifier-slider',
    },
    ActionTypes: {
        single: 'RESULT_RANKING',
    },
    Requests: {
        isSearchEndPointAvailable: 'RESULT_RANKING_SEARCH_AVAILABLE',
    },
};

export const rankingModifierSliderValues = {
    [IndexType.COVEO]: {
        min: -1000,
        max: 1000,
        step: 25,
        Threshold: {
            min: -500,
            max: 500,
        },
    },
    [IndexType.ELASTIC]: {
        min: -10000,
        max: 10000,
        step: 250,
        Threshold: {
            min: -5000,
            max: 5000,
        },
    },
};
