import {IReduxAction} from '@coveord/plasma-react';
import _ from 'underscore';

import {IAssociationsState} from '../../../../SearchOptimizationState';
import {AssociationsActionTypes, ISetAssociationsPayload} from '../actions/AssociationsActions';

const initialAssociationsState: IAssociationsState = {
    rules: null,
    totalEntries: 0,
};

const set = (state: IAssociationsState, action: IReduxAction<ISetAssociationsPayload>) => ({
    ...state,
    rules: action.payload.rules,
    totalEntries: action.payload.totalEntries,
});

const AssociationsReducers: Record<string, (...args: any[]) => IAssociationsState> = {
    [AssociationsActionTypes.set]: set,
    [AssociationsActionTypes.clearAll]: (state: IAssociationsState) => initialAssociationsState,
};

export type IAssociationsPayload = Record<string, unknown> | ISetAssociationsPayload;

export const associationsReducer = (
    state: IAssociationsState = initialAssociationsState,
    action?: IReduxAction<IAssociationsPayload>,
) => (!_.isUndefined(AssociationsReducers[action.type]) ? AssociationsReducers[action.type](state, action) : state);
