import * as ReactVapor from '@coveord/plasma-react';
import _ from 'underscore';
import {CardActionPayload} from '../../actions/CardActions';

export const CalculatedMetricActionsType = {
    Initialize: 'CALCULATED_METRIC_INITIALIZE',
    StartLoading: 'CALCULATED_METRIC_START_LOADING',
    SetConst: 'CALCULATED_METRIC_SET_CONST',
    Reload: 'CALCULATED_METRIC_RELOAD',
};

export interface CalculatedMetricInitializePayload {
    metricA: string | number;
    metricB: string | number;
    operator: string;
    format: string;
}

const initialize = (
    id: string,
    attrs: CalculatedMetricInitializePayload,
): ReactVapor.IReduxAction<CalculatedMetricInitializePayload> => ({
    type: CalculatedMetricActionsType.Initialize,
    payload: _.extend({}, {id}, attrs),
});

const startLoading = (id: string): ReactVapor.IReduxAction<CardActionPayload> => ({
    type: CalculatedMetricActionsType.StartLoading,
    payload: {id},
});

export interface CalculatedMetricSetPayload extends CardActionPayload {
    valueA?: number;
    valueB?: number;
}

const setConst = (id: string, valueA: number, valueB: number): ReactVapor.IReduxAction<CalculatedMetricSetPayload> => ({
    type: CalculatedMetricActionsType.SetConst,
    payload: {id, valueA, valueB},
});

const reload = (id: string): ReactVapor.IReduxAction<CardActionPayload> => ({
    type: CalculatedMetricActionsType.Reload,
    payload: {id},
});

export const CalculatedMetricActions = {
    initialize,
    startLoading,
    setConst,
    reload,
};
