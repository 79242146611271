import {RequestsSelectors} from '@coveord/jsadmin-common';
import {SelectSelector} from '@coveord/plasma-react';
import {createSelector} from 'reselect';
import {CommerceState} from '../../CommerceState';
import {CatalogConfigurationConstants} from '../CatalogConfiguration/CatalogConfigurationConstants';
import {SourcesActionsTypes} from '../SourceSelect/SourcesActions';

const getCatalogEnabledSources = (state: CommerceState) => state.catalogManager?.sources?.data || [];
const getCatalogEnabledSourceIdToNameMap = (state: CommerceState) =>
    state.catalogManager?.sources?.map || new Map<string, string>();
const getCatalogEnabledSourceIds = createSelector(
    (state: CommerceState) => state.catalogManager?.sources?.map,
    (sourcesMap) => Object.keys(sourcesMap) || [],
);
const isLoading = RequestsSelectors.createLoadingSelector([SourcesActionsTypes.fetch]);

const getSelectedSources = (state: CommerceState) =>
    SelectSelector.getMultiSelectSelectedValues(state, {
        id: CatalogConfigurationConstants.ComponentIds.ScopeSourceSelect,
    });

export const SourcesSelectors = {
    getCatalogEnabledSources,
    getCatalogEnabledSourceIdToNameMap,
    getCatalogEnabledSourceIds,
    isLoading,
    getSelectedSources,
};
