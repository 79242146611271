import {notifications} from '@components/mantine';
import {OrganizationSelectors} from '@core/organization';
import {RequestsActions} from '@coveord/jsadmin-common';
import {
    IReduxAction,
    ITableHOCCompositeState,
    IThunkAction,
    TableHOCUtils,
    TableWithPaginationActions,
    turnOffLoading,
} from '@coveord/plasma-react';

import {ProductListingOnboardingSelectors} from '../Catalog/CatalogOnboarding/ProductListingOnboardingSelectors';
import {getProductListingsServiceSingleton, IProductListingsService} from '../Catalog/services/ProductListingsService';
import {CommerceState} from '../CommerceState';
import {locales} from '../Locales';
import {ProductListingComponentIds} from './ProductListingComponentIds';
import {ProductListingModel} from './ProductListingState';

export const ProductListingsActionsTypes = {
    create: 'CREATE_PRODUCT_LISTING',
    fetchProductListingsForCatalog: 'FETCH_PRODUCT_LISTINGS_FOR_CATALOG',
    delete: 'DELETE_PRODUCT_LISTING',
    updateProductListingsForCatalog: 'UPDATE_PRODUCT_LISTINGS_FOR_CATALOG',
};

export interface IUpdateProductListingsForCatalogPayload {
    productListingsForCatalog: ProductListingModel[];
}

const updateProductListingsForCatalog = (
    productListingsForCatalog: ProductListingModel[],
): IReduxAction<IUpdateProductListingsForCatalogPayload> => ({
    type: ProductListingsActionsTypes.updateProductListingsForCatalog,
    payload: {
        productListingsForCatalog,
    },
});

const fetchProductListingsForCatalog =
    (
        catalogId: string,
        productListingService: IProductListingsService = getProductListingsServiceSingleton(),
    ): IThunkAction<void, CommerceState> =>
    async (dispatch, getState) => {
        const state = getState();
        const tableId = ProductListingComponentIds.ProductListingsForCatalogTable;
        const compositeState: ITableHOCCompositeState = TableHOCUtils.getCompositeState(tableId, state);
        dispatch(RequestsActions.request(ProductListingsActionsTypes.fetchProductListingsForCatalog));
        try {
            const productListings = await productListingService.getProductListingsForCatalog(
                OrganizationSelectors.getOrganizationId(state),
                catalogId,
                compositeState.pageNb,
                compositeState.perPage,
            );
            dispatch(updateProductListingsForCatalog(productListings.items));
            dispatch(TableWithPaginationActions.setCount(tableId, productListings.totalEntries));
            dispatch(turnOffLoading([tableId]));
            dispatch(RequestsActions.success(ProductListingsActionsTypes.fetchProductListingsForCatalog));
        } catch (exception) {
            dispatch(RequestsActions.failure(ProductListingsActionsTypes.fetchProductListingsForCatalog, exception));
            notifications.showError(locales.format('fetchProductListingsForCatalogFailed'));
        }
    };

const createProductListing =
    (
        productListingService: IProductListingsService = getProductListingsServiceSingleton(),
    ): IThunkAction<Promise<boolean>, CommerceState> =>
    async (dispatch, getState) => {
        const state = getState();
        dispatch(RequestsActions.request(ProductListingsActionsTypes.create));

        const productListing = ProductListingOnboardingSelectors.getProductListingToCreate(state);

        try {
            await productListingService.create(
                OrganizationSelectors.getOrganizationId(state),
                productListing.catalogId,
                productListing,
            );
            dispatch(RequestsActions.success(ProductListingsActionsTypes.create));
            return true;
        } catch (exception) {
            dispatch(RequestsActions.failure(ProductListingsActionsTypes.create, exception));
            notifications.showError(locales.format('createProductListingFailed'));
        }
        return false;
    };

const deleteProductListing =
    (
        catalogId: string,
        productListingId: string,
        productListingService: IProductListingsService = getProductListingsServiceSingleton(),
    ): IThunkAction<Promise<void>, CommerceState> =>
    async (dispatch, getState) => {
        const state = getState();
        dispatch(RequestsActions.request(ProductListingsActionsTypes.delete));
        try {
            await productListingService.delete(
                OrganizationSelectors.getOrganizationId(state),
                catalogId,
                productListingId,
            );
            dispatch(RequestsActions.success(ProductListingsActionsTypes.delete));
            dispatch(reloadProductListingsForCatalogTable(catalogId, productListingService));
        } catch (exception) {
            dispatch(RequestsActions.failure(ProductListingsActionsTypes.delete, exception));
            notifications.showError(locales.format('deleteCatalogFailed'));
        }
    };

const reloadProductListingsForCatalogTable =
    (
        catalogId: string,
        productListingService: IProductListingsService = getProductListingsServiceSingleton(),
    ): IThunkAction<void, CommerceState> =>
    async (dispatch, getState) => {
        dispatch(ProductListingsActions.fetchProductListingsForCatalog(catalogId, productListingService));
    };

export const ProductListingsActions = {
    fetchProductListingsForCatalog,
    deleteProductListing,
    createProductListing,
};
