import {ContextFields, DocumentSuggestions, CaseClassifications} from '@core/api';

export interface ICaseAssistPreviewState {
    fields: ContextFields;
    documentSuggestions: DocumentSuggestions;
    caseClassifications: CaseClassifications;
}

export const CaseAssistPreviewInitialState: ICaseAssistPreviewState = {
    fields: {
        subject: {
            value: '',
        },
        description: {
            value: '',
        },
    },
    documentSuggestions: {
        documents: [],
        totalCount: 0,
    },
    caseClassifications: {
        fields: {},
    },
};
