import {activity} from './activity';
import {answerManager} from './answerManager';
import {chunkInspector} from './chunkInspector';
import {commerce} from './commerce';
import {content} from './content';
import {coveoOnly} from './coveoOnly';
import {home} from './home';
import {logs} from './logs';
import {machineLearning} from './machineLearning';
import {merchandisingHub} from './merchandisingHub';
import {organization} from './organization';
import {search} from './search';
import {service} from './service';
import {settings} from './settings';
import {updates} from './updates';
import {usage} from './usage';
import {user} from './user';

export const pagesMapping = {
    'commerce-hub': {
        ...merchandisingHub,
    },
    'knowledge-hub': {
        ...answerManager,
        ...chunkInspector,
    },
    platform: {
        ...activity,
        ...commerce,
        ...content,
        ...coveoOnly,
        ...home,
        ...logs,
        ...machineLearning,
        ...organization,
        ...search,
        ...service,
        ...settings,
        ...updates,
        ...usage,
        ...user,
    },
};
