import {CurrentOrganization} from '@core/organization';
import {IDispatch} from '@coveord/plasma-react';
import {IQuery} from 'coveo-search-ui';

import {UserSelectors} from '@core/user';
import {ObjectKeyValueActions} from '@core/store';
import {RequestsActions} from '../../redux/requests/RequestsActions';
import {SearchUIConstants} from '../SearchUIConstants';
import {getSearchServiceSingleton} from '../SearchUIService';

export const generateFetchQueryKey = (queryKey: string): string => `${SearchUIConstants.actionTypes.fetch}_${queryKey}`;

export const setShouldExecuteQuery = (id: string) => (dispatch: IDispatch) => {
    dispatch(
        ObjectKeyValueActions.update(SearchUIConstants.actionTypes.setShouldExecuteQuery, {
            [id]: true,
        }),
    );
};

export const resetShouldExecuteQuery = (id: string) => (dispatch: IDispatch) => {
    dispatch(
        ObjectKeyValueActions.update(SearchUIConstants.actionTypes.setShouldExecuteQuery, {
            [id]: false,
        }),
    );
};

export const setShouldClearQuery = (id: string) => (dispatch: IDispatch) => {
    dispatch(
        ObjectKeyValueActions.update(SearchUIConstants.actionTypes.setShouldClearQuery, {
            [id]: true,
        }),
    );
};

export const resetShouldClearQuery = (id: string) => (dispatch: IDispatch) => {
    dispatch(
        ObjectKeyValueActions.update(SearchUIConstants.actionTypes.setShouldClearQuery, {
            [id]: false,
        }),
    );
};

export const clearQuery = (queryKey: string) => async (dispatch: IDispatch) => {
    dispatch(ObjectKeyValueActions.remove(SearchUIConstants.actionTypes.setQuery, queryKey));
    dispatch(RequestsActions.clean(queryKey));
};

export const executeQuery =
    (queryKey: string, query: IQuery, searchService = getSearchServiceSingleton()) =>
    async (dispatch: IDispatch, getState) => {
        const state = getState();
        const organizationId = CurrentOrganization.getId();
        const viewAllContent = UserSelectors.canViewAllContent(state);

        const requestKey = generateFetchQueryKey(queryKey);
        dispatch(RequestsActions.request(requestKey));
        try {
            const result = await searchService.getResults(organizationId, viewAllContent, query);
            dispatch(
                ObjectKeyValueActions.update(SearchUIConstants.actionTypes.setQuery, {
                    [queryKey]: result,
                }),
            );
            dispatch(RequestsActions.success(requestKey));
        } catch (ex) {
            dispatch(RequestsActions.failure(requestKey));
        }
    };

export const SearchUIActions = {
    setShouldExecuteQuery,
    resetShouldExecuteQuery,
    setShouldClearQuery,
    resetShouldClearQuery,
    clearQuery,
    executeQuery,
};
