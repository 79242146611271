import {notifications} from '@components/mantine';
import {
    DefinitionModel,
    LicenseModel,
    LicenseSourceTypeModel,
    ModifierModel,
    ModifierStatementDetailModel,
    ModifierStatementModel,
    OverrideModel,
    Platform,
    ProductEdition,
} from '@core/api';
import {RequestsActions, deepClone} from '@coveord/jsadmin-common';
import {IThunkAction, selectListBoxOption} from '@coveord/plasma-react';

import {setKeyValue} from '@core/store';
import {Locales} from '../Locales';
import {AdminState} from '../application/Reducers';
import {LicenseExtensions, LicenseFeatures, LicenseIntegrations} from './SettingsConstants';
import {SettingsSelectors} from './SettingsSelectors';
import {SettingsUtils} from './SettingsUtils';

export const SettingActionTypes = {
    fetchPossibleSourceTypes: 'FETCH_POSSIBLE_SOURCE_TYPES',
    fetchPossibleModifierTemplates: 'FETCH_POSSIBLE_MODIFIER_TEMPLATES',
    fetchPossibleStatements: 'FETCH_POSSIBLE_STATEMENTS',
    fetchOrganizationDefinition: 'FETCH_ORGANIZATION_DEFINITION',
    addOrganizationDefinitionOverrideStatement: 'ADD_ORGANIZATION_DEFINITION_OVERRIDE_STATEMENT',
    updateOrganizationDefinition: 'UPDATE_ORGANIZATION_DEFINITION',
    updateOrganizationExpirationDate: 'UPDATE_ORGANIZATION_EXPIRATION_DATE',
    updateOverrideId: 'UPDATE_OVERRIDE_ID',
    saveOrganizationDefinition: 'SAVE_ORGANIZATION_DEFINITION',
};

const fetchPossibleSourceTypes = (): IThunkAction<Promise<LicenseSourceTypeModel[]>, AdminState> => (dispatch) =>
    dispatch(
        RequestsActions.handle(SettingActionTypes.fetchPossibleSourceTypes, () =>
            Platform.license.getPossibleSourceTypes(),
        ),
    );

const fetchPossibleModifierTemplates = (): IThunkAction<Promise<ModifierModel[]>, AdminState> => (dispatch) =>
    dispatch(
        RequestsActions.handle(SettingActionTypes.fetchPossibleModifierTemplates, () =>
            Platform.organizationSettings.getAvailableModifierTemplates(),
        ),
    );

const fetchPossibleStatements = (): IThunkAction<Promise<ModifierStatementDetailModel[]>, AdminState> => (dispatch) =>
    dispatch(
        RequestsActions.handle(SettingActionTypes.fetchPossibleStatements, () =>
            Platform.organizationSettings.getAvailableStatementDetails(),
        ),
    );

const fetchOrganizationDefinition = (): IThunkAction<Promise<DefinitionModel>, AdminState> => (dispatch) => {
    const serverCall = async () => {
        try {
            const definition: DefinitionModel = await Platform.organization.getDefinition();

            dispatch(setKeyValue(SettingActionTypes.updateOrganizationDefinition, definition));
            return definition;
        } catch (e) {
            dispatch(setKeyValue(SettingActionTypes.updateOrganizationDefinition, null));
        }
    };

    return dispatch(RequestsActions.handle(SettingActionTypes.fetchOrganizationDefinition, serverCall));
};

const addOrganizationDefinitionOverrideStatement =
    (modifierStatementModel: ModifierStatementModel): IThunkAction<void, AdminState> =>
    (dispatch, getState) => {
        const state = getState();
        const overrideId = SettingsSelectors.selectOverrideId(state);
        const updatedDefinition = deepClone(SettingsSelectors.selectOrganizationDefinition(state));

        updatedDefinition.overrides
            .find((overrideModel: OverrideModel) => overrideModel.id === overrideId)
            .statements.push(modifierStatementModel);

        dispatch(setKeyValue(SettingActionTypes.updateOrganizationDefinition, updatedDefinition));
        dispatch(selectListBoxOption(overrideId, true, JSON.stringify(modifierStatementModel)));
    };

const updateOverrideId =
    (overrideId: string): IThunkAction<void, AdminState> =>
    (dispatch) =>
        dispatch(setKeyValue(SettingActionTypes.updateOverrideId, overrideId));

const saveOrganizationDefinition =
    (
        organizationDefinition: DefinitionModel,
        edition: ProductEdition,
        possibleModifierTemplates: ModifierModel[],
    ): IThunkAction<Promise<DefinitionModel>, AdminState> =>
    (dispatch, getState) => {
        const state = getState();

        organizationDefinition.overrides.forEach((overrideModel: OverrideModel) => {
            overrideModel.statements = SettingsSelectors.selectOverrideStatements(state, overrideModel.id).map(
                (statement: string) => JSON.parse(statement),
            );
        });

        const licenseModifier: ModifierModel = SettingsUtils.findProductTypeModifier(organizationDefinition);

        if (licenseModifier !== undefined) {
            licenseModifier.id = SettingsUtils.determineProductTypeModifier(licenseModifier, state);

            SettingsUtils.setModifiersForAddOns(
                organizationDefinition,
                edition,
                possibleModifierTemplates,
                Object.values(LicenseIntegrations),
                state,
            );

            SettingsUtils.setModifiersForAddOns(
                organizationDefinition,
                edition,
                possibleModifierTemplates,
                Object.values(LicenseFeatures),
                state,
            );

            SettingsUtils.setModifiersForAddOns(
                organizationDefinition,
                edition,
                possibleModifierTemplates,
                Object.values(LicenseExtensions),
                state,
            );
        }

        const serverCall = () =>
            // eslint-disable-next-line @helpers/no-then-catch-finally
            Platform.organization.updateDefinition(organizationDefinition).then((updatedModel: DefinitionModel) => {
                notifications.showSuccess(Locales.format('orgDefinitionSaveSuccessful'));
                return updatedModel;
            });

        return dispatch(
            RequestsActions.handle(SettingActionTypes.updateOrganizationExpirationDate, () => serverCall()),
        );
    };

const saveExpirationDate = (): IThunkAction<Promise<LicenseModel>, AdminState> => (dispatch, getState) => {
    const expirationDate: number = SettingsSelectors.selectExpirationDate(getState()).inputUpperLimit.getTime();

    const serverCall = () => Platform.license.updateExpirationDate({expirationDate: expirationDate});

    return dispatch(RequestsActions.handle(SettingActionTypes.updateOrganizationDefinition, () => serverCall()));
};

export const SettingsActions = {
    fetchPossibleSourceTypes,
    fetchPossibleModifierTemplates,
    fetchPossibleStatements,
    fetchOrganizationDefinition,
    addOrganizationDefinitionOverrideStatement,
    updateOverrideId,
    saveOrganizationDefinition,
    saveExpirationDate,
};
