import {BasePayload, IListBoxPayload, IReduxAction, ListBoxActions} from '@coveord/plasma-react';
import _ from 'underscore';
import {FieldFilterSetActionsType} from '../actions/FieldFilterSetActions';

export interface FieldFilterSetState {
    id: string;
}

export interface FieldFilterSetStates {
    [id: string]: FieldFilterSetState;
}

const add = (state: FieldFilterSetStates, action: IReduxAction<BasePayload>) => ({
    ...state,
    [action.payload.id]: {
        id: action.payload.id,
    },
});

const remove = (state: FieldFilterSetStates, action: IReduxAction<BasePayload>) => _.omit(state, action.payload.id);

const onSelect = (state: FieldFilterSetState, action: IReduxAction<IListBoxPayload>) => ({
    ...state,
    [action.payload.id]: {...state[action.payload.id]},
});

const FieldFilterSetReducers: {[key: string]: (...args: any[]) => FieldFilterSetStates} = {
    [FieldFilterSetActionsType.add]: add,
    [FieldFilterSetActionsType.remove]: remove,
};

const FieldFilterSetReducer: {[key: string]: (...args: any[]) => FieldFilterSetState} = {
    [ListBoxActions.select]: onSelect,
};

export type FieldFilterSetPayload = BasePayload | IListBoxPayload;

export const fieldFilterSetReducer = (
    state: FieldFilterSetStates = {},
    action: IReduxAction<FieldFilterSetPayload>,
): FieldFilterSetStates => {
    if (!_.isUndefined(FieldFilterSetReducers[action.type])) {
        return FieldFilterSetReducers[action.type](state, action);
    }

    if (action?.payload?.id && state[action.payload.id] && !_.isUndefined(FieldFilterSetReducer[action.type])) {
        return {
            ...state,
            [action.payload.id]: FieldFilterSetReducer[action.type](state[action.payload.id], action),
        };
    }

    return state;
};
