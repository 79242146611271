import {IReduxAction} from '@coveord/plasma-react';

import {IActiveQueryPipelineState} from '../../SearchOptimizationState';
import {ActiveQueryPipelineActionsTypes, IActiveQueryPipelinePayload} from './ActiveQueryPipelineActions';

const initialActiveQueryPipeline: IActiveQueryPipelineState = {
    queryPipeline: null,
    initializedABTest: false,
};

export const ActiveQueryPipelineReducer = (
    state: IActiveQueryPipelineState = initialActiveQueryPipeline,
    action: IReduxAction<IActiveQueryPipelinePayload>,
): IActiveQueryPipelineState => {
    switch (action.type) {
        case ActiveQueryPipelineActionsTypes.set:
            return {
                ...state,
                queryPipeline: action.payload.activeQueryPipeline,
            };
        case ActiveQueryPipelineActionsTypes.remove:
            return {
                ...state,
                queryPipeline: null,
            };
        case ActiveQueryPipelineActionsTypes.initABTest:
            return {
                ...state,
                initializedABTest: true,
            };
        case ActiveQueryPipelineActionsTypes.clearInitABTest:
            return {
                ...state,
                initializedABTest: false,
            };
        default:
            return state;
    }
};
