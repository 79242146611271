import moment from 'moment';
import {IReduxAction, XYSerie} from '@coveord/plasma-react';
import _ from 'underscore';

import {Locales} from '../../../../Locales';
import {GraphSetPayload} from '../actions';
import {Requests} from '../constants';

export type SearchHubGraphState = Record<string, XYSerie>;

const set = (state: SearchHubGraphState, action: IReduxAction<GraphSetPayload>) => ({
    ...state,
    [action.payload.id]: {
        label: Locales.formatOrDefault(`${action.payload.id}Metric`, {
            defaultTranslation: Locales.formatOrHumanize(action.payload.id),
        }),
        data: action.payload.values.map(({date, value}) => ({x: moment(date, 'YYYY-MM-DD').unix(), y: value})),
    },
});

const GraphReducers: {[key: string]: (...args: any[]) => SearchHubGraphState} = {
    [Requests.Graph.set]: set,
    [Requests.Graph.clear]: () => null,
};

type GraphPayload = Record<string, unknown> | GraphSetPayload;
export const searchHubGraphReducers = (
    state: SearchHubGraphState = null,
    action: IReduxAction<GraphPayload>,
): SearchHubGraphState => {
    if (!_.isUndefined(GraphReducers[action.type])) {
        return GraphReducers[action.type](state, action);
    }

    return state;
};
