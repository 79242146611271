import {usePrivileges, useUserModel} from '../contexts';

/**
 * This hook provides the user model with its privileges and access.
 * @returns {UserModel}
 */
export const useUser = () => {
    const user = useUserModel();
    const {privileges, access} = usePrivileges();
    return {...user, privileges, access};
};
