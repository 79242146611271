import {IAccesses, ISearchInterfaceConfigurationResponse, SortingBy, SortingOrder} from '@core/api';

import {Locales} from '../Locales';

const tableId = 'search-interfaces-table';

export const SearchInterfacesConstants = {
    tableId,
};

export const SearchInterfaceActionsTypes = {
    add: 'ADD_SEARCH_INTERFACE',
    saveAccesses: 'SAVE_ACCESSES_SEARCH_INTERFACE',
    update: 'UPDATE_SEARCH_INTERFACE',
    delete: 'DELETE_SEARCH_INTERFACE',
    fetch: 'FETCH_SEARCH_INTERFACES',
    setIPXs: 'SET_IPXS',
};

const defaultSelectedFacets = [
    {field: 'source', label: 'Source'},
    {field: 'filetype', label: 'Filetype'},
];

const defaultSelectedSorts = [
    {by: SortingBy.RELEVANCY, label: 'Relevance'},
    {by: SortingBy.DATE, label: Locales.format('sort-date-descending-label'), order: SortingOrder.DESC},
];

export const newInitialConfigs = {
    name: '',
    title: '',
    facets: defaultSelectedFacets,
    sortCriteria: defaultSelectedSorts,
    accesses: {users: [], domains: []},
} as ISearchInterfaceConfigurationResponse;

export const newInitialNextGenSearchPage = {
    id: '',
    accesses: {users: [], domains: [], sharingDomainEnabled: false, sharingLinkEnabled: false},
} as {id: string; accesses: IAccesses};
