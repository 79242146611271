import {CatalogConfigurationModel} from '@core/api';
import {ObjectReducerGenerator} from '@core/store';
import {AnyAction, CombinedState, combineReducers} from 'redux';
import {CatalogConfigurationConstants} from './CatalogConfigurationConstants';

export type IConfigurationState = ReturnType<typeof configurationReducer>;

export const initialConfigurationState: CatalogConfigurationModel = {
    id: '',
    name: '',
    product: {
        idField: '',
        objectType: '',
        fields: [],
    },
    variant: {
        idField: '',
        objectType: '',
        fields: [],
    },
    availability: {
        idField: '',
        objectType: '',
        availableSkusField: '',
        fields: [],
    },
    fieldsMapping: {},
    associatedCatalogs: [],
};

export const configurationReducer: (
    state: CombinedState<{
        configuration;
    }>,
    action: AnyAction,
) => CombinedState<{
    configuration: CatalogConfigurationModel;
}> = combineReducers({
    configuration: ObjectReducerGenerator(CatalogConfigurationConstants.ActionsTypes.update, initialConfigurationState),
});
