import isEqual from 'fast-deep-equal';

import {DebugStoreType, DebugStoreTypeKey} from '../types';
import {PubSub} from './PubSub';
import {StoreOverloaded} from './StoreOverloaded';

let Store: DebugStoreType = {
    launchDarklyTool: {},
    trackingTool: [],
    uiFeatureFlagTool: {},
};

export const Debug = {
    getState: <K extends DebugStoreTypeKey>(key: K): DebugStoreType[K] | undefined => {
        if (__USE_DEBUG_BAR__ !== 'true') {
            StoreOverloaded.reset();
            return undefined;
        }

        const storeOverloadedByKey = StoreOverloaded.getByKey(key);

        return (storeOverloadedByKey !== undefined ? storeOverloadedByKey : Store[key]) as DebugStoreType[K];
    },
    isOverloaded: <K extends DebugStoreTypeKey>(key: K): boolean => {
        if (__USE_DEBUG_BAR__ !== 'true') {
            StoreOverloaded.reset();
            return false;
        }

        return StoreOverloaded.isKeyActive(Store, key);
    },
    resetState: <K extends DebugStoreTypeKey>(key: K): void => {
        if (__USE_DEBUG_BAR__ !== 'true') {
            StoreOverloaded.reset();
            return;
        }

        StoreOverloaded.resetByKey(key);
        PubSub.publish(key);
    },
    setState: <K extends DebugStoreTypeKey>(
        key: K,
        setter: (current: DebugStoreType[K]) => DebugStoreType[K],
        overloaded: boolean = false,
    ): void => {
        if (__USE_DEBUG_BAR__ !== 'true') {
            StoreOverloaded.reset();
            return;
        }

        let after;
        let before;
        if (!overloaded) {
            before = Store;
            Store = {...Store, [key]: setter(Store[key])};
            after = Store;
        } else {
            const storeOverloaded = StoreOverloaded.get();

            before = storeOverloaded;
            after = {...storeOverloaded, [key]: setter(storeOverloaded[key] || Store[key])};
            StoreOverloaded.set(after);
        }

        if (!isEqual(before, after)) {
            PubSub.publish(key);
        }
    },
};
