export interface CardState {
    type: string;
    id: string;
    title: string;
    defaultTitle?: string;
    link: string;
    explorerLink: string;
    isLoading: boolean;
    isSelected: boolean;
    error: string;
}

export const CardDefaultState: CardState = {
    type: '',
    id: null,
    title: '',
    defaultTitle: '',
    link: '',
    explorerLink: '',
    isLoading: false,
    isSelected: false,
    error: null,
};

export const CardsDefaultState: CardState[] = [];
