/**
 * The usage's subroute defined after `/:organizationId/usage`.
 */
export const usage: Record<string, string> = {
    '/:organizationId/usage/advanced-reports': 'viewed advanced reports V2',
    '/:organizationId/usage/advanced-reports/:dashboardName': 'viewed dashboard V2',
    '/:organizationId/usage/dimensions': 'viewed dimensions',
    '/:organizationId/usage/dimensions/activity': 'viewed dimensions activity',
    '/:organizationId/usage/dimensions/edit/:dimensionApiName': 'viewed dimension edit',
    '/:organizationId/usage/dimensions/new': 'viewed dimension add',
    '/:organizationId/usage/export': 'viewed data exports',
    '/:organizationId/usage/filters/permissions': 'viewed permission filters',
    '/:organizationId/usage/filters/permissions/:permissionFilterId': 'viewed permission filter edit',
    '/:organizationId/usage/filters/permissions/activity': 'viewed permission filters activity',
    '/:organizationId/usage/filters/permissions/new': 'viewed permission filter add',
    '/:organizationId/usage/filters/reporting': 'viewed named filters',
    '/:organizationId/usage/filters/reporting/:namedFilterId': 'viewed named filter edit',
    '/:organizationId/usage/filters/reporting/activity': 'viewed named filters activity',
    '/:organizationId/usage/filters/reporting/new': 'viewed named filter add',
    '/:organizationId/usage/health/latest-events': 'viewed data health latest events',
    '/:organizationId/usage/health/overview': 'viewed data health overview',
    '/:organizationId/usage/health/event-problems': 'viewed data health event problems',
    '/:organizationId/usage/raw-data/data-exports/past-exports': 'viewed raw data past exports',
    '/:organizationId/usage/raw-data/data-exports/past-exports/:exportId': 'viewed raw data past export recreate',
    '/:organizationId/usage/raw-data/data-exports/past-exports/activity': 'viewed raw data past exports activity',
    '/:organizationId/usage/raw-data/data-exports/past-exports/new': 'viewed raw data csv export add',
    '/:organizationId/usage/raw-data/data-exports/schedules': 'viewed raw data schedules',
    '/:organizationId/usage/raw-data/data-exports/schedules/:scheduleId/exports': 'viewed raw data schedule exports',
    '/:organizationId/usage/raw-data/data-exports/schedules/:scheduleId/exports/:exportId':
        'viewed raw data schedule export recreate',
    '/:organizationId/usage/raw-data/data-exports/schedules/activity': 'viewed raw data schedules activity',
    '/:organizationId/usage/raw-data/data-exports/schedules/edit/:scheduleId': 'viewed raw data schedule edit',
    '/:organizationId/usage/raw-data/data-exports/schedules/new': 'viewed raw data schedule add',
    '/:organizationId/usage/raw-data/snowflake/data-share': 'viewed raw data data share',
    '/:organizationId/usage/raw-data/snowflake/data-share/new': 'viewed raw data data share add',
    '/:organizationId/usage/raw-data/snowflake/reader-account': 'viewed raw data reader account',
    '/:organizationId/usage/reports': 'viewed reports',
    '/:organizationId/usage/reports/activity': 'viewed reports activity',
    '/:organizationId/usage/reports/dashboard/:dashboardId': 'viewed dashboard report',
    '/:organizationId/usage/reports/dashboard/:dashboardId/explore': 'viewed dashboard report data explorer',
    '/:organizationId/usage/reports/dashboard/new': 'viewed dashboard report add',
    '/:organizationId/usage/reports/explorer/:explorerId': 'viewed explorer report',
    '/:organizationId/usage/reports/explorer/new': 'viewed explorer report add',
    '/:organizationId/usage/visit': 'viewed visit browser',
};
