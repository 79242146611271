import moment from 'moment-timezone';
import _ from 'underscore';
import {DateUtils} from '../utils/DateUtils';

import timepicker from './timepicker.ejs';

export class Time extends Backbone.Model {
    constructor(attributes?, options?) {
        super(attributes, options);
    }

    defaults() {
        return {
            hours: 12,
            minutes: 0,
            meridian: 'PM',
        };
    }

    getHours24() {
        const hours = this.get('hours');
        const meridian = this.get('meridian');
        if (hours === 12 && meridian === 'AM') {
            return 0;
        }
        return meridian === 'PM' && hours < 12 ? hours + 12 : hours;
    }

    getHours() {
        return this.get('hours');
    }

    getMinutes() {
        return this.get('minutes');
    }

    getFormatted() {
        const date = moment.tz(DateUtils.ApplicationTimezone);
        date.hours(this.getHours24());
        date.minutes(this.getMinutes());

        return date.format('h:mm A');
    }
}

export class TimePickerView extends Marionette.ItemView<Time> {
    timepicker: JQuery;
    model: Time;

    constructor(options?) {
        super(
            _.extend(
                {
                    events: {
                        'focus input.admin-input-timepicker': 'onOpenTimePicker',
                        'click input.admin-input-timepicker.open + .add-on': 'onCloseTimePicker',
                        'blur input.admin-input-timepicker': 'onChangeTimeCustom',
                    },
                    className: 'input-append bootstrap-timepicker',
                },
                options,
            ),
        );
        this.template = timepicker;

        this.ui = {
            clockIcon: '.admin-input-timepicker + .add-on',
        };
    }

    onShow() {
        this.timepicker = this.$el.find('.admin-input-timepicker').timepicker({
            showInputs: false,
        });
        this.timepicker.timepicker('setTime', this.model.getFormatted());
        this.timepicker.on('changeTime.timepicker', (e: JQuery.TriggeredEvent) => {
            this.onChangeTime(e);
        });
        this.timepicker.on('show.timepicker', (e: JQuery.TriggeredEvent) => {
            $(e.currentTarget).addClass('open');
        });
        this.timepicker.on('hide.timepicker', (e: JQuery.TriggeredEvent) => {
            $(e.currentTarget).removeClass('open');
            this.ui.clockIcon.removeClass('closeable');
        });
    }

    onDestroy() {
        this.timepicker.off();
    }

    disable() {
        this.$el.find('.admin-input-timepicker').prop('disabled', true);
    }

    private onChangeTime(e) {
        this.model.set({
            hours: e.time.hours,
            minutes: e.time.minutes,
            meridian: e.time.meridian,
        });
    }

    private onChangeTimeCustom(event) {
        this.timepicker.timepicker('setTime', event.currentTarget.value);
    }

    onOpenTimePicker() {
        this.timepicker.timepicker('showWidget');
    }

    onCloseTimePicker() {
        if (this.ui.clockIcon.hasClass('closeable')) {
            this.ui.clockIcon.removeClass('closeable');
            this.timepicker.timepicker('hideWidget');
        } else {
            this.ui.clockIcon.addClass('closeable');
        }
    }
}

export class TimePickerWidget extends Marionette.Object {
    timepicker: TimePickerView;

    constructor(private time: Time) {
        super();
    }

    show(region: Marionette.Region) {
        this.timepicker = new TimePickerView({
            model: this.time,
        });
        region.show(this.timepicker);
    }

    disable() {
        this.timepicker.disable();
    }
}
