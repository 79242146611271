import {GenericError, PageModel} from '@core/api';
import {AccessTokenUtils} from '@core/authentication';
import {Config} from '@core/configuration';
import {ProductListingModel} from '../../ProductListings/ProductListingState';

const isErrorResponse = (response: unknown): response is GenericError =>
    response !== null &&
    typeof response === 'object' &&
    'errorCode' in response &&
    'message' in response &&
    'requestID' in response;

export interface IProductListingsService {
    getProductListingsForCatalog(
        organizationId: string,
        catalogId: string,
        page?: number,
        pageSize?: number,
    ): Promise<PageModel<ProductListingModel, 'items'>>;

    delete(organizationId: string, catalogId: string, productListingId: string): Promise<void>;

    create(organizationId: string, catalogId: string, productListing): Promise<ProductListingModel>;
}

export class ProductListingsService implements IProductListingsService {
    async getProductListingsForCatalog(
        organizationId: string,
        catalogId: string,
        page?: number,
        pageSize?: number,
    ): Promise<PageModel<ProductListingModel, 'items'>> {
        const url = ProductListingServiceUrls.listForCatalog(organizationId, catalogId, page, pageSize);
        const response = await fetch(url, {
            headers: {
                Authorization: `Bearer ${AccessTokenUtils.getToken()}`,
            },
        });

        return (await response.json()) as Promise<PageModel<ProductListingModel, 'items'>>;
    }

    async delete(organizationId: string, catalogId: string, productListingId: string): Promise<void> {
        const url = ProductListingServiceUrls.delete(organizationId, catalogId, productListingId);
        await fetch(url, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${AccessTokenUtils.getToken()}`,
            },
        });
    }

    async create(organizationId: string, catalogId: string, productListing): Promise<ProductListingModel> {
        const url = ProductListingServiceUrls.create(organizationId, catalogId);
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${AccessTokenUtils.getToken()}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(productListing),
        });

        const responseData = await response.json();
        if (isErrorResponse(responseData)) {
            throw Error(responseData.message);
        }

        return responseData as Promise<ProductListingModel>;
    }
}

export const ProductListingServiceUrls = {
    listForCatalog: (organizationId: string, catalogId: string, page?: number, pageSize?: number) => {
        const paginationParams = [page ? `page=${page}` : '', pageSize ? `pageSize=${pageSize}` : '']
            .filter((v) => !!v)
            .join('&');
        return `${Config.CloudPlatform.url}/rest/organizations/${organizationId}/catalogs/${catalogId}/productlistings?${paginationParams}`;
    },
    delete: (organizationId: string, catalogId: string, productListingId: string) =>
        `${Config.CloudPlatform.url}/rest/organizations/${organizationId}/catalogs/${catalogId}/productlistings/${productListingId}`,
    create: (organizationId: string, catalogId: string) =>
        `${Config.CloudPlatform.url}/rest/organizations/${organizationId}/catalogs/${catalogId}/productlistings`,
};

const productListingsServiceSingleton = new ProductListingsService();
export const getProductListingsServiceSingleton = (): IProductListingsService => productListingsServiceSingleton;
