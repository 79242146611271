import {createSelector} from 'reselect';
import {CommerceState} from '../CommerceState';
import {ICardSelectGroupState} from './CardSelectGroupReducer';

const getCardSelectGroup = (state: CommerceState, props: {id: string}): ICardSelectGroupState =>
    state.cardSelectGroups[props.id];

const getSelectedValue = createSelector(
    getCardSelectGroup,
    <T>(cardSelectGroup: ICardSelectGroupState<T>): T => cardSelectGroup && cardSelectGroup.selectedValue,
);

export const CardSelectGroupSelectors = {
    getSelectedValue,
    getCardSelectGroup,
};
