import {IReduxAction} from '@coveord/plasma-react';
import _ from 'underscore';
import {CardState} from '../../CardState';
import {EditNoteCardFullPayload} from '../actions/EditNoteCardActions';
import {NoteCardInitializePayload} from '../actions/NoteCardActions';

export const noteCardInitializeReducer = (state: CardState, action: IReduxAction<NoteCardInitializePayload>) =>
    _.extend({}, state, {
        title: action.payload.title,
        value: action.payload.value,
        style: action.payload.style,
        isMarkdown: action.payload.isMarkdown,
    });

export const noteCardSaveReducer = (state: CardState[], action: IReduxAction<EditNoteCardFullPayload>) =>
    _.map(state, (cardState: CardState) => {
        if (cardState.id === action.payload.id) {
            return _.extend({}, cardState, {
                value: action.payload.value,
                style: action.payload.style,
                isMarkdown: true,
            });
        }
        return cardState;
    });
