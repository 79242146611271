import {QueryClient, QueryClientProvider} from '@core/api';
import {createHashRouter, RouterProvider} from '@core/routes';
import {wrapCreateBrowserRouter} from '@sentry/react';
import {FunctionComponent, PropsWithChildren, StrictMode} from 'react';

import {authenticationFlowRoutes} from '../routes';

const sentryCreateHashRouter = wrapCreateBrowserRouter(createHashRouter);
const authenticationFlowRouter = sentryCreateHashRouter(authenticationFlowRoutes);

const ConditionalStrictMode: FunctionComponent<PropsWithChildren> = ({children}) => {
    const strictModeDisabled = import.meta.env.VITE_DANGEROUSLY_DISABLE_STRICT_MODE?.toLowerCase() === 'true';

    return strictModeDisabled ? children : <StrictMode>{children}</StrictMode>;
};

export const AuthenticationFlowApplication: FunctionComponent = () => {
    const queryClient = new QueryClient({defaultOptions: {queries: {refetchOnWindowFocus: false}}});

    return (
        <ConditionalStrictMode>
            <QueryClientProvider client={queryClient}>
                <RouterProvider router={authenticationFlowRouter} />
            </QueryClientProvider>
        </ConditionalStrictMode>
    );
};
