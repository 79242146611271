import {IReduxAction} from '@coveord/plasma-react';
import {Action} from 'redux';

export const CardActionType = {
    Mount: 'CARD_MOUNT',
    Unmount: 'CARD_UNMOUNT',
    Load: 'CARD_LOAD',
    Update: 'CARD_UPDATE',
    SetTitle: 'CARD_SET_TITLE',
    SetExplorerLink: 'CARD_SET_EXPLORER_LINK',
    SetDefaultTitle: 'CARD_SET_DEFAULT_TITLE',
    SetError: 'CARD_SET_ERROR',
    SetSelected: 'CARD_SET_SELECTED',
    DeselectAll: 'CARD_DESELECT_ALL',
};

export interface CardActionPayload {
    id: string;
}

const load = (id: string): IReduxAction<CardActionPayload> => ({
    type: CardActionType.Load,
    payload: {id},
});

const update = (id: string): IReduxAction<CardActionPayload> => ({
    type: CardActionType.Update,
    payload: {id},
});

export interface CardMountPayload extends CardActionPayload {
    type: string;
    title: string;
    defaultTitle: string;
    link: string;
}

export const mount = (
    id: string,
    type: string,
    title: string,
    defaultTitle: string,
    link: string,
): IReduxAction<CardMountPayload> => ({
    type: CardActionType.Mount,
    payload: {id, type, title, defaultTitle, link},
});

export const unmount = (id: string): IReduxAction<CardActionPayload> => ({
    type: CardActionType.Unmount,
    payload: {id},
});

export interface CardSetTitlePayload extends CardActionPayload {
    title: string;
}

export const setTitle = (id: string, title: string): IReduxAction<CardSetTitlePayload> => ({
    type: CardActionType.SetTitle,
    payload: {id, title},
});

export interface CardSetExplorerLinkPayload extends CardActionPayload {
    link: string;
}

export const setExplorerLink = (id: string, link: string): IReduxAction<CardSetExplorerLinkPayload> => ({
    type: CardActionType.SetExplorerLink,
    payload: {id, link},
});

export interface CardSetDefaultTitlePayload extends CardActionPayload {
    defaultTitle: string;
}

export const setDefaultTitle = (id: string, defaultTitle: string): IReduxAction<CardSetDefaultTitlePayload> => ({
    type: CardActionType.SetDefaultTitle,
    payload: {id, defaultTitle},
});

export interface CardSetErrorPayload extends CardActionPayload {
    error: string;
}

export const setError = (id: string, error: string): IReduxAction<CardSetErrorPayload> => ({
    type: CardActionType.SetError,
    payload: {id, error},
});

export interface CardSetSelectedPayload extends CardActionPayload {
    isSelected: boolean;
}

export const deselectCards = (): Action => ({
    type: CardActionType.DeselectAll,
});

export const setIsSelected = (id: string, isSelected: boolean): IReduxAction<CardSetSelectedPayload> => ({
    type: CardActionType.SetSelected,
    payload: {id, isSelected},
});

export const CardActions = {
    load,
    update,
    mount,
    unmount,
    setTitle,
    setExplorerLink,
    setDefaultTitle,
    setError,
    setIsSelected,
    deselectCards,
};
