import {MultilineInputCollection, ValidationError} from '@coveord/jsadmin-common';
import _ from 'underscore';
import * as s from 'underscore.string';
import {FieldModel} from '../../../../fields/FieldModel';
import {Locales} from '../../../../Locales';

export interface SfObjectFieldMappingsModelAttributes {
    fields: string[];
    sfFieldLabel?: string;
}

export class SfObjectFieldMappingsModel extends Backbone.Model {
    multilineFields: MultilineInputCollection;

    get fields(): string[] {
        return this.get('fields');
    }

    constructor(attributes: SfObjectFieldMappingsModelAttributes, options?: any) {
        super(attributes, options);

        this.multilineFields = new MultilineInputCollection();
        this.multilineFields.fromStringArray(this.fields);
    }

    defaults(): SfObjectFieldMappingsModelAttributes {
        return {
            fields: [],
        };
    }

    validate(attributes: SfObjectFieldMappingsModelAttributes): ValidationError[] {
        const errors: ValidationError[] = [];

        if (attributes.fields) {
            _.each(attributes.fields, (fieldName: string, index: number) => {
                fieldName = s.trim(fieldName);

                if (!FieldModel.isNameValid(fieldName)) {
                    errors.push({
                        message: Locales.format('fieldNameCannotContainSpecialChars'),
                        isMultilineInput: true,
                        jQuerySelector: `#FieldList ul li:nth-child(${index + 1}) input`,
                    });
                }
            });
        }

        return errors.length ? errors : null;
    }
}
