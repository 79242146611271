import _ from 'underscore';
import {ComponentIdMap} from '../../ComponentIdMap';
import {CardDefaultState, CardState} from '../CardState';
import {NoteCardStyles} from './NoteCardStyles';

export interface NoteCardState extends CardState {
    value: string;
    style: string;
    isMarkdown: boolean;
}

export const NoteCardDefaultState: NoteCardState = _.extend({}, CardDefaultState, {
    type: ComponentIdMap.CalculatedMetricComponent,
    value: '',
    style: NoteCardStyles.Info,
    isMarkdown: false,
});
