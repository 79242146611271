import {IReduxAction} from '@coveord/plasma-react';

export const ProjectDropdownActionsType = {
    update: 'UPDATE_PROJECT_DROPDOWN',
};

const update = (projectIds: string[] = []): IReduxAction<string[]> => ({
    type: ProjectDropdownActionsType.update,
    payload: projectIds,
});

export const ProjectDropdownActions = {
    update,
};
