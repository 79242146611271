import {DefinitionModel, ModifierModel, ModifierType, ProductEdition} from '@core/api';
import {ProductTypes} from '@coveord/jsadmin-common';
import {AdminState} from '../application/Reducers';
import {SettingsSelectors} from './SettingsSelectors';

const findProductTypeModifier = (organizationDefinition: DefinitionModel) =>
    organizationDefinition.modifiers.find((modifierModel) => modifierModel.modifierType === ModifierType.license);

const findAddOnModifierForEdition = (modifierModels: ModifierModel[], addOnKey: string, edition: ProductEdition) => {
    const addOnModifiers = modifierModels.filter((modifierModel) =>
        modifierModel.id.toLowerCase().startsWith(addOnKey.toLowerCase()),
    );

    if (addOnModifiers.length === 0) {
        return undefined;
    } else if (addOnModifiers.length === 1) {
        return addOnModifiers[0];
    } else {
        const addOnModifiersForEdition = addOnModifiers.find((modifierModel) =>
            modifierModel.id.toLowerCase().includes(edition.toString().toLowerCase()),
        );

        return addOnModifiersForEdition !== undefined ? addOnModifiersForEdition : addOnModifiers[0];
    }
};

const determineProductTypeModifier = (licenseModifier: ModifierModel, state: AdminState) => {
    const selectedProductType = SettingsSelectors.selectProductType(state);
    if (
        selectedProductType === ProductTypes.TEST &&
        ['MonitoringRatlab', 'FunctionalTest', 'IndexingMetricsBenchmark'].includes(licenseModifier.id)
    ) {
        return licenseModifier.id;
    } else if (
        selectedProductType === ProductTypes.INTERNAL &&
        ['IndexingMetricsDashboard', 'CoveoOffensiveSecurityInternal'].includes(licenseModifier.id)
    ) {
        return licenseModifier.id;
    } else if (selectedProductType === ProductTypes.SANDBOX) {
        return 'Non-Production';
    }
    return SettingsSelectors.selectProductType(state);
};

const removeAddOnModifier = (modifierModels: ModifierModel[], addOnKey: string) =>
    modifierModels.filter((modifierModel) => !modifierModel.id.startsWith(addOnKey));

const setModifiersForAddOns = (
    organizationDefinition: DefinitionModel,
    edition: ProductEdition,
    possibleModifierTemplates: ModifierModel[],
    addOnKeys: string[],
    state: AdminState,
) => {
    addOnKeys.forEach((addOnKey: string) => {
        organizationDefinition.modifiers = removeAddOnModifier(organizationDefinition.modifiers, addOnKey);
        if (SettingsSelectors.selectIsAddOnChecked(state, addOnKey)) {
            const modifier = findAddOnModifierForEdition(possibleModifierTemplates, addOnKey, edition);

            if (modifier !== undefined) {
                organizationDefinition.modifiers.push(modifier);
            }
        }
    });
};

export const SettingsUtils = {
    findProductTypeModifier,
    setModifiersForAddOns,
    determineProductTypeModifier,
};
