import {Platform} from '@core/api';
import {UserSelectors} from '@core/user';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

type Section =
    | 'home'
    | 'content'
    | 'commerce'
    | 'service'
    | 'search-optimization'
    | 'machine-learning'
    | 'analytics'
    | 'organization'
    | 'internal';

const defaultSectionsState: Record<Section, boolean> = Object.freeze({
    home: false,
    content: false,
    commerce: false,
    service: false,
    'search-optimization': false,
    'machine-learning': false,
    analytics: false,
    organization: false,
    internal: false,
});
const serializeSectionExpandedState = (sections: Record<Section, boolean>): string =>
    Object.entries(sections)
        .filter(([section, expanded]) => expanded && !!section)
        .map(([section]) => section)
        .join(';');

const deserializeSectionExpandedState = (sections: string): Record<Section, boolean> =>
    sections?.split(';').reduce(
        (state, expandedSection) => {
            if (expandedSection) {
                state[expandedSection] = true;
            }
            return state;
        },
        {...defaultSectionsState},
    ) ?? {...defaultSectionsState};

export const useSections = (): [Record<Section, boolean>, (section: Section) => void] => {
    const additionalInfo = useSelector(UserSelectors.getAdditionalInformation);

    const [sections, setSections] = useState<Record<Section, boolean>>(
        deserializeSectionExpandedState(additionalInfo.navigation),
    );

    useEffect(() => {
        setSections(deserializeSectionExpandedState(additionalInfo.navigation));
    }, [additionalInfo.navigation]);

    const toggleExpanded = (section: Section) =>
        setSections((previous) => {
            const current = {...previous, [section]: !previous[section]};
            Platform.user.update({
                additionalInformation: {
                    ...additionalInfo,
                    navigation: serializeSectionExpandedState(current),
                },
            });

            return current;
        });

    return [sections, toggleExpanded];
};
