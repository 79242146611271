import {IReduxAction, IThunkAction} from '@coveord/plasma-react';

export interface RequestErrorPayload {
    [key: string]: any;
}

const request = (requestName: string): IReduxAction<void> => ({
    type: `${requestName}_REQUEST`,
});

const success = (requestName: string): IReduxAction<void> => ({
    type: `${requestName}_SUCCESS`,
});

const failure = (requestName: string, errorData = {}): IReduxAction<RequestErrorPayload> => ({
    type: `${requestName}_FAILURE`,
    payload: errorData,
});

const clean = (requestName: string): IReduxAction<void> => ({
    type: `${requestName}_CLEAN`,
});

const handle =
    <T>(requestName: string, action: () => Promise<T>, triggerLoading = true): IThunkAction<Promise<T>> =>
    async (dispatch) => {
        if (triggerLoading) {
            dispatch(request(requestName));
        }
        try {
            const result = await action();
            dispatch(success(requestName));
            return result;
        } catch (exception) {
            dispatch(failure(requestName, exception));
        }
    };

export const RequestsActions = {
    request,
    success,
    failure,
    clean,
    handle,
};
