import CaseAssist from '../strings/CaseAssist.json';
import CaseAssistTabs from '../strings/CaseAssistTabs.json';
import Documentation from '../strings/Documentation.json';
import DocumentSuggestion from '../strings/DocumentSuggestion.json';
import CaseClassification from '../strings/CaseClassification.json';
import JSAdminComponents from '../strings/JSAdminComponents.json';
import UnsavedChanges from '../strings/UnsavedChanges.json';
import Prerequisites from '../strings/Prerequisites.json';
import Preview from '../strings/Preview.json';
import InsightPanel from '../strings/InsightPanel.json';

export const toTranslate = {
    CaseAssist,
    CaseAssistTabs,
    Documentation,
    DocumentSuggestion,
    CaseClassification,
    JSAdminComponents,
    UnsavedChanges,
    Prerequisites,
    Preview,
    InsightPanel,
};
