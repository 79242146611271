export const StatementsName = {
    Overview: 'overview',
    MachineLearning: 'machineLearning',
    MachineLearningLearnMore: 'machineLearningLearnMore',
    ABTest: 'abTest',
    Thesaurus: 'thesaurus',
    FeaturedResults: 'top',
    StopWords: 'stopWords',
    RankingExpressions: 'rankingExpressions',
    RankingWeights: 'rankingWeights',
    Triggers: 'triggers',
    Filters: 'filters',
    QueryParameters: 'queryParameters',
    ResultRanking: 'resultRanking',
    StatementGroups: 'statementGroups',
};

export enum StatementTabs {
    Overview = 'overview',
    ABTest = 'abTest',
    StatementGroups = 'statementGroups',
    SearchTerms = 'searchTerms',
    ResultRanking = 'resultRanking',
    MachineLearning = 'machineLearning',
    Advanced = 'advanced',
}
