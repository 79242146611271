import {locales} from '../Locales';

export const CatalogConstants = {
    ComponentIds: ((id: string) => ({
        NameInput: `${id}-name-input`,
        DescriptionInput: `${id}-description-input`,
        SourceSelect: `${id}-source-select`,
        AvailabilitySourceSelect: `${id}-availability-source-select`,
        AssociationProjectMultiSelect: `${id}-association-project-multi-select`,
        UseDifferentSourceForAvailabilityCheckbox: `${id}-use-different-source-for-availability-checkbox`,
    }))('CatalogComponent'),
    New: {
        catalogId: 'new',
    },
    Preview: {
        productId: 'product-preview',
        availabilityId: 'availability-preview',
    },
    Tabs: {
        groupId: 'editcatalog',
        overview: 'overview',
        productId: 'product',
        availabilityId: 'availability',
        fieldAssociationId: 'fieldassociation',
        standardFieldsId: 'standardfields',
    },
    FieldAssociationTable: {
        ObjectType: {
            All: 'All',
            Product: 'Product',
            Variant: 'Variant',
            Availability: 'Availability',
        },
        tableId: 'fieldAssociationTable',
        objectTypePredicateId: 'objectTypePredicateId',
        SortKeys: {
            field: 'field',
            product: 'product',
            variant: 'variant',
            availability: 'availability',
        },
        Labels: {
            field: locales.format('fieldName'),
            product: locales.format('catalogsTableProductTag'),
            variant: locales.format('catalogsTableVariantTag'),
            availability: locales.format('catalogsTableAvailabilityTag'),
        },
        fewFieldsLimit: 2,
    },
    ObjectType: {
        product: 'product',
        variant: 'variant',
        availability: 'availability',
    },
    ActionsTypes: {
        fetch: 'FETCH_CATALOG',
        save: 'SAVE_CATALOG',
    },
    Search: {
        maximumAge: 30 * 1000,
    },
};
