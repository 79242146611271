import {useGuard} from '@components/security';
import {LicenseConstants, LicenseSelectors, LicenseState} from '@core/license';
import {useSelector} from 'react-redux';
import {LocalesKeys} from '../generated/LocalesKeys';
import {useGetAllProjects} from './useGetAllProjects';

interface UseCanAddProject {
    canAddProject: boolean;
    disabledTooltip?: LocalesKeys;
}

const getDisabledTooltip = (canEdit: boolean, isLimitReached: boolean): LocalesKeys => {
    if (!canEdit) {
        return 'Shared.button.create.missingPrivileges';
    }

    if (isLimitReached) {
        return 'Shared.button.create.limitReached';
    }
};

export const useCanAddProject = (): UseCanAddProject => {
    const {canEdit} = useGuard();
    const {data} = useGetAllProjects();
    const projectsLimit = useSelector(
        (state: LicenseState) =>
            LicenseSelectors.getOrganizationProperties(state)?.[LicenseConstants.propertyLimits.projects],
    );
    const isLimitReached = data?.totalEntries >= projectsLimit;

    return {
        canAddProject: canEdit && !isLimitReached,
        disabledTooltip: getDisabledTooltip(canEdit, isLimitReached),
    };
};
