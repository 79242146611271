import {Tabs, BoxProps} from '@components/mantine';
import {Children, ComponentProps, ReactElement} from 'react';

import {SubnavLayoutProvider} from './SubnavLayout.context';
import classes from './SubnavLayout.module.css';
import {SubnavLayoutVariant, SubnavLayoutConditionalProps} from './SubnavLayout.types';
import {SubnavLayoutBody} from './SubnavLayoutBody';
import {SubnavLayoutNavigation} from './SubnavLayoutNavigation';
import {SubnavLayoutPanel} from './SubnavLayoutPanel';
import {useSubnavLayoutControlled} from './useSubnavLayoutControlled';

export type SubnavLayoutProps<T> = SubnavLayoutConditionalProps<T> &
    BoxProps & {
        children: ReactElement[] | [ReactElement, ReactElement[]];
        /**
         * The variant of the navigation panels.
         * @default primary
         */
        variant?: SubnavLayoutVariant;
    };

export const SubnavLayout = <T extends string>({
    children,
    variant = 'primary',
    routed,
    value,
    onChange,
    ...props
}: SubnavLayoutProps<T>) => {
    const convertedChildren = Children.toArray(children) as ReactElement[];
    const panels = convertedChildren.filter((child) => child.type === SubnavLayoutPanel) as Array<
        ReactElement<ComponentProps<typeof SubnavLayoutPanel>, typeof SubnavLayoutPanel>
    >;
    const otherChildren = convertedChildren.filter((child) => child.type !== SubnavLayoutPanel);
    const {defaultTab, activeTab, setActiveTab} = useSubnavLayoutControlled(panels, {
        routed,
        value,
        onChange,
    });

    return (
        <SubnavLayoutProvider value={{activeTab, defaultTab, routed, panels, variant}}>
            <Tabs
                defaultValue={defaultTab}
                value={activeTab}
                onChange={setActiveTab}
                orientation="vertical"
                variant="pills"
                classNames={classes}
                data-subnav-layout-variant={variant}
                {...props}
            >
                {otherChildren}

                <SubnavLayoutBody />
            </Tabs>
        </SubnavLayoutProvider>
    );
};
SubnavLayout.Navigation = SubnavLayoutNavigation;
SubnavLayout.Panel = SubnavLayoutPanel;
SubnavLayout.displayName = '@components/page/layouts/SubnavLayout';
