import {
    Button,
    CloseButton,
    Combobox,
    Flex,
    InfiniteQueryScrollArea,
    InputBase,
    Skeleton,
    useCombobox,
    useDebouncedValue,
} from '@components/mantine';
import {ProjectEvents} from '@components/projects';
import {PlatformPaths} from '@configurations/platform';
import {
    keepPreviousData,
    Platform,
    ProjectModel,
    ProjectSortBy,
    SortingOrder,
    useInfiniteQuery,
    useQuery,
} from '@core/api';
import {PlatformProjectHelpers, ProjectSelectors, SelectedProject} from '@core/projects';
import {Link} from '@core/routes';
import {AddSize16Px} from '@coveord/plasma-react-icons';
import {FunctionComponent, useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import {useCanAddProject} from '../../hooks';
import {Locales} from '../../strings';
import {FilterTracking} from '../../tracking';
import classes from './ProjectGlobalFilter.module.css';

const ProjectGlobalFilterFooter: FunctionComponent<{closeDropdown: () => void}> = ({closeDropdown}) => {
    const {canAddProject, disabledTooltip} = useCanAddProject();

    return (
        <Button
            component={Link}
            to={PlatformPaths.AddProject()}
            variant="subtle"
            fullWidth
            onClick={closeDropdown}
            data-disabled={!canAddProject}
            disabledTooltip={Locales.format(disabledTooltip)}
            leftSection={<AddSize16Px height={16} />}
        >
            {Locales.format('ProjectGlobalFilter.footer.cta')}
        </Button>
    );
};

export const ProjectGlobalFilter = () => {
    const [filter, setFilter] = useState('');
    const [debouncedFilter] = useDebouncedValue(filter, 200);
    const combobox = useCombobox({
        onDropdownClose: () => {
            combobox.resetSelectedOption();
            combobox.focusTarget();
            setFilter('');
        },
        onDropdownOpen: () => {
            combobox.selectActiveOption();
            combobox.focusSearchInput();
        },
    });

    const projectsSearchQuery = useInfiniteQuery({
        queryKey: PlatformProjectHelpers.infiniteQueryKeys.filterList(debouncedFilter),
        queryFn: ({pageParam}) =>
            Platform.project.list({
                page: pageParam,
                perPage: 25,
                sortBy: ProjectSortBy.name,
                order: SortingOrder.ASC,
                filter: debouncedFilter,
            }),
        getNextPageParam: (lastPage, allPages) =>
            allPages?.flatMap((page) => page.items)?.length < lastPage?.totalEntries ? allPages?.length : undefined,
        placeholderData: keepPreviousData,
        initialPageParam: 0,
    });

    const searchedProjects = useMemo(
        () => projectsSearchQuery.data?.pages.flatMap((page) => page.items).flat() ?? [],
        [projectsSearchQuery.data],
    );

    const {data: projects, isLoading: isListLoading} = useQuery({
        placeholderData: keepPreviousData,
        queryKey: PlatformProjectHelpers.queryKeys.list(),
        queryFn: () => Platform.project.list(),
        retry: false,
        gcTime: 0,
    });

    const selectedProjectId = useSelector(ProjectSelectors.getSelectedProjectId);

    useEffect(() => {
        if (filter) {
            return;
        }

        setSelectedProject((projects?.items ?? []).find((project) => project.id === selectedProjectId));
    }, [selectedProjectId, projects]);

    const [selectedProject, setSelectedProject] = useState<ProjectModel>();

    const newProject = useCallback(
        (id: string) => (projects?.items ?? []).find((project) => project.id === id),
        [JSON.stringify(projects)],
    );

    const onOptionSubmit = (val: string) => {
        combobox.closeDropdown();
        SelectedProject.setProject(newProject(val));
        FilterTracking.trackSwitchProjectFilter(val);
        window.location.hash = window.location.hash.split('?')[0];
        // Event dispatched to update list items for panels in Backbone (ex: Reports)
        window.dispatchEvent(new Event(ProjectEvents.updateSelectedProject));
    };

    const options = (searchedProjects ?? []).map((item) => (
        <Combobox.Option value={item.id} key={item.id} active={item.id === selectedProjectId}>
            {item.name}
        </Combobox.Option>
    ));

    return (
        <Flex direction="column" justify="center" px="sm" py="md" w="100%" bg="gray.1">
            {isListLoading || projectsSearchQuery.isLoading ? (
                <Skeleton width="100%" height={34} />
            ) : (
                <Combobox store={combobox} withinPortal onOptionSubmit={onOptionSubmit}>
                    <Combobox.Target>
                        {/* eslint-disable @helpers/no-right-section-without-pointer-events-none */}
                        <InputBase
                            classNames={{input: classes.input}}
                            component="button"
                            type="button"
                            onClick={() => combobox.toggleDropdown()}
                            pointer
                            rightSectionPointerEvents={!!selectedProject ? 'auto' : 'none'}
                            rightSection={
                                !!selectedProject ? (
                                    <CloseButton
                                        onClick={() => {
                                            SelectedProject.clearProject();
                                            FilterTracking.trackClearProjectFilter(selectedProjectId);
                                            window.location.hash = window.location.hash.split('?')[0];
                                            // Event dispatched to update list items for panels in Backbone (ex: Reports)
                                            window.dispatchEvent(new Event(ProjectEvents.updateSelectedProject));
                                        }}
                                    />
                                ) : (
                                    <Combobox.Chevron />
                                )
                            }
                        >
                            {selectedProject?.name || Locales.format('ProjectGlobalFilter.placeholder')}
                        </InputBase>
                    </Combobox.Target>

                    <Combobox.Dropdown>
                        <Combobox.Search
                            mb="xs"
                            value={filter}
                            onChange={(event) => setFilter(event.currentTarget.value)}
                        />
                        <Combobox.Options>
                            <InfiniteQueryScrollArea
                                maw={200}
                                query={projectsSearchQuery}
                                count={() => (searchedProjects ?? []).length ?? 0}
                            >
                                {options.length > 0 ? (
                                    options
                                ) : (
                                    <Skeleton visible={projectsSearchQuery.isLoading}>
                                        <Combobox.Empty>
                                            {Locales.format('ProjectGlobalFilter.nothingFound')}
                                        </Combobox.Empty>
                                    </Skeleton>
                                )}
                            </InfiniteQueryScrollArea>
                        </Combobox.Options>
                        <Combobox.Footer>
                            <ProjectGlobalFilterFooter closeDropdown={() => combobox.closeDropdown()} />
                        </Combobox.Footer>
                    </Combobox.Dropdown>
                </Combobox>
            )}
        </Flex>
    );
};
