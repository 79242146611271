import _ from 'underscore';
import {commonLocales} from '../CommonLocales';
import {NavigateAwayChoice, PreventNavigate} from '../utils/PreventNavigate';

export interface PreventNavigation {
    addIsDirtyCallback: (callback: {(): boolean}) => void;
    isDirty: () => boolean;
    getDirtyNavigateAwayChoices: () => NavigateAwayChoice;
}

export interface PreventNavigateView {
    preventNavigate: PreventNavigation;
}

export interface PreventNavigateBehaviorOptions {
    title?: string;
    description?: string;
    okButtonText?: string;
    cancelButtonText?: string;
}

export class PreventNavigateBehavior extends Marionette.Behavior implements PreventNavigate {
    private isDirtyCallbacks: Array<{(): boolean}>;

    constructor(options: PreventNavigateBehaviorOptions, view: Marionette.LayoutView<Backbone.Model>) {
        super(options, view);
        this.isDirtyCallbacks = [];
        this.view.preventNavigate = this;
    }

    isDirty(): boolean {
        return _.some(this.isDirtyCallbacks, (callback: () => boolean) => callback());
    }

    getDirtyNavigateAwayChoices(): NavigateAwayChoice {
        return {
            title: this.options.title || commonLocales.format('unsavedChangesTitle'),
            description: this.options.description || commonLocales.format('unsavedChangesDescription'),
            okButtonText: this.options.okButtonText || commonLocales.format('closeWithoutSavingButton'),
            cancelButtonText: this.options.cancelButtonText || commonLocales.format('buttonCancel'),
        };
    }

    private addIsDirtyCallback(callback: {(): boolean}) {
        this.isDirtyCallbacks.push(callback);
    }
}
