export const ComponentIds = {
    CaseAssist: {
        CaseAssistConfigTable: 'CaseAssistConfigTableComponent',
        CreateCaseAssistConfig: 'CreateCaseAssistConfigComponent',
        EditCaseAssistConfig: 'EditCaseAssistConfigComponent',
    },
    InsightPanel: {
        InsightPanelConfigTable: 'InsightPanelConfigTableComponent',
        EditInsightPanelConfig: 'EditInsightPanelConfigComponent',
        CreateInsightPanelConfig: 'CreateInsightPanelConfigComponent',
    },
};
