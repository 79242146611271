import {IReduxAction} from '@coveord/plasma-react';
import {ReducersMapObject} from 'redux';

import {ProjectFieldActionsPayload, ProjectFieldActionsType} from '../actions';
import {ProjectFieldState} from '../store';

const projectFieldReducers = (state: string[] = [], action: IReduxAction<ProjectFieldActionsPayload>): string[] => {
    switch (action.type) {
        case ProjectFieldActionsType.initializeProjectField:
            return [action.payload?.projectIds ?? []].flat();
        default:
            return state;
    }
};

export const rootReducer: ReducersMapObject<ProjectFieldState> = {
    projectField: projectFieldReducers,
};
