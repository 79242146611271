import {isEmpty} from 'underscore';
import {
    ButtonPositionHorizontalRequestModel,
    ButtonPositionHorizontalResponseModel,
    ButtonPositionVerticalRequestModel,
    ButtonPositionVerticalResponseModel,
    IPXButtonModel,
    InProductExperienceModel,
} from '@core/api';
import {InAppWidgetConstants} from './InAppWidgetConstants';

export const inAppWidgetButtonPositionToRadioOption = (pos: IPXButtonModel['buttonPosition']): string => {
    const button = InAppWidgetConstants.Inputs.Button;

    const isTop = pos.verticalPosition === ButtonPositionVerticalResponseModel.Top;
    const isMiddle = pos.verticalPosition === ButtonPositionVerticalResponseModel.Middle;
    const isBottom = pos.verticalPosition === ButtonPositionVerticalResponseModel.Bottom;

    const isRight = pos.horizontalPosition === ButtonPositionHorizontalResponseModel.Right;
    const isCenter = pos.horizontalPosition === ButtonPositionHorizontalResponseModel.Center;
    const isLeft = pos.horizontalPosition === ButtonPositionHorizontalResponseModel.Left;

    if (isTop && isRight) {
        return button.positionTopRight;
    }

    if (isMiddle && isRight) {
        return button.positionMiddleRight;
    }

    if (isBottom && isCenter) {
        return button.positionBottomCenter;
    }

    if (isBottom && isLeft) {
        return button.positionBottomLeft;
    }

    return button.positionBottomRight;
};

export const ipxModelToCreateUpdateIPXModel = (pageToUpdate: Partial<InProductExperienceModel>) => ({
    name: pageToUpdate.name,
    title: pageToUpdate.title,
    buttonColor: pageToUpdate.button?.buttonColor?.color,
    buttonTextColor: pageToUpdate.button?.buttonColor?.textColor,
    buttonFont: pageToUpdate.button?.buttonFont?.family,
    buttonFontSize: pageToUpdate.button?.buttonFont?.size,
    buttonIcon: pageToUpdate.button?.buttonIcon?.value,
    buttonPositionVertical:
        pageToUpdate.button?.buttonPosition?.verticalPosition.toLowerCase() as ButtonPositionVerticalRequestModel,
    buttonPositionHorizontal:
        pageToUpdate.button?.buttonPosition?.horizontalPosition.toLowerCase() as ButtonPositionHorizontalRequestModel,
    buttonCSSClasses: pageToUpdate.button?.buttonCSSClasses?.join(','),
    buttonJavaScript: pageToUpdate.button?.buttonJS,
    buttonCSS: pageToUpdate.button?.buttonCSS,
    buttonTargetSelector: pageToUpdate.button?.buttonTargetSelector,
    searchHub: pageToUpdate.token?.searchHub,
    filter: pageToUpdate.token?.filter,
    userDisplayName: pageToUpdate.token?.userDisplayName,
    userIds: JSON.stringify(pageToUpdate.token?.userIds),
    queryPipeline: pageToUpdate.token?.pipeline,
});

export const DefaultInAppWidgetPage = (): Partial<InProductExperienceModel> => ({
    button: {
        buttonColor: {
            color: '#f58020',
            textColor: '#fff',
        },
        buttonIcon: {
            value: `<svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 22 22"><path d="m11,2c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9m0 20c-6.1 0-11-4.9-11-11 0-6.1 4.9-11 11-11 6.1 0 11 4.9 11 11 0 6.1-4.9 11-11 11"/><path d="m11,4.7c-2.2 0-4 1.8-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.8-3 5h2c0-2.2 3-2.5 3-5 0-2.3-1.8-4-4-4m-1 12.3h2v-2h-2v2"/></svg>`,
        },
        buttonCSSClasses: ['btn', 'mod-primary'],
        buttonFont: {
            family: 'canada-type-gibson,sans-serif',
            size: '12px',
        },
        buttonPosition: {
            horizontalPosition: ButtonPositionHorizontalResponseModel.Right,
            verticalPosition: ButtonPositionVerticalResponseModel.Bottom,
        },
        buttonCSS: [],
        buttonJS: [],
        buttonTargetSelector: '',
    },
});

const stringValueIsNotDefined = (value: string) => isEmpty(value?.trim());

export const isNameInvalid = (name: string, otherWidgetsNames: string[], otherSearchPagesNames: string[]) => {
    if (stringValueIsNotDefined(name)) {
        return true;
    }

    if (name.length > 50) {
        return true;
    }

    if (/[^A-Za-z0-9\-_]/.exec(name)) {
        return true;
    }

    return otherWidgetsNames
        .concat(otherSearchPagesNames)
        .some((existingName) => existingName.toLowerCase() === name.toLocaleLowerCase());
};

export const isTitleInvalid = (title: string) => stringValueIsNotDefined(title) || title.length > 200;

export const isSearchHubInvalid = (searchHub: string) => stringValueIsNotDefined(searchHub);

export const isFontFamilyInvalid = (fontFamily: string) => !!/[^A-Za-z0-9'\-,\s]/.exec(fontFamily);

export const isFontSizeInvalid = (fontSize: string) => isNaN(Number(fontSize));

export const isButtonIconInvalid = (icon: string) => {
    // Having "no icon" is not invalid since the backend will serve one by default.
    if (stringValueIsNotDefined(icon)) {
        return false;
    }

    // Due to how the icon is ultimately inserted into the DOM inside an image tag
    // we need to make sure it's a valid XML/svg with the proper xmlns attribute
    const parser = new DOMParser();
    const parsed = parser.parseFromString(icon, 'image/svg+xml');
    const svg = parsed.querySelector('svg');

    if (!svg) {
        return true;
    }

    const xmlns = svg.getAttribute('xmlns');
    if (!xmlns || xmlns !== 'http://www.w3.org/2000/svg') {
        return true;
    }

    return false;
};
