import {Operators, QPLJoinOperators, QueryParameters} from '../constants';
import {QPLHelpers} from '../helpers';

const TOKENS = {
    GROUP_START: '(',
    GROUP_END: ')',
};

interface IExpressionGroup {
    parts: string[];
    operator: QPLJoinOperators;
}

/**
 * QPL Expression builder contract:
 *
 * Expressions:
 * - Must be added to the expressions part as a string
 * - Must add a combine operator at the end.
 * - The expression's parts can either be: TOKENS, combineOperator or an Expression.
 *
 * Groups:
 * - Empty groups are removed
 * - Groups with single elements are not wrapped with ( )
 */
export class QPLExpressionBuilder {
    private root: IExpressionGroup = {
        parts: [],
        operator: QPLJoinOperators.And,
    };

    private groups: IExpressionGroup[] = [this.root];

    constructor(rootOperator: QPLJoinOperators) {
        this.startGroup(rootOperator);
    }

    changeCurrentOperator(operator: QPLJoinOperators) {
        this.getCurrentGroup().operator = operator;
        return this;
    }

    startGroup(combineOperator: QPLJoinOperators) {
        this.groups.push({parts: [], operator: combineOperator});
        return this;
    }

    endGroup() {
        const {parts} = this.groups.pop();

        if (parts.length > 0) {
            // Remove combine operator
            parts.pop();

            const expression = this.getWrappedGroupIfNecessary(parts);
            const parent = this.getCurrentGroup();
            parent.parts.push(expression, parent.operator);
        }
        return this;
    }

    addIsBetweenExpression(operator: string, startDate: Date, endDate: Date) {
        // We wrap isBetween expressions to improve readability
        const expression = [
            TOKENS.GROUP_START,
            QueryParameters.QueryTimeUTC,
            operator,
            startDate.toISOString(),
            QPLJoinOperators.And,
            endDate.toISOString(),
            TOKENS.GROUP_END,
        ].join(' ');

        return this.addExpression(expression);
    }

    addUnaryFieldExpression(queryParameter: string, operator: string, contextKey?: string) {
        if (queryParameter === QueryParameters.Context && !contextKey) {
            return this;
        }

        const canUseContextKey = queryParameter === QueryParameters.Context && contextKey;

        return this.negateExpressionIfRequired(operator, (newOperator: string) =>
            [queryParameter, canUseContextKey && `[${contextKey}]`, newOperator].filter((element) => !!element),
        );
    }

    addBinaryFieldExpression(queryParameter: string, operator: string, value: string | boolean, contextKey?: string) {
        if (queryParameter === QueryParameters.Context && !contextKey) {
            return this;
        }

        const canUseContextKey = queryParameter === QueryParameters.Context && contextKey;
        const newValue: boolean | string =
            canUseContextKey && typeof value === 'boolean' ? value : QPLHelpers.escapeQuotesInValue(value as string);
        return this.negateExpressionIfRequired(operator, (newOperator: string) => [
            ...[queryParameter, canUseContextKey && `[${contextKey}]`, newOperator].filter((element) => !!element),
            newValue,
        ]);
    }

    build(buildPrefix = '') {
        if (this.groups.length > 2 || this.groups.length < 1) {
            throw Error('Unclosed groups');
        }

        if (this.groups.length > 1) {
            this.endGroup();
            // Remove last combine operator
            this.getCurrentGroup().parts.pop();
        }

        const mainExpression = this.root.parts[0];
        const finalExpression = [];

        if (buildPrefix.length > 0) {
            finalExpression.push(buildPrefix);
        }

        if (mainExpression) {
            finalExpression.push(mainExpression);
        }

        return finalExpression.join(' ');
    }

    private getCurrentGroup() {
        return this.groups[this.groups.length - 1];
    }

    private getWrappedGroupIfNecessary(parts: string[]) {
        return parts.length > 1 ? [TOKENS.GROUP_START, ...parts, TOKENS.GROUP_END].join(' ') : parts[0];
    }

    private addExpression(expression: string) {
        const currentGroup = this.getCurrentGroup();
        currentGroup.parts.push(expression, currentGroup.operator);
        return this;
    }

    private negateExpressionIfRequired(
        operator: string,
        buildExpression: (operator: string) => Array<string | boolean>,
    ) {
        const expression = Operators.OperatorsThatRequireExplicitNegation.includes(operator)
            ? ['not', TOKENS.GROUP_START, ...buildExpression(Operators.NegationOperators[operator]), TOKENS.GROUP_END]
            : buildExpression(operator);

        return this.addExpression(expression.join(' '));
    }
}
