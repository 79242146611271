import {Tabs, TabsListProps, TabsProps} from '@components/mantine';
import {Guard} from '@components/security';
import {useNavigate} from '@core/routes';
import {FunctionComponent} from 'react';

import {useTabsLayoutInternal} from './TabsLayout.context';

export interface TabsLayoutTabsListProps
    extends Omit<TabsListProps, 'children'>,
        Partial<Pick<TabsProps, 'orientation'>> {}

export const TabsLayoutTabsList: FunctionComponent<TabsLayoutTabsListProps> = ({
    orientation = 'horizontal',
    variant = 'default',
    ...tabsListProps
}) => {
    const {tabs, activeTab} = useTabsLayoutInternal();
    const navigate = useNavigate();

    return (
        <Tabs
            value={activeTab}
            onChange={(tab) => navigate(`./${tab}`)}
            orientation={orientation}
            variant={variant}
            data-testid="tabs-layout-tabs-list"
        >
            <Tabs.List {...tabsListProps}>
                {tabs.map(({props: {canRender, label, path}}) => (
                    <Guard canRender={canRender ?? true} key={path}>
                        <Tabs.Tab key={path} value={path}>
                            {label}
                        </Tabs.Tab>
                    </Guard>
                ))}
            </Tabs.List>
        </Tabs>
    );
};
