import {RequestsSelectors} from '@coveord/jsadmin-common';
import {ContextFields, DocumentSuggestions, CaseClassifications} from '@core/api';
import {ServiceState} from '../../../ServiceState';

const getCaseFields = (state: ServiceState): ContextFields =>
    state.caseAssistManager.editCaseAssistConfig.preview.fields;

const getDocumentSuggestions = (state: ServiceState): DocumentSuggestions =>
    state.caseAssistManager.editCaseAssistConfig.preview.documentSuggestions;

const getCaseClassifications = (state: ServiceState): CaseClassifications =>
    state.caseAssistManager.editCaseAssistConfig.preview.caseClassifications;

const isFetchingCaseClassifications = RequestsSelectors.createLoadingSelector(['FETCH_CASE_CLASSIFICATIONS_PREVIEW']);
const isFetchingDocumentSuggestions = RequestsSelectors.createLoadingSelector(['FETCH_DOCUMENT_SUGGESTIONS_PREVIEW']);

export const CaseAssistPreviewSelectors = {
    getCaseFields,
    getDocumentSuggestions,
    getCaseClassifications,
    isFetchingCaseClassifications,
    isFetchingDocumentSuggestions,
};
