import * as ReactVapor from '@coveord/plasma-react';

export const EditNoteCardActionsType = {
    Initialize: 'EDIT_NOTE_CARD_INITIALIZE',
    Clear: 'EDIT_NOTE_CARD_CLEAR',
    ApplyChanges: 'EDIT_NOTE_CARD_APPLY_CHANGES',
    SetValue: 'EDIT_NOTE_CARD_SET_VALUE',
    SetStyle: 'EDIT_NOTE_CARD_SET_STYLE',
    SetTitle: 'EDIT_NOTE_CARD_SET_TITLE',
};

export interface EditNoteCardActionPayload {
    // empty
}

export interface EditNoteCardFullPayload {
    id: string;
    title?: string;
    value: string;
    style: string;
}

const initialize = (
    id: string,
    value: string,
    title: string,
    style: string,
): ReactVapor.IReduxAction<EditNoteCardFullPayload> => ({
    type: EditNoteCardActionsType.Initialize,
    payload: {id, value, title, style},
});

const clear = (): ReactVapor.IReduxAction<EditNoteCardActionPayload> => ({
    type: EditNoteCardActionsType.Clear,
    payload: {},
});

const save = (
    id: string,
    value: string,
    title: string,
    style: string,
): ReactVapor.IReduxAction<EditNoteCardFullPayload> => ({
    type: EditNoteCardActionsType.ApplyChanges,
    payload: {id, value, title, style},
});

export interface EditNoteCardSetValuePayload {
    value: string;
}

const setValue = (value: string): ReactVapor.IReduxAction<EditNoteCardSetValuePayload> => ({
    type: EditNoteCardActionsType.SetValue,
    payload: {value},
});

export interface EditNoteCardSetTitlePayload {
    title: string;
}

const setTitle = (title: string): ReactVapor.IReduxAction<EditNoteCardSetTitlePayload> => ({
    type: EditNoteCardActionsType.SetTitle,
    payload: {title},
});

export interface EditNoteCardSetStylePayload {
    style: string;
}

const setStyle = (style: string): ReactVapor.IReduxAction<EditNoteCardSetStylePayload> => ({
    type: EditNoteCardActionsType.SetStyle,
    payload: {style},
});

export const EditNoteCardActions = {
    initialize,
    clear,
    save,
    setValue,
    setStyle,
    setTitle,
};
