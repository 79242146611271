export const SchemaSourceConstants = {
    Components: {
        FilterInput: {
            Id: 'schema-source-filter-input',
        },
        EntityTable: 'entity-table',
        FieldsTable: 'fields-table',
        ViewListBox: 'view',
    },
    Requests: {
        fetchTableEntities: 'FETCH_SCHEMA',
        fetchSlackTableEntities: 'FETCH_SLACK_SCHEMA',
        fetchSelectedSchemaEntity: 'FETCH_SELECTED_SCHEMA_ENTITY_WITH_FIELDS',
        fetchSelectedSchemaReference: 'FETCH_SELECTED_SCHEMA_REFERENCE_WITH_FIELDS',
        setEntity: 'SET_ENTITY',
        setSourceEntities: 'SET_SOURCE_ENTITIES',
        setSchemaEntitiesLoading: 'SET_SCHEMA_ENTITIES_LOADING',
        setSchemaFieldsLoading: 'SET_SCHEMA_FIELDS_LOADING',
    },
};
