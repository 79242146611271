import {SvgName} from '@coveord/plasma-style';

export enum ProgressStep {
    completedAddSourceOfContent = 'completedAddSourceOfContent',
    completedCreateSearchPage = 'completedCreateSearchPage',
    completedConfigureRankingRules = 'completedConfigureRankingRules',
    completedDiscoverUsageAnalytics = 'completedDiscoverUsageAnalytics',
    completedExploreMachineLearningModels = 'completedExploreMachineLearningModels',
}

export type UserTrialProgess = {
    [key in ProgressStep]: boolean;
};
export interface ResourceToExploreLink {
    title: string;
    url: string;
    description: string;
    svgName: SvgName;
}

const getMailToTrialUrl = (subject = '', body = '') => {
    const encodedSubject = encodeURIComponent(subject);
    const encodedBody = encodeURIComponent(body);
    return `mailto:trials@coveo.com?subject=${encodedSubject}&body=${encodedBody}`;
};

const getRequestName = (stepId: string): string => `${stepId}_validation`;

export const TrialHomePageConstants = {
    StepsIDs: {
        Step1: 'trial-dashboard-step-1-content',
        Step2: 'trial-dashboard-step-2-search-page',
        Step3: 'trial-dashboard-step-3-query-pipeline-rules',
        Step4: 'trial-dashboard-step-4-machine-learning-model',
    },
    Routes: {
        membersPath: 'organization/members',
    },
    Regex: {
        FacetSource: /<.*CoveoDynamicFacet.*@source.*>/,
    },
    Request: {
        fetchContent: 'FETCH_CONTENT',
        fetchSearchPages: 'FETCH_SEARCH_PAGES',
        setHostedSearchPages: 'SET_HOSTED_SEARCH_PAGES',
    },
    ContactUsModal: {
        ids: {
            contactExpert: 'contact-expert',
            contactUsCard: 'trial-contact-us',
            reviewMyTrial: 'review-my-trial-contact-us',
            requestExtension: 'request-extension-trial-contact-us',
            contactUsCardExpired: 'trial-contact-us-expired',
        },

        firstNameInputId: 'contact-us-first-name',
        lastNameInputId: 'contact-us-last-name',
        emailInputId: 'contact-us-email',
        messageInputId: 'contact-us-message',
        updated: 'updated',
        actionTypes: {submitToMarketo: 'SUBMIT_TO_MARKETO'},
    },
    getRequestName,
    getMailToTrialUrl,
    actionType: 'trial-state',
    coveoBuddy: 'Alex',
    pricingUrl: 'https://www.coveo.com/en/pricing',

    addContentRelatedDocumentationv2: 'https://docs.coveo.com/en/1702/',
    createSearchPageDocumentationv2: 'https://docs.coveo.com/en/1656/',
};

export const COVEO_CONNECT_URL = 'https://connect.coveo.com/s/';
export const COVEO_COMMUNITY_SLACK_CHANNEL_URL =
    'https://join.slack.com/t/coveocommunity/shared_invite/zt-i89n5nrr-_cyKTXvKVwvwrrac3k0C0A';
