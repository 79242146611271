import {IReduxAction} from '@coveord/plasma-react';
import _ from 'underscore';
import {
    EditNoteCardActionPayload,
    EditNoteCardActionsType,
    EditNoteCardFullPayload,
    EditNoteCardSetStylePayload,
    EditNoteCardSetTitlePayload,
    EditNoteCardSetValuePayload,
} from '../actions/EditNoteCardActions';
import {EditNoteCardDefaultState, EditNoteCardState} from '../EditNoteCardState';

const initialize = (state: EditNoteCardState, action: IReduxAction<EditNoteCardFullPayload>): EditNoteCardState =>
    _.extend({}, state, EditNoteCardDefaultState, {
        value: action.payload.value,
        style: action.payload.style,
        title: action.payload.title,
    });

const clear = (state: EditNoteCardState, action: IReduxAction<EditNoteCardActionPayload>): EditNoteCardState =>
    _.extend({}, EditNoteCardDefaultState);

const setValue = (state: EditNoteCardState, action: IReduxAction<EditNoteCardSetValuePayload>): EditNoteCardState =>
    _.extend({}, state, {value: action.payload.value});

const setStyle = (state: EditNoteCardState, action: IReduxAction<EditNoteCardSetStylePayload>): EditNoteCardState =>
    _.extend({}, state, {style: action.payload.style});

const setTitle = (state: EditNoteCardState, action: IReduxAction<EditNoteCardSetTitlePayload>): EditNoteCardState =>
    _.extend({}, state, {title: action.payload.title});

const EditNoteCardReducers: {[key: string]: (...args: any[]) => EditNoteCardState} = {
    [EditNoteCardActionsType.Initialize]: initialize,
    [EditNoteCardActionsType.Clear]: clear,
    [EditNoteCardActionsType.SetValue]: setValue,
    [EditNoteCardActionsType.SetStyle]: setStyle,
    [EditNoteCardActionsType.SetTitle]: setTitle,
};

export type EditNoteCardPayload =
    | EditNoteCardActionPayload
    | EditNoteCardFullPayload
    | EditNoteCardSetValuePayload
    | EditNoteCardSetStylePayload;

export const EditNoteCardReducer = (
    state: EditNoteCardState = EditNoteCardDefaultState,
    action?: IReduxAction<EditNoteCardPayload>,
): EditNoteCardState => {
    if (EditNoteCardReducers[action.type]) {
        return EditNoteCardReducers[action.type](state, action);
    }

    return state;
};
