import {IData, IReduxAction} from '@coveord/plasma-react';

import {SalesforceRowActionPayload, SalesforceFieldsActionPayload, SalesforceActions} from './SalesforceActions';
import {SfObjectFieldModel} from './fields/SfObjectFieldModel';

export interface SalesforceState {
    selectedObjectRow: IData;
    // TODO: May be deleted when TableHOC is in effect.
    selectedObjectFieldRow: IData;
    areFieldsLoading: boolean;
    areFieldsInError: boolean;
    fields: SfObjectFieldModel[];
}

export const salesforceInitialState = {
    selectedObjectRow: undefined,
    selectedObjectFieldRow: undefined,
    areFieldsLoading: false,
    areFieldsInError: false,
    fields: [],
};

const salesforceRowReducer = (
    state: SalesforceState = salesforceInitialState,
    action: IReduxAction<SalesforceRowActionPayload>,
): SalesforceState => {
    switch (action.type) {
        case SalesforceActions.setSelectedObjectRow:
            return {
                ...state,
                selectedObjectRow: action.payload.row,
            };
        case SalesforceActions.setSelectedObjectFieldRow:
            return {
                ...state,
                selectedObjectFieldRow: action.payload.row,
            };
        default:
            return state;
    }
};

const salesforceFieldsLoadingReducer = (
    state: SalesforceState = salesforceInitialState,
    action: IReduxAction,
): SalesforceState => {
    switch (action.type) {
        case SalesforceActions.setFieldsLoading:
            return {
                ...state,
                areFieldsLoading: true,
                areFieldsInError: false,
            };
        default:
            return state;
    }
};

const salesforceFieldsLoadedReducer = (
    state: SalesforceState = salesforceInitialState,
    action: IReduxAction<SalesforceFieldsActionPayload>,
): SalesforceState => {
    switch (action.type) {
        case SalesforceActions.setFields:
            return {
                ...state,
                areFieldsLoading: false,
                fields: action.payload.fields,
            };
        default:
            return state;
    }
};

const salesforceFieldsInErrorReducer = (
    state: SalesforceState = salesforceInitialState,
    action: IReduxAction,
): SalesforceState => {
    switch (action.type) {
        case SalesforceActions.setFieldsInError:
            return {
                ...state,
                areFieldsLoading: false,
                areFieldsInError: true,
            };
        default:
            return state;
    }
};

export const salesforceReducer = (state: SalesforceState, action: IReduxAction) => {
    state = salesforceRowReducer(state, action as unknown as IReduxAction<SalesforceRowActionPayload>);
    state = salesforceFieldsLoadingReducer(state, action);
    state = salesforceFieldsInErrorReducer(state, action);
    state = salesforceFieldsLoadedReducer(state, action as unknown as IReduxAction<SalesforceFieldsActionPayload>);
    return state;
};
