import {NavLink, NavLinkProps} from '@components/mantine';
import {Link, type LinkProps, useMatch} from '@core/routes';
import {FunctionComponent} from 'react';
import {BadgeVariant, NavigationBadge} from './NavigationBadge';

interface NavigationLinkProps
    extends Omit<NavLinkProps, 'component' | 'active'>,
        Omit<LinkProps, 'color' | 'onChange' | 'style'> {
    badge?: BadgeVariant;
}

export const NavigationLink: FunctionComponent<NavigationLinkProps> = ({badge, label, ...others}) => {
    const match = useMatch(`/:orgId${others.to}/*`);

    return (
        <NavLink
            label={label}
            rightSection={badge ? <NavigationBadge variant={badge} /> : undefined}
            active={!!match}
            component={Link}
            noWrap
            data-navlink
            {...others}
        />
    );
};
