import {RouteObject} from '@core/routes';
// legacy packages
import {commerceRoutes} from '@coveord/jsadmin-commerce';
import {logBrowserRoutes} from '@coveord/jsadmin-log-browser';
import {SORoutes} from '@coveord/jsadmin-search-optimization';
import {serviceRoutes} from '@coveord/jsadmin-service';
import {UARoutes} from '@coveord/jsadmin-usage-analytics';
import {routes as V2Routes} from '@coveord/jsadmin-v2/routes/index';
import {MLRoutes} from '@pages/model';
// new packages
import {activityBrowserRoutes} from '@pages/activity-browser';
import {advancedReportsRoutes} from '@pages/advanced-reports';
import {conditionsRoutes} from '@pages/conditions';
import {contentBrowserRoutes} from '@pages/content-browser';
import {crawlingModuleRoutes} from '@pages/crawling-module';
import {dataHealthRoutes} from '@pages/data-health';
import {homePageRoutes} from '@pages/home';
import {indexRoutes} from '@pages/index';
import {projectRoutes} from '@pages/project';
import {propertiesManagerRoutes} from '@pages/properties-manager';
import {relevanceInspectorRoutes} from '@pages/relevance-inspector';
import {snapshotRoutes} from '@pages/snapshot';
import {sourceMainRoutes} from '@pages/source';
import {storefrontAssociationsRoutes} from '@pages/storefront-associations';
import {privilegesRoutes} from '@pages/privileges';

export const mainBusinessRoutes: RouteObject[] = [
    ...activityBrowserRoutes,
    ...advancedReportsRoutes,
    ...conditionsRoutes,
    ...contentBrowserRoutes,
    ...crawlingModuleRoutes,
    ...dataHealthRoutes,
    ...homePageRoutes,
    ...projectRoutes,
    ...relevanceInspectorRoutes,
    ...snapshotRoutes,
    ...sourceMainRoutes,
    ...storefrontAssociationsRoutes,
    ...V2Routes,
    ...SORoutes,
    ...UARoutes,
    ...commerceRoutes,
    ...MLRoutes,
    ...serviceRoutes,
    ...logBrowserRoutes,
    ...propertiesManagerRoutes,
    ...indexRoutes,
    ...privilegesRoutes,
];
