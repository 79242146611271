import {Page} from '@components/page';
import {FunctionComponent} from 'react';

import {Locales} from '../../strings';
import {ErrorContent} from './ErrorContent';

export const ErrorPage: FunctionComponent = () => (
    <Page title={Locales.format('error.dev.title')} canRender framework="Mantine" owner="frontend-foundation">
        <ErrorContent />
    </Page>
);
