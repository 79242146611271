import {Platform, PrivilegeModel, useQuery, UseQueryResult} from '@core/api';
import {OrganizationContext} from '@core/organization';
import Registry from '@core/registry';
import {createContext, useContext, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {PrivilegesListActions, PrivilegesLists, privilegesListsIds} from '../privileges';
import {UserPrivilegesValidator} from '../user';

export const PrivilegesContext = createContext<{query: UseQueryResult<PrivilegeModel[]> | undefined}>({
    query: undefined,
});

export const usePrivileges = () => {
    const context = useContext(PrivilegesContext);
    const userPrivileges = useMemo(
        () => ({privileges: context?.query?.data, access: new UserPrivilegesValidator(context?.query?.data ?? [])}),
        [context.query?.data],
    );

    if (!context) {
        throw new Error('usePrivileges must be used within a PrivilegesProvider');
    }

    if (!context.query?.data) {
        throw new Error('Privileges data is not available');
    }

    return userPrivileges;
};

export const PrivilegesProvider = ({children}) => {
    const organizationCtx = useContext(OrganizationContext);
    const organizationId = organizationCtx.query?.data?.id;
    const dispatch = useDispatch();

    const privilegesQuery = useQuery({
        queryKey: ['init', 'user-privileges', organizationId],
        queryFn: async () => {
            const privileges = await Platform.privileges.getUserPrivileges();
            dispatch(PrivilegesListActions.addOrOverwrite(privilegesListsIds[PrivilegesLists.User], privileges));
            Registry.register('userPrivileges', privileges);
            return privileges;
        },
        retry: false,
        enabled: !!organizationId,
        initialData: Registry.get('userPrivileges', {optional: true}),
        staleTime: Infinity,
    });

    return <PrivilegesContext.Provider value={{query: privilegesQuery}}>{children}</PrivilegesContext.Provider>;
};
