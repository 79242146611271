import * as PlasmaStyle from '@coveord/plasma-style';
import _ from 'underscore';
import {PickySelectable, PickySingleSelect, SingleSelectEvents} from '../base-models/PickyModels';
import {KeyCode} from '../Constants';
import {Channels, Radio} from '../radio/Radio';
import {UI} from '../utils/UIUtils';
import {getChildViewEvent} from '../utils/Utils';
import {FlatSelectView} from './flat-select/FlatSelectView';
import {RadioSelectView} from './RadioSelectViews';
import {WidgetEvents} from './WidgetEvents';
import {commonLocales} from '../CommonLocales';

import SelectOptionTemplate from './select-option.ejs';
import selectDropdown from './select-dropdown.ejs';

export interface OtherProperty {
    property: string;
    value: string;
}

export interface SelectOption {
    value: any;
    label?: string;
    iconClass?: string;
    svgClass?: string;
    svgSpanClass?: string;
    otherClasses?: string;
    otherProperty?: {property: string; value: string};
    otherProperties?: OtherProperty[];
}

export class Options extends PickySingleSelect<Option> {
    selected: Option;

    constructor(models?, options = {}) {
        super(
            models,
            _.extend(options, {
                model: Option,
            }),
        );
    }

    setSelectOptions(selectOptions: any[]) {
        const options: Option[] = [];
        for (let i = 0; i < selectOptions.length; i++) {
            const selectOption = selectOptions[i];
            const val = _.property('value')(selectOption);
            const value = _.isUndefined(val) ? selectOption : val;
            const label = _.property('label')(selectOption) || val || selectOption;
            const iconClass = _.property('iconClass')(selectOption) || '';
            const svgClass = _.property('svgClass')(selectOption) || '';
            const svgSpanClass = _.property('svgSpanClass')(selectOption) || '';
            const otherProperty = _.property('otherProperty')(selectOption);
            const otherProperties = _.property('otherProperties')(selectOption);
            const otherClasses = _.property('otherClasses')(selectOption) || '';

            options.push(
                new Option({
                    iconClass,
                    value,
                    label,
                    svgClass,
                    svgSpanClass,
                    otherProperty,
                    otherProperties,
                    otherClasses,
                }),
            );
        }
        this.reset(options);
    }

    hasOptionValue(optionValue): boolean {
        return !!this.findWhere({value: optionValue});
    }

    pickySelectFirst() {
        if (this.length > 0) {
            this.pickySelect(this.at(0));
        }
    }

    pickySelectFromValueOrFirst(value: any) {
        const target = this.findWhere({value: value});
        if (target) {
            this.pickySelect(target);
        } else {
            this.pickySelectFirst();
        }
    }

    pickySelectFromValueOrDefault(value: any, defaultValue: any) {
        const target = this.findWhere({value: value});
        if (target) {
            this.pickySelect(target);
        } else {
            this.pickySelectFromValueOrFirst(defaultValue);
        }
    }

    pickySelectFromValueOrFirstOrEmptyValue(value: any) {
        const target = this.findWhere({value: value});
        if (target) {
            this.pickySelect(target);
        } else if (this.length > 0) {
            this.pickySelectFirst();
        } else {
            this.setSelectOptions([{value: '', label: ''}]);
            this.pickySelectFirst();
        }
    }

    pickySelectFromValueOrEmptyValue(value) {
        const target = this.findWhere({value: value});
        if (target) {
            this.pickySelect(target);
        } else {
            const targ = this.findWhere({value: ''});
            if (targ) {
                this.pickySelect(targ);
            }
        }
    }

    deepClone(): Options {
        return new Options(this.toJSON());
    }
}

export class Option extends PickySelectable {
    getValue(): string {
        return this.value;
    }

    get value(): string {
        return this.get('value');
    }

    get label(): string {
        return this.get('label');
    }

    get iconClass(): string {
        return this.get('iconClass') || '';
    }

    get svgClass(): string {
        return this.get('svgClass') || '';
    }

    get svgSpanClass(): string {
        return this.get('svgSpanClass') || '';
    }

    get otherProperty(): OtherProperty {
        return this.get('otherProperty');
    }

    get otherProperties(): OtherProperty[] {
        return this.get('otherProperties');
    }

    get otherClasses(): string {
        return this.get('otherClasses');
    }
}

export class SingleSelectOptionView extends Marionette.ItemView<Option> {
    constructor(options?) {
        super(
            _.extend(
                {
                    tagName: 'li',
                },
                options,
            ),
        );

        this.template = SelectOptionTemplate;
        this.templateHelpers = {
            PlasmaStyle: PlasmaStyle,
            formatValue: () => this.options.valueFormatter(this.model.label),
            formatDescription: () =>
                options.valueAsDescription
                    ? this.templateHelpers.formatValue()
                    : this.options.descriptionFormatter(this.model.value),
            isSelected: () => this.model.selected,
            hasOtherProperty: () => !!this.model.otherProperty,
            hasOtherProperties: () => !!this.model.otherProperties,
            getOtherProperties: () =>
                _.isArray(this.model.otherProperties)
                    ? this.model.otherProperties
                          .map((property: OtherProperty) => `${property.property}="${property.value}"`)
                          .join(' ')
                    : '',
            iconClass: this.model.iconClass,
            svgClass: this.model.svgClass,
            svgSpanClass: this.model.svgSpanClass,
            otherClasses: this.model.otherClasses,
        };
    }

    triggers() {
        return {
            click: SingleSelectEvents.SelectOne,
        };
    }
}

export class SingleSelectView extends Marionette.CompositeView<Backbone.Model, Options> {
    collection: Options;
    noPickySelect: boolean;
    private isFocused: boolean;
    private tabIndex: string;

    constructor(options?) {
        super(
            _.extend(
                {
                    childView: SingleSelectOptionView,
                    childViewOptions: {
                        valueAsDescription: options.valueAsDescription,
                        valueFormatter: options.valueFormatter,
                        descriptionFormatter: options.descriptionFormatter,
                    },
                    childViewContainer: 'ul.dropdown-menu',
                    className: 'admin-select dropdown',
                    collectionEvents: {
                        'select:one': 'render',
                    },
                    events: {
                        keydown: 'onKeyDown',
                        keyup: 'onKeyUp',
                        'focus .dropdown-toggle': 'setFocus',
                        'mousedown .dropdown-toggle': 'onMouseDown',
                        'click .dropdown-toggle': 'onClick',
                    },
                },
                options,
            ),
        );
        this.template = selectDropdown;
        this.templateHelpers = {
            PlasmaStyle: PlasmaStyle,
            currentSelectedValueIcon: () => (this.collection.selected ? this.collection.selected.iconClass : ''),
            currentSelectedSvgClass: () => (this.collection.selected ? this.collection.selected.svgClass : ''),
            currentSelectedSvgSpanClass: () => (this.collection.selected ? this.collection.selected.svgSpanClass : ''),
            currentSelectedValue: () => {
                const selectedLabel = this.collection.selected ? this.collection.selected.label : null;
                if (selectedLabel) {
                    return options.valueFormatter(selectedLabel);
                }
            },
            currentSelectedDescription: () => {
                const selectedValue = this.collection.selected ? this.collection.selected.value : null;
                if (selectedValue) {
                    return options.descriptionFormatter(selectedValue);
                }
            },
            prependText: () => options.prependText || '',
            dropdownToggleId: () => options.dropdownToggleId,
            customAttributes: () => (options && options.customAttributes ? options.customAttributes.join(' ') : ''),
            tag: options.useTooltipOnButton ? 'span' : 'button',
        };

        this.ui = {
            toggle: '.dropdown-toggle',
            valueIcon: '.dropdown-toggle .value-icon',
        };
        this.noPickySelect = options.noPickySelect;
    }

    onBeforeRender() {
        const isButtonFocused = !_.isString(this.ui.toggle) && this.ui.toggle.is(':focus');
        this.isFocused = this.$el.hasClass('open') || isButtonFocused;

        this.tabIndex = !_.isString(this.ui.toggle) ? this.ui.toggle.attr('tabindex') : '';
    }

    onRender() {
        this.forceClose();
        if (this.isFocused) {
            this.ui.toggle.focus();
        }
        if (this.tabIndex) {
            this.ui.toggle.attr('tabindex', this.tabIndex);
        }
    }

    onDomRefresh() {
        if (this.ui.valueIcon.tooltip) {
            this.ui.valueIcon.tooltip({container: 'body'});
        }
    }

    forceClose(changeFocus = false) {
        this.$el.removeClass('open');
        if (changeFocus) {
            const focusable = this.$el.offsetParent().find(':focusable').not('.coveo-checkbox, .dropdown-menu a');
            const currentIndex = focusable.index(document.activeElement);
            focusable.eq(currentIndex + 1).focus();
        } else if (this.isFocused) {
            this.ui.toggle.focus();
        }
    }

    setEnabled(enabled: boolean) {
        this.bindUIElements();
        this.ui.toggle.prop('disabled', !enabled);
    }

    setFocus(e?: JQueryEventObject) {
        if (!this.$el.hasClass('open')) {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }

            if (_.isUndefined(e)) {
                this.ui.toggle.focus();
            }
        }
    }

    selectOption(option: Option) {
        const optionView = this.children.findByModel(option);
        if (optionView) {
            this.$el.find('.selected-value').removeClass('selected-value');
            optionView.$el.children().addClass('selected-value');
            this.$el.find('.dropdown-selected-value').html(option.label);
        }
    }

    private onMouseDown(e: JQuery.TriggeredEvent) {
        e.preventDefault();
        e.stopPropagation();
    }

    private onClick(e: JQuery.TriggeredEvent) {
        if (this.$el.hasClass('open')) {
            e.preventDefault();
            e.stopPropagation();
            this.forceClose();
        } else {
            this.toggleActive(this.$el.find('.selected-value').parent(), true, true);
        }
    }

    private onKeyDown(e: JQueryKeyEventObject) {
        if (e.keyCode === KeyCode.ESCAPE && !this.$el.hasClass('open')) {
            const ev: any = jQuery.Event('keydown');
            ev.keyCode = KeyCode.ESCAPE;
            this.$el.parent().trigger(ev);
        } else if (this.$el.hasClass('open') || this.ui.toggle.is(':focus')) {
            if (
                _.contains(
                    [KeyCode.ARROW_DOWN, KeyCode.ARROW_UP, KeyCode.ENTER, KeyCode.SPACEBAR, KeyCode.ESCAPE],
                    e.keyCode,
                )
            ) {
                e.preventDefault();
                e.stopPropagation();
            } else if (e.keyCode === KeyCode.TAB && this.$el.hasClass('open')) {
                e.preventDefault();
                e.stopPropagation();
            }
        }
    }

    private onKeyUp(e: JQueryKeyEventObject) {
        if (this.$el.hasClass('open')) {
            if (
                _.contains(
                    [KeyCode.ARROW_DOWN, KeyCode.ARROW_UP, KeyCode.ENTER, KeyCode.SPACEBAR, KeyCode.ESCAPE],
                    e.keyCode,
                )
            ) {
                e.preventDefault();
                e.stopPropagation();
            }
            if (e.keyCode === KeyCode.ENTER || e.keyCode === KeyCode.SPACEBAR) {
                const active = this.getActive();
                if (active.length) {
                    this.collection.at(active.index()).select();
                }
                this.forceClose();
            } else if (e.keyCode === KeyCode.TAB) {
                const active = this.getActive();
                if (active.length) {
                    this.collection.at(active.index()).select();
                    this.forceClose(true);
                } else {
                    this.forceClose();
                }
            } else if (e.keyCode === KeyCode.ESCAPE) {
                this.forceClose();
            } else if (e.keyCode === KeyCode.ARROW_DOWN) {
                const el = this.findNextChoice();
                this.toggleActive(el);
            } else if (e.keyCode === KeyCode.ARROW_UP) {
                const el = this.findPreviousChoice();
                this.toggleActive(el);
            }
        } else if (_.contains([KeyCode.ARROW_DOWN, KeyCode.ARROW_UP, KeyCode.ENTER, KeyCode.SPACEBAR], e.keyCode)) {
            e.preventDefault();
            e.stopPropagation();

            this.toggleActive(this.$el.find('.selected-value').parent(), true, true);
            this.$el.addClass('open');
        }
    }

    private getActive(): JQuery {
        return this.$el.find('.active');
    }

    private toggleActive(el: JQuery, setActive = true, close = false) {
        const active = this.getActive().removeClass('active');
        if (el.length) {
            el.toggleClass('active', setActive);
            if (close) {
                Radio.channel(Channels.Widgets).emit(WidgetEvents.Open, el);
            } else if (setActive) {
                UI.scrollIntoView(el, true, null, this.ui.content);
            }
        }
    }

    private findPreviousChoice(): JQuery {
        const active = this.getActive();
        let toActivate: JQuery;
        if (active.length) {
            toActivate = active.prevAll('li').first();
        }
        return toActivate && toActivate.length ? toActivate : this.$el.find('li').last();
    }

    private findNextChoice(): JQuery {
        const active = this.getActive();
        let toActivate: JQuery;
        if (active.length) {
            toActivate = active.nextAll('li').first();
        }
        return toActivate && toActivate.length ? toActivate : this.$el.find('li').first();
    }
}

/**
 * We cannot remove the L10N usage here because it would be a breaking change. Since L10N was containing every strings of every projects,
 * the default formatter was always working, but with the Locales class, this stops. When moving to Locales, we should pass a valueFormatter
 * to every SingleSelect that doesn't have one. Once done, remove this or return the value (a.k.a. will be useless anyway).
 *
 * @deprecated Stop using the default formatter since L10N will be removed.
 */
const DEFAULT_VALUE_FORMATTER = (value: string) => commonLocales.formatOrHumanize(value);
const DEFAULT_DESCRIPTION_FORMATTER = (value: string) => '';

export interface SingleSelectOptions {
    flatSelect?: boolean;
    radioSelect?: boolean;
    radioSelectName?: string;
    flatBlock?: boolean;
    valueAsDescription?: boolean;
    valueFormatter?: (value: string) => string;
    descriptionFormatter?: (value: string) => string;
    prependText?: string;
    dropdownToggleId?: string;
    modBtnGroup?: boolean;
    useButtonTag?: boolean;
    noPickySelect?: boolean;
    customAttributes?: string[];
    useTooltipOnButton?: boolean;
}

export class DefaultSingleSelectOptions implements SingleSelectOptions {
    flatSelect = false;
    radioSelect = false;
    valueFormatter = DEFAULT_VALUE_FORMATTER;
    descriptionFormatter = DEFAULT_DESCRIPTION_FORMATTER;
}

export class SingleSelectWidget extends Marionette.Object {
    singleSelect: SingleSelectView;

    constructor(private selectOptions: Options) {
        super();
    }

    show(region: Marionette.Region, options: SingleSelectOptions = new DefaultSingleSelectOptions()) {
        let view: Marionette.CollectionView<Backbone.Model, Options>;
        if (options.flatSelect) {
            view = new FlatSelectView({
                collection: this.selectOptions,
                valueAsDescription: options.valueAsDescription || false,
                valueFormatter: options.valueFormatter || DEFAULT_VALUE_FORMATTER,
                descriptionFormatter: options.descriptionFormatter || DEFAULT_DESCRIPTION_FORMATTER,
                flatBlock: options.flatBlock || false,
                modBtnGroup: options.modBtnGroup || false,
                useButtonTag: options.useButtonTag || false,
            });
        } else if (options.radioSelect) {
            view = new RadioSelectView({
                collection: this.selectOptions,
                valueFormatter: options.valueFormatter || DEFAULT_VALUE_FORMATTER,
                descriptionFormatter: options.descriptionFormatter || DEFAULT_DESCRIPTION_FORMATTER,
                radioSelectName: options.radioSelectName,
            });
        } else {
            this.singleSelect = new SingleSelectView({
                collection: this.selectOptions,
                valueAsDescription: options.valueAsDescription || false,
                valueFormatter: options.valueFormatter || DEFAULT_VALUE_FORMATTER,
                descriptionFormatter: options.descriptionFormatter || DEFAULT_DESCRIPTION_FORMATTER,
                prependText: options.prependText || '',
                dropdownToggleId: options.dropdownToggleId || '',
                noPickySelect: options.noPickySelect || false,
                customAttributes: options.customAttributes || [],
                useTooltipOnButton: !!options.useTooltipOnButton,
            });
            view = this.singleSelect;
        }
        try {
            region.show(view);
            this.listenTo(view, getChildViewEvent(SingleSelectEvents.SelectOne), this.onSelectOption);
            Radio.channel(Channels.Widgets).on(WidgetEvents.Open, () => {
                if (this.singleSelect && !this.singleSelect.isDestroyed) {
                    this.singleSelect.forceClose();
                }
            });
        } catch (err) {
            // Nothing to see here!
        }
    }

    onSelectOption(view) {
        if (this.singleSelect && (view.model.selected || this.singleSelect.noPickySelect)) {
            this.singleSelect.forceClose();
        }
        if (this.singleSelect && this.singleSelect.noPickySelect) {
            this.singleSelect.selectOption(view.model);
        } else {
            this.selectOptions.pickySelect(view.model);
        }
    }

    focus() {
        if (this.singleSelect) {
            this.singleSelect.setFocus();
        }
    }
}
