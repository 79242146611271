import {PipelineModel, Platform, PrivilegeModel} from '@core/api';
import {PrivilegesAccessOptions, PrivilegesListActions, PrivilegeTypes, PrivilegeUtils} from '@core/user';
import {BasePayload, IDispatch, IReduxAction, IThunkAction} from '@coveord/plasma-react';

import {AdminState} from '../../application/Reducers';
import {SourcesActions} from '../../sources/actions/SourcesActions';
import {PrivilegesTableSelectors} from '../privileges-table/PrivilegesTableSelectors';
import {
    granularResourcesUrl,
    isBinaryDomain,
    pushDocumentDomain,
    searchDomain,
    sourceDomain,
} from './GranularPrivilegesTableConstants';
import {
    getAppliedPrivileges,
    getGranularPrivileges,
    GranularPrivilegesTableSelectorProps,
    GranularPrivilegesTableSelectors,
} from './GranularPrivilegesTableSelectors';
import {GranularPrivilegesTableUtils} from './GranularPrivilegesTableUtils';

export const GranularPrivilegesTableActionTypes = {
    add: 'ADD_GRANULAR_PRIVILEGES_TABLE',
    remove: 'REMOVE_GRANULAR_PRIVILEGES_TABLE',
    setLoading: 'SET_GRANULAR_PRIVILEGES_TABLE_LOADING',
};

export interface GranularResource {
    id: string;
    name: string;
    additionalInfo?: string;
}

export type GranularResourceFetchArguments = {
    domain: string;
    resourceId: string;
    isNew?: boolean;
    panelResource?: PrivilegeModel;
};

export const granularResourceDefaults: GranularResource = {
    id: undefined,
    name: '',
};

export interface GranularPrivilegesTablePayload extends BasePayload {
    resources: GranularResource[];
    loading: boolean;
}

const add = (id: string, resources: GranularResource[]): IReduxAction<Partial<GranularPrivilegesTablePayload>> => ({
    type: GranularPrivilegesTableActionTypes.add,
    payload: {
        id,
        resources,
    },
});

const setLoading = (id: string, loading: boolean): IReduxAction<Partial<GranularPrivilegesTablePayload>> => ({
    type: GranularPrivilegesTableActionTypes.setLoading,
    payload: {
        id,
        loading,
    },
});

const remove = (id: string): IReduxAction<BasePayload> => ({
    type: GranularPrivilegesTableActionTypes.remove,
    payload: {
        id,
    },
});

const fetch =
    ({domain, isNew = false, resourceId, panelResource}: GranularResourceFetchArguments): IThunkAction =>
    async (dispatch: IDispatch) => {
        const setResources = (data) => {
            const additionalSelfRow: GranularResource[] =
                isNew && PrivilegeUtils.getPrivilegeDomain(panelResource) === domain
                    ? [{...granularResourceDefaults, id: resourceId}]
                    : [];
            const resources: GranularResource[] = data
                .map((resource: any) =>
                    GranularPrivilegesTableUtils.parseGranularResource(domain, {
                        ...granularResourceDefaults,
                        ...resource,
                    }),
                )
                .concat(additionalSelfRow);
            dispatch(add(domain, resources));
        };

        if (domain === sourceDomain || domain === pushDocumentDomain) {
            dispatch(setLoading(domain, true));
            const models = await dispatch(SourcesActions.fetchAll());
            setResources(models);
            setLoading(domain, false);
        } else if (domain === searchDomain) {
            dispatch(setLoading(domain, true));
            let pipelines: PipelineModel[] = [];
            let hasMore = true;
            const perPage = 200;
            let page = 0;

            while (hasMore) {
                const results = await Platform.pipeline.list({perPage, page});
                pipelines = pipelines.concat(results);
                page++;
                hasMore = results.length === perPage;
            }

            setResources(pipelines);
            dispatch(setLoading(domain, false));
        } else {
            const url = granularResourcesUrl[domain];
            if (url) {
                dispatch(setLoading(domain, true));
                $.get(url)
                    .done((data) => {
                        if (Array.isArray(data)) {
                            setResources(data);
                        }
                    })
                    .always(() => dispatch(setLoading(domain, false)));
            }
        }
    };

const setAccessLevel =
    (selectors: GranularPrivilegesTableSelectorProps, targetId: string, accessLevel: string): IThunkAction =>
    (dispatch: IDispatch, getState: () => AdminState) => {
        if (selectors && !_.isEmpty(targetId) && !_.isEmpty(accessLevel)) {
            const availablePrivilegesByDomain = PrivilegesTableSelectors.getAvailablePrivilegesGroupedByDomain(
                getState(),
                selectors,
            );
            let granularPrivilege: PrivilegeModel;
            if (isBinaryDomain(selectors.domain)) {
                // Push document to sources is a privilege of type "Allow" which is not defined on platform side
                granularPrivilege = {
                    ...availablePrivilegesByDomain[selectors.domain][0],
                    targetId,
                };
            } else {
                granularPrivilege = {
                    ...availablePrivilegesByDomain[selectors.domain][0],
                    type: PrivilegeTypes.Edit,
                    targetId,
                };
            }

            if (accessLevel === PrivilegesAccessOptions.Edit || accessLevel === PrivilegesAccessOptions.AllowPush) {
                dispatch(PrivilegesListActions.addToList(selectors.AppliedPrivilegesList, [granularPrivilege]));
                dispatch(PrivilegesListActions.addToList(selectors.GranularPrivilegesList, [granularPrivilege]));
            } else if (
                accessLevel === PrivilegesAccessOptions.View ||
                accessLevel === PrivilegesAccessOptions.DenyPush
            ) {
                dispatch(PrivilegesListActions.removeFromList(selectors.AppliedPrivilegesList, [granularPrivilege]));
                dispatch(PrivilegesListActions.removeFromList(selectors.GranularPrivilegesList, [granularPrivilege]));
            }
        }
    };

const fillInPrivilegesList =
    (selectors: GranularPrivilegesTableSelectorProps): IThunkAction =>
    (dispatch: IDispatch, getState: () => AdminState) => {
        if (selectors) {
            const state: AdminState = getState();
            const appliedPrivileges = getAppliedPrivileges(state, selectors);
            const granularPrivileges = getGranularPrivileges(state, selectors);

            if (_.isEmpty(appliedPrivileges) && !_.isEmpty(granularPrivileges)) {
                dispatch(PrivilegesListActions.addToList(selectors.AppliedPrivilegesList, granularPrivileges));
            } else if (!_.isEmpty(appliedPrivileges) && _.isEmpty(granularPrivileges)) {
                dispatch(PrivilegesListActions.addToList(selectors.GranularPrivilegesList, appliedPrivileges));
            }
        }
    };

const resetRowToInitialState =
    (selectors: GranularPrivilegesTableSelectorProps, targetId: string): IThunkAction =>
    (dispatch: IDispatch, getState: () => AdminState) => {
        if (selectors && !_.isEmpty(targetId)) {
            const state = getState();
            const currentPrivileges = GranularPrivilegesTableSelectors.getAppliedPrivilegesByResourcesId(
                state,
                selectors,
            )[targetId];
            const initialPrivileges = GranularPrivilegesTableSelectors.getInitialPrivilegesByResourcesId(
                state,
                selectors,
            )[targetId];

            if (!_.isEmpty(currentPrivileges)) {
                dispatch(PrivilegesListActions.removeFromList(selectors.AppliedPrivilegesList, currentPrivileges));
                dispatch(PrivilegesListActions.removeFromList(selectors.GranularPrivilegesList, currentPrivileges));
            }
            if (!_.isEmpty(initialPrivileges)) {
                dispatch(PrivilegesListActions.addToList(selectors.AppliedPrivilegesList, initialPrivileges));
                dispatch(PrivilegesListActions.addToList(selectors.GranularPrivilegesList, initialPrivileges));
            }
        }
    };

export const GranularPrivilegesTableActions = {
    add,
    remove,
    setLoading,
    fetch,
    setAccessLevel,
    fillInPrivilegesList,
    resetRowToInitialState,
};
