import * as ReactVapor from '@coveord/plasma-react';
import _ from 'underscore';
import {
    StrictValidationActionsType,
    StrictValidationApplyPayload,
    StrictValidationDimensionFetchDonePayload,
    StrictValidationTogglePayload,
} from '../actions/StrictValidationActions';
import {StrictValidationDefaultState, StrictValidationState} from '../StrictValidationState';

const applyFetchDoneReducer = (
    state: StrictValidationState,
    action: ReactVapor.IReduxAction<StrictValidationApplyPayload>,
) => {
    state.applyState = _.extend({}, state.applyState, {
        result: action.payload.result,
        isFetched: true,
        isFailed: false,
    });
    return state;
};

const applyFetchFailReducer = (
    state: StrictValidationState,
    action: ReactVapor.IReduxAction<StrictValidationApplyPayload>,
) => {
    state.applyState = _.extend({}, state.applyState, {
        result: undefined,
        isFetched: true,
        isFailed: true,
    });
    return state;
};

const applyResetReducer = (
    state: StrictValidationState,
    action: ReactVapor.IReduxAction<StrictValidationApplyPayload>,
) => {
    state.applyState = _.clone(StrictValidationDefaultState.applyState);
    return state;
};

const dimensionFetchDoneReducer = (
    state: StrictValidationState,
    action: ReactVapor.IReduxAction<StrictValidationDimensionFetchDonePayload>,
) => {
    state.validatedDimensions = _.extend({}, state.validatedDimensions, {
        [action.payload.id]: {
            results: action.payload.results,
            isFetched: true,
            isFetching: false,
        },
    });
    return state;
};

const dimensionFetchFailReducer = (
    state: StrictValidationState,
    action: ReactVapor.IReduxAction<StrictValidationTogglePayload>,
) => {
    state.validatedDimensions = _.extend({}, state.validatedDimensions, {
        [action.payload.id]: {
            isFailed: true,
            isFetched: true,
            isFetching: false,
        },
    });
    return state;
};

const dimensionFetchStartedReducer = (state, action: ReactVapor.IReduxAction<StrictValidationTogglePayload>) => {
    state.validatedDimensions = _.extend({}, state.validatedDimensions, {
        [action.payload.id]: {
            isFailed: false,
            isFetched: false,
            isFetching: true,
        },
    });
    return state;
};

const saveStartedReducer = (
    state: StrictValidationState,
    action: ReactVapor.IReduxAction<StrictValidationTogglePayload>,
) => {
    state.applyState = _.extend({}, state.applyState, {
        saveStarted: true,
        saveDone: false,
        saveFailed: false,
        result: !state.applyState.result,
    });
    return state;
};

const saveDoneReducer = (
    state: StrictValidationState,
    action: ReactVapor.IReduxAction<StrictValidationTogglePayload>,
) => {
    state.applyState = _.extend({}, state.applyState, {
        saveStarted: false,
        saveDone: true,
        saveFailed: false,
    });
    return state;
};

const saveFailedReducer = (
    state: StrictValidationState,
    action: ReactVapor.IReduxAction<StrictValidationTogglePayload>,
) => {
    state.applyState = _.extend({}, state.applyState, {
        saveStarted: false,
        saveDone: true,
        saveFailed: true,
    });
    return state;
};

const StrictValidationActionReducers: {[key: string]: (...args: any[]) => any} = {
    [StrictValidationActionsType.ApplyFetchDone]: applyFetchDoneReducer,
    [StrictValidationActionsType.ApplyFetchFail]: applyFetchFailReducer,
    [StrictValidationActionsType.DimensionFetchStarted]: dimensionFetchStartedReducer,
    [StrictValidationActionsType.DimensionFetchDone]: dimensionFetchDoneReducer,
    [StrictValidationActionsType.DimensionFetchFail]: dimensionFetchFailReducer,
    [StrictValidationActionsType.SaveStarted]: saveStartedReducer,
    [StrictValidationActionsType.SaveDone]: saveDoneReducer,
    [StrictValidationActionsType.SaveFailed]: saveFailedReducer,
    [StrictValidationActionsType.ResetApplyState]: applyResetReducer,
};

export type StrictValidationActionPayload = StrictValidationApplyPayload | StrictValidationTogglePayload;

export const StrictValidationReducers = (
    state: StrictValidationState = StrictValidationDefaultState,
    action: ReactVapor.IReduxAction<StrictValidationActionPayload>,
) => {
    if (_.contains(_.keys(StrictValidationActionReducers), action.type)) {
        let newState: StrictValidationState = _.extend({}, state);
        newState = StrictValidationActionReducers[action.type](newState, action);
        return newState;
    }
    return state;
};
