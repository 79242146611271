import {PrivilegesLists} from '@core/user';
import {IDispatch, IItemBoxProps, ReduxConnect, SingleSelectConnected} from '@coveord/plasma-react'; // eslint-disable-line import/no-deprecated
import {PureComponent} from 'react';

import {AdminState} from '../../application/Reducers';
import {
    DefaultGroup,
    DefaultGroupsActions,
    DefaultGroupsSelectors,
    DefaultGroupsState,
} from '../../groups/DefaultGroups';
import {Locales} from '../../Locales';

export interface PrivilegesPresetChooserOwnProps {
    id: string;
    onPresetChangeCallback: (value: string) => void;
    supportsDefaultGroupsPresets?: boolean;
    disabled?: boolean;
    type?: PrivilegesLists;
}

export interface PrivilegesPresetChooserStateProps {
    defaultGroups: DefaultGroupsState;
}

export interface PrivilegesPresetChooserDispatchProps {
    onMount: () => void;
}

export interface PrivilegesPresetChooserProps
    extends PrivilegesPresetChooserOwnProps,
        Partial<PrivilegesPresetChooserStateProps>,
        Partial<PrivilegesPresetChooserDispatchProps> {}

const mapStateToProps = (state: AdminState): PrivilegesPresetChooserStateProps => ({
    defaultGroups: DefaultGroupsSelectors.getAll(state),
});

const mapDispatchToProps = (
    dispatch: IDispatch,
    ownProps: PrivilegesPresetChooserOwnProps,
): PrivilegesPresetChooserDispatchProps => ({
    onMount: () => {
        if (ownProps.supportsDefaultGroupsPresets) {
            dispatch(DefaultGroupsActions.fetch());
        }
    },
});

@ReduxConnect(mapStateToProps, mapDispatchToProps)
export class PrivilegesPresetChooser extends PureComponent<PrivilegesPresetChooserProps> {
    static Values = {
        Maximum: 'MAXIMUM',
        View: 'VIEW',
        Minimum: 'MINIMUM',
    };

    static ApiKeyPresets = {
        Minimum: 'MINIMUM',
        Admin: 'ADMIN',
        Search: 'SEARCH',
        AnonymousSearch: 'ANONYMOUS_SEARCH',
        Monitoring: 'MONITORING',
        Analytics: 'ANALYTICS',
    };

    static Diviver: IItemBoxProps = {
        value: 'divider-not-a-preset',
        divider: true,
    };

    static excludedDefaultGroups = ['administrators'];

    componentDidMount() {
        this.props.onMount();
    }

    render() {
        return (
            <SingleSelectConnected
                id={this.props.id}
                key={this.props.id}
                disabled={this.props.disabled}
                placeholder={Locales.format('CUSTOM')}
                onSelectOptionCallback={(preset: string) => this.props.onPresetChangeCallback(preset)}
                items={[...this.getStandardPresets(), ...this.getDefaultGroupsPresets()]}
                toggleClasses="privilege-preset-chooser"
                buttonPrepend={<span className="dropdown-prepend">{Locales.format('template.title') + ':'}</span>}
            />
        );
    }

    private getStandardPresets(): IItemBoxProps[] {
        return Object.values(
            this.props.type === PrivilegesLists.ApiKeys
                ? PrivilegesPresetChooser.ApiKeyPresets
                : PrivilegesPresetChooser.Values,
        ).map(
            (preset: string): IItemBoxProps => ({
                value: preset,
                displayValue: Locales.formatOrDefault(`privilegePreset_${preset}`, {
                    defaultTranslation: Locales.formatOrHumanize(preset),
                }),
            }),
        );
    }

    private getDefaultGroupsPresets(): IItemBoxProps[] {
        return this.props.supportsDefaultGroupsPresets
            ? [
                  PrivilegesPresetChooser.Diviver,
                  ..._.chain(this.props.defaultGroups)
                      .reject(({id}: DefaultGroup) => _.contains(PrivilegesPresetChooser.excludedDefaultGroups, id))
                      .map(
                          ({id, displayName}: DefaultGroup): IItemBoxProps => ({
                              value: id,
                              displayValue: `${displayName} ${Locales.format('template')}`,
                          }),
                      )
                      .value(),
              ]
            : [];
    }
}
