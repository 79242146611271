import {Config} from '@core/configuration';

export const SecurityCacheBasePath: string = '/content/security-identities';

export const SecurityCacheBaseUrl: string = `${Config.CloudPlatform.url}/rest/organizations/{organizationName}/securitycache/`;

export const SecurityCacheEvents: {[key: string]: string} = {
    Refresh: 'security-cache:refresh',
    Save: 'security-cache:save',
    Change: 'security-cache:change',
    CloseModal: 'security-cache:close',
};

export const SecurityProviderTypes = {
    ACTIVE_DIRECTORY2: 'ACTIVE_DIRECTORY2',
    EMAIL: 'EMAIL',
    EXPANDED: 'EXPANDED',
};

export const SecurityProviderStatusType = {
    PENDING_REFRESH: 'PENDING_REFRESH',
    REFRESHING: 'REFRESHING',
    IDLE: 'IDLE',
    ERROR: 'ERROR',
};

export const SecurityCachePanel = {
    providers: 'PROVIDER',
    identities: 'IDENTITIES',
};

export const SecurityProvidersTableId = 'securityProvidersTable';
export const SecurityIdentitiesTableId = 'securityIdentitiesTable';

export const SecurityCacheActionsStrings = {
    refreshNow: 'refreshSecurityCacheNowAction',
    stats: 'securityCacheStatsAction',
};

export const SecurityCacheActionTypes = {
    fetchProviders: 'PROVIDERS_LIST',
    fetchIdentities: 'IDENTITIES_LIST',
    fetchIdentitiesErrorCode: 'IDENTITIES_LIST_ERROR_CODE',
};

export const SecurityCacheRegions = {
    main: 'SECURITY_CACHE',
    stats: 'SECURITY_CACHE_GLOBAL_STATS',
    edit: 'SECURITY_PROVIDER_EDIT_JSON',
    schedules: 'SECURITY_PROVIDER_SCHEDULES',
    associatedSources: 'SECURITY_PROVIDER_ASSOCIATED_SOURCE',
    identities: 'SECURITY_PROVIDER_IDENTITIES',
};

export const SecurityProvidersRequestsNames = {
    fetch: 'SECURITY_PROVIDERS_FETCH',
    refresh: 'SECURITY_PROVIDER_REFRESH',
    createToken: 'CREATE_AUTH_TOKEN',
    cancel: 'SECURITY_PROVIDER_CANCEL',
};

export const SECURITY_CACHE_TOAST_DISMISS_TIMEOUT = 3000;

export const TableHeaderWithSortColumnName = {
    type: 'TYPE',
    name: 'NAME',
    currentStatus: 'CURRENT_STATUS',
    content: 'CONTENT',
};
