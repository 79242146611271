import {CheckboxSelectors, DatePickerSelectors, InputSelectors, SelectSelector} from '@coveord/plasma-react';

import {organizationSettingsConstants} from './organization/OrganizationSettingsConstants';
import {AdminState} from '../application/Reducers';
import {DetailsComponentConstants} from './license/details/DetailsComponentConstants';
import {OrganizationSettingsLicenseDatePicker} from '../settings/organization/OrganizationSettingsLicenseDatePicker';

const selectOrganizationDefinition = (state: AdminState) => state.organizationDefinition;

const selectOverrideId = (state: AdminState) => state.overrideId;

const selectProductType = (state: AdminState) =>
    SelectSelector.getSelectedValue(state, {id: DetailsComponentConstants.productTypeDropdownId});

const selectProductEdition = (state: AdminState) =>
    SelectSelector.getSelectedValue(state, {id: DetailsComponentConstants.productEditionDropdownId});

const selectExpirationDate = (state: AdminState) =>
    DatePickerSelectors.getDatePicker(state, {
        id: OrganizationSettingsLicenseDatePicker.Id,
    });

const selectOverrideStatements = (state: AdminState, multiSelectId: string) =>
    SelectSelector.getMultiSelectSelectedValues(state, {
        id: multiSelectId,
    });

const selectEditOverrideOperation = (state: AdminState) =>
    SelectSelector.getSelectedValue(state, {
        id: DetailsComponentConstants.overrideConfigurationOperationSingleSelectId,
    });

const selectEditOverrideKey = (state: AdminState) =>
    SelectSelector.getSelectedValue(state, {
        id: DetailsComponentConstants.overrideConfigurationKeySingleSelectId,
    });

const selectEditOverrideValue = (state: AdminState) =>
    SelectSelector.getSelectedValue(state, {
        id: DetailsComponentConstants.overrideConfigurationValueSingleSelectId,
    });

const selectOrganizationDisplayName = (state: AdminState) =>
    InputSelectors.getValue(state, {
        id: organizationSettingsConstants.displayNameInputId,
    });

const selectOrganizationContactEmail = (state: AdminState) =>
    InputSelectors.getValue(state, {
        id: organizationSettingsConstants.ownerEmailInputId,
    });

const selectIsAddOnChecked = (state: AdminState, addOn: string) =>
    CheckboxSelectors.getIsSelected(state, {
        id: addOn,
    });

const selectOrganizationExperimentalStatus = (state: AdminState): boolean =>
    CheckboxSelectors.getIsSelected(state, {
        id: organizationSettingsConstants.experimentalStatusCheckboxId,
    });

export const SettingsSelectors = {
    selectProductType,
    selectProductEdition,
    selectExpirationDate,
    selectOrganizationDefinition,
    selectOverrideId,
    selectOverrideStatements,
    selectEditOverrideOperation,
    selectEditOverrideKey,
    selectEditOverrideValue,
    selectOrganizationDisplayName,
    selectOrganizationContactEmail,
    selectIsAddOnChecked,
    selectOrganizationExperimentalStatus,
};
