import {DateRangeAttributes} from '@coveord/jsadmin-common';
import {IItemBoxProps, IReduxAction} from '@coveord/plasma-react';

export const EditVisitMetricTimeseriesActionsType = {
    Initialize: 'EDIT_VISIT_METRIC_TIMESERIES_INITIALIZE',
    ApplyChanges: 'EDIT_VISIT_METRIC_TIMESERIES_APPLY_CHANGES',
    SetFilters: 'EDIT_VISIT_METRIC_TIMESERIES_SET_FILTERS',
    SetExclusionFilters: 'EDIT_VISIT_METRIC_TIMESERIES_SET_EXCLUSION_FILTERS',
    SetUseExclusionFilters: 'EDIT_VISIT_METRIC_TIMESERIES_SET_USE_EXCLUSION_FILTERS',
};

export interface EditVisitMetricTimeseriesFullPayload {
    id: string;
    title: string;
    filters: string[];
    exclusionFilters: string[];
    eventsExclusion: string[];
    useExclusionFilters: boolean;
    useEventsExclusion: boolean;
    metrics: IItemBoxProps[];
    headerHref: string;
    headerHrefs: IItemBoxProps[];
    displayAs: string;
    showMetrics: boolean;
    showAverage: boolean;
    showPeak: boolean;
    showTrend: boolean;
    dateRange: DateRangeAttributes;
}

const initialize = (
    payload: EditVisitMetricTimeseriesFullPayload,
): IReduxAction<EditVisitMetricTimeseriesFullPayload> => ({
    type: EditVisitMetricTimeseriesActionsType.Initialize,
    payload,
});

export interface EditVisitMetricTimeseriesSetFiltersPayload {
    filters: string[];
    exclusionFilters: string[];
    eventsExclusion: string[];
    useExclusionFilters: boolean;
    useEventsExclusion: boolean;
}

const setFilters = (
    payload: EditVisitMetricTimeseriesSetFiltersPayload,
): IReduxAction<EditVisitMetricTimeseriesSetFiltersPayload> => ({
    type: EditVisitMetricTimeseriesActionsType.SetFilters,
    payload,
});

export const EditVisitMetricTimeseriesActions = {
    initialize,
    setFilters,
};
