import {SelectSelector} from '@coveord/plasma-react';
import {createSelector} from 'reselect';
import {AdminState} from '../../../../application/Reducers';
import {DefaultLogicalIndex, SourcesConstants} from '../../../SourcesConstants';
import {LogicalIndex} from './LogicalIndexActions';

type Id = {id: string};
export type AvailableIndexes = Record<string, LogicalIndex>;

const getAvailableIndexes = createSelector(
    [(state: AdminState): AvailableIndexes => state.logicalIndex?.availableIndexes],
    (availableIndexes) => availableIndexes ?? {},
);

const getName = (state: AdminState, indexId: Id): string => {
    const logicalIndex: LogicalIndex =
        getAvailableIndexes(state) && getAvailableIndexes(state)[indexId.id || 'default'];
    return (logicalIndex && logicalIndex.name) || '';
};

const getValue = (state: AdminState): string =>
    SelectSelector.getListBoxSelected(state, {id: SourcesConstants.Components.LogicalIndexDropdown})?.[0] ||
    DefaultLogicalIndex;

export const LogicalIndexSelectors = {
    getAvailableIndexes,
    getName,
    getValue,
};
