import {PlatformPaths} from '@configurations/platform';
import {RouteObject} from '@core/routes';

export const routes: RouteObject[] = [
    {
        path: `${PlatformPaths.Conditions.fullPath}/*`,
        lazy: async () => {
            const {ConditionsPage} = await import('../pages');
            return {Component: ConditionsPage};
        },
    },
];
