import {InsightPanelConfigModel} from '@core/api';
import {ObjectReducerGenerator} from '@core/store';
import {combineReducers} from 'redux';
import {EditInsightPanelConfigActionTypes} from './EditInsightPanelConfigActions';

export interface IEditInsightPanelConfigState {
    savedConfig: Partial<InsightPanelConfigModel>;
}

const initialState: IEditInsightPanelConfigState = {
    savedConfig: {},
};

const updateSavedInsightPanelConfigReducer = ObjectReducerGenerator<Partial<InsightPanelConfigModel>>(
    EditInsightPanelConfigActionTypes.updateInsightPanelConfig,
    initialState.savedConfig,
);

export const editInsightPanelConfigReducer = combineReducers({
    savedConfig: updateSavedInsightPanelConfigReducer,
});
