import {
    CaseClassifications,
    ContextField,
    DocumentSuggestions,
    Platform,
    PlatformNoHandlers,
    PreviewRequestBody,
    SuggestionRequestBody,
} from '@core/api';
import {CookieUtils} from '@core/authentication';
import {ObjectKeyValueActions} from '@core/store';
import {RequestsActions} from '@coveord/jsadmin-common';
import {IThunkAction} from '@coveord/plasma-react';
import {ServiceState} from '../../../ServiceState';
import {EditCaseAssistConfigHeaderTabs, EditCaseAssistConfigSelectors} from '../EditCaseAssistConfigSelectors';
import {CaseClassificationSelectors} from '../caseClassificationTab/CaseClassificationSelectors';
import {DocumentSuggestionSelectors} from '../documentSuggestionTab/DocumentSuggestionSelectors';
import {CaseAssistPreviewInitialState} from './CaseAssistPreviewConstants';
import {CaseAssistPreviewSelectors} from './CaseAssistPreviewSelectors';

export const CaseAssistPreviewActionTypes = {
    updateSubject: 'UPDATE_CASE_ASSIST_PREVIEW_SUBJECT',
    updateDescription: 'UPDATE_CASE_ASSIST_PREVIEW_DESCRIPTION',
    updateDocumentSuggestions: 'UPDATE_DOCUMENT_SUGGESTIONS_PREVIEW',
    updateCaseClassifications: 'UPDATE_CASE_CLASSIFICATIONS_PREVIEW',
    fetchDocumentSuggestions: 'FETCH_DOCUMENT_SUGGESTIONS_PREVIEW',
    fetchCaseClassifications: 'FETCH_CASE_CLASSIFICATIONS_PREVIEW',
};

const toPreviewRequestBody = (
    state: ServiceState,
    tabSection: EditCaseAssistConfigHeaderTabs,
    canEditConfig: boolean,
): PreviewRequestBody => ({
    visitorId: CookieUtils.getVisitorId() ?? 'n/a',
    locale: navigator.language,
    fields: CaseAssistPreviewSelectors.getCaseFields(state),
    configuration: EditCaseAssistConfigSelectors.getCaseAssistConfig(state, tabSection, canEditConfig),
});

const toSuggestionRequestBody = (state: ServiceState): SuggestionRequestBody => ({
    visitorId: CookieUtils.getVisitorId() ?? 'n/a',
    locale: navigator.language,
    fields: CaseAssistPreviewSelectors.getCaseFields(state),
});

const updateSubject = (subject: string) => (dispatch) =>
    dispatch(ObjectKeyValueActions.update<ContextField>(CaseAssistPreviewActionTypes.updateSubject, {value: subject}));

const updateDescription = (description: string) => (dispatch) =>
    dispatch(
        ObjectKeyValueActions.update<ContextField>(CaseAssistPreviewActionTypes.updateDescription, {
            value: description,
        }),
    );

const updateDocumentSuggestions = (documentSuggestions: DocumentSuggestions) => (dispatch) =>
    dispatch(
        ObjectKeyValueActions.update<DocumentSuggestions>(
            CaseAssistPreviewActionTypes.updateDocumentSuggestions,
            documentSuggestions,
        ),
    );

const updateCaseClassifications = (caseClassifications: CaseClassifications) => (dispatch) =>
    dispatch(
        ObjectKeyValueActions.update<CaseClassifications>(
            CaseAssistPreviewActionTypes.updateCaseClassifications,
            caseClassifications,
        ),
    );

const clearPreviews = (): IThunkAction<void, ServiceState> => async (dispatch) => {
    dispatch(CaseAssistPreviewActions.updateSubject(CaseAssistPreviewInitialState.fields.subject.value));
    dispatch(CaseAssistPreviewActions.updateDescription(CaseAssistPreviewInitialState.fields.description.value));
    dispatch(CaseAssistPreviewActions.updateDocumentSuggestions(CaseAssistPreviewInitialState.documentSuggestions));
    dispatch(CaseAssistPreviewActions.updateCaseClassifications(CaseAssistPreviewInitialState.caseClassifications));
};

const clearPreviewClassifications = (): IThunkAction<void, ServiceState> => async (dispatch) => {
    dispatch(CaseAssistPreviewActions.updateCaseClassifications(CaseAssistPreviewInitialState.caseClassifications));
};

const fetchDocumentSuggestions =
    (withHandlers = true, canEditConfig: boolean = false): IThunkAction<void, ServiceState> =>
    async (dispatch, getState) => {
        const client = withHandlers ? Platform.caseAssistConfig : PlatformNoHandlers.caseAssistConfig;
        const state = getState();
        const retrieveDocumentSuggestions = canEditConfig
            ? async () =>
                  await client.previewDocumentSuggestion(
                      toPreviewRequestBody(state, EditCaseAssistConfigHeaderTabs.DocumentSuggestion, canEditConfig),
                  )
            : async () =>
                  await client.suggestDocuments(
                      EditCaseAssistConfigSelectors.getCaseAssistConfigId(state),
                      toSuggestionRequestBody(state),
                  );

        if (!DocumentSuggestionSelectors.isValidConfiguration(state, canEditConfig)) {
            return null;
        }
        dispatch(RequestsActions.request(CaseAssistPreviewActionTypes.fetchDocumentSuggestions));

        const makeRequest = async () => {
            const documentSuggestions = await retrieveDocumentSuggestions();
            dispatch(updateDocumentSuggestions(documentSuggestions));
            return documentSuggestions;
        };
        return dispatch(RequestsActions.handle(CaseAssistPreviewActionTypes.fetchDocumentSuggestions, makeRequest));
    };

const fetchCaseClassifications =
    (withHandlers = true, canEditConfig: boolean = false): IThunkAction<void, ServiceState> =>
    async (dispatch, getState) => {
        const client = withHandlers ? Platform.caseAssistConfig : PlatformNoHandlers.caseAssistConfig;
        const state = getState();
        const retrieveClassifications = canEditConfig
            ? async () =>
                  await client.previewCaseClassication(
                      toPreviewRequestBody(state, EditCaseAssistConfigHeaderTabs.CaseClassification, canEditConfig),
                  )
            : async () =>
                  await client.classify(
                      EditCaseAssistConfigSelectors.getCaseAssistConfigId(state),
                      toSuggestionRequestBody(state),
                  );

        if (!CaseClassificationSelectors.isValidConfiguration(state, canEditConfig)) {
            return null;
        }

        dispatch(RequestsActions.request(CaseAssistPreviewActionTypes.fetchCaseClassifications));

        const makeRequest = async () => {
            const caseClassifications = await retrieveClassifications();

            dispatch(updateCaseClassifications(caseClassifications));
            return caseClassifications;
        };

        return dispatch(RequestsActions.handle(CaseAssistPreviewActionTypes.fetchCaseClassifications, makeRequest));
    };

export const CaseAssistPreviewActions = {
    updateSubject,
    updateDescription,
    updateDocumentSuggestions,
    updateCaseClassifications,
    clearPreviews,
    clearPreviewClassifications,
    fetchDocumentSuggestions,
    fetchCaseClassifications,
};
