import _ from 'underscore';

import {KeyCode} from '../Constants';
import {Channels, Radio} from '../radio/Radio';
import {WidgetEvents} from '../widgets/WidgetEvents';

$.fn.CoveoFocusFirstInput = function () {
    $('input[type="text"], textarea, [tabindex], button', $(this))
        .filter(':not([readonly], [disabled]):first, .no-focus-first')
        .focus();
    return this;
};

$.fn.CoveoOnTransitionDone = function (callback: (...args: any[]) => any): JQuery {
    const element: HTMLElement = this[0];

    const transitions = {
        WebkitTransition: 'webkitTransitionEnd',
        MozTransition: 'transitionend',
        OTransition: 'oTransitionEnd otransitionend',
        transition: 'transitionend',
    };

    let transitionEndEventName = '';
    for (const t in transitions) {
        if (element.style[t] !== undefined) {
            transitionEndEventName = transitions[t];
            break;
        }
    }

    this.one(transitionEndEventName, () => {
        callback();
    });

    return this;
};

$.fn.CoveoTooltip = function (options?: TooltipOptions, replaceTitle = true) {
    const elements: JQuery = this;
    if (!_.isUndefined(options.title) && replaceTitle) {
        elements.attr('title', options.title as string);
        elements.removeAttr('data-original-title');
        elements.removeData('bs.tooltip');
    }
    return elements.tooltip('destroy').tooltip(options).tooltip('fixTitle');
};

($.fn as any).getCaretPosition = function (): number {
    return this.get(0).selectionStart;
};

($.fn as any).setCaretPosition = function (position: number) {
    this.focus();
    this.get(0).selectionStart = position;
    this.get(0).selectionEnd = position;
    return this;
};

$.fn.getSelector = function () {
    const $el = $(this);
    if ($el.attr('id')) {
        return '#' + $el.attr('id');
    } else {
        return '.' + $el.attr('class').split(' ').join('.');
    }
};

Marionette.Region.prototype.reset = function () {
    this.empty();

    if (this.$el && this.$el.get(0)) {
        this.el = this.$el.getSelector();
    }

    delete this.$el;
    return this;
};

/*
 * getStyleObject Plugin for jQuery JavaScript Library
 * From: http://upshots.org/?p=112
 *
 * Copyright: Unknown, see source link
 * Plugin version by Dakota Schneider (http://hackthetruth.org)
 */
$.fn.getStyleObject = function () {
    const dom = this.get(0);
    let style;
    const returns = {};
    if (window.getComputedStyle) {
        const camelize = (a, b) => b.toUpperCase();
        style = window.getComputedStyle(dom, null);
        for (let i = 0; i < style.length; i++) {
            const prop = style[i];
            const camel = prop.replace(/\-([a-z])/g, camelize);
            const val = style.getPropertyValue(prop);
            returns[camel] = val;
        }
        return returns;
    }
    if (dom.currentStyle) {
        style = dom.currentStyle;
        /* eslint-disable-next-line */
        for (const prop in style) {
            returns[prop] = style[prop];
        }
        return returns;
    }
    return this.css();
};

$.fn.hideOnClickOutside = function (options: HideOnClickOutsideParams = {}) {
    const channel = Radio.channel(Channels.Widgets);
    const element: any = this;
    const stopPropagation = _.isUndefined(options.stopPropagation) ? true : options.stopPropagation;

    const escapeHandler = (event: JQuery.TriggeredEvent) => {
        if (event.keyCode === KeyCode.ESCAPE) {
            hideHandler(event);
        }
    };

    const defaultHideHandler = (event: JQuery.TriggeredEvent) => {
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }
        if (options.hideHandler) {
            options.hideHandler(event);
        }

        element.addClass('hidden');
        $(document).off('keydown', escapeHandler);
    };

    const hidePopup = (event: JQuery.TriggeredEvent) => {
        // clicked element is not a child of the popup
        const $el = $(event.target);
        const popup = $(element.selector);
        if ($el.is(':visible') && !element.is($el)) {
            if (popup.length && popup.is(':visible') && popup.find($el).length === 0) {
                defaultHideHandler(event);
            } else if (element.is(':visible') && element.find($el).length === 0) {
                defaultHideHandler(event);
            }
        }
    };

    const defaultShowHandler = (event: JQuery.TriggeredEvent) => {
        element.removeClass('hidden');
        $(document).on('keydown', escapeHandler);
        $(document).on('click', hidePopup);

        if (options.showHandler) {
            options.showHandler(event);
        }
    };

    const toggleButton = options.toggleButton;
    const showHandler: (...args: any[]) => any = defaultShowHandler;
    const hideHandler: (...args: any[]) => any = defaultHideHandler;

    if (toggleButton && toggleButton.length) {
        toggleButton.click((event: JQuery.TriggeredEvent) => {
            if (element.is(':visible')) {
                defaultHideHandler(event);
            } else {
                channel.emit(WidgetEvents.Open, element);
                if (stopPropagation) {
                    event.stopPropagation();
                    event.stopImmediatePropagation();
                    event.preventDefault();
                }
                showHandler(event);
            }
        });
    }
    channel.on(WidgetEvents.Open, (el) => {
        if (!element.is(el) && element.find(el).length === 0 && element.is(':visible')) {
            hideHandler();
        }
    });

    element.open = () => {
        showHandler();
    };

    element.close = () => {
        hideHandler();
    };

    return element;
};

$.fn.setHeightOnHover = function (options?: SetHeightOnHoverParams) {
    const dataAttributeKey = 'full-height';
    const childSelector = options && options.childSelector ? options.childSelector : '';
    const classToAdd = options && options.addClassOnClone ? options.addClassOnClone : '';
    const elements: any = this;
    let timeout: number = 0;

    elements.mouseenter(function () {
        const $el = childSelector ? $(this).find(childSelector) : $(this);

        if (!$el.data(dataAttributeKey)) {
            timeout = window.setTimeout(() => {
                const clone = $el.clone();
                $el.parent().append(clone);
                clone.addClass(classToAdd);
                clone.css({height: 'auto'});
                const height = clone.height();
                $el.data(dataAttributeKey, height);
                $el.css({height: height + 'px'});
                clone.remove();
            }, 50);
        } else {
            $el.get(0).style.height = $el.data(dataAttributeKey) + 'px';
        }
    });

    elements.mouseleave(function () {
        const $el = childSelector ? $(this).find(childSelector) : $(this);

        $el.get(0).style.height = '';
        clearTimeout(timeout);
    });

    return elements;
};

export class JQueryUtils {
    static isMatchingSelectors(target: Element, matchingSelectors: string[] = []) {
        return _.some(
            matchingSelectors,
            (selector: string) => $(target).is(selector) || $(target).parents(selector).length > 0,
        );
    }

    static toJqueryPromise<T>(promise: Promise<T>): JQueryPromise<T> {
        const deferred = $.Deferred<T>();
        promise.then(deferred.resolve).catch(deferred.reject);
        return deferred.promise();
    }

    static toES6Promise<T>(jqPromise: JQueryPromise<T> | JQueryDeferred<T>): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            jqPromise.done(resolve).fail(reject);
        });
    }
}

export type WhenAjaxResponse<T> = [T, string, XMLHttpRequest];
