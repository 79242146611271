export const ComponentIdMap = {
    AddCardComponent: 'AddCard',
    BarChartComponent: 'BarChart',
    PackedBubbleComponent: 'PackedBubbleChart',
    CalculatedMetricComponent: 'CalculatedMetric',
    EditCalculatedMetricComponent: 'EditCalculatedMetric',
    NoteCardComponent: 'NoteCard',
    EditNoteCardComponent: 'EditNoteCard',
    MetricTimeseriesComponent: 'Chart',
    VisitMetricTimeseriesComponent: 'VisitMetricTimeseries',
    EditVisitMetricTimeseriesComponent: 'EditVisitMetricTimeseries',
    DimensionTimeseriesComponent: 'DimensionChart',
    EditDimensionTimeseriesComponent: 'EditDimensionChart',
    PieChartComponent: 'PieChart',
    DetailedStatisticsComponent: 'DetailedStatistics',
    QuickMetricComponent: 'QuickMetric',
    TrendComponent: 'Trend',
    ChordComponent: 'Chord',
    DashboardComponent: 'DASHBOARD',
    ExplorerComponent: 'EXPLORER',
    FilterComponent: 'Filters',
    MultiFilterComponent: 'MultiFilters',
    QuickExplorerComponent: 'QuickExplorer',
    VisitBrowserComponent: 'AnalyticsVisitBrowser',
    DimensionsComponent: 'AnalyticsDimensionManager',
    ExportTabsComponent: 'AnalyticsExportTabs',
    ExportComponent: 'AnalyticsExport',
    ExportScheduleComponent: 'AnalyticsExportSchedule',
    ViewScheduleExportsComponent: 'AnalyticsViewScheduleExports',
    EditExportComponent: 'AnalyticsEditExport',
    EditExportScheduleComponent: 'AnalyticsEditExportSchedule',
    EditFiltersComponent: 'AnalyticsEditFilters',
    EditPermissionFiltersComponent: 'AnalyticsEditPermissionFilters',
    NamedFiltersComponent: 'AnalyticsNamedFilterManager',
    EditNamedFiltersComponent: 'AnalyticsEditNamedFilters',
    PermissionFiltersComponent: 'AnalyticsPermissionFilterManager',
    PredefinedFiltersComponent: 'AnalyticsPredefinedFilterManager',
    ManageUserFiltersComponent: 'AnalyticsManageUserFilters',
    ManageUserReportsComponent: 'AnalyticsManageUserReports',
    ReportsComponent: 'AnalyticsReports',
    VisitMetricComponent: 'VisitMetric',
    EditVisitComponent: 'EditVisitMetric',
    MapCardComponent: 'Map',
    EditMapCardComponent: 'EditMap',
    UnsupportedCardComponent: 'UnsupportedCard',
    PermissionFiltersAccess: 'PermissionFiltersAccess',
    RawData: 'RawData',
};
