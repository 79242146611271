import {LicenseModel} from '@core/api';
import {IReduxAction} from '@coveord/plasma-react';

enum ActionsType {
    UPDATE_LICENSE = 'UPDATE_LICENSE',
    FETCH_LIMITS = 'FETCH_LIMITS',
    UPDATE_LIMITS = 'UPDATE_LIMITS',
}

export interface LicenseActionsPayload {
    license: LicenseModel;
}

const update = (license: LicenseModel): IReduxAction<LicenseActionsPayload> => ({
    type: ActionsType.UPDATE_LICENSE,
    payload: {license},
});

export const LicenseActions = {
    ActionsType,
    update,
};
