export const baseCommercePath: string = '/commerce/';

export enum CommercePathsMap {
    catalogs = 'catalogs',
    createCatalog = 'catalogs/create',
    editCatalog = 'catalogs/edit/:id',
    configurations = 'catalogs/configurations',
    editCatalogConfiguration = 'configurations/edit/:id',
    productListingCatalogs = 'productlistings',
    productListingsForCatalog = 'productlistings/catalogs/:id',
    createProductListing = 'productlistings/create',
    createApiKey = 'catalogs/apikey/:id',
}

export const CommercePathsGenerators = {
    catalogs: CommercePathsMap.catalogs,
    createCatalog: CommercePathsMap.createCatalog,
    editCatalog: (catalogId: string) => CommercePathsMap.editCatalog.replace(':id', catalogId),
    configurations: CommercePathsMap.configurations,
    editCatalogConfiguration: (configurationId: string) =>
        CommercePathsMap.editCatalogConfiguration.replace(':id', configurationId),
    productListingCatalogs: CommercePathsMap.productListingCatalogs,
    productListingsForCatalog: (catalogId: string) =>
        CommercePathsMap.productListingsForCatalog.replace(':id', catalogId),
    createProductListing: CommercePathsMap.createProductListing,
    createApiKey: (catalogId: string) => CommercePathsMap.createApiKey.replace(':id', catalogId),
};
