export const Channels = {
    AddCardComponent: 'add-card',
    Dashboard: 'dashboard',
    ExportTabsComponent: 'export-tabs',
    EditExportComponent: 'edit-export',
    EditExportScheduleComponent: 'edit-export-schedule',
    EditFiltersComponent: 'edit-filters',
    ReportAccessComponent: 'report-access',
    ChartComponent: 'base-chart',
    SettingsComponent: 'settings',
    VisitBrowserComponent: 'visit-browser',
};
