import _ from 'underscore';
import {commonLocales} from '../CommonLocales';
import {UnknownUnitError} from './UnknownUnitError';

export const MetricFileSizeUnits = {
    Kilobit: 'kB',
    Megabit: 'MB',
    Gigabit: 'GB',
    Terabit: 'TB',
    Petabit: 'PB',
    Exabit: 'EB',
    Zettabit: 'ZB',
    Yottabit: 'YB',
};

export const BinaryFileSizeUnits = {
    Kilobyte: 'KiB',
    Megabyte: 'MiB',
    Gigabyte: 'GiB',
    Terabyte: 'TiB',
    Petabyte: 'PiB',
    Exabyte: 'EiB',
    Zettabyte: 'ZiB',
    Yottabyte: 'YiB',
};

export const METRIC_FILE_SIZE_THRESHOLD = 1000;
export const BINARY_FILE_SIZE_THRESHOLD = 1024;

export class Numbers {
    static formatHumanFileSize(bytes: number, si: boolean, useTheWrongUnitForPeopleSatisfaction = false) {
        if (!_.isUndefined(bytes)) {
            const thresh = si ? METRIC_FILE_SIZE_THRESHOLD : BINARY_FILE_SIZE_THRESHOLD;
            if (bytes < thresh) {
                return bytes + ' B';
            }
            const units =
                si || useTheWrongUnitForPeopleSatisfaction
                    ? _.values(MetricFileSizeUnits)
                    : _.values(BinaryFileSizeUnits);
            let u = -1;
            do {
                bytes /= thresh;
                ++u;
            } while (bytes >= thresh);

            return bytes.toFixed(1) + ' ' + units[u];
        } else {
            return commonLocales.format('notApplicable').toUpperCase();
        }
    }

    static computeHumanFileSize(bytes: number, si: boolean, unit: string) {
        const threshold = si ? METRIC_FILE_SIZE_THRESHOLD : BINARY_FILE_SIZE_THRESHOLD;
        const index = _.indexOf(_.values(si ? MetricFileSizeUnits : BinaryFileSizeUnits), unit);
        if (index < 0) {
            throw new UnknownUnitError(`The following unit is unknown: ${unit}.`);
        }

        return bytes / Math.pow(threshold, index + 1);
    }

    static toFixed(value) {
        if (value && value.toString().indexOf('.') !== -1) {
            return parseFloat(value).toFixed(2);
        }
        return value;
    }

    static getPercentage(processed: number, total: number): number {
        if (total !== 0) {
            const percentage = Math.round((processed / total) * 100);
            return Math.min(100, percentage);
        }
        return 0;
    }

    static roundTo(val: number, multiple: number = 1): number {
        // + 0 prevents the method from returning '-0'
        return Math.round(val / multiple) * multiple + 0;
    }

    static toLogarithm(value: number, minValue: number, maxValue: number) {
        if (value <= 0) {
            return 0;
        } else {
            const minv = Math.log(minValue);
            const maxv = Math.log(maxValue);
            const scale = (maxv - minv) / 100;

            return Math.exp(minv + scale * value);
        }
    }

    static fromLogarithm(value: number, minValue: number, maxValue: number) {
        if (value === 0) {
            return 0;
        } else {
            const minv = Math.log(minValue);
            const maxv = Math.log(maxValue);
            const scale = (maxv - minv) / 100;

            return (Math.log(value) - minv) / scale;
        }
    }

    static isInteger(value: number): boolean {
        return isFinite(value) && Math.floor(value) === value;
    }

    static sum(array: any[] = [], iteratee: (item: any) => number = (item) => item): number {
        return array
            .map((item) => iteratee(item))
            .filter(_.isNumber)
            .reduce((memo, num) => memo + num, 0);
    }

    static average(array: any[] = [], iteratee: (item: any) => number = (item) => item): number {
        const sum: number = this.sum(array, iteratee);
        const count: number = this.sum(array, () => 1);
        return sum / count;
    }
}
