import {DateRangeAttributes} from '@coveord/jsadmin-common';
import {IItemBoxProps, IReduxAction} from '@coveord/plasma-react';

export const EditDimensionTimeseriesActionsType = {
    Initialize: 'EDIT_DIMENSION_TIMESERIES_INITIALIZE',
    Clear: 'EDIT_DIMENSION_TIMESERIES_CLEAR',
    ApplyChanges: 'EDIT_DIMENSION_TIMESERIES_APPLY_CHANGES',
    SetTitle: 'EDIT_DIMENSION_TIMESERIES_SET_TITLE',
    SetFilters: 'EDIT_DIMENSION_TIMESERIES_SET_FILTERS',
    SetHeaderHref: 'EDIT_DIMENSION_TIMESERIES_SET_HEADER_HREF',
};

export interface EditDimensionTimeseriesActionPayload {
    // empty
}

export interface EditDimensionTimeseriesFullPayload {
    id: string;
    title: string;
    filters: string;
    metrics: IItemBoxProps[];
    dimensions: IItemBoxProps[];
    headerHref: string;
    headerHrefs: IItemBoxProps[];
    displayAs: string;
    showMetrics: boolean;
    showAverage: boolean;
    showPeak: boolean;
    showTrend: boolean;
    dateRange: DateRangeAttributes;
}

const initialize = (payload: EditDimensionTimeseriesFullPayload): IReduxAction<EditDimensionTimeseriesFullPayload> => ({
    type: EditDimensionTimeseriesActionsType.Initialize,
    payload,
});

const clear = (): IReduxAction<EditDimensionTimeseriesActionPayload> => ({
    type: EditDimensionTimeseriesActionsType.Clear,
    payload: {},
});

export interface EditDimensionTimeseriesSetTitlePayload {
    title: string;
}

const setTitle = (title: string): IReduxAction<EditDimensionTimeseriesSetTitlePayload> => ({
    type: EditDimensionTimeseriesActionsType.SetTitle,
    payload: {title},
});

export interface EditDimensionTimeseriesSetFiltersPayload {
    filters: string;
}

const setFilters = (filters: string): IReduxAction<EditDimensionTimeseriesSetFiltersPayload> => ({
    type: EditDimensionTimeseriesActionsType.SetFilters,
    payload: {filters},
});

export interface EditDimensionTimeseriesSetHeaderHrefPayload {
    headerHref: string;
}

const setHeaderHref = (headerHref: string): IReduxAction<EditDimensionTimeseriesSetHeaderHrefPayload> => ({
    type: EditDimensionTimeseriesActionsType.SetHeaderHref,
    payload: {headerHref},
});

export const EditDimensionTimeseriesActions = {
    initialize,
    clear,
    setTitle,
    setFilters,
    setHeaderHref,
};
