import {DayjsCalendarTimeMissingFormat, DayjsMissingFormat, DayjsRelativeTimeMissingFormat} from '../implementation';
import {formatLocale, missingCompact, missingFull} from './sharedConfig';

/**
 * Contains default Admin-UI Date formatters.
 * Note that many formatters come in multiple "flavors": short, medium, long or full, depending on
 * how detailed they represent date/time. Which one you should use depends on the available space.
 * For example, prefer short/medium for limited space areas, and long/full for tooltips and/or table cells.
 * Not every cross-combination of detail level and length-flavor is available.
 */
export const DateTimeFormatter = Object.freeze({
    /**
     * The locale used by these formatters.
     */
    formatLocale,

    /**
     * Formatter for `Date` values of which only the date (year/month/day) part is relevant, using compact representation.
     */
    dateShort: new DayjsMissingFormat({missing: missingCompact, pattern: 'L'}),

    /**
     * Formatter for `Date` values of which only the date (year/month/day) part is relevant, using a medium-length representation.
     */
    dateMedium: new DayjsMissingFormat({missing: missingCompact, pattern: 'll'}),

    /**
     * Formatter for `Date` values of which only the date (year/month/day) part is relevant, using long representation.
     */
    dateLong: new DayjsMissingFormat({missing: missingFull, pattern: 'LL'}),

    /**
     * Formatter for `Date` values of which both the date (year/month/day) and time (hour/minute) part is relevant, using compact representation.
     */
    dateTimeShort: new DayjsMissingFormat({missing: missingCompact, pattern: 'lll'}),

    /**
     * Formatter for `Date` values of which both the date (year/month/day) and time (hour/minute) part is relevant, using full representation.
     */
    dateTimeMedium: new DayjsMissingFormat({missing: missingCompact, pattern: 'LLL'}),

    /**
     * Formatter for `Date` values of which both the date (year/month/day) and time (hour/minute) part is relevant, using long representation (includes short day).
     */
    dateTimeLong: new DayjsMissingFormat({missing: missingFull, pattern: 'llll'}),

    /**
     * Formatter for `Date` values of which both the date (year/month/day) and time (hour/minute) part is relevant, using full representation (includes long day).
     */
    dateTimeFull: new DayjsMissingFormat({missing: missingFull, pattern: 'LLLL'}),

    // dateTimeLong/dateTimeFull reserved for values including seconds; there are no locale sensitive patterns for this in dayjs.

    /**
     * Formatter for `Date` values of which only the time (hour/minute) part is relevant, using compact representation.
     */
    timeShort: new DayjsMissingFormat({missing: missingCompact, pattern: 'LT'}),

    /**
     * Formatter for `Date` values of which only the time (hour/minute/second) part is relevant, using full representation.
     */
    timeMedium: new DayjsMissingFormat({missing: missingCompact, pattern: 'LTS'}),

    /**
     * Formatter for `Date` values that represent a timestamp, up to second precision,
     * displayed as "wallclock time". Note that this uses a technical representation,
     * unaffected by the user's locale.
     */
    timestampShort: new DayjsMissingFormat({missing: missingCompact, pattern: 'YYYY-MM-DD HH:mm:ss'}),

    // timestampMedium reserved for timestampShort + offset

    /**
     * Formatter for `Date` values that represent a timestamp, up to millisecond precision,
     * displayed as "wallclock time". Note that this uses a technical representation,
     * unaffected by the user's locale.
     */
    timestampLong: new DayjsMissingFormat({missing: missingFull, pattern: 'YYYY-MM-DD HH:mm:ss.SSS'}),

    // timestampFull reserved for timestampLong + offset

    /**
     * Date represented as ISO-8601, meant as a "technical formatter" to format values for API calls.
     * The date is emitted in the time zone of the value, which defaults to the user's time zone.
     */
    isoDate: new DayjsMissingFormat({missing: '', pattern: 'YYYY-MM-DD'}),

    /**
     * Timestamp represented as ISO-8601, meant as a "technical formatter" to format values for API calls.
     * This differs from {@link Dayjs#toISOString} by persisting the offset, instead of emitting the value in UTC.
     */
    isoTimestamp: new DayjsMissingFormat({missing: '', pattern: 'YYYY-MM-DDTHH:mm:ss.SSSZ'}),

    /**
     * Formatter for relative time, such as "a few seconds ago" or "in 10 minutes".
     */
    fromNow: new DayjsRelativeTimeMissingFormat({missing: missingCompact}),

    /**
     * Formatter for time relative to a reference time, such as "Yesterday at 4:55" or "Sunday at 4:55".
     */
    calendar: new DayjsCalendarTimeMissingFormat({missing: missingCompact}),
});
