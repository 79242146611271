import {CaseAssistConfigModel} from '@core/api';
import {RequestsSelectors} from '@coveord/jsadmin-common';
import {SelectSelector, TabSelectors} from '@coveord/plasma-react';

import {ServiceState} from '../../ServiceState';
import {CaseAssistConfigurationConstants} from './CaseAssistConfigurationConstants';
import {CaseClassificationSelectors} from './caseClassificationTab/CaseClassificationSelectors';
import {DocumentSuggestionSelectors} from './documentSuggestionTab/DocumentSuggestionSelectors';
import {OverviewSelectors} from './overviewTab/OverviewSelectors';
import {OverviewTabSelectors} from './overviewTab/OverviewTabSelectors';

export enum EditCaseAssistConfigHeaderTabs {
    Overview = 'overview',
    DocumentSuggestion = 'documentSuggestion',
    CaseClassification = 'caseClassification',
}
export interface CaseAssistConfigModelWithProjectIds extends CaseAssistConfigModel {
    projectIds: string[];
}

const isFetchingConfig = RequestsSelectors.createLoadingSelector(['FETCH_CASE_ASSIST_CONFIG']);

const getCaseAssistConfigId = (state: ServiceState) => state.caseAssistManager.editCaseAssistConfig.data.id || '';

const getCaseAssistConfigName = (state: ServiceState) => state.caseAssistManager.editCaseAssistConfig.data.name;

const getCaseAssistConfigPipeline = (state: ServiceState) =>
    state.caseAssistManager.editCaseAssistConfig.data.documentSuggestionConfiguration?.pipeline || '';

const getCaseAssistConfigStrategy = (state: ServiceState) =>
    state.caseAssistManager.editCaseAssistConfig.data.documentSuggestionConfiguration?.strategy;

const getCaseAssistConfigFilter = (state: ServiceState) =>
    state.caseAssistManager.editCaseAssistConfig.data.documentSuggestionConfiguration?.filter || '';

const getCaseAssistConfig = (
    state: ServiceState,
    modifiedSection: EditCaseAssistConfigHeaderTabs,
    canEditConfig: boolean,
): CaseAssistConfigModelWithProjectIds => {
    const projectIds =
        SelectSelector.getListBoxSelected(state, {
            id: CaseAssistConfigurationConstants.ComponentIds.ProjectAssociationMultiSelect,
        }) ?? [];

    let data = {...state.caseAssistManager.editCaseAssistConfig.data, projectIds};

    switch (modifiedSection) {
        case EditCaseAssistConfigHeaderTabs.Overview:
            data = {
                ...data,
                ...OverviewSelectors.getConfiguration(state),
            };
            break;
        case EditCaseAssistConfigHeaderTabs.DocumentSuggestion:
            data = {
                ...data,
                documentSuggestionConfiguration: DocumentSuggestionSelectors.getConfiguration(state, canEditConfig),
            };
            break;
        case EditCaseAssistConfigHeaderTabs.CaseClassification:
            const caseClassificationConfig = CaseClassificationSelectors.getEditedConfiguration(state);
            data = {
                ...data,
                classificationConfigurations: caseClassificationConfig ? [caseClassificationConfig] : [],
            };
            break;
        default:
            break;
    }

    return data as CaseAssistConfigModelWithProjectIds;
};

const getCaseAssistSelectedTabId = (state: ServiceState) =>
    [
        EditCaseAssistConfigHeaderTabs.Overview,
        EditCaseAssistConfigHeaderTabs.DocumentSuggestion,
        EditCaseAssistConfigHeaderTabs.CaseClassification,
    ].find((tabId) => TabSelectors.getTab(state, {id: tabId})?.isSelected);

const getValidationIds = (section: string) => {
    const sectionIds = {
        [EditCaseAssistConfigHeaderTabs.Overview]: OverviewTabSelectors.getValidationIds(),
        [EditCaseAssistConfigHeaderTabs.DocumentSuggestion]: DocumentSuggestionSelectors.getValidationIds(),
        [EditCaseAssistConfigHeaderTabs.CaseClassification]: CaseClassificationSelectors.getValidationIds(),
    };

    return sectionIds[section] ?? [];
};

const isValidating = (state: ServiceState, section: string) => {
    switch (section) {
        case EditCaseAssistConfigHeaderTabs.DocumentSuggestion:
            return DocumentSuggestionSelectors.isValidating(state);
        case EditCaseAssistConfigHeaderTabs.CaseClassification:
            return CaseClassificationSelectors.isValidating(state);
        default:
            return false;
    }
};

const areTabsActive = (state: ServiceState) =>
    !DocumentSuggestionSelectors.shouldShowBlankSlate(state) ||
    !CaseClassificationSelectors.shouldShowBlankSlate(state);

export const EditCaseAssistConfigSelectors = {
    isFetchingConfig,
    getCaseAssistConfig,
    getCaseAssistConfigId,
    getCaseAssistConfigName,
    getCaseAssistConfigPipeline,
    getCaseAssistConfigStrategy,
    getCaseAssistConfigFilter,
    getCaseAssistSelectedTabId,
    getValidationIds,
    isValidating,
    areTabsActive,
};
