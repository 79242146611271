import * as ReactVapor from '@coveord/plasma-react';
import _ from 'underscore';
import {
    FetchGroupsFailedPayload,
    FetchGroupsStartedPayload,
    FetchGroupsSuccessPayload,
    UAGroupsActionsType,
} from '../actions/UAGroupsActions';
import {UAGroupsDefaultState, UAGroupsState} from '../UAGroupsState';

const fetchGroupsSuccessReducer = (
    state: UAGroupsState,
    action: ReactVapor.IReduxAction<FetchGroupsSuccessPayload>,
): UAGroupsState => {
    const newState = _.extend({}, state, {
        error: false,
        loaded: true,
        groups: action.payload.groups,
    });
    return newState;
};

const fetchGroupsReducerStarted = (
    state: UAGroupsState,
    action: ReactVapor.IReduxAction<FetchGroupsStartedPayload>,
): UAGroupsState => _.extend({}, state, {loaded: false, error: false});

const fetchGroupsReducerFailed = (
    state: UAGroupsState,
    action: ReactVapor.IReduxAction<FetchGroupsFailedPayload>,
): UAGroupsState => _.extend({}, state, {error: true});

const UAGroupsReducers: {[key: string]: (...args: any[]) => any} = {
    [UAGroupsActionsType.FetchGroupsStarted]: fetchGroupsReducerStarted,
    [UAGroupsActionsType.FetchGroupsSuccess]: fetchGroupsSuccessReducer,
    [UAGroupsActionsType.FetchGroupsFailure]: fetchGroupsReducerFailed,
};

export const UAGroupsReducer = (
    state: UAGroupsState = UAGroupsDefaultState,
    action: ReactVapor.IReduxAction<FetchGroupsSuccessPayload | FetchGroupsFailedPayload | FetchGroupsStartedPayload>,
) => {
    let newState = state;
    if (!_.isUndefined(UAGroupsReducers[action.type])) {
        newState = UAGroupsReducers[action.type](state, action);
    }
    return newState;
};
