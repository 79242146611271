import {Alert} from '@components/mantine';
import {ProjectEvents} from '@components/projects';
import {ProjectSelectors} from '@core/projects';
import {useLocation} from '@core/routes';
import {clsx} from 'clsx';
import {FunctionComponent, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {PanelHelpers} from '../../helpers';
import {Locales} from '../../strings/Locales';
import classes from './UnsupportedPanelAlert.module.css';

export const UnsupportedPanelAlert: FunctionComponent = () => {
    const location = useLocation();
    const selectedProjectId = useSelector(ProjectSelectors.getSelectedProjectId);
    const [isDisplayed, setIsDisplayed] = useState(false);

    useEffect(() => {
        setIsDisplayed(
            !!selectedProjectId &&
                !PanelHelpers.isPanelProject(location.pathname) &&
                !PanelHelpers.isPanelProjectSupported(location.pathname) &&
                location.pathname !== '/',
        );
    }, [location.pathname, selectedProjectId]);

    useEffect(() => {
        const checkAlertIsDisplayed = () => {
            setIsDisplayed(
                !!selectedProjectId &&
                    !PanelHelpers.isPanelProject(location.pathname) &&
                    !PanelHelpers.isPanelProjectSupported(location.pathname) &&
                    location.pathname !== '/',
            );
        };

        window.addEventListener(ProjectEvents.updateSelectedProject, checkAlertIsDisplayed);

        return () => {
            document.removeEventListener(ProjectEvents.updateSelectedProject, checkAlertIsDisplayed);
        };
    }, []);

    return (
        <Alert
            onClose={() => setIsDisplayed(false)}
            classNames={{
                root: clsx(classes.root, {
                    [classes.rootDisplayed]: isDisplayed,
                    [classes.rootHidden]: !isDisplayed,
                }),
                wrapper: classes.wrapper,
                body: classes.body,
                icon: classes.icon,
                closeButton: classes.closeButton,
            }}
            withCloseButton
        >
            {Locales.format('UnsupportedPanelAlert.message')}
        </Alert>
    );
};
