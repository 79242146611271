import {NavLink, Tabs} from '@components/mantine';
import {Guard} from '@components/security';
import {FunctionComponent, ReactNode} from 'react';

import {useSubnavLayoutInternal} from './SubnavLayout.context';
import classes from './SubnavLayoutNavigation.module.css';

export const SubnavLayoutNavigation: FunctionComponent = () => {
    const {activeTab, panels, variant} = useSubnavLayoutInternal();

    const renderRoot = (path: string, label: ReactNode, onClick: () => void) => (
        <NavLink
            active={activeTab === path}
            component="button"
            label={label}
            onClick={onClick}
            classNames={classes}
            role="tab"
            aria-selected={activeTab === path}
            data-subnav-layout-variant={variant}
        />
    );

    return (
        <Tabs.List>
            {panels.map(({props: {canRender, label, path}}) => (
                <Guard canRender={canRender ?? true} key={path}>
                    <Tabs.Tab key={path} value={path} renderRoot={({onClick}) => renderRoot(path, label, onClick)} />
                </Guard>
            ))}
        </Tabs.List>
    );
};
