import * as ReactVapor from '@coveord/plasma-react';
import {MetricCardUpdateDefaultTitlePayload, MetricCardUpdatePayload} from '../../Metric/actions/MetricCardActions';

export const QuickMetricActionsType = {
    Update: 'QUICK_METRIC_UPDATE',
    UpdateDefaultTitle: 'QUICK_METRIC_UPDATE_DEFAULT_TITLE',
};

const update = (id: string, value: number): ReactVapor.IReduxAction<MetricCardUpdatePayload> => ({
    type: QuickMetricActionsType.Update,
    payload: {id, value},
});

const updateDefaultTitle = (
    id: string,
    title: string,
): ReactVapor.IReduxAction<MetricCardUpdateDefaultTitlePayload> => ({
    type: QuickMetricActionsType.UpdateDefaultTitle,
    payload: {id, title},
});

export const QuickMetricActions = {
    update,
    updateDefaultTitle,
};
