import {notifications} from '@components/mantine';
import {DNEConfigurationModel, MLAssociationModel, MLModel, Platform} from '@core/api';
import {track} from '@core/tracking';
import {commonLocales, GlobalConstants, PerPageNumbers, RequestsActions} from '@coveord/jsadmin-common';
import {
    IReduxAction,
    ITableHOCCompositeState,
    IThunkAction,
    TableHOCUtils,
    TableWithPaginationActions,
} from '@coveord/plasma-react';

import {ComponentIds} from '../../../../ComponentsIds';
import {locales} from '../../../../Locales';
import {SearchOptimizationState} from '../../../../SearchOptimizationState';
import {MoveDirection} from '../../../StatementConstants';
import {AssociationSelectors} from '../AssociationSelectors';

export const AssociationsActionTypes = {
    set: 'SET_ASSOCIATIONS',
    setModels: 'SET_EDIT_ASSOCIATION_MODELS',
    setRankingModifier: 'SET_EDIT_ASSOCIATION_RANKING_MODIFIER',
    setSelectedModelId: 'SET_EDIT_ASSOCIATION_SELECTED_MODEL_ID',
    setJSON: 'SET_ASSOCIATION_JSON',
    clear: 'CLEAR_EDIT_ASSOCIATION',
    clearAll: 'CLEAR_ASSOCIATIONS',
};

export const AssociationsRequests = {
    fetchAll: 'FETCH_ASSOCIATIONS',
    fetch: 'FETCH_ASSOCIATION',
    save: 'SAVE_ASSOCIATION',
    move: 'CHANGE_ASSOCIATION_POSITION',
    fetchModels: 'FETCH_ASSOCIATION_MODELS',
    fetchModelDetails: 'FETCH_ASSOCIATION_MODEL_DETAILS',
};

export interface ISetAssociationsPayload {
    rules: MLAssociationModel[];
    totalEntries: number;
}

export interface ISetAssociationModelsPayload {
    models: MLModel[];
}

export interface ISetAssociationRankingModifierPayload {
    rankingModifier: number;
}

export interface ISetAssociationSelectedModelIdPayload {
    selectedModelId: string;
}

const setAssociations = (
    rules: MLAssociationModel[],
    totalEntries = rules?.length ?? 0,
): IReduxAction<ISetAssociationsPayload> => ({
    type: AssociationsActionTypes.set,
    payload: {rules, totalEntries},
});

const setModels = (models: MLModel[]): IReduxAction<ISetAssociationModelsPayload> => ({
    type: AssociationsActionTypes.setModels,
    payload: {models},
});

const setRankingModifier = (rankingModifier: number): IReduxAction<ISetAssociationRankingModifierPayload> => ({
    type: AssociationsActionTypes.setRankingModifier,
    payload: {rankingModifier},
});

const setSelectedModelId = (selectedModelId: string): IReduxAction<ISetAssociationSelectedModelIdPayload> => ({
    type: AssociationsActionTypes.setSelectedModelId,
    payload: {selectedModelId},
});

const clearAll = (): IReduxAction => ({type: AssociationsActionTypes.clearAll});

const clear = (): IReduxAction => ({type: AssociationsActionTypes.clear});

const fetchAll =
    (pipelineId: string, resetPending = true): IThunkAction<Promise<MLAssociationModel[]>, SearchOptimizationState> =>
    (dispatch, getState) => {
        if (_.isEmpty(pipelineId)) {
            return Promise.reject<MLAssociationModel[]>();
        }

        const {perPage, pageNb}: ITableHOCCompositeState = TableHOCUtils.getCompositeState(
            ComponentIds.AssociationsTable,
            getState(),
        );
        return dispatch(
            RequestsActions.handle(
                AssociationsRequests.fetchAll,
                async () => {
                    const list = await Platform.pipeline.associations.list(pipelineId, {
                        perPage: perPage || PerPageNumbers[1],
                        page: pageNb || 0,
                    });
                    dispatch(setAssociations(list.rules, list.totalEntries));
                    dispatch(TableWithPaginationActions.setCount(ComponentIds.AssociationsTable, list.totalEntries));
                    return list.rules;
                },
                resetPending,
            ),
        );
    };

const fetchModels = (): IThunkAction<Promise<MLModel[]>, SearchOptimizationState> => (dispatch) =>
    dispatch(
        RequestsActions.handle(AssociationsRequests.fetchModels, async () => {
            const models = await Platform.ml.models.list();
            dispatch(setModels(models));
            return models;
        }),
    );

const fetchModel =
    (modelId: string): IThunkAction<Promise<DNEConfigurationModel>, SearchOptimizationState> =>
    (dispatch) =>
        dispatch(
            RequestsActions.handle(AssociationsRequests.fetchModelDetails, () => Platform.ml.dneConfig.get(modelId)),
        );

const fetch =
    (pipelineId: string, id: string): IThunkAction<Promise<MLAssociationModel>, SearchOptimizationState> =>
    (dispatch) => {
        if (_.isEmpty(pipelineId) || _.isEmpty(id)) {
            return Promise.reject<MLAssociationModel>();
        }

        return dispatch(
            RequestsActions.handle(`${AssociationsRequests.fetch}_${id}`, () =>
                Platform.pipeline.associations.getAssociation(pipelineId, id),
            ),
        );
    };

const save =
    (pipelineId: string, id: string): IThunkAction<Promise<Record<string, unknown>>, SearchOptimizationState> =>
    (dispatch, getState) => {
        const data = AssociationSelectors.serializeAssociation(getState());
        const request =
            id === GlobalConstants.urlParameters.new
                ? Platform.pipeline.associations.associate(pipelineId, data)
                : Platform.pipeline.associations.updateAssociation(pipelineId, id, data);

        return dispatch(RequestsActions.handle(`${AssociationsRequests.save}_${id}`, () => request));
    };

const saveJSON =
    (pipelineId: string, id: string): IThunkAction<Promise<Record<string, unknown>>, SearchOptimizationState> =>
    (dispatch, getState) => {
        const activeQueryPipeline = AssociationSelectors.getActiveQueryPipeline(getState());
        const value = AssociationSelectors.getAssociationJSON(getState());
        const data = {...JSON.parse(value), useAdvancedConfiguration: true};
        track(
            `completed ml model association ${
                id === GlobalConstants.urlParameters.new ? 'add with code' : 'edit json'
            }`,
            {
                ...(id !== GlobalConstants.urlParameters.new && {associationId: id}),
                pipelineId: pipelineId,
                modelId: data?.modelId,
                modelType: data?.modelEngine,
                modelStatus: data?.modelStatus,
                pipelineIsABTest: activeQueryPipeline?.initializedABTest,
                pipelineUseCase: activeQueryPipeline?.queryPipeline?.useCase,
                pipelineConditionId: activeQueryPipeline?.queryPipeline?.condition?.id,
                associationCondition: data?.condition,
                ITD: data?.intelligentTermDetection,
                matchAdvancedExpression: data?.matchBasicExpression,
                matchBasicExpression: data?.matchBasicExpression,
                automaticSelection: data?.customQueryParameters?.automaticSelection?.isEnabled,
                facetOrdering: data?.customQueryParameters?.facetOrdering?.isEnabled,
                facetValueOrdering: data?.customQueryParameters?.facetValueOrdering?.isEnabled,
                rankingBoost: data?.customQueryParameters?.rankingBoost?.isEnabled,
                subModel: data?.customQueryParameters?.submodel,
                rankingModifier: data?.rankingModifier,
                useAdvancedConfiguration: data?.useAdvancedConfiguration,
            },
        );
        const request =
            id === GlobalConstants.urlParameters.new
                ? Platform.pipeline.associations.associate(pipelineId, data)
                : Platform.pipeline.associations.updateAssociation(pipelineId, id, data);

        return dispatch(RequestsActions.handle(`${AssociationsRequests.save}_${id}`, () => request));
    };

const move =
    (
        pipelineId: string,
        associationId: string,
        direction: MoveDirection,
    ): IThunkAction<Promise<void>, SearchOptimizationState> =>
    (dispatch, getState) => {
        const state = getState();
        const isMovingUp = direction === MoveDirection.Up;
        const associations = AssociationSelectors.getRules(state);
        const currentIndex = _.findIndex(associations, ({id}: MLAssociationModel) => id === associationId);
        const newPosition = associations[isMovingUp ? currentIndex - 1 : currentIndex + 1]?.position ?? null;

        const serverCall = async () => {
            if (newPosition != null) {
                await Platform.pipeline.associations.updatePosition(pipelineId, associationId, newPosition);
            } else {
                // Moving the association to another page
                const {perPage, pageNb}: ITableHOCCompositeState = TableHOCUtils.getCompositeState(
                    ComponentIds.AssociationsTable,
                    state,
                );
                const {rules: adjacentPage} = await Platform.pipeline.associations.list(pipelineId, {
                    perPage,
                    page: isMovingUp ? pageNb - 1 : pageNb + 1,
                });
                await Platform.pipeline.associations.updatePosition(
                    pipelineId,
                    associationId,
                    adjacentPage[isMovingUp ? perPage - 1 : 0]?.position,
                );
                notifications.showSuccess(
                    locales.format('moveAcrossPageSuccess', {
                        pagePosition: isMovingUp
                            ? commonLocales.format('previous').toLowerCase()
                            : commonLocales.format('next').toLowerCase(),
                    }),
                );
            }
            await dispatch(AssociationsActions.fetchAll(pipelineId, false));
        };

        return dispatch(RequestsActions.handle(AssociationsRequests.move, serverCall));
    };

export const AssociationsActions = {
    setAssociations,
    fetchAll,
    fetch,
    fetchModels,
    fetchModel,
    clearAll,
    clear,
    save,
    saveJSON,
    setRankingModifier,
    setSelectedModelId,
    setModels,
    move,
};
