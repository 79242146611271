export const AllowedIdentityEditionActionsType = {
    ApplyChanges: 'APPLY_ALLOWED_IDENTITY_CHANGES',
    CancelChanges: 'CANCEL_ALLOWED_IDENTITY_CHANGES',
    ChangeAdditinalInfo: 'CHANGE_ALLOWED_IDENTITY_ADDITIONAL_INFO',
    ChangeIdentity: 'CHANGE_ALLOWED_IDENTITY_IDENTITY',
    ChangeIdentityType: 'CHANGE_ALLOWED_IDENTITY_IDENTITY_TYPE',
    ChangeSecurityProvider: 'CHANGE_ALLOWED_IDENTITY_SECURITY_PROVIDER',
    ToggleOpen: 'TOGGLE_OPEN_ALLOWED_IDENTITY',
};

export interface IAllowedIdentityEditionAction {
    type: string;
    payload: {
        id?: string;
        additionalInfo?: string;
        identity?: string;
        identityType?: string;
        securityProvider?: string;
        isOpen?: boolean;
    };
}

export const applyChanges = (id: string): IAllowedIdentityEditionAction => ({
    type: AllowedIdentityEditionActionsType.ApplyChanges,
    payload: {
        id,
    },
});

export const cancelChanges = (id: string): IAllowedIdentityEditionAction => ({
    type: AllowedIdentityEditionActionsType.CancelChanges,
    payload: {
        id,
    },
});

export const changeAdditionalInfo = (id: string, additionalInfo: string): IAllowedIdentityEditionAction => ({
    type: AllowedIdentityEditionActionsType.ChangeAdditinalInfo,
    payload: {
        id,
        additionalInfo,
    },
});

export const changeIdentity = (id: string, identity: string): IAllowedIdentityEditionAction => ({
    type: AllowedIdentityEditionActionsType.ChangeIdentity,
    payload: {
        id,
        identity,
    },
});

export const changeIdentityType = (id: string, identityType: string): IAllowedIdentityEditionAction => ({
    type: AllowedIdentityEditionActionsType.ChangeIdentityType,
    payload: {
        id,
        identityType,
    },
});

export const changeSecurityProvider = (id: string, securityProvider: string): IAllowedIdentityEditionAction => ({
    type: AllowedIdentityEditionActionsType.ChangeSecurityProvider,
    payload: {
        id,
        securityProvider,
    },
});

export const toggleOpen = (id: string, isOpen: boolean): IAllowedIdentityEditionAction => ({
    type: AllowedIdentityEditionActionsType.ToggleOpen,
    payload: {
        id,
        isOpen,
    },
});
