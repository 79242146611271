import {track} from '@core/tracking';

const trackClickedScheduledMaintenance = () => track({action: 'clicked', subject: 'scheduled maintenance'});

const trackClickedSystemsStatus = () => track({action: 'clicked', subject: 'systems status'});

const trackClickedOrganizationStatus = () => track({action: 'clicked', subject: 'organization status'});

const trackViewedWhatsNew = () => track({action: 'viewed', subject: 'whats new'});

const trackClickedUserNotifications = () => track({action: 'opened', subject: 'user notifications'});

const trackDnsDeprecationNotificationsOpened = () =>
    track({action: 'opened', subject: 'dns deprecation notifications'});

const trackDnsDeprecationNotificationsClosed = () =>
    track({action: 'closed', subject: 'dns deprecation notifications'});

const trackDnsDeprecationNotificationsViewDetailsClicked = () =>
    track({action: 'clicked', subject: 'dns deprecation notifications view details'});

export const NotificationsTracking = {
    trackClickedScheduledMaintenance,
    trackClickedSystemsStatus,
    trackClickedOrganizationStatus,
    trackViewedWhatsNew,
    trackClickedUserNotifications,
    trackDnsDeprecationNotificationsOpened,
    trackDnsDeprecationNotificationsClosed,
    trackDnsDeprecationNotificationsViewDetailsClicked,
};
