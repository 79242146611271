import {ObjectReducerGenerator} from '@core/store';
import {PrerequisitesActionTypes} from './PrerequisitesActions';

export interface IPrerequisitesState {
    hasSource: boolean;
    isOrganizationPaused: boolean;
}

export const prerequisitesReducer = ObjectReducerGenerator<IPrerequisitesState>(PrerequisitesActionTypes.update, {
    hasSource: false,
    isOrganizationPaused: false,
});
