export const answerManager: Record<string, string> = {
    '/:organizationId/answer-manager': 'viewed answer configurations',
    '/:organizationId/answer-manager/create': 'viewed answer configuration add',
    '/:organizationId/answer-manager/edit/:answerConfigId': 'viewed answer configuration edit',
    '/:organizationId/answer-manager/configurations/:answerConfigId/evaluations': 'viewed answer evaluations',
    '/:organizationId/answer-manager/configurations/:answerConfigId/evaluations/details/:evaluationId':
        'viewed answer evaluation details',
    '/:organizationId/answer-manager/configurations/:answerConfigId/rules': 'viewed answer rules',
    '/:organizationId/answer-manager/configurations/:answerConfigId/rules/create': 'viewed answer rule add',
    '/:organizationId/answer-manager/configurations/:answerConfigId/rules/edit/:answerRuleId':
        'viewed answer rule edit',
};
