import {ProjectFieldActions} from '@components/projects';
import {changeInputValue, IThunkAction, ValidationActions, ValidationTypes} from '@coveord/plasma-react';
import {ServiceState} from '../../../ServiceState';
import {CaseAssistConfigurationConstants} from '../CaseAssistConfigurationConstants';
import {EditCaseAssistConfigSelectors} from '../EditCaseAssistConfigSelectors';

export const OverviewTabActionTypes = {
    clearPendingChanges: 'CLEAR_OVERVIEW_CHANGES',
};

const componentIds = {
    name: CaseAssistConfigurationConstants.ComponentIds.NameInput,
    projectIds: CaseAssistConfigurationConstants.ComponentIds.ProjectAssociationMultiSelect,
};

const clearPendingChanges = (): IThunkAction<void, ServiceState> => async (dispatch, getState) => {
    const initialName = EditCaseAssistConfigSelectors.getCaseAssistConfigName(getState());
    dispatch(changeInputValue(componentIds.name, initialName));
    dispatch(ProjectFieldActions.resetProjectField(componentIds.projectIds));
    dispatch(ValidationActions.clearError(componentIds.name, ValidationTypes.nonEmpty));
    dispatch(ValidationActions.clearDirty(componentIds.name, ValidationTypes.wrongInitialValue));
};

export const OverviewTabActions = {
    clearPendingChanges,
};
