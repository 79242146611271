import {PlatformPaths} from '@configurations/platform';
import {RouteObject} from '@core/routes';

export const mainRoutes: RouteObject[] = [
    {
        path: `${PlatformPaths.CreateSource.fullPath}/*`,
        lazy: async () => {
            const {CreateSourcePage} = await import('../pages/CreateSourcePage');
            return {Component: CreateSourcePage};
        },
    },
    {
        path: `${PlatformPaths.EditSource.fullPath}/*`,
        lazy: async () => {
            const {EditSourcePage} = await import('../pages/EditSourcePage');
            return {Component: EditSourcePage};
        },
    },
];
