import {Debug} from '@core/debug';

import {Store} from '../store';

// Do not fill this array in this package, instead use the register method
const registeredFlags: Set<string> = new Set();

/**
 * Registers a feature flag, or set of feature flags in the application.
 *
 * @deprecated Use a LaunchDarkly flag instead
 * @param flagsToRegister
 */
const register = (flagsToRegister: string | Record<string, string>): void => {
    let flags: string[];

    if (typeof flagsToRegister === 'string') {
        flags = [flagsToRegister];
    } else {
        flags = Object.values(flagsToRegister);
    }

    flags.forEach((flag: string) => {
        if (registeredFlags.has(flag)) {
            throw new Error(`The user feature flag "${flag}" is already registered.`);
        }
        registeredFlags.add(flag);
        Debug.setState('uiFeatureFlagTool', (prev) => ({...prev, [flag]: false}));
    });
};

const hasFlag = (flag: string): boolean => {
    if (!registeredFlags.has(flag)) {
        throw new Error(`The user feature flag "${flag}" does not exist.`);
    }
    const activeFlags = (Store.getState().user?.additionalInformation?.featureFlags ?? '').split(';');

    return activeFlags.includes(flag);
};

export const UserFeatureFlags = {
    /**
     * @returns The registered feature flags
     */
    get list(): string[] {
        return Array.from(registeredFlags);
    },

    /**
     * Registers a feature flag, or set of feature flags in the application.
     *
     * @param {(string|Record<string, string>)} flags The feature flag name, or record of multiple unique feature flag names.
     */
    register,

    /**
     * Checks whether a feature flag is active or not.
     *
     * @param {string} flag The name of the feature flag to check.
     * @returns {boolean} Whether the flag is active or not.
     * @throws Error if the flag has never been registered.
     */
    hasFlag,
};
