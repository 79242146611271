import {IReduxAction} from '@coveord/plasma-react';
import _ from 'underscore';
import {EditTimeseriesActionsType} from '../actions/EditTimeseriesActions';
import {
    EditVisitMetricTimeseriesActionsType,
    EditVisitMetricTimeseriesFullPayload,
    EditVisitMetricTimeseriesSetFiltersPayload,
} from '../actions/EditVisitMetricTimeseriesActions';
import {EditVisitMetricTimeseriesDefaultState, EditVisitMetricTimeseriesState} from '../EditVisitMetricTimeseriesState';

const initialize = (
    state: EditVisitMetricTimeseriesState,
    action: IReduxAction<EditVisitMetricTimeseriesFullPayload>,
): EditVisitMetricTimeseriesState => _.extend({}, state, EditVisitMetricTimeseriesDefaultState, action.payload);

const clear = (): EditVisitMetricTimeseriesState => ({...EditVisitMetricTimeseriesDefaultState});

const setFilters = (
    state: EditVisitMetricTimeseriesState,
    action: IReduxAction<EditVisitMetricTimeseriesSetFiltersPayload>,
): EditVisitMetricTimeseriesState => ({
    ...state,
    ...action.payload,
});

const EditVisitMetricTimeseriesReducers: {[key: string]: (...args: any[]) => EditVisitMetricTimeseriesState} = {
    [EditVisitMetricTimeseriesActionsType.Initialize]: initialize,
    [EditVisitMetricTimeseriesActionsType.SetFilters]: setFilters,
    [EditTimeseriesActionsType.Clear]: clear,
};

export type EditVisitMetricTimeseriesPayload =
    | EditVisitMetricTimeseriesFullPayload
    | EditVisitMetricTimeseriesSetFiltersPayload;

export const EditVisitMetricTimeseriesReducer = (
    state: EditVisitMetricTimeseriesState = EditVisitMetricTimeseriesDefaultState,
    action?: IReduxAction<EditVisitMetricTimeseriesPayload>,
): EditVisitMetricTimeseriesState =>
    EditVisitMetricTimeseriesReducers[action.type]
        ? EditVisitMetricTimeseriesReducers[action.type](state, action)
        : state;
