import {notifications} from '@components/mantine';
import {CaseAssistConfigModel, PlatformNoHandlers} from '@core/api';
import {RequestsActions} from '@coveord/jsadmin-common';
import {IReduxAction, IThunkAction} from '@coveord/plasma-react';

import {HandleSaveProjectAssociationCallbackMethod} from '@components/projects';
import {locales} from '../../locales/Locales';
import {ServiceState} from '../../ServiceState';
import {CaseClassificationActions} from './caseClassificationTab/CaseClassificationActions';
import {DocumentSuggestionActions} from './documentSuggestionTab/DocumentSuggestionActions';
import {EditCaseAssistConfigHeaderTabs, EditCaseAssistConfigSelectors} from './EditCaseAssistConfigSelectors';
import {OverviewTabActions} from './overviewTab/OverviewTabActions';

export const EditCaseAssistConfigActionTypes = {
    updateCaseAssistConfig: 'UPDATE_CASE_ASSIST_CONFIG',
    fetchCaseAssistConfig: 'FETCH_CASE_ASSIST_CONFIG',
    saveCaseAssistConfig: 'SAVE_CASE_ASSIST_CONFIG',
    disableConfiguration: 'DISABLE_CASE_ASSIST_CONFIG',
    clearPendingChanges: 'CLEAR_CASE_ASSIST_PENDING_CHANGES',
};

export interface IUpdateCaseAssistPayload {
    caseAssistConfig: Partial<CaseAssistConfigModel>;
}

const defaultCaseAssistConfig: Partial<CaseAssistConfigModel> = {
    name: '',
    classificationConfigurations: [],
};

const updateCaseAssistConfig = (
    caseAssistConfig: Partial<CaseAssistConfigModel>,
): IReduxAction<IUpdateCaseAssistPayload> => ({
    type: EditCaseAssistConfigActionTypes.updateCaseAssistConfig,
    payload: {
        caseAssistConfig,
    },
});

const fetchCaseAssistConfig =
    (caseId: string): IThunkAction<void, ServiceState> =>
    async (dispatch) => {
        dispatch(RequestsActions.request(EditCaseAssistConfigActionTypes.fetchCaseAssistConfig));

        if (!caseId) {
            dispatch(updateCaseAssistConfig(defaultCaseAssistConfig));
        } else {
            try {
                const foundCase = await PlatformNoHandlers.caseAssistConfig.get(caseId);
                dispatch(updateCaseAssistConfig(foundCase));
                dispatch(RequestsActions.success(EditCaseAssistConfigActionTypes.fetchCaseAssistConfig));
            } catch (exception) {
                dispatch(RequestsActions.failure(EditCaseAssistConfigActionTypes.fetchCaseAssistConfig, exception));
                notifications.showError(locales.format('fetchCaseAssistConfigFailed'));
            } finally {
                dispatch(clearPendingChanges());
            }
        }
    };

const saveCaseAssistConfig =
    (
        modifiedSection: EditCaseAssistConfigHeaderTabs,
        handleSaveProjectAssociation: HandleSaveProjectAssociationCallbackMethod,
        canEditConfig: boolean,
    ): IThunkAction<Promise<boolean>, ServiceState> =>
    async (dispatch, getState) => {
        const state = getState();
        const {projectIds, ...configToSave} = EditCaseAssistConfigSelectors.getCaseAssistConfig(
            state,
            modifiedSection,
            canEditConfig,
        );

        dispatch(RequestsActions.request(EditCaseAssistConfigActionTypes.saveCaseAssistConfig));
        let isSaveSuccessful = false;
        try {
            await PlatformNoHandlers.caseAssistConfig.update(configToSave as CaseAssistConfigModel);
            dispatch(updateCaseAssistConfig(configToSave));

            // Summit : save associated projects
            await handleSaveProjectAssociation({
                resourceId: configToSave.id,
                newAssociatedProjectIds: projectIds,
            });

            dispatch(RequestsActions.success(EditCaseAssistConfigActionTypes.saveCaseAssistConfig));
            notifications.showSuccess(locales.format('saveCaseAssistConfigCompleted'));
            isSaveSuccessful = true;
        } catch (exception) {
            dispatch(RequestsActions.failure(EditCaseAssistConfigActionTypes.saveCaseAssistConfig, exception));
            notifications.showError(locales.format('saveCaseAssistConfigFailed'));
            isSaveSuccessful = false;
        } finally {
            dispatch(clearPendingChanges(modifiedSection));
        }
        return isSaveSuccessful;
    };

const disableConfiguration =
    (configurationName: string): IThunkAction<void, ServiceState> =>
    async (dispatch, getState) => {
        const state = getState();
        const configToSave = state.caseAssistManager.editCaseAssistConfig.data;

        delete configToSave[configurationName];
        dispatch(RequestsActions.request(EditCaseAssistConfigActionTypes.disableConfiguration));

        try {
            if (configToSave.id) {
                await PlatformNoHandlers.caseAssistConfig.update(configToSave as CaseAssistConfigModel);
                dispatch(updateCaseAssistConfig(configToSave));
                dispatch(fetchCaseAssistConfig(configToSave.id));
            }

            dispatch(RequestsActions.success(EditCaseAssistConfigActionTypes.disableConfiguration));
        } catch (exception) {
            dispatch(RequestsActions.failure(EditCaseAssistConfigActionTypes.disableConfiguration, exception));
            notifications.showError(locales.format('disableConfigurationFailed'));
        }
    };

const clearPendingChanges =
    (section?: string): IThunkAction<void, ServiceState> =>
    async (dispatch) => {
        const clearAll = !section;

        if (clearAll || section === 'overview') {
            dispatch(OverviewTabActions.clearPendingChanges());
        }
        if (clearAll || section === 'documentSuggestion') {
            dispatch(DocumentSuggestionActions.clearPendingChanges());
        }
        if (clearAll || section === 'caseClassification') {
            dispatch(CaseClassificationActions.clearPendingChanges());
        }
    };

export const EditCaseAssistConfigActions = {
    fetchCaseAssistConfig,
    updateCaseAssistConfig,
    saveCaseAssistConfig,
    disableConfiguration,
    clearPendingChanges,
};
