import {PlatformPaths} from '@configurations/platform';
import {RouteObject} from '@core/routes';

export const routes: RouteObject[] = [
    {
        path: `${PlatformPaths.ApplyResourceSnapshot.fullPath}/*`,
        lazy: async () => {
            const {ApplyPage} = await import('../pages/ApplyPage');
            return {Component: ApplyPage};
        },
    },
    {
        path: `${PlatformPaths.AddResourceSnapshot.fullPath}/*`,
        lazy: async () => {
            const {SnapshotCreationPage} = await import('../pages/SnapshotCreationPage');
            return {Component: SnapshotCreationPage};
        },
    },
];
