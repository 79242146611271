import {PlatformPaths} from '@configurations/platform';
import {RouteObject} from '@core/routes';

export const routes: RouteObject[] = [
    {
        path: `${PlatformPaths.Index.fullPath}/*`,
        lazy: async () => {
            const {IndexPage} = await import('../pages/IndexPage');
            return {Component: IndexPage};
        },
    },
    {
        path: `${PlatformPaths.IndexDetails.fullPath}`,
        lazy: async () => {
            const {IndexDetailsPage} = await import('../pages/IndexDetailsPage');
            return {Component: IndexDetailsPage};
        },
    },
];
