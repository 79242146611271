import {getStore} from '@core/store';

interface State {
    user: {
        additionalInformation: {
            featureFlags: string;
            [key: string]: string;
        };
    };
}

export const Store: ReturnType<typeof getStore<State>> = getStore<State>();
