import {track as amplitudeTrack} from '@amplitude/analytics-browser';
import {Debug, TrackingCategory} from '@core/debug';
import {Scope, captureMessage} from '@sentry/react';

import {identify} from './identify';

const availableAction = [
    'accepted',
    'added',
    'applied',
    'cancelled',
    'checked',
    'cleared',
    'clicked',
    'closed',
    'completed',
    'copied',
    'deleted',
    'disabled',
    'enabled',
    'expanded',
    'loaded',
    'logout',
    'modified',
    'opened',
    'performed',
    'played',
    'received',
    'removed',
    'saved',
    'selected',
    'shared',
    'submitted',
    'switched',
    'unchecked',
    'viewed',
] as const;
export type ActionType = (typeof availableAction)[number];
type TrackData = Record<string, any>;
type TrackEvent = {
    action: ActionType;
    subject: string;
};

/**
 * Track customer actions.
 *
 * @param {TrackEvent|string} event
 * @param {TrackData} [data]
 *
 * @example ```typescript
 *   import {track} from '@core/tracking';
 *
 *   const trackComponent = (facetFields: ContentBrowserFacetFields) => {
 *     track('deleted content browser facet badge', {facet: facetFields});
 *   };
 *
 * @example ```typescript
 *   import {track} from '@core/tracking';
 *
 *   const trackComponent = (facetFields: ContentBrowserFacetFields) => {
 *     track({action: 'deleted', subject: 'content browser facet badge'}, {facet: facetFields});
 *   };
 * ```
 */
export const track = (event: TrackEvent | string, data: TrackData = {}): void => {
    identify();

    let eventInput: TrackEvent;
    if (typeof event !== 'object') {
        const [action, ...subject] = event.split(' ');
        eventInput = {action: action as ActionType, subject: subject.join(' ')};
    } else {
        eventInput = event;
    }

    if (!availableAction.includes(eventInput.action)) {
        const scope = new Scope();
        scope.setTags({
            package: '@core/tracking',
            method: 'track',
        });
        scope.setLevel('warning');
        scope.setContext('tracking', {
            event: `${eventInput.action} ${eventInput.subject}`,
            data,
        });
        captureMessage(
            `[@core/tracking] The "${eventInput.action}" action is not available in the list of usable actions "${availableAction.join(
                ',',
            )}".`,
            scope,
        );
    }

    // eslint-disable-next-line @helpers/no-then-catch-finally
    amplitudeTrack(`${eventInput.action} ${eventInput.subject}`, data).promise.then((onfulfilled) => {
        Debug.setState('trackingTool', (prev) => {
            const [action, ...subject] = onfulfilled.event.event_type.split(' ');
            let category: TrackingCategory = 'action';
            if (action === 'viewed') {
                category = 'read';
            } else if (['deleted', 'removed'].includes(action)) {
                category = 'delete';
            } else if (['added', 'completed', 'modified', 'saved', 'submitted'].includes(action)) {
                category = 'write';
            } else if (action === 'loaded') {
                category = 'system';
            }

            if (prev.length >= 25) {
                prev.pop();
            }

            return [
                {
                    action,
                    category,
                    event: onfulfilled.event,
                    legacyEvent: typeof event === 'string',
                    subject: subject.join(' '),
                },
                ...prev,
            ];
        });
    });
};
