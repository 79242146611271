import _ from 'underscore';
import {Options} from '../SingleSelect';
import {FlatSelectOptionView} from './FlatSelectOptionView';

export interface FlatSelectViewOptions {
    collection: Options;
    descriptionFormatter?: (value: string) => string;
    flatBlock?: boolean;
    modBtnGroup?: boolean;
    useButtonTag?: boolean;
    valueAsDescription?: boolean;
    valueFormatter?: (value: string) => string;
}

export class FlatSelectView extends Marionette.CollectionView<Backbone.Model, Options> {
    constructor(options?: FlatSelectViewOptions) {
        super(
            _.extend(
                {
                    childView: FlatSelectOptionView,
                    childViewOptions: {
                        descriptionFormatter: options.descriptionFormatter,
                        useButtonTag: options.useButtonTag,
                        valueAsDescription: options.valueAsDescription,
                        valueFormatter: options.valueFormatter,
                    },
                    className: 'flat-select clearfix',
                    collectionEvents: {
                        'select:one': 'onSelectOne',
                    },
                },
                options,
            ),
        );
    }

    onRender() {
        this.$el.toggleClass('flat-select-block', !!this.options.flatBlock);
        this.$el.toggleClass('mod-btn-group', !!this.options.modBtnGroup);
        this.$el.toggleClass('btn-group', !!this.options.modBtnGroup);
    }

    private onSelectOne() {
        this.children.each((view: FlatSelectOptionView) => {
            view.toggleSelected();
        });
    }
}
