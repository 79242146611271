import {IReduxAction} from '@coveord/plasma-react';
import {CreateCaseAssistConfigActionTypes} from './CreateCaseAssistConfigActions';

export interface ICreateCaseAssistConfigState {
    createdConfigId: string;
}

interface SetCreatedConfigIdPayload {
    id: string;
}

const setCreatedConfigId = (state: ICreateCaseAssistConfigState, action: IReduxAction<SetCreatedConfigIdPayload>) => ({
    ...state,
    createdConfigId: action.payload?.id ?? '',
});

const clearCreatedConfigId = (state: ICreateCaseAssistConfigState, action: IReduxAction<void>) => ({
    ...state,
    createdConfigId: '',
});

const CreateCaseAssistConfigReducers = {
    [CreateCaseAssistConfigActionTypes.setCreatedConfigId]: setCreatedConfigId,
    [CreateCaseAssistConfigActionTypes.clearCreatedConfigId]: clearCreatedConfigId,
};

export const createCaseAssistConfigReducer = (
    state: ICreateCaseAssistConfigState = {createdConfigId: ''},
    action: IReduxAction<any> = {type: '', payload: {}},
) =>
    _.isUndefined(CreateCaseAssistConfigReducers[action.type])
        ? state
        : CreateCaseAssistConfigReducers[action.type](state, action);
