export const Requests = {
    fetchStatements: 'FETCH_CONDITION_STATEMENTS',
    fetchObjects: 'FETCH_CONDITION_OBJECTS',
    fetchCondition: 'FETCH_CONDITION',
    saveCondition: 'SAVE_CONDITION',
    deleteCondition: 'DELETE_CONDITION',
    Statements: {
        fetchAll: 'FETCH_STATEMENTS',
        save: 'SAVE_STATEMENT',
    },
};
