import {Config} from '@core/configuration';
import {IThunkAction} from '@coveord/plasma-react';

import {UsageAnalyticsState} from '../../UsageAnalyticsState';
import {UAGroupAttributes} from '../UAGroup';

export const UAGroupsActionsType = {
    FetchGroupsStarted: 'UA_FETCH_GROUPS_STARTED',
    FetchGroupsSuccess: 'UA_FETCH_GROUPS_FULFILLED',
    FetchGroupsFailure: 'UA_FETCH_GROUPS_FAILED',
};

export interface FetchGroupsStartedPayload {}
export interface FetchGroupsFailedPayload {}
export interface FetchGroupsSuccessPayload {
    groups: UAGroupAttributes[];
}

const fetchGroups = (): IThunkAction<JQueryPromise<any>, UsageAnalyticsState> => (dispatch) => {
    dispatch({
        type: UAGroupsActionsType.FetchGroupsStarted,
        payload: {},
    });

    const groupUrl = `${Config.CloudPlatform.url}/rest/organizations/{organizationName}/groups`;
    return $.get(groupUrl)
        .done((data) => {
            dispatch({
                type: UAGroupsActionsType.FetchGroupsSuccess,
                payload: {
                    groups: data,
                },
            });
        })
        .fail((xhr: JQueryXHR) => {
            xhr.errorHandled = true;
            dispatch({
                type: UAGroupsActionsType.FetchGroupsFailure,
                payload: {},
            });
        });
};

export const UAGroupsActions = {
    fetchGroups,
};
