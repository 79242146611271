import {Config} from '@core/configuration';
import {IReduxAction, IThunkAction} from '@coveord/plasma-react';

import {AdminState} from '../../../../application/Reducers';
import {LogicalIndexSelectors} from './LogicalIndexSelectors';

export const LogicalIndexesActionTypes = {
    set: 'SET_LOGICAL_INDEX',
};

export interface LogicalIndex {
    id: string;
    name: string;
    organizationId: string;
}

export interface LogicalIndexActionPayload {
    logicalIndexes: LogicalIndex[];
}

const set = (logicalIndexes: LogicalIndex[]): IReduxAction<LogicalIndexActionPayload> => ({
    type: LogicalIndexesActionTypes.set,
    payload: {
        logicalIndexes,
    },
});

const fetch = (): IThunkAction<JQueryXHR | JQueryPromise<void>, AdminState> => (dispatch, getState) => {
    if (_.isEmpty(LogicalIndexSelectors.getAvailableIndexes(getState()))) {
        return $.get(`${Config.CloudPlatform.url}/rest/organizations/{organizationName}/logicalindexes`).done(
            (response: LogicalIndex[]) => dispatch(set(response)),
        );
    }

    return $.Deferred<void>().resolve().promise();
};

export const LogicalIndexActions = {
    set,
    fetch,
};
