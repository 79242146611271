import {RouteObject} from '@core/routes';
import {CommercePathsMap, baseCommercePath} from '../RoutesDefinition';

export const routes: RouteObject[] = [
    {
        path: `/:orgId${baseCommercePath}${CommercePathsMap.catalogs}/*`,
        lazy: async () => {
            const {CatalogsPage} = await import('../Catalog/CatalogsPage');
            return {Component: CatalogsPage};
        },
    },
    {
        path: `/:orgId${baseCommercePath}${CommercePathsMap.editCatalog}/*`,
        lazy: async () => {
            const {EditCatalogPage} = await import('../Catalog/EditCatalog/EditCatalogPage');
            return {Component: EditCatalogPage};
        },
    },
    {
        path: `/:orgId${baseCommercePath}${CommercePathsMap.editCatalogConfiguration}/*`,
        lazy: async () => {
            const {EditConfigurationPage} = await import(
                '../Catalog/CatalogConfiguration/EditConfiguration/EditConfigurationPage'
            );
            return {Component: EditConfigurationPage};
        },
    },
    {
        path: `/:orgId${baseCommercePath}${CommercePathsMap.productListingCatalogs}/*`,
        lazy: async () => {
            const {ProductListingsPage} = await import('../ProductListings/ProductListingsPage');
            return {Component: ProductListingsPage};
        },
    },
    {
        path: `/:orgId${baseCommercePath}${CommercePathsMap.productListingsForCatalog}/*`,
        lazy: async () => {
            const {ProductListingsForCatalogPage} = await import('../ProductListings/ProductListingsForCatalogPage');
            return {Component: ProductListingsForCatalogPage};
        },
    },
];
