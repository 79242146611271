import {emailValidator, Strings, ValidationError} from '@coveord/jsadmin-common';

import {Locales} from '../../../Locales';
import {DocumentPermissionModelAttributes} from '../../sources/BaseSourceModel';
import {GroupValidator} from '../GroupValidator';

export interface AllowedIdentityAttributes extends DocumentPermissionModelAttributes {
    isOpen?: boolean;
    appliedIdentity?: string;
}

export enum IdentityTypes {
    User = 'USER',
    Group = 'GROUP',
}

export const EmailSecurityProviderID = 'Email Security Provider';

export const defaultAllowedIdentityAttributes: AllowedIdentityAttributes = {
    additionalInfo: '{}',
    allowed: true,
    identity: '',
    identityType: IdentityTypes.User,
    securityProvider: EmailSecurityProviderID,
};

export const validateAllowedIdentity = (attributes: AllowedIdentityAttributes): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (Strings.isEmpty(attributes.identity)) {
        errors.push({
            attributeName: 'identity',
            message: Locales.format('allowedIdentityIdentityCannotBeEmpty'),
        });
    }

    if (typeof attributes.additionalInfo !== 'object') {
        errors.push({
            jQuerySelector: 'textarea[name="additionalInfo"]',
            message: Locales.format('allowedIdentityAdditionalInfoMustBeValidJSON'),
        });
    }

    return errors.length ? errors : null;
};

export const allowedIdentityIsValid = (attributes: AllowedIdentityAttributes): boolean => {
    if (Strings.isEmpty(attributes.identity)) {
        return false;
    }

    if (attributes.identityType === IdentityTypes.User) {
        return emailValidator.validateInput(attributes.identity, {blacklisted_chars: '*'})?.status === 'valid';
    }

    if (attributes.identityType === IdentityTypes.Group) {
        return GroupValidator.validateInput(attributes.identity)?.status === 'valid';
    }

    if (typeof attributes.additionalInfo !== 'object') {
        return false;
    }

    return true;
};
