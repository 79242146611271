import {ReducersMapObject} from 'redux';
import {EditCalculatedMetricReducer} from '../components/Card/CalculatedMetric/reducers/EditCalculatedMetricReducers';
import {EditCardDestinationReducer} from '../components/Card/Extras/Destination/EditCardDestinationReducers';
import {EditNoteCardReducer} from '../components/Card/Note/reducers/EditNoteCardReducer';
import {CardReducer} from '../components/Card/reducers/CardReducers';
import {EditDimensionTimeseriesReducer} from '../components/Card/Timeserie/reducers/EditDimensionTimeseriesReducer';
import {EditTimeseriesReducer} from '../components/Card/Timeserie/reducers/EditTimeseriesReducer';
import {EditVisitMetricTimeseriesReducer} from '../components/Card/Timeserie/reducers/EditVisitMetricTimeseriesReducer';
import {DashboardReducer} from '../components/Dashboard/reducers/DashboardReducer';
import {ReportTemplateReducer} from '../components/Dashboard/templates/ReportTemplateReducer';
import {ReportAccessReducer} from '../components/Reports/report-access/reducers/ReportAccessReducer';
import {UAGroupsReducer} from '../groups/reducers/UAGroupsReducer';
import {StrictValidationReducers} from '../settings/strict-validation/reducers/StrictValidationReducers';
import {UsageAnalyticsSettingsReducers} from '../settings/tabs/UsageAnalyticsSettingsTabsReducers';
import {UAUsersReducer} from '../users/reducers/UAUsersReducer';

export const usageAnalyticsReducers: ReducersMapObject = {
    templates: ReportTemplateReducer,
    reportAccess: ReportAccessReducer,
    users: UAUsersReducer,
    uaGroups: UAGroupsReducer,
    settings: UsageAnalyticsSettingsReducers,
    strictValidation: StrictValidationReducers,
    cards: CardReducer,
    dashboard: DashboardReducer,
    editCalculatedMetric: EditCalculatedMetricReducer,
    editNoteCard: EditNoteCardReducer,
    editCardDestination: EditCardDestinationReducer,
    editTimeseries: EditTimeseriesReducer,
    editDimensionTimeseries: EditDimensionTimeseriesReducer,
    editVisitMetricTimeseries: EditVisitMetricTimeseriesReducer,
};
