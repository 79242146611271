import {CurrentOrganization} from '@core/organization';
import {
    InputSelectors,
    IReduxAction,
    IThunkAction,
    ValidationActions,
    ValidationSelectors,
    ValidationTypes,
} from '@coveord/plasma-react';
import {getSearchServiceSingleton} from '../../search-ui/SearchUIService';
import {ServiceState} from '../../ServiceState';
import {locales} from '../../locales/Locales';

export interface IWithQuerySyntaxValidationActionTypes {
    validateSyntax: string;
    beginValidateSyntax: string;
    endValidateSyntax: string;
}

export const WithQuerySyntaxValidationActionTypes: IWithQuerySyntaxValidationActionTypes = {
    validateSyntax: 'VALIDATE_QUERY_SYNTAX',
    beginValidateSyntax: 'BEGIN_VALIDATE_QUERY_SYNTAX',
    endValidateSyntax: 'END_VALIDATE_QUERY_SYNTAX',
};

export interface IWithQuerySyntaxValidationPayload {
    id: string;
}

export interface IWithQuerySyntaxValidationActions {
    validateSyntax: (id: string) => IThunkAction<void, ServiceState>;
    beginValidateSyntax: (id: string) => IReduxAction<IWithQuerySyntaxValidationPayload>;
    endValidateSyntax: (id: string) => IReduxAction<IWithQuerySyntaxValidationPayload>;
}

export const generateActionTypes = (context: string): IWithQuerySyntaxValidationActionTypes => ({
    validateSyntax: `${context}_${WithQuerySyntaxValidationActionTypes.validateSyntax}`,
    beginValidateSyntax: `${context}_${WithQuerySyntaxValidationActionTypes.beginValidateSyntax}`,
    endValidateSyntax: `${context}_${WithQuerySyntaxValidationActionTypes.endValidateSyntax}`,
});

export const generateActions = (
    context: string,
    actionTypes: IWithQuerySyntaxValidationActionTypes,
): IWithQuerySyntaxValidationActions => ({
    validateSyntax: (id: string) => validateSyntax(context, id),
    beginValidateSyntax: (id: string) => ({
        type: actionTypes.beginValidateSyntax,
        payload: {
            id,
        },
    }),
    endValidateSyntax: (id: string) => ({
        type: actionTypes.endValidateSyntax,
        payload: {
            id,
        },
    }),
});

const validateSyntax =
    (context: string, id: string): IThunkAction<void, ServiceState> =>
    async (dispatch, getState) => {
        const invalidSyntaxError = locales.format('invalidQuerySyntaxMessage');

        const errorsOfSameType = ValidationSelectors.getErrors(id)(getState()).filter(
            (error) => error.validationType === ValidationTypes.default,
        );

        if (errorsOfSameType.some((error) => error.value !== invalidSyntaxError)) {
            return;
        }

        const expression = InputSelectors.getValue(getState(), {id: id});
        if (expression.length === 0) {
            if (errorsOfSameType.some((error) => error.value === invalidSyntaxError)) {
                dispatch(ValidationActions.setError(id, ''));
            }
            return;
        }

        dispatch(beginValidateSyntax(context, id));

        try {
            const message = (await isQuerySyntaxValid(id, expression)) ? '' : invalidSyntaxError;
            dispatch(ValidationActions.setError(id, message));
        } catch (error) {
            dispatch(ValidationActions.setError(id, locales.format('unableToValidateQuerySyntaxMessage')));
        } finally {
            dispatch(endValidateSyntax(context, id));
        }
    };

const isQuerySyntaxValid = async (id: string, expression: string) => {
    const organizationId = CurrentOrganization.getId();
    if (!organizationId) {
        throw new Error('The organization is not set');
    }
    const searchService = getSearchServiceSingleton();
    try {
        const response = await searchService.getResults(organizationId!, false, {
            q: '@uri',
            cq: expression,
            numberOfResults: 0,
        });

        return response.exception?.code !== 'InvalidSyntax';
    } catch (error) {
        if (error.type === 'InvalidQueryExpressionException') {
            return false;
        } else {
            throw error;
        }
    }
};

const beginValidateSyntax = (context: string, id: string): IReduxAction<IWithQuerySyntaxValidationPayload> => ({
    type: generateActionTypes(context).beginValidateSyntax,
    payload: {
        id,
    },
});

const endValidateSyntax = (context: string, id: string): IReduxAction<IWithQuerySyntaxValidationPayload> => ({
    type: generateActionTypes(context).endValidateSyntax,
    payload: {
        id,
    },
});

export const WithQuerySyntaxValidationActions = {
    validateSyntax,
    beginValidateSyntax,
    endValidateSyntax,
};
