import {IReduxAction} from '@coveord/plasma-react';
import {SearchHubLicenseType} from '@core/api';
import _ from 'underscore';
import {LicenseSetPayload} from '../actions';
import {Requests} from '../constants';

export interface SearchHubLicenseState {
    id: string;
    label: string;
    type: SearchHubLicenseType;
}

const set = (state: SearchHubLicenseState[], action: IReduxAction<LicenseSetPayload>) => action.payload.hubs;

const LicensesReducers: {[key: string]: (...args: any[]) => SearchHubLicenseState[]} = {
    [Requests.License.set]: set,
    [Requests.License.clear]: () => [], // return empty array
};

export const searchHubLicenseReducers = (
    state: SearchHubLicenseState[] = [],
    action: IReduxAction<LicenseSetPayload>,
): SearchHubLicenseState[] => {
    if (!_.isUndefined(LicensesReducers[action.type])) {
        return LicensesReducers[action.type](state, action);
    }

    return state;
};
