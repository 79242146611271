import {ConditionModel} from '@core/api';
// TODO: UITOOL 8786
// eslint-disable-next-line @helpers/no-jsadmin-import
import {RequestsSelectors} from '@coveord/jsadmin-common';
import {
    DatePickerDateRange,
    DatePickerSelectors,
    FlatSelectSelectors,
    InputSelectors,
    IStringListState,
    SelectSelector,
} from '@coveord/plasma-react';
import {createSelector} from 'reselect';
// TODO: refactor file (SEARCHAPI-7186)
import {
    Components,
    ObjectsActionsTypes,
    Operators,
    QPL,
    QPLContextTypes,
    QPLJoinOperators,
    QueryParameters,
    StatusRequests,
} from '../constants';
import {ConditionsHelpers} from '../helpers';
import {ConditionsState, IConditionObjects, IContextKeyPair, ISubConditionObject} from '../interfaces';
import {QPLExpressionBuilder} from '../models';

const getConditionsStatements = (state: ConditionsState): ConditionModel[] => state.conditionStatements;

const getIndexedConditionStatements = createSelector(
    getConditionsStatements,
    (conditions): Record<string, ConditionModel> =>
        conditions?.reduce(
            (result: Record<string, ConditionModel>, acc: ConditionModel) => ({...result, [acc.id]: {...acc}}),
            {},
        ),
);

const getSelectedCondition = createSelector(
    SelectSelector.getListBoxSelected,
    getIndexedConditionStatements,
    (selectedConditionIds: string[], conditionsById: Record<string, ConditionModel>): ConditionModel => {
        const selectedConditionId: string = selectedConditionIds[0] || '';
        return conditionsById?.[selectedConditionId] ?? null;
    },
);

const getNewCondition = (state: ConditionsState, containerId: string): string => {
    const conditions: string[] = ConditionsSelectors.getSubConditionsIdsList(state, {id: containerId});
    const builder = new QPLExpressionBuilder(QPLJoinOperators.And);

    conditions.forEach((id: string) => {
        const {
            values: valuesId,
            queryParameter: queryParameterId,
            combineOperator: combineOperatorId,
            contextInput: contextInputId,
            contextType: contextTypeId,
            dateValues: dateValuesId,
            dateOperator: dateOperatorId,
            operator: operatorId,
            booleanValues: booleanValuesId,
        } = Components.conditionConfigurationBoxIds(id);

        const values: string[] = SelectSelector.getMultiSelectSelectedValues(state, {id: valuesId});
        const queryParameter: string = SelectSelector.getListBoxSelected(state, {id: queryParameterId})[0];
        const combineOperatorValues: string[] = SelectSelector.getListBoxSelected(state, {id: combineOperatorId});
        const combineOperator: QPLJoinOperators =
            combineOperatorValues.length && ConditionsHelpers.parseQPLJoinOperator(combineOperatorValues[0]);
        const contextKey: string = InputSelectors.getValue(state, {id: contextInputId});
        const contextType = SelectSelector.getListBoxSelected(state, {id: contextTypeId})[0] as QPLContextTypes;
        const dates = ConditionsSelectors.getSelectedDateRangeSelector(state, dateValuesId);

        if (queryParameter === QueryParameters.QueryTimeUTC) {
            const selectedOperator = SelectSelector.getListBoxSelected(state, {id: dateOperatorId})[0];
            if (dates) {
                builder
                    .changeCurrentOperator(combineOperator)
                    .addIsBetweenExpression(selectedOperator, dates[0], dates[1]);
            }
        } else if (contextType === QPLContextTypes.BooleanType) {
            const selectedOperator: string = SelectSelector.getListBoxSelected(state, {id: operatorId})[0];
            const booleanValue = FlatSelectSelectors.getSelectedOptionId(state, {id: booleanValuesId});

            builder
                .changeCurrentOperator(combineOperator)
                .addBinaryFieldExpression(queryParameter, selectedOperator, booleanValue === 'true', contextKey);
        } else {
            const selectedOperator: string = SelectSelector.getListBoxSelected(state, {id: operatorId})[0];
            builder.changeCurrentOperator(combineOperator);

            if (Operators.UnaryFieldOperatorsByName.hasOwnProperty(selectedOperator)) {
                builder.addUnaryFieldExpression(queryParameter, selectedOperator, contextKey);
            } else if (values.length) {
                builder.startGroup(QPLJoinOperators.Or);
                values.forEach((value: string) => {
                    builder.addBinaryFieldExpression(queryParameter, selectedOperator, value, contextKey);
                });
                builder.endGroup();
            }
        }
    });

    return builder.build(QPL.Features.Condition);
};

const getConditionObjects = (state: Partial<ConditionsState>): IConditionObjects[] =>
    (state.conditionObjects && state.conditionObjects.list) || [];

const getCondition = (state: Partial<ConditionsState>) => state.condition;

const getSubConditions = (state: Partial<ConditionsState>, props: {id: string}): IStringListState =>
    (state && state.subConditions && state.subConditions[props.id]) || {id: undefined, list: []};

const getConditionObjectsStatus = (state: Partial<ConditionsState>) =>
    (state.conditionObjects && state.conditionObjects.status) || StatusRequests.New;

const getContextValue = (state: Partial<ConditionsState>, props: {id: string}): string => {
    const customSelected: string[] = SelectSelector.getListState(state, props);
    return customSelected.length
        ? customSelected[customSelected.length - 1]
        : SelectSelector.getListBoxSelected(state, props)[0];
};

const getPopularContextKeys = (state: Partial<ConditionsState>): IContextKeyPair[] =>
    state.conditionObjects && (state.conditionObjects.popularContextKeys || []);

const selectedContextKeyPairCombiner = (
    contextValue: string,
    popularContextKeys: IContextKeyPair[],
): IContextKeyPair | undefined => {
    if (contextValue && popularContextKeys?.length > 0) {
        return popularContextKeys.find((key) => key.key === contextValue);
    }
    return;
};

const getSelectedContextKeyPair = createSelector(
    getContextValue,
    getPopularContextKeys,
    selectedContextKeyPairCombiner,
);

const subConditionsIdsListCombiner = (subConditions: IStringListState): string[] =>
    (subConditions && subConditions.list) || [];

const getSubConditionsIdsList: (state: Partial<ConditionsState>, props: {id: string}) => string[] = createSelector(
    getSubConditions,
    subConditionsIdsListCombiner,
);

const subConditionQueryParameterSelected = (
    listBoxOfValues: string[],
    subConditionQueryParameter: string,
    conditionObjects: IConditionObjects[],
): string =>
    (listBoxOfValues.length && listBoxOfValues[0]) ||
    subConditionQueryParameter ||
    (conditionObjects.length && conditionObjects[0].name);

const getSubConditionQueryParameterSelected: (
    state: Partial<ConditionsState>,
    props: {id: string; subCondition: ISubConditionObject},
) => string = createSelector(
    SelectSelector.getListBoxSelected,
    (state: Partial<ConditionsState>, props: {id: string; subCondition: ISubConditionObject}) =>
        props.subCondition && props.subCondition.queryParameter,
    getConditionObjects,
    subConditionQueryParameterSelected,
);

const getContextKeyRequestsThatFailedSelector = RequestsSelectors.createErrorMessageSelector([
    ObjectsActionsTypes.requestContextKeys,
]);

const getSelectedDateRangeSelector = (
    state: ConditionsState,
    datePickerId: string,
): DatePickerDateRange | undefined => {
    const datePickerState = DatePickerSelectors.getDatePicker(state, {id: datePickerId});
    if (datePickerState && datePickerState.appliedLowerLimit && datePickerState.appliedUpperLimit) {
        return [datePickerState.appliedLowerLimit, datePickerState.appliedUpperLimit];
    }
};

const getSelectedValueOperatorSelector = (state: ConditionsState, listBoxId: string): string | undefined => {
    const listBoxState = SelectSelector.getListBox(state, {id: listBoxId});

    if (listBoxState && listBoxState.selected && listBoxState.selected.length > 0) {
        return listBoxState.selected[0];
    }
};

const getConditionObjectsMap = createSelector(
    getConditionObjects,
    (conditionObjects: IConditionObjects[]): Record<string, IConditionObjects> =>
        conditionObjects.reduce(
            (result: Record<string, IConditionObjects>, acc: IConditionObjects) => ({...result, [acc.name]: {...acc}}),
            {},
        ),
);

export const ConditionsSelectors = {
    getConditionsStatements,
    getIndexedConditionStatements,
    getSelectedCondition,
    getConditionObjects,
    getConditionObjectsMap,
    getCondition,
    getNewCondition,
    getSubConditionsIdsList,
    getContextValue,
    getSelectedContextKeyPair,
    getSubConditionQueryParameterSelected,
    getConditionObjectsStatus,
    getPopularContextKeys,
    getContextKeyRequestsThatFailedSelector,
    getSelectedDateRangeSelector,
    getSelectedValueOperatorSelector,
};
