import {Box, BoxProps, createPolymorphicComponent} from '@components/mantine';
import {Navigate, Outlet, Route, Routes, useMatch, useResolvedPath} from '@core/routes';
import {Children, ComponentProps, ReactElement, forwardRef} from 'react';

import {TabsLayoutProvider} from './TabsLayout.context';
import {TabsLayoutPanel} from './TabsLayoutPanel';
import {TabsLayoutTabsList} from './TabsLayoutTabsList';

export interface TabsLayoutProps extends BoxProps {
    children: ReactElement[] | [ReactElement, ReactElement[]];
}

export const TabsLayout = createPolymorphicComponent<
    'div',
    TabsLayoutProps,
    {
        /**
         * This component defines a tab panel within the tab layout.
         * A tab button will be automatically created for each panel when rendering the TabsList.
         */
        Panel: typeof TabsLayoutPanel;
        /**
         * This component renders the tabs list (the tab buttons).
         */
        TabsList: typeof TabsLayoutTabsList;
    }
>(
    forwardRef<HTMLDivElement, TabsLayoutProps>(({children, ...others}, ref) => {
        const {pathname: basePath} = useResolvedPath('.', {relative: 'route'});
        const convertedChildren = Children.toArray(children) as ReactElement[];
        const otherChildren = convertedChildren.filter((child) => child.type !== TabsLayoutPanel);
        const tabs = convertedChildren.filter((child) => child.type === TabsLayoutPanel) as Array<
            ReactElement<ComponentProps<typeof TabsLayoutPanel>, typeof TabsLayoutPanel>
        >;
        const defaultTab = tabs.find((tab) => tab.props.default)?.props.path ?? tabs[0].props.path;
        const activeTab = useMatch(basePath + `/:tab/*`)?.params.tab ?? defaultTab;

        return (
            <TabsLayoutProvider value={{tabs, activeTab}}>
                {otherChildren}
                <Routes>
                    <Route
                        path="/"
                        element={
                            <Box ref={ref} {...others}>
                                <Outlet />
                            </Box>
                        }
                    >
                        <Route index element={<Navigate to={`./${defaultTab}`} replace />} />
                        {tabs.map((tab) => (
                            <Route key={tab.props.path} path={`${tab.props.path}/*`} element={tab} />
                        ))}
                        <Route path="*" element={<Navigate to={`./${defaultTab}`} replace />} />
                    </Route>
                </Routes>
            </TabsLayoutProvider>
        );
    }),
);
TabsLayout.Panel = TabsLayoutPanel;
TabsLayout.TabsList = TabsLayoutTabsList;
TabsLayout.displayName = '@components/page/layouts/TabsLayout';
