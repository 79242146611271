import _ from 'underscore';
import {AllowedIdentityAttributes} from '../models/AllowedIdentityModel';

export const IdentityFeedbackState = {
    VALID: 'VALID',
    WARNING: 'WARNING',
    ERROR: 'ERROR',
};

export const AllowedIdentitiesActionsType = {
    SetAllowedIdentities: 'SET_ALLOWED_IDENTITIES',
    AddAllowedIdentity: 'ADD_ALLOWED_IDENTITY',
    RemoveAllowedIdentity: 'REMOVE_ALLOWED_IDENTITY',
    ClearAllowedIdentitiesStates: 'CLEAR_ALLOWED_IDENTITIES_STATES',
    SetIdentityFeedbackState: 'SET_IDENTITY_FEEDBACK_STATE',
};

export interface IAllowedIdentitiesAction {
    type: string;
    payload?: {
        allowedIdentities?: AllowedIdentityAttributes[];
        id?: string;
        identityFeedbackState?: string;
    };
}

export const setAllowedIdentities = (allowedIdentities: AllowedIdentityAttributes[]): IAllowedIdentitiesAction => ({
    type: AllowedIdentitiesActionsType.SetAllowedIdentities,
    payload: {
        allowedIdentities,
    },
});

export const addAllowedIdentity = (): IAllowedIdentitiesAction => ({
    type: AllowedIdentitiesActionsType.AddAllowedIdentity,
});

export const removeAllowedIdentity = (id: string): IAllowedIdentitiesAction => ({
    type: AllowedIdentitiesActionsType.RemoveAllowedIdentity,
    payload: {
        id,
    },
});

export const clearAllowedIdentitiesStates = (): IAllowedIdentitiesAction => ({
    type: AllowedIdentitiesActionsType.ClearAllowedIdentitiesStates,
});

export const setIdentityFeedbackState = (identityFeedbackState: string): IAllowedIdentitiesAction => ({
    type: AllowedIdentitiesActionsType.SetIdentityFeedbackState,
    payload: {
        identityFeedbackState: _.contains(_.values(IdentityFeedbackState), identityFeedbackState)
            ? identityFeedbackState
            : IdentityFeedbackState.VALID,
    },
});
