export const PlatformLaunchDarklyFeatureFlags = {
    // Platform Unit Feature Flags
    SurveyIncognitoOrgs: 'survey-incognito-orgs',
    VisitBrowserFeatureFlag: 'visit_browser_mantine',
    // Query Execution Unit Feature Flags
    ExtensionsMantine: 'ld-extensions-mantine',
    ClusterPageRefactor: 'ld-cluster-mantine',
    PipelinesListingMetrics: 'ld-pipelines-listing-metrics',
    PipelinesNavigationRevamp: 'ld-pipelines-navigation-revamp',
    PipelinesOverviewMetrics: 'ld-pipelines-overview-metrics-tab',
    // Content Ingestion Unit Feature Flags
    AddSourceModalLicenseUpgradeRequest: 'ld-ciu-add-source-modal-license-upgrade-request',
    AddSourceModalSourceSuggestion: 'ld-ciu-add-source-modal-source-suggestion',
    CatalogSourceSpgMigration: 'catalog-source-spg-migration',
    ConfluenceSpaceFilteringRevamp: 'ctsvc_confluence_space_filtering_revamp',
    CustomLoginSequenceStepActionTypeToLowercase: 'ld-ciu-custom-login-sequence-step-action-type-to-lowercase',
    ConfluenceServerSourceRevamp: 'confluence_server_source_revamp',
    DocumentConfiguration: 'document-configuration',
    GenericRestSourceRevamp: 'generic_rest_source_revamp',
    JiraServerSourceRevamp: 'jira_server_source_revamp',
    KhorosSourceRevamp: 'khoros_source_revamp',
    MantineSourceListUserFeedback: 'ld-ctcore-mantine-source-list-user-feedback',
    NewSecurityIdentitiesFilter: 'new_security_identities_filter',
    PushApiKeyTemplate: 'push-api-key-template',
    PushSourceSpgMigration: 'push-source-spg-migration',
    ReworkSourcePanelGeneratorCore: 'ld-ctcore-rework-source-panel-generator-core',
    SnapshotCreationFlow: 'snapshot-creation-flow',
    SourceEditJsonModalRevamp: 'ld-ciu-source-edit-json-modal-revamp',
    SourceSalesforceSitesNewSourceType: 'ld-ctsvc-source-salesforce-sites-new-source-type',
    UseFixedBasicFilterRegexTranslation: 'ld-ciu-use-fixed-basic-filter-regex-translation',
    ZendeskSourceRevamp: 'zendesk_source_revamp',
    SecurityCacheMantineMigration: 'ld-security-cache-mantine-migration',
    // Usage Analytics Unit Feature Flags
    RawDataExportTabInMantine: 'raw_data_exports_tab_mantine',
    SnowflakeTabInMantine: 'snowflake_tab_in_mantine',
    ReportsInMantine: 'mantine-ua-reports',
    RawDataExportScheduleTabInMantine: 'raw_data_export_schedules_tab_mantine',
    // Machine Learning Platform Unit Feature Flags
    TemporaryDisableModelFunctionalities: 'ml_temporary_disable_model_creation',
    ActivateEntitlementValidation: 'ml_activate_entitlement_validation',
    // Service Unit Feature Flags
    CreateIPXPipelines: 'allow-create-ipx-pipelines',
    IPXv1Creation: 'enable-jsui-ipx',
    MantineSearchPageIPXPages: 'mantine_search_page_ipx_pages',
    NewSearchPageRoutes: 'enable-new-search-page-routes',
    // Query Pipelines Feature Flags
    MLEditAssociationMigration: 'ml_edit_association_migration',
} as const;
