import {QueryClient, QueryClientProvider} from '@core/api';
import {Framework, OwnershipProvider, Team} from '@core/debug';
import {getStore} from '@core/store';
import {FunctionComponent, ReactNode, Suspense} from 'react';
import {Provider} from 'react-redux';
import {ConditionalStrictMode} from './ConditionalStrictMode';
import {DebugBar} from './DebugBar';

export interface AppProps {
    children: ReactNode;
    owner: Team;
    framework: Framework;
}

export const App: FunctionComponent<AppProps> = ({children, owner, framework}) => {
    const queryClient = new QueryClient({defaultOptions: {queries: {refetchOnWindowFocus: false}}});

    return (
        <ConditionalStrictMode>
            <OwnershipProvider defaultOwner={owner} defaultFramework={framework}>
                <QueryClientProvider client={queryClient}>
                    <Provider store={getStore()}>
                        <Suspense>
                            {children}
                            <DebugBar />
                        </Suspense>
                    </Provider>
                </QueryClientProvider>
            </OwnershipProvider>
        </ConditionalStrictMode>
    );
};
