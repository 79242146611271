import {To} from 'react-router-dom';

export const injectOrganizationId = (to: To, orgId: string): To =>
    typeof to === 'string' ? injectOrg(to, orgId) : {...to, pathname: injectOrg(to.pathname ?? '', orgId)};

const injectOrg = (path: string, orgId: string | null) => {
    if (
        path.startsWith(`/${orgId}/`) || // Don't prepend the orgId if it's already there
        !path.startsWith('/') // Don't prepend the ordId if it's a relative path
    ) {
        return path;
    }

    return `/${orgId}${path}`;
};
