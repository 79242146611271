import {IndexingPipelineLogEntry} from '@core/api';
import {CommonActions, IReduxAction} from '@coveord/plasma-react';

import {ILogBrowserActionsPayload} from '../LogBrowserStore';
import {LogsConstants} from './LogsConstants';

export interface ILogEntryState extends Omit<IndexingPipelineLogEntry, 'organizationId' | 'requestId'> {
    uri: string;
}

export interface ILogsState {
    logEntries: ILogEntryState[];
}

export const logInitialState: ILogEntryState = {
    id: undefined,
    guid: undefined,
    uri: undefined,
    task: undefined,
    result: undefined,
    operation: undefined,
    datetime: undefined,
    meta: undefined,
    sourceId: undefined,
    resourceId: undefined,
};

export const logsInitialState: ILogsState = {
    logEntries: null,
};

export const logsReducer = (
    state: ILogsState = logsInitialState,
    action: IReduxAction<ILogBrowserActionsPayload>,
): ILogsState => {
    switch (action.type) {
        case LogsConstants.Requests.update:
            return {
                logEntries: action.payload.logs.logEntries,
            };
        case CommonActions.clearState:
            return logsInitialState;
        default:
            return state;
    }
};
