import isEmail, {IsEmailOptions} from 'validator/lib/isEmail';
import {commonLocales} from '../CommonLocales';
import {InputValidatorResponse} from './';

declare type InputValidatorWithOptions = (
    value: string,
    options?: IsEmailOptions | undefined,
) => {
    status: 'valid' | 'invalid' | 'warning';
    message?: string;
};

const validateInput: InputValidatorWithOptions = (
    value: string,
    options?: IsEmailOptions | undefined,
): InputValidatorResponse => {
    if (!!isEmail(value?.trim(), options)) {
        return {status: 'valid'};
    } else {
        return {status: 'invalid', message: commonLocales.format('emailIsNotValid')};
    }
};

export const emailValidator = {
    validateInput,
};
