import {lazy} from 'react';

export const DebugBar = lazy(async () => {
    if (__USE_DEBUG_BAR__?.toLowerCase() !== 'true') {
        return Promise.resolve({default: () => <></>});
    }

    const module = await import('@components/debug-bar');
    return {default: () => <module.DebugBar withRightOffset />};
});
