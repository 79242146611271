import {Config} from '@core/configuration';
import Registry from '@core/registry';
import {IThunkAction} from '@coveord/plasma-react';
import _ from 'underscore';
import * as s from 'underscore.string';

import {UAInjectable} from '../../UAInjectable';
import {UsageAnalyticsState} from '../../UsageAnalyticsState';
import {UAUserAttributes} from '../UAUser';

export const UAUsersActionsType = {
    FetchUsersStarted: 'UA_FETCH_USERS_STARTED',
    FetchUsersSuccess: 'UA_FETCH_USERS_FULFILLED',
    FetchUsersFailure: 'UA_FETCH_USERS_FAILED',
};

export interface FetchUsersStartedPayload {}
export interface FetchUsersFailedPayload {}
export interface FetchUsersSuccessPayload {
    users: UAUserAttributes[];
}

interface ParsedUserProfile {
    firstName: string;
    lastName: string;
}

interface ParsedUserAttributes {
    displayName?: string;
    userProfile?: ParsedUserProfile;
    email?: string;
    username?: string;
    providerUsername?: string;
}

const fetchUsers = (): IThunkAction<JQueryPromise<any>, UsageAnalyticsState> => (dispatch) => {
    dispatch({
        type: UAUsersActionsType.FetchUsersStarted,
        payload: {},
    });

    return $.get(`${Config.CloudPlatform.url}/rest/organizations/{organizationName}/members`)
        .done((data) => {
            const loggedUserAttributes: ParsedUserAttributes = Registry.get(UAInjectable.User).toJSON();
            const users = [].concat(loggedUserAttributes, data);
            data = _.chain(users)
                .indexBy('username')
                .map((user: ParsedUserAttributes) => {
                    user.username = user.username || user.email;
                    user.displayName =
                        s.trim(user.displayName) || s.trim(user.providerUsername) || user.email || user.username;

                    return user;
                })
                .value();
            dispatch({
                type: UAUsersActionsType.FetchUsersSuccess,
                payload: {
                    users: data,
                },
            });
        })
        .fail((xhr: JQueryXHR) => {
            xhr.errorHandled = true;
            dispatch({
                type: UAUsersActionsType.FetchUsersFailure,
                payload: {},
            });
        });
};

export const UAUsersActions = {
    fetchUsers,
};
