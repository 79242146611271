export interface InjectedComponentFactory {
    initializeComponentFromComponentDataAttribute: (
        dataComponentAttribute: string,
        target?: string,
        data?: any,
    ) => Marionette.Object;
}

export const UAInjectable = {
    User: 'UA:User',
    ComponentFactory: 'UA:ComponentFactory',
};
