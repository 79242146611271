import * as ReactVapor from '@coveord/plasma-react';
import _ from 'underscore';
import {
    FetchUsersFailedPayload,
    FetchUsersStartedPayload,
    FetchUsersSuccessPayload,
    UAUsersActionsType,
} from '../actions/UAUsersActions';
import {UAUsersDefaultState, UAUsersState} from '../UAUsersState';

const fetchUsersReducerSuccess = (
    state: UAUsersState,
    action: ReactVapor.IReduxAction<FetchUsersSuccessPayload>,
): UAUsersState => {
    const newState = _.extend({}, state, {
        error: false,
        loaded: true,
        users: action.payload.users,
    });
    return newState;
};

const fetchUsersReducerStarted = (
    state: UAUsersState,
    action: ReactVapor.IReduxAction<FetchUsersStartedPayload>,
): UAUsersState => _.extend({}, state, {loaded: false, error: false});

const fetchUsersReducerFailed = (
    state: UAUsersState,
    action: ReactVapor.IReduxAction<FetchUsersFailedPayload>,
): UAUsersState => _.extend({}, state, {error: true});

const UAUsersReducers: {[key: string]: (...args: any[]) => any} = {
    [UAUsersActionsType.FetchUsersStarted]: fetchUsersReducerStarted,
    [UAUsersActionsType.FetchUsersSuccess]: fetchUsersReducerSuccess,
    [UAUsersActionsType.FetchUsersFailure]: fetchUsersReducerFailed,
};

export const UAUsersReducer = (
    state: UAUsersState = UAUsersDefaultState,
    action: ReactVapor.IReduxAction<FetchUsersStartedPayload | FetchUsersFailedPayload | FetchUsersSuccessPayload>,
) => {
    let newState = state;
    if (!_.isUndefined(UAUsersReducers[action.type])) {
        newState = UAUsersReducers[action.type](state, action);
    }
    return newState;
};
