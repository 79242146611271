import {Dictionary, Locales} from '@core/locales';
import _ from 'underscore';

import {LocalesKeys} from './LocalesKeys';
import DatePicker from './strings/DatePicker.json';
import ErrorCodes from './strings/ErrorCodes.json';
import Operations from './strings/Operations.json';
import Results from './strings/Results.json';
import Strings from './strings/Strings.json';
import Tasks from './strings/Tasks.json';

const req: Record<string, Record<string, any>> = {DatePicker, ErrorCodes, Operations, Results, Strings, Tasks};
const translations = {};

Object.keys(req).forEach((key: string) => {
    const parts: string[] = key.split('/');
    const roots: string[] = parts.splice(1, parts.length - 2);

    let map: any = translations;

    _.each(roots, (root: string) => {
        if (!map[root]) {
            _.extend(map, {[root]: {}});
        }
        map = map[root];
    });

    _.extend(map, req[key]);
});

const enDictionary: Dictionary = {
    locale: 'en',
    translations,
};

export const locales = new Locales<LocalesKeys>({
    dictionaries: [enDictionary],
    defaultLocale: 'en',
});
