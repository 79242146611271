import {IDispatch, IReduxAction, IThunkAction, RadioSelectSelectors} from '@coveord/plasma-react';
import {ModalSelectUtils} from './ModalSelectUtils';

export interface IModalSelectActionPayload {
    id: string;
    value?: string;
}

export const ModalSelectActionTypes = {
    set: 'SET_MODALSELECT',
    remove: 'REMOVE_MODALSELECT',
};

const setModalSelect = (id: string, value?: string): IReduxAction<IModalSelectActionPayload> => ({
    type: ModalSelectActionTypes.set,
    payload: {
        id,
        value,
    },
});

const removeModalSelect = (id: string): IReduxAction<IModalSelectActionPayload> => ({
    type: ModalSelectActionTypes.remove,
    payload: {id},
});

const setModalValueFromRadio =
    (id: string): IThunkAction =>
    (dispatch: IDispatch, getState) => {
        const radioValue = RadioSelectSelectors.getValue(getState(), {
            id: ModalSelectUtils.getRadioSelectIdFromModalSelectId(id),
        });
        return dispatch(setModalSelect(id, radioValue));
    };

export const ModalSelectActions = {
    setModalSelect,
    removeModalSelect,
    setModalValueFromRadio,
};
