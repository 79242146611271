// TODO: UITOOL 8779
// eslint-disable-next-line @helpers/no-jsadmin-import
import {DateUtils, deepClone, generateUUID} from '@coveord/jsadmin-common';
import {ReactNode} from 'react';
import {Operators, QPLContextTypes, QPLJoinOperators, QueryParameters} from '../constants';
import {IConditionList, IConditionObjects, IDetailedCondition, IDetailedObject, ISubCondition} from '../interfaces';

const negativeOperator = 'not';

const isDetailedObject = (detailed: IDetailedCondition | IDetailedObject): detailed is IDetailedObject =>
    detailed && !!(detailed as IDetailedObject).object;

const formatOperatorIfRequired = (operator) =>
    Operators.OperatorSpecialValues.hasOwnProperty(operator) ? Operators.OperatorSpecialValues[operator] : operator;

const dateIsValid = (date: any) => !isNaN(date) && date instanceof Date;

const parseDatesIfPossible = (from: string, to: string) => {
    const fromDate = new Date(from);
    const toDate = new Date(to);
    const dateFormat = 'lll';

    return {
        from: dateIsValid(fromDate) ? DateUtils.format(fromDate, dateFormat, DateUtils.UserTimezone) : from,
        to: dateIsValid(toDate) ? DateUtils.format(toDate, dateFormat, DateUtils.UserTimezone) : to,
    };
};

const extractCondition = (
    detailed: IDetailedCondition,
    prettyConditionsMap: {[key: string]: IConditionObjects},
    uuid = generateUUID(5),
): ReactNode[] => {
    const prettyCondition: ReactNode[] = [];
    if (isDetailedObject(detailed.left)) {
        const {friendlyName, name} = prettyConditionsMap[`$${detailed.left.object}`] || ({} as IConditionObjects);
        prettyCondition.push(friendlyName || name);

        if ('$' + detailed.left.object === QueryParameters.Context) {
            prettyCondition.push(`[${detailed.left.key}]`);
        }

        prettyCondition.push(
            ' ',
            <span key={`${uuid}_${name}_${detailed.operator}_left`} className="text">
                {formatOperatorIfRequired(detailed.operator)}
            </span>,
        );
    } else if (detailed.left) {
        const leftObject: IDetailedCondition = deepClone(detailed.left);
        const shouldNegateExpression = detailed.operator === negativeOperator && detailed.right === undefined;

        if (shouldNegateExpression) {
            leftObject.operator = Operators.NegationOperators[detailed.left.operator];
        }

        prettyCondition.push(...extractCondition(leftObject, prettyConditionsMap));

        if (!shouldNegateExpression) {
            prettyCondition.push(
                ' ',
                <span key={`${uuid}_${detailed.operator}_left`} className="text">
                    {formatOperatorIfRequired(detailed.operator)}
                </span>,
            );
        }
    }

    if (typeof detailed.right?.valueOf() === 'string') {
        prettyCondition.push(` ${detailed.right} `);
    } else if (typeof detailed.right === 'boolean') {
        prettyCondition.push(
            <span className="italic text" key={`${uuid}_${detailed.operator}_right`}>
                {` ${detailed.right} `}
            </span>,
        );
    } else if (typeof detailed.right === 'object') {
        prettyCondition.push(' ', ...extractCondition(detailed.right, prettyConditionsMap), ' ');
    } else if (isDetailedObject(detailed.left) && detailed.from && detailed.to) {
        const {from, to} = parseDatesIfPossible(detailed.from, detailed.to);
        prettyCondition.push(` ${from} `);
        prettyCondition.push(
            <span key={`${uuid}_${detailed.operator}_left`} className="text">
                {QPLJoinOperators.And}
            </span>,
        );
        prettyCondition.push(` ${to} `);
    }

    return prettyCondition;
};

const shouldCombineValues = (left: any, right: any, detailed: IDetailedCondition | any): boolean =>
    left[0].operator === right[0].operator &&
    left[0].queryParameter === right[0].queryParameter &&
    detailed.operator === QPLJoinOperators.Or &&
    left[0].contextType !== QPLContextTypes.BooleanType &&
    right[0].contextType !== QPLContextTypes.BooleanType;

const extractSubCondition = (detailed: IDetailedCondition | any): IConditionList => {
    if (!detailed) {
        return [];
    }

    let left: any = [];
    let right: any = [];

    if (isDetailedObject(detailed.left)) {
        const parameterQueryTimeUtc = 'queryTimeUtc';
        const isTimestamp = detailed.left.object === parameterQueryTimeUtc;
        const leftObject: ISubCondition = {
            queryParameter: '$' + detailed.left.object,
            key: detailed.left.key || '',
            operator: detailed.operator,
            values: [],
        };
        if (isTimestamp) {
            leftObject.from = detailed.from;
            leftObject.to = detailed.to;
        } else if (detailed.right !== undefined) {
            if (typeof detailed.right === 'boolean') {
                leftObject.contextType = QPLContextTypes.BooleanType;
                leftObject.values = [detailed.right.toString()];
            } else {
                leftObject.values = [detailed.right];
            }
        } else {
            leftObject.values = [];
        }
        left = [leftObject];
    } else if (detailed.left) {
        left = extractSubCondition(detailed.left);
    }

    if (detailed.right) {
        right = extractSubCondition(detailed.right);
    }

    if (typeof detailed.operator?.valueOf() === 'string' && typeof detailed.right === 'object') {
        if (shouldCombineValues(left, right, detailed)) {
            left[0].values.push(...right[0].values);
            return [...left, ...right.slice(1)];
        }

        return [...left, {combineOperator: detailed.operator}, ...right];
    }

    if (detailed.operator === negativeOperator && detailed.right === undefined) {
        left[0].operator = Operators.NegationOperators[left[0].operator];
    }

    return [...left, ...right];
};

const parseQPLJoinOperator = (operator: string): QPLJoinOperators => {
    switch (operator) {
        case QPLJoinOperators.And:
            return QPLJoinOperators.And;
        case QPLJoinOperators.Or:
            return QPLJoinOperators.Or;
        default:
            throw Error(`Unsupported QPL join operator: ${operator}`);
    }
};

export const ConditionsHelpers = {
    extractCondition,
    extractSubCondition,
    parseQPLJoinOperator,
};
