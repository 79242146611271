export const CaseAssistConfigurationConstants = {
    Scopes: {
        DocumentSuggestion: 'DOCUMENT_SUGGESTION',
        CaseClassification: 'CASE_CLASSIFICATION',
    },
    MLEngineIds: {
        CaseClassificationModel: 'caseclassification',
    },
    ComponentIds: {
        NameInput: 'name-input',
        ProjectAssociationMultiSelect: 'project-association-multi-select',
        ValidatingMarker: 'form-is-validating',
        CancelEditModal: 'cancel-case-assist-edit-modal',
        DocumentSuggestion: {
            ActionBar: 'document-suggestion-action-bar',
            QueryPipelines: 'document-suggestion-query-pipelines',
            QueryPipelineReadOnly: 'document-suggestion-query-pipeline-readonly',
            FilterInput: 'filter-input',
        },
        CaseClassification: {
            ActionBar: 'case-classification-action-bar',
            FilterInput: 'case-classification-filter-input',
            FieldsToPredictInput: 'case-classification-fields-to-predict-input',
            FieldSecurityWarning: 'field-security-warning',
            StrategyModalSelect: 'case-classification-strategy-modal',
            ModelSelect: 'case-classification-model-select',
            ModelSelectReadOnly: 'case-classification-model-select-readonly',
        },
        Preview: {
            CaseSubject: 'case-subject-input',
            CaseDescription: 'case-description-input',
        },
    },
};
