import _ from 'underscore';
import * as s from 'underscore.string';

export class Strings {
    static containsIgnoreCase(str: string, test: string) {
        if (!str) {
            return false;
        }
        if (str && !test) {
            return true;
        }

        str = str.toLowerCase();
        test = test.toLowerCase().trim();

        return s.include(str, test);
    }

    static appendIfNotPresent(value: string, suffix: string) {
        if (!s.endsWith(value, suffix)) {
            return value + suffix;
        }
        return value;
    }

    static prependIfNotPresent(value: string, prefix: string) {
        if (!s.startsWith(value, prefix)) {
            return prefix + value;
        }
        return value;
    }

    static splitAndTrimValue(val: string) {
        let values = val.toString().split(',');
        values = _.compact(values);
        values = _.invoke(values, 'trim');
        values = _.compact(values);
        values = _.uniq(values);

        return values;
    }

    static integerValueOf(val: string) {
        const chars = val.split('');
        const charsAscii = _.map(chars, (char) => char.charCodeAt(0));
        return charsAscii.reduce((a, b) => a + b, 0);
    }

    static isEmpty(val: any) {
        return _.isEmpty((val || '').toString());
    }

    static getAllRegexMatch(str: string, regex, groupIndex?: number): string[] {
        let regexResults = [];
        let tempRegexResult;
        do {
            tempRegexResult = str.match(regex);
            if (tempRegexResult && tempRegexResult.length > 0) {
                if (groupIndex) {
                    regexResults.push(tempRegexResult[groupIndex]);
                } else {
                    regexResults = regexResults.concat(tempRegexResult);
                }
                str = str.slice(tempRegexResult.index + tempRegexResult[0].length);
            }
        } while (tempRegexResult && tempRegexResult.length > 0);

        return regexResults;
    }

    static addEllipsis(str: string): string {
        return str + '...';
    }

    static escapeBackspacesAndFormfeeds(str: string): string {
        return str.replace(/[\b]/g, '\\b').replace(/\f/g, '\\f');
    }

    static unescapeBackspacesAndFormfeeds(str: string): string {
        return str.replace(/\\b/g, '\b').replace(/\\f/g, '\f');
    }

    static reverse(str: string): string {
        return str.split('').reverse().join('');
    }

    /**
     * Hash a given string to a unique hash value
     * Took from https://jsperf.com/hashing-strings
     */
    static hash(str: string) {
        let res = 0;
        for (let i = 0; i < str.length; i++) {
            res = res * 31 + str.charCodeAt(i);
            /* eslint-disable-next-line no-bitwise */
            res = res & res;
        }
        return res;
    }
}
