import {IData, IReduxAction} from '@coveord/plasma-react';
import {SfObjectFieldModel} from './fields/SfObjectFieldModel';

export interface SalesforceRowActionPayload {
    row: IData;
}

export interface SalesforceFieldsActionPayload {
    fields: SfObjectFieldModel[];
}

export const SalesforceActions: {[key: string]: string} = {
    setSelectedObjectRow: 'SET_SALESFORCE_SELECTED_OBJECT_ROW',
    setSelectedObjectFieldRow: 'SET_SALESFORCE_SELECTED_OBJECT_FIELD_ROW',
    setFieldsLoading: 'SET_SALESFORCE_FIELDS_LOADING',
    setFieldsInError: 'SET_SALESFORCE_FIELDS_IN_ERROR',
    setFields: 'SET_SALESFORCE_FIELDS_LOADED',
    toggleFieldSelected: 'TOGGLE_SALESFORCE_FIELD_SELECTED',
};

export const setSelectedObjectRow = (row: IData): IReduxAction<SalesforceRowActionPayload> => ({
    type: SalesforceActions.setSelectedObjectRow,
    payload: {
        row,
    },
});

export const setSelectedObjectFieldRow = (row: IData): IReduxAction<SalesforceRowActionPayload> => ({
    type: SalesforceActions.setSelectedObjectFieldRow,
    payload: {
        row,
    },
});

export const setFieldsLoading = (): IReduxAction => ({
    type: SalesforceActions.setFieldsLoading,
});

export const setFieldsInError = (): IReduxAction => ({
    type: SalesforceActions.setFieldsInError,
});

export const setFields = (fields: SfObjectFieldModel[]): IReduxAction<SalesforceFieldsActionPayload> => ({
    type: SalesforceActions.setFields,
    payload: {
        fields,
    },
});
