import {Dictionary, Locales} from '@core/locales';
import _ from 'underscore';

import {SOLocalesKeys} from './SOLocalesKeys';
import ABTest from './strings/ABTest.json';
import Associations from './strings/associations.json';
import Conditions from './strings/conditions.json';
import Configuration from './strings/Configuration.json';
import CopyStatement from './strings/CopyStatement/copyStatement.json';
import Description from './strings/Description/description.json';
import Documentation from './strings/Documentation.json';
import EditStatementCode from './strings/EditStatementCode/EditStatementCode.json';
import FeaturedResults from './strings/featuredResults.json';
import Filters from './strings/Filters/Filters.json';
import Global from './strings/global.json';
import Navigation from './strings/navigation.json';
import QueryPipelinePage from './strings/QueryPipelinePage.json';
import SearchSettings from './strings/QueryPipelines/Advanced/SearchSettings/search-settings.json';
import QueryPipelinesV2 from './strings/QueryPipelines/query-pipelines-v2.json';
import QueryPipelines from './strings/QueryPipelines/queryPipelines.json';
import RankingExpressions from './strings/RankingExpressions.json';
import RankingWeights from './strings/RankingWeights.json';
import ResultRanking from './strings/ResultRanking/resultRanking.json';
import ResultRankingDocs from './strings/ResultRanking/resultRankingDocs.json';
import SearchUI from './strings/search-ui.json';
import PostCreationModal from './strings/StatementGroups/postCreationModal.json';
import SelectGroup from './strings/StatementGroups/selectGroup.json';
import StatementGroups from './strings/StatementGroups/statementGroups.json';
import StatementsHeader from './strings/Statements/Header/StatementsHeader.json';
import Overview from './strings/Statements/Overview/overview.json';
import Statements from './strings/Statements/statements.json';
import StopWords from './strings/stopWords.json';
import Synonyms from './strings/Synonyms/synonyms.json';
import Table from './strings/table.json';
import Triggers from './strings/Triggers/triggers.json';

const translations = {};

const req: Record<string, Record<string, any>> = {
    'strings/ABTest': ABTest,
    'strings/Associations': Associations,
    'strings/Conditions': Conditions,
    'strings/CopyStatement/copyStatement': CopyStatement,
    'strings/Documentation': Documentation,
    'strings/EditStatementCode/EditStatementCode': EditStatementCode,
    'strings/FeaturedResults': FeaturedResults,
    'strings/Filters/Filters': Filters,
    'strings/Global': Global,
    'strings/Navigation': Navigation,
    'strings/QueryPipelines/Advanced/SearchSettings/search-settings': SearchSettings,
    'strings/QueryPipelines/queryPipelines': QueryPipelines,
    'strings/QueryPipelinePage': QueryPipelinePage,
    'strings/QueryPipelines/query-pipelines-v2': QueryPipelinesV2,
    'strings/RankingExpressions': RankingExpressions,
    'strings/RankingWeights': RankingWeights,
    'strings/ResultRanking/resultRanking': ResultRanking,
    'strings/ResultRanking/resultRankingDocs': ResultRankingDocs,
    'strings/SearchUI': SearchUI,
    'strings/StatementGroups/selectGroup': SelectGroup,
    'strings/StatementGroups/statementGroups': StatementGroups,
    'strings/StatementGroups/postCreationModal': PostCreationModal,
    'strings/Statements/Overview/overview': Overview,
    'strings/Configuration': Configuration,
    'strings/Statements/statements': Statements,
    'strings/Statements/Header/StatementsHeader': StatementsHeader,
    'strings/StopWords': StopWords,
    'strings/Synonyms/synonyms': Synonyms,
    'strings/Table': Table,
    'strings/Triggers/triggers': Triggers,
    'strings/Description/description': Description,
};

Object.keys(req).forEach((key) => {
    const parts: string[] = key.split('/');
    const roots: string[] = parts.splice(1, parts.length - 2);

    let map: any = translations;

    _.each(roots, (root) => {
        if (!map[root]) {
            _.extend(map, {[root]: {}});
        }
        map = map[root];
    });

    _.extend(map, req[key]);
});

const enDictionary: Dictionary = {
    locale: 'en',
    translations,
};

export const locales: Locales<SOLocalesKeys> = new Locales<SOLocalesKeys>({
    dictionaries: [enDictionary],
    defaultLocale: 'en',
});
