import {PlatformPaths} from '@configurations/platform';
import {StatementsFeature} from '@core/api';
import {RouteObject} from '@core/routes';

export const routes: RouteObject[] = [
    {
        path: `${PlatformPaths.QueryPipelines.fullPath}/*`,
        lazy: async () => {
            const {QueryPipelinesPage} = await import('../query-pipelines/QueryPipelinesPage');
            return {Component: QueryPipelinesPage};
        },
        children: [
            {
                path: 'new',
                lazy: async () => {
                    const {AddQueryPipelineModal} = await import('../query-pipelines/add/AddQueryPipelineModal');
                    return {Component: AddQueryPipelineModal};
                },
            },
            {
                path: ':pipelineId/import',
                lazy: async () => {
                    const {ImportExportPipelineModal} = await import(
                        '../query-pipelines/import-export/ImportExportPipelineModal'
                    );
                    return {
                        Component: () => <ImportExportPipelineModal type="import" />,
                    };
                },
            },
            {
                path: ':pipelineId/export',
                lazy: async () => {
                    const {ImportExportPipelineModal} = await import(
                        '../query-pipelines/import-export/ImportExportPipelineModal'
                    );
                    return {
                        Component: () => <ImportExportPipelineModal type="export" />,
                    };
                },
            },
        ],
    },
    {
        path: `${PlatformPaths.EditPipeline.fullPath}/*`,
        lazy: async () => {
            const {QueryPipelinePage} = await import('../query-pipelines/QueryPipelinePage');
            return {Component: QueryPipelinePage};
        },
    },
    {
        path: `${PlatformPaths.EditStatementGroup.fullPath}/*`,
        lazy: async () => {
            const {StatementGroupConfigurationPage} = await import(
                '../statements/statement-groups/configuration/StatementGroupConfigurationPage'
            );
            return {Component: StatementGroupConfigurationPage};
        },
    },
    {
        path: `${PlatformPaths.EditSynonym.fullPath}/*`,
        lazy: async () => {
            const {EditSynonymPage} = await import('../statements/search-terms/synonyms/edit/EditSynonymPage');
            return {Component: EditSynonymPage};
        },
    },
    {
        path: `${PlatformPaths.EditSynonymCode.fullPath}/*`,
        lazy: async () => {
            const {EditStatementCodePage} = await import('../statements/edit-code/EditStatementCodePage');
            return {element: <EditStatementCodePage statementType={StatementsFeature.Thesaurus} />};
        },
    },
    {
        path: `${PlatformPaths.EditResultRanking.fullPath}/*`,
        lazy: async () => {
            const {ResultRankingConfigurationPage} = await import(
                '../statements/result-ranking/edit/ResultRankingConfigurationPage'
            );
            return {Component: ResultRankingConfigurationPage};
        },
    },
    {
        path: `${PlatformPaths.EditResultRankingCode.fullPath}/*`,
        lazy: async () => {
            const {FeaturedResultsJSONEditorPage} = await import(
                '../statements/result-ranking/edit/featured-results/FeaturedResultsJSONEditorPage'
            );
            return {Component: FeaturedResultsJSONEditorPage};
        },
    },
    {
        path: `${PlatformPaths.EditAssociation.fullPath}/*`,
        lazy: async () => {
            const {EditAssociationsPage} = await import('../statements/basics/associations/pages/EditAssociationsPage');
            return {Component: EditAssociationsPage};
        },
    },
    {
        path: `${PlatformPaths.EditAssociationCode.fullPath}/*`,
        lazy: async () => {
            const {EditAssociationsJSONPage} = await import(
                '../statements/basics/associations/pages/EditAssociationsJSONPage'
            );
            return {Component: EditAssociationsJSONPage};
        },
    },
    {
        path: `${PlatformPaths.EditRankingWeight.fullPath}/*`,
        lazy: async () => {
            const {EditRankingWeightPage} = await import(
                '../statements/advanced/ranking-weights/edit/EditRankingWeightPage'
            );
            return {Component: EditRankingWeightPage};
        },
    },
    {
        path: `${PlatformPaths.EditRankingWeightCode.fullPath}/*`,
        lazy: async () => {
            const {EditStatementCodePage} = await import('../statements/edit-code/EditStatementCodePage');
            return {element: <EditStatementCodePage statementType={StatementsFeature.RankingWeight} />};
        },
    },
    {
        path: `${PlatformPaths.EditFilter.fullPath}/*`,
        lazy: async () => {
            const {EditFilterPage} = await import('../statements/advanced/filters/edit/EditFilterPage');
            return {Component: EditFilterPage};
        },
    },
    {
        path: `${PlatformPaths.EditFilterCode.fullPath}/*`,
        lazy: async () => {
            const {EditStatementCodePage} = await import('../statements/edit-code/EditStatementCodePage');
            return {element: <EditStatementCodePage statementType={StatementsFeature.Filter} />};
        },
    },
    {
        path: `${PlatformPaths.EditSearchSetting.fullPath}/*`,
        lazy: async () => {
            const {EditSearchSettingsPage} = await import(
                '../statements/advanced/search-settings/edit/EditSearchSettingsPage'
            );
            return {Component: EditSearchSettingsPage};
        },
    },
    {
        path: `${PlatformPaths.EditSearchSettingCode.fullPath}/*`,
        lazy: async () => {
            const {EditStatementCodePage} = await import('../statements/edit-code/EditStatementCodePage');
            return {element: <EditStatementCodePage statementType={StatementsFeature.QueryParamOverride} />};
        },
    },
    {
        path: `${PlatformPaths.EditTrigger.fullPath}/*`,
        lazy: async () => {
            const {EditTriggerPage} = await import('../statements/advanced/triggers/edit/EditTriggerPage');
            return {Component: EditTriggerPage};
        },
    },
    {
        path: `${PlatformPaths.EditTriggerCode.fullPath}/*`,
        lazy: async () => {
            const {EditStatementCodePage} = await import('../statements/edit-code/EditStatementCodePage');
            return {element: <EditStatementCodePage statementType={StatementsFeature.Trigger} />};
        },
    },
];
