import {notifications} from '@components/mantine';
import {
    CatalogModel,
    CatalogsListOptions,
    CreateCatalogModel,
    PageModel,
    PlatformClientFeatures,
    PlatformNoHandlers,
} from '@core/api';
import {LicenseSelectors} from '@core/license';
import {OrganizationSelectors} from '@core/organization';
import {ProjectSelectors, ProjectState} from '@core/projects';
import {UserSelectors} from '@core/user';
import {RequestsActions} from '@coveord/jsadmin-common';
import {
    IReduxAction,
    ITableHOCCompositeState,
    IThunkAction,
    TableHOCUtils,
    TableWithPaginationActions,
    turnOffLoading,
} from '@coveord/plasma-react';

import {CommerceState} from '../../CommerceState';
import {locales} from '../../Locales';
import {CatalogComponentIds} from '../CatalogComponentIds';
import {CatalogConfigurationConstants} from '../CatalogConfiguration/CatalogConfigurationConstants';
import {CatalogConfigurationSelectors} from '../CatalogConfiguration/CatalogConfigurationSelectors';
import {PrerequisitesActions} from '../Prerequisites/PrerequisitesActions';

export const CatalogsActionsTypes = {
    updateCatalogs: 'UPDATE_CATALOGS',
    fetch: 'FETCH_CATALOGS',
    fetchFields: 'FETCH_CATALOG_FIELDS',
    updateFields: 'UPDATE_CATALOG_FIELDS',
    delete: 'DELETE_CATALOG',
    create: 'CREATE_CATALOG',
};

export interface IUpdateCatalogsPayload {
    catalogs: CatalogModel[];
}

const updateCatalogs = (catalogs: CatalogModel[]): IReduxAction<IUpdateCatalogsPayload> => ({
    type: CatalogsActionsTypes.updateCatalogs,
    payload: {
        catalogs,
    },
});

const getCatalogsByProject = async (
    state: ProjectState,
    pageOptions: CatalogsListOptions,
): Promise<PageModel<CatalogModel>> => {
    const user = UserSelectors.getPrivilegesValidator(state);
    const license = LicenseSelectors.getLicense(state);
    const selectedProject = ProjectSelectors.getSelectedProject(state);

    const isProjectFeaturesEnabled = user.canViewProjects(license);

    if (!isProjectFeaturesEnabled) {
        return PlatformNoHandlers.catalog.list(pageOptions);
    }

    if (selectedProject && !ProjectSelectors.isResourceTypeAssociated(state, 'CATALOG')) {
        return {
            items: [],
            totalEntries: 0,
            totalPages: 0,
        };
    }

    return PlatformNoHandlers.withFeatures(
        // eslint-disable-next-line react-hooks/rules-of-hooks
        PlatformClientFeatures.useProjectFilter(selectedProject, 'CATALOG', '/ids'),
    ).catalog.list(pageOptions);
};

const fetchCatalogs = (): IThunkAction<void, CommerceState> => async (dispatch, getState) => {
    const tableId = CatalogComponentIds.CatalogsTable;
    dispatch(RequestsActions.request(CatalogsActionsTypes.fetch));
    const compositeState: ITableHOCCompositeState = TableHOCUtils.getCompositeState(tableId, getState());
    const pageOptions: CatalogsListOptions = {
        page: compositeState.pageNb,
        pageSize: compositeState.perPage,
        filter: compositeState.filter,
        enrichWithFields: false,
    };

    try {
        const catalogs = await getCatalogsByProject(getState(), pageOptions);
        dispatch(updateCatalogs(catalogs.items));
        dispatch(TableWithPaginationActions.setCount(tableId, catalogs.totalEntries));
        dispatch(turnOffLoading([tableId]));
        dispatch(RequestsActions.success(CatalogsActionsTypes.fetch));
    } catch (exception) {
        dispatch(RequestsActions.failure(CatalogsActionsTypes.fetch, exception));
        notifications.showError(locales.format('fetchCatalogsFailed'));
    }
};

const deleteCatalog =
    (catalogId: string): IThunkAction<Promise<void>, CommerceState> =>
    async (dispatch) => {
        dispatch(RequestsActions.request(CatalogsActionsTypes.delete));
        try {
            await PlatformNoHandlers.catalog.delete(catalogId);
            dispatch(RequestsActions.success(CatalogsActionsTypes.delete));
            dispatch(reloadCatalogsTable());
        } catch (exception) {
            dispatch(RequestsActions.failure(CatalogsActionsTypes.delete, exception));
            notifications.showError(locales.format('deleteCatalogFailed'));
        }
    };

const createCatalog = (): IThunkAction<Promise<boolean>, CommerceState> => async (dispatch, getState) => {
    dispatch(RequestsActions.request(CatalogsActionsTypes.create));

    const state = getState();
    const {name, description, scope, hasVariant, hasAvailability} =
        CatalogConfigurationSelectors.getEditedPartialCatalog(state);
    const catalog = {
        name,
        description,
        scope: scope,
        product: CatalogConfigurationConstants.Default.Product,
        variant: hasVariant ? CatalogConfigurationConstants.Default.Variant : undefined,
        availability: hasAvailability ? CatalogConfigurationConstants.Default.Availability : undefined,
    } as CreateCatalogModel;

    try {
        await PlatformNoHandlers.catalog.create(catalog as any);
        dispatch(RequestsActions.success(CatalogsActionsTypes.create));
        dispatch(reloadCatalogsTable());
        return true;
    } catch (exception) {
        dispatch(RequestsActions.failure(CatalogsActionsTypes.create, exception));
        notifications.showError(locales.format('createCatalogFailed'));
    }
    return false;
};

const reloadCatalogsTable = (): IThunkAction<void, CommerceState> => async (dispatch, getState) => {
    const state = getState();
    dispatch(CatalogsActions.fetchCatalogs());
    dispatch(PrerequisitesActions.validatePrerequisites(OrganizationSelectors.getOrganizationId(state)));
};

export const CatalogsActions = {
    reloadCatalogsTable,
    fetchCatalogs,
    deleteCatalog,
    createCatalog,
};
