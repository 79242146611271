import {FilterConditions} from '@core/api';
import {BasePayload} from '@coveord/plasma-react';

export interface FieldFilterActionPayload extends BasePayload {
    filter: FilterConditions;
    fieldFilterSetId: string;
}

export const FieldFilterActionTypes = {
    add: 'ADD_FIELD_FILTER',
    remove: 'REMOVE_FIELD_FILTER',
    setEditMode: 'SET_EDIT_MODE_FIELD_FILTER',
    edit: 'EDIT_FIELD_FILTER',
};

const add = (id: string, fieldFilterSetId: string, filter: FilterConditions) => ({
    type: FieldFilterActionTypes.add,
    payload: {id, fieldFilterSetId, filter},
});

const set = (id: string, fieldFilterSetId: string, filter: FilterConditions) => ({
    type: FieldFilterActionTypes.edit,
    payload: {id, fieldFilterSetId, filter},
});

const remove = (id: string) => ({
    type: FieldFilterActionTypes.remove,
    payload: {id},
});

export const FieldFilterActions = {
    add,
    set,
    remove,
};
