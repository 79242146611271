import _ from 'underscore';
import {CardDefaultState, CardState} from '../CardState';

export interface MetricCardState extends CardState {
    value: number;
    defaultTitle: string;
}

export const MetricCardDefaultState: MetricCardState = _.extend({}, CardDefaultState, {
    value: null,
    defaultTitle: '',
});
