import {OtherProperty, ProductEditions, ProductTypes, SelectOption} from '@coveord/jsadmin-common';
import {createContext} from 'react';

import {Locales} from '../Locales';
import {LocalesKeys} from '../LocalesKeys';

export const SettingsActionTypes = {
    fetchOrganizationDefinition: 'ORGANIZATION_DEFINITION_GET',
};

const settingsTabsId = {
    license: 'license',
    analytics: 'analytics',
    organization: 'organization',
    coveoOnly: 'coveo-only',
};

export const SettingsConstants = {
    ids: {
        crawlingModuleCheckbox: 'EnableCrawlingModuleCheckbox',
        tabs: settingsTabsId,
    },
};

export const ProductTypesOptions: SelectOption[] = _.map(ProductTypes, (productType: string) => ({
    value: productType,
    label: Locales.format(`LICENSE_${productType}` as LocalesKeys),
}));

export enum LicenseIntegrations {
    ADOBE = 'Adobe',
    GENESYS = 'Genesys',
    SALESFORCE = 'Salesforce',
    SAP = 'SAP',
    SERVICE_NOW = 'ServiceNow',
    SITECORE = 'Sitecore',
    ZENDESK = 'Zendesk',
}

export const IntegrationsOptions: OtherProperty[] = _.map(LicenseIntegrations, (integration: string) => ({
    value: integration,
    property: Locales.format(`${integration}_INTEGRATION` as LocalesKeys),
}));

export enum LicenseFeatures {
    BRING_YOUR_OWN_KEY = 'BringYourOwnKey',
    CRAWLING_MODULE = 'CrawlingModule',
    CRITICIAL_MONITORING = 'Critical', // High Availability Upgrade
    NOTIFICATION = 'Notification',
    OPTICAL_CHARACTER_RECOGNITION = 'OCR',
    PASSAGE_RETRIEVAL = 'PassageRetrieval',
    SAML = 'SAML',
}

export const EarlyAccessLicenseFeatures = new Set<string>([]);

export const LicenseFeaturesOptions: OtherProperty[] = _.map(LicenseFeatures, (feature: string) => ({
    value: feature,
    property: Locales.format(`${feature}_FEATURE` as LocalesKeys),
}));

export enum LicenseExtensions {
    ECOMMERCE = 'Ecommerce',
    SELF_SERVICE = 'Self-Service',
    AGENTS = 'Agents',
    GENERATIVE_ANSWERING = 'RelevanceGenerativeAnswering',
}

export const LicenseExtensionsOptions: OtherProperty[] = _.map(LicenseExtensions, (extension: string) => ({
    value: extension,
    property: Locales.format(`${extension}_EXTENSION` as LocalesKeys),
}));

export const ProductEditionOptions: SelectOption[] = _.map(ProductEditions, (productEdition: string) => ({
    value: productEdition,
    label: Locales.format(`${productEdition}_EDITION` as LocalesKeys),
}));

export const SAMLForm = {
    ADVANCED: {
        id: 'Advanced',
        name: 'Advanced',
    },
    CERTIFICATE_FILE_UPLOAD: {
        id: 'CertificateFile',
        name: 'CertificateFile',
    },
    CERTIFICATE_PATH: {
        id: 'CertificatePath',
        name: 'certificatePath',
    },
    DISPLAY: {
        id: 'SAMLDisplayName',
        name: 'displayName',
    },
    ENTITY: {
        id: 'Entity',
        name: 'entity',
    },
    POST_BINDING_ENDPOINT: {
        id: 'PostBindingEndpoint',
        name: 'postBindingEndpoint',
    },
    PUBLIC_CERTIFICATE: {
        id: 'PublicCertificate',
        name: 'x509Certificate',
    },
};

interface SingleSignOn {
    isSamlEnabled: boolean;
    canEditSaml: boolean;
}

export const SingleSignOnContext =
    createContext<[SingleSignOn, React.Dispatch<React.SetStateAction<SingleSignOn>>]>(null);

export const historyLimit = [
    'numberOfDocumentsLimit',
    'pushApiMaximumDailyInvocations',
    'pushApiMaximumDailyDocumentLimit',
    'pushApiMaximumDailyRelationshipLimit',
    'crawlerMaximumDailyDocumentLimit',
    'streamApiMaximumDailyInvocation',
    'streamApiCloseMaximumDailyInvocation',
    'dailyIndexedCatalogContentLimitInMegabytes',
    'snowflakeCreditsLimit',
    'totalPassageRetrievalQueriesPerMonth',
];
