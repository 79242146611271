import {AccessTokenUtils} from '@core/authentication';
import {Config} from '@core/configuration';

export interface ISourceService {
    hasSource(organizationId: string): Promise<boolean>;
}

export class SourceService implements ISourceService {
    async hasSource(organizationId: string): Promise<boolean> {
        const url = SourceServiceUrls.getOneSource(organizationId);
        const response = await fetch(url, {
            headers: {
                Authorization: `Bearer ${AccessTokenUtils.getToken()}`,
            },
        });
        const sources = (await response.json()) as [];
        return sources.length > 0;
    }
}

export const SourceServiceUrls = {
    getOneSource: (organizationId: string) =>
        `${Config.CloudPlatform.url}/rest/organizations/${organizationId}/sources?perPage=1`,
};

const sourceServiceSingleton = new SourceService();
export const getSourceServiceSingleton = (): ISourceService => sourceServiceSingleton;
