import {MLModel} from '@core/api';
import {KeyValueReducerGenerator} from '@core/store';
import {IReduxAction} from '@coveord/plasma-react';
import {CaseAssistConfigurationConstants} from '../CaseAssistConfigurationConstants';
import {createWithEmptyStateTabReducer, IWithEmptyStateTabState} from '../WithEmptyStateTabReducer';
import {
    createWithQuerySyntaxValidationReducer,
    IWithQuerySyntaxValidationState,
} from '../WithQuerySyntaxValidationReducer';
import {CaseClassificationActionTypes} from './CaseClassificationActions';
import {FieldsToPredictInitialState, fieldsToPredictReducer, IFieldsToPredictState} from './FieldsToPredictReducer';

const withEmptyStateReducer = createWithEmptyStateTabReducer(
    CaseAssistConfigurationConstants.Scopes.CaseClassification,
);
const withQuerySyntaxValidationReducer = createWithQuerySyntaxValidationReducer(
    CaseAssistConfigurationConstants.Scopes.CaseClassification,
);

export interface ICaseClassificationTabState {
    axonModels: MLModel[];
    fieldsToPredict: IFieldsToPredictState;
    withEmptyStateTab: IWithEmptyStateTabState;
    withQuerySyntaxValidation: IWithQuerySyntaxValidationState;
}

export const CaseClassificationTabInitialState: ICaseClassificationTabState = {
    axonModels: [],
    fieldsToPredict: FieldsToPredictInitialState,
    withEmptyStateTab: withEmptyStateReducer.initialState,
    withQuerySyntaxValidation: withQuerySyntaxValidationReducer.initialState,
};

const axonModelsReducer = KeyValueReducerGenerator<MLModel[]>(CaseClassificationActionTypes.updateModels, []);

const caseClassificationTabReducerOwnReducer = (
    state: ICaseClassificationTabState = CaseClassificationTabInitialState,
    action: IReduxAction<any> = {type: '', payload: {}},
) =>
    _.isUndefined(CaseClassificationActionTypes[action.type])
        ? state
        : CaseClassificationActionTypes[action.type](state, action);

export const caseClassificationTabReducer = (
    state: ICaseClassificationTabState = CaseClassificationTabInitialState,
    action: IReduxAction<any>,
) => {
    const updatedWithState = {
        ...state,
        axonModels: axonModelsReducer(state.axonModels, action),
        fieldsToPredict: fieldsToPredictReducer(state.fieldsToPredict, action),
        withEmptyStateTab: withEmptyStateReducer.reducer(state.withEmptyStateTab, action),
        withQuerySyntaxValidation: withQuerySyntaxValidationReducer.reducer(state.withQuerySyntaxValidation, action),
    };

    return caseClassificationTabReducerOwnReducer(updatedWithState, action);
};
