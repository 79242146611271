import {IReduxAction} from '@coveord/plasma-react';
import {CaseAssistConfigModel} from '@core/api';
import {
    DocumentSuggestionTabInitialState,
    documentSuggestionTabReducer,
    IDocumentSuggestionTabState,
} from './documentSuggestionTab/DocumentSuggestionReducers';
import {
    caseClassificationTabReducer,
    CaseClassificationTabInitialState,
    ICaseClassificationTabState,
} from './caseClassificationTab/CaseClassificationReducers';
import {EditCaseAssistConfigActionTypes, IUpdateCaseAssistPayload} from './EditCaseAssistConfigActions';
import {caseAssistPreviewReducer} from './preview/CaseAssistPreviewReducer';
import {CaseAssistPreviewInitialState, ICaseAssistPreviewState} from './preview/CaseAssistPreviewConstants';
import {IModalSelectState, modalSelectsInitialState, modalSelectsReducer} from './modalSelect/ModalSelectReducers';

export interface IEditCaseAssistConfigState {
    data: Partial<CaseAssistConfigModel>;
    preview: ICaseAssistPreviewState;
    documentSuggestionTab: IDocumentSuggestionTabState;
    caseClassificationTab: ICaseClassificationTabState;
    modalSelects: IModalSelectState[];
}

const initialState: IEditCaseAssistConfigState = {
    data: {
        id: '',
        name: '',
        classificationConfigurations: [],
    },
    preview: CaseAssistPreviewInitialState,
    documentSuggestionTab: DocumentSuggestionTabInitialState,
    caseClassificationTab: CaseClassificationTabInitialState,
    modalSelects: modalSelectsInitialState,
};

const updateCaseAssistConfig = (
    state: IEditCaseAssistConfigState,
    action: IReduxAction<IUpdateCaseAssistPayload>,
): IEditCaseAssistConfigState => ({
    ...state,
    data: action.payload!.caseAssistConfig,
});

const EditCaseAssistReducers: Record<string, (...args: any[]) => IEditCaseAssistConfigState> = {
    [EditCaseAssistConfigActionTypes.updateCaseAssistConfig]: updateCaseAssistConfig,
};

const editCaseAssistConfigOwnReducer = (
    state: IEditCaseAssistConfigState = initialState,
    action: IReduxAction<any> = {type: '', payload: {}},
) => (_.isUndefined(EditCaseAssistReducers[action.type]) ? state : EditCaseAssistReducers[action.type](state, action));

export const editCaseAssistConfigReducer = (
    state: IEditCaseAssistConfigState = initialState,
    action: IReduxAction<any>,
) => {
    const updatedWithState = {
        ...state,
        preview: caseAssistPreviewReducer(state.preview, action),
        documentSuggestionTab: documentSuggestionTabReducer(state.documentSuggestionTab, action),
        caseClassificationTab: caseClassificationTabReducer(state.caseClassificationTab, action),
        modalSelects: modalSelectsReducer(state.modalSelects, action),
    };

    return editCaseAssistConfigOwnReducer(updatedWithState, action);
};
