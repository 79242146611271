<% const PlasmaStyle = require('@coveord/plasma-style'); %>

<span class="enabled <%- ctx.isSelected() ? 'selected-value state-selected' : '' %>">
    <% if (PlasmaStyle.svg && PlasmaStyle.svg[ctx.iconClass]) { %>
    <%= PlasmaStyle.svg[ctx.iconClass].render('icon ' + ctx.svgClass, ctx.svgSpanClass) %>
    <% } else if (ctx.iconClass && s.contains(ctx.iconClass, 'coveo-icon-')) { %>
    <%= PlasmaStyle.SVG(ctx.iconClass, 'icon icon-small ' + ctx.svgClass, ctx.svgSpanClass) %>
    <% } else if (ctx.iconClass) { %>
    <i class="<%- ctx.iconClass %>" title="<%- ctx.formatDescription() %>"></i>
    <% } %>
    <%- ctx.formatValue() %></span>
