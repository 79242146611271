import {CommerceLocalesKeys as GeneratedCommerceLocalesKeys} from './CommerceLocalesKeys';
import Catalog from './strings/Catalog.json';
import CatalogOnboarding from './strings/CatalogOnboarding.json';
import Documentation from './strings/Documentation.json';
import JSAdminComponents from './strings/JSAdminComponents.json';
import Prerequisites from './strings/Prerequisites.json';
import ProductListingCatalogs from './strings/ProductListingCatalogs.json';
import ProductListings from './strings/ProductListings.json';
import ApiKey from './strings/ApiKey.json';
import Promotions from './strings/Promotions.json';
import StandardFields from './strings/StandardFields.json';
import UnsavedChanges from './strings/UnsavedChanges.json';

export const toTranslate = {
    Catalog,
    CatalogOnboarding,
    Documentation,
    JSAdminComponents,
    Prerequisites,
    ProductListingCatalogs,
    ProductListings,
    ApiKey,
    Promotions,
    StandardFields,
    UnsavedChanges,
};
export type CommerceLocalesKeys = GeneratedCommerceLocalesKeys;
