import {notifications} from '@components/mantine';
import {Platform, PlatformNoHandlers} from '@core/api';
import {RequestsActions} from '@coveord/jsadmin-common';
import {IThunkAction} from '@coveord/plasma-react';
import {HandleSaveProjectAssociationCallbackMethod} from '@components/projects';

import {ObjectKeyValueActions} from '@core/store';
import {locales} from '../../locales/Locales';
import {ServiceState} from '../../ServiceState';
import {InsightPanelConfigUpdateParamsWithProjectIds} from './EditInsightPanelConfig';

export const EditInsightPanelConfigActionTypes = {
    updateInsightPanelConfig: 'UPDATE_INSIGHT_PANEL_CONFIG',
    fetchInsightPanelConfig: 'FETCH_INSIGHT_PANEL_CONFIG',
    saveInsightPanelConfig: 'SAVE_INSIGHT_PANEL_CONFIG',
    clearPendingChanges: 'CLEAR_INSIGHT_PANEL_PENDING_CHANGES',
    fetchPipelines: 'FETCH_QUERY_PIPELINES',
    updatePipelines: 'UPDATE_QUERY_PIPELINES',
};

const fetchInsightPanelConfig =
    (configId: string): IThunkAction<void, ServiceState> =>
    async (dispatch) => {
        const makeRequest = async () => {
            const config = await Platform.insightPanelConfig.get(configId);
            dispatch(ObjectKeyValueActions.update(EditInsightPanelConfigActionTypes.updateInsightPanelConfig, config));
        };
        dispatch(RequestsActions.handle(EditInsightPanelConfigActionTypes.fetchInsightPanelConfig, makeRequest));
    };

const saveInsightPanelConfig =
    (
        configToSave: InsightPanelConfigUpdateParamsWithProjectIds,
        handleSaveProjectAssociation: HandleSaveProjectAssociationCallbackMethod,
    ): IThunkAction<void, ServiceState> =>
    async (dispatch) => {
        const makeRequest = async () => {
            try {
                const {projectIds, ...config} = configToSave;
                const updatedConfig = await PlatformNoHandlers.insightPanelConfig.update(config);
                dispatch(
                    ObjectKeyValueActions.update(
                        EditInsightPanelConfigActionTypes.updateInsightPanelConfig,
                        updatedConfig,
                    ),
                );
                // Summit : Save Insight Panel Project Associations
                await handleSaveProjectAssociation({
                    resourceId: updatedConfig.id,
                    newAssociatedProjectIds: projectIds,
                });
                notifications.showSuccess(locales.format('saveInsightPanelConfigCompleted'));
            } catch (exception) {
                notifications.showError(locales.format('saveInsightPanelConfigFailed'));
                throw exception;
            }
        };
        dispatch(RequestsActions.handle(EditInsightPanelConfigActionTypes.saveInsightPanelConfig, makeRequest));
    };

export const EditInsightPanelConfigActions = {
    fetchInsightPanelConfig,
    saveInsightPanelConfig,
};
