import {ListProjectParams, ProjectResourceType, ResourceParams} from '@core/api';

const allQueryKey = ['projects'];
const queryKeys = {
    root: () => allQueryKey,
    // Platform.project.get
    get: (id: string) => [...allQueryKey, 'get', id],
    // Platform.project.list
    list: (params?: ListProjectParams) => [
        ...allQueryKey,
        'list',
        ...(params && Object.entries(params).length > 0 ? [params] : []),
    ],
    // Platform.project.listAssociatedProjects
    listAssociatedProjects: (resourceType: ProjectResourceType, resourceIds: string[]) => [
        ...allQueryKey,
        'associated',
        resourceType,
        resourceIds,
    ],
    // Platform.project.listResources
    listAssociatedResources: (projectId: string, resourceType?: ProjectResourceType, params?: ResourceParams) => [
        ...allQueryKey,
        'resources',
        projectId,
        ...(resourceType ? [resourceType] : []),
        ...(params && Object.entries(params).length > 0 ? [params] : []),
    ],
    // Platform.project.listResourcesByStatus
    listResourcesByStatus: (projectId: string) => [...queryKeys.listAssociatedResources(projectId), 'status'],
};

const infiniteGeneralKey = ['infinite'];
const infiniteQueryKeys = {
    // Platform.project.list
    filterList: (filter: string) => [...allQueryKey, 'list', ...infiniteGeneralKey, filter],
};

const mutationKeys = {
    // Platform.project.updateProjectResourceAssociation
    updateProjectResourceAssociation: (resourceType: ProjectResourceType) => [
        ...allQueryKey,
        'association',
        'update',
        resourceType,
    ],
};

export const PlatformHelpers = {queryKeys, infiniteQueryKeys, mutationKeys};
