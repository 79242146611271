import {IThunkAction} from '@coveord/plasma-react';
import _ from 'underscore';

import {Config} from '@core/configuration';
import {setKeyValue} from '@core/store';
import {IGNORED_DIMENSIONS} from '../../event-filters/FiltersCommon';
import {CommonState} from '../../redux/CommonState';
import {RequestsActions} from '../../redux/requests/RequestsActions';
import {DimensionModelAttributes} from '../DimensionsCommon';

export const DimensionsActionsType = {
    set: 'SET_DIMENSIONS',
    request: 'REQUEST_DIMENSION',
};

const getDimensions =
    (
        url = `${Config.UsageAnalytics.url}/dimensions?org={organizationId}`,
    ): IThunkAction<JQueryPromise<any>, CommonState> =>
    (dispatch, getState) => {
        const organizationId = getState().organization.id;

        dispatch(RequestsActions.request(DimensionsActionsType.request));

        return $.get(url.replace('{organizationId}', organizationId))
            .done((dimensions: DimensionModelAttributes[]) => {
                dispatch(
                    setKeyValue(
                        DimensionsActionsType.set,
                        _.reject(dimensions, (dimension: DimensionModelAttributes) =>
                            _.contains(IGNORED_DIMENSIONS, dimension.returnName),
                        ),
                    ),
                );
                dispatch(RequestsActions.success(DimensionsActionsType.request));
            })
            .fail((xhr: JQueryXHR) => {
                dispatch(RequestsActions.failure(DimensionsActionsType.request));
            });
    };

export const DimensionsActions = {
    getDimensions,
};
