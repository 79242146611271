import _ from 'underscore';
import {IReduxAction} from '@coveord/plasma-react';
import {createWithEmptyStateTabReducer, IWithEmptyStateTabState} from '../WithEmptyStateTabReducer';
import {
    createWithQuerySyntaxValidationReducer,
    IWithQuerySyntaxValidationState,
} from '../WithQuerySyntaxValidationReducer';
import {CaseAssistConfigurationConstants} from '../CaseAssistConfigurationConstants';
export interface IDocumentSuggestionTabState {
    isEditing?: boolean;
    isDirty?: boolean;
    withEmptyStateTab: IWithEmptyStateTabState;
    withQuerySyntaxValidation: IWithQuerySyntaxValidationState;
}

const withEmptyStateReducer = createWithEmptyStateTabReducer(
    CaseAssistConfigurationConstants.Scopes.DocumentSuggestion,
);
const withQuerySyntaxValidationReducer = createWithQuerySyntaxValidationReducer(
    CaseAssistConfigurationConstants.Scopes.DocumentSuggestion,
);

export const DocumentSuggestionTabInitialState: IDocumentSuggestionTabState = {
    withEmptyStateTab: withEmptyStateReducer.initialState,
    withQuerySyntaxValidation: withQuerySyntaxValidationReducer.initialState,
};

const actionTypes = {
    ...withEmptyStateReducer.actionTypes,
    ...withQuerySyntaxValidationReducer.actionTypes,
};

const documentSuggestionTabReducerOwnReducer = (
    state: IDocumentSuggestionTabState = DocumentSuggestionTabInitialState,
    action: IReduxAction<any> = {type: '', payload: {}},
) => (_.isUndefined(actionTypes[action.type]) ? state : actionTypes[action.type](state, action));

export const documentSuggestionTabReducer = (
    state: IDocumentSuggestionTabState = DocumentSuggestionTabInitialState,
    action: IReduxAction<any>,
) => {
    const updatedWithState = {
        ...state,
        withEmptyStateTab: withEmptyStateReducer.reducer(state.withEmptyStateTab, action),
        withQuerySyntaxValidation: withQuerySyntaxValidationReducer.reducer(state.withQuerySyntaxValidation, action),
    };

    return documentSuggestionTabReducerOwnReducer(updatedWithState, action);
};
