import {Config} from '@core/configuration';
import {PrivilegeModel} from '@core/api';
import {IReduxAction, IThunkAction} from '@coveord/plasma-react';
import _ from 'underscore';

import {AdminState} from '../application/Reducers';

export const DefaultGroupsActionTypes = {
    set: 'SET_DEFAULT_GROUPS',
};

export interface DefaultGroup {
    /**
     * The id is the one of the template, not the real id of the group in the org that implements this template.
     */
    id: string;
    deletable: boolean;
    displayName: string;
    privileges: PrivilegeModel[];
}

export type DefaultGroupsState = {[id: string]: DefaultGroup};

export interface DefaultGroupsActionPayload {
    defaultGroups: DefaultGroup[];
}

export const defaultGroupsReducer = (
    state: DefaultGroupsState = {},
    {type, payload}: IReduxAction<any>,
): DefaultGroupsState => {
    switch (type) {
        case DefaultGroupsActionTypes.set:
            const newDefaultGroups: DefaultGroupsState = _.indexBy(payload.defaultGroups, 'id');
            return _.isEqual(newDefaultGroups, state) ? state : newDefaultGroups;
        default:
            return state;
    }
};

const getAll = (state: AdminState): DefaultGroupsState => state.defaultGroups || {};

const get = (state: AdminState, id: string) => getAll(state)[id];

const set = (defaultGroups: DefaultGroup[]): IReduxAction<DefaultGroupsActionPayload> => ({
    type: DefaultGroupsActionTypes.set,
    payload: {
        defaultGroups,
    },
});

const fetch = (): IThunkAction<void, AdminState> => (dispatch, getState) => {
    if (_.isEmpty(getAll(getState()))) {
        $.get(`${Config.CloudPlatform.url}/rest/organizations/{organizationName}/defaultgroups`).done(
            (response: DefaultGroup[]) => dispatch(set(response)),
        );
    }
};

const getDefaultGroupPrivilegesWithTargetId = (defaultGroup: DefaultGroup, targetId: string): PrivilegeModel[] =>
    defaultGroup
        ? _.map(defaultGroup.privileges, (privilege: PrivilegeModel) =>
              privilege.targetId === defaultGroup.id ? {...privilege, targetId} : privilege,
          )
        : [];

export const DefaultGroupsSelectors = {
    get,
    getAll,
};

export const DefaultGroupsActions = {
    set,
    fetch,
};

export const DefaultGroupsUtils = {
    getDefaultGroupPrivilegesWithTargetId,
};
