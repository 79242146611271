import {useLocation} from '@core/routes';
import {trackPageView} from '@core/tracking';
import {FunctionComponent, useEffect} from 'react';

export const PageViewTracker: FunctionComponent = () => {
    const {pathname} = useLocation();
    useEffect(() => {
        trackPageView(pathname);
    }, [pathname]);

    return null;
};
