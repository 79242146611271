import {createContext, useContext} from 'react';

import {SubnavLayoutPanels, SubnavLayoutVariant} from './SubnavLayout.types';

type SubnavLayoutContextType = {
    activeTab?: string;
    defaultTab: string;
    panels: SubnavLayoutPanels;
    routed: boolean;
    variant: SubnavLayoutVariant;
};

export const SubnavLayoutContext = createContext<SubnavLayoutContextType>({
    defaultTab: '',
    panels: [],
    routed: false,
    variant: 'primary',
});

export const SubnavLayoutProvider = SubnavLayoutContext.Provider;

/**
 * Hook to get the context of the SubnavLayout.
 * @internal
 *
 * @return {SubnavLayoutContextType} - The context of the SubnavLayout.
 */
export const useSubnavLayoutInternal = () => {
    const context = useContext(SubnavLayoutContext);
    if (!context) {
        throw new Error('useSubnavLayout must be used within a SubnavLayoutProvider');
    }

    return context;
};

/**
 * Hook to get the active tab of the SubnavLayout.
 *
 * @return {string} - The active tab of the SubnavLayout.
 */
export const useSubnavLayout = () => {
    const {activeTab} = useSubnavLayoutInternal();

    return activeTab;
};
