import {
    Formatting,
    FormatToParts,
    MissingFormatOptions,
    MissingFormatPart,
    ResolvedMissingFormatOptions,
} from '@core/format';
import _ from 'underscore';
import isEmail from 'validator/lib/isEmail';

import {commonLocales} from '../CommonLocales';
import {KeyCode} from '../Constants';
import {UI} from './UIUtils';

export const getLocationOrigin = (): string =>
    window.location.protocol +
    '//' +
    window.location.hostname +
    (window.location.port ? ':' + window.location.port : '') +
    window.location.search;

export const splitToArrayOfString = (input) => {
    if (!(input instanceof Array)) {
        input = _.compact(input.split(/[^\w]/));
    }

    return input;
};

export const hasErrorCode = (xhr: JQueryXHR) => xhr?.responseJSON?.errorCode;

const hexDigits = '0123456789ABCDEF';

export const generateUUID = (length: number) => {
    if (isNaN(length)) {
        return null;
    }

    const str = [];
    for (let i = 0; i < length; i++) {
        str[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }

    return str.join('');
};

export const isKeyCodeNumeric = (keyCode: number) =>
    (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105);

export const isKeyCodeNonAlphaNumeric = (keyCode: number) =>
    (keyCode >= 8 && keyCode <= 46) || (keyCode >= 91 && keyCode <= 93) || (keyCode >= 112 && keyCode <= 145);

export const createUniqueRequestId = (settings: JQueryAjaxSettings) => settings.type + '|' + settings.url;

export const validateEmail = (email: string): boolean => isEmail(email);

export const isFloat = (n: number): boolean => typeof n === 'number' && n % 1 !== 0;

type NumberFormatEx = FormatToParts<
    number | null | undefined,
    Intl.ResolvedNumberFormatOptions & ResolvedMissingFormatOptions,
    Intl.NumberFormatPart | MissingFormatPart
>;
const numberFormatMap = new Map<string, NumberFormatEx>();

const lessThanOne = (v: unknown): v is number => typeof v === 'number' && Math.abs(v) < 1;

const getFormatter = (
    compact: boolean,
    maximumFractionDigits: number,
    maximumFractionDigitsIfLessThanOne: number,
): NumberFormatEx => {
    const key = `${compact ? 'compact' : 'full'}[${maximumFractionDigits}, ${maximumFractionDigitsIfLessThanOne}]`;
    let format = numberFormatMap.get(key);
    if (!format) {
        const locale = 'en-US';
        const options: Intl.NumberFormatOptions & MissingFormatOptions = {
            missing: commonLocales.format('notApplicable').toUpperCase(),
            minimumFractionDigits: 0,
            maximumFractionDigits,
        };
        format = Formatting.hybridNumberFormat(
            locale,
            options,
            10_000,
            compact ? {notation: 'compact'} : {minimumFractionDigits: 0, maximumFractionDigits: 0},
        );
        if (maximumFractionDigitsIfLessThanOne > maximumFractionDigits) {
            options.maximumFractionDigits = maximumFractionDigitsIfLessThanOne;
            format = Formatting.combine(lessThanOne, new Intl.NumberFormat(locale, options), format);
        }
        numberFormatMap.set(key, format);
    }
    return format;
};

export const formatNumber = (
    n: number,
    maximumFractionDigits: number = 0,
    maximumFractionDigitsIfLessThanOne: number = maximumFractionDigits,
): string => {
    const format = getFormatter(false, maximumFractionDigits, maximumFractionDigitsIfLessThanOne);
    return format.format(n);
};

export const formatBigNumber = (
    n: number,
    maximumFractionDigitsIfLessThanOne: number = 1,
    maximumFractionDigits = 1,
): string => {
    const format = getFormatter(true, maximumFractionDigits, maximumFractionDigitsIfLessThanOne);
    // To keep the behavior the same, use "G" (giga) for values in the billion range.
    // Note that this patch is hard tied to the 'en-US' locale.
    return format.format(n).replace(/B$/, 'G');
};

export const formatNumberForTitle = (
    n,
    decimals: number = 0,
    decimalsIfLesserThanOne: number = 0,
    comparedDecimalsIfLesserThanOne: number | null = null,
    decimalIfBig = 0,
    returnAnyway = false,
) => {
    const absoluteN = Math.abs(n);
    if (absoluteN < 1 && decimalsIfLesserThanOne > 0) {
        decimals = decimalsIfLesserThanOne;
    }
    if (_.isNull(comparedDecimalsIfLesserThanOne)) {
        comparedDecimalsIfLesserThanOne = decimalsIfLesserThanOne;
    }
    const formatted = formatBigNumber(n, decimalsIfLesserThanOne, decimalIfBig);
    const notFormatted = formatNumber(n, decimals, comparedDecimalsIfLesserThanOne);
    return returnAnyway || formatted !== notFormatted ? notFormatted : '';
};

export const getChildViewEvent = (eventString: string) => 'childview:' + eventString;

export const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

(String.prototype as any).addSlashes = function (): string {
    return this.replace(/\\/g, '\\\\').replace(/\'/g, "\\'").replace(/\"/g, '\\"').replace(/\0/g, '\\0');
};

(String.prototype as any).removeSlashes = function (): string {
    return this.replace(/\\\\/g, '\\');
};

export interface Popup extends JQuery {
    close(): void;
    open(): void;
}

jQuery.extend(jQuery.expr[':'], {
    focusable: (el) => $(el).is('a, button, :input, [tabindex]'),
});

$(document).ready(() => {
    $('body')
        .off()
        .on('click', '.admin-clearable-input + span', (e: JQuery.TriggeredEvent) => {
            const $el = $(e.currentTarget);
            const input = $el.parent().find('.admin-clearable-input');
            if (input.length > 0) {
                input.val('');
                input.keyup();
                UI.focusInputAndMoveCursorToEnd(input);
                $el.removeClass('visible');
            }
        })
        .on('keyup', '.admin-clearable-input', (e: JQueryKeyEventObject) => {
            const $el = $(e.currentTarget);
            $el.next('span').toggleClass('visible', $el.val() !== '');
        })
        .on(
            'click',
            '.coveo-checkbox:not(.react-vapor-checkbox) + button, .coveo-slide-toggle + button',
            (e: JQuery.TriggeredEvent) => {
                const $el = $(e.currentTarget);
                $el.prev().click();
                e.preventDefault();
            },
        )
        .on(
            'keyup',
            '.coveo-checkbox:not(.react-vapor-checkbox) + button, .coveo-slide-toggle + button',
            (e: JQueryKeyEventObject) => {
                if (e.keyCode === KeyCode.SPACEBAR) {
                    const $el = $(e.currentTarget);
                    $el.prev().click();
                    e.preventDefault();
                    e.stopPropagation();
                }
            },
        );
});

export enum XHRReadyState {
    UNSENT = 0,
    OPENED = 1,
    HEADERS_RECEIVED = 2,
    LOADING = 3,
    DONE = 4,
}

export const Utils = {
    generateUUID,
};
