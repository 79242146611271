import moment from 'moment-timezone';
import _ from 'underscore';

import {commonLocales} from '../CommonLocales';
import {BackboneUtils} from '../utils/BackboneUtils';
import {Strings} from '../utils/StringsUtils';
import {Pageable} from './PageableCollection';
import {CommonLocalesKeys} from '../CommonLocalesKeys';

export interface FilterEventArguments extends Backbone.Silenceable {
    filter: string;
    dateFormat?: string;
    attributeNames?: string[];
    attributeFormatters?: {[s: string]: (value: string, model: Backbone.Model) => string};
}

export const MATCHES_FILTER = 'matchesFilter';
export const MATCHES_PREDICATE = 'matchesPredicate';

export const FilterEvents = {
    Filter: 'collection:filtered',
};

export interface PredicateFilterEventArguments {
    key: string;
    index?: number;
    predicateFunction?: (model: Backbone.Model) => boolean;
    predicateProperties?: {[key: string]: any};
    silent: boolean;
}

export interface Filterable<TModel extends Backbone.Model> {
    filterable: FilterableCollection<TModel>;
}

export class FilterableCollection<TModel extends Backbone.Model> {
    private currentFilter: FilterEventArguments;
    private currentPredicate: PredicateFilterEventArguments;

    private collection: Backbone.Collection<TModel>;

    hasMatch: boolean = true;

    static createPredicateArguments(predicates: any, keyArg: string, silent = false): PredicateFilterEventArguments {
        const predicate = predicates[keyArg];

        const args: PredicateFilterEventArguments = {
            key: keyArg,
            silent: silent,
        };
        if (_.isFunction(predicate)) {
            args.predicateFunction = predicate;
        } else if (_.isObject(predicate)) {
            args.predicateProperties = predicate;
        }

        return args;
    }

    constructor(collection) {
        this.collection = collection;
    }

    getFilter(): string {
        return this.currentFilter ? this.currentFilter.filter : '';
    }

    applyCurrentPredicateFilter() {
        this.applyPredicateFilter(this.currentPredicate);
    }

    applyPredicateFilter(args: PredicateFilterEventArguments) {
        this.currentPredicate = args;

        if (args.predicateProperties || args.predicateFunction) {
            this.collection.each((model: TModel) => {
                model[MATCHES_PREDICATE] = false;
            });

            if (args.predicateProperties) {
                _.each(this.collection.where(args.predicateProperties), (model: TModel) => {
                    model[MATCHES_PREDICATE] = true;
                });
            } else if (args.predicateFunction) {
                _.each(this.collection.filter(args.predicateFunction), (model: TModel) => {
                    model[MATCHES_PREDICATE] = true;
                });
            }
        } else {
            this.collection.each((model: TModel) => {
                model[MATCHES_PREDICATE] = true;
            });
        }
        this.hasMatch = this.collection.any(
            (model: TModel) =>
                model[MATCHES_PREDICATE] && (model[MATCHES_FILTER] || model[MATCHES_FILTER] === undefined),
        );
        if (!args.silent) {
            this.onFilterApplied();
        }
    }

    getPredicateFilter(): string {
        return this.currentPredicate ? this.currentPredicate.key : '';
    }

    getPredicateFilterCount(args: PredicateFilterEventArguments) {
        let matches: TModel[] = [];
        if (args.predicateProperties) {
            matches = this.collection.where(args.predicateProperties);
        } else if (args.predicateFunction) {
            matches = this.collection.filter(args.predicateFunction);
        }

        return _.filter(matches, (model: TModel) => (model[MATCHES_FILTER] === false ? false : true)).length;
    }

    getVisibleCount(): number {
        const f = MATCHES_FILTER;
        const p = MATCHES_PREDICATE;
        return this.collection.filter((model: TModel) => {
            const matchesFilter = _.isUndefined(model[f]) ? true : model[f];
            const matchesPredicate = _.isUndefined(model[p]) ? true : model[p];
            return matchesFilter && matchesPredicate;
        }).length;
    }

    applyFilter(args: FilterEventArguments) {
        this.currentFilter = args;

        this.collection.each((model: TModel) => {
            model[MATCHES_FILTER] = true;
        });

        if (!_.isEmpty(args.filter)) {
            this.hasMatch = false;
            this.collection.each((model: TModel) => {
                let match = false;
                const searchedAttributes = args.attributeNames || _.keys(model.attributes);
                for (let i = 0; i < searchedAttributes.length; i++) {
                    const attributeName = searchedAttributes[i];
                    const attributeValue = BackboneUtils.extractNestedAttributeValue(attributeName, model);
                    match = this.matchFilter(model, attributeName, attributeValue, args);
                    if (match) {
                        break;
                    }
                }

                this.hasMatch = this.hasMatch || match;
                model[MATCHES_FILTER] = match;
            });
        } else {
            this.hasMatch = true;
        }

        if (!args.silent) {
            this.onFilterApplied();
        }
    }

    clearFilter() {
        this.applyFilter({filter: ''});
    }

    private matchFilter(model: TModel, attributeName: string, attributeValue: any, args: FilterEventArguments) {
        if (_.isBoolean(attributeValue) && attributeValue === true) {
            attributeValue = commonLocales.format(attributeName as CommonLocalesKeys);
        }

        if (args.attributeFormatters) {
            const formatter = args.attributeFormatters[attributeName];
            if (formatter) {
                attributeValue = formatter(attributeValue, model);
            }
        }

        if (_.isNumber(attributeValue)) {
            attributeValue = attributeValue.toString();
        }

        if (_.isString(attributeValue)) {
            return Strings.containsIgnoreCase(attributeValue, args.filter);
        } else if (moment.isMoment(attributeValue)) {
            if (_.isString(args.dateFormat) && !_.isEmpty(args.dateFormat)) {
                const formattedString = attributeValue.format(args.dateFormat);
                return Strings.containsIgnoreCase(formattedString, args.filter);
            }
        } else if (_.isArray(attributeValue) || _.isObject(attributeValue)) {
            for (const i in attributeValue) {
                if (Object.prototype.hasOwnProperty.call(attributeValue, i)) {
                    const match = this.matchFilter(model, attributeName, attributeValue[i], args);
                    if (match) {
                        return match;
                    }
                }
            }
        }

        return false;
    }

    private onFilterApplied() {
        if (this.collection['pageable']) {
            const collection: Pageable<TModel> = this.collection as any;
            collection.pageable.setPage(0, true); // Silent the PageChanged to prevent rendering the table twice.
        }

        this.collection.trigger(FilterEvents.Filter);
    }
}
