import moment from 'moment-timezone';
import _ from 'underscore';
import {commonLocales} from '../CommonLocales';
import {KeyCode, TOOLTIP_DISMISS_TIME} from '../Constants';
import {Numbers} from '../utils/NumbersUtils';
import {isKeyCodeNonAlphaNumeric, isKeyCodeNumeric} from '../utils/Utils';

import numericInput from './numeric-input.ejs';
import numericSpinner from './numeric-spinner.ejs';

const SpinnerEvents = {
    CHANGE_VALUE: 'spinner:change-value',
    INCREMENT: 'spinner:up',
    DECREMENT: 'spinner:down',
};

export class RangeLimitedValue extends Backbone.Model {
    get minimum(): number {
        return this.get('minimum');
    }

    get maximum(): number {
        return this.get('maximum');
    }

    get jump(): number {
        return this.get('jump');
    }

    get value(): number {
        return this.get('value');
    }

    set value(value: number) {
        this.set('value', value);
    }

    defaults() {
        return {
            minimum: 0,
            maximum: 10,
            jump: 1,
            value: 0,
        };
    }

    validate(attrs, options?) {
        if (attrs.minimum && attrs.value < attrs.minimum) {
            return commonLocales.format('valueLower', {count: attrs.minimum});
        }
        if (attrs.maximum && attrs.value > attrs.maximum) {
            return commonLocales.format('valueHigher', {count: attrs.maximum});
        }
    }

    setValue(value: number, isSpinner: boolean, options?: Backbone.ModelSetOptions) {
        let isValueValid = true;
        if (isSpinner) {
            if (value < this.minimum) {
                value = this.maximum;
            } else if (value > this.maximum) {
                value = this.minimum;
            }
        } else {
            if (value < this.minimum) {
                value = this.minimum;
                isValueValid = false;
            } else if (value > this.maximum) {
                value = this.maximum;
                isValueValid = false;
            }
        }

        this.set('value', value, options);
        return isValueValid;
    }
}

class RegularInputView extends Marionette.ItemView<RangeLimitedValue> {
    model: RangeLimitedValue;

    private allowedCharacters: any[];

    constructor(baseOptions: BaseChildViewProperties, options?) {
        super(
            _.extend(
                {},
                baseOptions,
                {
                    className: baseOptions.className || 'admin-numeric-input',
                    events: _.extend(
                        {
                            'keydown input[type=text]': 'onKeyDown',
                            'keyup input[type=text]': 'onKeyUp',
                            'blur input[type=text]': 'onBlur',
                        },
                        baseOptions.events,
                    ),
                    modelEvents: _.extend(
                        {
                            'change:value': 'onChangeValue',
                            invalid: 'onInvalidValue',
                        },
                        baseOptions.modelEvents,
                    ),
                },
                options,
            ),
        );

        this.template = numericInput;
        this.templateHelpers = {
            formatValue: () => this.model.value,
        };
        this.allowedCharacters = [KeyCode.END, KeyCode.HOME, KeyCode.BACKSPACE, KeyCode.DELETE];

        this.ui = {
            input: 'input[type=text]',
        };
    }

    triggers() {
        return {
            'change input[type=text]': SpinnerEvents.CHANGE_VALUE,
        };
    }

    onChangeValue() {
        const formattedValue = this.templateHelpers.formatValue();

        if (!Number.isNaN(formattedValue)) {
            this.ui.input.val(formattedValue);
        }
    }

    onInvalidValue() {
        this.ui.input.attr({
            'data-toggle': 'tooltip',
            'data-container': '#main',
            'data-placement': 'bottom',
            'data-trigger': 'manual',
            title: this.model.validationError,
        });
        const tooltip = this.ui.input.tooltip();
        tooltip.on('shown.bs.tooltip', () => {
            tooltip.data('tooltip').$tip.addClass('state-error');
        });
        tooltip.tooltip('show');
        setTimeout(() => {
            if (this.ui.input.tooltip) {
                this.ui.input.tooltip('destroy');
            }
        }, TOOLTIP_DISMISS_TIME);
    }

    onBlur(e: JQuery.TriggeredEvent) {
        this.onChangeValue();
    }

    onKeyDown(e: JQuery.TriggeredEvent) {
        const keyCode = e.keyCode;
        if (keyCode === KeyCode.ENTER || keyCode === KeyCode.ESCAPE || keyCode === KeyCode.TAB) {
            this.ui.input.blur();
            return true;
        }

        if (keyCode === KeyCode.ARROW_UP) {
            this.trigger(SpinnerEvents.INCREMENT, {
                model: this.model,
                view: this,
            });
        } else if (keyCode === KeyCode.ARROW_DOWN) {
            this.trigger(SpinnerEvents.DECREMENT, {
                model: this.model,
                view: this,
            });
        }
        return (
            isKeyCodeNumeric(keyCode) ||
            isKeyCodeNonAlphaNumeric(keyCode) ||
            _.contains(this.allowedCharacters, keyCode)
        );
    }

    getValue(): number {
        return parseInt(this.ui.input.val(), 10);
    }

    hintError() {
        const backgroundColor = this.ui.input.css('background-color');
        this.ui.input.css('background-color', 'rgba(227, 146, 146, 0.5)').CoveoOnTransitionDone(() => {
            if (!this.isDestroyed && this.ui && this.ui.input && this.ui.input.length) {
                this.ui.input.css('background-color', backgroundColor);
            }
        });
    }
}

class SpinnerView extends RegularInputView {
    constructor(options?) {
        super(
            {
                className: 'input-group admin-numeric-spinner',
                events: {
                    'click input[type=text]': 'onFocusInput',
                    'blur input[type=text]': 'onBlurInput',
                },
            },
            options,
        );

        this.template = numericSpinner;
        this.templateHelpers = {
            formatValue: () =>
                this.options.format ? (moment.localeData() as any).ordinal(this.model.value) : this.model.value,
        };
    }

    triggers() {
        return _.extend(super.triggers(), {
            'click #SpinnerUp': SpinnerEvents.INCREMENT,
            'click #SpinnerDown': SpinnerEvents.DECREMENT,
        });
    }

    onFocusInput() {
        if (!this.ui.input.hasClass('focus')) {
            this.ui.input.select();
            this.ui.input.addClass('focus');
        }
    }

    onBlurInput() {
        this.ui.input.removeClass('focus');
    }
}

export class NumericInputWidget extends Marionette.Object {
    constructor(
        private limitedValue: RangeLimitedValue,
        private useSpinner = false,
        private format = true,
    ) {
        super();
    }

    show(region: Marionette.Region) {
        const buttonGroup = this.useSpinner
            ? new SpinnerView({model: this.limitedValue, format: this.format})
            : new RegularInputView({}, {model: this.limitedValue});

        region.show(buttonGroup);

        this.listenTo(buttonGroup, SpinnerEvents.CHANGE_VALUE, this.onChangeValue);
        this.listenTo(buttonGroup, SpinnerEvents.INCREMENT, this.onIncrementValue);
        this.listenTo(buttonGroup, SpinnerEvents.DECREMENT, this.onDecrementValue);
    }

    private onChangeValue(args) {
        const model: RangeLimitedValue = args.model;
        const view: SpinnerView = args.view;

        // send false to isSpinner so the max value don't wrap to min and min to max
        const valueIsValid = model.setValue(view.getValue(), false, {validate: false});
        if (!valueIsValid && view.hintError) {
            view.hintError();
        }
        model.isValid();
    }

    private onIncrementValue(args) {
        const model: RangeLimitedValue = args.model;
        let value: number = Numbers.roundTo(model.value, model.jump);
        value = value % model.jump === 0 && value > model.value ? value : value + model.jump;
        model.setValue(value, this.useSpinner, {validate: true});
    }

    private onDecrementValue(args) {
        const model: RangeLimitedValue = args.model;
        let value: number = Numbers.roundTo(model.value, model.jump);
        value = value % model.jump === 0 && value < model.value ? value : value - model.jump;
        model.setValue(value, this.useSpinner, {validate: true});
    }
}
