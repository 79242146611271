import {Loadable} from '@components/page';
import {RouteObject} from '@core/routes';

export const routes: RouteObject[] = [
    {
        path: `/:orgId/usage/reports/*`,
        Component: Loadable(() => import('../components/Reports/ReportsPage').then((module) => module.ReportsPage)),
    },
    {
        path: `/:orgId/usage/reports/dashboard/:id/*`,
        Component: Loadable(() =>
            import('../components/Dashboard/DashboardPage').then((module) => module.DashboardPage),
        ),
    },
    {
        path: `/:orgId/usage/reports/explorer/:id/*`,
        Component: Loadable(() => import('../components/Explorer/ExplorerPage').then((module) => module.ExplorerPage)),
    },
    {
        path: `/:orgId/usage/raw-data/*`,
        lazy: async () => {
            const {RawDataPage} = await import('../components/RawData/RawDataPage');
            return {Component: RawDataPage};
        },
    },
    {
        path: `/:orgId/usage/dimensions/*`,
        lazy: async () => {
            const {DimensionsPage} = await import('../components/Dimensions/DimensionsPage');
            return {Component: DimensionsPage};
        },
    },
    {
        path: `/:orgId/usage/filters/reporting/*`,
        lazy: async () => {
            const {NamedFiltersPage} = await import('../predefined-filters/NamedFiltersPage');
            return {Component: NamedFiltersPage};
        },
    },
    {
        path: `/:orgId/usage/filters/permissions/*`,
        lazy: async () => {
            const {PermissionFiltersPage} = await import('../predefined-filters/PermissionFiltersPage');
            return {Component: PermissionFiltersPage};
        },
    },
    {
        path: `/:orgId/usage/visit/*`,
        Component: Loadable(() =>
            import('../components/VisitBrowser/VisitBrowserBackbonePage').then(
                (module) => module.VisitBrowserBackbonePage,
            ),
        ),
    },
];
