import {UAGroupAttributes} from './UAGroup';

export interface UAGroupsState {
    error: boolean;
    loaded: boolean;
    groups: UAGroupAttributes[];
}

export const UAGroupsDefaultState: UAGroupsState = {
    error: false,
    loaded: false,
    groups: [],
};
