import Registry from '@core/registry';
import {Radio} from '@coveord/jsadmin-common';
import * as ReactVapor from '@coveord/plasma-react';
import _ from 'underscore';

import {Channels} from '../../../../Channels';
import {UAInjectable} from '../../../../UAInjectable';
import {UALoggedUser} from '../../../../users/UALoggedUser';
import {ReportAccessReducerUtils} from '../reducers/ReportAccessReducerUtils';
import {ReportAccessEvents} from '../ReportAccessEvents';
import {ReportAccessIdentityTypes} from '../ReportAccessIdentityTypes';
import {ReportAccessEditAttributes} from '../ReportAccessState';
import {ReportAccessBackendType, ReportAccessType} from '../ReportAccessType';
import {ReportAccessUrl} from '../ReportAccessUtils';

export const ReportAccessActionsType = {
    AddIdentity: 'REPORT_ACCESS_ADD_IDENTITY',
    RemoveIdentity: 'REPORT_ACCESS_REMOVE_IDENTITY',
    FetchReportAccessSuccess: 'REPORT_ACCESS_FETCH_FULFILLED',
    SetType: 'REPORT_ACCESS_SET_TYPE',
    ClearModal: 'REPORT_ACCESS_CLEAR_MODAL',
};
export interface AddIdentityPayload {}

const addIdentity = (): ReactVapor.IReduxAction<AddIdentityPayload> => ({
    type: ReportAccessActionsType.AddIdentity,
    payload: {},
});

export interface RemoveIdentityPayload {
    id: string;
}

const removeIdentity = (id: string): ReactVapor.IReduxAction<AddIdentityPayload> => ({
    type: ReportAccessActionsType.RemoveIdentity,
    payload: {
        id,
    },
});

export interface FetchReportAccessPayload {
    type: string;
    identities: ReportAccessEditAttributes[];
}
interface ReportAccessUserResponse {
    userId: string;
}
interface ReportAccessGroupResponse {
    groupId: string;
}
interface ReportAccessResponse {
    accessType: string;
    allowedUsers?: ReportAccessUserResponse[];
    allowedGroups?: ReportAccessGroupResponse[];
}
const fetchAccess =
    (reportId: string): ReactVapor.IThunkAction =>
    (dispatch) => {
        const currentUser: UALoggedUser = Registry.get(UAInjectable.User);
        const channel = Radio.channel(Channels.ReportAccessComponent);
        $.get(ReportAccessUrl(reportId))
            .done((data: ReportAccessResponse) => {
                if (data.accessType === ReportAccessBackendType.Public) {
                    dispatch({
                        type: ReportAccessActionsType.FetchReportAccessSuccess,
                        payload: {
                            type: ReportAccessType.Public,
                            identities: [],
                        },
                    });
                } else {
                    const userIdentities = _.map(data.allowedUsers, (user: ReportAccessUserResponse) => ({
                        identity: user.userId,
                        identityType: ReportAccessIdentityTypes.User,
                    }));
                    const groupIdentities = _.map(data.allowedGroups, (group: ReportAccessGroupResponse) => ({
                        identity: group.groupId,
                        identityType: ReportAccessIdentityTypes.Group,
                    }));
                    let type = ReportAccessType.Custom;
                    if (
                        groupIdentities.length === 0 &&
                        userIdentities.length === 1 &&
                        userIdentities[0].identity === currentUser.id
                    ) {
                        type = ReportAccessType.Private;
                    }
                    dispatch({
                        type: ReportAccessActionsType.FetchReportAccessSuccess,
                        payload: {
                            identities: userIdentities
                                .concat(groupIdentities)
                                .map((identity: ReportAccessEditAttributes) => ({
                                    appliedState: identity,
                                    editionState: identity,
                                    id: ReportAccessReducerUtils.GenerateIdentityId(),
                                    isOpen: false,
                                })),
                            type,
                        },
                    });
                }
            })
            .fail((xhr: JQueryXHR) => channel.emit(ReportAccessEvents.FetchError, xhr));
    };

export interface ReportAccessSaveData {
    accessType: string;
    allowedUsers: string[];
    allowedGroups: string[];
}

const saveAccess =
    (reportId: string, data: ReportAccessSaveData): ReactVapor.IThunkAction =>
    () => {
        const channel = Radio.channel(Channels.ReportAccessComponent);
        $.ajax({
            url: ReportAccessUrl(reportId),
            method: 'PUT',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify(data),
        })
            .done(() => channel.emit(ReportAccessEvents.SaveSuccess))
            .fail((xhr: JQueryXHR) => channel.emit(ReportAccessEvents.SaveError, xhr));
    };

export interface SetTypePayload {
    type: string;
}

const setType = (type: string): ReactVapor.IReduxAction<SetTypePayload> => ({
    type: ReportAccessActionsType.SetType,
    payload: {
        type,
    },
});

export interface ClearModalPayload {}
const clearModal = (): ReactVapor.IReduxAction<ClearModalPayload> => ({
    type: ReportAccessActionsType.ClearModal,
    payload: {},
});

export const ReportAccessActions = {
    addIdentity,
    removeIdentity,
    fetchAccess,
    saveAccess,
    setType,
    clearModal,
};
