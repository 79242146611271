import {IQuery, IQueryResults, SearchEndpoint} from 'coveo-search-ui';
import {configureCloudV2Endpoint} from './SearchUIEndpoint';

export class SearchService {
    getResults(organizationId: string, viewAllContent: boolean, query: IQuery): Promise<IQueryResults> {
        configureCloudV2Endpoint(organizationId, viewAllContent);

        return SearchEndpoint.endpoints.default.search(query);
    }
}

export const searchServiceSingleton = new SearchService();
export const getSearchServiceSingleton = (): SearchService => searchServiceSingleton;
