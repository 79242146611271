export const GlobalConfigurationConstants = {
    actionTypes: {
        regions: 'REGION_CONFIGURATIONS',
        organization: 'ORGANIZATION_CONFIGURATION',
    },
    requestNames: {
        fetchRegions: 'FETCH_REGION_CONFIGURATIONS',
        fetchOrganization: 'FETCH_ORGANIZATION_CONFIGURATION',
    },
};
