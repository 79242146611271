import {useDidUpdate} from '@components/mantine';
import {Config} from '@core/configuration';
import {useMatch} from '@core/routes';
import {useEffect, useState} from 'react';

export const useInAppExperience = () => {
    const match = useMatch('/:orgId/:section/:page/*');
    const [ipxAdded, setIpxAdded] = useState(false);
    useEffect(() => {
        const script = document.createElement('script');
        const addIPXToPage = async () => {
            script.src = Config.CloudPlatformDocumentation.helpWidget;
            script.async = true;
            script.onload = async () =>
                await window.CoveoIPX?.initialize(Config.CloudPlatformDocumentation.helpWidgetApiKey, {
                    menuSection: match?.params?.section,
                    menuItem: match?.params?.page,
                });
            document.head.appendChild(script);
            setIpxAdded(true);
        };
        addIPXToPage();
        return () => {
            document.head.removeChild(script);
        };
    }, []);
    useDidUpdate(() => {
        if (ipxAdded) {
            window.CoveoIPX?.addContext({
                menuSection: match?.params?.section,
                menuItem: match?.params?.page,
            });
        }
    }, [match?.params]);
};
